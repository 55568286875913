import React, { useEffect, useMemo, useState } from "react";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import { useDebounce } from "use-debounce";
import { DashboardSubScreenLayout, apiGenerator } from "Utils";
import { classnames } from "Utils/Helpers/functions.helpers";
import {
  Button,
  ConfirmationModal,
  DivButton,
  ExpandableTable,
  HoverableTooltip,
  Icon,
  IconButton,
  SearchInput,
} from "UI";
import { WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";
import _ from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import CheckBox from "UI/CheckBox/CheckBox.component";
import PermissionInformationModal from "./PermissionInformationModal/PermissionInformationModal.component";
import EmptyContent from "../Components/EmptyContent/EmptyContent.component";
import ScreenShotModal from "./ScreenShotModal/ScreenShotModal.component";
import PowerShellModal from "./PowerShellModal/PowerShellModal.component";
import UserCell from "../Components/UserCell/UserCell.component";

import "./Permissions.styles.scss";

const Permissions = ({ translate, history }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [showScreenShotModal, setShowScreenShotModal] = useState(false);
  const [showPowerShellModal, setShowPowerShellModal] = useState(false);
  const [screenShot, setScreenShot] = useState(null);
  const [seatPermissions, setSeatPermissions] = useState(null);
  const [updatingPermissions, setUpdatingPermissions] = useState(false);
  const [showTerminateSessionConfirmationModal, setShowTerminateSessionConfirmationModal] = useState(false);
  const [showPermissionInformationModal, setShowPermissionInformationModal] = useState(false);
  const [highlightGuidance, setHighlightGuidance] = useState(false);
  const highlightGuidanceTimeout = React.useRef(null);
  const [machineNotRunningError, setMachineNotRunningError] = useState(false);

  const {
    isInitialLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchSeats,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.ORGANIZATION_SEATS,
    params: {
      q: searchQuery,
    },
    options: {
      keepPreviousData: true,
      overrideQueryKey: [API_ENDPOINTS.ORGANIZATION_SEATS, debouncedSearchQuery],
      refetchInterval: 5000,
    },
    resource: "seats",
  });

  const { data: permissionsData, refetch: refetchPermissions } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_SEATS_ALL_PERMISSIONS, debouncedSearchQuery],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_SEATS_ALL_PERMISSIONS);
    },
    staleTime: Infinity,
    onSuccess: () => {
      setUpdatingPermissions(false);
    },
  });

  useEffect(() => {
    refetchPermissions();
  }, []);

  const initialSeatPermissions = permissionsData?.data?.permissions;

  const updatePermissionsMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS_PERMISSIONS(data.id), {
        permissions: data.permissions,
      });
    },
  });

  const handleUpdatePermissions = async (terminateSessionConfirmationShown = false) => {
    setUpdatingPermissions(true);
    let anyReadyMachineWithInternetAccessChanged = false;

    const updatedPermissions = seatPermissions?.filter((seat) => {
      const initialPermissions = initialSeatPermissions?.find((p) => p.id === seat.id);

      if (initialPermissions.permissions.public_internet_access !== seat.permissions.public_internet_access) {
        const seatData = data?.find((s) => +s.id === +seat.id);
        const { machine } = seatData?.attributes;
        const { friendly_status: friendlyStatus } = machine.attributes;
        const machineReady = WORKSTATION_FRIENDLY_STATUS.READY === friendlyStatus;
        anyReadyMachineWithInternetAccessChanged = anyReadyMachineWithInternetAccessChanged || machineReady;
      }

      return !_.isEqual(initialPermissions.permissions, seat.permissions);
    });

    if (anyReadyMachineWithInternetAccessChanged && !terminateSessionConfirmationShown) {
      setShowTerminateSessionConfirmationModal(true);
      return;
    }

    if (updatedPermissions?.length === 0) return;

    await Promise.all(
      updatedPermissions.map(async (seat) => {
        try {
          await updatePermissionsMutation.mutateAsync(seat);
        } catch (error) {
          console.log(error);
        }
      }),
    );

    refetchData();
  };

  const refetchData = async () => {
    await Promise.all([refetchPermissions(), refetchSeats()]);
    setUpdatingPermissions(false);
  };

  const columns = [
    { name: "Id", weight: 0, hide: true },
    { name: "Computer", weight: 6 },
    { name: "Internet Access", keyName: "internetAccess", weight: 5, centered: true },
    { name: "Application Usage Monitoring", keyName: "usageMonitoring", weight: 5, centered: true },
    { name: "Upload & Download Files", keyName: "downloadFromVagon", weight: 5, centered: true },
    { name: "Copy Clipboard", keyName: "clipboard", weight: 5, centered: true },
    { name: "buttons", weight: 2, hide: true },
  ];

  const takeScreenShotMutation = useMutation({
    mutationFn: (machineId) => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_TAKE_SCREENSHOT(machineId));
    },
    onSuccess: (data) => {
      const base64 = data.data.screenshot;
      setScreenShot(`data:image/jpeg;base64,${base64}`);
      setShowScreenShotModal(true);
    },
  });

  useEffect(() => {
    setSeatPermissions(initialSeatPermissions);
    setUpdatingPermissions(false);
  }, [JSON.stringify(initialSeatPermissions)]);

  const handlePermissionChange = (seatId, permissionName, value) => {
    if (seatPermissions === null) return;
    setSeatPermissions((prevSeatPermissions) => {
      return prevSeatPermissions?.map((seat) => {
        if (+seat.id === +seatId) {
          return {
            ...seat,
            permissions: {
              ...seat.permissions,
              [permissionName]: value,
            },
          };
        }

        return seat;
      });
    });
  };

  const tableData = useMemo(() => {
    return data?.map((seat) => {
      const { machine, user_type: userType } = seat?.attributes;
      const { friendly_status: friendlyStatus } = machine.attributes;
      const machineReady = WORKSTATION_FRIENDLY_STATUS.READY === friendlyStatus;
      const initialPermissions = initialSeatPermissions?.find((p) => +p.id === +seat.id);
      const currentPermissions = seatPermissions?.find((p) => +p.id === +seat.id);
      const isOwner = userType === "owner";
      const canChangeInternetAccess = ![
        WORKSTATION_FRIENDLY_STATUS.TURNING_ON,
        WORKSTATION_FRIENDLY_STATUS.TURNING_OFF,
        WORKSTATION_FRIENDLY_STATUS.MODIFYING_NETWORK,
      ].includes(friendlyStatus);

      return {
        key: seat.id,
        computer: <UserCell user={seat?.attributes?.user} seat={seat} removeCheckbox isOwner={isOwner} />,
        internetAccess: (
          <div
            className={classnames([
              "checkbox-cell",
              initialPermissions?.permissions?.public_internet_access ===
                currentPermissions?.permissions?.public_internet_access && "low-opacity",
            ])}
          >
            <CheckBox
              checked={currentPermissions?.permissions?.public_internet_access}
              onChange={(e) => {
                handlePermissionChange(seat.id, "public_internet_access", e.target.checked);
              }}
              disabled={!canChangeInternetAccess}
            />
          </div>
        ),
        usageMonitoring: (
          <div
            className={classnames([
              "checkbox-cell",
              initialPermissions?.permissions?.analytics_collection_enabled ===
                currentPermissions?.permissions?.analytics_collection_enabled && "low-opacity",
            ])}
          >
            <CheckBox
              checked={currentPermissions?.permissions?.analytics_collection_enabled}
              onChange={(e) => {
                handlePermissionChange(seat.id, "analytics_collection_enabled", e.target.checked);
              }}
            />
          </div>
        ),
        downloadFromVagon: (
          <div
            className={classnames([
              "checkbox-cell",
              initialPermissions?.permissions?.can_download_from_vagon_workstation ===
                currentPermissions?.permissions?.can_download_from_vagon_workstation && "low-opacity",
            ])}
          >
            <CheckBox
              checked={currentPermissions?.permissions?.can_download_from_vagon_workstation}
              onChange={(e) => {
                handlePermissionChange(seat.id, "can_download_from_vagon_workstation", e.target.checked);
              }}
            />
          </div>
        ),

        clipboard: (
          <div
            className={classnames([
              "checkbox-cell",
              initialPermissions?.permissions?.clipboard_enabled ===
                currentPermissions?.permissions?.clipboard_enabled && "low-opacity",
            ])}
          >
            <CheckBox
              checked={currentPermissions?.permissions?.clipboard_enabled}
              onChange={(e) => {
                handlePermissionChange(seat.id, "clipboard_enabled", e.target.checked);
              }}
            />
          </div>
        ),
        buttons: (
          <div className="button-cell">
            <HoverableTooltip content="Take Screenshot">
              <IconButton
                name="camera"
                disabled={!machineReady}
                color="gray-4"
                onClick={() => {
                  takeScreenShotMutation.mutate(machine.id);
                }}
                onDisabledClick={() => {
                  handleHighlightGuidance(seat);
                }}
              />
            </HoverableTooltip>
            <HoverableTooltip
              content={"Computer is\nnot running"}
              side="right"
              sideOffset="1"
              arrow
              open={machineNotRunningError?.id === seat.id}
            >
              <HoverableTooltip content="Run Script">
                <IconButton
                  name="shell"
                  disabled={!machineReady}
                  color="gray-4"
                  onClick={() => {
                    setSelectedSeat(seat);
                    setShowPowerShellModal(true);
                  }}
                  onDisabledClick={() => {
                    handleHighlightGuidance(seat);
                  }}
                />
              </HoverableTooltip>
            </HoverableTooltip>
          </div>
        ),
      };
    });
  }, [
    JSON.stringify(data),
    JSON.stringify(initialSeatPermissions),
    JSON.stringify(seatPermissions),
    machineNotRunningError,
  ]);

  const handleHighlightGuidance = (seat) => {
    setHighlightGuidance(true);
    setMachineNotRunningError(seat);
    clearTimeout(highlightGuidanceTimeout.current);
    highlightGuidanceTimeout.current = setTimeout(() => {
      setHighlightGuidance(false);
      setMachineNotRunningError(null);
    }, 2000);
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("organizationPermissions.header.title")}
      headerDescription={translate("organizationPermissions.header.description")}
      noScroll
      loading={isInitialLoading && searchQuery === ""}
    >
      <div className="teams-permissions-container">
        <div className="top-bar">
          <div className="left-actions">
            <SearchInput
              placeholder={translate("organizationPermissions.searchInput.placeHolder")}
              iconName="search"
              color="white"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
          <div className="right-actions">
            {!_.isEqual(initialSeatPermissions, seatPermissions) ? (
              <>
                <HoverableTooltip content="Reset">
                  <IconButton smaller name="reset" onClick={() => setSeatPermissions(initialSeatPermissions)} />
                </HoverableTooltip>
                <Button
                  text="Update Permissions"
                  onClick={() => {
                    handleUpdatePermissions();
                  }}
                  disabled={updatingPermissions}
                  iconLeft={updatingPermissions ? "loader" : ""}
                />
              </>
            ) : (
              <DivButton
                className={classnames(["info-button-container", highlightGuidance && "highlighted"])}
                onClick={() => {
                  setShowPermissionInformationModal(true);
                }}
              >
                <Icon name="info" color="gray-4" />
              </DivButton>
            )}
          </div>
        </div>

        {data?.length > 0 ? (
          <ExpandableTable
            className="permissions-table"
            columns={columns}
            data={tableData}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onPageEnd={fetchNextPage}
            hideVerticalBar
          />
        ) : (
          <EmptyContent
            headerText={translate("organizationPermissions.emptyContent.title")}
            descriptionText={translate("organizationPermissions.emptyContent.description")}
            onButtonClick={() => {
              history.push(routes.organizationAdminHome);
            }}
            buttonText={translate("organizationPermissions.emptyContent.button")}
          />
        )}
      </div>
      {showScreenShotModal && <ScreenShotModal onClose={() => setShowScreenShotModal(false)} screenShot={screenShot} />}
      {showPowerShellModal && (
        <PowerShellModal onClose={() => setShowPowerShellModal(false)} machine={selectedSeat?.attributes?.machine} />
      )}
      {showTerminateSessionConfirmationModal && (
        <ConfirmationModal
          closeAction={() => {
            setShowTerminateSessionConfirmationModal(false);
            setUpdatingPermissions(false);
          }}
          headerText={translate("organizationPermissions.terminateSessionConfirmation.header")}
          descriptionText={translate("organizationPermissions.terminateSessionConfirmation.description")}
          confirmText={translate("organizationPermissions.terminateSessionConfirmation.confirm")}
          confirmAction={() => {
            setShowTerminateSessionConfirmationModal(false);
            handleUpdatePermissions(true);
          }}
          secondaryText={translate("organizationPermissions.terminateSessionConfirmation.cancel")}
          secondaryAction={() => {
            setShowTerminateSessionConfirmationModal(false);
            setUpdatingPermissions(false);
          }}
        />
      )}
      {showPermissionInformationModal && (
        <PermissionInformationModal
          translate={translate}
          onClose={() => {
            setShowPermissionInformationModal(false);
          }}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default Permissions;
