import { REQUEST_STATUS } from "Constants/global.constants";
import * as LoginConstants from "Constants/Authentication/Login.constants";

const initialState = {
  loginCTX: {
    scope: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  checkFingerprintCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  verifyFingerprintCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
};

export default function loginCTX(state = initialState, action) {
  switch (action.type) {
    case LoginConstants.LOGIN_CLEAR:
      return initialState;
    case LoginConstants.LOGIN_API_PENDING:
      return loginAPIPending(state, action);
    case LoginConstants.LOGIN_API_SUCCESS:
      return loginAPISuccess(state, action);
    case LoginConstants.LOGIN_API_FAILURE:
      return loginAPIFailure(state, action);
    case LoginConstants.LOGOUT_API_PENDING:
      return logout();
    case LoginConstants.CHECK_FINGERPRINT_PENDING:
      return checkFingerprintAPIPending(state);
    case LoginConstants.CHECK_FINGERPRINT_SUCCESS:
      return checkFingerprintAPISuccess(state, action);
    case LoginConstants.CHECK_FINGERPRINT_FAILURE:
      return checkFingerprintAPIFailure(state, action);
    case LoginConstants.VERIFY_FINGERPRINT_PENDING:
      return verifyFingerprintAPIPending(state);
    case LoginConstants.VERIFY_FINGERPRINT_SUCCESS:
      return verifyFingerprintAPISuccess(state, action);
    case LoginConstants.VERIFY_FINGERPRINT_FAILURE:
      return verifyFingerprintAPIFailure(state, action);
    default:
      return state;
  }
}

function loginAPIPending(state, action) {
  return {
    ...state,
    loginCTX: {
      scope: action.payload?.requestPayload?.scope,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function loginAPISuccess(state) {
  return {
    ...state,
    loginCTX: {
      ...state.loginCTX,
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function loginAPIFailure(state, action) {
  return {
    ...state,
    loginCTX: {
      ...state.loginCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
      client_code: action.payload.client_code,
    },
  };
}

function checkFingerprintAPIPending(state) {
  return {
    ...state,
    checkFingerprintCTX: {
      ...state.checkFingerprintCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function checkFingerprintAPISuccess(state, action) {
  return {
    ...state,
    checkFingerprintCTX: {
      ...state.checkFingerprintCTX,
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      requireMfa: action.payload.require_mfa,
    },
  };
}

function checkFingerprintAPIFailure(state, action) {
  return {
    ...state,
    checkFingerprintCTX: {
      ...state.checkFingerprintCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
      client_code: action.payload.client_code,
    },
  };
}

function verifyFingerprintAPIPending(state) {
  return {
    ...state,
    verifyFingerprintCTX: {
      ...state.checkFingerprintCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function verifyFingerprintAPISuccess(state) {
  return {
    ...state,
    verifyFingerprintCTX: {
      ...state.verifyFingerprintCTX,
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function verifyFingerprintAPIFailure(state, action) {
  return {
    ...state,
    verifyFingerprintCTX: {
      ...state.verifyFingerprintCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
      client_code: action.payload.client_code,
    },
  };
}

function logout() {
  return initialState;
}
