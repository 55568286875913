import * as OrganizationConstants from "../Constants/Organization.constants";

export const getOrganizationAPI = () => ({
  type: OrganizationConstants.GET_ORGANIZATION_API_PENDING,
});

export const getOrganizationPlanAPI = () => ({
  type: OrganizationConstants.GET_ORGANIZATION_PLAN_API_PENDING,
});

export const createOrganizationSeatsAPI = (data) => ({
  type: OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_PENDING,
  payload: data,
});

export const resetCreateOrganizationSeatsAPI = () => ({
  type: OrganizationConstants.RESET_CREATE_ORGANIZATION_SEATS_API,
});

export const setCreateComputersCTX = (data) => ({
  type: OrganizationConstants.SET_CREATE_COMPUTERS_CTX,
  payload: data,
});

export const resetCreateComputersCTX = () => ({
  type: OrganizationConstants.RESET_CREATE_COMPUTERS_CTX,
});

export const getSeatsMeAPI = () => ({
  type: OrganizationConstants.GET_SEATS_ME_API_PENDING,
});
