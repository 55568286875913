import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import routes from "Constants/Route.constants";
import { kebabToCamel } from "Utils/Helpers/functions.helpers";
import CreateComputerPurpose from "./CreateComputerPurpose/CreateComputerPurpose.component";
import CreateComputerPreInstall from "./CreateComputerPreInstall/CreateComputerPreInstall.component";
import CreateComputerConnection from "./CreateComputerConnection/CreateComputerConnection.component";
import CreateComputerSubscription from "./CreateComputerSubscription/CreateComputerSubscription.component";
import CreateComputerPayment from "./CreateComputerPayment/CreateComputerPayment.component";

import CreateComputerHeader from "./Components/CreateComputerHeader/CreateComputerHeader.component";

import "./CreateComputer.styles.scss";

const CreateComputer = (props) => {
  const {
    account,
    payment,
    referral,
    workstations,
    subscriptions,
    validateSoftwares,
    getSoftwares,
    getPlanData,
    setUserInterestsAPI,
    getAccountPredefinedAPI,
    getPaymentClientSecretAPI,
    applyPromoCodeAPI,
    createUserPaymentMethodAPI,
    plansCTX,
    regionsCTX,
    setRegion,
    setBalance,
    setDiskSize,
    onCreateWorkstationComplete,
    handleCheckout,
    translate,
    history,
    match,
  } = props;

  const workstationConfig = workstations.createWorkstationConfigCTX;
  const currentPage = kebabToCamel(match.params.page);

  return (
    <div className="computer-creation-container">
      <CreateComputerHeader
        iconName="vagon-icon"
        title={translate(`${currentPage}.header.title`)}
        description={translate(`${currentPage}.header.description`)}
        history={history}
        beta={["selectSoftware"].includes(currentPage)}
        onCloseButtonClick={() => {
          history.push(routes.home);
        }}
      />
      <div className="new-computer-content">
        {/* Use switch to not render everything and render to pass new props */}
        <Switch>
          <Route
            path={routes.createComputerPurpose}
            render={(props) => (
              <CreateComputerPurpose
                account={account}
                setUserInterestsAPI={setUserInterestsAPI}
                translate={translate}
                history={history}
                {...props}
              />
            )}
          />
          <Route
            path={routes.createComputerConnection}
            render={(props) => (
              <CreateComputerConnection
                region={workstations.createWorkstationConfigCTX.region}
                getSoftwares={getSoftwares}
                setRegion={setRegion}
                regionsCTX={regionsCTX}
                translate={translate}
                {...props}
              />
            )}
          />
          <Route
            path={routes.createComputerPreInstall}
            render={(props) => (
              <CreateComputerPreInstall
                {...props}
                account={account}
                selectedSoftwareIds={workstationConfig.softwares}
                setDiskSize={setDiskSize}
                preSelectedPackageId={workstationConfig.golden_image_id}
                validateSoftwares={validateSoftwares}
                getSoftwaresCTX={workstations.getSoftwaresCTX}
                installSoftwaresCTX={workstations.installSoftwaresCTX}
                workstationConfig={workstations.createWorkstationConfigCTX}
                onComplete={onCreateWorkstationComplete}
                translate={translate}
                subscriptions={subscriptions}
              />
            )}
          />
          <Route
            path={routes.createComputerSubscription}
            render={(props) => (
              <CreateComputerSubscription
                balance={workstations.createWorkstationConfigCTX.balance}
                setBalance={setBalance}
                onCheckout={handleCheckout}
                account={account}
                subscriptions={subscriptions}
                workstationConfig={workstations.createWorkstationConfigCTX}
                plansCTX={plansCTX}
                getWorkstationMachineTypesCTX={workstations.getWorkstationMachineTypesCTX}
                getPlanData={getPlanData}
                getAccountPredefinedAPI={getAccountPredefinedAPI}
                applyPromoCodeAPI={applyPromoCodeAPI}
                translate={translate}
                {...props}
              />
            )}
          />
          <Route
            path={routes.createComputerPayment}
            render={(props) => (
              <CreateComputerPayment
                {...props}
                account={account}
                payment={payment}
                referral={referral}
                workstations={workstations}
                subscriptions={subscriptions}
                createUserPaymentMethodAPI={createUserPaymentMethodAPI}
                getPaymentClientSecretAPI={getPaymentClientSecretAPI}
                onComplete={onCreateWorkstationComplete}
                translate={translate}
              />
            )}
          />
          <Redirect to={{ pathname: routes.createComputerPurpose }} />
        </Switch>
      </div>
    </div>
  );
};

export default CreateComputer;
