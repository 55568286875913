import React from "react";
import { connect } from "react-redux";
import AppStreamingStreamsComponent from "Components/AppStreaming/Streams/AppStreamingStreams.component";
import {
  createStreamAPI,
  getStreamsAPI,
  getStreamCapacitiesAPI,
  getAppCapacitiesAPI,
  pauseStreamAPI,
  updateStreamAPI,
  getCurrentPlanAPI,
  getApplicationsAPI,
  getVendorAccountAPI,
} from "Actions/AppStreaming.actions";
import { getProductOnboardingAPI } from "Actions/Account.actions";

const POLLING_INTERVAL = 60000;
class AppStreamingStreamsContainer extends React.Component {
  componentDidMount() {
    const { getStreamsAPI, getCurrentPlanAPI, getApplicationsAPI, getVendorAccountAPI } = this.props;
    getApplicationsAPI();
    getStreamsAPI();
    this.setPollingInterval();
    getCurrentPlanAPI();
    getVendorAccountAPI();
    getProductOnboardingAPI();
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
  }

  setPollingInterval = () => {
    clearInterval(this.pollingInterval);
    this.pollingInterval = setInterval(this.getStreams, POLLING_INTERVAL);
  };

  getStreams = () => {
    const { getStreamsAPI } = this.props;
    getStreamsAPI();
  };

  render() {
    return <AppStreamingStreamsComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  appStreaming: state.appStreaming,
});

const actionCreators = {
  getApplicationsAPI,
  createStreamAPI,
  getStreamsAPI,
  getStreamCapacitiesAPI,
  getAppCapacitiesAPI,
  pauseStreamAPI,
  updateStreamAPI,
  getCurrentPlanAPI,
  getVendorAccountAPI,
  getProductOnboardingAPI,
};

export default connect(mapStateToProps, actionCreators)(AppStreamingStreamsContainer);
