import React from "react";

const WorkstationCursor = ({ visible, x, y, cursorImage }) => {
  if (!visible || !cursorImage) return null;

  return (
    <div style={{ position: "fixed", top: y, left: x, width: 50, height: 50 }}>
      <img
        src={`data:image/png;base64,${cursorImage}`}
        onError={({ currentTarget }) => {
          // in case the cursor capture returned garbage in agent (it does occasionally, some problem in win32 api)
          // so we just hardcode the base64 img of the default windows cursor as a fallback
          const defaultImg = [
            "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAD9SURB",
            "VFhH7ZK9CsIwFEaTaFdtBX/wLZx8Cl/Rl7BjXboJLiJUV3GwOBUsqDhovvSKpWh1aFKQHCjc3Cbc0y9ljLcOwumNWW1IAS7ceX0SUuAuqU+CBEA9EjkBYF6i",
            "IADMSrwRAOYkPggAMxIlApfrzYBEiQBIkpNmiS8CII6PGiV+EAC73V5J0KkKKQik6Zmq7CcsPnSqQnICGM5YK535gVrLgYFodie0UxMk8ByOe+a8vUZPxS5r",
            "2qkJKZAfrlrC88NwAQeZgudrTSG719dwoGre3kAgirZIYUWvzIEvx3CVAneX1DZHwxkMkQIXnalw+iNqWywWi8XybzD2AJsQX2dx6fEeAAAAAElFTkSuQmCC",
          ].join("");
          // eslint-disable-next-line no-param-reassign
          currentTarget.onerror = null;
          // eslint-disable-next-line no-param-reassign
          currentTarget.src = `data:image/png;base64,${defaultImg}`;
        }}
        alt="mouse cursor"
      />
    </div>
  );
};

export default WorkstationCursor;
