import React, { useEffect, useRef } from "react";

import { LOCAL_STORAGE, WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";
import { getItemFromLocalStorage, saveItemToLocalStorage } from "Utils";
import { removeItemFromLocalStorage } from "Utils/Helpers/storage.helpers";

const TIMEOUT_PROGRESS_BAR = 30000;

const ComputerProgressBar = (props) => {
  const { friendlyStatus, translate } = props;
  const [progressStep, setProgressStep] = React.useState(-1);
  const timer = useRef(null);

  useEffect(() => {
    switch (friendlyStatus) {
      case WORKSTATION_FRIENDLY_STATUS.TURNING_OFF:
        setProgressStep(-1);
        removeItemFromLocalStorage(LOCAL_STORAGE.workstationProgressStep);
        break;
      case WORKSTATION_FRIENDLY_STATUS.READY:
        setProgressStep(4);
        clearInterval(timer.current);
        break;
      case WORKSTATION_FRIENDLY_STATUS.WARMING_UP:
        setProgressStep(3);
        clearInterval(timer.current);
        break;
      case WORKSTATION_FRIENDLY_STATUS.CREATING:
      case WORKSTATION_FRIENDLY_STATUS.TURNING_ON:
        stepProgressBar(getItemFromLocalStorage(LOCAL_STORAGE.workstationProgressStep) || 1);
        break;
      default:
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [friendlyStatus]);

  const stepProgressBar = (currentStep) => {
    setProgressStep(currentStep);
    saveItemToLocalStorage(LOCAL_STORAGE.workstationProgressStep, currentStep);
    if (currentStep < 2) {
      timer.current = setTimeout(() => {
        stepProgressBar(currentStep + 1);
      }, TIMEOUT_PROGRESS_BAR);
    }
  };

  const determineTextByProgressStep = () => {
    if (!progressStep) return null;

    return translate(`home.progressBar.step${progressStep}`);
  };

  return (
    progressStep > -1 &&
    progressStep < 4 && (
      <div className="progress-container">
        <div className="progress-bar">
          {[1, 2, 3, 4].map((idx) => (
            <div className={`step ${idx <= progressStep ? "active" : ""}`} key={idx} />
          ))}
        </div>
        <div className="progress-text">{determineTextByProgressStep()}</div>
      </div>
    )
  );
};

export default ComputerProgressBar;
