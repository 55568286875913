/* eslint import/no-dynamic-require: 0 */
/* eslint global-require: 0 */

import React, { useState } from "react";
import { IconButton } from "UI";
import { classnames } from "Utils";

import "./WorkstationUsages.styles.scss";

export const WorkstationUsage = (props) => {
  const { highlighted, selected, hover, highlightedSide, friendlyName, details, price, computerInfo, onClick } = props;
  return (
    // eslint-disable-next-line
    <div
      className={classnames([
        "workstation-usage-container",
        highlighted && "highlighted",
        selected && "selected",
        hover && "hover",
        highlightedSide && "highlighted-side",
        onClick && "clickable",
      ])}
      onClick={onClick}
    >
      <img className="computer-icon" src={require(`Assets/images/machineTypes/${friendlyName}.png`)} />
      {friendlyName}
      {details}
      {price}
      {computerInfo}
    </div>
  );
};

const gpuInfo = (usage) => {
  if (parseInt(usage.attributes.details.attributes.gpu_memory, 10) === 0) {
    if (usage.attributes.details.attributes.friendly_name === "Sand") {
      return "3.1GHz";
    }
    return "4.0GHz";
  }
  if (usage.attributes.details.attributes.gpu > 1) {
    return `${usage.attributes.details.attributes.gpu}x${(usage.attributes.details.attributes.gpu_memory * 1).toFixed(
      0,
    )}GB GPU`;
  }
  return `${(usage.attributes.details.attributes.gpu_memory * 1).toFixed(0)} GB GPU`;
};

const WorkstationCreditUsagesComponent = (props) => {
  const { usages, setComputerPriceByHour, setSelectedMachineId, selectedMachineId } = props;

  const [highlightedUsageIndex, setHighlightedUsageIndex] = useState(Math.floor(usages?.length / 2 + 1));

  return (
    <div className="workstation-usages-container">
      {usages && (
        <>
          <IconButton
            name="chevron-left"
            color="gray-5"
            disabled={highlightedUsageIndex < 1}
            onClick={() => {
              setHighlightedUsageIndex(highlightedUsageIndex - 1);
            }}
          />

          <div className="workstation-usages">
            {usages.map((usage, index) => (
              <WorkstationUsage
                key={usage.attributes.details.id}
                highlighted={index === highlightedUsageIndex}
                highlightedSide={Math.abs(highlightedUsageIndex - index) === 1}
                friendlyName={usage.attributes.details.attributes.friendly_name}
                selected={selectedMachineId === usage.attributes.details.id}
                price={
                  <p className="credits-per-hour">
                    {(usage.attributes.price_in_team_credit * 60).toFixed(0)} credits
                    <br /> per hour
                  </p>
                }
                computerInfo={
                  <p className="computer-information">
                    {(usage.attributes.details.attributes.cpu * 1).toFixed(0)} cores
                    <br />
                    {gpuInfo(usage)}
                    {usage.attributes.details.attributes.gpu_memory !== 0 && <br />}
                    {(usage.attributes.details.attributes.memory * 1).toFixed(0)}GB RAM
                    <br />
                  </p>
                }
                onClick={() => {
                  setHighlightedUsageIndex(index);
                  setComputerPriceByHour((usage.attributes.price_in_team_credit * 60).toFixed(1));
                  setSelectedMachineId(usage.attributes.details.id);
                }}
              />
            ))}
          </div>

          <IconButton
            name="chevron-right"
            color="gray-5"
            disabled={highlightedUsageIndex >= usages.length - 1}
            onClick={() => {
              setHighlightedUsageIndex(highlightedUsageIndex + 1);
            }}
          />
        </>
      )}
    </div>
  );
};

export default WorkstationCreditUsagesComponent;
