/* eslint import/no-dynamic-require: 0 */
/* eslint global-require: 0 */

import React, { useState } from "react";
import { IconButton } from "UI";
import { classnames, Loader } from "Utils";
import "./WorkstationUsages.styles.scss";

const DEFAULT_IMAGE = require("Assets/images/machineTypes/Default.png");

export const WorkstationUsage = (props) => {
  const { highlighted, highlightedSide, friendlyName, details, onClick } = props;
  let img;
  try {
    // eslint-disable-next-line
    img = <img src={require(`Assets/images/machineTypes/${friendlyName}.png`)} />;
  } catch {
    img = <img src={DEFAULT_IMAGE} />;
  }
  return (
    // eslint-disable-next-line
    <div
      className={classnames([
        "workstation-usage-container",
        highlighted && "highlighted",
        highlightedSide && "highlighted-side",
        onClick && "clickable",
      ])}
      onClick={onClick}
    >
      {img}
      {friendlyName}
      {details}
    </div>
  );
};

const WorkstationUsagesComponent = ({ usages, isLoading }) => {
  const [highlightedUsageIndex, setHighlightedUsageIndex] = useState(Math.floor(usages?.length / 2 + 1));

  if (!usages || isLoading) {
    return (
      <div className="workstation-usages-container">
        <Loader centered />
      </div>
    );
  }

  return (
    <div className="workstation-usages-container">
      {usages && (
        <>
          <IconButton
            name="chevron-left"
            color="gray-5"
            disabled={highlightedUsageIndex < 1}
            onClick={() => {
              setHighlightedUsageIndex(highlightedUsageIndex - 1);
            }}
          />
          <div className="workstation-usages">
            {usages.map((usage, index) => (
              <WorkstationUsage
                key={usage.attributes.machine_type.id}
                highlighted={index === highlightedUsageIndex}
                highlightedSide={Math.abs(highlightedUsageIndex - index) === 1}
                friendlyName={usage.attributes.machine_type.attributes.friendly_name}
                details={<p>{usage.attributes.minutes} min. left</p>}
              />
            ))}
          </div>
          <IconButton
            name="chevron-right"
            color="gray-5"
            disabled={highlightedUsageIndex >= usages.length - 1}
            onClick={() => {
              setHighlightedUsageIndex(highlightedUsageIndex + 1);
            }}
          />
        </>
      )}
    </div>
  );
};

export default WorkstationUsagesComponent;
