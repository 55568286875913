import React from "react";
import { ToastContainer, Slide, toast } from "react-toastify";
import parse from "html-react-parser";
import { classnames } from "Utils";

import "./ToastNotification.styles.scss";

export const ToastNotificationContainer = () => {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar
      closeButton={false}
      transition={Slide}
      draggable
      theme="colored"
      toastStyle={{ backgroundColor: "rgba(255, 255, 255, 0.6)", minHeight: "3rem" }}
      style={{ fontFamily: "vagonRails, sans-serif", width: "min-content", height: "min-content", bottom: "1.2rem" }}
    />
  );
};

export const ToastNotificationContent = ({ header, description, className: classNameFromProps }) => {
  return (
    <div className={classnames(["toast-notification-content", classNameFromProps])}>
      <p>{header}</p>
      {description && <p>{parse(description)}</p>}
    </div>
  );
};

export const showToastNotification = (props) => {
  const { header, description, autoClose } = props;
  const options = {};
  if (autoClose) options.autoClose = autoClose;
  toast.dismiss();
  toast(<ToastNotificationContent header={header} description={description} />, options);
};
