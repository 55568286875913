import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import "./Slider.style.scss";
import { classnames } from "Utils";

const SLIDER_HANDLE_WIDTH = 18;

const Slider = (props) => {
  const { minimumAllowedStep, step, setStep, stepCount, sliderStepWidth, color } = props;

  const sliderRef = useRef();
  const sliderHandleRef = useRef();
  const isDragging = useRef(false);

  const [sliderState, setSliderState] = useState({
    left: 0,
    width: 0,
  });
  const [handlePositionLeft, setHandlePositionLeft] = useState(0);

  useEffect(() => {
    calculateSliderState();

    window.addEventListener("resize", debounceCalculateSliderState);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("resize", debounceCalculateSliderState);
    };
  }, []);

  useEffect(() => {
    const stepSize = sliderState.width / stepCount;

    if (!step) {
      setHandlePositionLeft(0);
    } else {
      setHandlePositionLeft(stepSize * step - SLIDER_HANDLE_WIDTH / 2);
    }
  }, [sliderState.left, sliderState.width, step]);

  const calculateSliderState = () => {
    const boundingRectSlider = sliderRef.current.getBoundingClientRect();

    setSliderState({
      left: boundingRectSlider.left,
      width: boundingRectSlider.width,
    });
  };

  const debounceCalculateSliderState = _.debounce(calculateSliderState, 300);

  const determineStepByMousePosition = (mousePositionX) => {
    if (mousePositionX < sliderState.left) {
      return minimumAllowedStep || 1;
    } else if (mousePositionX > sliderState.left + sliderState.width) {
      return stepCount;
    }

    const stepSize = sliderState.width / stepCount;

    const step = parseInt((mousePositionX - sliderState.left + stepSize / 2) / stepSize, 10);

    if (minimumAllowedStep && step < minimumAllowedStep) {
      return minimumAllowedStep;
    }

    return step;
  };

  const handleDragStart = (event) => {
    event.preventDefault();

    isDragging.current = true;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (event) => {
    if (isDragging.current) {
      setStep(determineStepByMousePosition(event.pageX));
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
  };

  const handleStepClick = (event) => {
    const step = determineStepByMousePosition(event.pageX);
    if (step >= minimumAllowedStep) {
      setStep(step);
    } else {
      setStep(minimumAllowedStep);
    }
  };

  const stepElements = [];

  for (let i = 0; i < stepCount; i += 1) {
    stepElements.push(
      <div
        key={i}
        className={classnames(["slider-step", i < step && "fill", color])}
        style={{ width: sliderStepWidth }}
      />,
    );
  }

  return (
    // eslint-disable-next-line
    <div className="vg-slider" ref={sliderRef} onClick={(event) => handleStepClick(event)}>
      {stepElements}
      <div
        className={classnames(["slider-handle", color])}
        ref={sliderHandleRef}
        style={{ left: handlePositionLeft }}
        onDragStart={handleDragStart}
        draggable
      />
    </div>
  );
};

export default Slider;
