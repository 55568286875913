import React, { useRef, useEffect } from "react";
import { TextButton } from "UI";
import { classnames } from "Utils";

import "./MenuPopUp.styles.scss";

const MenuButton = (props) => {
  const { text, icon, onClick, disabled } = props;
  return (
    <div className={classnames(["menu-button", disabled && "disabled"])}>
      <TextButton text={text} icon={icon} iconPosition="left" onClick={onClick} color="gray-3" disabled={disabled} />
    </div>
  );
};

const MenuPopUp = (props) => {
  const {
    position,
    referencePosRef,
    moveFile,
    deleteFile,
    deleteFileDisabled,
    renameFile,
    downloadFile,
    setShowMenuPopUp,
    selectedFolderItems,
    disable,
    translate,
  } = props;

  const popUpMenu = useRef(null);
  const windowHeightRef = useRef(window.innerHeight);
  const windowWidthRef = useRef(window.innerWidth);

  const isCloseToBottom = windowHeightRef.current - 1.6 * position.y < 0;
  const positionY = isCloseToBottom ? position.top : position.y;
  const isCloseToRight = windowWidthRef.current - 1.6 * position.x < 0;

  const refPosRect = referencePosRef.current ? referencePosRef.current.getBoundingClientRect() : {};

  const refPos = { x: refPosRect.left, y: refPosRect.top };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpMenu.current && !popUpMenu.current.contains(event.target)) {
        setShowMenuPopUp(false);
      }
    };

    const closeOnResize = () => {
      setShowMenuPopUp(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", closeOnResize);

    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", closeOnResize);
    };
  }, []);

  return (
    <div
      className="file-menu-pop-up-container"
      ref={popUpMenu}
      style={{ top: positionY - refPos.y, left: position.x - refPos.x }}
    >
      {!disable && (
        <div
          className="file-menu-pop-up"
          style={{ transform: `translate(${isCloseToRight ? "-100%" : "0"},${isCloseToBottom ? "-100%" : "0"})` }}
        >
          {moveFile && <MenuButton text={translate("fileManager.actions.move")} icon="file-drag" onClick={moveFile} />}
          {renameFile && selectedFolderItems.length === 1 && (
            <MenuButton text={translate("fileManager.actions.rename")} icon="file-rename" onClick={renameFile} />
          )}
          {downloadFile && selectedFolderItems.length === 1 && (
            <MenuButton text={translate("fileManager.actions.download")} icon="file-download" onClick={downloadFile} />
          )}
          {deleteFile && (
            <MenuButton
              text={translate("fileManager.actions.delete")}
              icon="trash"
              onClick={deleteFile}
              disabled={deleteFileDisabled}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MenuPopUp;
