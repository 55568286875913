import React from "react";
import { Icon, DivButton } from "UI";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DatePickerInput.styles.scss";

const DatePickerInput = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  inline,
  placeHolder,
  className: classNameFromProps,
  maxDate,
  minDate,
  ...rest
}) => {
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <DivButton className="date-picker-custom-input-container" onClick={onClick} divRef={ref}>
      <div className="date-picker-custom-input">{value || placeHolder}</div>
      <div className="date-picker-custom-icon-button">
        <Icon name="calendar" smaller />
      </div>
    </DivButton>
  ));

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      customInput={<ExampleCustomInput />}
      popperPlacement="bottom-end"
      showPopperArrow={false}
      inline={inline}
      dateFormat="dd-MM-yyyy"
      maxDate={maxDate || new Date()}
      calendarClassName={classNameFromProps}
      minDate={minDate}
      {...rest}
    />
  );
};

export default DatePickerInput;
