import React, { useEffect, useRef } from "react";
import { DivButton } from "UI";
import { classnames } from "Utils";
import { useClickOutside, useWindowResize } from "Utils/Hooks";
import "./AppMenuPopUp.styles.scss";

const MenuButton = ({ text, onClick, disabled, disableHover }) => {
  return (
    <DivButton
      className={classnames(["menu-button", disableHover && "disable-hover"])}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </DivButton>
  );
};

const AppMenuPopUp = ({
  referencePosRef,
  popUpPosition,
  setShowPopUpMenu,
  setPopUpPosition,
  content,
  fixedToRight,
  className: classNameFromProps,
}) => {
  const popUpMenuRef = useRef(null);
  const windowHeightRef = useRef(window.innerHeight);
  const windowWidthRef = useRef(window.innerWidth);

  const isCloseToBottom = windowHeightRef.current / 2 < popUpPosition?.y;
  const positionY = isCloseToBottom ? popUpPosition?.top : popUpPosition?.y;
  const isCloseToRight = !fixedToRight && windowWidthRef.current - 3 * popUpPosition?.x < 0;

  const refPosRect = referencePosRef?.current ? referencePosRef?.current.getBoundingClientRect() : {};

  const refPos = { x: refPosRect.left, y: refPosRect.top };

  const clickOutsideRef = useClickOutside(null, () => {
    if (setShowPopUpMenu) setShowPopUpMenu(false);
    if (setPopUpPosition) setPopUpPosition(null);
  });
  const resizeRef = useWindowResize(null, () => {
    if (setShowPopUpMenu) setShowPopUpMenu(false);
    if (setPopUpPosition) setPopUpPosition(null);
  });

  useEffect(() => {
    clickOutsideRef.current = popUpMenuRef.current;
    resizeRef.current = popUpMenuRef.current;
  }, []);

  const shownContent = content.filter((item) => !!item && !item.hide);

  return (
    <div
      className={classnames(["app-menu-pop-up-container", classNameFromProps])}
      style={popUpPosition ? { top: positionY - refPos.y, left: popUpPosition.x - refPos.x } : {}}
    >
      <div
        className="app-menu-pop-up"
        ref={popUpMenuRef}
        style={
          popUpPosition
            ? {
                top: positionY - refPos.y,
                left: popUpPosition.x - refPos.x,
                transform: `translate(${isCloseToRight ? "-100%" : "0"},${isCloseToBottom ? "-100%" : "0"})`,
              }
            : {}
        }
      >
        {shownContent.map((singleContent) => (
          <MenuButton
            key={singleContent.key || singleContent.text}
            text={singleContent.text}
            onClick={singleContent.onClick}
            disabled={singleContent.disabled}
            disableHover={singleContent.disableHover}
          />
        ))}
      </div>
    </div>
  );
};

export default AppMenuPopUp;
