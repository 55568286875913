import * as ReferralConstants from "Constants/Referral.constants";

export const getRewardsStatusAPI = (accessToken) => ({
  type: ReferralConstants.GET_REWARDS_STATUS_API_PENDING,
  payload: { accessToken },
});

export const getRewardsAPI = (accessToken) => ({
  type: ReferralConstants.GET_REWARDS_API_PENDING,
  payload: { accessToken },
});

export const claimRewardsAPI = (accessToken) => ({
  type: ReferralConstants.CLAIM_REWARDS_API_PENDING,
  payload: { accessToken },
});

export const inviteAPI = (accessToken, email, templateId) => ({
  type: ReferralConstants.INVITE_API_PENDING,
  payload: { accessToken, email, templateId },
});
