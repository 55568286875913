import React, { Component } from "react";

import { connect } from "react-redux";
import { Tracker } from "Utils";
import { getPlansAPI } from "Actions/Dashboard/Plans.actions";
import { getRegionsAPI, getLocationAPI } from "Actions/Dashboard/Regions.actions";
import {
  createUserWorkstationAsSubscriptionAPI,
  getUserSubscriptionPaymentInformationAPI,
} from "Actions/Dashboard/Subscriptions.actions";
import {
  updateCreateWorkstationConfig,
  getWorkstationsAPI,
  getWorkstationMachineTypesAPI,
  getWorkstationSoftwaresAPI,
  installWorkstationSoftwaresAPI,
  validateWorkstationSoftwaresAPI,
} from "Actions/Dashboard/Workstations.actions";
import { getAccountPredefinedAPI, applyPromoCodeAPI, setUserInterestsAPI } from "Actions/Account.actions";
import {
  getPaymentMethodsAPI,
  getPaymentClientSecretAPI,
  createUserPaymentMethodAPI,
} from "Actions/Dashboard/Payment.actions";
import { getRewardsAPI } from "Actions/Referral.actions";

import { REQUEST_STATUS, LOCAL_STORAGE } from "Constants/global.constants";
import routes from "Constants/Route.constants";
import { removeItemFromLocalStorage } from "Utils/Helpers/storage.helpers";

import CreateComputer from "Components/Dashboard/CreateComputer/CreateComputer.component";

class CreateComputerContainer extends Component {
  constructor(props) {
    super();

    // Todo improve this logic
    props.getPlansAPI();
    props.getRegionsAPI();
    props.getLocationAPI();
    props.getWorkstationMachineTypesAPI(props.account.accessToken);
    props.getAccountPredefinedAPI(props.account.accessToken);
    props.getWorkstationsAPI(props.account.accessToken);
  }

  componentDidMount() {
    const { account, workstations, history, match } = this.props;
    const workstationConfig = workstations.createWorkstationConfigCTX;

    if (!workstationConfig.workstation_name) {
      // Check workstation name
      history.push(routes.home);
    } else if (account.account.attributes.interests.length < 1) {
      history.push(routes.createComputerPurpose);
    } else if (!workstationConfig.region) {
      // Check region
      history.push(routes.createComputerConnection);
    } else if (match.url === routes.createComputerPreInstall) {
      this.getSoftwares();
    } else if (match.url === routes.createComputerSubscription) {
      // Get applied plan details
      if (account.isTeamAccount) {
        // Team accounts should complete after pre install
        history.push(routes.createComputerPreInstall);
      } else {
        this.getPlanData();
      }
    } else if (match.url === routes.createComputerPayment) {
      if (account.isTeamAccount) {
        // Team accounts should complete after pre install
        history.push(routes.createComputerPreInstall);
      } else {
        // Get payment information if every field exists
        this.handleCheckout();
      }
    } else {
      this.getSoftwares();
      history.push(routes.createComputerPreInstall);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      account,
      payment,
      workstations,
      subscriptions,
      installWorkstationSoftwaresAPI,
      getPaymentMethodsAPI,
      getWorkstationsAPI,
      history,
    } = this.props;

    const workstationConfig = workstations.createWorkstationConfigCTX;

    if (prevProps.workstations.getWorkstationsCTX.status !== workstations.getWorkstationsCTX.status) {
      if (
        workstations.getWorkstationsCTX.status === REQUEST_STATUS.SUCCESS &&
        payment.payment3DSecureCTX.status !== REQUEST_STATUS.PENDING
      ) {
        installWorkstationSoftwaresAPI(
          account.accessToken,
          workstationConfig.softwares,
          workstationConfig.golden_image_id,
        );
        history.push(routes.home);
        removeItemFromLocalStorage(LOCAL_STORAGE.createComputerConfigs);
      }
    }

    if (
      prevProps.payment.payment3DSecureCTX.status === REQUEST_STATUS.PENDING &&
      payment.payment3DSecureCTX.status === REQUEST_STATUS.SUCCESS
    ) {
      installWorkstationSoftwaresAPI(
        account.accessToken,
        workstationConfig.softwares,
        workstationConfig.golden_image_id,
      );
      history.push(routes.home);
      removeItemFromLocalStorage(LOCAL_STORAGE.createComputerConfigs);
    }

    if (
      (prevProps.payment.deleteUserPaymentMethodCTX.status === REQUEST_STATUS.PENDING &&
        payment.deleteUserPaymentMethodCTX.status === REQUEST_STATUS.SUCCESS) ||
      (prevProps.payment.setPreferredPaymentMethodCTX.status === REQUEST_STATUS.PENDING &&
        payment.setPreferredPaymentMethodCTX.status === REQUEST_STATUS.SUCCESS) ||
      (prevProps.payment.createUserPaymentMethodCTX.status === REQUEST_STATUS.PENDING &&
        payment.createUserPaymentMethodCTX.status === REQUEST_STATUS.SUCCESS)
    ) {
      getPaymentMethodsAPI(account.accessToken);
    }

    if (
      prevProps.subscriptions.createUserWorkstationAsSubscriptionCTX.status === REQUEST_STATUS.PENDING &&
      subscriptions.createUserWorkstationAsSubscriptionCTX.status === REQUEST_STATUS.SUCCESS
    ) {
      getWorkstationsAPI(account.accessToken);
    }
  }

  setRegion = (region) => {
    const { updateCreateWorkstationConfig } = this.props;
    updateCreateWorkstationConfig({ region });
  };

  setBalance = (balance) => {
    const { updateCreateWorkstationConfig } = this.props;
    updateCreateWorkstationConfig({ balance });
  };

  setDiskSize = (size) => {
    const { updateCreateWorkstationConfig } = this.props;
    updateCreateWorkstationConfig({ disk_size: size });
  };

  onCreateWorkstationComplete = (paymentType) => {
    const { account, workstations, createUserWorkstationAsSubscriptionAPI } = this.props;
    const workstationConfig = workstations.createWorkstationConfigCTX;
    createUserWorkstationAsSubscriptionAPI(
      account.accessToken,
      workstationConfig,
      workstationConfig.balance,
      paymentType,
    );
  };

  getSoftwares = () => {
    const { account, workstations, getWorkstationSoftwaresAPI } = this.props;
    const workstationConfig = workstations.createWorkstationConfigCTX;
    getWorkstationSoftwaresAPI(account.accessToken);
    this.validateSoftwares(workstationConfig.softwares, workstationConfig.golden_image_id, workstationConfig.disk_size);
  };

  validateSoftwares = (softwareIds = [], goldenImageId, diskSize = 75) => {
    const { account, workstations, validateWorkstationSoftwaresAPI, updateCreateWorkstationConfig } = this.props;
    const workstationConfig = workstations.createWorkstationConfigCTX;

    updateCreateWorkstationConfig({
      disk_size: diskSize,
      softwares: softwareIds,
      golden_image_id: goldenImageId,
    });
    validateWorkstationSoftwaresAPI(
      account.accessToken,
      softwareIds,
      goldenImageId,
      diskSize,
      workstationConfig.region,
    );
  };

  getPlanData = () => {
    const { account, workstations, getUserSubscriptionPaymentInformationAPI, getRewardsAPI } = this.props;
    const workstationConfig = workstations.createWorkstationConfigCTX;
    getUserSubscriptionPaymentInformationAPI(account.accessToken, {
      disk_size: workstationConfig.disk_size,
    });
    getRewardsAPI(account.accessToken);
  };

  handleCheckout = () => {
    const { account, workstations, getUserSubscriptionPaymentInformationAPI, getPaymentMethodsAPI } = this.props;
    const workstationConfig = workstations.createWorkstationConfigCTX;

    getUserSubscriptionPaymentInformationAPI(account.accessToken, {
      disk_size: workstationConfig.disk_size,
      balance: workstationConfig.balance,
    });
    getPaymentMethodsAPI(account.accessToken);
  };

  render() {
    return (
      <CreateComputer
        {...this.props}
        getSoftwares={this.getSoftwares}
        validateSoftwares={this.validateSoftwares}
        getPlanData={this.getPlanData}
        handleCheckout={this.handleCheckout}
        setRegion={this.setRegion}
        setBalance={this.setBalance}
        setDiskSize={this.setDiskSize}
        onCreateWorkstationComplete={this.onCreateWorkstationComplete}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  referral: state.referral,
  subscriptions: state.subscriptions,
  payment: state.payment,
  plansCTX: state.plansCTX,
  regionsCTX: state.regionsCTX,
  workstations: state.workstations,
});

const actionCreators = {
  getPlansAPI,
  getRewardsAPI,
  getRegionsAPI,
  getLocationAPI,
  getAccountPredefinedAPI,
  applyPromoCodeAPI,
  setUserInterestsAPI,

  getUserSubscriptionPaymentInformationAPI,
  getWorkstationMachineTypesAPI,
  getWorkstationsAPI,
  installWorkstationSoftwaresAPI,
  getWorkstationSoftwaresAPI,
  validateWorkstationSoftwaresAPI,
  getPaymentMethodsAPI,
  getPaymentClientSecretAPI,
  createUserPaymentMethodAPI,
  createUserWorkstationAsSubscriptionAPI,
  updateCreateWorkstationConfig,
};

export default connect(mapStateToProps, actionCreators)(CreateComputerContainer);
