import React from "react";

import "./Purpose.styles.scss";

const Purpose = (props) => {
  const { purposeName, purposeText, purposeImage, isSelected, setSelectedPurpose } = props;

  const handleEnterKeyUp = (event) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      setSelectedPurpose(purposeName);
    }
  };

  return (
    <div
      className={`purpose ${isSelected ? "selected" : ""}`}
      onClick={() => setSelectedPurpose(purposeName)}
      role="button"
      tabIndex={0}
      onKeyUp={handleEnterKeyUp}
    >
      {purposeText}
      <img className="purpose-image" alt={purposeName} src={purposeImage} />
    </div>
  );
};

export default Purpose;
