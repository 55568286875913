import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Joi from "joi";
import _ from "lodash";
import { classnames, isMobile } from "Utils";
import ReCaptcha from "Utils/Components/Form/ReCaptcha";
import { Input, PasswordInput, Button, TopLoader, TextButton } from "UI";
import routes from "Constants/Route.constants";
import { APP_SCOPES, ERROR_CODE, LOCAL_STORAGE, REQUEST_STATUS, ROOT_PATHS } from "Constants/global.constants";
import AuthenticationHeader from "Utils/Components/Authentication/AuthenticationHeader/AuthenticationHeader.component";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useDispatch, useSelector } from "react-redux";
import { getAccountAPI } from "Actions/Account.actions";
import { checkFingerprintAPI, loginClear, verifyFingerprintAPI } from "Actions/Authentication/Login.actions";
import { secondsToMinutesSeconds } from "Utils/Helpers/functions.helpers";
import { getItemFromLocalStorage, saveItemToLocalStorage } from "Utils/Helpers/storage.helpers";
import OtpInput from "UI/OtpInput/OtpInput.component";

import {
  CHECK_FINGERPRINT_ERROR_CODES,
  VERIFICATION_TIMEOUT,
  VERIFY_FINGERPRINT_ERROR_CODES,
} from "Constants/Authentication/Login.constants";
import { useAppScope } from "Utils/Hooks/useAppScope";
import GoogleSignIn from "Utils/Components/GoogleSignIn/GoogleSignIn.component";

import "./Login.styles.scss";

const emailValidationErrors = {
  "string.empty": "Enter your email address",
  "string.email": "Enter a valid email address",
  "any.required": "Enter your email address",
};

const passwordValidationErrors = {
  "string.empty": "Enter the password of your account",
  "any.required": "Enter the password of your account",
  "string.min": "Enter the password of your account",
};

// prettier-ignore
const credentialError = "Oops, something went wrong. Please, check your account information.";
const scopeError = "We're redirecting you to related login screen. Please try to login from this page.";
const fingerprintError = "Oops, something went wrong about fingerprint.";
const contactSupportError = "Oops, something went wrong. Please contact support.";
const accountNotFoundError =
  "We couldn't find an account with this email address. We are redirecting you to the sign up page.";

const ValidationSchema = Joi.object({
  email: Joi.string()
    .email({
      minDomainSegments: 2,
      tlds: false,
    })
    .required()
    .messages(emailValidationErrors),
  password: Joi.string().min(8).required().messages(passwordValidationErrors),
});

const LoginComponent = ({ history, login, loginAPI, loginFromPlugin, translate }) => {
  const { loginCTX } = login || {};
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null);
  const [verificationError, setVerificationError] = React.useState(null);
  const [counter, setCounter] = React.useState(VERIFICATION_TIMEOUT);
  const [verificationCode, setVerificationCode] = React.useState("");
  const [verificationResent, setVerificationResent] = React.useState(false);
  const [otpSubmitted, setOtpSubmitted] = React.useState(false);
  const [visitorData, setVisitorData] = React.useState(null);

  const { setAppScope } = useAppScope();
  const dispatch = useDispatch();

  const { status: loginStatus } = useSelector((state) => state.login.loginCTX) || {};
  const { status: verifyFingerprintStatus, client_code: verifyFingerprintClientCode } =
    useSelector((state) => state.login.verifyFingerprintCTX) || {};
  const { getAccountCTX, account } = useSelector((state) => state.account) || {};
  const { scope } = account?.attributes || {};
  const {
    status: checkFingerprintStatus,
    client_code: checkFingerprintClientCode,
    requireMfa,
  } = useSelector((state) => state.login.checkFingerprintCTX) || {};

  const getFingerprint = () => {
    const fpPromise = FingerprintJS.load();
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        setVisitorData(result);
        dispatch(checkFingerprintAPI({ fingerprint: result.visitorId }));
      });
  };

  const checkLocalFingerprint = async () => {
    const visitorId = await getItemFromLocalStorage(LOCAL_STORAGE.visitorId);
    if (visitorId) {
      setVisitorData({ visitorId });
      dispatch(checkFingerprintAPI({ fingerprint: visitorId, fingerprintFromCache: true }));
      return;
    }

    getFingerprint();
  };

  useEffect(() => {
    if (loginStatus === REQUEST_STATUS.SUCCESS) {
      checkLocalFingerprint();
    }
  }, [loginStatus]);

  useEffect(() => {
    if (getAccountCTX.status === REQUEST_STATUS.SUCCESS) {
      if (loginFromPlugin) {
        setAppScope(APP_SCOPES.vendor);
        history.push(routes.appStreamingSendTokenToPlugin);
        return;
      }

      if (!scope) {
        history.push(routes.suite);
        return;
      }

      setAppScope(scope);

      history.push(ROOT_PATHS[scope]);
    }
  }, [getAccountCTX.status]);

  useEffect(() => {
    if (checkFingerprintStatus === REQUEST_STATUS.SUCCESS && !requireMfa) {
      if (visitorData) saveItemToLocalStorage(LOCAL_STORAGE.visitorId, visitorData.visitorId);

      dispatch(getAccountAPI());
    }

    if (loginStatus !== REQUEST_STATUS.SUCCESS || checkFingerprintStatus !== REQUEST_STATUS.PENDING) {
      return undefined;
    }

    if (requireMfa && !visitorData) {
      getFingerprint();
      return undefined;
    }

    if (
      checkFingerprintStatus === REQUEST_STATUS.FAILURE &&
      checkFingerprintClientCode === CHECK_FINGERPRINT_ERROR_CODES.fingerprintBlocked
    ) {
      setError({ email: fingerprintError });
    }

    if (requireMfa) {
      setCounter(VERIFICATION_TIMEOUT);
    }

    function checkToken() {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        resetState();
      }
    }

    window.addEventListener("storage", checkToken);

    return () => {
      window.removeEventListener("storage", checkToken);
    };
  }, [checkFingerprintStatus]);

  useEffect(() => {
    if (verifyFingerprintStatus === REQUEST_STATUS.SUCCESS) {
      if (visitorData) saveItemToLocalStorage(LOCAL_STORAGE.visitorId, visitorData.visitorId);

      if (loginFromPlugin) history.push(routes.appStreamingSendTokenToPlugin);
      else history.push(routes.home);
    }

    if (verifyFingerprintStatus === REQUEST_STATUS.FAILURE) {
      if (verifyFingerprintClientCode === VERIFY_FINGERPRINT_ERROR_CODES.wrongVerificationCode) {
        setVerificationError("Invalid verification code");
      } else {
        setVerificationError("Verification Failed");
        setTimeout(() => {
          resetState();
        }, 3000);
      }
    }
  }, [verifyFingerprintStatus]);

  React.useEffect(() => {
    if (loginCTX?.status === REQUEST_STATUS.FAILURE && loginCTX?.error) {
      if (loginCTX.client_code === ERROR_CODE.WRONG_SCOPE) {
        setError({
          email: scopeError,
        });
      } else if (loginCTX.client_code === ERROR_CODE.VENDOR_ADMIN_NOT_FOUND) {
        setError({
          email: contactSupportError,
        });
      } else if (loginCTX.client_code === ERROR_CODE.ACCOUNT_NOT_FOUND) {
        setError({
          email: accountNotFoundError,
        });
        setTimeout(() => {
          resetState();
          history.push(routes.register);
        }, 3000);
      } else {
        setError({
          email: credentialError,
        });
      }
    } else {
      setError(null);
    }
  }, [loginCTX]);

  useEffect(() => {
    if (counter === 0 && verificationResent) {
      resetState();
    }
    if (requireMfa) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
    return undefined;
  }, [counter, requireMfa]);

  const resendVerificationCode = () => {
    dispatch(checkFingerprintAPI({ fingerprint: visitorData.visitorId }));
    setCounter(VERIFICATION_TIMEOUT);
    setVerificationCode("");
    setVerificationError(null);
    setVerificationResent(true);
  };

  const resetState = () => {
    dispatch(loginClear());
    setEmail("");
    setPassword("");
    setVerificationCode("");
    setVerificationError("");
  };

  const handleSubmit = (event, reCaptcha) => {
    event.preventDefault();

    const { error: validationError } = ValidationSchema.validate({
      email,
      password,
    });

    if (validationError) {
      const errorString = _.replace(validationError, "ValidationError: ", "");

      if (_.includes(emailValidationErrors, errorString)) {
        setError({
          email: errorString,
        });
      }

      if (_.includes(passwordValidationErrors, errorString)) {
        setError({
          password: errorString,
        });
      }
    } else {
      setError(null);
      reCaptcha({ action: "login" }, (recaptchaResponse) => {
        loginAPI({ email, password, recaptchaResponse });
      });
    }
  };

  const handleGoogleSignIn = (assertion, reCaptcha) => {
    loginAPI({
      assertion,
      provider: "google",
      grantType: "assertion",
      recaptchaResponse: reCaptcha,
    });
  };

  const fromVerify = history.location.state && history.location.state.from === routes.verifyRegister;
  const getTitle = () => {
    return fromVerify ? translate("verifyRegister.loginHeader.welcome") : translate("login.header.welcome");
  };
  const getDescription = () => {
    return fromVerify ? translate("verifyRegister.loginHeader.description") : translate("login.header.description");
  };
  const getAction = () => {
    if (fromVerify) return () => {};

    return () => history.push(routes.register);
  };

  return (
    <>
      {loginCTX.status === REQUEST_STATUS.PENDING ||
        (checkFingerprintStatus === REQUEST_STATUS.PENDING && <TopLoader />)}
      <div className={classnames(["vg-login-content", "form-container"])}>
        {requireMfa && checkFingerprintStatus !== REQUEST_STATUS.PENDING ? (
          <>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                setOtpSubmitted(true);
                setTimeout(() => {
                  setOtpSubmitted(false);
                }, 1000);
                setVerificationError(null);
                dispatch(verifyFingerprintAPI(visitorData, verificationCode));
              }}
            >
              <AuthenticationHeader
                title={translate("login.verifyDevice.header")}
                description={translate("login.verifyDevice.description")}
              />
              <div className="verification-container">
                <p>{translate("login.verifyDevice.securityCode")}</p>
                <OtpInput
                  value={verificationCode}
                  setValue={setVerificationCode}
                  hasError={verificationError}
                  errorText={verificationError}
                  disabled={verifyFingerprintClientCode === VERIFY_FINGERPRINT_ERROR_CODES.verificationFailed}
                  mobile={isMobile}
                />
                {counter === 0 ? (
                  <p className="bottom-text">
                    {translate("login.verifyDevice.bottomText.expired")}
                    <TextButton onClick={resendVerificationCode} text="Resend Now" color="purple" />
                  </p>
                ) : (
                  <p className="bottom-text">
                    {translate("login.verifyDevice.bottomText.willExpire")}
                    <span>{secondsToMinutesSeconds(counter)}</span>
                  </p>
                )}
              </div>
              <Button
                type="submit"
                text="Send"
                block
                big
                clickable
                disabled={
                  otpSubmitted ||
                  counter === 0 ||
                  verificationCode.length !== 6 ||
                  verifyFingerprintClientCode === VERIFY_FINGERPRINT_ERROR_CODES.verificationFailed
                }
              />
            </form>
          </>
        ) : (
          <ReCaptcha>
            {(reCaptcha) => (
              <form onSubmit={(event) => handleSubmit(event, reCaptcha)} noValidate>
                <AuthenticationHeader
                  title={getTitle()}
                  description={getDescription()}
                  descriptionActionText={
                    fromVerify
                      ? translate("appStreamingLogin.header.descriptionActionText")
                      : translate("login.header.descriptionActionText")
                  }
                  descriptionAction={getAction()}
                />

                <div className="field-group">
                  <GoogleSignIn
                    onSuccess={(e) => {
                      const assertion = e.credential;
                      handleGoogleSignIn(assertion, reCaptcha);
                    }}
                  />
                  <hr className="hr-text" data-content="or" />
                  <Input
                    type="email"
                    autoComplete="email"
                    label={translate("login.form.email.label")}
                    placeholder={translate("login.form.email.placeholder")}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    hasError={error && error.email}
                    errorText={error && error.email}
                  />
                  <PasswordInput
                    label={translate("login.form.password.label")}
                    placeholder={translate("login.form.password.placeholder")}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    hasError={error && error.password}
                    errorText={error && error.password}
                  />

                  {login && <Link to={routes.forgetPassword}>{translate("login.form.forgotPassword.link")}</Link>}
                </div>

                <Button
                  type="submit"
                  disabled={
                    loginCTX?.status === REQUEST_STATUS.PENDING ||
                    loginCTX?.status === REQUEST_STATUS.SUCCESS ||
                    checkFingerprintStatus === REQUEST_STATUS.PENDING ||
                    reCaptcha === null
                  }
                  clickable
                  text={translate("login.form.button.text")}
                  block
                />
              </form>
            )}
          </ReCaptcha>
        )}
      </div>
    </>
  );
};

export default LoginComponent;
