import * as MessageHelper from "Utils/Workstation/messageHelper";
import { Logger, getItemFromLocalStorage, isFirefox } from "Utils";
import { isMacOS, isSafari } from "Utils/Helpers/browser.helpers";
import * as Gamepad from "Utils/Workstation/Gamepad";
import { getSwitchCmdPreference } from "Utils/Helpers/streaming.helpers";
import { LOCAL_STORAGE } from "Constants/global.constants";

// Touch screen right click timing 1000ms
const TOUCH_RIGHT_CLICK_TIME = 1000;
const TOUCH_DOUBLE_CLICK_TIME = 500;
let touchStarted = false;
let doubleClick = false;

const CTRL_PAYLOAD = (up) => MessageHelper.generateKeyboardPayload("Control", 17, "ControlLeft", up);
const META_PAYLOAD = (up) => MessageHelper.generateKeyboardPayload("Meta", 91, "MetaLeft", up);
const ALT_PAYLOAD = (up) => MessageHelper.generateKeyboardPayload("Alt", 18, "AltLeft", up);
const CAPSLOCK_PAYLOAD = (up) => MessageHelper.generateKeyboardPayload("CapsLock", 18, "CapsLock", up);

export default class Peripherals {
  constructor(websocket, player) {
    this.websocket = websocket;
    this.buttonMask = 0;
    this.previousScrollTime = new Date().getTime();
    this.mouseDeltaX = 0;
    this.mouseDeltaY = 0;
    this.verticalScrollDirection = -1;
    this.switchCmdWithCtrl = getSwitchCmdPreference();
    this.preventExcludedKeys = ["META", "v", "V", "c", "C"];
    this.excludedKeys = [];
    this.player = player;
    this.pressedKeys = [];
    this.touchStarted = false;
    this.canPaste = false;
    if (this.player) {
      this.width = this.player.width;
      this.height = this.player.height;
    }
    this.customResolutionEnabled = false;
    this.disableKeyboardActions = false;
    this.gameMode = false;
    Gamepad.startPolling(this.gamepadHandler);
    this.timeOutHandlers = {};
    this.disableMetaKey = false;
    this.altKeyPressed = false;
    this.debugEnabled = getItemFromLocalStorage(LOCAL_STORAGE.debugEnabled, false) === "+++"; // +++ is the secret code :)
    this.activeTouches = {};
    this.holdHandler = null;
    this.scale = () => {
      const scale = document.getElementById("video-container")?.getAttribute("data-scale") || 1;
      return parseFloat(scale);
    };
  }

  sendPayload = (payload) => {
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(payload);
    }
  };

  sendBytes = (buffer) => {
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(buffer);
    }
  };

  simulateKeyPress(key, keyCode, code) {
    let payload = MessageHelper.generateKeyboardPayload(key, keyCode, code, false);
    this.sendPayload(payload);
    payload = MessageHelper.generateKeyboardPayload(key, keyCode, code, true);
    this.sendPayload(payload);
  }

  keyDownHandler = (e) => {
    if (!this.debugEnabled && (e.metaKey || e.ctrlKey) && e.shiftKey && e.keyCode === 27) {
      return;
    }

    if (this.disableKeyboardActions) {
      return;
    }

    if (this.excludedKeys.includes(e.key)) {
      return;
    }

    this.pressedKeys.push(e);

    if (!this.preventExcludedKeys.includes(e.key)) {
      e.preventDefault();
    }

    if (e.key === "Meta" && this.switchCmdWithCtrl) {
      this.sendPayload(CTRL_PAYLOAD(false));
      this.canPaste = true;
      return;
    } else if (e.key === "Control" && this.switchCmdWithCtrl) {
      this.sendPayload(META_PAYLOAD(false));
      return;
    } else if (e.key === "Control") {
      this.canPaste = true;
    } else if (e.key === "Alt") {
      this.altKeyPressed = true;
    } else if (e.key === "CapsLock" && isMacOS) {
      setTimeout(() => {
        this.sendPayload(CAPSLOCK_PAYLOAD(true));
      }, 300);
    }

    if (this.disableMetaKey && e.key === "Meta") {
      return;
    }

    if (!isFirefox && this.canPaste && e.code === "KeyV") {
      navigator.clipboard.readText().then((text) => {
        if (text === " ") {
          return;
        }
        const clipboardPayload = MessageHelper.generatePasteToRemoteEventPayload(text, isSafari);
        this.sendPayload(clipboardPayload);
        if (isSafari) {
          this.sendPayload(CTRL_PAYLOAD(true));
        }
      });

      if (!isSafari) {
        setTimeout(() => {
          const payload = MessageHelper.generateKeyboardPayload(e.key, e.keyCode || e.which, e.code, false);
          this.sendPayload(payload);
        }, 20);
      }
      return;
    }

    if (e.key !== "Alt" && this.altKeyPressed && !e.altKey) {
      this.sendPayload(ALT_PAYLOAD(true));
      this.altKeyPressed = false;
    }
    const payload = MessageHelper.generateKeyboardPayload(e.key, e.keyCode || e.which, e.code, false);
    this.sendPayload(payload);
  };

  keyUpHandler = (e) => {
    if (this.disableKeyboardActions) {
      return;
    }

    if (this.excludedKeys.includes(e.key)) {
      return;
    }

    if (!this.preventExcludedKeys.includes(e.key)) {
      e.preventDefault();
    }

    if (e.key === "Meta" && this.switchCmdWithCtrl) {
      this.sendPayload(CTRL_PAYLOAD(true));
      this.canPaste = false;
      return;
    } else if (e.key === "Control" && this.switchCmdWithCtrl) {
      this.sendPayload(META_PAYLOAD(true));
      return;
    } else if (e.key === "Control") {
      this.canPaste = false;
    } else if (e.key === "Alt") {
      this.altKeyPressed = false;
    } else if (e.key === "CapsLock" && isMacOS) {
      this.sendPayload(CAPSLOCK_PAYLOAD(false));
      setTimeout(() => {
        this.sendPayload(CAPSLOCK_PAYLOAD(true));
      }, 300);
      return;
    }

    const payload = MessageHelper.generateKeyboardPayload(e.key, e.keyCode || e.which, e.code, true);
    this.sendPayload(payload);
    this.removePressedKey(e);
  };

  calculateMouseXandY = (x, y) => {
    let playerX = this.player.width;
    let playerY = this.player.height;

    if (this.customResolutionEnabled) {
      playerX = this.width;
      playerY = this.height;
    }

    let ratio = Math.max(1.0, playerX / 2560, playerY / 1600);
    if (playerX === 3840 && playerY === 2160) {
      ratio = 1;
    }

    if (playerX === 4096 && playerY === 2160) {
      ratio = 1;
    }

    playerX /= ratio;
    playerY /= ratio;

    const cx = Math.trunc((playerX * x) / this.player.offsetWidth);
    const cy = Math.trunc((playerY * y) / this.player.offsetHeight);
    return { cx, cy };
  };

  // This should be separate from mouseHandler
  mouseMoveHandler = (e) => {
    if (this.disableKeyboardActions) {
      return;
    }
    e.preventDefault();

    let x = -1;
    let y = -1;
    let cx = -1;
    let cy = -1;
    if (e && e.offsetX !== undefined && e.offsetY !== undefined) {
      x = e.clientX - this.player.getBoundingClientRect().left;
      y = e.clientY - this.player.getBoundingClientRect().top;

      const mousePoints = this.calculateMouseXandY(x, y);
      cx = mousePoints.cx;
      cy = mousePoints.cy;
    }
    if (this.gameMode) {
      cx = e.movementX;
      cy = e.movementY;
    }
    const payload = MessageHelper.generateMouseEventPayload(cx, cy, 0, 0, null, false, this.gameMode);
    this.sendPayload(payload);
  };

  mouseHandler = (e) => {
    if (this.disableKeyboardActions) {
      return;
    }
    e.preventDefault();

    let x = -1;
    let y = -1;
    let cx = -1;
    let cy = -1;

    const isUp = e.type === "mouseup";
    const clickType = e.which || null;
    if (e && e.offsetX !== undefined && e.offsetY !== undefined) {
      x = e.clientX - this.player.getBoundingClientRect().left;
      y = e.clientY - this.player.getBoundingClientRect().top;

      const mousePoints = this.calculateMouseXandY(x, y);
      cx = mousePoints.cx;
      cy = mousePoints.cy;
    }

    if (this.gameMode) {
      cx = e.movementX;
      cy = e.movementY;
    }
    // 1 is left click, 3 is right click
    const payload = MessageHelper.generateMouseEventPayload(cx, cy, 0, 0, clickType, isUp, this.gameMode);
    this.sendPayload(payload);
  };

  mouseWheelHandler = (e) => {
    e.preventDefault();
    const normalizedData = this.normalizeScrollEvent(e);
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - this.previousScrollTime;

    const deltaX = (normalizedData.spinX / (isFirefox ? 6 : 3)) * 120;
    const deltaY = (normalizedData.spinY / (isFirefox ? 6 : 3)) * 120;
    if (Math.abs(deltaY) >= 120 && Math.abs(deltaX) >= 120) {
      this.mouseDeltaX = deltaX;
      this.mouseDeltaY = deltaY;
    } else {
      this.mouseDeltaX += deltaX * 0.7;
      this.mouseDeltaY += deltaY * 0.7;
      if (timeDiff < 40 && Math.abs(this.mouseDeltaX) < 120 && Math.abs(this.mouseDeltaY) < 120) {
        return;
      }
    }

    this.previousScrollTime = currentTime;
    let x = -1;
    let y = -1;
    let cx = -1;
    let cy = -1;
    if (e && e.offsetX !== undefined && e.offsetY !== undefined) {
      x = e.offsetX;
      y = e.offsetY;

      const mousePoints = this.calculateMouseXandY(x, y);
      cx = mousePoints.cx;
      cy = mousePoints.cy;
    }
    if (this.gameMode) {
      cx = e.movementX;
      cy = e.movementY;
    }
    if (Math.abs(this.mouseDeltaY) > 5) {
      const payloadScrollY = MessageHelper.generateMouseEventPayload(
        cx,
        cy,
        0,
        this.mouseDeltaY * this.verticalScrollDirection,
        null,
        false,
        this.gameMode,
      );
      this.sendPayload(payloadScrollY);
    }
    if (Math.abs(this.mouseDeltaX) > 5) {
      const payloadScrollX = MessageHelper.generateMouseEventPayload(
        cx,
        cy,
        this.mouseDeltaX,
        0,
        null,
        false,
        this.gameMode,
      );
      this.sendPayload(payloadScrollX);
    }
    this.mouseDeltaY = 0;
    this.mouseDeltaX = 0;
  };

  mouseOutHandler = (e) => {
    e.preventDefault();
    this.buttonMask = 0;
  };

  gamepadHandler = (e) => {
    // since e is directly received in the format to be sent over the websocket
    this.sendPayload(JSON.stringify(e));
  };

  touchHandler = (e) => {
    if ((e.touches.length > 1 && !touchStarted) || (e.touches.length === 0 && !touchStarted)) {
      // Logger.log("Peripherals|touchHandler dismissed", e, touchStarted);
      return;
    }

    touchStarted = true;
    e.preventDefault();
    // Logger.log("Peripherals|touchHandler", e, e.type);

    let x = -1;
    let y = -1;
    let cx = -1;
    let cy = -1;

    const isUp = e.type === "touchend";
    const isDown = e.type === "touchstart";
    // Clear previous timer for right click
    clearTimeout(this.rightClickInvervId);

    // Second finger touch
    if (e.touches.length > 1 && touchStarted) {
      touchStarted = false;
      const mouseUpPayload = MessageHelper.generateMouseEventPayload(cx, cy, 0, 0, 1, true);
      this.sendPayload(mouseUpPayload);
      return;
    }

    if (e.changedTouches !== undefined) {
      x = e.changedTouches[0].clientX - this.player.getBoundingClientRect().left;
      y = e.changedTouches[0].clientY - this.player.getBoundingClientRect().top;

      const mousePoints = this.calculateMouseXandY(x, y);
      cx = mousePoints.cx;
      cy = mousePoints.cy;
    }
    // 1 is left click, 3 is right click
    const payload = MessageHelper.generateMouseEventPayload(cx, cy, 0, 0, 1, isUp);

    // Imitate Double click
    if (isDown && doubleClick) {
      Logger.log("Peripherals|touchHandler doubleclick", e, e.type);
      doubleClick = false;
      const mouseUpPayload = MessageHelper.generateMouseEventPayload(cx, cy, 0, 0, 1, true);
      // Double click
      this.sendPayload(payload);
      this.sendPayload(mouseUpPayload);
      this.sendPayload(payload);
      this.sendPayload(mouseUpPayload);
    } else {
      if (isDown) {
        doubleClick = true;
      }
      this.sendPayload(payload);
      clearInterval(this.doubleClickInterval);
      this.doubleClickInterval = setTimeout(() => {
        doubleClick = false;
      }, TOUCH_DOUBLE_CLICK_TIME);
    }

    // To imitate right click in touch screens -> down and up mouse clicks send with seperate payloads
    if (isDown) {
      this.touchStarted = false;
      this.rightClickInvervId = setTimeout(() => {
        const rightClickPayloadDown = MessageHelper.generateMouseEventPayload(cx, cy, 0, 0, 3, false);
        this.sendPayload(rightClickPayloadDown);
        const rightClickPayloadUp = MessageHelper.generateMouseEventPayload(cx, cy, 0, 0, 3, true);
        this.sendPayload(rightClickPayloadUp);
      }, TOUCH_RIGHT_CLICK_TIME);
    }
  };

  touchMoveHandler = (e) => {
    if (e.changedTouches.length > 1 || e.touches.length > 1 || (e.touches.length === 0 && !touchStarted)) {
      return;
    }

    e.preventDefault();

    let x = -1;
    let y = -1;
    let cx = -1;
    let cy = -1;
    // Clear previous timer for right click
    clearTimeout(this.rightClickInvervId);

    if (e.changedTouches !== undefined) {
      x = e.changedTouches[0].pageX;
      y = e.changedTouches[0].pageY;
      cx = Math.trunc((this.player.width * x) / window.innerWidth);
      cy = Math.trunc((this.player.height * y) / window.innerHeight);
    }
    const payload = MessageHelper.generateMouseEventPayload(cx, cy, 0, 0, null, false);
    this.sendPayload(payload);
  };

  touchSwipeHandler = (e) => {
    e.preventDefault();
    let x = -1;
    let y = -1;
    let cx = -1;
    let cy = -1;

    if (e.changedTouches !== undefined) {
      x = e.changedTouches[0].clientX - this.player.getBoundingClientRect().left;
      y = e.changedTouches[0].clientY - this.player.getBoundingClientRect().top;

      const mousePoints = this.calculateMouseXandY(x, y);
      cx = mousePoints.cx;
      cy = mousePoints.cy;
    }
    const deltaY = e.velocityY * this.verticalScrollDirection * 10;
    const deltaX = e.velocityX * -10;

    if (Math.abs(deltaY) > 1) {
      const payloadScrollY = MessageHelper.generateMouseEventPayload(cx, cy, 0, deltaY, null, false);
      this.sendPayload(payloadScrollY);
    }
    if (Math.abs(deltaX) > 1) {
      const payloadScrollX = MessageHelper.generateMouseEventPayload(cx, cy, deltaX, 0, null, false);
      this.sendPayload(payloadScrollX);
    }
  };

  windowsResizeHandler = (width, height, retryConnectionCount = 0) => {
    const payload = MessageHelper.generateWindowResizeEventPayload(width, height, retryConnectionCount);
    this.sendPayload(payload);
  };

  setVerticalScrollDirection = (inverseDirection) => {
    if (inverseDirection) {
      this.verticalScrollDirection = 1;
    } else {
      this.verticalScrollDirection = -1;
    }
  };

  setSwitchCmdCtrlPreference = (preference) => {
    this.switchCmdWithCtrl = preference;
  };

  removePressedKey = (e) => {
    for (let index = 0; index < this.pressedKeys.length; index += 1) {
      const pressedKey = this.pressedKeys[index];
      if (pressedKey.key === e.key) {
        this.pressedKeys.splice(index, 1);
        return;
      }
    }
  };

  onFocus = () => {
    for (let index = 0; index < this.pressedKeys.length; index += 1) {
      const e = this.pressedKeys[index];
      const payload = MessageHelper.generateKeyboardPayload(e.key, e.keyCode || e.which, e.code, true);
      if (this.switchCmdWithCtrl) {
        if (e.key === "Meta") {
          this.sendPayload(CTRL_PAYLOAD(true));
        } else if (e.key === "Control") {
          this.sendPayload(META_PAYLOAD(true));
        }
      } else {
        this.sendPayload(payload);
      }
    }
    this.sendPayload(META_PAYLOAD(true));
    this.sendPayload(CTRL_PAYLOAD(true));
    this.sendPayload(ALT_PAYLOAD(true));
    this.pressedKeys = [];
    this.altKeyPressed = false;
  };

  setCustomResolution = (x, y) => {
    if (!x || !y || x === 0 || y === 0) {
      return;
    }
    this.customResolutionEnabled = true;
    this.width = x;
    this.height = y;
  };

  getKeyByEvent = (e) => {
    const key = e.which || e.keyCode;
    return key;
  };

  static preventEventDefault(e) {
    e.preventDefault();
    return false;
  }

  setDisableKeyboardActions = (flag) => {
    this.disableKeyboardActions = flag;
  };

  normalizeScrollEvent = (event) => {
    const PIXEL_STEP = 10;
    const LINE_HEIGHT = 40;
    const PAGE_HEIGHT = 800;

    let sX = 0;
    let sY = 0;
    let pX = 0;
    let pY = 0; // pixelX, pixelY

    // Legacy
    if ("detail" in event) {
      sY = event.detail;
    }
    if ("wheelDelta" in event) {
      sY = -event.wheelDelta / 120;
    }
    if ("wheelDeltaY" in event) {
      sY = -event.wheelDeltaY / 120;
    }
    if ("wheelDeltaX" in event) {
      sX = -event.wheelDeltaX / 120;
    }

    // side scrolling on FF with DOMMouseScroll
    if ("axis" in event && event.axis === event.HORIZONTAL_AXIS) {
      sX = sY;
      sY = 0;
    }

    pX = sX * PIXEL_STEP;
    pY = sY * PIXEL_STEP;

    if ("deltaY" in event) {
      pY = event.deltaY;
    }
    if ("deltaX" in event) {
      pX = event.deltaX;
    }

    if ((pX || pY) && event.deltaMode) {
      if (event.deltaMode === 1) {
        // delta in LINE units
        pX *= LINE_HEIGHT;
        pY *= LINE_HEIGHT;
      } else {
        // delta in PAGE units
        pX *= PAGE_HEIGHT;
        pY *= PAGE_HEIGHT;
      }
    }

    // Fall-back if spin cannot be determined
    if (pX && !sX) {
      sX = pX < 1 ? -1 : 1;
    }
    if (pY && !sY) {
      sY = pY < 1 ? -1 : 1;
    }

    return {
      spinX: sX,
      spinY: sY,
      pixelX: pX,
      pixelY: pY,
    };
  };

  changeGameMode = (mode) => {
    this.gameMode = mode;
  };

  simulateTouchStart = (e) => {
    document.activeElement.blur();
    e.preventDefault();
    const touches = [];
    if (e.changedTouches !== undefined) {
      for (let index = 0; index < e.changedTouches.length; index += 1) {
        const touch = e.changedTouches[index];
        if (!touches.find((x) => x.i === Math.abs(touch.identifier))) {
          const x = (touch.clientX - this.player.getBoundingClientRect().left) / this.scale();
          const y = (touch.clientY - this.player.getBoundingClientRect().top) / this.scale();

          const mousePoints = this.calculateMouseXandY(x, y);
          const { cx, cy } = mousePoints;
          touches.push(MessageHelper.generateTouch(Math.abs(touch.identifier), cx, cy, false, false, false));
        }
      }
    }
    if (e.touches !== undefined) {
      for (let index = 0; index < e.touches.length; index += 1) {
        const touch = e.touches[index];
        if (!touches.find((x) => x.i === Math.abs(touch.identifier))) {
          const x = (touch.clientX - this.player.getBoundingClientRect().left) / this.scale();
          const y = (touch.clientY - this.player.getBoundingClientRect().top) / this.scale();

          const mousePoints = this.calculateMouseXandY(x, y);
          const { cx, cy } = mousePoints;
          touches.push(MessageHelper.generateTouch(Math.abs(touch.identifier), cx, cy, false, false, true));
        }
      }
    }
    Logger.log("Peripherals|simulateTouchStart", touches);
    const payload = MessageHelper.generateTouchEventMessage(touches);
    this.sendPayload(payload);
    touches.forEach((touch) => {
      this.activeTouches[touch.i] = touch;
    });
  };

  simulateTouchEnd = (e) => {
    e.preventDefault();
    const touches = [];
    const movedTouches = [];
    if (e.changedTouches !== undefined) {
      for (let index = 0; index < e.changedTouches.length; index += 1) {
        const touch = e.changedTouches[index];
        if (!touches.find((x) => x.i === Math.abs(touch.identifier))) {
          const x = (touch.clientX - this.player.getBoundingClientRect().left) / this.scale();
          const y = (touch.clientY - this.player.getBoundingClientRect().top) / this.scale();

          const mousePoints = this.calculateMouseXandY(x, y);
          const { cx, cy } = mousePoints;
          touches.push(MessageHelper.generateTouch(Math.abs(touch.identifier), cx, cy, false, true, false));
          movedTouches.push(MessageHelper.generateTouch(Math.abs(touch.identifier), cx, cy, false, false, true));
        }
        delete this.activeTouches[Math.abs(touch.identifier)];
      }
    }
    if (e.touches !== undefined) {
      for (let index = 0; index < e.touches.length; index += 1) {
        const touch = e.touches[index];
        if (!touches.find((x) => x.i === Math.abs(touch.identifier))) {
          const x = (touch.clientX - this.player.getBoundingClientRect().left) / this.scale();
          const y = (touch.clientY - this.player.getBoundingClientRect().top) / this.scale();

          const mousePoints = this.calculateMouseXandY(x, y);
          const { cx, cy } = mousePoints;
          const touchPayload = MessageHelper.generateTouch(Math.abs(touch.identifier), cx, cy, false, false, true);
          movedTouches.push(MessageHelper.generateTouch(Math.abs(touch.identifier), cx, cy, false, false, true));
          touches.push(touchPayload);
          this.activeTouches[touchPayload.i] = touchPayload;
        }
      }
    }
    Logger.log("Peripherals|simulateTouchEnd", touches);
    const payload = MessageHelper.generateTouchEventMessage(touches);
    const movedPayload = MessageHelper.generateTouchEventMessage(movedTouches);
    this.sendPayload(movedPayload);
    this.sendPayload(payload);
    this.setHoldDetectionCallbacks();
  };

  simulateTouchMove = (e) => {
    Logger.log("Peripherals|simulateTouchMove", e);
    e.preventDefault();
    const touches = [];
    if (e.touches !== undefined) {
      for (let index = 0; index < e.touches.length; index += 1) {
        const touch = e.touches[index];
        const x = (touch.clientX - this.player.getBoundingClientRect().left) / this.scale();
        const y = (touch.clientY - this.player.getBoundingClientRect().top) / this.scale();

        const mousePoints = this.calculateMouseXandY(x, y);
        const { cx, cy } = mousePoints;
        touches.push(MessageHelper.generateTouch(Math.abs(touch.identifier), cx, cy, false, false, true));
      }
      const payload = MessageHelper.generateTouchEventMessage(touches);
      this.sendPayload(payload);
      touches.forEach((touch) => {
        this.activeTouches[touch.i] = touch;
      });
      this.setHoldDetectionCallbacks();
    }
  };

  simulateTouchCancelled = (e) => {
    e.preventDefault();
    this.simulateTouchEnd(e);
  };

  simulatePointStart = (e) => {
    if (e.pointerType === "mouse" || e.pointerType === "touch") {
      return;
    }
    Logger.log("Peripherals|simulatePointStart", e);
    e.preventDefault();
    const touches = [];

    const x = e.clientX;
    const y = e.clientY;

    const mousePoints = this.calculateMouseXandY(x, y);
    const { cx, cy } = mousePoints;
    touches.push(MessageHelper.generateTouch(Math.abs(e.pointerId), cx, cy, false, false, false));
    const payload = MessageHelper.generateTouchEventMessage(touches);
    this.sendPayload(payload);
  };

  simulatePointEnd = (e) => {
    if (e.pointerType === "mouse" || e.pointerType === "touch") {
      return;
    }
    Logger.log("Peripherals|simulatePointEnd", e);
    e.preventDefault();
    const touches = [];

    const x = e.clientX;
    const y = e.clientY;

    const mousePoints = this.calculateMouseXandY(x, y);
    const { cx, cy } = mousePoints;
    touches.push(MessageHelper.generateTouch(Math.abs(e.pointerId), cx, cy, false, true, false));
    const payload = MessageHelper.generateTouchEventMessage(touches);
    this.sendPayload(payload);
  };

  simulatePointMove = (e) => {
    if (e.pointerType === "mouse" || e.pointerType === "touch") {
      return;
    }
    Logger.log("Peripherals|simulatePointMove", e);
    e.preventDefault();
    const touches = [];

    const x = e.clientX;
    const y = e.clientY;

    const mousePoints = this.calculateMouseXandY(x, y);
    const { cx, cy } = mousePoints;
    touches.push(MessageHelper.generateTouch(Math.abs(e.pointerId), cx, cy, false, false, true));
    const payload = MessageHelper.generateTouchEventMessage(touches);
    this.sendPayload(payload);
  };

  simulatePointCancelled = (e) => {
    if (e.pointerType === "mouse" || e.pointerType === "touch") {
      return;
    }
    Logger.log("Peripherals|simulatePointCancelled", e);
    e.preventDefault();
    const touches = [];

    const x = e.clientX;
    const y = e.clientY;

    const mousePoints = this.calculateMouseXandY(x, y);
    const { cx, cy } = mousePoints;
    touches.push(MessageHelper.generateTouch(Math.abs(e.pointerId), cx, cy, true, false, false));
    const payload = MessageHelper.generateTouchEventMessage(touches);
    this.sendPayload(payload);
  };

  simulatePointDefault = (e) => {
    e.preventDefault();
  };

  setHoldDetectionCallbacks = () => {
    if (this.activeTouches.length < 0) {
      return;
    }
    this.clearHoldDetectionCallbacks();
    this.holdHandler = setTimeout(() => {
      this.sendHoldEvent();
    }, 500);
  };

  clearHoldDetectionCallbacks = () => {
    clearTimeout(this.holdHandler);
  };

  sendHoldEvent = () => {
    const touches = [];
    Object.values(this.activeTouches).forEach((touch) => {
      touches.push(MessageHelper.generateTouch(touch.i, touch.x, touch.y, false, false, true));
    });
    const payload = MessageHelper.generateTouchEventMessage(touches);
    this.sendPayload(payload);
    this.setHoldDetectionCallbacks();
  };

  setDisableMetaKey = (disable) => {
    this.disableMetaKey = disable;
  };

  clearIntervals = () => {
    Object.values(this.timeOutHandlers).forEach((id) => {
      this.cancelHoldInterval(id);
    });
    clearTimeout(this.holdHandler);
  };
}
