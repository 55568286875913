import React, { useState, useEffect, useRef } from "react";
import { DashboardSubScreenLayout, useAPIRequest, useAPI, getItemFromLocalStorage, isMobile } from "Utils";
import { Button, ConfirmationModal, Announcement, Modal } from "UI";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { REQUEST_STATUS, LOCAL_STORAGE } from "Constants/global.constants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import routes from "Constants/Route.constants";
import { STREAM_ERROR_CODES } from "Constants/AppStreaming.constants";
import StreamsOnboardingModal from "Components/AppStreaming/Modals/OnboardingModals/StreamsStreamsOnboardingModal.component";
import { STREAMS_APPS_DUMMY_DATA, STREAMS_DUMMY_DATA } from "Constants/StreamsOnboarding.constants";
import CreateStreamModal from "../Modals/CreateStreamModal/CreateStreamModal.component";
import ShareStreamModal from "../Modals/ShareStreamModal/ShareStreamModal.component";
import StreamError from "../Modals/StreamError/StreamError.component";
import StreamTable from "./StreamTable/StreamTable.component";
import AppListCard from "./AppListCard/AppListCard.component";
import EmptyContent from "../EmptyContent/EmptyContent.component";

import "./AppStreamingStreams.styles.scss";

const AppStreamingStreams = ({
  account,
  appStreaming,
  translate,
  createStreamAPI,
  getStreamsAPI,
  getStreamCapacitiesAPI,
  getAppCapacitiesAPI,
  pauseStreamAPI,
  updateStreamAPI,
  getProductOnboardingAPI,
  history,
}) => {
  const location = useLocation();
  const selectedAppFromPath = location?.selectedApp || {};
  const selectedAppId = new URLSearchParams(window.location.search).get("application");

  const [showCreateStreamModal, setShowCreateStreamModal] = useState(!!selectedAppId);
  const [selectedApp, setSelectedApp] = useState(selectedAppFromPath);
  const [selectedStream, setSelectedStream] = useState(null);

  const [showDeleteStreamConfirmationModal, setShowDeleteStreamConfirmationModal] = useState(false);
  const [showShareStreamModal, setShowShareStreamModal] = useState(false);
  const [showStreamsOnboardingModal, setShowStreamsOnboardingModal] = useState(null);
  const [onboardingModalCurrentStep, setOnboardingModalCurrentStep] = useState(0);
  const [showStreamsDummyData, setShowStreamsDummyData] = useState(false);

  const [showStreamConfigAnnouncement, setShowStreamConfigAnnouncement] = useState(
    getItemFromLocalStorage(LOCAL_STORAGE.showStreamConfigAnnouncement, true),
  );
  const [showStartStreamErrorModal, setShowStartStreamErrorModal] = useState(false);

  const containerRef = useRef(null);
  const balance = parseFloat(
    useSelector((state) => state.appStreaming.getVendorAccountCTX?.data?.attributes?.balance),
    10,
  );

  const { data: productOnboardingStatesData, status: productOnboardingStatesStatus } =
    account?.productOnboardingCTX || {};
  const checkCreatedStream = productOnboardingStatesData?.streams?.stream_created;

  useEffect(() => {
    if (!_.isEmpty(selectedAppFromPath)) {
      if (pendingPaymentApplication || balance <= 0.67) {
        setShowStartStreamErrorModal(true);
      } else {
        setShowCreateStreamModal(true);
        setSelectedApp(selectedAppFromPath);
      }
    }
  }, []);

  const { data: activeApplications, loading: isActiveApplicationsLoading } = useAPIRequest({
    type: "get",
    endpoint: APP_STREAMING_API_ENDPOINTS.ACTIVE_APPLICATIONS,
  });

  const { api: deleteStreamAPI } = useAPI({
    type: "delete",
    endpoint: APP_STREAMING_API_ENDPOINTS.DELETE_STREAM(selectedStream?.id),
    onSuccess: () => {
      getStreamsAPI();
    },
  });

  const {
    createStreamCTX,
    getStreamsCTX,
    getCurrentPlanCTX,
    getApplicationsCTX,
    appCapacitiesCTX,
    pauseStreamCTX,
    updateStreamCTX,
  } = appStreaming;

  const { applications } = useSelector((state) => state.appStreaming.getApplicationsCTX.data) || {};
  const pendingPaymentApplication = applications?.find((app) => app.attributes.status === "pending_payment");

  useEffect(() => {
    if (selectedApp?.id) getAppCapacitiesAPI(selectedApp?.id);
  }, [selectedApp]);

  useEffect(() => {
    if (selectedAppId && getStreamsCTX.status === REQUEST_STATUS.SUCCESS) {
      const app = getStreamsCTX?.data?.applications?.find((app) => app.id === selectedAppId);
      if (app) setSelectedApp(app);
    }
  }, [selectedAppId, getStreamsCTX.status]);

  useEffect(() => {
    if (selectedStream && updateStreamCTX.status === REQUEST_STATUS.FAILURE) {
      setShowStartStreamErrorModal(true);
    }
  }, [updateStreamCTX.status]);

  useEffect(() => {
    getProductOnboardingAPI();
  }, []);

  const capacities = appCapacitiesCTX.data?.[selectedApp.id];

  const appsWithStreams =
    getStreamsCTX.data?.applications?.filter((application) => application.attributes.streams.length !== 0) || [];
  const appWithCapacities =
    appsWithStreams?.filter(
      (app) =>
        app.attributes.streams.reduce(
          (prevSum, currStream) => prevSum + currStream.attributes.sum_of_all_capacities,
          0,
        ) !== 0,
    ) || [];
  useEffect(() => {
    if (showStreamConfigAnnouncement && getStreamsCTX.status === REQUEST_STATUS.SUCCESS) {
      const streams = [].concat(...appWithCapacities.map((app) => app.attributes.streams));
      if (
        streams.some((stream) => !stream.attributes.capacities?.[0]?.attributes?.capacity_type?.includes("on_demand"))
      )
        setShowStreamConfigAnnouncement(false);
    }
  }, [getStreamsCTX.status]);

  useEffect(() => {
    if (showStreamsOnboardingModal === null && productOnboardingStatesStatus === REQUEST_STATUS.SUCCESS) {
      const shouldDisplayAfterCreationOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showStreamsOnboardingModal, true);

      setTimeout(() => {
        setShowStreamsOnboardingModal(checkCreatedStream ? false : shouldDisplayAfterCreationOnboarding);
      }, 500);
    }
  }, [productOnboardingStatesStatus]);

  const displayOnboarding = showStreamsOnboardingModal
    ? onboardingModalCurrentStep < 1
    : appWithCapacities.length === 0;

  const noAppExists = activeApplications?.applications?.length === 0;
  const streamCount =
    appWithCapacities?.reduce((prevSum, currApp) => prevSum + currApp?.attributes?.streams?.length, 0) || 0;

  const renderStreamTables = () => {
    const streamsData = showStreamsOnboardingModal && showStreamsDummyData ? STREAMS_DUMMY_DATA : appWithCapacities;

    return streamsData.map((app) => {
      return (
        <StreamTable
          key={app.id}
          app={app}
          selectedStream={selectedStream}
          setSelectedApp={setSelectedApp}
          setSelectedStream={setSelectedStream}
          setShowDeleteStreamConfirmationModal={setShowDeleteStreamConfirmationModal}
          setShowShareStreamModal={setShowShareStreamModal}
          getStreamCapacitiesAPI={getStreamCapacitiesAPI}
          pauseStreamAPI={pauseStreamAPI}
          pauseStreamCTX={pauseStreamCTX}
          updateStreamCTX={updateStreamCTX}
          updateStreamAPI={updateStreamAPI}
          getStreamsAPI={getStreamsAPI}
          vendorType={appStreaming?.getVendorAccountCTX?.data?.attributes?.vendor_type}
          showStreamsOnboardingModal={showStreamsOnboardingModal}
          translate={translate}
        />
      );
    });
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("appStreamingStreams.header.title")}
      translate={translate}
      waitFor={[!isActiveApplicationsLoading, getStreamsCTX.data !== null]}
      pending={!getStreamsCTX.data}
      appStreaming
    >
      {displayOnboarding ? (
        <>
          <EmptyContent
            headerText={
              (showStreamsOnboardingModal ? false : noAppExists)
                ? translate("appStreamingStreams.initialContent.noAppExists.header")
                : translate("appStreamingStreams.initialContent.header")
            }
            descriptionText={
              (showStreamsOnboardingModal ? false === 0 : noAppExists)
                ? translate("appStreamingStreams.initialContent.noAppExists.description")
                : translate("appStreamingStreams.initialContent.description")
            }
            buttonText={translate("appStreamingStreams.initialContent.noAppExists.action")}
            onButtonClick={() => history.push(routes.appStreamingHome)}
            withoutButton={!noAppExists}
          >
            {(activeApplications?.applications?.length !== 0 ||
              (showStreamsOnboardingModal && onboardingModalCurrentStep >= 0)) && (
              <div className="app-list-cards-container">
                <p>Choose App</p>
                {(showStreamsOnboardingModal ? STREAMS_APPS_DUMMY_DATA : activeApplications)?.applications.map(
                  (app) => {
                    return (
                      <AppListCard
                        key={app.id}
                        logo={app.attributes.logo_url || app.attributes.dummy_logo_url}
                        name={app.attributes.name}
                        bundledApp={app.attributes.is_bundled_app}
                        onClick={() => {
                          if (!showStreamsOnboardingModal) {
                            setSelectedApp(app);
                            setShowCreateStreamModal(true);
                          }
                        }}
                        highlightClass={app.attributes.highlightClass}
                      />
                    );
                  },
                )}
              </div>
            )}
          </EmptyContent>
        </>
      ) : (
        <div className="app-streaming-streams-container" ref={containerRef}>
          {streamCount < 100 && (
            <div className="header-button">
              <div className="button-container">
                <Button
                  text={translate("appStreamingStreams.initialContent.action")}
                  iconLeft="plus"
                  onClick={() => {
                    if (pendingPaymentApplication || balance <= 0.67) {
                      setShowStartStreamErrorModal(true);
                    } else {
                      setShowCreateStreamModal(true);
                    }
                  }}
                  className="new-stream-button"
                />
              </div>
            </div>
          )}
          {renderStreamTables()}
        </div>
      )}

      {showCreateStreamModal && (
        <CreateStreamModal
          setShowCreateStreamModal={setShowCreateStreamModal}
          selectedApp={selectedApp}
          capacities={capacities}
          createStreamAPI={createStreamAPI}
          getStreamsAPI={getStreamsAPI}
          createStreamCTX={createStreamCTX}
          getCurrentPlanCTX={getCurrentPlanCTX}
          getApplicationsCTX={getApplicationsCTX}
          setSelectedStream={setSelectedStream}
          setSelectedApp={setSelectedApp}
          activeApplications={activeApplications}
          translate={translate}
          history={history}
          appCapacitiesCTX={appCapacitiesCTX}
        />
      )}
      {showDeleteStreamConfirmationModal && (
        <ConfirmationModal
          className="delete-stream-confirmation-modal"
          closeOnOverlayClick
          closeAction={() => setShowDeleteStreamConfirmationModal(false)}
          headerText={translate("appStreamingModals.deleteStream.header")}
          descriptionText={translate("appStreamingModals.deleteStream.description")}
          confirmText={translate("appStreamingModals.deleteStream.action")}
          confirmAction={() => {
            setShowDeleteStreamConfirmationModal(false);
            deleteStreamAPI();
          }}
          secondaryText={translate("appStreamingModals.deleteStream.secondaryAction")}
          secondaryAction={() => setShowDeleteStreamConfirmationModal(false)}
        />
      )}
      {showShareStreamModal && (
        <ShareStreamModal
          app={selectedApp}
          stream={selectedStream}
          onClose={() => {
            setShowShareStreamModal(false);
            setSelectedApp({});
          }}
          translate={translate}
        />
      )}
      {showStartStreamErrorModal && !showStreamsOnboardingModal && (
        <Modal
          className="start-stream-error-modal"
          closeOnOverlayClick
          closeAction={() => setShowStartStreamErrorModal(false)}
          topRightIcon="close"
          topRightIconAction={() => setShowStartStreamErrorModal(false)}
          descriptionText={
            <StreamError
              translate={translate}
              error={updateStreamCTX.client_code || STREAM_ERROR_CODES.balance_error}
              history={history}
              setShowModal={setShowStartStreamErrorModal}
            />
          }
          big
        />
      )}
      {showStreamsOnboardingModal && (
        <StreamsOnboardingModal
          setCurrentStep={setOnboardingModalCurrentStep}
          setShowStreamsDummyData={setShowStreamsDummyData}
          onExit={() => {
            setShowStreamsOnboardingModal(false);
            localStorage.setItem(LOCAL_STORAGE.showStreamsOnboardingModal, false);
          }}
          translate={translate}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default AppStreamingStreams;
