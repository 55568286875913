import React, { useLayoutEffect, useRef } from "react";
import nipplejs from "nipplejs";

import "./joystick.styles.scss";

const Joystick = ({ sendPayload }) => {
  const axis0 = useRef(0);
  const axis1 = useRef(0);
  const axis2 = useRef(0);
  const axis3 = useRef(0);

  useLayoutEffect(() => {
    const leftJoystick = nipplejs.create({
      mode: "static",
      position: { left: "10%", top: "80%" },
      color: "white",
      zone: document.getElementById("left-joystick"),
    });

    const rightJoystick = nipplejs.create({
      mode: "static",
      position: { left: "90%", top: "80%" },
      color: "white",
      zone: document.getElementById("right-joystick"),
    });

    leftJoystick.on("move", (_, nipple) => {
      axis0.current = nipple.vector.x;
      axis1.current = -nipple.vector.y;
    });

    leftJoystick.on("end", () => {
      axis0.current = 0;
      axis1.current = 0;
    });

    rightJoystick.on("move", (evt, nipple) => {
      axis2.current = nipple.vector.x;
      axis3.current = -nipple.vector.y;
    });

    rightJoystick.on("end", () => {
      axis2.current = 0;
      axis3.current = 0;
    });

    const interval = setInterval(() => {
      sendAxisPayloads();
    }, 40);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const sendAxisPayloads = () => {
    // { $type: Message Type, t: Game Pad Button Type, g: Game Pad Index, b: Axis, v: Axis Value },
    const payloads = [
      { $type: "24", t: 2, g: 0, b: 0, v: axis0.current },
      { $type: "24", t: 2, g: 0, b: 1, v: axis1.current },
      { $type: "24", t: 2, g: 0, b: 2, v: axis2.current },
      { $type: "24", t: 2, g: 0, b: 3, v: axis3.current },
    ];

    payloads.forEach((payload) => sendPayload(JSON.stringify(payload)));
  };

  return (
    <>
      <div className="left-joystick" id="left-joystick" />
      <div className="right-joystick" id="right-joystick" />
    </>
  );
};

export default Joystick;
