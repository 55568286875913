import React from "react";
import { Button } from "UI";
import routes from "Constants/Route.constants";
import logo from "Assets/images/logo/vg-logo-black.svg";
import NothingToShow from "Assets/images/vagon-nothing.png";
import { withRouter } from "react-router-dom";

import "./NotFound.styles.scss";

const NotFoundComponent = ({ history, location }) => {
  if (location.pathname.includes("stream")) {
    history.push(routes.appStreamingHome);
  }

  return (
    <div className="not-found-container">
      <img src={NothingToShow} className="nothing-img" />
      <div className="error-info-container">
        <img src={logo} alt="Vagon" className="logo" />
        <p className="something-went-wrong">We looked everywhere.</p>
        <p>
          Looks like this page is missing. If you need help, visit our{" "}
          <a href="http://help.vagon.io/en/" target="_blank" rel="noopener noreferrer">
            help page
          </a>
          .
        </p>
        <h1>404</h1>
        <Button
          text="Go to Home"
          className="vg-button"
          onClick={() => {
            history.push(routes.home);
          }}
          block
        />
      </div>
    </div>
  );
};

export default withRouter(NotFoundComponent);
