import React from "react";
import { ExpandableTable } from "UI";
import { minutesToReadableHoursMinutesSeconds } from "Utils/Helpers/functions.helpers";
import NoTransactionsImage from "Assets/images/organizations/no-content.png";

import "./AnalyticChartTable.styles.scss";

const EmptyContent = ({ translate }) => {
  return (
    <div className="no-data-content">
      <img src={NoTransactionsImage} />
      <div className="text-container">
        <h3>{translate("organizationUsageAnalytics.analyticChartTable.noData.header")}</h3>
        <p>{translate("organizationUsageAnalytics.analyticChartTable.noData.description")}</p>
      </div>
    </div>
  );
};

const UsageBar = ({ usage, maxUsage }) => {
  const percentageUsage = (usage / maxUsage) * 100;

  return (
    <div className="usage-bar-container">
      <div
        style={{
          width: `${percentageUsage}%`,
        }}
        className="usage-bar"
      >
        <span>{minutesToReadableHoursMinutesSeconds(usage)}</span>
      </div>
    </div>
  );
};

const AnalyticChartTable = ({ columns, data, isLoading, translate, isError }) => {
  const maxUsage = data?.reduce((acc, seat) => {
    const { usage } = seat;
    return usage > acc ? usage : acc;
  }, 0);

  const dataMapping = data?.map((item) => {
    const usage = <UsageBar usage={item.usage} maxUsage={maxUsage} />;

    return {
      ...item,
      usage,
    };
  });

  if (isError) {
    return (
      <div className="analytic-chart-table">
        <EmptyContent translate={translate} />
      </div>
    );
  }

  return (
    <ExpandableTable className="analytic-chart-table" isLoading={isLoading} columns={columns} data={dataMapping} />
  );
};

export default AnalyticChartTable;
