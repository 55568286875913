import React from "react";

import { classnames, Tracker } from "Utils";
import { Icon } from "UI";

import "./Button.styles.scss";

const Button = ({
  onClick,
  disabled,
  errorText,
  big,
  medium,
  block,
  textContained,
  icon,
  text,
  white,
  gray,
  basic,
  basicBabyPowder,
  blue,
  aqua,
  breath,
  eventType,
  color,
  className: classNameFromProps,
  id,
  iconLeft,
  buttonRef,
  outline,
  aquaDark,
  darkNavy,
  purpleLight,
  aquaDarkOutline,
  grayOutline,
  gray4Outline,
  rightContent,
  leftContent,
  clickable,
  transparent,
  ...rest
}) => {
  const className = classnames([
    "vg-button",
    classNameFromProps,
    onClick && "clickable",
    big && "big",
    medium && "medium",
    block && "block",
    textContained && "text-contained",
    gray && "gray",
    white && "white",
    basic && "basic",
    basicBabyPowder && "basicBabyPowder",
    blue && "blue",
    aqua && "aqua",
    aquaDark && "aqua-dark",
    darkNavy && "dark-navy",
    breath && "breath",
    disabled && "disabled",
    outline && "outline",
    aquaDarkOutline && "aqua-dark-outline",
    grayOutline && "gray-outline",
    gray4Outline && "gray-4-outline",
    clickable && "clickable",
    transparent && "transparent",
    purpleLight && "purple-light",
    color,
  ]);

  const handleButtonClick = (event) => {
    if (onClick) onClick(event);
  };

  return (
    <>
      {errorText && (
        <div className="vg-button-error">
          <Icon name="error" color="purple-main" />
          {errorText}
        </div>
      )}
      <button
        className={className}
        id={id || ""}
        type="button"
        disabled={disabled}
        onClick={handleButtonClick}
        ref={buttonRef}
        {...rest}
      >
        {leftContent && leftContent}
        {iconLeft && <Icon name={iconLeft} />}
        {text}
        {icon && <Icon name={icon} />}
        {rightContent && rightContent}
      </button>
    </>
  );
};

export default Button;
