import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import NoTransactionsImage from "Assets/images/organizations/no-content.png";
import { API_ENDPOINTS } from "Constants/api.constants";
import { convertDateFormat, apiGenerator } from "Utils";
import { capitalize, convertToSelectedTimeZone, localDateFormat } from "Utils/Helpers/functions.helpers";
import { ExpandableTable, HoverableTooltip, IconButton } from "UI";
import { WORKSTATION_STATUS } from "Constants/global.constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ORGANIZATION_ADMIN_ROUTES } from "Constants/Route.constants";
import { ORGANIZATION_STATS_FILTERED_DUMMY_DATA } from "Constants/teamsOnboarding.constants";
import { useSelector } from "react-redux";

const PER_PAGE = 15;

const connectionColumn = ({ usageAnalytics, dataCollected, onMonitoringClick }) => {
  if (dataCollected) {
    return (
      <div className="connection-column">
        <HoverableTooltip content={!usageAnalytics && "Data Collected"}>
          <IconButton
            name="monitoring"
            onClick={() => {
              if (!usageAnalytics) onMonitoringClick();
            }}
          />
        </HoverableTooltip>
      </div>
    );
  }
  return "";
};

const UsageAnalyticsEmptyContent = ({ translate }) => {
  return (
    <div className="no-data-content">
      <img src={NoTransactionsImage} />
      <div className="text-container">
        <h3>{translate("organizationStats.userSessionsTable.usageAnalyticsEmpty.header")}</h3>
        <p>{translate("organizationStats.userSessionsTable.usageAnalyticsEmpty.description")}</p>
      </div>
    </div>
  );
};

const UserSessionTable = ({
  startDate,
  endDate,
  userFilter,
  regionFilter,
  performanceFilter,
  showTeamsStatsOnboardingModal,
  onboardingModalCurrentStep,
  seatFilter,
  selectedSession,
  setSelectedSession,
  usageAnalytics,
  translate,
}) => {
  const history = useHistory();
  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};

  const {
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    data: sessionsData,
  } = useInfiniteQuery({
    queryKey: [
      API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS,
      startDate,
      endDate,
      userFilter,
      regionFilter,
      performanceFilter,
      seatFilter,
    ],
    queryFn: ({ pageParam = 1 }) => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS, {
        params: {
          start_at: startDate ? localDateFormat(startDate) : null,
          end_at: endDate ? localDateFormat(endDate) : null,
          user_id: userFilter?.id,
          region: regionFilter,
          machine_type_id: performanceFilter?.id,
          page: pageParam,
          seat_id: seatFilter?.id,
          per_page: PER_PAGE,
          bypass_date_filter: true,
        },
      }).then((res) => {
        return res.data;
      });
    },

    getNextPageParam: (lastPage) => lastPage.next_page,
  });

  const formatSessionsData = (sessionData) => {
    if (isLoading) return [];
    return sessionData?.map((session) => {
      const {
        start_at: startTime,
        user,
        region,
        duration,
        cost,
        was_activity_watcher_enabled: dataCollected,
        machine,
        end_at: endTime,
      } = session?.attributes;
      const { status } = machine?.attributes || {};

      const setClassName = () => {
        if (!selectedSession?.id) return "";
        if (+selectedSession.id !== +session.id) return "opacity-50";
        return "";
      };

      return {
        session: session.id,
        key: session.id,
        user: user?.attributes?.email,
        "data center": capitalize(region),
        date: startTime
          ? convertToSelectedTimeZone(
              `${convertDateFormat(startTime.substring(0, 10))} ${startTime.substring(
                startTime.indexOf("T") + 1,
                startTime.indexOf("T") + 6,
              )}`,
              userTimeZone,
            )
          : "",
        performance: session.attributes.machine_type.attributes.friendly_name,
        duration:
          !endTime && status === WORKSTATION_STATUS.RUNNING
            ? "Running"
            : new Date(duration * 1000).toISOString().substr(11, 8),
        usage: `$ ${cost}`,
        connection: connectionColumn({
          usageAnalytics,
          dataCollected,
          onMonitoringClick: () => {
            history.push({
              pathname: ORGANIZATION_ADMIN_ROUTES.organizationAdminUsageAnalytics,
              state: { session },
            });
          },
        }),
        onRowClick: setSelectedSession ? () => setSelectedSession(session) : null,
        className: setClassName(),
      };
    });
  };

  const sessions = sessionsData?.pages?.flatMap((page) => page.sessions) || {};

  const emptySessionData = showTeamsStatsOnboardingModal ? false : sessions.length === 0;
  const sessionData = showTeamsStatsOnboardingModal || sessions.length > 0;

  const sessionsColumns = [
    { name: "Session", weight: 30 },
    { name: "User", weight: 80 },
    { name: "Date", weight: 60 },
    { name: "Duration", weight: 40 },
    { name: "Data Center", weight: 50 },
    { name: "Performance", weight: 50 },
    { name: "Connection", weight: 10, customWeight: 10, hide: true },
  ];

  if (emptySessionData) {
    return (
      <div className="content-container-xd">
        <div className="organization-sessions-table">
          {usageAnalytics ? (
            <UsageAnalyticsEmptyContent translate={translate} />
          ) : (
            <div className="no-user-stats-content">
              <img src={NoTransactionsImage} />
              <span>{translate("organizationStats.userSessionsTable.noStats")}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="content-container-xd">
        {sessionData && (
          <ExpandableTable
            data={formatSessionsData(showTeamsStatsOnboardingModal ? ORGANIZATION_STATS_FILTERED_DUMMY_DATA : sessions)}
            columns={sessionsColumns}
            hideVerticalBar
            className="organization-sessions-table"
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            onPageEnd={() => {
              fetchNextPage();
            }}
          />
        )}
      </div>
    </>
  );
};

export default UserSessionTable;
