import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import dashboardTranslations from "Translations/dashboard.json";
import workstationTranslations from "Translations/workstation.json";
import filesTranslations from "Translations/files.json";
import appStreamingTranslations from "Translations/appStreaming.json";
import organizationsTranslations from "Translations/organizations.json";
import Tracker from "Utils/Helpers/tracker.helpers";

const UnAuthenticatedRoute = (props) => {
  const { component: Component, path, addTranslation } = props;

  useEffect(() => {
    Tracker.event({ type: "page_view" });
  }, [path]);

  useEffect(() => {
    addTranslation(workstationTranslations);
    addTranslation(filesTranslations);
    addTranslation(dashboardTranslations);
    addTranslation(appStreamingTranslations);
    addTranslation(organizationsTranslations);
  }, []); //eslint-disable-line

  return (
    <Translate>
      {({ translate }) => (
        <>
          <Route exact path={path} render={(props) => <Component {...props} translate={translate} />} />
        </>
      )}
    </Translate>
  );
};

export default withLocalize(UnAuthenticatedRoute);
