import React from "react";
import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { Translate } from "react-localize-redux";
import { ConfirmationModal } from "UI";
import { apiGenerator } from "Utils";

const ResetComputerModal = ({
  setShowResetComputerConfirmationModal,
  setShowMachineResetSuccessModal,
  getWorkstationsAPI,
  translate,
}) => {
  const resetWorkstationMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("post")(API_ENDPOINTS.WORKSTATION_RESET);
    },
    onSuccess: () => {
      setShowResetComputerConfirmationModal(false);
      setShowMachineResetSuccessModal(true);
      getWorkstationsAPI();
    },
  });

  const error = resetWorkstationMutation.isError;

  return (
    <ConfirmationModal
      headerText={translate("accountSettings.resetComputer.confirm.header")}
      descriptionText={translate("accountSettings.resetComputer.confirm.description")}
      errorText={error && <Translate id="accountSettings.resetComputer.error" />}
      confirmText={translate("accountSettings.resetComputer.confirm.confirm")}
      confirmAction={() => {
        resetWorkstationMutation.mutate();
      }}
      closeAction={() => setShowResetComputerConfirmationModal(false)}
      secondaryText={translate("accountSettings.resetComputer.confirm.secondary")}
      secondaryAction={() => {
        setShowResetComputerConfirmationModal(false);
      }}
      disableConfirmButton={resetWorkstationMutation.isLoading || resetWorkstationMutation.isSuccess || error}
      closeOnOverlayClick
    />
  );
};

export default ResetComputerModal;
