import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator, getItemFromLocalStorage, isMobile } from "Utils";
import { DivButton, Icon } from "UI";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";
import TeamsCreateTemplateOnboardingModal from "Components/Workstation/Modals/TeamsCreateTemplateOnboardingModal.component";
import { useSelector } from "react-redux";
import CreateTemplateHeader from "./CreateTemplateHeader/CreateTemplateHeader.component";
import NewTemplate from "./NewTemplate/NewTemplate.component";
import TemplateFromComputer from "./TemplateFromComputer/TemplateFromComputer.component";
import "./CreateTemplate.styles.scss";

const CreateTemplate = ({ history, translate }) => {
  const [onboardingModalCurrentStep, setOnboardingModalCurrentStep] = useState(0);
  const [showTeamsCreateTemplateOnboardingModal, setShowTeamsCreateTemplateOnboardingModal] = useState(null);
  const { productOnboardingCTX } = useSelector((state) => state.account);
  const checkTemplate = productOnboardingCTX?.data?.teams?.template_created;
  const { data: seatsData } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATIONS_SEATS_IMAGE_CREATABLE, "createTemplate"],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATIONS_SEATS_IMAGE_CREATABLE, {}).then((res) => {
        return res.data;
      });
    },
  });

  useEffect(() => {
    if (showTeamsCreateTemplateOnboardingModal === null && productOnboardingCTX.status === REQUEST_STATUS.SUCCESS) {
      const shouldDisplayAfterCreationOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showTeamsCreateTemplateOnboardingModal, true);

      setTimeout(() => {
        setShowTeamsCreateTemplateOnboardingModal(checkTemplate ? false : shouldDisplayAfterCreationOnboarding);
      }, 500);
    }
  }, [productOnboardingCTX]);

  const disableCreateFromComputer = seatsData?.seats?.length === 0;

  return (
    <div className="teams-create-template-container">
      <CreateTemplateHeader
        title={translate("organizationAdminCreateTemplate.header.title")}
        description={translate("organizationAdminCreateTemplate.header.description")}
        onCloseButtonClick={() => {
          history.push(routes.organizationAdminTemplates);
        }}
      />
      <div className="teams-create-template-content">
        <Switch>
          <Route
            path={routes.organizationAdminCreateTemplateStart}
            render={() => (
              <div className="action-cards">
                <div className="action-card-container">
                  <DivButton
                    className="card first-item"
                    onClick={() => {
                      if (!showTeamsCreateTemplateOnboardingModal)
                        history.push(routes.organizationAdminCreateTemplateFromComputer);
                    }}
                    disabled={disableCreateFromComputer && !showTeamsCreateTemplateOnboardingModal}
                  >
                    <Icon name="select-computer" color="gray-4" />
                    <p>{translate("organizationAdminCreateTemplate.actionCards.existingComputer.title")}</p>
                    <p>{translate("organizationAdminCreateTemplate.actionCards.existingComputer.description")}</p>
                  </DivButton>
                  <DivButton
                    className="card"
                    onClick={() => {
                      if (!showTeamsCreateTemplateOnboardingModal)
                        history.push(routes.organizationAdminCreateTemplateNew);
                    }}
                  >
                    <Icon name="application-library" color="gray-4" />
                    <p>{translate("organizationAdminCreateTemplate.actionCards.applicationLibrary.title")}</p>
                    <p>{translate("organizationAdminCreateTemplate.actionCards.applicationLibrary.description")}</p>
                  </DivButton>
                </div>
              </div>
            )}
          />
          <Route
            path={routes.organizationAdminCreateTemplateNew}
            render={() => (
              <NewTemplate
                onboardingModalCurrentStep={onboardingModalCurrentStep}
                showTeamsCreateTemplateOnboardingModal={showTeamsCreateTemplateOnboardingModal}
                translate={translate}
                history={history}
              />
            )}
          />
          <Route
            path={routes.organizationAdminCreateTemplateFromComputer}
            render={() => <TemplateFromComputer translate={translate} history={history} />}
          />
        </Switch>
      </div>
      {showTeamsCreateTemplateOnboardingModal && (
        <TeamsCreateTemplateOnboardingModal
          currentStep={onboardingModalCurrentStep}
          setCurrentStep={setOnboardingModalCurrentStep}
          onExit={() => {
            setShowTeamsCreateTemplateOnboardingModal(false);
            history.push(routes.organizationAdminTemplates);
            localStorage.setItem(LOCAL_STORAGE.showTeamsCreateTemplateOnboardingModal, false);
            localStorage.setItem(LOCAL_STORAGE.showTeamsTemplatesOnboardingAssignedModal, true);
          }}
          history={history}
          translate={translate}
        />
      )}
    </div>
  );
};

export default CreateTemplate;
