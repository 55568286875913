import React from "react";
import { Translate } from "react-localize-redux";
import { ConfirmationModal } from "UI";

const GameModeOnModal = (props) => {
  const { setShowGameModeOnModal, changeGameMode, translate } = props;

  return (
    <ConfirmationModal
      headerText={translate("modals.GameModeOn.header")}
      descriptionText={
        <Translate
          id="modals.GameModeOn.description"
          options={{
            renderInnerHtml: true,
          }}
        />
      }
      confirmText={translate("modals.GameModeOn.confirm")}
      confirmAction={() => {
        changeGameMode();
        setShowGameModeOnModal(false);
      }}
      secondaryText={translate("modals.GameModeOn.cancel")}
      secondaryAction={() => {
        setShowGameModeOnModal(false);
      }}
      closeAction={() => {
        setShowGameModeOnModal(false);
      }}
    />
  );
};

export default GameModeOnModal;
