import React from "react";

import { Icon } from "UI";

const IconButton = (props) => {
  const { onClick, preventKeyEvent, buttonRef, disabled, onDisabledClick, ...rest } = props;

  const handleKeyUp = (event) => {
    event.preventDefault();
    if (!preventKeyEvent) {
      if (event.key === "Enter") {
        onClick(event);
      }
    }
  };

  return (
    <Icon
      iconRef={buttonRef}
      onClick={(event) => {
        if (disabled && onDisabledClick) onDisabledClick(event);
        if (!disabled && onClick) onClick(event);
      }}
      disabled={disabled}
      role="button"
      tabIndex={0}
      onKeyUp={handleKeyUp}
      {...rest}
    />
  );
};

export default IconButton;
