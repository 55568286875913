import React from "react";
import { Icon } from "UI";
import { useClickOutside } from "Utils/Hooks";

import "./IframeModal.styles.scss";

const IframeModal = (props) => {
  const { src, setShowIframeModal } = props;

  const closeAction = () => {
    setShowIframeModal(false);
  };

  const modalContainerRef = useClickOutside(null, closeAction);

  return (
    <div className="workstation-iframe-container">
      <div className="workstation-iframe-modal" ref={modalContainerRef}>
        <Icon name="close" onClick={closeAction} className="top-right-icon" />
        <iframe className="workstation-iframe" title="workstation-iframe" src={src} allow="accelerometer" />
      </div>
    </div>
  );
};

export default IframeModal;
