import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ForgetPasswordComponent from "Components/Authentication/ForgetPassword/ForgetPassword.component";

import { forgetPasswordAPI, forgetPasswordClear } from "Actions/Authentication/ForgetPassword.actions";

class ForgetPasswordContainer extends React.Component {
  componentDidMount() {
    const { forgetPasswordClear } = this.props;
    forgetPasswordClear();
  }

  render() {
    return <ForgetPasswordComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  forgetPasswordCTX: state.forgetPasswordCTX,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgetPasswordAPI,
      forgetPasswordClear,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordContainer);
