import { getVendorAccountAPI } from "Actions/AppStreaming.actions";
import AppMenuPopUp from "Components/AppStreaming/Home/AppMenuPopUp/AppMenuPopUp.component";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { VENDOR_ADMIN_TYPES } from "Constants/AppStreaming.constants";
import defaultUserImage from "Assets/images/organizations/user.png";
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, ConfirmationModal, HoverableTooltip, Icon, IconButton, Input, TextButton } from "UI";
import { SETTINGS_USER_INVITE } from "Constants/StreamsOnboarding.constants";
import { useAPI } from "Utils";

import "./VendorAdmins.styles.scss";

const VendorAdmins = ({
  showSettingsOnboardingModal,
  showDummyInvitedMember,
  vendorAdminLimitReached,
  profilePictureUrl,
  translate,
}) => {
  const [vendorAdmins, setVendorAdmins] = React.useState([]);
  const [vendorAdminInvitations, setVendorAdminInvitations] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [popUpPosition, setPopUpPosition] = React.useState(null);
  const [selectedVendorAdmin, setSelectedVendorAdmin] = React.useState(null);
  const [createVendorAdminInvitationError, setCreateVendorAdminInvitationError] = React.useState(null);
  const [showDeleteAdminConfirmationModal, setShowDeleteAdminConfirmationModal] = React.useState(false);
  const [showDeleteInvitationConfirmationModal, setShowDeleteInvitationConfirmationModal] = React.useState(false);

  const vendorType = useSelector((state) => state.appStreaming.vendorType);
  const isVendorPro = vendorType === "pro" || vendorType === "enterprise";

  const vendorAdminsContainerRef = useRef(null);
  const dispatch = useDispatch();

  const { email: currentUserEmail } = useSelector((state) => state.account.account.attributes) || {};
  const { api: getVendorAdmins } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_VENDOR_ADMINS,
    onSuccess: (resp) => {
      setVendorAdmins(resp.vendor_admins);
    },
  });

  const { api: getVendorAdminInvitationsAPI } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_VENDOR_ADMIN_INVITATIONS,
    onSuccess: (resp) => {
      setVendorAdminInvitations(resp.invitations);
    },
  });

  const { api: createVendorAdminInvitationAPI, isLoading: createVendorAdminInvitationLoading } = useAPI({
    type: "post",
    endpoint: APP_STREAMING_API_ENDPOINTS.CREATE_VENDOR_ADMIN_INVITATION,
    onSuccess: () => {
      getVendorAdminInvitationsAPI();
      setEmail("");
      setCreateVendorAdminInvitationError(false);
      dispatch(getVendorAccountAPI());
      getVendorAdmins();
    },
    onErr: (err) => {
      setCreateVendorAdminInvitationError(err.client_code);
      setTimeout(() => {
        setCreateVendorAdminInvitationError(false);
      }, 5000);
    },
  });

  const { api: deleteVendorAdminAPI } = useAPI({
    type: "delete",
    endpoint: APP_STREAMING_API_ENDPOINTS.DELETE_VENDOR_ADMIN(selectedVendorAdmin?.id),
    onSuccess: () => {
      getVendorAdmins();
      dispatch(getVendorAccountAPI());
    },
  });

  const { api: deleteVendorAdminInvitationAPI } = useAPI({
    type: "delete",
    endpoint: APP_STREAMING_API_ENDPOINTS.DELETE_VENDOR_ADMIN_INVITATION(selectedVendorAdmin?.id),
    onSuccess: () => {
      getVendorAdminInvitationsAPI();
      dispatch(getVendorAccountAPI());
    },
  });

  useEffect(() => {
    getVendorAdmins();
    getVendorAdminInvitationsAPI();
  }, [profilePictureUrl]);

  const appMenuPopUpItems = () => {
    const items = [];
    if (selectedVendorAdmin.type === "vendor_admin") {
      items.push({
        key: "remove-admin",
        text: "Remove",
        onClick: () => {
          setShowDeleteAdminConfirmationModal(true);
          setPopUpPosition(null);
        },
      });
    }

    if (selectedVendorAdmin.type === "vendor_admin_invitation") {
      items.push({
        key: "remove-invitation",
        text: "Cancel Request",
        onClick: () => {
          setShowDeleteInvitationConfirmationModal(true);
          setPopUpPosition(null);
        },
      });
    }
    return items;
  };

  return (
    <div className="vendor-admins-container" ref={vendorAdminsContainerRef}>
      <div className="vendor-admin-list">
        {vendorAdmins?.map((admin) => {
          const { name, profile_picture_url: profilePictureUrl } = admin.attributes.user.attributes;
          const avatarContent = name ? name.split("")[0].toUpperCase() : null;
          const img = () => {
            if (profilePictureUrl) {
              return profilePictureUrl;
            }

            if (name) {
              return null;
            }

            return defaultUserImage;
          };

          return (
            <div className="vendor-admin" key={admin.id}>
              <Avatar content={avatarContent} img={img()} />
              <div>
                <h3>{admin.attributes.email}</h3>
                <p>
                  {admin.attributes.admin_type}
                  {currentUserEmail === admin.attributes.email && " - You"}
                </p>
              </div>
              {currentUserEmail !== admin.attributes.email &&
                admin.attributes.admin_type !== VENDOR_ADMIN_TYPES.owner && (
                  <IconButton
                    name="more"
                    small
                    onClick={(event) => {
                      if (!showSettingsOnboardingModal) {
                        event.preventDefault();
                        event.stopPropagation();
                        setSelectedVendorAdmin(admin);
                        setPopUpPosition({ x: event.clientX, y: event.clientY });
                      }
                    }}
                  />
                )}
            </div>
          );
        })}

        {(showDummyInvitedMember ? SETTINGS_USER_INVITE : vendorAdminInvitations)?.map((invitation) => (
          <div className="vendor-admin invitation" key={invitation.email}>
            <Avatar img={defaultUserImage} />
            <div>
              <h3>{invitation.attributes.email}</h3>
              <p>Pending</p>
            </div>
            <IconButton
              name="more"
              small
              onClick={(event) => {
                if (!showSettingsOnboardingModal) {
                  event.preventDefault();
                  event.stopPropagation();
                  setSelectedVendorAdmin(invitation);
                  setPopUpPosition({ x: event.clientX, y: event.clientY });
                }
              }}
            />
          </div>
        ))}
      </div>

      {!vendorAdminLimitReached && (
        <div className="vendor-admin">
          <Avatar content="?" />
          <div className="vendor-admin-input">
            <h3>Email</h3>
            <Input
              placeholder="leonardo@davinci.com"
              value={email}
              onChange={(e) => {
                if (!showSettingsOnboardingModal) {
                  setEmail(e.target.value);
                }
              }}
              maxLength={44}
              disabled={showSettingsOnboardingModal}
            />
            <div className="text-button-wrapper">
              {createVendorAdminInvitationError && (
                <HoverableTooltip
                  side="top"
                  align="start"
                  content={translate(
                    `appStreamingSettings.vendorAdmins.createInvitationError.${createVendorAdminInvitationError}`,
                  )}
                >
                  <Icon className="hide-on-mobile shake" name="error" color="purple-main" small />
                </HoverableTooltip>
              )}

              <TextButton
                label="Email"
                text="Send"
                disabled={createVendorAdminInvitationLoading}
                onClick={() => {
                  if (!showSettingsOnboardingModal) createVendorAdminInvitationAPI({ email });
                }}
              />
            </div>
          </div>
        </div>
      )}

      {!isVendorPro && (
        <div className="vendor-admin">
          <Avatar content="?" />
          <div className="vendor-admin-input disabled">
            <p>Email</p>
            <Input placeholder="leonardo@davinci.com" disabled />
          </div>
        </div>
      )}

      {popUpPosition && (
        <AppMenuPopUp
          popUpPosition={popUpPosition}
          referencePosRef={vendorAdminsContainerRef}
          setShowPopUpMenu={() => {
            setPopUpPosition(null);
          }}
          content={appMenuPopUpItems()}
        />
      )}
      {showDeleteAdminConfirmationModal && (
        <ConfirmationModal
          className="delete-app-file-confirmation-modal"
          closeOnOverlayClick
          closeAction={() => setShowDeleteAdminConfirmationModal(false)}
          headerText={translate("appStreamingSettings.vendorAdmins.deleteAdminModal.header")}
          descriptionText={translate("appStreamingSettings.vendorAdmins.deleteAdminModal.description")}
          confirmText={translate("appStreamingSettings.vendorAdmins.deleteAdminModal.action")}
          confirmAction={() => {
            setShowDeleteAdminConfirmationModal(false);
            deleteVendorAdminAPI();
          }}
          secondaryText={translate("appStreamingSettings.vendorAdmins.deleteAdminModal.secondaryAction")}
          secondaryAction={() => setShowDeleteAdminConfirmationModal(false)}
        />
      )}

      {showDeleteInvitationConfirmationModal && (
        <ConfirmationModal
          className="delete-app-file-confirmation-modal"
          closeOnOverlayClick
          closeAction={() => setShowDeleteInvitationConfirmationModal(false)}
          headerText={translate("appStreamingSettings.vendorAdmins.deleteInvitationModal.header")}
          descriptionText={translate("appStreamingSettings.vendorAdmins.deleteInvitationModal.description")}
          confirmText={translate("appStreamingSettings.vendorAdmins.deleteInvitationModal.action")}
          confirmAction={() => {
            setShowDeleteInvitationConfirmationModal(false);
            deleteVendorAdminInvitationAPI();
          }}
          secondaryText={translate("appStreamingSettings.vendorAdmins.deleteInvitationModal.secondaryAction")}
          secondaryAction={() => setShowDeleteInvitationConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default VendorAdmins;
