import * as SubscriptionsConstants from "Constants/Dashboard/Subscriptions.constants";

export const getUserSubscriptionPaymentInformationAPI = (accessToken, configs) => ({
  type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_PENDING,
  payload: { accessToken, configs },
});

export const getFileStoragePlansAPI = () => ({
  type: SubscriptionsConstants.GET_FILE_STORAGE_SUBSCRIPTION_PLANS_PENDING,
});

export const createUserWorkstationAsSubscriptionAPI = (accessToken, configs, balance, paymentType) => ({
  type: SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_PENDING,
  payload: { accessToken, configs, balance, paymentType },
});

export const clearSubscriptionWorkstation = () => ({
  type: SubscriptionsConstants.CLEAR_SUBSCRIPTION_WORKSTATION,
});

export const getUserSubscriptionAPI = (accessToken) => ({
  type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_PENDING,
  payload: { accessToken },
});

export const updateUserSubscriptionAPI = (accessToken, isPayAsYouGoEnabled) => ({
  type: SubscriptionsConstants.UPDATE_USER_SUBSCRIPTION_API_PENDING,
  payload: { accessToken, isPayAsYouGoEnabled },
});

export const cancelSubscriptionAPI = (accessToken) => ({
  type: SubscriptionsConstants.CANCEL_USER_SUBSCRIPTION_API_PENDING,
  payload: { accessToken },
});

export const renewSubscriptionAPI = (accessToken) => ({
  type: SubscriptionsConstants.RENEW_USER_SUBSCRIPTION_API_PENDING,
  payload: { accessToken },
});

export const upgradeDiskSizeSubscriptionAPI = (accessToken, diskSize, paymentMethod) => ({
  type: SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_PENDING,
  payload: { accessToken, diskSize, paymentMethod },
});

export const upgradeFileStorageSubscriptionAPI = (accessToken, storageSize) => ({
  type: SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_PENDING,
  payload: { accessToken, storageSize },
});

export const resetUpgradeDiskSizeSubscriptionAPI = () => ({
  type: SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_RESET,
});
