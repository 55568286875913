import React, { useEffect, useState } from "react";
import { Button, Dropdown, IconButton, Modal } from "UI";
import { useMutation } from "@tanstack/react-query";
import { apiGenerator } from "Utils";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import ExpandableAnimation from "UI/ExpandableAnimation/ExpandableAnimation.component";

import "./DepositRuleModal.styles.scss";

const DepositRuleModal = ({ depositRule, onClose, refetchBalance, translate }) => {
  const [amount, setAmount] = useState(depositRule?.attributes?.amount || 125);
  const [limit, setLimit] = useState(depositRule?.attributes?.limit || 125);
  const [consentApproved, setConsentApproved] = useState(Boolean(depositRule) || false);

  useEffect(() => {
    if (!depositRule) {
      setAmount(100);
      setLimit(50);
      setConsentApproved(false);
      createDepositRuleMutation.reset();
    }
  }, [depositRule]);

  const createDepositRuleMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.VENDOR_DEPOSIT_RULES, {
        amount,
        limit,
      });
    },
    onSuccess: () => {
      refetchBalance();
    },
  });

  const deleteDepositRuleMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("delete")(APP_STREAMING_API_ENDPOINTS.VENDOR_DEPOSIT_RULE(depositRule.id));
    },
    onSuccess: () => {
      refetchBalance();
      createDepositRuleMutation.reset();
    },
  });

  const limitOptions = [
    {
      value: 50,
    },
    {
      value: 100,
    },
    {
      value: 250,
    },
    {
      value: 500,
    },
    {
      value: 1000,
    },
  ];

  const amountOptions = [
    {
      value: 100,
      disabled: limit > 100,
    },
    {
      value: 250,
      disabled: limit > 250,
    },
    {
      value: 500,
      disabled: limit > 500,
    },
    {
      value: 1000,
      disabled: limit > 1000,
    },
  ];

  return (
    <Modal
      className="deposit-rule-modal"
      headerText={translate("appStreamingBilling.depositRuleModal.header")}
      descriptionText={translate("appStreamingBilling.depositRuleModal.description")}
      closeAction={() => onClose()}
      topRightIcon="close"
      topRightIconAction={() => onClose()}
    >
      <div className="deposit-rule-modal-content">
        <p className="label">{translate("appStreamingBilling.depositRuleModal.form.limit.label")}</p>
        <Dropdown
          box
          mapFunction={(option) => {
            return `$${option.value}`;
          }}
          defaultSelected={limitOptions.find((option) => option.value === limit)}
          options={limitOptions}
          keyName="value"
          handleSelectedOptionChange={(option) => {
            setLimit(option.value);
            if (amount < option.value) {
              setAmount(option.value);
            }
          }}
          disabled={createDepositRuleMutation.isLoading || createDepositRuleMutation.isSuccess || depositRule}
        />
        <p className="label">{translate("appStreamingBilling.depositRuleModal.form.amount.label")}</p>
        <Dropdown
          box
          mapFunction={(option) => {
            return `$${option.value}`;
          }}
          defaultSelected={amountOptions.find((option) => option.value === amount)}
          keyName="value"
          options={amountOptions}
          handleSelectedOptionChange={(option) => setAmount(option.value)}
          disabled={createDepositRuleMutation.isLoading || createDepositRuleMutation.isSuccess || depositRule}
        />

        <div className="check-box-text">
          <input
            type="checkbox"
            checked={consentApproved}
            onChange={(e) => {
              setConsentApproved(e.target.checked);
            }}
            disabled={Boolean(depositRule)}
          />
          <span>{translate("appStreamingBilling.depositRuleModal.form.consent")}</span>
        </div>

        <Button
          block
          text="Set Auto Deposit Rule"
          onClick={() => {
            createDepositRuleMutation.mutate();
          }}
          disabled={
            !consentApproved ||
            createDepositRuleMutation.isLoading ||
            createDepositRuleMutation.isSuccess ||
            depositRule
          }
        />

        <ExpandableAnimation isOpen={depositRule}>
          <div className="delete-deposit-rule-box">
            <div>
              <h1>{translate("appStreamingBilling.depositRuleModal.active.header")}</h1>
              <p>
                {translate("appStreamingBilling.depositRuleModal.active.description", {
                  amount,
                  limit,
                })}
              </p>
            </div>
            <IconButton name="close" onClick={() => deleteDepositRuleMutation.mutate()} small />
          </div>
        </ExpandableAnimation>
      </div>
    </Modal>
  );
};

export default DepositRuleModal;
