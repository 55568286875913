import React from "react";
import { Translate } from "react-localize-redux";
import { classnames } from "Utils";
import "./StatusBadge.styles.scss";

const StatusBadge = ({ status, color }) => {
  return (
    <div className={classnames(["vg-status-badge", color])}>
      <div className={classnames(["status-circle", status])} />
      <p>
        <Translate id={`statusBadge.status.${status}`} />
      </p>
    </div>
  );
};

export default StatusBadge;
