import { all, put, takeLatest } from "redux-saga/effects";
import apiGenerator from "Utils/Helpers/api.helpers";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";

import * as ForgetPasswordResetConstants from "Constants/Authentication/ForgetPassword.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";

function* forgetPasswordResetAPISaga(action) {
  const api = apiGenerator("put")(API_ENDPOINTS.FORGET_PASSWORD_RESET(action.payload.code), action.payload.payload);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ForgetPasswordResetConstants.FORGET_PASSWORD_RESET_API_SUCCESS,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: ForgetPasswordResetConstants.FORGET_PASSWORD_RESET_API_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([takeLatest(ForgetPasswordResetConstants.FORGET_PASSWORD_RESET_API_PENDING, forgetPasswordResetAPISaga)]);
}
