import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useGesture } from "@use-gesture/react";

function Draggable({ children, enabled, reset, setReset }) {
  const [xy, setXY] = useState([0, 0]);
  const [lastXY, setLastXY] = useState([0, 0]);
  const [scale, setScale] = useState(1);
  const [initialDistance, setInitialDistance] = useState(0);
  const [gestureActive, setGestureActive] = useState(false);

  useEffect(() => {
    if (reset) {
      setXY([0, 0]);
      setLastXY([0, 0]);
      setScale(1);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();

    if (enabled) {
      document.body.addEventListener("touchmove", preventDefault, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventDefault);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventDefault);
    };
  }, [enabled]);

  const childRef = useRef();
  const [gridSize, setGridSize] = useState(50); // will be calculated based on the child's size

  const bind = useGesture(
    {
      onDragStart: () => {
        if (gestureActive) {
          return;
        }

        setGestureActive(true);
      },
      onDrag: ({ movement: [mx, my], touches }) => {
        if (touches === 1) {
          setXY([mx + lastXY[0], my + lastXY[1]]);
        }
      },
      onDragEnd: () => {
        let gridX = Math.round(xy[0] / gridSize) * gridSize;
        let gridY = Math.round(xy[1] / gridSize) * gridSize;

        const childWidth = childRef.current.offsetWidth * scale;
        const childHeight = childRef.current.offsetHeight * scale;

        const maxX = childWidth / 2 + window.innerWidth * 0.25;
        const maxY = childHeight / 2 + window.innerHeight * 0.35;

        if (maxX < Math.abs(gridX)) {
          gridX = Math.sign(gridX) * maxX;
        }

        if (maxY < Math.abs(gridY)) {
          gridY = Math.sign(gridY) * maxY;
        }

        setXY([gridX, gridY]);
        setLastXY([gridX, gridY]);

        setGestureActive(false);
      },
      onPinchStart: ({ da: [d, a] }) => {
        setInitialDistance(d);

        if (gestureActive) {
          return;
        }

        setGestureActive(true);
      },
      onPinch: ({ da: [d, a] }) => {
        if (initialDistance) {
          const scaleFactor = d / initialDistance;
          setScale((prevScale) => prevScale * scaleFactor);
          setInitialDistance(d);
        }
      },
      onPinchEnd: () => {
        setGestureActive(false);
        setInitialDistance(null);
      },
    },
    {
      // Configure the gesture recognition settings
      drag: { pointer: { touch: true } },
      pinch: { pointer: { touch: true } },
    },
  );

  // Animated styles that respond to state changes
  const style = {
    x: xy[0],
    y: xy[1],
    scale,
  };

  return (
    <motion.div {...(enabled ? bind() : {})} style={style} ref={childRef}>
      {children(scale)}
    </motion.div>
  );
}

export default Draggable;
