/* eslint-disable max-classes-per-file */
import * as React from "react";
import { angleToPosition, positionToAngle, valueToAngle, angleToValue } from "./circularGeometry";
import { arcPathWithSquaredEnds } from "./svgPaths";
import "./CircularSlider.style.scss";

const SLIDER_HANDLE_WIDTH = 18;
const STROKE_WIDTH = 4;
const ARC_SPACING = 1;

export const CircularSlider = (props) => {
  const {
    size,
    handle1,
    handle2,
    handleSize,
    maxValue,
    minValue,
    startAngle,
    endAngle,
    angleType,
    disabled,
    arcColor,
    arcBackgroundColor,
    outerShadow,
  } = props;

  const svgRef = React.useRef();

  const processSelection = (ev) => {
    const { size, maxValue, minValue, angleType, startAngle, endAngle, handle1, disabled, handle2, coerceToInt } =
      props;
    if (!handle1.onChange) {
      // Read-only, don't bother doing calculations
      return;
    }
    const svgRefX = svgRef.current;
    if (!svgRefX) {
      return;
    }
    // Find the coordinates with respect to the SVG
    const svgPoint = svgRefX.createSVGPoint();
    const x = ev.clientX;
    const y = ev.clientY;
    svgPoint.x = x;
    svgPoint.y = y;
    const coordsInSvg = svgPoint.matrixTransform(svgRefX.getScreenCTM().inverse());

    const angle = positionToAngle(coordsInSvg, size, angleType);
    let value = angleToValue({
      angle,
      minValue,
      maxValue,
      startAngle,
      endAngle,
    });
    if (coerceToInt) {
      value = Math.round(value);
    }

    if (!disabled) {
      if (
        handle2 &&
        handle2.onChange &&
        // make sure we're closer to handle 2 -- i.e. controlling handle2
        Math.abs(value - handle2.value) < Math.abs(value - handle1.value)
      ) {
        handle2.onChange(value);
      } else {
        handle1.onChange(value);
      }
    }
  };

  const removeMouseListeners = () => {
    const svgRefX = svgRef.current;
    if (svgRefX) {
      svgRefX.removeEventListener("mousemove", processSelection);
      svgRefX.removeEventListener("mouseleave", removeMouseListeners);
      svgRefX.removeEventListener("mouseup", removeMouseListeners);
    }
    if (props.onControlFinished) {
      props.onControlFinished();
    }
  };
  const onMouseDown = (ev) => {
    const svgRefX = svgRef.current;
    if (svgRefX) {
      svgRefX.addEventListener("mousemove", processSelection);
      svgRefX.addEventListener("mouseleave", removeMouseListeners);
      svgRefX.addEventListener("mouseup", removeMouseListeners);
    }
    processSelection(ev);
  };

  const onMouseEnter = (ev) => {
    if (ev.buttons === 1) {
      // The left mouse button is pressed, act as though user clicked us
      onMouseDown(ev);
    }
  };

  const totalSpacing = maxValue * ARC_SPACING;
  const arcSize = (360 - totalSpacing) / maxValue;

  const arcsGenerator = (startAngle, endAngle, color) => {
    const arcCount = Math.floor((endAngle - startAngle) / arcSize);

    const newArcs = [];

    for (let i = 0; i < arcCount; i += 1) {
      const start = startAngle + (arcSize + ARC_SPACING) * i;
      const end = start + arcSize;

      newArcs.push(
        <path
          d={arcPathWithSquaredEnds({
            startAngle: start,
            endAngle: end,
            angleType,
            innerRadius: trackInnerRadius,
            thickness: STROKE_WIDTH,
            svgSize: size,
            direction: angleType.direction,
          })}
          fill={color}
          key={i}
        />,
      );
    }
    return newArcs;
  };

  const shadowWidth = 20;
  const trackInnerRadius = size / 2 - STROKE_WIDTH - shadowWidth;
  const handle1Angle = valueToAngle({
    value: handle1.value,
    minValue,
    maxValue,
    startAngle,
    endAngle,
  });
  const handle2Angle =
    handle2 &&
    valueToAngle({
      value: handle2.value,
      minValue,
      maxValue,
      startAngle,
      endAngle,
    });
  const handle1Position = angleToPosition(
    { degree: handle1Angle, ...angleType },
    trackInnerRadius + STROKE_WIDTH / 2,
    size,
  );
  const handle2Position =
    handle2Angle && angleToPosition({ degree: handle2Angle, ...angleType }, trackInnerRadius + STROKE_WIDTH / 2, size);

  const controllable = !disabled && Boolean(handle1.onChange);

  const emptyArcs = arcsGenerator(handle1Angle, endAngle, arcBackgroundColor);
  const filledArcs = arcsGenerator(startAngle, handle1Angle, arcColor);
  const handlePosOffset = SLIDER_HANDLE_WIDTH / 2;

  return (
    <svg
      width={size}
      height={size}
      ref={svgRef}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onClick={
        /* TODO: be smarter about this -- for example, we could run this through our
          calculation and determine how close we are to the arc, and use that to decide
          if we propagate the click. */
        (ev) => controllable && ev.stopPropagation()
      }
    >
      {
        /* Shadow */
        outerShadow && (
          <>
            <radialGradient id="outerShadow">
              <stop offset="90%" stopColor={arcColor} />
              <stop offset="100%" stopColor="white" />
            </radialGradient>

            <circle
              fill="none"
              stroke="url(#outerShadow)"
              cx={size / 2}
              cy={size / 2}
              // Subtract an extra pixel to ensure there's never any gap between slider and shadow
              r={trackInnerRadius + STROKE_WIDTH + shadowWidth / 2 - 1}
              strokeWidth={shadowWidth}
            />
          </>
        )
      }
      {handle2Angle === undefined ? (
        /* One-handle mode */
        <>
          {/* Arc Background  */}
          {emptyArcs}
          {/* Arc (render after background so it overlays it) */}
          {filledArcs}
        </>
      ) : (
        /* Two-handle mode */
        <>
          {/* Arc Background Part 1  */}
          <path
            d={arcPathWithSquaredEnds({
              startAngle,
              endAngle: handle1Angle,
              angleType,
              innerRadius: trackInnerRadius,
              thickness: STROKE_WIDTH,
              svgSize: size,
              direction: angleType.direction,
            })}
            fill={arcBackgroundColor}
          />
          {/* Arc Background Part 2  */}
          <path
            d={arcPathWithSquaredEnds({
              startAngle: handle2Angle,
              endAngle,
              angleType,
              innerRadius: trackInnerRadius,
              thickness: STROKE_WIDTH,
              svgSize: size,
              direction: angleType.direction,
            })}
            fill={arcBackgroundColor}
          />
          {/* Arc (render after background so it overlays it) */}
          <path
            d={arcPathWithSquaredEnds({
              startAngle: handle1Angle,
              endAngle: handle2Angle,
              angleType,
              innerRadius: trackInnerRadius,
              thickness: STROKE_WIDTH,
              svgSize: size,
              direction: angleType.direction,
            })}
            fill={arcColor}
          />
        </>
      )}

      {
        /* Handle 1 */
        controllable && (
          <>
            <filter id="handleShadow" x="-50%" y="-50%" width="16" height="16">
              <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
              <feColorMatrix
                result="matrixOut"
                in="offOut"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="5" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
            <rect
              width={SLIDER_HANDLE_WIDTH}
              height={SLIDER_HANDLE_WIDTH}
              x={handle1Position.x - handlePosOffset}
              y={handle1Position.y - handlePosOffset}
              fill={arcColor}
              filter="url(#handleShadow)"
              transform={`rotate(${(handle1.value * 360) / maxValue}, ${handle1Position.x},${handle1Position.y})`}
              rx="2"
            />
          </>
        )
      }

      {
        /* Handle 2 */
        handle2Position && (
          <>
            <circle
              r={handleSize}
              cx={handle2Position.x}
              cy={handle2Position.y}
              fill="#ffffff"
              filter="url(#handleShadow)"
            />
          </>
        )
      }
    </svg>
  );
};

CircularSlider.defaultProps = {
  size: 200,
  minValue: 0,
  maxValue: 100,
  startAngle: 0,
  endAngle: 360,
  angleType: {
    direction: "cw",
    axis: "-y",
  },
  handleSize: SLIDER_HANDLE_WIDTH,
  arcBackgroundColor: "#aaa",
};

export const CircularSliderWithChildren = (props) => {
  const { size, children } = props;
  return (
    <div
      style={{
        width: size,
        height: size,
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
      }}
    >
      <CircularSlider {...props} />
      <div
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};
CircularSliderWithChildren.defaultProps = CircularSlider.defaultProps;
export default CircularSlider;
