import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationAPI, getSeatsMeAPI } from "Actions/Organization.actions";
import { getProductOnboardingAPI } from "Actions/Account.actions";
import OrganizationAdminHome from "Components/Organization/Admin/Home/Home.component";

const HomeContainer = (props) => {
  const { organization } = useSelector((state) => state.organizations);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationAPI());
    dispatch(getProductOnboardingAPI());
  }, []);

  useEffect(() => {
    if (organization?.id) {
      dispatch(getSeatsMeAPI());
    }
  }, [organization]);

  return <OrganizationAdminHome {...props} />;
};

export default HomeContainer;
