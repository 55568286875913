import React from "react";
import { connect } from "react-redux";

import { getItemFromLocalStorage } from "Utils";
import HomeComponent from "Components/Dashboard/Home/Home.component";
import {
  updateCreateWorkstationConfig,
  getWorkstationsAPI,
  getWorkstationMachineTypesAPI,
  updateWorkstationStatusAPI,
  getWorkstationCurrentSessionAPI,
  updateWorkstationAutoStopConfigurationAPI,
  resetUpdateWorkstationStatusAPI,
} from "Actions/Dashboard/Workstations.actions";
import {
  getPaymentMethodsAPI,
  getPaymentClientSecretAPI,
  createUserPaymentMethodAPI,
  approveUserPaymentMethodAPI,
  denyUserPaymentMethodAPI,
  payment3DSecureAPI,
} from "Actions/Dashboard/Payment.actions";
import {
  upgradeDiskSizeSubscriptionAPI,
  resetUpgradeDiskSizeSubscriptionAPI,
  getUserSubscriptionAPI,
} from "Actions/Dashboard/Subscriptions.actions";

import {
  getAccountAPI,
  getAccountPredefinedAPI,
  getMachineUsageHistoryAPI,
  setUserRememberMachineTypeAPI,
  setUserMultiGpuConsentAPI,
} from "Actions/Account.actions";
import { getRewardsAPI } from "Actions/Referral.actions";
import { HINTER_TYPES, LOCAL_STORAGE, REQUEST_STATUS, WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";
import { removeItemFromLocalStorage } from "Utils/Helpers/storage.helpers";

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hinterType: HINTER_TYPES.REFERRAL,
    };

    props.getWorkstationMachineTypesAPI();

    if (props.workstations.getWorkstationsCTX.status === REQUEST_STATUS.NOT_DEFINED) {
      props.getWorkstationsAPI();
    }
  }

  componentDidMount() {
    const {
      account,
      workstations,
      getAccountPredefinedAPI,
      getPaymentMethodsAPI,
      getUserSubscriptionAPI,
      getRewardsAPI,
      getMachineUsageHistoryAPI,
    } = this.props;

    if (workstations.workstations && workstations.workstations.length > 0) {
      const pollingInterval = this.determinePollingIntervalByWorkstation(workstations.workstations[0]);

      this.setPollingInterval(pollingInterval);
    }

    getAccountPredefinedAPI(account.accessToken);
    getPaymentMethodsAPI(account.accessToken);
    getUserSubscriptionAPI(account.accessToken);
    getRewardsAPI(account.accessToken);
    getMachineUsageHistoryAPI();
  }

  componentDidUpdate(prevProps) {
    const { workstations, translate } = this.props;

    if (prevProps.workstations.workstations.length === 0 && workstations.workstations.length !== 0) {
      const pollingInterval = this.determinePollingIntervalByWorkstation(workstations.workstations[0]);

      this.setPollingInterval(pollingInterval);
    }

    if (prevProps.workstations.workstations.length > 0 && workstations.workstations.length > 0) {
      const previousFriendlyStatus =
        prevProps.workstations.workstations[0].attributes.machine.attributes.friendly_status;

      const { friendly_status: friendlyStatus } = workstations.workstations[0].attributes.machine.attributes;

      if (previousFriendlyStatus !== friendlyStatus) {
        const pollingInterval = this.determinePollingIntervalByWorkstation(workstations.workstations[0]);

        this.setPollingInterval(pollingInterval);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  determinePollingIntervalByWorkstation = (workstation) => {
    const { account } = this.props;
    const { friendly_status: friendlyStatus } = workstation.attributes.machine.attributes;

    switch (friendlyStatus) {
      case WORKSTATION_FRIENDLY_STATUS.CREATING:
        return 5000;
      case WORKSTATION_FRIENDLY_STATUS.TURNING_ON:
        removeItemFromLocalStorage(LOCAL_STORAGE.streamRating);
        return 5000;
      case WORKSTATION_FRIENDLY_STATUS.READY:
        return 15000;
      case WORKSTATION_FRIENDLY_STATUS.TURNING_OFF:
        if (!getItemFromLocalStorage(LOCAL_STORAGE.streamRating, false)) {
          this.setHinterType(HINTER_TYPES.RATING);
        }
        return 5000;
      case WORKSTATION_FRIENDLY_STATUS.OFF:
        if (!account.isTeamAccount) {
          this.setHinterType(HINTER_TYPES.REFERRAL);
        }
        return 15000;
      default:
        return 5000;
    }
  };

  setPollingInterval = (pollingInterval) => {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.getWorkstations();

    this.interval = setInterval(this.getWorkstations, pollingInterval);
  };

  getWorkstations = () => {
    const { account, getWorkstationsAPI } = this.props;

    getWorkstationsAPI(account.accessToken);
  };

  setHinterType = (hinterType) => {
    this.setState({ hinterType });
  };

  render() {
    const { hinterType } = this.state;
    return <HomeComponent {...this.props} hinterType={hinterType} setHinterType={this.setHinterType} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  referral: state.referral,
  workstations: state.workstations,
  payment: state.payment,
  subscriptions: state.subscriptions,
});

const actionCreators = {
  updateCreateWorkstationConfig,
  getWorkstationsAPI,
  getWorkstationMachineTypesAPI,
  getMachineUsageHistoryAPI,
  updateWorkstationStatusAPI,
  resetUpdateWorkstationStatusAPI,
  getWorkstationCurrentSessionAPI,
  getPaymentMethodsAPI,
  getPaymentClientSecretAPI,
  upgradeDiskSizeSubscriptionAPI,
  resetUpgradeDiskSizeSubscriptionAPI,
  getUserSubscriptionAPI,
  getAccountAPI,
  createUserPaymentMethodAPI,
  approveUserPaymentMethodAPI,
  denyUserPaymentMethodAPI,
  payment3DSecureAPI,
  getAccountPredefinedAPI,
  setUserRememberMachineTypeAPI,
  setUserMultiGpuConsentAPI,
  updateWorkstationAutoStopConfigurationAPI,
  getRewardsAPI,
};

export default connect(mapStateToProps, actionCreators)(HomeContainer);
