import axios from "axios";
import { LOCAL_STORAGE } from "Constants/global.constants";
import { useCallback, useEffect, useState, useRef } from "react";
import { Logger } from "Utils";
import { apiErrorHandler } from "./saga.helpers";
import { getItemFromLocalStorage } from "./storage.helpers";

const apiGenerator = (type, accessToken = null) => {
  const headers = {
    "content-type": "application/json",
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  } else {
    const localAccessToken = getItemFromLocalStorage(LOCAL_STORAGE.accessToken);
    if (localAccessToken) {
      headers.Authorization = `Bearer ${localAccessToken}`;
    }
  }

  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers,
  };

  switch (type) {
    case "get":
      return (url, options = {}) => axios.get(url, { ...defaultOptions, ...options });
    case "post":
      return (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options });
    case "patch":
      return (url, data, options = {}) => axios.patch(url, data, { ...defaultOptions, ...options });
    case "put":
      return (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options });
    case "delete":
      return (url, data, options = {}) => axios.delete(url, { data, ...defaultOptions, ...options });
    default:
      return null;
  }
};

// Custom Hook for api requests
// Calls for api on component mount
export function useAPIRequest(props) {
  const { type = "get", endpoint, body, params, onErr, onSuccess } = props;

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const request = async () => {
    apiGenerator(type)(endpoint, body, params)
      .then((response) => {
        setData(response.data);
        if (onSuccess) onSuccess();
        Logger.log("useAPIRequest", response.data);
      })
      .catch((err) => {
        setData(null);
        setError(apiErrorHandler(err));
        if (onErr) onErr();
        Logger.log("useAPIRequest", err);
      });
  };
  useEffect(() => {
    request();
  }, []);

  return {
    data,
    error,
    isLoading: Boolean(!data) && Boolean(!error),
    refresh: request,
  };
}

// Custom Hook for api requests
export function useAPI(props) {
  const { type = "get", endpoint, onErr, onSuccess } = props;

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const mountedRef = useRef(false);

  // effect just for tracking mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const apiCallback = useCallback(
    async (body = {}, options = {}, urlParams) => {
      setIsLoading(true);
      Logger.log("useAPI callback", body, options);
      const api = apiGenerator(type);

      api(urlParams ? endpoint(urlParams) : endpoint, body || options, options)
        .then((response) => {
          if (mountedRef.current) {
            setData(response.data);
            setIsLoading(false);
            if (onSuccess) onSuccess(response.data);
            Logger.log("useAPI", response.data);
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            const error = apiErrorHandler(err);
            setError(error);
            setIsLoading(false);
            if (onErr) onErr(error);
            Logger.log("useAPI", err);
          }
        });
    },
    [endpoint, onErr, type, onSuccess],
  );

  return {
    api: apiCallback,
    data,
    error,
    isLoading,
  };
}

export default apiGenerator;
