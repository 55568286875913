import React from "react";
import "./ProgressBar.style.scss";

const ProgressBar = ({ progress }) => {
  return (
    <div className="vg-progress-bar-container">
      <div
        className="progress-bar"
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
