import React, { useRef } from "react";

import "./Slide.styles.scss";

const Slide = (props) => {
  const { activeIndex, setActiveIndex, showDots, children } = props;

  const sliderRef = useRef(null);

  const childNumber = children.length;
  const sliderWidth = sliderRef.current ? sliderRef.current.offsetWidth : 0;
  const translate = activeIndex * sliderWidth;

  return (
    <div className="slide" ref={sliderRef}>
      <div className="slide-content" style={{ transform: `translateX(-${translate}px)` }}>
        {children.map((child, index) => {
          return (
            // eslint-disable-next-line
            <div key={index} className="slide-page">
              {child}
            </div>
          );
        })}
      </div>
      {showDots && <Dots indexNumber={childNumber} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />}
    </div>
  );
};

const Dots = (props) => {
  const { indexNumber, activeIndex, setActiveIndex } = props;
  return (
    <div className="dots">
      {[...Array(indexNumber).keys()].map((index) => {
        return (
          /* eslint-disable */
          <div
            key={index}
            className="dot"
            disabled={index === activeIndex}
            onClick={() => {
              setActiveIndex(index);
            }}
          />
        );
        /* eslint-enable */
      })}
    </div>
  );
};

export default Slide;
