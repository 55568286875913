import { getProductOnboardingAPI } from "Actions/Account.actions";
import Plans from "Components/Organization/Admin/Plans/Plans.component";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const PlansContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductOnboardingAPI());
  }, []);

  return <Plans {...props} />;
};

export default PlansContainer;
