import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import userImage from "Assets/images/anonymous.png";
import noContent from "Assets/images/organizations/no-content.png";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator, classnames } from "Utils";
import { isEmail, isOdd } from "Utils/Helpers/functions.helpers";
import useLocalStorage from "Utils/Hooks/useLocalStorage";
import StatusBadge from "UI/StatusBadge/StatusBadge.component";
import { Button, ExpandableTable, IconButton, Input, Modal, TextButton } from "UI";

import "./InviteTeamMembersModal.styles.scss";

const InviteTeamMembersModal = ({ onClose, translate }) => {
  const [email, setEmail] = useState("");
  const [toBeInvitedUsers, setToBeInvitedUsers] = useLocalStorage("toBeInvitedUsers", []);
  const inviteEnabled = isEmail(email);
  const [initialRender, setInitialRender] = useState(true);
  const emailInputRef = React.useRef(null);

  const { data: invitationsData, refetch: refetchInvitations } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_INVITATIONS],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_INVITATIONS);
    },
    onSuccess: (data) => {
      const { invitations } = data.data;
      const invitationMails = invitations.map((item) => item.attributes.email);
      setToBeInvitedUsers((prev) => prev.filter((item) => !invitationMails.includes(item)));
    },
  });

  const checkInvitationMutation = useMutation({
    mutationFn: (data) => {
      if (toBeInvitedUsers.includes(email)) {
        throw new Error("User already invited");
      }
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_INVITATIONS_CHECK, data);
    },

    onSuccess: () => {
      setToBeInvitedUsers((prev) => [email, ...prev]);
      setEmail("");
    },
  });

  const bulkInviteMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_INVITATIONS_BULK, data);
    },
    onSuccess: () => {
      refetchInvitations();
    },
  });

  const removeInviteMutation = useMutation({
    mutationFn: (id) => {
      return apiGenerator("delete")(API_ENDPOINTS.ORGANIZATION_INVITATION(id));
    },
    onSuccess: () => {
      refetchInvitations();
    },
  });

  useEffect(() => {
    const keydownHandler = (event) => {
      // if key is letter or number or
      if (event.keyCode >= 48 && event.keyCode <= 90) {
        setEmail(event.key);
        checkInvitationMutation.reset();
      }

      // if key is backspace
      if (event.keyCode === 27 || event.keyCode === 8) {
        setEmail("");
        checkInvitationMutation.reset();
      }
    };

    if (emailInputRef?.current && !initialRender) {
      emailInputRef.current.focus();
    }

    if (checkInvitationMutation?.isError) {
      window.addEventListener("keydown", keydownHandler);
    }

    setInitialRender(false);

    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
  }, [checkInvitationMutation?.isError]);

  const columns = [
    { name: "User", weight: 30 },
    { name: "Status", weight: 20 },
    { name: "button", weight: 1, hide: true },
  ];

  const toBeInvitedUsersTableData =
    toBeInvitedUsers.map((item, index) => {
      return {
        key: item,
        user: (
          <div className="user-cell">
            <img src={userImage} alt="user" />
            <div className="user-info-container">
              <span className="user-name"> {item}</span>
            </div>
          </div>
        ),
        status: <StatusBadge status="send-invitation" color={isOdd(index) && "gray"} />,
        button: (
          <IconButton
            name="close"
            tiny
            onClick={() => {
              setToBeInvitedUsers((prev) => prev.filter((i) => i !== item));
            }}
          />
        ),
      };
    }) || [];

  const pendingInvitationsTableData =
    invitationsData?.data?.invitations?.map((item, index) => {
      const { email } = item.attributes;
      return {
        key: email,
        user: (
          <div className="user-cell">
            <img src={userImage} alt="user" />
            <div className="user-info-container">
              <span className="user-name"> {email}</span>
            </div>
          </div>
        ),
        status: (
          <StatusBadge status="pending-invitation" color={isOdd(index + toBeInvitedUsersTableData.length) && "gray"} />
        ),
        button: (
          <IconButton
            name="close"
            tiny
            onClick={() => {
              removeInviteMutation.mutate(item.id);
            }}
          />
        ),
      };
    }) || [];

  const data = [...toBeInvitedUsersTableData, ...pendingInvitationsTableData];

  const handleEnterKeyUp = (event) => {
    event.preventDefault();

    if (event.keyCode === 13) {
      checkInvitationMutation.mutate({ email });
    }
  };

  return (
    <Modal
      className="invite-team-members-modal"
      closeAction={onClose}
      topRightIcon="close"
      headerText={translate("organizationHome.inviteTeamMembersModal.header.title")}
      descriptionText={translate("organizationHome.inviteTeamMembersModal.header.description")}
      topRightIconAction={onClose}
      overrideButton={
        toBeInvitedUsers.length > 0 && (
          <Button
            text="Send Invitation Emails"
            onClick={() => {
              bulkInviteMutation.mutate({ emails: toBeInvitedUsers });
            }}
            disabled={toBeInvitedUsers.length === 0}
          />
        )
      }
    >
      <div className="invite-team-members-container">
        <div className="invite-bar">
          <div className="team-member-input">
            {checkInvitationMutation?.isError ? (
              <div className={classnames(["invited-user", "failed"])}>
                <p>{email}</p>{" "}
                <IconButton
                  className="close-icon"
                  onClick={() => {
                    setEmail("");
                    checkInvitationMutation.reset();
                  }}
                  name="close"
                  color="red"
                />
              </div>
            ) : (
              <Input
                className="invite-box"
                placeholder="Email Address"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value.trim());
                }}
                onKeyUp={handleEnterKeyUp}
                inputRef={emailInputRef}
                maxLength={40}
              />
            )}
          </div>
          <TextButton
            className="team-invite-button"
            text="Add"
            color="aqua-light"
            onClick={() => {
              checkInvitationMutation.mutate({ email });
            }}
            disabled={!inviteEnabled}
          />
        </div>

        <div className={classnames(["team-user-list", data?.length === 0 && "no-invitation"])}>
          {data?.length > 0 ? (
            <ExpandableTable className="team-invite-table" data={data} columns={columns} hideColumnNames />
          ) : (
            <div className="no-content">
              <img src={noContent} />
              <p>You haven’t added any team members, yet.</p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default InviteTeamMembersModal;
