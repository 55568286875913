import React, { useEffect } from "react";
import { APP_SCOPES, ROOT_PATHS } from "Constants/global.constants";
import { useAppScope } from "Utils/Hooks/useAppScope";
import routes from "Constants/Route.constants";

import ProtectedRouteWithTranslation from "Utils/Components/Route/Helpers/ProtectedRouteWithTranslation";

const OrganizationAdminRoute = (props) => {
  const { path, account, history } = props;
  const { appScope, setAppScope } = useAppScope();

  const {
    organization_admin: organizationAdmin,
    organization_member: organizationMember,
    organization_machine: organizationMachine,
    organization_invitation_present: organizationInvitationPresent,
  } = account?.account?.attributes || {};

  useEffect(() => {
    if (appScope !== APP_SCOPES.team_admin) {
      history.push(ROOT_PATHS[appScope]);
      return;
    }

    if (organizationInvitationPresent) {
      history.push(routes.organization);
    }

    if (path === routes.organization) {
      if (organizationAdmin && !organizationInvitationPresent) {
        history.push(routes.organizationAdminHome);
      }
      if (organizationMember) {
        setAppScope(APP_SCOPES.team_member);
      }
    }

    if (!organizationAdmin) {
      history.push(routes.organization);
    }

    if (!path.includes("team")) {
      history.push(routes.organization);
    }

    if (!organizationAdmin && !organizationMember && !organizationMachine) {
      history.push(routes.organization);
    }

    if (organizationMember) {
      if (!path.includes("team/member")) {
        history.push(routes.organizationMemberHome);
      }
    }
  }, [path, account.getAccountCTX.status, organizationAdmin, organizationMember, appScope]);

  return <ProtectedRouteWithTranslation {...props} />;
};

export default OrganizationAdminRoute;
