/* eslint-disable import/no-dynamic-require */
import React from "react";
import _ from "lodash";
import { Translate } from "react-localize-redux";

import { DashboardSubScreenLayout, PaymentInfoLine } from "Utils";
import { Button, ConfirmationModal, Icon, CircularSlider, Slider, TopLoader } from "UI";
import { REQUEST_STATUS, DISK_LOCATIONS } from "Constants/global.constants";

import "./Storage.style.scss";

const RESET_ERROR_TIME = 4000;

const STORAGE_BY_STEP = {
  1: 75,
  2: 125,
  3: 175,
  4: 225,
  5: 275,
  6: 325,
  7: 375,
  8: 425,
  9: 475,
  10: 525,
};

const Storage = ({
  account,
  subscriptions,
  setShouldShowStorageScreen,
  translate,
  resetUpgradeDiskSizeSubscriptionAPI,
  upgradeDiskSizeSubscriptionAPI,
  diskLocation,
}) => {
  const [image, setImage] = React.useState(null);
  const [selectedStorageLevel, setSelectedStorageLevel] = React.useState();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [error, setError] = React.useState();

  const { upgradeDiskSizeSubscriptionCTX } = subscriptions;
  const subscription = account.account.attributes.subscriptions[0];

  const currentDiskSize = subscription.attributes.disk_size;
  const monthlyIncrease = parseFloat((STORAGE_BY_STEP[selectedStorageLevel] - currentDiskSize) / 10).toFixed(2);

  const increaseUntilEndOfMonth = parseFloat((account.account.remainingDaysToRenew * monthlyIncrease) / 30).toFixed(2);

  const balance = account.account.attributes.balance || 0;

  React.useEffect(() => {
    resetUpgradeDiskSizeSubscriptionAPI();
  }, []);

  React.useEffect(() => {
    setSelectedStorageLevel(_.findKey(STORAGE_BY_STEP, (value) => value === currentDiskSize));
  }, [subscription]);

  React.useEffect(() => {
    if (selectedStorageLevel) {
      // eslint-disable-next-line global-require
      setImage(require(`Assets/images/storage/storage-${selectedStorageLevel}.png`));
    }
  }, [selectedStorageLevel]);

  React.useEffect(() => {
    let resetErrorTimeout;

    if (upgradeDiskSizeSubscriptionCTX.status === REQUEST_STATUS.SUCCESS) {
      setShouldShowStorageScreen(false);
    } else if (upgradeDiskSizeSubscriptionCTX.status === REQUEST_STATUS.FAILURE) {
      const { client_code: clientCode } = upgradeDiskSizeSubscriptionCTX;
      setError(clientCode);
      resetErrorTimeout = setTimeout(resetUpgradeDiskSizeSubscriptionAPI, RESET_ERROR_TIME);
    } else {
      setError(false);
    }
    return () => {
      clearTimeout(resetErrorTimeout);
    };
  }, [upgradeDiskSizeSubscriptionCTX]);

  const handleConfirm = () => {
    upgradeDiskSizeSubscriptionAPI(account.accessToken, STORAGE_BY_STEP[selectedStorageLevel], "balance");
  };

  const actionButton = (
    <div className="storage-action">
      {currentDiskSize !== STORAGE_BY_STEP[selectedStorageLevel] && (
        <PaymentInfoLine price={increaseUntilEndOfMonth} translate={translate} />
      )}
      <Button
        block
        disabled={currentDiskSize === STORAGE_BY_STEP[selectedStorageLevel]}
        text={translate("addStorage.action.buttonText")}
        onClick={() => setShowConfirm(true)}
      />
    </div>
  );

  return (
    <>
      {upgradeDiskSizeSubscriptionCTX.status === REQUEST_STATUS.PENDING && <TopLoader />}
      <DashboardSubScreenLayout
        headerTitle={translate("addStorage.header.title")}
        headerDescription={translate("addStorage.header.description")}
        balance={balance}
        balanceTitle={translate("usageHistory.balanceTitle")}
        translate={translate}
        showBalance
        showCloseIcon
        onClose={() => {
          setShouldShowStorageScreen(false);
        }}
        actionContent={actionButton}
      >
        <div className="vg-storage">
          <div className="left">
            <div className="header">{translate("addStorage.content.title")}</div>
            <div className="disk-size">
              {currentDiskSize} GB{" "}
              {currentDiskSize !== STORAGE_BY_STEP[selectedStorageLevel] && !!selectedStorageLevel && (
                <>
                  <Icon name="arrow-right" color="gray-3" /> {STORAGE_BY_STEP[selectedStorageLevel]} GB
                  <span className="monthly-increase">
                    + ${monthlyIncrease} / {translate("addStorage.content.month")}
                  </span>
                </>
              )}
            </div>
            <div className="slider">
              <Slider
                minimumAllowedStep={_.findKey(STORAGE_BY_STEP, (value) => value === currentDiskSize)}
                stepCount={10}
                step={selectedStorageLevel}
                setStep={setSelectedStorageLevel}
              />
            </div>
            <div className="description">{translate("addStorage.content.description")}</div>
            {diskLocation === DISK_LOCATIONS.ebs && (
              <div className="description">
                {translate("addStorage.confirm.info", { resizeDuration: currentDiskSize / 5 })}
              </div>
            )}
            <div className="circular-slider">
              <CircularSlider
                minimumAllowedStep={_.findKey(STORAGE_BY_STEP, (value) => value === currentDiskSize)}
                stepCount={10}
                step={selectedStorageLevel}
                stepSize={1}
                setStep={setSelectedStorageLevel}
                displayValue={
                  <div className="storage-disk-size">
                    <span className="primaryTextInCircularSlider">{STORAGE_BY_STEP[selectedStorageLevel]} GB</span>
                    {STORAGE_BY_STEP[selectedStorageLevel] !== currentDiskSize && (
                      <span className="secondaryTextInCircularSlider">
                        ${monthlyIncrease} / {translate("addStorage.content.month")}
                      </span>
                    )}
                  </div>
                }
              />
            </div>
          </div>
          <div className="right">
            <img className="storage-image" src={image} alt="Storage" />
          </div>
          {showConfirm && (
            <ConfirmationModal
              headerText={translate("addStorage.confirm.header")}
              descriptionText={
                <>
                  <div>{translate("addStorage.confirm.description")}</div>
                  {diskLocation === DISK_LOCATIONS.ebs
                    ? translate("addStorage.confirm.info", { resizeDuration: currentDiskSize / 5 })
                    : ""}
                  {error && (
                    <div className="error">
                      <Translate
                        id={`addStorage.error.${error}`}
                        options={{ onMissingTranslation: () => translate("addStorage.error.default") }}
                      />
                    </div>
                  )}
                </>
              }
              confirmText={translate("addStorage.confirm.confirm")}
              confirmAction={handleConfirm}
              closeAction={() => setShowConfirm(false)}
              disableConfirmButton={
                upgradeDiskSizeSubscriptionCTX.status === REQUEST_STATUS.PENDING ||
                upgradeDiskSizeSubscriptionCTX.status === REQUEST_STATUS.FAILURE
              }
              secondaryText={translate("addStorage.confirm.secondary")}
              secondaryAction={() => {
                setShowConfirm(false);
              }}
            />
          )}
        </div>
      </DashboardSubScreenLayout>
    </>
  );
};

export default Storage;
