import React from "react";

import Menu from "./Components/Menu/Menu.component";
import "./DashboardLayout.style.scss";

const DashboardLayout = (props) => {
  const { path, children, translate, history } = props;

  return (
    <div className="dashboard-base-container">
      <Menu path={path} translate={translate} history={history} />
      <div className="dashboard-content-container" id="dashboard-content-container">
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
