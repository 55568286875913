import React, { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator } from "Utils";
import { Modal, TextButton } from "UI";
import ExpandableAnimation from "UI/ExpandableAnimation/ExpandableAnimation.component";
import ModalResult from "Components/Organization/Admin/Components/ModalResult/ModalResult.component";
import "./UpgradeSharedFileStorageModal.styles.scss";

const UpgradeSharedFileStorageModal = ({ onClose, translate, history }) => {
  const { organization } = useSelector((state) => state.organizations) || {};
  const { organizationPlan } = useSelector((state) => state.organizations?.getOrganizationPlanCTX) || {};
  const { file_storage_options: fileStorageOptions } = organizationPlan?.attributes || {};
  const fileStorageSizes = fileStorageOptions?.map((diskOption) => diskOption.attributes.size);
  const fileStorageSize = organization?.attributes?.subscription?.attributes?.shared_file_storage_size;

  const [fileStorageSizeIndex, setFileStorageSizeIndex] = useState(fileStorageSizes.indexOf(fileStorageSize));
  const [calculationData, setCalculationData] = useState(null);

  const calculateFileStorageSizeMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_CALCULATE_SHARED_FILE_STORAGE, data);
    },
    onSuccess: (data) => {
      setCalculationData(data);
    },
  });

  const {
    current_month_price: currentMonthPrice,
    renewal_price: renewalPrice,
    is_balance_sufficient: isBalanceSufficient,
  } = calculationData?.data || {};

  const upgradeFileStorageMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_UPGRADE_SHARED_FILE_STORAGE, data);
    },
  });

  const { isSuccess, isError } = upgradeFileStorageMutation;

  useEffect(() => {
    calculateFileStorageSizeMutation.mutate({
      file_storage_size: fileStorageSizes[fileStorageSizeIndex],
    });
  }, [fileStorageSizeIndex]);

  if (!fileStorageSizes) {
    return null;
  }

  return (
    <Modal
      headerText={translate("organizationFiles.sharedFileStorageModal.header.title")}
      descriptionText={translate("organizationFiles.sharedFileStorageModal.header.description")}
    >
      <div className="file-storage-modal-content">
        {isSuccess || isError ? (
          <ModalResult
            success={isSuccess}
            successHeader={translate("organizationFiles.sharedFileStorageModal.success.title")}
            successDescription={translate("organizationFiles.sharedFileStorageModal.success.description")}
            failureHeader={translate("organizationFiles.sharedFileStorageModal.error.title")}
            failureDescription={translate("organizationFiles.sharedFileStorageModal.error.description")}
            failure={isError}
            onClose={onClose}
          />
        ) : (
          <>
            <div className="storage-buttons">
              <button
                type="button"
                label="Decrement"
                disabled={fileStorageSizes.indexOf(fileStorageSize) === fileStorageSizeIndex}
                onClick={() => {
                  setFileStorageSizeIndex((prev) => prev - 1);
                }}
              >
                -
              </button>
              <p>{fileStorageSizes[fileStorageSizeIndex]} GB</p>
              <button
                type="button"
                label="Increment"
                disabled={fileStorageSizeIndex === fileStorageSizes.length - 1}
                onClick={() => {
                  setFileStorageSizeIndex((prev) => prev + 1);
                }}
              >
                +
              </button>
            </div>
            <ExpandableAnimation isOpen={fileStorageSizeIndex !== fileStorageSizes.indexOf(fileStorageSize)}>
              <div className="storage-price-info">
                {isBalanceSufficient ? (
                  <>
                    <Translate
                      id="organizationFiles.sharedFileStorageModal.priceInfo"
                      data={{ price: currentMonthPrice?.toFixed(2), renewalPrice }}
                    />
                  </>
                ) : (
                  <div className="insufficient-balance">
                    <Translate id="organizationFiles.sharedFileStorageModal.insufficientBalance" />
                    <TextButton
                      text="Add Balance"
                      color="purple-main"
                      onClick={() => {
                        history.push({
                          pathname: routes.organizationAdminBilling,
                          state: { showDepositBalanceModal: true },
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </ExpandableAnimation>

            <div className="buttons-container">
              <TextButton text="Cancel" color="gray-3" onClick={onClose} />
              <TextButton
                text="Increase Storage"
                color="aqua-main"
                disabled={fileStorageSizes.indexOf(fileStorageSize) === fileStorageSizeIndex || !isBalanceSufficient}
                onClick={() => {
                  upgradeFileStorageMutation.mutate({
                    file_storage_size: fileStorageSizes[fileStorageSizeIndex],
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpgradeSharedFileStorageModal;
