import React, { useCallback, useMemo, useRef } from "react";
import { Loader, classnames } from "Utils";
import { isOdd } from "Utils/Helpers/functions.helpers";
import { Icon, IconButton, TextButton } from "UI";
import "./ExpandableTable.styles.scss";

const ExpandableRow = ({
  rowID,
  keyNames,
  columnWidths,
  rowData,
  rowIndex,
  hideVerticalBar,
  rowRef,
  disabledCustom,
  disabledAnimation,
  hoverColumnName,
  onboardingModalCurrentStep,
  onboarding,
  shownColumns,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { buttonText, caret, expandableContent, expanded, disabled, onRowClick, className } = rowData;
  const [hovering, setHovering] = React.useState(false);

  const rowCells = keyNames.map((keyName, index) => {
    const cellKey = `body-${rowID}-${keyName}`;
    const { centered } = shownColumns[index];

    const style = { width: columnWidths[keyName] };
    return (
      <div className={classnames(["table-cell", centered && "centered"])} key={cellKey} style={style}>
        {caret && index === 0 && expandableContent && (
          <IconButton
            name={isOpen || expanded ? "caret-up" : "caret-down"}
            tiny
            className="expand-table-icon"
            onClick={() => {
              if (!disabledCustom) setIsOpen(!isOpen);
            }}
          />
        )}
        {keyName === hoverColumnName ? hovering && rowData[keyName] : rowData[keyName]}
        {buttonText && index + 1 === keyNames.length && expandableContent && (
          <TextButton
            text={buttonText}
            className={classnames(["expand-table-button"])}
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
      </div>
    );
  });
  return (
    <>
      <div className="entire-table-row">
        <div
          className={classnames([
            "table-row",
            !isOdd(rowIndex) && "gray",
            disabled && "disabled",
            onRowClick && "clickable",
            className && className,
            rowData.highlightClass,
          ])}
          ref={rowRef}
          onMouseEnter={() => {
            setHovering(true);
          }}
          onMouseLeave={() => {
            setHovering(false);
          }}
          onClick={() => {
            if (onRowClick) {
              onRowClick(rowData);
            }
          }}
        >
          {rowCells}
        </div>
        <div
          className={classnames([
            "expandable-box-wrapper",
            (isOpen || expanded || (rowIndex === 0 && onboardingModalCurrentStep === 0 && onboarding)) && "open",
            disabledAnimation && "disabled-animation",
          ])}
        >
          <div
            className={classnames([
              "expandable-box",
              (isOpen || expanded || (rowIndex === 0 && onboardingModalCurrentStep === 0 && onboarding)) && "open",
            ])}
          >
            {!hideVerticalBar && <div className="vertical-bar" />}
            <div className="expandable-content">{rowData.expandableContent}</div>
          </div>
        </div>
      </div>
      {(isOpen || expanded) && <div className="horizontal-divider" />}
    </>
  );
};
const ExpandableTable = ({
  data,
  columns,
  hideVerticalBar,
  hideColumnNames,
  className: classNameFromProps,
  isFetchingNextPage,
  hasNextPage,
  onPageEnd,
  hoverColumnName,
  tableRef,
  isLoading,
  onboardingModalCurrentStep,
  onboarding,
  disabled,
  disabledAnimation,
  emptyContent,
  key,
}) => {
  const tableBodyRef = useRef();
  const observer = useRef();
  const lastRowRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          onPageEnd();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, hasNextPage],
  );

  const shownColumns = columns.filter((col) => col.weight !== 0);
  const keyNames = shownColumns.map((col) => col.keyName || col.name.toLowerCase());

  const totalWeight = columns.reduce((acc, col) => {
    return acc + col.weight;
  }, 0);
  const columnWidths = Object.fromEntries(
    columns.map((col) => {
      const percent = Math.round(100 * (col.weight / totalWeight));
      return [col.keyName || col.name.toLowerCase(), `${percent}%`];
    }),
  );

  const headerCells = columns.map((col) => {
    const content = () => {
      if (col.override) {
        return col.override;
      }
      return col.hide ? "" : col.name;
    };
    const columnWidth = { width: columnWidths[col.keyName || col.name.toLowerCase()] };
    return (
      <div
        key={`header-cell-${col.keyName || col.name}`}
        className={classnames(["header-cell", col.centered && "centered"])}
        style={columnWidth}
      >
        {!hideColumnNames && content()}
      </div>
    );
  });

  const bodyRows = data?.map((rowData, rowIndex) => {
    const rowKey = rowData.key || rowData.id;

    return (
      <ExpandableRow
        key={rowKey}
        rowID={rowKey}
        keyNames={keyNames}
        columnWidths={columnWidths}
        rowData={rowData}
        hideVerticalBar={hideVerticalBar}
        rowIndex={rowIndex}
        rowRef={data.length - 1 === rowIndex ? lastRowRef : null}
        hoverColumnName={hoverColumnName}
        onboardingModalCurrentStep={onboardingModalCurrentStep}
        onboarding={onboarding}
        disabledCustom={disabled}
        disabledAnimation={disabledAnimation}
        shownColumns={shownColumns}
      />
    );
  });

  const isBodyOverflowed = useMemo(() => {
    return tableBodyRef.current?.scrollHeight > tableBodyRef.current?.clientHeight;
  }, [tableBodyRef.current, tableBodyRef.current?.scrollHeight, tableBodyRef.current?.clientHeight]);

  if (isLoading) {
    return (
      <div className={classnames(["vg-expandable-table", classNameFromProps])} ref={tableRef}>
        <div className="vg-table-loader">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className={classnames(["vg-expandable-table", classNameFromProps])} ref={tableRef} key={key}>
      <div className={classnames(["header-line", isBodyOverflowed && "overflowed"])}>{headerCells}</div>
      <div className="table-body" id="table-body" ref={tableBodyRef}>
        {emptyContent || bodyRows}
        {isFetchingNextPage && (
          <div className="loading-new-page">
            <Icon name="loader" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandableTable;
