import { all, put, takeLatest } from "redux-saga/effects";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";
import * as OrganizationConstants from "Constants/Organization.constants";
import * as PaymentConstants from "Constants/Dashboard/Payment.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";
import apiGenerator from "Utils/Helpers/api.helpers";

function* getOrganizationSaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.ORGANIZATION);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: OrganizationConstants.GET_ORGANIZATION_API_SUCCESS,
        payload: { organization: response.data.organization, accessType: response.data.access_type },
      });
    } else {
      yield put({
        type: OrganizationConstants.GET_ORGANIZATION_API_FAILURE,
        payload: apiErrorHandler(response),
      });
    }
  } catch (err) {
    yield put({
      type: OrganizationConstants.GET_ORGANIZATION_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getOrganizationPlanSaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_PLAN);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: OrganizationConstants.GET_ORGANIZATION_PLAN_API_SUCCESS,
        payload: { organizationPlan: response.data },
      });
    }
  } catch (err) {
    yield put({
      type: OrganizationConstants.GET_ORGANIZATION_PLAN_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getSeatsMeSaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_SEATS_ME);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: OrganizationConstants.GET_SEATS_ME_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: OrganizationConstants.GET_SEATS_ME_API_FAILURE,
        payload: apiErrorHandler(response),
      });
    }
  } catch (err) {
    yield put({
      type: OrganizationConstants.GET_SEATS_ME_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* createOrganizationSeatsSaga(action) {
  const api = apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS, action.payload);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      if (response.data.payment_required) {
        yield put({
          type: PaymentConstants.PAYMENT_3DSECURE_REQUESTED,
          payload: {
            data: response.data,
            completeEndpoint: API_ENDPOINTS.ORGANIZATION_SEATS_COMPLETE,
            successType: OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_SUCCESS,
            failureType: OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_FAILURE,
          },
        });
      } else {
        yield put({
          type: OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_SUCCESS,
        });
      }
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(OrganizationConstants.GET_ORGANIZATION_API_PENDING, getOrganizationSaga),
    takeLatest(OrganizationConstants.GET_ORGANIZATION_PLAN_API_PENDING, getOrganizationPlanSaga),
    takeLatest(OrganizationConstants.GET_SEATS_ME_API_PENDING, getSeatsMeSaga),
    takeLatest(OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_PENDING, createOrganizationSeatsSaga),
  ]);
}
