import React, { useState, useEffect } from "react";

import { Loader, getItemFromLocalStorage, FeedbackModal, isElectronApp, saveItemToLocalStorage, isTablet } from "Utils";
import { BoardingModal } from "UI";
import { REQUEST_STATUS, LOCAL_STORAGE, FEEDBACK_TYPES, DISK_LOCATIONS } from "Constants/global.constants";
import routes from "Constants/Route.constants";
import ReferralSuccessImage from "Assets/images/referral/referral-success.png";
import ComputerPerformance from "./ComputerPerformance/ComputerPerformance.component";
import Storage from "./Storage/Storage.component";
import Banner from "./components/Banner.component";
import ComputerActionButton from "./components/ComputerActionButton.component";
import MachineTypeSelection from "./components/MachineTypeSelection.component";
import WorkstationOverview from "./components/WorkstationOverview.component";
import NewComputer from "./NewComputer/NewComputer.component";
import AppDownloadAnnouncement from "./AppDownloadAnnouncement/AppDownloadAnnouncement";
import MigrateRegion from "./MigrateRegion/MigrateRegion.component";
import "./Home.styles.scss";

const Home = (props) => {
  const {
    history,
    match,
    account,
    referral,
    workstations,
    subscriptions,
    hinterType,
    setHinterType,
    updateCreateWorkstationConfig,
    getWorkstationCurrentSessionAPI,
    updateWorkstationStatusAPI,
    updateWorkstationAutoStopConfigurationAPI,
    resetUpgradeDiskSizeSubscriptionAPI,
    upgradeDiskSizeSubscriptionAPI,
    setUserRememberMachineTypeAPI,
    setUserMultiGpuConsentAPI,
    resetUpdateWorkstationStatusAPI,
    getWorkstationsAPI,
    translate,
  } = props;

  const {
    getWorkstationCurrentSessionCTX,
    getWorkstationMachineTypesCTX,
    getWorkstationsCTX,
    updateWorkstationStatusCTX,
  } = workstations;

  const { has_referrer: hasReferrer, verified } = account?.account?.attributes || {};
  const { referreReward } = referral.getRewardsCTX.data || {};

  const workstation = workstations.workstations && workstations.workstations[0];

  const [selectedMachineType, setSelectedMachineType] = useState(null);
  const [isInGracePeriod, setIsInGracePeriod] = useState(false);
  const [isInTeamGracePeriod, setIsInTeamGracePeriod] = useState(false);
  const [isLoadingWorkstations, setIsLoadingWorkstations] = useState(false);
  const [displayAllMachineTypes, setDisplayAllMachineTypes] = useState(false);
  const [showInvitedBoardingModal, setShowInvitedBoardingModal] = useState(
    getItemFromLocalStorage(LOCAL_STORAGE.showInviteBoarding) !== false && hasReferrer,
  );

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedBackRating, setFeedBackRating] = useState(0);
  const [shouldShowMachineTypeSelection, setShouldShowMachineTypeSelection] = useState(false);
  const [shouldSavePerformance, setShouldSavePerformance] = useState(account.account.attributes.remember_machine_type);

  const [showAppDownloadAnnouncement, setShowAppDownloadAnnouncement] = useState(
    !isElectronApp && getItemFromLocalStorage(LOCAL_STORAGE.showAppDownloadAnnouncement, true) && !isTablet,
  );

  const activeSubscription =
    account.account.attributes.subscriptions &&
    account.account.attributes.subscriptions.length > 0 &&
    account.account.attributes.subscriptions[0].attributes;

  const diskLocation = workstations?.workstations[0]?.attributes?.disk?.id ? DISK_LOCATIONS.ebs : DISK_LOCATIONS.ami;

  useEffect(() => {
    if (getWorkstationsCTX.status === REQUEST_STATUS.NOT_DEFINED) {
      setIsLoadingWorkstations(true);
    } else if (getWorkstationsCTX.status === REQUEST_STATUS.PENDING) {
      // Due to polling we'll encounter the pending state often. Do not switch to loading in that state.
    } else {
      setIsLoadingWorkstations(false);
    }
  }, [getWorkstationsCTX.status]);

  useEffect(() => {
    if (
      !selectedMachineType &&
      getWorkstationMachineTypesCTX &&
      getWorkstationMachineTypesCTX.status === REQUEST_STATUS.SUCCESS &&
      getWorkstationsCTX.status === REQUEST_STATUS.SUCCESS
    ) {
      setSelectedMachineType(getPerformanceOption());
    }
  }, [getWorkstationMachineTypesCTX, getWorkstationsCTX, workstation]);

  useEffect(() => {
    if (account.getAccountCTX.status === REQUEST_STATUS.SUCCESS && activeSubscription) {
      const { status: subscriptionStatus, end_at: subscriptionEndDate } = activeSubscription;

      if (subscriptionStatus === "waiting_team_subscription_payment") {
        setIsInTeamGracePeriod(true);
      } else {
        setIsInTeamGracePeriod(false);
      }
      if (new Date() > new Date(subscriptionEndDate) && subscriptionStatus === "payment_required") {
        setIsInGracePeriod(true);
      } else {
        setIsInGracePeriod(false);
      }
    }
  }, [account.getAccountCTX.status, activeSubscription.status]);

  const getPerformanceOption = () => {
    // Get Performance Option From LocalStorage
    const machineTypeId =
      getItemFromLocalStorage(LOCAL_STORAGE.machineType) ||
      (workstation && workstation.attributes.machine.attributes.machine_type_id);
    const workstationMachineTypes = getWorkstationMachineTypesCTX.data.machine_types;

    if (!machineTypeId) {
      // SET DEFAULT MACHINE_TYPE BY CHANGING [0] from here
      let selectedMachine = workstationMachineTypes[0];
      let selectedMachinePrice = workstationMachineTypes[0].attributes.price_by_hour;
      // Find the best machine with credit
      workstationMachineTypes.forEach((machine) => {
        if (
          !selectedMachine.attributes.remaining_credit ||
          (machine.attributes.price_by_hour > selectedMachinePrice &&
            machine.attributes.remaining_credit >= selectedMachine.attributes.remaining_credit)
        ) {
          selectedMachine = machine;
          selectedMachinePrice = machine.attributes.price_by_hour;
        }
      });
      return parseInt(selectedMachine.attributes.details.id, 10);
    }
    return parseInt(machineTypeId, 10);
  };

  const shouldShowStorageScreen = match.url.includes(routes.addStorage);

  const setShouldShowStorageScreen = (state) => {
    if (state) {
      history.push(routes.addStorage);
    } else {
      history.push(routes.home);
    }
  };
  const shouldShowComputerPerformanceScreen = match.url.includes(routes.performance);

  const setShouldShowComputerPerformanceScreen = (state) => {
    if (state) {
      history.push(routes.performance);
    } else {
      history.push(routes.home);
    }
  };

  const shouldShowMigrateRegion = match.url.includes(routes.migrateRegion);

  const setShouldShowMigrateRegion = (state) => {
    if (state) {
      history.push(routes.migrateRegion);
    } else {
      history.push(routes.home);
    }
  };
  const handleAnnouncementClose = () => {
    setShowAppDownloadAnnouncement(false);
    saveItemToLocalStorage(LOCAL_STORAGE.showAppDownloadAnnouncement, false);
  };

  let machineTypesWrapperClassName = "machine-type-selection-wrapper";

  if (shouldShowMachineTypeSelection) {
    if (displayAllMachineTypes) {
      machineTypesWrapperClassName += " full-height";
    } else {
      machineTypesWrapperClassName += " open";
    }
  }

  if (isLoadingWorkstations) {
    return <Loader />;
  } else if (shouldShowStorageScreen) {
    return (
      <Storage
        account={account}
        subscriptions={subscriptions}
        setShouldShowStorageScreen={setShouldShowStorageScreen}
        resetUpgradeDiskSizeSubscriptionAPI={resetUpgradeDiskSizeSubscriptionAPI}
        upgradeDiskSizeSubscriptionAPI={upgradeDiskSizeSubscriptionAPI}
        translate={translate}
        diskLocation={diskLocation}
      />
    );
  } else if (shouldShowComputerPerformanceScreen) {
    return (
      <ComputerPerformance
        account={account}
        getWorkstationMachineTypesCTX={getWorkstationMachineTypesCTX}
        selectedMachineType={selectedMachineType}
        setSelectedMachineType={setSelectedMachineType}
        setShouldShowComputerPerformanceScreen={setShouldShowComputerPerformanceScreen}
        setUserRememberMachineTypeAPI={setUserRememberMachineTypeAPI}
        shouldSavePerformance={shouldSavePerformance}
        setShouldSavePerformance={setShouldSavePerformance}
        translate={translate}
      />
    );
  } else if (shouldShowMigrateRegion) {
    return (
      <MigrateRegion
        workstation={workstation}
        onClose={() => {
          getWorkstationsAPI();
          setShouldShowMigrateRegion(false);
        }}
        translate={translate}
      />
    );
  } else if (workstation) {
    return (
      <div className="vg-home-container">
        <div className="vg-banner-container">
          <Banner
            hinterType={hinterType}
            setHinterType={setHinterType}
            isTeamAccount={account.isTeamAccount}
            workstation={workstation}
            referral={referral}
            interest={account.account.attributes.interests[0]}
            updateWorkstationStatusCTX={updateWorkstationStatusCTX}
            getWorkstationCurrentSessionCTX={getWorkstationCurrentSessionCTX}
            getWorkstationCurrentSessionAPI={getWorkstationCurrentSessionAPI}
            setShowFeedbackModal={setShowFeedbackModal}
            feedBackRating={feedBackRating}
            setFeedBackRating={setFeedBackRating}
            small={shouldShowMachineTypeSelection}
            closed={shouldShowMachineTypeSelection && displayAllMachineTypes}
            history={history}
            translate={translate}
          />
          <div className={machineTypesWrapperClassName}>
            <MachineTypeSelection
              selectedMachineType={selectedMachineType}
              setSelectedMachineType={setSelectedMachineType}
              account={account}
              getWorkstationMachineTypesCTX={getWorkstationMachineTypesCTX}
              shouldShowMachineTypeSelection={shouldShowMachineTypeSelection}
              setShouldShowMachineTypeSelection={setShouldShowMachineTypeSelection}
              displayAllMachineTypes={displayAllMachineTypes}
              setDisplayAllMachineTypes={setDisplayAllMachineTypes}
              translate={translate}
              history={history}
            />
          </div>
          <ComputerActionButton
            accessToken={account.accessToken}
            workstation={workstation}
            selectedMachineType={selectedMachineType}
            isInGracePeriod={isInGracePeriod}
            isInTeamGracePeriod={isInTeamGracePeriod}
            updateWorkstationStatusAPI={updateWorkstationStatusAPI}
            resetUpdateWorkstationStatusAPI={resetUpdateWorkstationStatusAPI}
            updateWorkstationStatusCTX={updateWorkstationStatusCTX}
            shouldSavePerformance={shouldSavePerformance}
            setShouldShowMachineTypeSelection={setShouldShowMachineTypeSelection}
            setUserMultiGpuConsentAPI={setUserMultiGpuConsentAPI}
            account={account}
            getWorkstationMachineTypesCTX={getWorkstationMachineTypesCTX}
            getWorkstationsAPI={getWorkstationsAPI}
            translate={translate}
          />
        </div>
        {!shouldShowMachineTypeSelection && showAppDownloadAnnouncement && !isElectronApp && (
          <AppDownloadAnnouncement handleAnnouncementClose={handleAnnouncementClose} translate={translate} />
        )}
        {!shouldShowMachineTypeSelection && (
          <WorkstationOverview
            accessToken={account.accessToken}
            isTeamAccount={account.isTeamAccount}
            workstation={workstation}
            machineUsageHistoryCTX={account.machineUsageHistoryCTX}
            account={account}
            selectedMachineType={selectedMachineType}
            subscriptions={account.account.attributes.subscriptions}
            getWorkstationMachineTypesCTX={getWorkstationMachineTypesCTX}
            updateWorkstationAutoStopConfigurationAPI={updateWorkstationAutoStopConfigurationAPI}
            setShouldShowStorageScreen={setShouldShowStorageScreen}
            setShouldShowComputerPerformanceScreen={setShouldShowComputerPerformanceScreen}
            setShouldShowMigrateRegion={setShouldShowMigrateRegion}
            isInGracePeriod={isInGracePeriod}
            translate={translate}
          />
        )}
        {showFeedbackModal && (
          <FeedbackModal
            feedBackRating={feedBackRating}
            setShowFeedbackModal={setShowFeedbackModal}
            type={FEEDBACK_TYPES.streaming}
            translate={translate}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <NewComputer
        account={account}
        workstations={workstations}
        updateCreateWorkstationConfig={updateCreateWorkstationConfig}
        history={history}
        translate={translate}
      />
      {showInvitedBoardingModal && verified && (
        <BoardingModal
          headerImages={[ReferralSuccessImage]}
          headerTexts={[translate("modals.invitedWelcome.header")]}
          descriptions={[translate("modals.invitedWelcome.description", { referreReward })]}
          buttonTexts={[translate("modals.invitedWelcome.buttonText")]}
          buttonAction={() => {
            setShowInvitedBoardingModal(false);
            saveItemToLocalStorage(LOCAL_STORAGE.showInviteBoarding, false);
          }}
        />
      )}
    </>
  );
};

export default Home;
