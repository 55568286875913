import React, { useEffect, useState } from "react";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { Button, ConfirmationModal, Modal, Table, TextButton } from "UI";
import moment from "moment";
import { Loader, apiGenerator, useAPI } from "Utils";
import { useDispatch } from "react-redux";
import { getApplicationsAPI } from "Actions/AppStreaming.actions";
import { useQuery } from "@tanstack/react-query";
import StreamError from "Components/AppStreaming/Modals/StreamError/StreamError.component";

import "./ApplicationVersionsModal.styles.scss";

const ApplicationVersionsModal = ({
  selectedAppID,
  setShowApplicationVersionsModal,
  setShowUploadAppVersionModal,
  translate,
}) => {
  const [selectedExecutableId, setSelectedExecutableId] = useState(null);
  const [showActivateConfirmationModal, setShowActivateConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const dispatch = useDispatch();

  const columns = [
    { name: "ID" },
    { name: "Name" },
    { name: "Executable" },
    { name: "Date" },
    { name: "Status", customWeight: 20 },
  ];

  const {
    api: executablesAPI,
    data: executables,
    executablesLoading,
  } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.APPLICATION_EXECUTABLES(selectedAppID),
  });

  const {
    data: checkBoostData,
    isLoading: checkBoostDataLoading,
    refetch: refetchCheckBoostData,
  } = useQuery({
    queryKey: ["checkBoostInProgress", selectedAppID.id],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_APPLICATION_CHECK_BOOST(selectedAppID));
    },
    cacheTime: 0,
  });

  const { api: updateActiveExecutableAPI, isLoading: updateExecutableLoading } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.UPDATE_ACTIVE_EXECUTABLE,
    type: "put",
    onSuccess: () => {
      executablesAPI();
      dispatch(getApplicationsAPI());
      refetchCheckBoostData();
    },
  });

  const { api: deleteExecutableAPI, isLoading: deleteExecutableLoading } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.DELETE_EXECUTABLE,
    type: "delete",
    onSuccess: () => {
      executablesAPI();
      dispatch(getApplicationsAPI());
    },
  });

  useEffect(() => {
    executablesAPI();
  }, []);

  const loading = executablesLoading || updateExecutableLoading || deleteExecutableLoading;

  const data = executables?.executables?.map((executable) => {
    const { active } = executable.attributes;
    return {
      id: `#${executables.executables.length - executables.executables.indexOf(executable)}`,
      name: executable.attributes.version_name,
      executable: executable.attributes.executable_name,
      date: moment(executable.attributes.created_at).format("DD.MM.YYYY"),
      status: active ? (
        <div className="status">
          <div />
          Active
        </div>
      ) : (
        <div className="action-buttons">
          <TextButton
            text={translate("appStreamingModals.applicationVersions.activate")}
            color="gray-3"
            onClick={() => {
              setSelectedExecutableId(executable.id);
              setShowActivateConfirmationModal(true);
            }}
            disabled={loading}
            className="hover-on-aqua-main"
          />
          <TextButton
            text="Delete"
            color="gray-3"
            onClick={() => {
              setSelectedExecutableId(executable.id);
              setShowDeleteConfirmationModal(true);
            }}
            disabled={loading}
            className="hover-on-aqua-main"
          />
        </div>
      ),
    };
  });

  if (showActivateConfirmationModal) {
    return (
      <ConfirmationModal
        className="confirm-version-change-modal"
        closeOnOverlayClick
        closeAction={() => {
          setShowActivateConfirmationModal(false);
          setShowApplicationVersionsModal(false);
        }}
        headerText={translate("appStreamingModals.applicationVersions.changeVersion.header")}
        descriptionText={translate("appStreamingModals.applicationVersions.changeVersion.description")}
        confirmText="Confirm"
        confirmAction={() => {
          updateActiveExecutableAPI({}, {}, selectedExecutableId);
          setShowActivateConfirmationModal(false);
        }}
        secondaryText="Cancel"
        secondaryAction={() => {
          setShowActivateConfirmationModal(false);
          setShowApplicationVersionsModal(false);
        }}
      />
    );
  }

  if (showDeleteConfirmationModal) {
    return (
      <ConfirmationModal
        className="confirm-version-change-modal"
        closeOnOverlayClick
        closeAction={() => {
          setShowDeleteConfirmationModal(false);
        }}
        headerText={translate("appStreamingModals.applicationVersions.deleteVersion.header")}
        descriptionText={translate("appStreamingModals.applicationVersions.deleteVersion.description")}
        confirmText="Confirm"
        confirmAction={() => {
          deleteExecutableAPI({}, {}, selectedExecutableId);
          setShowDeleteConfirmationModal(false);
        }}
        secondaryText="Cancel"
        secondaryAction={() => {
          setShowDeleteConfirmationModal(false);
        }}
      />
    );
  }

  const { boost_in_progress: boostInProgress } = checkBoostData?.data || {};

  if (!checkBoostDataLoading && boostInProgress) {
    return (
      <Modal
        className="application-versions-error-modal"
        descriptionText={<StreamError withoutButton error="boostInProgress" translate={translate} />}
        closeAction={() => setShowApplicationVersionsModal(false)}
        topRightIcon="close"
        topRightIconAction={() => setShowApplicationVersionsModal(false)}
        big
      />
    );
  }

  return (
    <Modal
      className="application-versions-modal"
      closeOnOverlayClick
      topRightIcon="close"
      topRightIconAction={() => setShowApplicationVersionsModal(false)}
      headerText="Application Versions"
      closeAction={() => setShowApplicationVersionsModal(false)}
      big
    >
      {checkBoostDataLoading ? (
        <Loader />
      ) : (
        <>
          <Table columns={columns} data={data} className="application-versions-table" />
          <Button
            text="New Version"
            iconLeft="plus"
            color="light-navy"
            onClick={() => {
              setShowApplicationVersionsModal(false);
              setShowUploadAppVersionModal(true);
            }}
            disabled={executables?.executables?.length >= 5}
          />
        </>
      )}
    </Modal>
  );
};

export default ApplicationVersionsModal;
