import React from "react";

import { Button, TextButton, Icon, TopLoader } from "UI";
import { Animation, classnames } from "Utils";

import "./Modal.style.scss";

const ConfirmationModal = (props) => {
  const {
    closeOnOverlayClick,
    closeAction,
    headerText,
    descriptionText,
    confirmText,
    confirmAction,
    secondaryConfirmText,
    secondaryConfirmAction,
    disableConfirmButton,
    secondaryText,
    secondaryAction,
    pending,
    success,
    successText,
    successButtonText,
    errorText,
    confirmButtonIcon,
    blue,
    className: classNameFromProps,
  } = props;

  const className = classnames(["modal-content", classNameFromProps]);
  const ConfirmButtons = () => {
    if (!secondaryConfirmText) {
      return (
        <Button
          text={confirmText}
          onClick={confirmAction}
          className="confirm-action"
          disabled={disableConfirmButton}
          blue={blue}
          icon={confirmButtonIcon}
        />
      );
    }
    return (
      <div className="confirm-action-group">
        <TextButton
          text={confirmText}
          onClick={confirmAction}
          className="confirm-action"
          disabled={disableConfirmButton}
        />
        <TextButton
          text={secondaryConfirmText}
          onClick={secondaryConfirmAction}
          className="confirm-action"
          disabled={disableConfirmButton}
        />
      </div>
    );
  };
  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    <div className="confirmation-modal-overlay" onClick={() => closeOnOverlayClick && closeAction()}>
      <div className="modal-container" onClick={(event) => event.stopPropagation()}>
        {pending && <TopLoader isInsideComponent />}
        {closeAction && <Icon name="close" onClick={closeAction} className="top-right-icon" />}
        <div className={className}>
          <div className="header">{headerText}</div>
          <div className="description">
            {success ? (
              <div className="success">
                <Animation type="success" style={{ height: "6rem", width: "6rem" }} /> {successText}
              </div>
            ) : (
              <>
                {descriptionText}
                {errorText && (
                  <div className="error">
                    <Icon name="error" color="purple-main" />
                    <p>{errorText}</p>
                  </div>
                )}
              </>
            )}
          </div>
          {!success && (
            <div className="action-group">
              <TextButton
                className="secondary-action"
                text={secondaryText}
                onClick={secondaryAction}
                color="baby-blue"
              />
              <ConfirmButtons />
            </div>
          )}

          {success && successButtonText && (
            <div className="action-group">
              <TextButton
                style={{ marginLeft: "auto" }}
                className="confirm-action"
                text={successButtonText}
                onClick={closeAction}
                color="baby-blue"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
