import React from "react";
import { connect } from "react-redux";

import LoginComponent from "Components/Authentication/Login/Login.component";
import { loginAPI, loginClear } from "Actions/Authentication/Login.actions";

class LoginContainer extends React.Component {
  componentDidMount() {
    const { loginClear } = this.props;
    loginClear();
  }

  render() {
    return <LoginComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  login: state.login,
});

const actionCreators = {
  loginAPI,
  loginClear,
};

export default connect(mapStateToProps, actionCreators)(LoginContainer);
