const REGION_LINKS = {
  dublin: "https://dynamodb.eu-west-1.amazonaws.com/ping",
  frankfurt: "https://dynamodb.eu-central-1.amazonaws.com/ping",
  north_virginia: "https://dynamodb.us-east-1.amazonaws.com/ping",
  oregon: "https://dynamodb.us-west-2.amazonaws.com/ping",
  northern_california: "https://dynamodb.us-west-1.amazonaws.com/ping",
  mumbai: "https://dynamodb.ap-south-1.amazonaws.com/ping",
  singapore: "https://dynamodb.ap-southeast-1.amazonaws.com/ping",
  sydney: "https://dynamodb.ap-southeast-2.amazonaws.com/ping",
  tokyo: "https://dynamodb.ap-northeast-1.amazonaws.com/ping",
  bahrain: "https://dynamodb.me-south-1.amazonaws.com/ping",
  hong_kong: "https://dynamodb.ap-east-1.amazonaws.com/ping",
  sao_paulo: "https://dynamodb.sa-east-1.amazonaws.com/ping",
  seoul: "https://dynamodb.ap-northeast-2.amazonaws.com/ping",
  ohio: "https://dynamodb.us-east-2.amazonaws.com/ping",
  montreal: "https://dynamodb.ca-central-1.amazonaws.com/ping",
  stockholm: "https://dynamodb.eu-north-1.amazonaws.com/ping",
  london: "https://dynamodb.eu-west-2.amazonaws.com/ping",
  paris: "https://dynamodb.eu-west-3.amazonaws.com/ping",
  milan: "https://dynamodb.eu-south-1.amazonaws.com/ping",
  israel: "https://dynamodb.il-central-1.amazonaws.com/ping",
  jakarta: "https://dynamodb.ap-southeast-3.amazonaws.com/ping",
  uae: "https://dynamodb.me-central-1.amazonaws.com/ping",
  cape_town: "https://dynamodb.af-south-1.amazonaws.com/ping",
};

export const pingRegions = async (regions = Object.keys(REGION_LINKS)) => {
  const regionPromises = regions.map(async (region) => {
    const pingUrl = REGION_LINKS[region];
    const ping = await sendPings(pingUrl);
    return { region, ping };
  });

  const regionPings = await Promise.all(regionPromises);

  const sortedRegions = regionPings.sort((a, b) => a.ping - b.ping);

  return {
    pings: sortedRegions,
    bestRegionPing: sortedRegions[0],
  };
};

export const pingRegion = async (region) => {
  const pingUrl = REGION_LINKS[region];
  return sendPings(pingUrl);
};

async function fetchUrl(url, supportsCors, abortController) {
  const OPTS = {
    signal: abortController.signal,
    cache: "no-store",
    credentials: "omit",
    redirect: "error",
  };
  if (supportsCors) {
    await fetch(url, OPTS);
  } else {
    try {
      await fetch(url, OPTS);
    } catch (e) {
      if (e instanceof TypeError) {
        // Ignoring error, which should be a CORS error
      } else {
        throw e;
      }
    }
  }
}

async function sendPings(pingUrl) {
  let timedOut = false;

  try {
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => {
      timedOut = true;
      abortController.abort();
    }, 5 * 1000);

    await fetchUrl(pingUrl, false, abortController);
    const startTime = new Date().getTime();
    await fetchUrl(pingUrl, false, abortController);
    const endTime = new Date().getTime();
    const elapsed = endTime - startTime;

    clearTimeout(timeoutId);
    return elapsed;
  } catch (e) {
    if (timedOut && e instanceof DOMException && (e.code === DOMException.ABORT_ERR || e.name === "AbortError")) {
      return 500;
    }
    return 1000;
  }
}
