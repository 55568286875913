import React from "react";
import { useMutation } from "@tanstack/react-query";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import apiGenerator from "Utils/Helpers/api.helpers";
import { Icon } from "UI";
import ActionHeader from "UI/ActionHeader/ActionHeader.component";
import ConfigurePlan from "../Components/ConfigurePlan/ConfigurePlan.component";
import "./CreatePlan.styles.scss";

const CreatePlan = ({ createComputers, translate, history }) => {
  const createSeatPlanMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEAT_PLANS, data);
    },
    onSuccess: (resp) => {
      history.push({
        pathname: routes.organizationAdminPlans,
        state: { createdPlanId: resp.data.id },
      });
    },
  });

  return (
    <div className="create-plan-container">
      <ActionHeader
        title={translate("teamDashboard.chooseComputerPlan.header.title")}
        description={translate("teamDashboard.chooseComputerPlan.header.description")}
        onCloseButtonClick={() => history.push(routes.organizationAdminPlans)}
      />
      <div className="teams-choose-plan-container">
        <ConfigurePlan
          createComputers={createComputers}
          history={history}
          onButtonClick={(configurePlanConfigs) => {
            createSeatPlanMutation.mutate(configurePlanConfigs);
          }}
          hasError={createSeatPlanMutation.isError}
          errorText={translate("teamDashboard.chooseComputerPlan.createErrorText")}
          translate={translate}
        />
      </div>
    </div>
  );
};

export default CreatePlan;
