import React, { useEffect, useState } from "react";
import { APP_SCOPES, ROOT_PATHS } from "Constants/global.constants";
import { Button, DivButton, Icon } from "UI";
import AuthenticationHeader from "Utils/Components/Authentication/AuthenticationHeader/AuthenticationHeader.component";
import { useAppScope } from "Utils/Hooks/useAppScope";
import AuthenticationLayout from "Utils/Components/Layouts/AuthenticationLayout/AuthenticationLayout";
import { useSelector } from "react-redux";
import { capitalize } from "lodash";

import "./AppScope.styles.scss";

const AppScope = (props) => {
  const [selectedScope, setSelectedScope] = useState(null);
  const { appScope, setAppScope } = useAppScope();
  const { history, translate } = props;
  const { account } = useSelector((state) => state.account);

  useEffect(() => {
    if (appScope) {
      history.push(ROOT_PATHS[appScope]);
    }
  }, []);

  const appScopes = {
    teams: {
      color: "purple-main",
      logo: "teams-logo",
      scope: APP_SCOPES.team_admin,
    },
    computer: {
      color: "gray-2",
      logo: "computer-logo",
      scope: APP_SCOPES.user,
    },
    appStreaming: {
      color: "aqua-light",
      logo: "streams-logo",
      scope: APP_SCOPES.vendor,
    },
  };

  return (
    <AuthenticationLayout {...props}>
      <div className="vg-app-scope-content form-container">
        <AuthenticationHeader
          title={translate("appScope.header", { name: capitalize(account?.attributes?.name || "stranger") })}
          description={translate("appScope.description")}
        />
        <div className="app-scope-selection">
          {Object.keys(appScopes).map((scope) => (
            <DivButton
              key={scope}
              onClick={() => {
                setSelectedScope(appScopes[scope].scope);
              }}
              className={selectedScope === appScopes[scope].scope ? "selected" : ""}
            >
              <Icon name={appScopes[scope].logo} color={appScopes[scope].color} />
              <p>{translate(`appScope.scopes.${scope}.description`)}</p>
            </DivButton>
          ))}
          <Button
            text="Continue"
            onClick={() => {
              setAppScope(selectedScope);
              history.push(ROOT_PATHS[selectedScope]);
            }}
            block
            disabled={!selectedScope}
          />
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default AppScope;
