import React, { useRef, useState } from "react";
import { Steps } from "intro.js-react";
import CreateTemplateFromComputerImage from "Assets/images/onboardingModals/create-template-from-computer.jpg";
import ChooseApplicationImage from "Assets/images/onboardingModals/choose-application.jpg";
import routes from "Constants/Route.constants";
import { TEMPLATES_ONBOARDING_STEPS } from "Constants/teamsOnboarding.constants";
import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const TeamsCreateTemplateOnboardingModal = ({ setCurrentStep, onExit, history, translate }) => {
  const stepsObject = useRef(null);
  const hasNavigatedFourthStep = useRef(false);
  const hasNavigatedFifthStep = useRef(false);
  const hasNavigatedSixthStep = useRef(false);

  const [firstStep, setFirstStep] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const steps = [
    {
      element: ".card.first-item",
      title: `<img src=${CreateTemplateFromComputerImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.templates.chooseMethod.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.templates.chooseMethod.description")}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      title: `<img src=${ChooseApplicationImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.templates.createTemplate.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.templates.createTemplate.description")}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
  ];

  const onBeforeChange = (currentStep) => {
    setCurrentStep(currentStep);

    if (currentStep === TEMPLATES_ONBOARDING_STEPS.chooseMethod.step) {
      history.push(routes.organizationAdminCreateTemplateStart);
      hasNavigatedFourthStep.current = false;
      hasNavigatedFifthStep.current = false;
      hasNavigatedSixthStep.current = false;

      if (firstStep) {
        setFirstStep(false);
        setTimeout(() => {
          stepsObject.current.introJs.goToStep(TEMPLATES_ONBOARDING_STEPS.appSelection.step);
        }, 150);
        setTimeout(() => {
          stepsObject.current.updateStepElement(TEMPLATES_ONBOARDING_STEPS.chooseMethod.step);
        }, 100);
      }
      setSecondStep(true);
    }
    if (currentStep === TEMPLATES_ONBOARDING_STEPS.appSelection.step) {
      history.push(routes.organizationAdminCreateTemplateNew);
      if (secondStep) {
        setTimeout(() => {
          stepsObject.current.introJs.goToStep(TEMPLATES_ONBOARDING_STEPS.createTemplate.step);
        }, 175);
      }
      stepsObject.current.updateStepElement(TEMPLATES_ONBOARDING_STEPS.appSelection.step);

      setSecondStep(false);
      setFirstStep(true);
    }
  };

  return (
    <Steps
      enabled
      options={{ nextToDone: true }}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      onBeforeChange={onBeforeChange}
      ref={stepsObject}
    />
  );
};

export default TeamsCreateTemplateOnboardingModal;
