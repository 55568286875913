import React from "react";
import { classnames, Loader } from "Utils";
import { Modal, TextButton } from "UI";

import "./SlideModal.styles.scss";

const SlideModal = ({
  translate,
  header,
  contents,
  closeAction,
  confirmAction,
  topRightIcon,
  className: classNameFromProps,
  activeTab,
  setActiveTab,
  pending,
  loading,
  big,
  disablePrevious,
  hideFooter,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
  steps,
  currentStep,
  sidebarHeader,
  indexOffset,
}) => {
  const isLastTab = activeTab === contents.length - 1;
  const submitTab = activeTab === contents.length - 2;

  const nextButtonText = () => {
    if (contents[activeTab].overrideNextButtonText) return contents[activeTab].overrideNextButtonText;
    if (isLastTab) return translate("appStreamingModals.actions.done");
    if (submitTab) return translate("appStreamingModals.actions.submit");
    return translate("appStreamingModals.actions.next");
  };
  return (
    <Modal
      className={classnames(["slide-modal", classNameFromProps])}
      headerText={header}
      closeAction={(event) => {
        if (!pending) closeAction(event);
      }}
      topRightIcon={topRightIcon || "close"}
      descriptionText={loading ? <Loader /> : contents[activeTab].content}
      overrideButton={
        !hideFooter && (
          <div
            className={classnames([
              "slide-modal-footer",
              (contents.length === 1 || disablePrevious) && "single-button",
              contents[activeTab].hidePrevious && "single-button",
            ])}
          >
            {!contents[activeTab].hidePrevious && contents.length !== 1 && !disablePrevious && (
              <>
                <TextButton
                  text={translate("appStreamingModals.actions.previous")}
                  color="aqua-main"
                  onClick={() => {
                    if (contents[activeTab].overridePreviousAction) {
                      contents[activeTab].overridePreviousAction();
                      return;
                    }
                    if (activeTab > 0) setActiveTab(activeTab - 1);
                  }}
                  disabled={contents[activeTab].disablePrevious}
                  className="slide-modal-footer-button"
                />
              </>
            )}
            {!contents[activeTab].hideNext && (
              <TextButton
                text={nextButtonText()}
                color="aqua-main"
                onClick={() => {
                  if (contents[activeTab].overrideNextAction) {
                    contents[activeTab].overrideNextAction();
                    return;
                  }
                  if (contents[activeTab].onSubmit) {
                    contents[activeTab].onSubmit();
                  }
                  if (!isLastTab) setActiveTab(activeTab + 1);
                  else if (confirmAction) confirmAction();
                  else closeAction();
                }}
                className="slide-modal-footer-button"
                disabled={
                  pending ||
                  contents[activeTab].disabled ||
                  (isLastTab &&
                    contents.filter((tab) => {
                      return tab.disabled;
                    }).length > 0)
                }
              />
            )}
          </div>
        )
      }
      topRightIconAction={closeAction}
      big={big}
      isPending={pending}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      showSidebar={contents[activeTab].showSidebar}
      sidebarFooter={contents[activeTab].sidebarFooter}
      steps={steps}
      currentStep={currentStep}
      setCurrentStep={setActiveTab}
      sidebarHeader={sidebarHeader}
      indexOffset={indexOffset}
      contents={contents}
    />
  );
};

export default SlideModal;
