import React, { useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import moment from "moment";
import apiGenerator from "Utils/Helpers/api.helpers";
import ObjectsModal from "../Components/ObjectsModal/ObjectsModal.component";
import UserCell from "../Components/UserCell/UserCell.component";

const PER_PAGE = 20;

const COMPUTERS_TABLE_COLUMNS = [
  {
    name: "Computer",
    weight: 50,
  },
  {
    name: "Plan",
    weight: 30,
  },

  {
    name: "Created At",
    weight: 30,
  },
];

const AssignMemberToComputerModal = ({ onClose, selectedObject, translate }) => {
  const [selected, setSelected] = useState(null);

  const { user, invitation } = selectedObject.attributes;

  const { isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, data, refetch } = useInfiniteQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_SEATS_ASSIGNABLE],
    queryFn: ({ pageParam = 1 }) => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_SEATS_ASSIGNABLE, {
        params: { page: pageParam, per_page: PER_PAGE },
      }).then((res) => {
        return res.data;
      });
    },
    getNextPageParam: (lastPage) => lastPage.next_page,
  });

  const assignComputerMutation = useMutation({
    mutationKey: [API_ENDPOINTS.ORGANIZATION_SEATS_ASSIGN(selected)],
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS_ASSIGN(selected), data);
    },
  });

  const seats = data?.pages?.flatMap((page) => page?.seats);
  const assignableComputers = seats?.map((seat) => {
    return {
      key: seat.id,
      computer: (
        <UserCell
          onCheckBoxChange={(e) => {
            if (e.target.checked) setSelected(seat.id);
            else setSelected(null);
          }}
          checked={selected === seat.id}
          seat={seat}
        />
      ),
      plan: seat.attributes.plan.attributes.name,
      "created at": moment(seat.attributes.created_at).fromNow(),
    };
  });

  const handleClose = () => {
    onClose();
    refetch();
  };

  return (
    <ObjectsModal
      hideSearchBar
      onClose={handleClose}
      columns={COMPUTERS_TABLE_COLUMNS}
      data={assignableComputers}
      onConfirm={() => {
        assignComputerMutation.mutate({
          seat_id: selected,
          email: user ? user.attributes.email : invitation.attributes.email,
        });
      }}
      disableConfirm={!selected}
      success={assignComputerMutation.isSuccess}
      error={assignComputerMutation.isError}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      headerText={translate("assignMemberToComputerModal.header.title")}
      successHeader={translate("assignMemberToComputerModal.success.header")}
      successDescription={translate("assignMemberToComputerModal.success.description")}
      emptyContentHeader={translate("assignMemberToComputerModal.emptyContent.title")}
      emptyContentDescription={translate("assignMemberToComputerModal.emptyContent.description")}
    />
  );
};

export default AssignMemberToComputerModal;
