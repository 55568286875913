import React, { useEffect } from "react";

import { ConfirmationModal } from "UI";

import * as moment from "moment";
import { getItemFromLocalStorage } from "Utils";

const FreeUsageEndModal = (props) => {
  const { translate } = props;
  const [showFreeUsageEndNotification, setShowFreeUsageEndNotification] = React.useState(false);

  const showNotification = () => {
    setShowFreeUsageEndNotification(true);
  };

  useEffect(() => {
    const remainingLocal = getItemFromLocalStorage("userRemainingCredit");
    // Check if the user has remaining credit
    if (!remainingLocal || !props.getWorkstationCurrentSessionCTX.data.attributes) {
      return;
    }
    setShowFreeUsageEndNotification(false);
    const currentSessionUsage = moment(
      moment().diff(moment(new Date(props.getWorkstationCurrentSessionCTX.data.attributes.created_at)).local()),
    )
      .utc()
      .format("mm");
    // Calculate remaining free usage
    const remainingUsage = remainingLocal - currentSessionUsage;
    if (remainingLocal && remainingUsage > 15) {
      const millisecondsToNotify = (remainingUsage - 15) * 60 * 1000;
      setTimeout(() => showNotification(), millisecondsToNotify);
    }
  }, [props.getWorkstationCurrentSessionCTX.data.attributes]); //eslint-disable-line

  return (
    <>
      {showFreeUsageEndNotification && (
        <ConfirmationModal
          headerText={translate("modals.freeUsageEnd.header")}
          descriptionText={
            <>
              <p>{translate("modals.freeUsageEnd.description")}</p>
              <p>{translate("modals.freeUsageEnd.info")}</p>
            </>
          }
          confirmText={translate("modals.freeUsageEnd.confirm")}
          confirmAction={() => setShowFreeUsageEndNotification(false)}
        />
      )}
    </>
  );
};

export default FreeUsageEndModal;
