import { all, put, takeLatest } from "redux-saga/effects";

import apiGenerator from "../Utils/Helpers/api.helpers";
import { getStatusCodeFamily, apiErrorHandler } from "../Utils/Helpers/saga.helpers";

import * as VerifyRegisterConstants from "../Constants/Authentication/VerifyRegister.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "../Constants/api.constants";

function* verifyRegisterSaga(action) {
  const api = apiGenerator("post")(API_ENDPOINTS.VERIFY_REGISTER, {
    code: action.payload,
  });

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: VerifyRegisterConstants.VERIFY_REGISTER_API_SUCCESS,
      });
    }
  } catch (err) {
    yield put({
      type: VerifyRegisterConstants.VERIFY_REGISTER_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

export default function* root() {
  yield all([takeLatest(VerifyRegisterConstants.VERIFY_REGISTER_API_PENDING, verifyRegisterSaga)]);
}
