import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import ExpandableAnimation from "UI/ExpandableAnimation/ExpandableAnimation.component";
import _ from "lodash";
import { Button, NumberInput } from "UI";
import { classnames } from "Utils";

import "./UsageLimitForm.styles.scss";

const MAX_INPUT_DIGIT = 4;
const MAX_HOUR_AMOUNT = 720;
const MIN_HOUR_AMOUNT = 0;

const UsageLimitForm = forwardRef((props, ref) => {
  const { hours, setHour, defaultHour, customHourText, onAnyButtonClick, translate } = props;

  const [selectedHour, setSelectedHour] = useState(hours.includes(defaultHour) ? defaultHour : undefined);
  const [customHourSelected, setCustomHourSelected] = useState(false);
  const [customHour, setCustomHour] = useState(undefined);

  const customInputRef = useRef(null);

  const hourGroups = _.chunk(hours, 2);

  const reset = () => {
    setSelectedHour(undefined);
    setCustomHour(undefined);
    setCustomHourSelected(false);
  };

  useImperativeHandle(ref, () => ({
    reset,
  }));

  useEffect(() => {
    if (customHour === "" && customInputRef.current) {
      customInputRef.current.focus();
    }
  }, [customHour, customInputRef, customHourSelected]);

  useEffect(() => {
    if (selectedHour) {
      setHour(selectedHour);
    } else if (customHourSelected && customHour) {
      setHour(customHour);
    } else {
      setHour(0);
    }
  }, [selectedHour, customHour, customHourSelected]);

  return (
    <div className="usage-limit-form">
      <div className={`select-hour ${selectedHour || customHourSelected ? "selected" : ""}`}>
        {hourGroups.map((hourLine) => (
          <div className="hour-line" key={hourLine}>
            {hourLine.map((hour) => {
              const selected = selectedHour === hour;
              return (
                <div className="default-hour" key={hour}>
                  <Button
                    basic
                    key={hour}
                    className={selected && "selected"}
                    text={
                      <p className="hour">
                        {hour}
                        <br />
                        <span>Hours</span>
                      </p>
                    }
                    onClick={() => {
                      setSelectedHour(selected ? undefined : hour);
                      setCustomHourSelected(false);
                      onAnyButtonClick();
                    }}
                  />
                </div>
              );
            })}
          </div>
        ))}
        <div className="custom-button-wrapper">
          <Button
            basic
            className={classnames(["custom-hour-button", customHourSelected && "selected"])}
            text="Custom"
            onClick={() => {
              setSelectedHour(undefined);
              setCustomHour(customHourSelected ? undefined : customHour);
              setCustomHourSelected(!customHourSelected);
              onAnyButtonClick();
            }}
          />
        </div>
      </div>

      <ExpandableAnimation isOpen={customHourSelected}>
        <div className={classnames(["custom-hour", customHourSelected && "selected"])}>
          {translate("addBalanceForm.enterAmount")}
          <div>
            <NumberInput
              inputRef={customInputRef}
              onChange={setCustomHour}
              onFocus={(event) => {
                setSelectedHour(undefined);
                setCustomHour(event.target.value);
              }}
              maxInputValue={MAX_HOUR_AMOUNT}
              maxDigitNumber={MAX_INPUT_DIGIT}
              minInputValue={MIN_HOUR_AMOUNT}
              value={customHour || 0}
            />
            {customHourText && <p>{customHourText}</p>}
          </div>
        </div>
      </ExpandableAnimation>
    </div>
  );
});

export default UsageLimitForm;
