import React, { useState, useRef } from "react";
import { Dropdown, HoverableTooltip, Icon, IconButton, Switch, TextButton } from "UI";
import streamsLogo from "Assets/images/appStreaming/streams-logo.png";
import streamsLogoDark from "Assets/images/appStreaming/streams-logo-dark.png";
import streamsWhiteBg from "Assets/images/appStreaming/white-bg.svg";
import streamsDarkBg from "Assets/images/appStreaming/bg_black.jpg";
import { containsUnAllowedFileTypes, isFileSizesAllowed } from "Utils/Helpers/file.helpers";
import { UPLOAD_TYPES } from "Constants/global.constants";
import { DOCK_POSITIONS, STREAM_TYPES } from "Constants/AppStreaming.constants";
import { classnames, useAPI } from "Utils";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "Actions/Dashboard/Files.actions";
import _ from "lodash";
import StreamTexts from "./StreamTexts/StreamTexts.component";

import "./CustomizeStep.styles.scss";

const CustomizeStep = ({ streamConfigs, setStreamConfigs, isVendorEnterprise, streamType, translate }) => {
  const parentStream = streamType === STREAM_TYPES.parent;
  const {
    applicationId: appId,
    darkMode,
    id,
    dockPosition,
    showPlayButton,
    pixelStreamingEnabled,
    renderStreamingEnabled,
  } = streamConfigs;
  const [logoUrl, setLogoUrl] = useState(streamConfigs.logoUrl);
  const [bannerUrl, setBannerUrl] = useState(streamConfigs.bannerUrl);
  const [tempLogoName, setTempLogoName] = useState(null);
  const [tempBannerName, setTempBannerName] = useState(null);

  const dispatch = useDispatch();

  const logoInputRef = useRef(null);
  const bannerInputRef = useRef(null);

  const filesCTX = useSelector((state) => state.files);

  const { api: deleteLogoAPI } = useAPI({
    type: "delete",
    endpoint: APP_STREAMING_API_ENDPOINTS.DELETE_APP_LOGO(appId),
    onSuccess: () => {
      setLogoUrl(null);
      setTempLogoName(null);
    },
  });

  const { api: deleteBannerAPI } = useAPI({
    type: "delete",
    endpoint: APP_STREAMING_API_ENDPOINTS.DELETE_APP_BANNER(appId),
    onSuccess: () => {
      setBannerUrl(null);
      setTempBannerName(null);
    },
  });

  const uploadingLogo = _.find(
    filesCTX.uploadFilesCTX.uploadFiles,
    (file) => file.uploadType === UPLOAD_TYPES.APPLICATION_LOGO_UPLOAD,
  );

  const uploadingBanner = _.find(
    filesCTX.uploadFilesCTX.uploadFiles,
    (file) => file.uploadType === UPLOAD_TYPES.APPLICATION_BANNER_UPLOAD,
  );

  const handleImageInput = (files, type) => {
    const imgFile = files?.[0];

    if (!imgFile) return;
    // Check file types: only jpeg and png allowed
    if (containsUnAllowedFileTypes(files, ["image/jpeg", "image/png"])) {
      return;
    }
    // Check file size: 2MB is limit
    if (!isFileSizesAllowed(files, 2)) {
      return;
    }

    if (imgFile) {
      const reader = new FileReader();
      switch (type) {
        case "logo":
          reader.onload = (e) => {
            setLogoUrl(e.target.result);
          };
          setTempLogoName(imgFile.name);
          reader.readAsDataURL(imgFile);
          dispatch(uploadImage({ appId, files, uploadType: UPLOAD_TYPES.APPLICATION_LOGO_UPLOAD }));
          break;
        case "banner":
          reader.onload = (e) => {
            setBannerUrl(e.target.result);
          };
          setTempBannerName(imgFile.name);
          reader.readAsDataURL(imgFile);
          dispatch(uploadImage({ appId, files, uploadType: UPLOAD_TYPES.APPLICATION_BANNER_UPLOAD }));
          break;
        default:
          break;
      }
    }
  };

  const setApplicationLogo = () => {
    if (isVendorEnterprise && logoUrl) {
      return logoUrl;
    }
    return darkMode ? streamsLogoDark : streamsLogo;
  };

  const setBanner = () => {
    if (isVendorEnterprise && bannerUrl) {
      return encodeURI(bannerUrl);
    }
    return darkMode ? streamsDarkBg : streamsWhiteBg;
  };

  return (
    <div className={classnames(["customize-step", parentStream && "disabled"])}>
      <div
        className="preview-container"
        style={{
          backgroundImage: `url("${setBanner()}")`,
        }}
      >
        <div className={classnames(["loading-modal", darkMode && "dark"])}>
          <img src={setApplicationLogo()} />
          <div className="divider" />
          <div className="divider" />
        </div>
      </div>
      <div className="configuration-option">
        <Switch
          enterprise
          reverseText
          className="header"
          text={translate("configureStream.configurations.darkMode.header")}
          checked={darkMode}
          onChange={(e) => {
            setStreamConfigs({ darkMode: e });
          }}
        />
        <p className="description">{translate("configureStream.configurations.darkMode.description")}</p>
      </div>
      <div className="configuration-option">
        <div className="with-icon">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.showPlayButton.header")}
            checked={pixelStreamingEnabled || renderStreamingEnabled || showPlayButton}
            onChange={(e) => {
              setStreamConfigs({ showPlayButton: e });
            }}
            disabled={pixelStreamingEnabled || renderStreamingEnabled}
          />
          <HoverableTooltip
            content={translate("configureStream.configurations.showPlayButton.tooltipText")}
            side="right"
          >
            <Icon name="info" color="gray-4" smaller style={{ marginLeft: "0.4rem" }} />
          </HoverableTooltip>
        </div>
        <p className="description">{translate("configureStream.configurations.showPlayButton.description")}</p>
      </div>
      <div className="configuration-option dropdown">
        <div>
          <p className="header">{translate("configureStream.configurations.dockPosition.header")}</p>
          <p className="description">{translate("configureStream.configurations.dockPosition.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            options={Object.values(DOCK_POSITIONS)}
            defaultSelected={dockPosition}
            mapFunction={(option) => {
              return translate(`configureStream.configurations.dockPosition.options.${option}`);
            }}
            handleSelectedOptionChange={(e) => setStreamConfigs({ dockPosition: e })}
          />
        </div>
      </div>

      <div className={classnames(["configuration-option", "dropdown", !isVendorEnterprise && "disabled"])}>
        <div>
          <div className="with-badge">
            <p className="header">{translate("configureStream.configurations.customConnection.header")}</p>
            <div className="enterprise-badge">Enterprise</div>
          </div>
          <p className="description">{translate("configureStream.configurations.customConnection.description")}</p>
        </div>
      </div>

      {isVendorEnterprise && (
        <>
          <div className="configuration-option dropdown">
            <div>
              <p className="header">{translate("configureStream.configurations.applicationLogo.header")}</p>
              <p className="description image-name">
                {!tempLogoName && !logoUrl && translate("configureStream.configurations.applicationLogo.default")}
                {tempLogoName || logoUrl?.split("/")?.at(-1)}
                {!uploadingLogo && logoUrl ? (
                  <IconButton
                    name="close"
                    style={{ height: "0.8rem" }}
                    onClick={() => {
                      deleteLogoAPI();
                    }}
                  />
                ) : (
                  uploadingLogo && <Icon name="loader" style={{ height: "0.8rem" }} />
                )}
              </p>
            </div>
            <div className="input-box">
              <TextButton
                text="Upload"
                color="aqua-light"
                onClick={() => {
                  logoInputRef.current.click();
                }}
              />
              <input
                type="file"
                id="logo-upload-input"
                style={{ display: "none" }}
                name="logo-upload-input"
                ref={logoInputRef}
                value=""
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const { files } = event.target;
                  handleImageInput(files, "logo");
                }}
              />
            </div>
          </div>

          <div className="configuration-option dropdown">
            <div>
              <p className="header">{translate("configureStream.configurations.banner.header")}</p>
              <p className="description image-name">
                {!tempBannerName && !bannerUrl && translate("configureStream.configurations.banner.default")}
                {tempBannerName || bannerUrl?.split("/")?.at(-1)}
                {!uploadingBanner && bannerUrl ? (
                  <IconButton
                    name="close"
                    style={{ height: "0.8rem" }}
                    onClick={() => {
                      deleteBannerAPI();
                    }}
                  />
                ) : (
                  uploadingBanner && <Icon name="loader" style={{ height: "0.8rem" }} />
                )}
              </p>
            </div>
            <div className="input-box">
              <TextButton
                text="Upload"
                color="aqua-light"
                onClick={() => {
                  bannerInputRef.current.click();
                }}
              />
              <input
                type="file"
                id="logo-upload-input"
                style={{ display: "none" }}
                value=""
                name="logo-upload-input"
                accept="image/png, image/jpeg"
                ref={bannerInputRef}
                onChange={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  const { files } = event.target;
                  handleImageInput(files, "banner");
                }}
              />
            </div>
          </div>
          <StreamTexts id={id} translate={translate} />
        </>
      )}
    </div>
  );
};

export default CustomizeStep;
