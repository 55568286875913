import React from "react";
import Lottie from "react-lottie";

import vagonAnimationData from "Assets/lottie/v-loading.json";
import ConnectionAnimationData from "Assets/lottie/box-loading.json";

import "./Loader.styles.scss";
import { isMobile } from "Utils";

const Loader = (props) => {
  const { className, big, centered, connection, description, style, darkTheme } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: connection ? ConnectionAnimationData : vagonAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const animationSize = {
    height: connection ? 400 : 50,
    width: connection ? 500 : 50,
  };

  const animationSizeMobile = {
    height: connection ? 300 : 50,
    width: connection ? 375 : 50,
  };

  const containerClassNames = ["vg-loader", className, big && "big", centered && "centered", darkTheme && "dark-theme"];

  return (
    <div className={containerClassNames.join(" ")} style={style}>
      <Lottie
        options={defaultOptions}
        height={isMobile ? animationSizeMobile.height : animationSize.height}
        width={isMobile ? animationSizeMobile.width : animationSize.width}
        isStopped={false}
        isPaused={false}
      />
      {description && <div className="description">{description}</div>}
    </div>
  );
};

export default Loader;
