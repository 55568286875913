import React, { useRef, useState } from "react";
import { Steps } from "intro.js-react";
import ComputerTemplatesImage from "Assets/images/onboardingModals/computer-templates.jpg";
import CreateComputerTemplateImage from "Assets/images/onboardingModals/create-computer-template.jpg";
import { TEMPLATES_ONBOARDING_STEPS } from "Constants/teamsOnboarding.constants";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const TeamsTemplateOnboardingModal = ({
  showOnboardingLastStep,
  setCurrentStep,
  setExpandingRow,
  onExit,
  translate,
}) => {
  const [firstStep, setFirstStep] = useState(true);
  const [secondStep, setSecondStep] = useState(true);

  const stepsObject = useRef(null);
  const menuClass = document.getElementById("menu-widescreen-container");
  menuClass.style.position = "fixed";

  const steps = [
    {
      element: ".entire-table-row",
      title: `<img src=${ComputerTemplatesImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.templates.assignTemplate.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.templates.assignTemplate.description")}</p>`,
      position: "bottom",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".right-actions",
      title: `<img src=${CreateComputerTemplateImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.templates.createNewTemplate.header",
      )}</h1><p>${translate(
        "modals.onboardingModals.teamsOnboardingModal.templates.createNewTemplate.description",
      )}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
  ];

  const onBeforeChange = (currentStep) => {
    setCurrentStep(currentStep);
    setSecondStep(true);
    if (currentStep === 0) {
      setExpandingRow("65");
      if (firstStep) {
        setFirstStep(false);
        setTimeout(() => {
          stepsObject.current.updateStepElement(TEMPLATES_ONBOARDING_STEPS.templatesTab.step);
        }, 300);

        setTimeout(() => {
          stepsObject.current.introJs.goToStep(TEMPLATES_ONBOARDING_STEPS.templateDetail.step);
        }, 350);
      }
    } else if (currentStep === 1) {
      setFirstStep(true);
      setExpandingRow(null);
      if (secondStep) {
        setSecondStep(false);
        setTimeout(() => {
          stepsObject.current.updateStepElement(TEMPLATES_ONBOARDING_STEPS.templateDetail.step);
          stepsObject.current.introJs.goToStep(TEMPLATES_ONBOARDING_STEPS.createButton.step);
        }, 100);
      }
    } else {
      setFirstStep(true);
      setSecondStep(true);
      setExpandingRow(null);
    }
  };
  return (
    <Steps
      enabled
      options={{ nextToDone: true, doneLabel: showOnboardingLastStep ? "Done" : "Next", disableInteraction: false }}
      steps={steps}
      initialStep={0}
      onBeforeChange={onBeforeChange}
      onExit={onExit}
      ref={stepsObject}
    />
  );
};

export default TeamsTemplateOnboardingModal;
