import * as SubscriptionsConstants from "Constants/Dashboard/Subscriptions.constants";
import * as LoginConstants from "Constants/Authentication/Login.constants";
import { REQUEST_STATUS } from "Constants/global.constants";

const initialState = {
  getUserSubscriptionPaymentInformationCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  getFileStorageSubscriptionPlansCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  createUserWorkstationAsSubscriptionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    clientCode: null,
  },
  getUserSubscriptionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  subscription: null,
  updateUserSubscriptionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  cancelSubscriptionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  renewSubscriptionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  upgradeDiskSizeSubscriptionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    client_code: null,
  },
  upgradeFileStorageSubscriptionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    client_code: null,
  },
};

export default function subscriptions(state = initialState, action) {
  switch (action.type) {
    case SubscriptionsConstants.GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_PENDING: {
      return {
        ...state,
        getUserSubscriptionPaymentInformationCTX: {
          ...state.getUserSubscriptionPaymentInformationCTX,
          status: REQUEST_STATUS.PENDING,
        },
      };
    }
    case SubscriptionsConstants.GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_SUCCESS: {
      return {
        ...state,
        getUserSubscriptionPaymentInformationCTX: {
          ...state.getUserSubscriptionPaymentInformationCTX,
          status: REQUEST_STATUS.SUCCESS,
          data: action.payload.attributes,
        },
      };
    }
    case SubscriptionsConstants.GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_FAILURE: {
      return {
        ...state,
        getUserSubscriptionPaymentInformationCTX: {
          ...state.getUserSubscriptionPaymentInformationCTX,
          status: REQUEST_STATUS.FAILURE,
          error: true,
        },
      };
    }
    case SubscriptionsConstants.GET_FILE_STORAGE_SUBSCRIPTION_PLANS_PENDING: {
      return {
        ...state,
        getFileStorageSubscriptionPlansCTX: {
          ...state.getFileStorageSubscriptionPlansCTX,
          status: REQUEST_STATUS.PENDING,
        },
      };
    }
    case SubscriptionsConstants.GET_FILE_STORAGE_SUBSCRIPTION_PLANS_SUCCESS: {
      return {
        ...state,
        getFileStorageSubscriptionPlansCTX: {
          ...state.getFileStorageSubscriptionPlansCTX,
          status: REQUEST_STATUS.SUCCESS,
          data: action.payload.plans,
        },
      };
    }
    case SubscriptionsConstants.GET_FILE_STORAGE_SUBSCRIPTION_PLANS_FAILURE: {
      return {
        ...state,
        getFileStorageSubscriptionPlansCTX: {
          ...state.getFileStorageSubscriptionPlansCTX,
          status: REQUEST_STATUS.FAILURE,
          error: true,
        },
      };
    }
    case SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_PENDING: {
      return {
        ...state,
        createUserWorkstationAsSubscriptionCTX: {
          ...state.createUserWorkstationAsSubscriptionCTX,
          status: REQUEST_STATUS.PENDING,
          error: false,
          clientCode: null,
        },
      };
    }
    case SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        createUserWorkstationAsSubscriptionCTX: {
          ...state.createUserWorkstationAsSubscriptionCTX,
          status: REQUEST_STATUS.SUCCESS,
          error: false,
          clientCode: null,
        },
      };
    }
    case SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        createUserWorkstationAsSubscriptionCTX: {
          ...state.createUserWorkstationAsSubscriptionCTX,
          status: REQUEST_STATUS.FAILURE,
          error: true,
          clientCode: action.payload.client_code,
        },
      };
    }
    case SubscriptionsConstants.CLEAR_SUBSCRIPTION_WORKSTATION: {
      return {
        ...state,
        getUserSubscriptionPaymentInformationCTX: {
          status: REQUEST_STATUS.NOT_DEFINED,
          data: null,
          error: false,
        },
        createUserWorkstationAsSubscriptionCTX: {
          status: REQUEST_STATUS.NOT_DEFINED,
          error: false,
        },
      };
    }
    case SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_PENDING: {
      return {
        ...state,
        getUserSubscriptionCTX: {
          ...state.getUserSubscriptionCTX,
          status: REQUEST_STATUS.PENDING,
        },
      };
    }
    case SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_SUCCESS: {
      return {
        ...state,
        getUserSubscriptionCTX: {
          ...state.getUserSubscriptionCTX,
          status: REQUEST_STATUS.SUCCESS,
          error: false,
        },
        subscription: action.payload,
      };
    }
    case SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_FAILURE:
      return {
        ...state,
        getUserSubscriptionCTX: {
          ...state.getUserSubscriptionCTX,
          status: REQUEST_STATUS.FAILURE,
          error: true,
        },
        subscription: null,
      };
    case SubscriptionsConstants.UPDATE_USER_SUBSCRIPTION_API_PENDING: {
      return {
        ...state,
        updateUserSubscriptionCTX: {
          ...state.updateUserSubscriptionCTX,
          status: REQUEST_STATUS.PENDING,
          error: false,
        },
      };
    }
    case SubscriptionsConstants.UPDATE_USER_SUBSCRIPTION_API_SUCCESS: {
      return {
        ...state,
        updateUserSubscriptionCTX: {
          ...state.updateUserSubscriptionCTX,
          status: REQUEST_STATUS.SUCCESS,
          error: false,
        },
      };
    }
    case SubscriptionsConstants.UPDATE_USER_SUBSCRIPTION_API_FAILURE: {
      return {
        ...state,
        updateUserSubscriptionCTX: {
          ...state.updateUserSubscriptionCTX,
          status: REQUEST_STATUS.FAILURE,
          error: true,
        },
      };
    }
    case SubscriptionsConstants.CANCEL_USER_SUBSCRIPTION_API_PENDING: {
      return {
        ...state,
        cancelSubscriptionCTX: {
          ...state.cancelSubscriptionCTX,
          status: REQUEST_STATUS.PENDING,
          error: false,
        },
      };
    }
    case SubscriptionsConstants.CANCEL_USER_SUBSCRIPTION_API_SUCCESS: {
      return {
        ...state,
        cancelSubscriptionCTX: {
          ...state.cancelSubscriptionCTX,
          status: REQUEST_STATUS.SUCCESS,
          error: false,
        },
      };
    }
    case SubscriptionsConstants.CANCEL_USER_SUBSCRIPTION_API_FAILURE: {
      return {
        ...state,
        cancelSubscriptionCTX: {
          ...state.cancelSubscriptionCTX,
          status: REQUEST_STATUS.FAILURE,
          error: true,
        },
      };
    }
    case SubscriptionsConstants.RENEW_USER_SUBSCRIPTION_API_PENDING: {
      return {
        ...state,
        renewSubscriptionCTX: {
          ...state.renewSubscriptionCTX,
          status: REQUEST_STATUS.PENDING,
          error: false,
        },
      };
    }
    case SubscriptionsConstants.RENEW_USER_SUBSCRIPTION_API_SUCCESS: {
      return {
        ...state,
        renewSubscriptionCTX: {
          ...state.renewSubscriptionCTX,
          status: REQUEST_STATUS.SUCCESS,
          error: false,
        },
      };
    }
    case SubscriptionsConstants.RENEW_USER_SUBSCRIPTION_API_FAILURE: {
      return {
        ...state,
        renewSubscriptionCTX: {
          ...state.renewSubscriptionCTX,
          status: REQUEST_STATUS.FAILURE,
          error: true,
        },
      };
    }
    case SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_PENDING: {
      return {
        ...state,
        upgradeDiskSizeSubscriptionCTX: {
          ...state.upgradeDiskSizeSubscriptionCTX,
          status: REQUEST_STATUS.PENDING,
          error: false,
          client_code: null,
        },
      };
    }
    case SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_SUCCESS: {
      return {
        ...state,
        upgradeDiskSizeSubscriptionCTX: {
          ...state.upgradeDiskSizeSubscriptionCTX,
          status: REQUEST_STATUS.SUCCESS,
          error: false,
          client_code: null,
        },
      };
    }
    case SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_FAILURE: {
      return {
        ...state,
        upgradeDiskSizeSubscriptionCTX: {
          ...state.upgradeDiskSizeSubscriptionCTX,
          status: REQUEST_STATUS.FAILURE,
          error: true,
          client_code: action.payload.client_code,
        },
      };
    }
    case SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_RESET: {
      return {
        ...state,
        upgradeDiskSizeSubscriptionCTX: {
          ...state.upgradeDiskSizeSubscriptionCTX,
          status: REQUEST_STATUS.NOT_DEFINED,
          error: false,
          client_code: null,
        },
      };
    }
    case SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_PENDING: {
      return {
        ...state,
        upgradeFileStorageSubscriptionCTX: {
          ...state.upgradeFileStorageSubscriptionCTX,
          status: REQUEST_STATUS.PENDING,
          error: false,
          client_code: null,
        },
      };
    }
    case SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_SUCCESS: {
      return {
        ...state,
        upgradeFileStorageSubscriptionCTX: {
          status: REQUEST_STATUS.SUCCESS,
          error: false,
          client_code: null,
        },
      };
    }
    case SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_FAILURE: {
      return {
        ...state,
        upgradeFileStorageSubscriptionCTX: {
          status: REQUEST_STATUS.FAILURE,
          error: true,
          client_code: action.payload.client_code,
        },
      };
    }
    case SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_RESET: {
      return {
        ...state,
        upgradeFileStorageSubscriptionCTX: {
          status: REQUEST_STATUS.NOT_DEFINED,
          error: false,
          client_code: null,
        },
      };
    }
    case LoginConstants.LOGOUT_API_PENDING: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
