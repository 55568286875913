import React from "react";
import { APPLICATION_DUMMY_DATA } from "Constants/StreamsOnboarding.constants";
import AppCard from "../AppCard/AppCard.component";
import "./ApplicationCards.styles.scss";

const ApplicationCards = ({
  applicationList,
  selectedApp,
  setSelectedApp,
  testApplicationAPI,
  setShowDeleteAppConfirmationModal,
  onConfigure,
  onShare,
  setShowTestSessionModal,
  setShowApplicationVersionsModal,
  showApplicationOnboardingModal,
  setShowBundledAppListModal,
  setShowAppBudgetModal,
  translate,
}) => {
  return (
    <>
      <div className="app-cards-container">
        {(showApplicationOnboardingModal ? APPLICATION_DUMMY_DATA : applicationList)?.map((app) => {
          return (
            <AppCard
              key={app.id}
              app={app}
              appLogo={app.attributes.logo_url || app.attributes.dummy_logo_url}
              onConfigure={onConfigure}
              onShare={onShare}
              transparent={Object.keys(selectedApp).length !== 0 && app.id !== selectedApp.id}
              testApplicationAPI={testApplicationAPI}
              setShowDeleteAppConfirmationModal={setShowDeleteAppConfirmationModal}
              selectedApp={selectedApp}
              setSelectedApp={setSelectedApp}
              setShowTestSessionModal={setShowTestSessionModal}
              setShowApplicationVersionsModal={setShowApplicationVersionsModal}
              setShowBundledAppListModal={setShowBundledAppListModal}
              setShowAppBudgetModal={setShowAppBudgetModal}
              showApplicationOnboardingModal={showApplicationOnboardingModal}
              translate={translate}
            />
          );
        })}
      </div>
    </>
  );
};

export default ApplicationCards;
