import React, { useState, useEffect } from "react";
import _ from "lodash";

import {
  AUTO_STOP_OPTIONS,
  REQUEST_STATUS,
  WORKSTATION_FRIENDLY_STATUS,
  LOCAL_STORAGE,
} from "Constants/global.constants";
import { Dropdown, HoverableTooltip, Icon } from "UI";
import { ConnectionStrength, getItemFromLocalStorage, isMobile } from "Utils";
import AfterCreationOnboardingModal from "Components/Dashboard/AfterCreationOnboardingModal/AfterCreationOnboardingModal.component";
import WorkstationOverviewItem from "./WorkstationOverviewItem.component";

const determineAutoTurnOffSettingByWorkstation = (isAutoStopEnabled, autoStopThreshold) => {
  if (isAutoStopEnabled) {
    const autoStopOption = _.findKey(AUTO_STOP_OPTIONS, (option) => option === autoStopThreshold);
    if (autoStopOption) {
      return autoStopOption;
    }
  }
  return "OFF";
};

const WorkstationOverview = ({
  isTeamAccount,
  workstation,
  getWorkstationMachineTypesCTX,
  machineUsageHistoryCTX,
  account,
  selectedMachineType,
  subscriptions,
  setShouldShowComputerPerformanceScreen,
  updateWorkstationAutoStopConfigurationAPI,
  setShouldShowStorageScreen,
  setShouldShowMigrateRegion,
  isInGracePeriod,
  translate,
}) => {
  const {
    friendly_status: friendlyStatus,
    auto_stop_enabled: isAutoStopEnabled,
    auto_stop_threshold: autoStopThreshold,
  } = workstation.attributes.machine.attributes;

  const [autoTurnOffSetting, setAutoTurnOffSetting] = React.useState(
    determineAutoTurnOffSettingByWorkstation(isAutoStopEnabled, autoStopThreshold),
  );

  const [showAfterCreationOnboardingModal, setShowAfterCreationOnboardingModal] = useState(null);
  const { getAccountCTX } = account;

  useEffect(() => {
    if (
      showAfterCreationOnboardingModal === null &&
      machineUsageHistoryCTX.status === REQUEST_STATUS.SUCCESS &&
      getAccountCTX.status === REQUEST_STATUS.SUCCESS
    ) {
      const sessionCount = machineUsageHistoryCTX.data.length;
      const shouldDisplayAfterCreationOnboarding =
        !account.hasDeletedSubscription &&
        !isMobile &&
        sessionCount < 1 &&
        getItemFromLocalStorage(LOCAL_STORAGE.showAfterCreationOnboardingModal, true);

      setShowAfterCreationOnboardingModal(shouldDisplayAfterCreationOnboarding);
    }
  }, [machineUsageHistoryCTX.status, getAccountCTX.status]);

  if (
    getWorkstationMachineTypesCTX.status === REQUEST_STATUS.NOT_DEFINED ||
    getWorkstationMachineTypesCTX.status === REQUEST_STATUS.PENDING ||
    !getWorkstationMachineTypesCTX.data ||
    machineUsageHistoryCTX.status === REQUEST_STATUS.NOT_DEFINED ||
    machineUsageHistoryCTX.status === REQUEST_STATUS.PENDING ||
    !machineUsageHistoryCTX.data ||
    getAccountCTX.status === REQUEST_STATUS.NOT_DEFINED ||
    getAccountCTX.status === REQUEST_STATUS.PENDING ||
    !selectedMachineType
  ) {
    return null;
  }

  const selectAutoTurnOffOption = (autoTurnOffSetting) => {
    setAutoTurnOffSetting(autoTurnOffSetting);
    updateWorkstationAutoStopConfigurationAPI(autoTurnOffSetting);
  };

  const { machine_types: machineTypes } = getWorkstationMachineTypesCTX.data;
  const machineType =
    machineTypes.find((machine) => parseInt(selectedMachineType, 10) === parseInt(machine.id, 10)) || machineTypes[0];

  const { friendly_name: machineName } = machineType.attributes.details.attributes;
  const { remaining_credit: remainingCredit } = machineType.attributes;

  const isButtonsDisabled = isInGracePeriod || friendlyStatus !== WORKSTATION_FRIENDLY_STATUS.OFF;
  const isChangeRegionDisabled =
    isInGracePeriod || ![WORKSTATION_FRIENDLY_STATUS.OFF, WORKSTATION_FRIENDLY_STATUS.READY].includes(friendlyStatus);

  return (
    <div className="workstation-overview">
      <div className="row">
        <WorkstationOverviewItem
          label={translate("home.workstationOverview.performance.label")}
          content={machineName}
          secondContent={
            remainingCredit
              ? `/ ${remainingCredit} ${translate("home.workstationOverview.performance.freeCredit")}`
              : ""
          }
          action={() => {
            setShouldShowComputerPerformanceScreen(true);
          }}
          actionText={translate("home.workstationOverview.performance.actionText")}
          disabled={isButtonsDisabled}
          classSelector="workstation-performance-area"
        />

        <WorkstationOverviewItem
          label={translate("home.workstationOverview.storage.label")}
          content={subscriptions[0] && `${subscriptions[0].attributes.disk_size} GB`}
          action={() => setShouldShowStorageScreen(true)}
          actionText={translate("home.workstationOverview.storage.actionText")}
          disabled={isButtonsDisabled || isTeamAccount}
          classSelector="workstation-storage-area"
        />
      </div>
      <div className="row">
        <ConnectionStrength regions={[workstation.attributes.machine.attributes.region]}>
          {({ connectionStrength }) => (
            <WorkstationOverviewItem
              label={translate("home.workstationOverview.connectionPerformance.label")}
              content={
                <div className="connection-strength-content">
                  {translate(`connectionStrength.${connectionStrength}`)}
                  <HoverableTooltip
                    content={translate("home.workstationOverview.connectionPerformance.info")}
                    side="right"
                    align="end"
                  >
                    <Icon name="info" color="gray-4" />
                  </HoverableTooltip>
                </div>
              }
              actionText="Change"
              disabled={isChangeRegionDisabled}
              action={() => {
                setShouldShowMigrateRegion(true);
              }}
            />
          )}
        </ConnectionStrength>

        <WorkstationOverviewItem
          label={translate("home.workstationOverview.runInBackground.label")}
          content={
            <Dropdown
              defaultSelected={autoTurnOffSetting}
              options={Object.keys(AUTO_STOP_OPTIONS)}
              mapFunction={(option) => {
                return translate(`autoStopOption.${option}`);
              }}
              handleSelectedOptionChange={selectAutoTurnOffOption}
              disabled={isInGracePeriod}
            />
          }
        />
      </div>
      {showAfterCreationOnboardingModal && (
        <AfterCreationOnboardingModal
          translate={translate}
          onExit={() => {
            setShowAfterCreationOnboardingModal(false);
            localStorage.setItem(LOCAL_STORAGE.showAfterCreationOnboardingModal, false);
          }}
        />
      )}
    </div>
  );
};

export default WorkstationOverview;
