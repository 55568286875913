import React, { useMemo, useRef } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import {
  Dropdown,
  NumberInput,
  Switch,
  Icon,
  HoverableTooltip,
  TextButton,
  ExpandableTable,
  ProgressBar,
  IconButton,
} from "UI";
import { dolarToCents, humanFileSize } from "Utils/Helpers/functions.helpers";
import useFileUpload from "Utils/Hooks/useFileUpload";
import { useMutation, useQuery } from "@tanstack/react-query";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator } from "Utils";
import { FILE_UPLOAD_STATUS } from "Constants/global.constants";

import "./FilesStep.styles.scss";

const FilesStep = ({ streamConfigs, setStreamConfigs, translate }) => {
  const {
    filesEnabled,
    fileStorageSize,
    fileUploadLimit,
    fileDownloadLimit,
    pixelStreamingEnabled,
    renderStreamingEnabled,
    streamFileStorage,
    sumOfAllUsedCapacities,
  } = streamConfigs;
  const { getCurrentPlanCTX } = useSelector((state) => state.appStreaming);
  const fileStoragePricing = getCurrentPlanCTX?.data?.attributes?.file_storage_pricing;
  const disablePreloadFileActions = sumOfAllUsedCapacities > 0;

  const preloadFilesInputRef = useRef(null);
  const streamId = streamConfigs.id;

  const { data: preloadedFilesData, refetch: refetchPreloadedFiles } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.STREAM_PRELOADED_FILES(streamConfigs.id)],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.STREAM_PRELOADED_FILES(streamConfigs.id)).then(
        (res) => res.data,
      );
    },
  });

  const removePreloadedFileMutation = useMutation({
    mutationFn: (fileId) => {
      return apiGenerator("delete")(APP_STREAMING_API_ENDPOINTS.STREAM_PRELOADED_FILE(streamId, fileId));
    },

    onSuccess: () => {
      refetchPreloadedFiles();
    },
  });

  const { uploadFile, uploadingFiles, cancelUpload } = useFileUpload({
    onFileUploadSuccess: (fileName) => {
      cancelUpload(fileName);
      refetchPreloadedFiles();
    },
    afterCancelUpload: (fileId) => {
      removePreloadedFileMutation.mutate(fileId);
    },
  });

  const preloadFilesTableData = useMemo(() => {
    const filesData =
      preloadedFilesData?.files?.map((file) => {
        return {
          id: file.id,
          name: <p>{file.attributes.name}</p>,
          size: humanFileSize(file.attributes?.size),
          action: (
            <div className="actions-cell">
              <IconButton
                name="close"
                onClick={() => {
                  removePreloadedFileMutation.mutate(file.id);
                }}
                disabled={disablePreloadFileActions}
              />
            </div>
          ),
        };
      }) || [];

    const uploadingFilesData = Object.keys(uploadingFiles).map((key) => {
      const file = uploadingFiles[key];

      return {
        id: key,
        name: <p>{key}</p>,
        size: humanFileSize(file.file?.size),
        action: (
          <div className="actions-cell">
            {file.status === FILE_UPLOAD_STATUS.FAILURE ? (
              <HoverableTooltip content={translate(`fileUploadErrors.${file.errorCode}`)} side="right">
                <Icon name="info" color="red" />
              </HoverableTooltip>
            ) : (
              <>
                <span className="baby-blue">%{Math.round(file.progress)}</span>
                <ProgressBar progress={file.progress} />
              </>
            )}
            <IconButton
              name="close"
              onClick={() => {
                cancelUpload(key);
              }}
            />
          </div>
        ),
      };
    });

    return [...filesData, ...uploadingFilesData];
  }, [uploadingFiles, preloadedFilesData]);

  return (
    <>
      <div className="configuration-option">
        <div className="with-icon">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.streamFileStorage.header")}
            checked={streamFileStorage}
            disabled={pixelStreamingEnabled || renderStreamingEnabled}
            onChange={(e) => {
              setStreamConfigs({ streamFileStorage: e, filesEnabled: e ? filesEnabled : false });
            }}
          />
        </div>
        <p className="description">{translate("configureStream.configurations.streamFileStorage.description")}</p>
      </div>

      {streamFileStorage && (
        <>
          <div className="configuration-option dropdown file-storage-size">
            <div>
              <p className="header">{translate("configureStream.configurations.fileStorageSize.header")}</p>
              <p className="description">{translate("configureStream.configurations.fileStorageSize.description")}</p>
            </div>
            <div className="input-box">
              <Dropdown
                underlined
                defaultSelected={fileStorageSize}
                options={fileStoragePricing?.map((option) => option.attributes.size)}
                handleSelectedOptionChange={(e) => {
                  setStreamConfigs({ fileStorageSize: e });
                }}
                mapFunction={(option) => {
                  const dolar =
                    fileStoragePricing?.find((pricing) => pricing.attributes.size === option)?.attributes?.price || 0;
                  const cents = dolarToCents(dolar, 2);
                  return (
                    <Translate
                      id="configureStream.configurations.fileStorageSize.option"
                      options={{
                        renderInnerHtml: true,
                      }}
                      data={{
                        price: cents,
                        size: option,
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="configuration-option dropdown">
            <div className="text-content">
              <div className="with-icon">
                <p className="header">{translate("configureStream.configurations.preloadFiles.header")}</p>
                {disablePreloadFileActions && (
                  <HoverableTooltip content="Ensure that you don't have any running Stream Machines." side="right">
                    <Icon name="info" color="gray-3" smaller />
                  </HoverableTooltip>
                )}
              </div>

              <p className="description files-step-description">
                <Translate
                  id="configureStream.configurations.preloadFiles.description"
                  options={{
                    renderInnerHtml: true,
                  }}
                />
              </p>
            </div>

            <TextButton
              text="Browse"
              color="aqua-main"
              className="preload-files-button"
              onClick={() => {
                preloadFilesInputRef.current.click();
              }}
              disabled={disablePreloadFileActions}
            />
            <input
              type="file"
              style={{ display: "none" }}
              name="logo-upload-input"
              ref={preloadFilesInputRef}
              multiple
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const { files } = event.target;
                if (files.length > 0) {
                  const filesArray = Array.from(files);
                  filesArray.forEach((file) => {
                    uploadFile(file, APP_STREAMING_API_ENDPOINTS.STREAM_PRELOADED_FILES(streamId), (fileId) => {
                      return APP_STREAMING_API_ENDPOINTS.COMPLETE_STREAM_PRELOADED_FILES(streamId, fileId);
                    });
                  });
                }

                preloadFilesInputRef.current.value = "";
              }}
            />
          </div>
          {preloadFilesTableData.length > 0 && (
            <ExpandableTable
              className="preload-files-table"
              columns={[
                { name: "Name", weight: 5 },
                { name: "Size", weight: 3 },
                { name: "Action", weight: 2, hide: true },
              ]}
              data={preloadFilesTableData}
            />
          )}
          <div className="configuration-option">
            <div className="with-icon">
              <Switch
                enterprise
                reverseText
                className="header"
                text={translate("configureStream.configurations.files.header")}
                checked={filesEnabled}
                disabled={pixelStreamingEnabled || renderStreamingEnabled}
                onChange={(e) => {
                  setStreamConfigs({ filesEnabled: e });
                }}
              />
              <HoverableTooltip
                content={
                  pixelStreamingEnabled || renderStreamingEnabled
                    ? translate("pixelStreamingNotSupported")
                    : translate("configureStream.configurations.files.hoverContent")
                }
                side="right"
              >
                <Icon name="info" color="gray-4" smaller />
              </HoverableTooltip>
            </div>
            <p className="description">{translate("configureStream.configurations.files.description")}</p>
          </div>
          {filesEnabled && (
            <>
              <div className="configuration-option dropdown">
                <div className="text-content">
                  <div className="with-icon">
                    <p className="header">{translate("configureStream.configurations.sessionUploadLimit.header")}</p>
                  </div>
                  <p className="description">
                    {translate("configureStream.configurations.sessionUploadLimit.description")}
                  </p>
                </div>
                <div className="input-box">
                  <NumberInput
                    value={fileUploadLimit}
                    minInputValue={0}
                    maxInputValue={100}
                    onChange={(e) => setStreamConfigs({ fileUploadLimit: e })}
                  />
                  <p>GB per session</p>
                </div>
              </div>

              <div className="configuration-option dropdown">
                <div className="text-content">
                  <div className="with-icon">
                    <p className="header">{translate("configureStream.configurations.sessionDownloadLimit.header")}</p>
                    <HoverableTooltip
                      content={translate("configureStream.configurations.sessionDownloadLimit.hoverContent")}
                      side="right"
                    >
                      <Icon name="info" color="gray-4" smaller />
                    </HoverableTooltip>
                  </div>
                  <p className="description">
                    {translate("configureStream.configurations.sessionDownloadLimit.description")}
                  </p>
                </div>
                <div className="input-box">
                  <NumberInput
                    value={fileDownloadLimit}
                    minInputValue={0}
                    maxInputValue={100}
                    onChange={(e) => setStreamConfigs({ fileDownloadLimit: e })}
                  />
                  <p>GB per session</p>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FilesStep;
