import React, { useEffect, useMemo, useState } from "react";
import { LOCAL_STORAGE } from "Constants/global.constants";
import { TextButton, Switch, ProgressBar, Dropdown, HoverableTooltip, Icon } from "UI";
import useLocalStorage from "Utils/Hooks/useLocalStorage";
import { VR_BIT_RATES, VR_INSTALLATION_STATES, VR_STATES } from "Constants/streaming.constants";
import VrImage from "Assets/images/vr.png";
import { Translate } from "react-localize-redux";
import { classnames } from "Utils";
import { isVrSupported } from "Utils/Helpers/browser.helpers";
import _ from "lodash";
import { ToastNotificationContent } from "Components/Workstation/ToastNotification/ToastNotification";
import { toast } from "react-toastify";

import "./VrTab.styles.scss";

const VrTab = ({
  connected,
  isDockOpen,
  setIsDockOpen,
  translate,
  toggleVRMode,
  toggleVREnvironment,
  vrState,
  vrInstallationState,
  sendIsVrToolsRunningEvent,
  applyVrBitrate,
}) => {
  const [vrEnabled, setVrEnabled] = useLocalStorage(LOCAL_STORAGE.vrEnvironmentEnabled, false);
  const [vrBitRate, setVrBitRate] = useLocalStorage(LOCAL_STORAGE.vrBitRate, VR_BIT_RATES[15]);
  const [vrInstallationPercentage, setVrInstallationPercentage] = useLocalStorage(
    LOCAL_STORAGE.vrInstallationPercentage,
    0,
  );
  const [disableVrToggle, setDisableVrToggle] = useState(false);
  const [vrSupported, setVrSupported] = useState(false);

  useEffect(() => {
    isVrSupported().then((supported) => {
      if (supported) {
        setVrSupported(true);
      }
    });
  }, []);

  useEffect(() => {
    if (vrEnabled && vrState === VR_STATES.failed) {
      toast(<ToastNotificationContent description={translate("dock.vr.installationFailed")} />, {
        autoClose: 5000,
      });
      setVrEnabled(false);
    }
  }, [vrState, vrEnabled]);

  const vrToolsRunning = vrState === VR_STATES.running;

  useEffect(() => {
    let intervalId;
    if (vrEnabled) {
      intervalId = setInterval(() => {
        sendIsVrToolsRunningEvent();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [vrEnabled, sendIsVrToolsRunningEvent]);

  useEffect(() => {
    if (vrEnabled) {
      sendIsVrToolsRunningEvent();
    }
  }, [vrEnabled, sendIsVrToolsRunningEvent, vrState, isDockOpen]);

  const vrToolsInstalling = !_.isEmpty(vrInstallationState);
  const currentInstallationPercentage =
    VR_INSTALLATION_STATES[`${vrInstallationState.tool}:${vrInstallationState.state}`];

  const installationPercentages = Object.values(VR_INSTALLATION_STATES);

  const nextInstallationPercentage = installationPercentages.find(
    (percentage) => percentage > currentInstallationPercentage,
  );

  const joinDisabled = !connected || !vrEnabled || !vrToolsRunning || disableVrToggle || !vrSupported;

  const joinIcon = useMemo(() => {
    if (disableVrToggle && vrEnabled) {
      return "loader";
    }

    if (!joinDisabled) {
      return "vr-headset";
    }

    return null;
  }, [disableVrToggle, vrEnabled, vrToolsRunning, joinDisabled]);

  useEffect(() => {
    let interval;
    if (vrToolsInstalling) {
      if (vrInstallationPercentage < currentInstallationPercentage) {
        setVrInstallationPercentage(currentInstallationPercentage);
      }
      interval = setInterval(() => {
        if (currentInstallationPercentage === 100) {
          clearInterval(interval);
        }
        setVrInstallationPercentage((prev) => {
          if (prev === 100) {
            return 100;
          }
          if (prev < nextInstallationPercentage) {
            return prev + 1;
          }
          return prev;
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [vrInstallationState, vrToolsInstalling]);

  return (
    <div className="vr-tab">
      <div className="beta-header">
        <h1>{translate("dock.vr.header")}</h1>
        <span className="beta-tag">{translate("dock.vr.beta")}</span>
      </div>
      <Switch
        className="menu-item"
        text={translate("dock.settings.vrEnabled")}
        checked={vrEnabled}
        onChange={(state) => {
          setVrEnabled(state);
          toggleVREnvironment(state);
          setDisableVrToggle(true);
          setTimeout(() => {
            setDisableVrToggle(false);
          }, 10000);
        }}
        disabled={!connected || vrToolsInstalling || disableVrToggle}
      />
      <>
        {vrToolsInstalling && (
          <div className="menu-item installation-state-container">
            <p>Installing Dependencies...</p>
            <span className="baby-blue">%{Math.round(vrInstallationPercentage)}</span>
            <ProgressBar progress={vrInstallationPercentage} />
          </div>
        )}

        <p className={classnames(["menu-item", joinDisabled && "disabled"])}>{translate("dock.vr.vrQuality.header")}</p>
        <div className="menu-item">
          <Dropdown
            defaultSelected={vrBitRate}
            options={Object.values(VR_BIT_RATES)}
            mapFunction={(option) => translate(`dock.vr.vrQuality.options.${option}`)}
            handleSelectedOptionChange={(option) => {
              setVrBitRate(option);
              if (applyVrBitrate) {
                applyVrBitrate();
              }
            }}
            underlined
            disabled={joinDisabled}
          />
        </div>
      </>

      <div className="join-session-button-container">
        <TextButton
          className={classnames(["menu-item", joinIcon])}
          text={translate("dock.settings.vrJoinSession")}
          onClick={() => {
            toggleVRMode();
            setIsDockOpen(false);
          }}
          disabled={joinDisabled}
          icon={joinIcon}
        />

        {!vrSupported && vrEnabled && (
          <HoverableTooltip content="Device Not Found">
            <Icon name="info" color="gray-3" small />
          </HoverableTooltip>
        )}
      </div>

      {!vrToolsInstalling && (
        <div className="empty-folder">
          <img src={VrImage} alt="Nothing to show" />
          <h1>{translate("dock.vr.header")}</h1>

          {vrEnabled ? (
            <>
              <p className="empty-folder-info">
                <Translate
                  id="dock.vr.enabled.description"
                  options={{
                    renderInnerHtml: true,
                  }}
                />
              </p>
            </>
          ) : (
            <>
              <p className="empty-folder-info">
                <Translate
                  id="dock.vr.disabled.description1"
                  options={{
                    renderInnerHtml: true,
                  }}
                />
              </p>
              <p className="empty-folder-info with-margin">
                <Translate
                  id="dock.vr.disabled.description2"
                  options={{
                    renderInnerHtml: true,
                  }}
                />
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default VrTab;
