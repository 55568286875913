import React, { useState, useRef, useEffect } from "react";
import { ConfirmationModal } from "UI";

import { isMacOS } from "Utils/Helpers/browser.helpers";
import { logger } from "Utils/Helpers/logger.helpers";

const createActionText = (clipboardModalCopyMode, translate) => {
  if (isMacOS && !clipboardModalCopyMode) {
    return translate("modals.sync.macOS.pasteText");
  }

  if (!isMacOS && !clipboardModalCopyMode) {
    return translate("modals.sync.pasteText");
  }

  if (isMacOS && clipboardModalCopyMode) {
    return translate("modals.sync.macOS.copyText");
  }

  if (!isMacOS && clipboardModalCopyMode) {
    return translate("modals.sync.copyText");
  }

  return "";
};

const SyncClipboardModal = (props) => {
  const { setShowClipboardModal, clipboardModalCopyMode, firefoxSafariOnPaste, firefoxSafariOnCopy, translate } = props;

  const [showActionText, setShowActionText] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    // TODO consider adding it with button click
    document.addEventListener("copy", onCopy);
    document.addEventListener("paste", onPaste);

    return () => {
      document.removeEventListener("copy", onCopy);
      document.removeEventListener("paste", onPaste);
    };
  }, []);

  const onCopy = (event) => {
    event.preventDefault();
    logger("onCopy");
    firefoxSafariOnCopy(event);
    setShowClipboardModal(false);
  };

  const onPaste = (event) => {
    event.preventDefault();
    logger("onPaste");
    firefoxSafariOnPaste(event);
    setShowClipboardModal(false);
  };

  return (
    <ConfirmationModal
      modalRef={modalRef}
      headerText={translate("modals.sync.header")}
      descriptionText={translate("modals.sync.description")}
      confirmText={translate("modals.sync.confirm")}
      confirmAction={() => {
        setShowActionText(true);
      }}
      closeAction={() => {
        setShowClipboardModal(false);
      }}
      secondaryText={showActionText && createActionText(clipboardModalCopyMode, translate)}
      disableConfirmButton={showActionText}
      onPaste={onPaste}
      onCopy={onCopy}
    />
  );
};

export default SyncClipboardModal;
