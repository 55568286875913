import * as VerifyRegisterConstants from "Constants/Authentication/VerifyRegister.constants";
import { REQUEST_STATUS } from "Constants/global.constants";

const initialState = {
  status: REQUEST_STATUS.NOT_DEFINED,
  error: false,
};

export default function verifyRegisterCTX(state = initialState, action) {
  switch (action.type) {
    case VerifyRegisterConstants.VERIFY_REGISTER_API_PENDING: {
      return verifyRegisterAPIPending(state);
    }
    case VerifyRegisterConstants.VERIFY_REGISTER_API_SUCCESS: {
      return verifyRegisterAPISuccess(state);
    }
    case VerifyRegisterConstants.VERIFY_REGISTER_API_FAILURE: {
      return verifyRegisterAPIFailure(state);
    }
    default: {
      return state;
    }
  }
}

function verifyRegisterAPIPending(state) {
  return {
    ...state,
    status: REQUEST_STATUS.PENDING,
    error: false,
  };
}

function verifyRegisterAPISuccess(state) {
  return {
    ...state,
    status: REQUEST_STATUS.SUCCESS,
    error: false,
  };
}

function verifyRegisterAPIFailure(state) {
  return {
    ...state,
    status: REQUEST_STATUS.FAILURE,
    error: true,
  };
}
