import React, { useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import {
  DashboardSubScreenLayout,
  Loader,
  doesEventContainsFiles,
  bytesToGB,
  getItemFromLocalStorage,
  isMobile,
} from "Utils";
import { IconButton, TextButton, BoxInput, HoverableTooltip, BoardingModal, ConfirmationModal, Icon } from "UI";
import EmptyFolderImage from "Assets/images/fileManager/cloud-empty-1.png";
import VagonSubscriptionImage from "Assets/images/fileManager/file-subscription.png";
import VagonGracePeriodImage from "Assets/images/fileManager/file-grace.jpg";
import { REQUEST_STATUS, FILE_TYPES, LOCAL_STORAGE, FILE_STATUS } from "Constants/global.constants";
import FileUploadModal from "Utils/Components/File/FileUploadModal/FileUploadModal.component";
import FileDownloadModal from "Utils/Components/File/FileDownloadModal.component";
import ProcessPaymentModal from "Utils/Components/Payment/ProcessPaymentModal/ProcessPaymentModal.component";
import FileDuplicationModal from "./FileDuplicationModal.component";
import FileManagerTable from "./Components/FileManagerTable/FileManagerTable.component";
import FileMoveModal from "./Components/FileMoveModal/FileMoveModal.component";
import UpgradeFileStorageModal from "./Components/UpgradeFileStorageModal/UpgradeFileStorageModal.component";
import FileRenameModal from "./Components/FileRenameModal/FileRenameModal.component";
import FolderCreateModal from "./Components/FolderCreateModal/FolderCreateModal.component";
import MenuPopUp from "./Components/MenuPopUp/MenuPopUp.component";
import FileBreadcrumb from "./Components/FileBreadcrumb/FileBreadcrumb.component";
import { EmptyFolder, FolderInfo } from "./Components/EmptyFolder.component";
import FilesOnboardingModal from "../FilesOnboardingModal.component";

import "./Files.styles.scss";

const SEARCH_INPUT_DEBOUNCE_TIME = 500;

const FileActionButton = (props) => {
  const { iconName, action, content, buttonRef, disabled } = props;

  return (
    <HoverableTooltip content={!disabled && content}>
      <IconButton
        buttonRef={buttonRef}
        name={iconName}
        className="selected-file-actions"
        onClick={action}
        disabled={disabled}
      />
    </HoverableTooltip>
  );
};

const isCurrentlyUsed = (contents) => {
  return contents?.findIndex((content) => content.attributes.object_type !== "root") !== -1;
};

const FilesComponent = (props) => {
  const {
    account,
    workstations,
    subscriptions,
    handleDashboardButtonClick,
    files,
    searchFiles,
    loadFileContents,
    deleteFile,
    renameFile,
    moveFile,
    downloadFile,
    createFolder,
    uploadFilesToFolder,
    cancelFileUpload,
    clearUploadedFile,
    getFileStoragePlans,
    upgradeFileStorageSubscription,
    translate,
  } = props;

  const { userFilesCTX, moveFilesCTX, uploadFilesCTX, downloadFileCTX, deleteFileCTX, renameFileCTX, createFolderCTX } =
    files;

  const { contents, hash, currentFolder, usedStorage, path, teamFolderInfo } = userFilesCTX;
  const { subscription, getFileStorageSubscriptionPlansCTX, upgradeFileStorageSubscriptionCTX } = subscriptions;

  const hasWorkstation = !!(workstations.workstations && workstations.workstations[0]);
  const hasAccess = hasWorkstation && !account.account.isInGracePeriod && !account.account.isInTeamGracePeriod;
  const currentFolderId = currentFolder ? currentFolder.id : 0;

  const moreButtonRef = useRef(null);
  const fileManagerContainerRef = useRef(null);
  const searchRequestTimeout = useRef(null);
  const [selectedFolderItems, setSelectedFolderItems] = useState([]);

  const selectedRowID = selectedFolderItems.length === 1 ? selectedFolderItems[0] : null;
  const selectedItem = _.find(contents, (item) => item.id === selectedRowID);
  const teamSharedFolderItem = _.find(contents, (item) => item?.attributes?.name === "Teams Shared Folder");
  const isIncludeTeamSharedFolder = selectedFolderItems.find((item) => item === teamSharedFolderItem?.id);
  const disableActions = !isIncludeTeamSharedFolder;

  const selectedItems = _.filter(contents, (item) => selectedFolderItems.includes(item.id));

  const deleteEnabled = useMemo(() => {
    return selectedItems.every((item) => item.attributes.deletable);
  }, [selectedItems]);

  const isSelectedItemNotDirectory =
    selectedItem &&
    selectedItem.attributes.content_type !== FILE_TYPES.directory &&
    selectedItem.attributes.content_type !== FILE_TYPES.root;

  const [searchInput, setSearchInput] = useState("");
  const [filteredFiles, setFilteredFiles] = useState(contents);
  const searchedFiles = searchInput ? filteredFiles : null;

  const [popUpPosition, setPopUpPosition] = useState(null);

  const [showUpgradeFileStorageModal, setShowUpgradeFileStorageModal] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showRenameFileModal, setShowRenameFileModal] = useState(false);
  const [showFileDownloadModal, setShowFileDownloadModal] = useState(false);
  const [showFileMoveModal, setShowFileMoveModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const [showProcessPaymentModal, setShowProcessPaymentModal] = React.useState(false);
  const [showFileDeleteConfirmationModal, setShowFileDeleteConfirmationModal] = React.useState(false);
  const [showFilesOnboardingModal, setShowFilesOnboardingModal] = useState(null);

  const pending = [
    !showFileMoveModal && userFilesCTX.status,
    userFilesCTX.searchStatus,
    uploadFilesCTX.status,
    downloadFileCTX.status,
    deleteFileCTX.status,
    renameFileCTX.status,
  ].includes(REQUEST_STATUS.PENDING);

  useEffect(() => {
    const onDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!showFileUploadModal && doesEventContainsFiles(event) && !showFilesOnboardingModal) {
        setShowFileUploadModal(true);
      }
    };

    window.addEventListener("dragover", onDragOver);

    return () => {
      window.removeEventListener("dragover", onDragOver);
      clearTimeout(searchRequestTimeout.current);
    };
  }, [showFilesOnboardingModal]);

  useEffect(() => {
    // If hash changes filter again
    if (searchInput && contents) {
      const searchString = searchInput.toLowerCase();
      const filteredContents = _.filter(contents, (file) => file.attributes.name.toLowerCase().includes(searchString));
      setFilteredFiles(filteredContents);
    }
  }, [hash]);

  useEffect(() => {
    if (userFilesCTX.status === REQUEST_STATUS.SUCCESS) {
      setSearchInput("");
      if (showFilesOnboardingModal === null) {
        const shouldDisplayFilesOnboarding =
          hasWorkstation &&
          !isMobile &&
          !account.hasDeletedSubscription &&
          getItemFromLocalStorage(LOCAL_STORAGE.showFilesOnboardingModal, true) &&
          !isCurrentlyUsed(userFilesCTX.contents);
        setShowFilesOnboardingModal(shouldDisplayFilesOnboarding);
      }
    }
  }, [userFilesCTX.status]);

  useEffect(() => {
    if (downloadFileCTX.status === REQUEST_STATUS.PENDING) {
      setShowFileDownloadModal(true);
    }
  }, [downloadFileCTX.status]);

  const onUploadFileDrop = (files) => {
    if (files?.length > 0) {
      uploadFilesToFolder({ files, currentFolderId: searchInput ? null : currentFolderId });
    }
  };

  const goToFolder = (id) => {
    loadFileContents(id);
    setSelectedFolderItems([]);
  };

  const renameFileAction = () => {
    if (!hasAccess) return;

    setShowRenameFileModal(true);
    if (popUpPosition) setPopUpPosition(false);
  };

  const deleteFilesAction = () => {
    if (!isIncludeTeamSharedFolder) {
      selectedFolderItems.forEach((item) => {
        deleteFile(item, currentFolderId);
      });
      setSelectedFolderItems([]);
    }
  };

  const moveFileAction = () => {
    if (!hasAccess) return;

    setShowFileMoveModal(true);
    if (popUpPosition) setPopUpPosition(false);
  };

  const downloadFileAction = () => {
    downloadFile(selectedItem.id, selectedItem.attributes.name, selectedItem.attributes.size);
    if (popUpPosition) setPopUpPosition(false);
  };

  const handleSearchInputChange = (event) => {
    if (account.account.isInGracePeriod) return;

    setSelectedFolderItems([]);
    const searchValue = event.target.value;
    setSearchInput(searchValue);

    clearTimeout(searchRequestTimeout.current);
    if (searchValue) {
      const searchString = searchValue.toLowerCase();
      const filteredContents = _.filter(contents, (file) => file.attributes.name.toLowerCase().includes(searchString));
      setFilteredFiles(filteredContents);
      searchRequestTimeout.current = setTimeout(
        () => searchFiles(currentFolderId, searchValue),
        SEARCH_INPUT_DEBOUNCE_TIME,
      );
    } else {
      setFilteredFiles(null);
      // loadFileContents(currentFolderId, false, true);
      searchFiles(currentFolderId, searchValue);
    }
  };

  const handleRightClick = (event, item) => {
    event.preventDefault();
    if (account.account.isInGracePeriod) return;

    setSelectedFolderItems([item.id]);

    if ((selectedItem || item) && selectedFolderItems.length <= 1 && item.type !== FILE_TYPES.root && disableActions) {
      setPopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
    }
  };

  const handleMoreButtonClick = () => {
    const buttonRect = moreButtonRef.current.getBoundingClientRect();
    const position = { x: buttonRect.right, y: buttonRect.bottom, top: buttonRect.top };

    if (!popUpPosition && popUpPosition !== false) {
      setPopUpPosition(position);
    } else {
      setPopUpPosition(null);
    }
  };

  const showUsageInfo = () => {
    let used;
    let total;
    // Show team files info
    if (currentFolder && currentFolder.attributes && currentFolder.attributes.team_id) {
      used = bytesToGB(teamFolderInfo.in_use || 0).toFixed(2);
      total = bytesToGB(teamFolderInfo.total || 0);
    } else {
      // Show user files info
      used = bytesToGB(usedStorage || 0).toFixed(2);
      total = subscription.attributes.file_storage_size;
    }
    return `${used}  /  ${total} GB`;
  };

  const isDuplicateFilesExist = !_.isEmpty(
    _.filter(uploadFilesCTX.uploadFiles, (file) => file.isDuplicate && !file.progress),
  );

  const fileContent = () => {
    switch (userFilesCTX.status) {
      case REQUEST_STATUS.PENDING:
      case REQUEST_STATUS.SUCCESS: {
        if (!contents) {
          return <Loader />;
        }
        return (
          <>
            {searchInput.length > 0 ? (
              <h1 className="breadcrumb">{translate("fileSearch.results")}</h1>
            ) : (
              <FileBreadcrumb
                currentFolder={currentFolder}
                path={path}
                goToFolder={goToFolder}
                onBackButton={() => {
                  goToFolder(currentFolder.attributes.parent_id);
                }}
              />
            )}

            {contents && contents.length > 0 ? (
              <FileManagerTable
                contents={searchedFiles || contents}
                onRightClick={handleRightClick}
                goToFolder={goToFolder}
                selectedRowID={selectedRowID}
                selectedFolderItems={selectedFolderItems}
                setSelectedFolderItems={setSelectedFolderItems}
                translate={translate}
              />
            ) : (
              <FolderInfo currentFolder={currentFolder} translate={translate} />
            )}
          </>
        );
      }
      case REQUEST_STATUS.FAILURE: {
        return (
          <EmptyFolder
            image={EmptyFolderImage}
            title={translate("emptyFolder.header")}
            info={translate("emptyFolder.info")}
          />
        );
      }
      default:
        return <Loader />;
    }
  };

  const selectedFolderName = () => {
    if (selectedFolderItems?.length === 1) {
      return _.find(userFilesCTX.contents, { id: selectedFolderItems[0] }).attributes.name;
    }
    return translate("selectedFolder.description");
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("header.title")}
      headerDescription={translate("header.description")}
      headerRightContent={
        subscription && (
          <>
            <div>
              <p>{showUsageInfo()}</p>
            </div>
            {!account.isTeamAccount && (
              <TextButton
                text={translate("header.headerAction")}
                color="baby-blue"
                onClick={() => {
                  getFileStoragePlans();
                  setShowUpgradeFileStorageModal(true);
                }}
              />
            )}
          </>
        )
      }
      waitFor={[
        workstations.getWorkstationsCTX.status,
        account.getAccountCTX.status,
        userFilesCTX.status === REQUEST_STATUS.SUCCESS,
      ]}
      pending={pending}
      translate={translate}
    >
      <div
        ref={fileManagerContainerRef}
        className="file-manager-container"
        disabled={!hasAccess}
        onDrop={onUploadFileDrop}
      >
        <div className="file-manager-actions-bar">
          <div className="file-manager-left-actions">
            <div className="search-input">
              <BoxInput
                placeholder={translate("fileSearch.placeHolder")}
                iconName="search"
                color="purple-light"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
            </div>
            {/* eslint-disable  */}
            <HoverableTooltip content="Add Folder">
              <div
                className="left-action-button"
                onClick={() => {
                  if (!hasAccess) return;
                  setShowCreateFolderModal(true);
                }}
              >
                <IconButton name="folder-create" />
              </div>
            </HoverableTooltip>
            <HoverableTooltip content="Upload File">
              <div
                className="left-action-button"
                onClick={() => {
                  if (!hasAccess) return;
                  setShowFileUploadModal(true);
                }}
              >
                <IconButton name="files-upload" color="gray-2" />
              </div>
            </HoverableTooltip>
            {/* eslint-enable  */}
          </div>
          <div className="file-manager-right-actions">
            {
              // selectedItem && selectedItem.type !== FILE_TYPES.root
              selectedFolderItems.length > 0 && (
                <>
                  <div className="vertical-divider" />
                  {disableActions && (
                    <FileActionButton
                      iconName="trash"
                      content="Delete"
                      action={() => {
                        setShowFileDeleteConfirmationModal(true);
                        setPopUpPosition(false);
                      }}
                      disabled={!deleteEnabled}
                    />
                  )}
                  {
                    <div className="select-file">
                      <Icon name="file-select" />
                      {Boolean(selectedFolderItems) && <p>{selectedFolderItems.length} Selected</p>}
                    </div>
                  }
                  {isSelectedItemNotDirectory && selectedFolderItems.length <= 1 && (
                    <FileActionButton iconName="file-download" content="Download" action={downloadFileAction} />
                  )}
                  {/* <FileActionButton iconName="share" content="Share" /> */}
                  {disableActions && (
                    <FileActionButton
                      buttonRef={moreButtonRef}
                      iconName="more"
                      content="More"
                      action={(event) => {
                        event.preventDefault();
                        handleMoreButtonClick();
                      }}
                    />
                  )}
                </>
              )
            }
          </div>
        </div>
        {fileContent()}
        {popUpPosition && (
          <MenuPopUp
            referencePosRef={fileManagerContainerRef}
            position={popUpPosition}
            moveFile={moveFileAction}
            deleteFile={() => setShowFileDeleteConfirmationModal(true)}
            deleteFileDisabled={!deleteEnabled}
            renameFile={renameFileAction}
            downloadFile={isSelectedItemNotDirectory && downloadFileAction}
            setShowMenuPopUp={setPopUpPosition}
            selectedFolderItems={selectedFolderItems}
            disable={isIncludeTeamSharedFolder}
            translate={translate}
          />
        )}
      </div>
      {!hasWorkstation && (
        <BoardingModal
          headerImages={[VagonSubscriptionImage]}
          headerTexts={[translate("modals.needSubscription.header")]}
          buttonTexts={[translate("modals.needSubscription.buttonText")]}
          descriptions={[translate("modals.needSubscription.description")]}
          buttonAction={handleDashboardButtonClick}
          blue
        />
      )}
      {account.account.isInGracePeriod && !showProcessPaymentModal && (
        <BoardingModal
          headerImages={[VagonGracePeriodImage]}
          headerTexts={[translate("modals.gracePeriod.header")]}
          buttonTexts={[translate("modals.gracePeriod.buttonText")]}
          descriptions={[translate("modals.gracePeriod.description")]}
          buttonAction={() => {
            setShowProcessPaymentModal(true);
          }}
          blue
        />
      )}
      {account.account.isInTeamGracePeriod && !showProcessPaymentModal && (
        <BoardingModal
          headerImages={[VagonGracePeriodImage]}
          headerTexts={[translate("modals.teamGracePeriod.header")]}
          buttonTexts={[translate("modals.teamGracePeriod.buttonText")]}
          descriptions={[translate("modals.teamGracePeriod.description")]}
          buttonAction={() => {
            setShowProcessPaymentModal(true);
          }}
          buttonHidden
        />
      )}
      {showProcessPaymentModal && (
        <ProcessPaymentModal setShouldShowProcessPaymentModal={setShowProcessPaymentModal} translate={translate} />
      )}
      {showCreateFolderModal && (
        <FolderCreateModal
          createFolderCTX={createFolderCTX}
          createFolder={(newFolderName) => {
            createFolder(newFolderName, searchInput ? null : currentFolderId);
          }}
          setShowCreateFolderModal={setShowCreateFolderModal}
          translate={translate}
        />
      )}
      {showRenameFileModal && (
        <FileRenameModal
          renameFileCTX={renameFileCTX}
          renameFile={(newFileName) => {
            renameFile(selectedFolderItems[0], newFileName, currentFolderId);
          }}
          fileType={selectedItem.attributes.content_type}
          previousName={selectedItem.attributes.name}
          setShowRenameFileModal={setShowRenameFileModal}
          translate={translate}
        />
      )}
      {showUpgradeFileStorageModal && (
        <UpgradeFileStorageModal
          remainingDaysToRenew={account.account.remainingDaysToRenew}
          upgradeFileStorageSubscription={upgradeFileStorageSubscription}
          upgradeFileStorageSubscriptionCTX={upgradeFileStorageSubscriptionCTX}
          getFileStorageSubscriptionPlansCTX={getFileStorageSubscriptionPlansCTX}
          setShowUpgradeFileStorageModal={setShowUpgradeFileStorageModal}
          translate={translate}
        />
      )}
      {showFileMoveModal && (
        <FileMoveModal
          moveFile={(newFolderId) => {
            selectedFolderItems.forEach((item) => {
              moveFile(item, newFolderId, currentFolderId);
            });
            setShowFileMoveModal(false);
            loadFileContents(currentFolderId, true);
            setSelectedFolderItems([]);
          }}
          loadFileContents={loadFileContents}
          moveFilesCTX={moveFilesCTX}
          selectedFileIds={selectedFolderItems}
          setShowFileMoveModal={(showFileMoveModal) => {
            if (!showFileMoveModal) loadFileContents(currentFolderId, true);
            setShowFileMoveModal(showFileMoveModal);
          }}
          translate={translate}
        />
      )}
      {!isDuplicateFilesExist && showFileUploadModal && (
        <FileUploadModal
          clearUploadedFile={clearUploadedFile}
          setShowFileUploadModal={setShowFileUploadModal}
          files={uploadFilesCTX.uploadFiles}
          cancelFileUpload={cancelFileUpload}
          onFileDrop={onUploadFileDrop}
          account={account}
          searchInput={searchInput}
          currentFolderId={currentFolderId}
          createFolder={createFolder}
          contents={contents}
          uploadFilesToFolder={uploadFilesToFolder}
          translate={translate}
        />
      )}
      {showFileDownloadModal && (
        <FileDownloadModal
          fileName={downloadFileCTX.name}
          fileSize={downloadFileCTX.size}
          downloadUrl={downloadFileCTX.url}
          setShowDownloadModal={setShowFileDownloadModal}
          translate={translate}
        />
      )}
      {isDuplicateFilesExist &&
        _.map(
          _.filter(uploadFilesCTX.uploadFiles, (file) => file.isDuplicate && file.status === FILE_STATUS.FAILURE),
          (file, fileIndex) => {
            return (
              <FileDuplicationModal
                key={fileIndex}
                file={file}
                account={account}
                cancelFileUpload={cancelFileUpload}
                uploadFilesToFolder={uploadFilesToFolder}
                currentFolderId={currentFolderId}
                translate={translate}
              />
            );
          },
        )}
      {showFileDeleteConfirmationModal && (
        <ConfirmationModal
          closeOnOverlayClick
          closeAction={() => setShowFileDeleteConfirmationModal(false)}
          headerText={translate("modals.deleteFile.confirm.header")}
          descriptionText={translate("modals.deleteFile.confirm.description", {
            fileName: selectedFolderName(),
          })}
          confirmText={translate("modals.deleteFile.confirm.confirm")}
          confirmAction={() => {
            deleteFilesAction();
            setShowFileDeleteConfirmationModal(false);
          }}
          secondaryText={translate("modals.deleteFile.confirm.secondary")}
          secondaryAction={() => setShowFileDeleteConfirmationModal(false)}
        />
      )}
      {showFilesOnboardingModal && (
        <FilesOnboardingModal
          translate={translate}
          onExit={() => {
            setShowFilesOnboardingModal(false);
            localStorage.setItem(LOCAL_STORAGE.showFilesOnboardingModal, false);
          }}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default FilesComponent;
