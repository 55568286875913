import { REQUEST_STATUS } from "Constants/global.constants";
import { Translate } from "react-localize-redux";
import React, { useState, useEffect, useRef } from "react";

import { Modal, Input } from "UI";

const FolderCreateModal = (props) => {
  const { createFolder, createFolderCTX, setShowCreateFolderModal, translate } = props;
  const inputRef = useRef(null);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (submitted) {
      if (createFolderCTX.status === REQUEST_STATUS.SUCCESS) {
        setShowCreateFolderModal(false);
      } else if (createFolderCTX.status === REQUEST_STATUS.FAILURE) {
        setError(createFolderCTX.clientCode);
      }
    }
  }, [createFolderCTX.status]);

  const handleCreateFolder = () => {
    if (!submitted) {
      setSubmitted(true);
      createFolder(newFolderName);
    }
  };

  const handleEnterKeyUp = (event) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      handleCreateFolder();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "/" || (newFolderName === "" && event.key === " ")) {
      event.preventDefault();
    }
  };

  return (
    <Modal
      closeOnOverlayClick
      closeAction={() => setShowCreateFolderModal(false)}
      topRightIcon="close"
      topRightIconAction={() => setShowCreateFolderModal(false)}
      headerText={translate("modals.createFolder.header")}
      buttonText={translate("modals.createFolder.buttonText")}
      buttonAction={handleCreateFolder}
      buttonDisabled={newFolderName === "" || submitted}
      errorText={
        error &&
        submitted && (
          <Translate
            id={`modals.createFolder.error.${error}`}
            options={{ onMissingTranslation: () => translate("modals.createFolder.error.default") }}
          />
        )
      }
      isPending={createFolderCTX.status === REQUEST_STATUS.PENDING}
      small
      invisibleOverlay
      blue
    >
      <div className="create-folder-modal-content">
        <Input
          inputRef={inputRef}
          label={translate("modals.createFolder.description")}
          placeholder="Name"
          maxLength="50"
          value={newFolderName}
          onChange={(e) => {
            if (submitted) setSubmitted(false);
            if (error) setError(false);
            setNewFolderName(e.target.value);
          }}
          onKeyUp={handleEnterKeyUp}
          onKeyDown={handleKeyDown}
        />
      </div>
    </Modal>
  );
};

export default FolderCreateModal;
