import { clearUploadedFile, cancelFileUpload, clearUploadedFiles } from "Actions/Dashboard/Files.actions";
import { SESSION_STORAGE, UPLOAD_TYPES } from "Constants/global.constants";
import { getItemFromSessionStorage, saveItemToSessionStorage } from "Utils/Helpers/storage.helpers";
import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BoxInput, HoverableTooltip, Icon, IconButton, ProgressBar, TextButton } from "UI";
import { classnames } from "Utils";
import VagonNoFilesImage from "Assets/images/no-files.png";
import { toast } from "react-toastify";
import { ToastNotificationContent } from "Components/Workstation/ToastNotification/ToastNotification";
import "./FilesDock.styles.scss";

const FilesDock = ({
  files,
  isFilesDockOpen,
  setIsFilesDockOpen,
  reloadTempFiles,
  sendListTempFilesEvent,
  sendDownloadFileEvent,
  sendDownloadTempFileEvent,
  setDisableKeyboardActions,
  translate,
  openVagonExplorer,
  clickToStart,
}) => {
  const [query, setQuery] = useState("");
  const [showDragNotification, setShowDragNotification] = useState(false);
  const fileReloadInterval = useRef(null);
  const dragNotificationTimeoutRef = useRef(null);

  const { files: filesCTX } = useSelector((state) => state);
  const uploadingFiles = _.filter(
    filesCTX?.uploadFilesCTX?.uploadFiles,
    (file) => file.uploadType === UPLOAD_TYPES.STREAM_SESSION_FILE_UPLOAD,
  );
  const uploadedFiles = useSelector((state) => state.files?.streamSessionFilesCTX?.uploadedFiles);
  const dispatch = useDispatch();

  const filteredFiles = files.filter((value) => {
    if (query === "") {
      return true;
    }
    return value.toLowerCase().includes(query.toLowerCase());
  });

  const beforeUnload = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.returnValue = "Are you sure you want to close?";
  };

  useEffect(() => {
    if (clickToStart) return;

    const docksPreviewed = getItemFromSessionStorage(SESSION_STORAGE.streamDocksPreviewed);
    if (docksPreviewed) return;

    setIsFilesDockOpen(true);
    setTimeout(() => {
      setIsFilesDockOpen(false);
      saveItemToSessionStorage(SESSION_STORAGE.streamDocksPreviewed, true);
    }, 3000);
  }, [clickToStart]);

  useEffect(() => {
    reloadTempFiles();
    window.addEventListener("beforeunload", beforeUnload);
  }, []);

  useEffect(() => {
    if (uploadingFiles?.length > 0) {
      window.addEventListener("beforeunload", beforeUnload);
    } else {
      window.removeEventListener("beforeunload", beforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [uploadingFiles]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isFilesDockOpen) {
      fileReloadInterval.current = setInterval(() => {
        if (sendListTempFilesEvent) {
          sendListTempFilesEvent();
        }
      }, 5000);
    } else {
      clearInterval(fileReloadInterval.current);
    }

    return () => {
      clearInterval(fileReloadInterval.current);
    };
  }, [isFilesDockOpen]);

  useEffect(() => {
    Object.keys(uploadedFiles).forEach((key) => {
      const file = uploadedFiles[key];

      sendDownloadFileEvent(file.downloadUrl);
      setTimeout(() => {
        reloadTempFiles();
      }, 2500);
    });
  }, [JSON.stringify(uploadedFiles)]);

  useEffect(() => {
    if (isFilesDockOpen) {
      reloadTempFiles();
      sendListTempFilesEvent();
    }
  }, [isFilesDockOpen]);

  useEffect(() => {
    dispatch(clearUploadedFiles());
  }, [JSON.stringify(files)]); // eslint-disable-line

  return (
    <div className={classnames(["app-streaming-files-dock-wrapper", isFilesDockOpen && "open"])}>
      <div className="app-streaming-files-dock">
        {(files.length > 0 || isFilesDockOpen) && (
          <div
            className={classnames(["files-dock-button", isFilesDockOpen && "open"])}
            onClick={() => {
              setIsFilesDockOpen(!isFilesDockOpen);
            }}
            role="button"
            tabIndex="0"
          >
            <IconButton className={isFilesDockOpen ? "invisible" : "visible"} name="file-copy" small preventKeyEvent />
            <IconButton className={isFilesDockOpen ? "visible" : "invisible"} name="chevron-right" preventKeyEvent />
          </div>
        )}
        <div className="header">
          <p>Files</p>
          <TextButton
            text={translate("dock.files.openFileManagerText")}
            color={showDragNotification ? "purple" : ""}
            onClick={() => {
              openVagonExplorer();
            }}
          />
        </div>
        <BoxInput
          className="search-input"
          placeholder="Search"
          iconName="search"
          color="purple-light"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          onFocus={() => {
            setDisableKeyboardActions(true);
          }}
          onBlur={() => {
            setDisableKeyboardActions(false);
          }}
        />
        <div className="files-container">
          {uploadingFiles?.map((file) => (
            <div className="file" key={file.fileName}>
              <Icon name="file-img-white" />
              <p>{file.fileName}</p>
              <div className="actions">
                {file.status === -1 ? (
                  <>
                    <HoverableTooltip content={translate(`filesDock.uploadErrors.${file.error}`)}>
                      <Icon name="error" color="red" />
                    </HoverableTooltip>
                    <IconButton name="close" onClick={() => dispatch(clearUploadedFile(file.fileName))} />
                  </>
                ) : (
                  <>
                    <span className="baby-blue">%{Math.round(file.progress)}</span>
                    <ProgressBar progress={file.progress} />
                    <HoverableTooltip content="Cancel">
                      <IconButton name="close" onClick={() => dispatch(cancelFileUpload(file.fileName))} />
                    </HoverableTooltip>
                  </>
                )}
              </div>
            </div>
          ))}
          {filteredFiles?.map((fileName) => {
            return (
              <div
                className="file"
                key={fileName}
                onDragStart={(e) => {
                  e.preventDefault();
                  toast(
                    <ToastNotificationContent
                      description={translate("filesDock.dragNotification")}
                      className="drag-toast-notification"
                    />,
                    { autoClose: false },
                  );
                  setShowDragNotification(true);
                  dragNotificationTimeoutRef.current = setTimeout(() => {
                    setShowDragNotification(false);
                    dragNotificationTimeoutRef.current = null;
                    toast.dismiss();
                  }, 5000);
                }}
                draggable
              >
                <Icon name="file-img-white" />
                <p>{fileName}</p>
                <div className="actions">
                  <HoverableTooltip content="Download">
                    <IconButton
                      name="file-download"
                      onClick={() => {
                        sendDownloadTempFileEvent(fileName);
                      }}
                    />
                  </HoverableTooltip>
                </div>
              </div>
            );
          })}

          {files.length === 0 && uploadingFiles?.length === 0 && (
            <div className="empty-folder">
              <img src={VagonNoFilesImage} alt="Nothing to show" />
              <h1>{translate("filesDock.folderEmpty.header")}</h1>
              <p className="empty-folder-info">{translate("filesDock.folderEmpty.description")}</p>
            </div>
          )}

          {files.length !== 0 && filteredFiles.length === 0 && query && (
            <div className="empty-folder">
              <img src={VagonNoFilesImage} alt="Nothing to show" />
              <h1>{translate("filesDock.searchEmpty.header")}</h1>
              <p className="empty-folder-info">{translate("filesDock.searchEmpty.description")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilesDock;
