import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";

import "./SecurePaymentModal.styles.scss";

const StripePaymentFrame = (props) => {
  const { onFailure, onSuccess, clientSecret, onComplete } = props;

  const stripe = useStripe();
  const elements = useElements();

  const onAuthenticationComplete = (e) => {
    if (e.data === "3DS-authentication-complete") {
      e.preventDefault();
      on3DSComplete();
    }
  };

  useEffect(() => {
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("message", onAuthenticationComplete);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (stripe && elements) {
      // Add listener for iframe
      window.addEventListener("message", onAuthenticationComplete);
      // Start 3D payment process
      processPayment();
    }
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("message", onAuthenticationComplete);
    };
  }, [elements, stripe]); // eslint-disable-line

  const on3DSComplete = () => {
    // Hide the 3DS UI
    if (stripe) {
      // Remove iframe
      const frame = document.getElementById("payment-frame");

      // Check the PaymentIntent
      stripe.retrievePaymentIntent(clientSecret).then((result) => {
        let errorStatus = false;
        if (result.error) {
          // PaymentIntent client secret was invalid
          errorStatus = true;
        } else if (result.paymentIntent.status === "succeeded") {
          // Succeeded
        } else if (result.paymentIntent.status === "requires_payment_method") {
          // Authentication failed
          errorStatus = true;
        }

        onComplete(result.paymentIntent.id);

        if (frame) {
          frame.remove();
        }
        if (errorStatus) {
          if (onFailure) {
            onFailure(result.paymentIntent.last_payment_error.message);
          }
        } else if (!errorStatus) {
          if (onSuccess) {
            onSuccess();
          }
        }
      });
    }
  };

  const processPayment = () => {
    if (stripe) {
      const returnURL = `${window.location.origin}/payment-processing/`;
      stripe.confirmCardPayment(clientSecret, { return_url: returnURL }, { handleActions: false }).then((payload) => {
        if (payload.error) {
          // payload.error "payment_intent_authentication_failure"
          on3DSComplete();
        } else {
          const { paymentIntent } = payload;

          if (paymentIntent.status === "requires_action") {
            const iframeElement = document.createElement("iframe");
            iframeElement.src = paymentIntent.next_action && paymentIntent.next_action.redirect_to_url.url;
            iframeElement.width = 500;
            iframeElement.height = 600;
            const paymentFrame = document.getElementById("payment-frame");
            if (paymentFrame) {
              paymentFrame.appendChild(iframeElement);
            }
          } else if (paymentIntent.status === "succeeded") {
            on3DSComplete();
          }
        }
      });
    }
  };
  return <div id="payment-frame" />;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const SecurePaymentModal = (props) => (
  <div className="payment-3d-secure">
    <Elements stripe={stripePromise}>
      <StripePaymentFrame {...props} />
    </Elements>
  </div>
);

export default SecurePaymentModal;
