import { FILE_UPLOAD_STATUS } from "Constants/global.constants";
import apiGenerator from "Utils/Helpers/api.helpers";
import { MB_TO_BYTE } from "Utils/Helpers/functions.helpers";
import axios from "axios";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

const FILE_CHUNK_SIZE_MB = 250;

const useFileUpload = ({ onFileUploadSuccess, afterCancelUpload }) => {
  const [uploadingFiles, setUploadingFiles] = useState({});
  const sourceRef = useRef(null);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      const modifiedEvent = { ...event, returnValue: "You have unfinished uploads. Are you sure you want to leave?" };
      return modifiedEvent;
    };

    if (Object.keys(uploadingFiles).length > 0) {
      const files = Object.keys(uploadingFiles);

      const uploadingFile = files.find((file) => uploadingFiles[file].status === FILE_UPLOAD_STATUS.UPLOADING);

      const pendingFile = files.find((file) => uploadingFiles[file].status === FILE_UPLOAD_STATUS.PENDING);

      if (uploadingFile) {
        window.addEventListener("beforeunload", handleBeforeUnload);
      }

      if (!uploadingFile && pendingFile) {
        const { file, createEndpoint, completeEndpoint } = uploadingFiles[pendingFile];
        startUpload(file, createEndpoint, completeEndpoint);
      }
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploadingFiles]);

  useEffect(() => {
    // Cleanup
    return () => {
      if (sourceRef.current) {
        sourceRef.current.cancel("Request canceled");
      }

      setUploadingFiles({});
    };
  }, []);

  const reset = () => {
    setUploadingFiles({});
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const uploadFile = (file, createEndpoint, completeEndpoint) => {
    setUploadingFiles((prev) => ({
      ...prev,
      [file.name]: {
        file,
        progress: 0,
        status: "pending",
        createEndpoint,
        completeEndpoint,
      },
    }));
  };

  const getUploadURL = async (file, createEndpoint) => {
    const createFileResp = await apiGenerator("post")(createEndpoint, {
      name: file.name,
      size: file.size,
    }).catch((e) => {
      setFileStatus(file, FILE_UPLOAD_STATUS.FAILURE, e.response.data.client_code);
      throw new Error("Failed to create file");
    });

    if (createFileResp.status !== 200) {
      setFileStatus(file, FILE_UPLOAD_STATUS.FAILURE, createFileResp.data.client_code);
      throw new Error("Failed to create file");
    }
    return createFileResp.data;
  };

  const setFileStatus = (file, status, errorCode) => {
    setUploadingFiles((prev) => ({
      ...prev,
      [file.name]: {
        ...prev[file.name],
        status,
        errorCode,
      },
    }));
  };

  const startUpload = async (file, createEndpoint, completeEndpoint) => {
    setFileStatus(file, FILE_UPLOAD_STATUS.UPLOADING);
    sourceRef.current = axios.CancelToken.source();

    const contentType = file.type || "application/octet-stream";
    const resp = await getUploadURL(file, createEndpoint);

    const { id, file_id: fileId, upload_urls: uploadUrls } = resp || {};
    let { chunk_size: chunkSize } = resp || {};
    chunkSize = chunkSize || FILE_CHUNK_SIZE_MB;
    const uploadId = id || fileId;

    const totalPartNumber = uploadUrls.length;
    const expectedPartNumber = Math.ceil(file.size / (MB_TO_BYTE * chunkSize));

    if (chunkSize && totalPartNumber !== expectedPartNumber) {
      throw new Error("Request canceled");
    }

    const uploaded = [0];
    for (let i = 1; i < totalPartNumber; i += 1) {
      uploaded[i] = 0;
    }

    const promises = _.map(uploadUrls, (uploadUrl, idx) => {
      const filePartStart = idx * (MB_TO_BYTE * chunkSize);
      const filePartEnd = (idx + 1) * (MB_TO_BYTE * chunkSize);
      const fileBlob = idx < uploadUrls.length ? file.slice(filePartStart, filePartEnd) : file.slice(filePartStart);

      if (uploadUrl.includes("&uploadId=t&")) {
        throw new Error("Wrong upload url");
      }

      const api = apiGenerator("put")(uploadUrl, fileBlob, {
        headers: {
          "content-type": contentType,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          uploaded[idx] = percentCompleted;

          setUploadingFiles((prev) => ({
            ...prev,
            [file.name]: {
              ...prev[file.name],
              progress: _.mean(uploaded),
            },
          }));
        },
        cancelToken: sourceRef.current.token,
      }).catch((e) => {
        if (axios.isCancel(e)) {
          setUploadingFiles((prev) => {
            const updatedFiles = { ...prev };
            delete updatedFiles[file.name];
            return updatedFiles;
          });
        } else {
          setFileStatus(file, FILE_UPLOAD_STATUS.FAILURE, "default");
        }

        afterCancelUpload(uploadId);
        throw new Error("Request canceled");
      });

      return api;
    });

    const awsS3Responses = await Promise.all(promises);

    const completedParts = _.map(awsS3Responses, (part, index) => ({
      etag: part.headers.etag,
      part_number: index + 1,
    }));

    await apiGenerator("post")(completeEndpoint(uploadId), {
      parts: completedParts,
    });

    setFileStatus(file, FILE_UPLOAD_STATUS.SUCCESS);
    onFileUploadSuccess(file.name);
  };

  const uploadingFileByName = (fileName) => {
    return uploadingFiles[fileName];
  };

  const uploadingFileByStatus = (status) => {
    return uploadingFiles.filter((file) => file.status === status);
  };

  const cancelUpload = (fileName) => {
    const fileStatus = uploadingFiles[fileName].status;
    if (fileStatus === FILE_UPLOAD_STATUS.UPLOADING && sourceRef.current) {
      sourceRef.current.cancel("Request canceled");
    } else {
      setUploadingFiles((prev) => {
        const updatedFiles = { ...prev };
        delete updatedFiles[fileName];
        return updatedFiles;
      });
    }
  };

  return { uploadFile, uploadingFiles, uploadingFileByName, uploadingFileByStatus, cancelUpload };
};

export default useFileUpload;
