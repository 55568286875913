/* eslint-disable  react/jsx-props-no-spreading */
import React from "react";
import { Tracker, classnames } from "Utils";
import "./DivButton.styles.scss";

const DivButton = ({
  onClick,
  eventType,
  className: classNameFromProps,
  children,
  shadow,
  divRef,
  nonFocusable,
  disabled,
  ...rest
}) => {
  const handleButtonClick = (event) => {
    if (onClick) onClick(event);
  };

  const handleEnterKeyUp = (event) => {
    if (nonFocusable) {
      return;
    }
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      handleButtonClick(event);
    }
  };

  return (
    <div
      className={classnames([
        "div-button-container",
        classNameFromProps,
        shadow && "shadow",
        disabled && "disabled",
        onClick && "clickable",
      ])}
      onClick={handleButtonClick}
      onKeyUp={handleEnterKeyUp}
      role="button"
      tabIndex={0}
      {...rest}
      ref={divRef}
    >
      {children}
    </div>
  );
};

export default DivButton;
