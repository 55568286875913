import { all, put, takeLatest } from "redux-saga/effects";

import apiGenerator from "Utils/Helpers/api.helpers";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";

import * as RegionsConstants from "Constants/Regions.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";

function* getRegionsAPISaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.REGIONS);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: RegionsConstants.GET_REGIONS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: RegionsConstants.GET_REGIONS_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getLocationAPISaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.IP_LOCATION);
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: RegionsConstants.GET_LOCATION_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: RegionsConstants.GET_LOCATION_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(RegionsConstants.GET_REGIONS_PENDING, getRegionsAPISaga),
    takeLatest(RegionsConstants.GET_LOCATION_PENDING, getLocationAPISaga),
  ]);
}
