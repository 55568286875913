import React from "react";
import { COUNTRY_LIST_ALPHA } from "Constants/countries.constants";

import "./AddBillingAddressForm.styles.scss";

import { Input, Dropdown } from "UI";

const COUNTRY_LIST = Object.keys(COUNTRY_LIST_ALPHA);

const AddBillingAddressForm = (props) => {
  const { errors, billingDetails, setBillingDetails, setIsBillingAddressFormComplete, translate } = props;

  React.useEffect(() => {
    if (setIsBillingAddressFormComplete) {
      setIsBillingAddressFormComplete(
        billingDetails.companyName &&
          billingDetails.taxId &&
          billingDetails.country &&
          billingDetails.addressLine1 &&
          billingDetails.addressLine2 &&
          billingDetails.city &&
          billingDetails.postalCode,
      );
    }
  }, [billingDetails]);

  return (
    <form noValidate>
      <div className="billing-address-form">
        <Input
          label={translate("addBillingAddressForm.companyName")}
          placeholder={translate("addBillingAddressForm.companyName")}
          maxLength="30"
          value={billingDetails.companyName}
          onChange={(event) => {
            setBillingDetails({
              ...billingDetails,
              companyName: event.target.value,
            });
          }}
          hasError={errors.nameError}
          autoComplete="name"
        />
        <div className="tax-country-input-line">
          <Input
            placeholder={translate("addBillingAddressForm.taxId")}
            label={translate("addBillingAddressForm.taxId")}
            maxLength="30"
            value={billingDetails.taxId}
            onChange={(event) => {
              setBillingDetails({
                ...billingDetails,
                taxId: event.target.value,
              });
            }}
            hasError={errors.taxIdError}
          />
          <Dropdown
            editable
            resetOnOpen
            placeholder={translate("addBillingAddressForm.country")}
            label={translate("addBillingAddressForm.country")}
            maxLength="30"
            options={COUNTRY_LIST}
            value={billingDetails.country}
            defaultSelected={billingDetails.country}
            handleSelectedOptionChange={(value) => {
              setBillingDetails({
                ...billingDetails,
                country: value,
              });
            }}
            onChange={(event) => {
              setBillingDetails({
                ...billingDetails,
                country: event.target.value,
              });
            }}
            hasError={errors.countryError}
          />
        </div>
        <div className="address-input-line">
          <Input
            autoComplete="address-line1"
            placeholder={translate("addBillingAddressForm.addressLine")}
            label={translate("addBillingAddressForm.addressLine") + 1}
            maxLength="70"
            value={billingDetails.addressLine1}
            onChange={(event) => {
              setBillingDetails({
                ...billingDetails,
                addressLine1: event.target.value,
              });
            }}
            hasError={errors.addressLineError}
          />
          <Input
            autoComplete="address-line1"
            placeholder={translate("addBillingAddressForm.addressLine")}
            label={translate("addBillingAddressForm.addressLine") + 2}
            maxLength="70"
            value={billingDetails.addressLine2}
            onChange={(event) => {
              setBillingDetails({
                ...billingDetails,
                addressLine2: event.target.value,
              });
            }}
            hasError={errors.addressLineError}
          />
          <div className="city-input-line">
            <Input
              placeholder={translate("addPaymentForm.city")}
              label={translate("addPaymentForm.city")}
              maxLength="30"
              value={billingDetails.city}
              onChange={(event) => {
                setBillingDetails({
                  ...billingDetails,
                  city: event.target.value,
                });
              }}
              hasError={errors.cityError}
            />
            <Input
              placeholder={translate("addBillingAddressForm.postalCode")}
              label={translate("addBillingAddressForm.postalCode")}
              maxLength="30"
              value={billingDetails.postalCode}
              onChange={(event) => {
                setBillingDetails({
                  ...billingDetails,
                  postalCode: event.target.value,
                });
              }}
              hasError={errors.postalCodeError}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddBillingAddressForm;
