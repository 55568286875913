import React from "react";
import "./PaymentDetails.style.scss";

const PaymentDetails = (props) => {
  const { items, headerText, subHeaderText, totalAmountText, totalAmount, hide } = props;

  const filteredItems = (!hide && items?.filter((item) => !item.hide)) || [];
  return (
    !hide && (
      <div className="payment-details">
        <table>
          {headerText && (
            <thead>
              <tr>
                <th>{headerText}</th>
              </tr>
            </thead>
          )}
          {subHeaderText && (
            <thead className="sub-header">
              <tr>
                <th>{subHeaderText}</th>
              </tr>
            </thead>
          )}
          <tbody>
            {filteredItems.map((item) => {
              return (
                <tr key={item.key} className={item.discounted && "discounted"}>
                  <td>{item.name}</td>
                  {item.amount ? <td>$ {item.amount}</td> : <td> </td>}
                </tr>
              );
            })}
            {filteredItems.length > 0 && totalAmount && (
              <tr key="total" className="total-amount">
                {totalAmountText ? <td>{totalAmountText}</td> : <td> </td>}
                <td>{totalAmount}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  );
};

export default PaymentDetails;
