import {
  FORGET_PASSWORD_API_PENDING,
  FORGET_PASSWORD_RESET_API_PENDING,
  FORGET_PASSWORD_CLEAR,
  FORGET_PASSWORD_RESET_CLEAR,
} from "Constants/Authentication/ForgetPassword.constants";

export const forgetPasswordClear = () => ({
  type: FORGET_PASSWORD_CLEAR,
});

export const forgetPasswordResetClear = () => ({
  type: FORGET_PASSWORD_RESET_CLEAR,
});

export const forgetPasswordAPI = (forgetPasswordForm) => {
  const payload = {
    email: forgetPasswordForm.email.toLowerCase(),
    recaptcha_response: forgetPasswordForm.recaptchaResponse,
  };
  return {
    type: FORGET_PASSWORD_API_PENDING,
    payload,
  };
};

export const forgetPasswordResetAPI = (forgetPasswordResetForm, code) => {
  const payload = {
    password: forgetPasswordResetForm.password,
    password_confirmation: forgetPasswordResetForm.password,
  };
  return {
    type: FORGET_PASSWORD_RESET_API_PENDING,
    payload: { payload, code },
  };
};
