import React from "react";

import { logoutAPI } from "Actions/Authentication/Login.actions";
import { connect, useSelector } from "react-redux";
import { Icon } from "UI";
import DropdownMenu from "UI/DropdownMenu/DropdownMenu.component";
import routes from "Constants/Route.constants";
import { useAppScope } from "Utils/Hooks/useAppScope";
import { APP_SCOPES } from "Constants/global.constants";
import { isMobile } from "Utils/Helpers/browser.helpers";
import MenuWidescreen from "./Menu.widescreen.component";
import MenuMobile from "./Menu.mobile.component";
import "./Menu.styles.scss";

const Menu = (props) => {
  const { appScope, setAppScope } = useAppScope();

  const { history, path } = props;

  const { account } = useSelector((state) => state.account);

  const {
    organization_admin: organizationAdmin,
    organization_member: organizationMember,
    organization_machine: organizationMachine,
  } = account?.attributes || {};

  const appsMenuItems = [
    {
      label: organizationMember ? "Team Computer" : "Computer",
      onClick: () => {
        if (organizationMember || organizationMachine) {
          setAppScope(APP_SCOPES.team_member);
        } else {
          setAppScope(APP_SCOPES.user);
        }

        if (path === routes.organizationMemberTeamInfo) {
          history.push(routes.organizationMemberHome);
        }
      },
      rightSlot:
        appScope === APP_SCOPES.user ||
        (appScope === APP_SCOPES.team_member && path !== routes.organizationMemberTeamInfo) ? (
          <Icon name="apps-check" color="green" smaller />
        ) : (
          ""
        ),
    },
    {
      label: organizationMember ? "Team" : "Team Console",
      onClick: () => {
        if (organizationAdmin) {
          setAppScope(APP_SCOPES.team_admin);
        } else if (organizationMember) {
          history.push(routes.organizationMemberTeamInfo);
          setAppScope(APP_SCOPES.team_member);
        } else {
          setAppScope(APP_SCOPES.team_admin);
        }
      },
      rightSlot:
        appScope === APP_SCOPES.team_admin || path === routes.organizationMemberTeamInfo ? (
          <Icon name="apps-check" color="green" smaller />
        ) : (
          ""
        ),
    },
    {
      label: "Streams - App Streaming",
      onClick: () => {
        setAppScope(APP_SCOPES.vendor);
      },
      rightSlot: appScope === APP_SCOPES.vendor ? <Icon name="apps-check" color="green" smaller /> : "",
    },
  ];

  const appsMenu = (props) => {
    const { purpleBackground = false, appStreaming = false } = props || {};
    return (
      <DropdownMenu
        menuItems={appsMenuItems}
        side={isMobile ? "top" : "right"}
        sideOffset={-20}
        align="end"
        alignOffset={4}
      >
        <div
          className={`menu-item ${purpleBackground ? "dark-navy" : ""} ${
            appStreaming ? "app-streaming-menu-item" : ""
          }`}
        >
          <p>Apps</p>
          <Icon name="chevron-right" color="gray-3" smaller />
        </div>
      </DropdownMenu>
    );
  };

  return (
    <>
      <MenuWidescreen {...props} appsMenu={appsMenu} />
      <MenuMobile {...props} appsMenu={appsMenu} />
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const actionCreators = {
  logoutAPI,
};

export default connect(mapStateToProps, actionCreators)(Menu);
