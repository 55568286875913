import React from "react";
import { ExpandableTable } from "UI";
import NoTransactionsImage from "Assets/images/appStreaming/app-streaming_first review 1.png";

import "./SummaryTable.styles.scss";

const SummaryTable = ({ data, translate }) => {
  const summaryColumns = [
    { name: "Id", weight: 0, hide: true },
    { name: translate("appStreamingBilling.summary.columns.transaction"), weight: 60 },
    { name: translate("appStreamingBilling.summary.columns.amount"), weight: 40 },
  ];

  const summaryTransactions = data?.data?.transactions?.map((transaction, index) => {
    const { transaction_type: type, amount } = transaction;
    return {
      id: index,
      transaction: translate(`appStreamingBilling.chargeDetails.transactionTypes.${type}`),
      amount: `${amount >= 0 ? "+" : "-"} $ ${Math.abs(parseFloat(amount)).toFixed(2)}`,
    };
  });

  if (summaryTransactions?.length === 0)
    return (
      <div className="no-transactions-content">
        <img src={NoTransactionsImage} />
        <span>{translate("appStreamingBilling.noTransaction")}</span>
      </div>
    );

  return (
    <div className="summary-table-container">
      <ExpandableTable columns={summaryColumns} data={summaryTransactions} hideVerticalBar />
    </div>
  );
};

export default SummaryTable;
