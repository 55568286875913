import React from "react";
import { useDispatch } from "react-redux";
import { getOrganizationAPI } from "Actions/Organization.actions";
import info1 from "Assets/images/teams/info-1.png";
import { API_ENDPOINTS } from "Constants/api.constants";
import { DashboardSubScreenLayout, useAPI, useAPIRequest } from "Utils";
import { Button, Input } from "UI";
import { APP_SCOPES } from "Constants/global.constants";
import routes from "Routes/routes";
import { useAppScope } from "Utils/Hooks/useAppScope";
import { getAccountAPI } from "Actions/Account.actions";
import "./Landing.styles.scss";

const Landing = ({ history, translate }) => {
  const [teamName, setTeamName] = React.useState("");
  const dispatch = useDispatch();
  const { setAppScope } = useAppScope();

  const { api: createOrganizationAPI, isLoading: createOrganizationLoading } = useAPI({
    type: "post",
    endpoint: API_ENDPOINTS.ORGANIZATIONS,
    onSuccess: () => {
      dispatch(getOrganizationAPI());
      dispatch(getAccountAPI());
      history.push(routes.organizationAdminHome);
    },
  });

  const { data: invitationData, isLoading: invitationLoading } = useAPIRequest({
    endpoint: API_ENDPOINTS.ORGANIZATION_INVITATION_MINE,
  });

  const { api: acceptInviteAPI } = useAPI({
    type: "put",
    endpoint: API_ENDPOINTS.ACCEPT_ORGANIZATION_INVITATION,
    onSuccess: () => {
      dispatch(getAccountAPI());
    },
  });

  const { api: rejectInviteAPI } = useAPI({
    type: "put",
    endpoint: API_ENDPOINTS.REJECT_ORGANIZATION_INVITATION,
    onSuccess: () => {
      dispatch(getAccountAPI());
      setAppScope(APP_SCOPES.user);
    },
  });

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("teams.title")}
      headerDescription={translate("teams.description")}
      translate={translate}
      waitFor={[!invitationLoading]}
      noScroll
    >
      <div className="teams-landing-container">
        {invitationData ? (
          <div className="team-invite-container">
            <img src={info1} />
            <div className="invite-info-container">
              <p>{translate("teams.invitedInfo.invitedTo")}</p>
              <h2>{invitationData?.attributes?.organization_name}</h2>
              <p>
                Enjoy the collaboration experience with your teammates on Vagon. Once you accept the invite, you will be
                a member of {invitationData?.attributes?.organization_name}.
              </p>
            </div>
            <div className="button-container">
              <Button
                text="Decline"
                outline
                transparent
                onClick={() => {
                  rejectInviteAPI();
                }}
              />
              <Button
                text="Accept"
                onClick={() => {
                  acceptInviteAPI();
                }}
              />
            </div>
          </div>
        ) : (
          <div className="landing-content">
            <img src={info1} />
            <p className="landing-info">
              Work with multiple Vagon computers, invite team members, and manage team usages from a single dashboard.
            </p>
            <div className="team-name-input">
              <Input
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                label="Team"
                placeholder="Creative Studio Co."
                maxLength={60}
              />
            </div>

            <Button
              text="Create a Team"
              big
              onClick={() => {
                createOrganizationAPI({
                  organization_name: teamName,
                });
              }}
              disabled={teamName.length < 1 || createOrganizationLoading}
            />
          </div>
        )}
      </div>
    </DashboardSubScreenLayout>
  );
};

export default Landing;
