import React, { useMemo } from "react";
import ErrorIcon from "Assets/icons/appStreaming/stream-error.svg";
import DarkErrorIcon from "Assets/icons/appStreaming/stream-dark-error.svg";
import StreamsLogo from "Assets/images/appStreaming/streams-logo.png";
import DarkStreamsLogo from "Assets/images/appStreaming/streams-logo-dark.png";
import WhiteStaticBackground from "Assets/images/appStreaming/white-static-bg.jpg";
import { apiGenerator, classnames, isFirefox, isMobile } from "Utils";
import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ERROR_TEXT_MAPPING } from "Constants/AppStreaming.constants";

import "./AppStreamingError.styles.scss";

const AppStreamingError = () => {
  const location = useLocation();
  const streamID = location?.streamID;
  const error = location?.error;

  const { data: streamAssetsData } = useQuery({
    queryKey: [API_ENDPOINTS.APP_STREAMING_SESSION_ASSETS],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.APP_STREAMING_SESSION_ASSETS, {
        params: {
          stream_id: streamID,
        },
      }).then((res) => res.data);
    },
  });

  const { dark_mode: darkMode, logo_url: logoUrl, banner_url: bannerUrl, texts } = streamAssetsData?.attributes || {};

  const backgroundStyle = useMemo(() => {
    const encodedBannerURL = encodeURI(bannerUrl);

    // Firefox doesn't support webp images
    if (isFirefox && !bannerUrl && !darkMode) {
      return {
        backgroundImage: `url("${WhiteStaticBackground}")`,
      };
    }

    if (bannerUrl) return { backgroundImage: `url("${encodedBannerURL}")` };

    return {};
  }, [bannerUrl, darkMode]);

  const logo = useMemo(() => {
    if (logoUrl) return logoUrl;
    if (darkMode) return DarkStreamsLogo;
    return StreamsLogo;
  }, [logoUrl, darkMode]);

  const errorText = useMemo(() => {
    const textKey = ERROR_TEXT_MAPPING[error];
    return texts?.[textKey] || "Stream Not Found";
  }, [error, JSON.stringify(texts)]);

  const errorIcon = useMemo(() => {
    if (darkMode) return DarkErrorIcon;
    return ErrorIcon;
  }, [darkMode]);

  return (
    <div className={classnames(["app-streaming-error-container", darkMode && "dark"])} style={backgroundStyle}>
      <div className="app-logo-container">
        <img src={logo} className="logo-img" alt="nope" />
      </div>
      <div className="content-box">
        <img className="error-img" src={isMobile ? logo : errorIcon} alt="nope" />
        <p>{errorText}</p>
      </div>
    </div>
  );
};

export default AppStreamingError;
