import React, { useEffect } from "react";
import { ROOT_PATHS } from "Constants/global.constants";
import routes from "Constants/Route.constants";
import ProtectedRouteWithTranslation from "Utils/Components/Route/Helpers/ProtectedRouteWithTranslation";
import { useAppScope } from "Utils/Hooks/useAppScope";

const AppStreamingRoute = (props) => {
  const { path, account, history } = props;
  const { appScope } = useAppScope();
  const { vendor_admin: vendorAdmin, vendor_member: vendorMember } = account?.account?.attributes || {};

  useEffect(() => {
    if (appScope !== "vendor") {
      history.push(ROOT_PATHS[appScope]);
      return;
    }

    if (!vendorAdmin && !vendorMember) {
      history.push(routes.appStreamingLanding);
    }
  }, [path, account.accessToken, account.getAccountCTX.status, appScope, vendorAdmin, vendorMember]);

  return <ProtectedRouteWithTranslation {...props} />;
};

export default AppStreamingRoute;
