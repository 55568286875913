import React, { useState } from "react";
import { Icon, Modal, Input } from "UI";

import "./SoftwareCard.styles.scss";

const SoftwareRequestCard = (props) => {
  const { translate } = props;

  const [softwareName, setSoftwareName] = useState("");
  const [requested, setRequested] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const onClick = () => {
    setSoftwareName("");
    setRequested(false);
    setShowRequestModal(true);
  };

  const handleEnterKeyUp = (event, callback) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      callback();
      if (onClick) {
        onSoftwareRequest();
      }
    }
  };

  const onSoftwareRequest = () => {
    if (window.Intercom) {
      window.Intercom("trackEvent", "request-software", {
        app_id: process.env.REACT_APP_INTERCOM_API_KEY,
        software: softwareName,
      });
    }
    setRequested(true);
  };

  return (
    <div className="software-card-container">
      {showRequestModal && (
        <Modal
          closeOnOverlayClick
          closeAction={() => setShowRequestModal(false)}
          topRightIcon="close"
          topRightIconAction={() => setShowRequestModal(false)}
          headerText={translate("selectSoftware.content.requestSoftwareModal.header")}
          buttonText={translate("selectSoftware.content.requestSoftwareModal.buttonText")}
          buttonAction={onSoftwareRequest}
          success={requested}
          successText={translate("selectSoftware.content.requestSoftwareModal.successText")}
          small
        >
          <div className="software-request-modal-content">
            <Input
              label={translate("selectSoftware.content.requestSoftwareModal.description")}
              placeholder={translate("selectSoftware.content.requestSoftwareModal.placeholder")}
              maxLength="50"
              value={softwareName}
              onChange={(e) => {
                setSoftwareName(e.target.value);
              }}
              onKeyUp={(event) => handleEnterKeyUp(event, onSoftwareRequest)}
              tabIndex={0}
            />
          </div>
        </Modal>
      )}
      <div
        className={["software-card"].filter(Boolean).join(" ")}
        onClick={onClick}
        tabIndex={0}
        role="button"
        onKeyUp={(event) => handleEnterKeyUp(event, onClick)}
      >
        <div className="software">
          <Icon name="plus" color="gray-5-stroke" />
          <p>{translate("selectSoftware.content.requestSoftware")}</p>
        </div>
      </div>
    </div>
  );
};

export default SoftwareRequestCard;
