import React from "react";
import { Button } from "UI";
import InitialContentImage from "Assets/images/organizations/no-content.png";

import "./EmptyContent.styles.scss";

const EmptyContent = ({
  image,
  headerText,
  descriptionText,
  buttonText,
  onButtonClick,
  buttonColor,
  overrideButton,
  withoutButton,
  buttonIconLeft,
  children,
}) => {
  return (
    <div className="organization-empty-content-container">
      <img src={image || InitialContentImage} />
      <div>
        <h1>{headerText}</h1>
        {descriptionText && <div className="empty-content-description">{descriptionText}</div>}
      </div>
      {children}

      {!withoutButton &&
        (overrideButton || (
          <Button text={buttonText} onClick={onButtonClick} color={buttonColor} iconLeft={buttonIconLeft} />
        ))}
    </div>
  );
};

export default EmptyContent;
