import React from "react";
import { Steps } from "intro.js-react";
import GeneralSettingsImage from "Assets/images/onboardingModals/general-settings.jpg";
import DisplaySettingsImage from "Assets/images/onboardingModals/display-settings.jpg";
import FilesImage from "Assets/images/onboardingModals/dock-files.jpg";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const DockOnboardingModal = ({ translate, onExit, dockTabs, setCurrentTab }) => {
  const steps = [
    {
      element: ".selected",
      title: `<img src=${GeneralSettingsImage} />`,
      intro: `<h1>${translate("modals.onboardingModals.dockOnboardingModal.generalMenuIcon.header")}</h1><p>${translate(
        "modals.onboardingModals.dockOnboardingModal.generalMenuIcon.description",
      )}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".display",
      title: `<img src=${DisplaySettingsImage} />`,
      intro: `<h1>${translate("modals.onboardingModals.dockOnboardingModal.displayMenuIcon.header")}</h1><p>${translate(
        "modals.onboardingModals.dockOnboardingModal.displayMenuIcon.description",
      )}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".file-drag",
      title: `<img src=${FilesImage} />`,
      intro: `<h1>${translate("modals.onboardingModals.dockOnboardingModal.filesMenuIcon.header")}</h1><p>${translate(
        "modals.onboardingModals.dockOnboardingModal.filesMenuIcon.description",
      )}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
  ];
  return (
    <Steps
      enabled
      options={{ nextToDone: true }}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      onChange={(step) => {
        if (step === 1) setCurrentTab(dockTabs.DISPLAY);
        if (step === 2) setCurrentTab(dockTabs.FILES);
      }}
    />
  );
};

export default DockOnboardingModal;
