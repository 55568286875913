import React, { useState, useEffect, useRef } from "react";
import routes from "Constants/Route.constants";
import { Button, Input, Icon, TextButton } from "UI";
import { Loader, apiGenerator, useAPI } from "Utils";
import { API_ENDPOINTS } from "Constants/api.constants";
import { setCreateComputersCTX } from "Actions/Organization.actions";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import newComputerImage from "Assets/images/new-computer-2.png";
import info1 from "Assets/images/teams/info-1.png";
import { APP_SCOPES } from "Constants/global.constants";
import { getAccountAPI } from "Actions/Account.actions";
import { useAppScope } from "Utils/Hooks/useAppScope";
import queryClient from "Utils/Helpers/queryClient";
import "./NewComputer.styles.scss";

const INPUT_WIDTH_THRESHOLD = 2;

const NewComputer = (props) => {
  const [loading, setLoading] = useState(true);
  const { account, workstations, updateCreateWorkstationConfig, translate, history } = props;
  const { first_name: userName, organization_admin: organizationAdmin } = account.account.attributes;
  const { setAppScope } = useAppScope();

  const { workstation_name: workstationName } = workstations.createWorkstationConfigCTX;
  const inputRef = useRef();
  const dispatch = useDispatch();

  const [computerName, setComputerName] = useState(
    workstationName || (userName ? `${userName}'s Vagon` : "Your Vagon"),
  );

  const { data: invitationData, refetch } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_INVITATION_MINE],
    queryFn: async () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_INVITATION_MINE).then((response) => {
        return response.data;
      });
    },
    retry: false,
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries([API_ENDPOINTS.ORGANIZATION_INVITATION_MINE]);
    };
  }, []);

  const { api: acceptInviteAPI } = useAPI({
    type: "put",
    endpoint: API_ENDPOINTS.ACCEPT_ORGANIZATION_INVITATION,
    onSuccess: () => {
      setAppScope(APP_SCOPES.team_member);
      dispatch(getAccountAPI());
      queryClient.removeQueries([API_ENDPOINTS.ORGANIZATION_INVITATION_MINE]);
    },
  });

  const { api: rejectInviteAPI } = useAPI({
    type: "put",
    endpoint: API_ENDPOINTS.REJECT_ORGANIZATION_INVITATION,
    onSuccess: () => {
      queryClient.removeQueries([API_ENDPOINTS.ORGANIZATION_INVITATION_MINE]);
      if (organizationAdmin) {
        history.push(routes.organizationAdminHome);
      }
    },
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const onCreateComputerButtonClick = () => {
    updateCreateWorkstationConfig({ workstation_name: computerName });

    history.push(routes.createComputerPurpose);
  };

  const onCreateTeamComputerButtonClick = () => {
    setAppScope(APP_SCOPES.team_admin);
    dispatch(setCreateComputersCTX({ selectedUserIds: [account.account.id] }));
    history.push(routes.organizationAdminCreateComputersQuickStart);
  };

  const calculateComputerNameInputWidth = () => {
    return `${INPUT_WIDTH_THRESHOLD + computerName.length}rem`;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="new-computer-wrapper">
      <div className="image-container">
        <div className="image-filter" />
        <img alt="Create a New Vagon Computer" className="center-image" src={newComputerImage} />
      </div>
      {invitationData ? (
        <div className="team-invite-container">
          <img src={invitationData ? info1 : newComputerImage} />
          <div className="invite-info-container">
            <p>{translate("teams.invitedInfo.invitedTo")}</p>
            <h2>{invitationData?.attributes?.organization_name}</h2>
            <p>
              Enjoy the collaboration experience with your teammates on Vagon. <br /> Once you accept the invite, you
              will be a member of {invitationData?.attributes?.organization_name}.
            </p>
          </div>
          <div className="button-container">
            <Button
              text="Decline"
              outline
              transparent
              onClick={() => {
                rejectInviteAPI();
              }}
            />
            <Button
              text="Accept"
              onClick={() => {
                acceptInviteAPI();
              }}
            />
          </div>
        </div>
      ) : (
        <div className="center-container">
          <div className="logo-container">
            <Icon name="vagon-icon" color="white" />
          </div>
          <Input
            inputRef={inputRef}
            style={{ width: calculateComputerNameInputWidth() }}
            placeholder="Your Computer's Name"
            value={computerName}
            onChange={(event) => setComputerName(event.target.value)}
            maxLength={30}
            big
            white
            centered
          />
          <p>
            {organizationAdmin &&
              "You are Owner of a Team, you can create a \nPersonal Computer for yourself or Team Computer under your team."}
          </p>

          {organizationAdmin ? (
            <div className="buttons-container">
              <Button
                text="Create a Team Computer"
                onClick={onCreateTeamComputerButtonClick}
                eventType="createComputer"
                block
              />
              <Button
                text="Switch to Teams Console"
                onClick={() => {
                  setAppScope(APP_SCOPES.team_admin);
                  history.push(routes.organizationAdminHome);
                }}
                eventType="createComputer"
                block
                basic
              />
            </div>
          ) : (
            <Button text="Create Computer" onClick={onCreateComputerButtonClick} eventType="createComputer" darkNavy />
          )}
        </div>
      )}
      {organizationAdmin && !invitationData && (
        <TextButton color="gray-3" text="Create a Personal Vagon Computer" onClick={onCreateComputerButtonClick} />
      )}
    </div>
  );
};

export default NewComputer;
