import React from "react";
import { Modal } from "UI";
import Computer from "Assets/images/organizations/computer-duration.png";
import MeteredDuration from "Assets/images/organizations/metered-duration.png";
import AFKDuration from "Assets/images/organizations/afk-duration.png";
import ActiveDuration from "Assets/images/organizations/active-duration.png";

import "./AnalyticInformationModal.styles.scss";

const AnalyticInformationModal = ({ translate, onClose }) => {
  const analytics = [
    {
      id: 1,
      name: translate("organizationUsageAnalytics.analyticInformationModal.analytics.totalDuration.header"),
      description: translate("organizationUsageAnalytics.analyticInformationModal.analytics.totalDuration.description"),
      img: Computer,
    },
    {
      id: 2,
      name: translate("organizationUsageAnalytics.analyticInformationModal.analytics.meteredDuration.header"),
      description: translate("organizationUsageAnalytics.analyticInformationModal.analytics.meteredDuration.description"),
      img: MeteredDuration,
    },
    {
      id: 3,
      name: translate("organizationUsageAnalytics.analyticInformationModal.analytics.activeDuration.header"),
      description: translate("organizationUsageAnalytics.analyticInformationModal.analytics.activeDuration.description"),
      img: ActiveDuration,
    },
    {
      id: 4,
      name: translate("organizationUsageAnalytics.analyticInformationModal.analytics.afkDuration.header"),
      description: translate("organizationUsageAnalytics.analyticInformationModal.analytics.afkDuration.description"),
      img: AFKDuration,
    },
  ];

  return (
    <Modal
      className="analytic-information-modal"
      headerText={translate("organizationUsageAnalytics.analyticInformationModal.header")}
      closeAction={onClose}
      topRightIcon="close"
      topRightIconAction={onClose}
    >
      <div className="analytic-information-modal-content">
        {analytics.map((permission) => {
          return (
            <div className="analytic" key={permission.id}>
              <img src={permission.img} alt="analytic" />
              <div className="text">
                <div className="title">{permission.name}</div>
                <div className="description">{permission.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default AnalyticInformationModal;
