/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { FileUploadModal, FileStatus } from "Utils";

import { uploadFilesToFolder, clearUploadedFile, cancelFileUpload } from "Actions/Dashboard/Files.actions";
import { FILE_STATUS, UPLOAD_TYPES } from "Constants/global.constants";

import "./FileWatcher.styles.scss";

const isFileUploading = (files) => {
  return _.findKey(files, (file) => file.status === FILE_STATUS.PENDING);
};

// https://www.w3schools.com/howto/howto_js_draggable.asp
const FileWatcher = (props) => {
  const { files, clearUploadedFile, cancelFileUpload, uploadFilesToFolder, translate } = props;
  const { uploadFilesCTX } = files;
  const { uploadFiles, showProgressBar } = files.uploadFilesCTX;

  const uploadFileList = Object.keys(
    _.pickBy(
      uploadFiles,
      (file) => file.status === FILE_STATUS.PENDING && file.uploadType === UPLOAD_TYPES.FILE_UPLOAD,
    ),
  );
  const uploadingFileNumber = uploadFileList.length;
  const firstUploadedFile = uploadFiles[uploadFileList[0]];

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  useEffect(() => {
    if (isFileUploading(uploadFiles)) {
      window.addEventListener("beforeunload", confirmExit);
    }
    return () => {
      window.removeEventListener("beforeunload", confirmExit);
    };
  }, [uploadFiles, uploadFilesCTX]);

  const handleFileDrop = (files) => {
    if (files.length > 0) {
      uploadFilesToFolder({ files, currentFolderId: null });
    }
  };

  const confirmExit = (event) => {
    event.preventDefault();
    window.event.returnValue = null;
    return null;
  };

  if (!showProgressBar) {
    return null;
  }

  return (
    <>
      {!showFileUploadModal && (
        <div className="file-watcher">
          {firstUploadedFile && (
            <div
              className="file-uploading file-information-bar"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowFileUploadModal(true);
              }}
            >
              <FileStatus
                fileName={firstUploadedFile.name}
                fileNameLimit={15}
                fileSize={firstUploadedFile.size}
                fileStatus={firstUploadedFile.status}
                fileProgress={firstUploadedFile.progress}
                // onCancel={cancelFileUpload}
                onConfirm={clearUploadedFile}
              />
            </div>
          )}
          {uploadingFileNumber > 1 && (
            <div className="files-uploading file-information-bar" onClick={() => setShowFileUploadModal(true)}>
              {translate("fileWatcher.moreFiles", { amount: uploadingFileNumber - 1 })}
            </div>
          )}
        </div>
      )}
      {showFileUploadModal && (
        <FileUploadModal
          files={uploadFilesCTX.uploadFiles}
          onFileDrop={handleFileDrop}
          cancelFileUpload={cancelFileUpload}
          clearUploadedFile={clearUploadedFile}
          setShowFileUploadModal={setShowFileUploadModal}
          translate={translate}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  files: state.files,
  account: state.account,
});

const actionCreators = {
  uploadFilesToFolder,
  clearUploadedFile,
  cancelFileUpload,
};

export default connect(mapStateToProps, actionCreators)(FileWatcher);
