import React, { useEffect, useState } from "react";
import { Icon, DatePickerInput, Dropdown } from "UI";
import { DashboardSubScreenLayout, convertDateFormat, localDateFormat, apiGenerator } from "Utils";
import routes from "Constants/Route.constants";
import { useQuery } from "@tanstack/react-query";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { REGIONS_NAMES } from "Constants/global.constants";
import moment from "moment";
import UserSessionTable from "./UserSessionTable.component";
import EmptyContent from "../EmptyContent/EmptyContent.component";
import SessionGraph from "../SessionGraph.component";
import "./AppStreamingUsers.styles.scss";

const AppStreamingUsers = ({ account, appStreaming, history, getProductOnboardingAPI, translate }) => {
  const location = useLocation();
  const showMachines = location.state?.showMachines;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [applicationFilter, setApplicationFilter] = useState(null);
  const [streamNameFilter, setStreamNameFilter] = useState(null);
  const [regionFilter, setRegionFilter] = useState(null);
  const { getApplicationsCTX } = appStreaming;
  const applicationList = getApplicationsCTX.data?.applications || [];
  const firstDayOfMonth = moment().startOf("month").format("DD-MM-YYYY");
  const today = convertDateFormat(new Date().toISOString().substring(0, 10));
  const datePickerPlaceHolder = firstDayOfMonth.concat(" - ", today);

  const { data: graphData, isInitialLoading } = useQuery({
    queryKey: [
      APP_STREAMING_API_ENDPOINTS.GET_MACHINE_STATS,
      endDate,
      applicationFilter,
      streamNameFilter,
      regionFilter,
      startDate,
      endDate,
    ],
    keepPreviousData: true,
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_MACHINE_STATS, {
        params: {
          start_date: startDate ? localDateFormat(startDate) : firstDayOfMonth,
          end_date: endDate ? localDateFormat(endDate) : today,
          application_id: applicationFilter?.id,
          stream_id: streamNameFilter?.id,
          region: regionFilter === "all regions" ? null : regionFilter,
        },
      });
    },
  });

  const { data: filterOptionsData } = useQuery({
    queryKey: [
      APP_STREAMING_API_ENDPOINTS.GET_VENDOR_TRANSACTIONS_FILTER_OPTIONS,
      applicationFilter,
      streamNameFilter,
      startDate,
      endDate,
    ],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_MACHINES_FILTER_OPTIONS, {
        params: {
          start_at: startDate ? localDateFormat(startDate) : firstDayOfMonth,
          end_at: endDate ? localDateFormat(endDate) : today,
          application_id: applicationFilter?.id,
          stream_id: streamNameFilter?.id,
        },
      });
    },
    keepPreviousData: true,
  });

  useEffect(() => {
    getProductOnboardingAPI();
  }, []);

  const customerSessions = Object.entries(graphData?.data?.daily_session_count || {}).map(([date, count]) => {
    return {
      count,
      // Remove 0 if it's the beginning of dates and replace month and day locations i.e 05-03-2022 -> 5/3
      time: date.substring(8, 10).replace(/^0+/, "").concat("/", date.substring(5, 7).replace(/^0+/, "")),
    };
  });

  const applicationFilterOptions = [
    { id: null, name: "All Applications" },
    ...(filterOptionsData?.data?.applications || []),
  ];
  const streamNameFilterOptions = [{ id: null, name: "All Streams" }, ...(filterOptionsData?.data?.streams || [])];
  const regionFilterOptions = ["all regions", ...(filterOptionsData?.data?.regions || [])];

  const activeUsers = graphData?.data?.total_session_count || 0;
  const totalSessionHours = (graphData?.data?.total_session_duration / 3600 || 0).toFixed(1);
  const noApplication = applicationList.length === 0;
  const anySession = graphData?.data?.all_time_any_session;

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("appStreamingUsers.header.title")}
      headerDescription={translate("appStreamingUsers.header.description")}
      loading={isInitialLoading}
      translate={translate}
      appStreaming
    >
      <div className="app-streaming-users">
        {!anySession ? (
          <EmptyContent
            headerText={translate("appStreamingUsers.initialContent.header")}
            descriptionText={translate("appStreamingUsers.initialContent.withoutSessionDescription")}
            buttonText={translate("appStreamingUsers.initialContent.action")}
            onButtonClick={() => history.push(routes.appStreamingHome)}
            buttonColor="light-navy"
          />
        ) : (
          <>
            <div className="filter-graph-container">
              <div className="filter-row">
                <div className="dropdown-container">
                  <Dropdown
                    underlined
                    options={applicationFilterOptions}
                    mapFunction={(option) => {
                      return option.name;
                    }}
                    handleSelectedOptionChange={(e) => {
                      setApplicationFilter(e);
                      if (applicationFilter !== e.id && e.name !== "All Applications") {
                        setStreamNameFilter(null);
                        setRegionFilter(null);
                      }
                    }}
                    defaultSelected={applicationFilter}
                    placeholder="Application"
                    selectedOption={applicationFilter}
                    disabled={applicationFilterOptions.length === 1}
                  />
                  <Dropdown
                    underlined
                    options={streamNameFilterOptions}
                    mapFunction={(option) => option.name}
                    handleSelectedOptionChange={(e) => {
                      setStreamNameFilter(e);
                    }}
                    defaultSelected={streamNameFilter}
                    placeholder="Stream"
                    disabled={streamNameFilterOptions.length === 1}
                  />
                  <Dropdown
                    underlined
                    options={regionFilterOptions}
                    mapFunction={(option) => REGIONS_NAMES[option]}
                    handleSelectedOptionChange={(e) => {
                      setRegionFilter(e);
                    }}
                    defaultSelected={regionFilter}
                    placeholder="Region"
                    disabled={regionFilterOptions.length === 1}
                  />
                  <DatePickerInput
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    placeHolder={datePickerPlaceHolder}
                  />
                </div>
                <div className="users-graph-container content-background white">
                  <div className="info-container">
                    <div className="info-line">
                      <div className="aqua-light rounded icon-background">
                        <Icon name="team" color="aqua-main" small />
                      </div>
                      <div className="text-container">
                        <p>
                          <span className="bold">{activeUsers}</span>
                        </p>
                        <p>{translate("appStreamingUsers.users")}</p>
                      </div>
                    </div>
                    <div className="info-line">
                      <div className="baby-powder-background rounded icon-background">
                        <Icon name="hourglass" color="baby-powder" smaller />
                      </div>
                      <div className="text-container">
                        <p>
                          <span className="bold">{totalSessionHours}</span>
                          <span className="light">{translate("appStreamingUsers.hours")}</span>
                        </p>
                        <p>{translate("appStreamingUsers.totalSessions")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="graph-container">
                    <SessionGraph timeSlots={customerSessions} translate={translate} />
                  </div>
                </div>
              </div>
            </div>
            <div className="content-background white session-table">
              <UserSessionTable
                startDate={startDate}
                endDate={endDate}
                applicationFilter={applicationFilter}
                streamNameFilter={streamNameFilter}
                regionFilter={regionFilter}
                streamStats
                showMachines={showMachines}
              />
            </div>
          </>
        )}
      </div>
    </DashboardSubScreenLayout>
  );
};

export default AppStreamingUsers;
