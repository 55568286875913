import React from "react";
import _ from "lodash";
import { Translate } from "react-localize-redux";
import { MachineTypeCard } from "../../../UI";
import { dolarToCents } from "../../../Utils/Helpers/functions.helpers";

import "./MachineTypeSelection.styles.scss";

const groupMachineTypes = (machineTypes) => {
  return _.reduce(
    machineTypes,
    (group, machineType) => {
      const { name, friendly_name: friendlyName } = machineType.attributes;
      if (name.startsWith("g5")) {
        return {
          ...group,
          a10: {
            ...group.a10,
            [machineType.id]: machineType,
          },
        };
      } else if (name.startsWith("g4dn")) {
        return {
          ...group,
          gpu: {
            ...group.gpu,
            [machineType.id]: machineType,
          },
        };
      } else if (friendlyName === "Sand") {
        return {
          ...group,
          general: {
            ...group.general,
            [machineType.id]: machineType,
          },
        };
      }

      return {
        ...group,
        cpu: {
          ...group.cpu,
          [machineType.id]: machineType,
        },
      };
    },
    {},
  );
};

const MachineTypeSelection = ({ machineTypes, selectedMachineType, setSelectedMachineType, translate }) => {
  const groupedMachineTypes = groupMachineTypes(machineTypes);

  return (
    <>
      <div className="machine-type-groups">
        {Object.keys(groupedMachineTypes)
          .reverse()
          .map((group) => (
            <div key={group}>
              <div className="descriptions">
                <p className="title">
                  {translate(`appStreamingModals.configuration.machineTypeGroups.${group}.header`)}
                </p>
                <p className="description-text">
                  <Translate
                    id={`appStreamingModals.configuration.machineTypeGroups.${group}.description`}
                    options={{ renderInnerHtml: true }}
                  />
                </p>
              </div>
              <div className="grouped-machine-types">
                {Object.keys(groupedMachineTypes[group])?.map((machine) => {
                  const machineType = groupedMachineTypes[group][machine];
                  return (
                    <div key={machineType.id} className="machine-type-card-container">
                      <MachineTypeCard
                        key={machineType.id}
                        machineType={machineType}
                        minimized
                        selectedMachineType={selectedMachineType}
                        setSelectedMachineType={setSelectedMachineType}
                        shouldSavePerformance={false}
                        appStreaming
                      />
                      <div className="pricing-info">
                        <span>{dolarToCents(machineType.price)}¢</span>
                        {translate("appStreamingModals.configuration.performance.timeUnit")}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default MachineTypeSelection;
