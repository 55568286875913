import React, { useEffect, useRef } from "react";

import _ from "lodash";

const HEIGHT_THRESHOLD = 50;

const SectionScroll = (props) => {
  const { className, children, isScrolling, setIsScrolling, setActiveSection } = props;

  const wrapperRef = useRef(null);

  useEffect(() => {
    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener("scroll", scrollListener);
      }
    };
  }, []);

  useEffect(() => {
    if (wrapperRef.current) {
      const wrapper = wrapperRef.current;
      wrapper.addEventListener("scroll", scrollListener);
    }
  }, [wrapperRef]);

  const scrollListener = () => {
    if (!isScrolling) {
      if (setIsScrolling) {
        setIsScrolling(true);
      }
    }
    debounce();
  };

  const calculateActiveSection = () => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const { height } = wrapper.getBoundingClientRect();
      const { scrollTop } = wrapper;
      let currentSection = Math.round(scrollTop / (height - HEIGHT_THRESHOLD));

      if (currentSection < 0) currentSection = 0;
      if (currentSection >= children.length) currentSection = children.length - 1;
      setActiveSection(currentSection);
    }
  };

  const debounceFunction = () => {
    if (setActiveSection) {
      calculateActiveSection();
    }
    if (setIsScrolling) {
      setIsScrolling(false);
    }
  };

  const debounce = _.debounce(debounceFunction, 300);

  const renderChildren = () => (typeof children === "function" ? children() : children);

  return (
    <div className={`section-scroll-wrapper ${className}`} ref={wrapperRef}>
      {renderChildren()}
    </div>
  );
};

export default SectionScroll;
