import React from "react";
import { connect } from "react-redux";

import { changePasswordAPI, getAccountAPI } from "Actions/Account.actions";
import { logoutAPI } from "Actions/Authentication/Login.actions";
import {
  getUserSubscriptionAPI,
  renewSubscriptionAPI,
  cancelSubscriptionAPI,
} from "Actions/Dashboard/Subscriptions.actions";
import { getRewardsAPI } from "Actions/Referral.actions";
import { getWorkstationsAPI } from "Actions/Dashboard/Workstations.actions";
import AccountComponent from "Components/Dashboard/Account/Account/Account.component";

class AccountContainer extends React.Component {
  constructor(props) {
    super();

    const { getUserSubscriptionAPI, getRewardsAPI, getWorkstationsAPI } = props;

    getUserSubscriptionAPI();
    getRewardsAPI();
    getWorkstationsAPI();
  }

  render() {
    return <AccountComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  referral: state.referral,
  subscriptions: state.subscriptions,
  workstations: state.workstations,
});

const actionCreators = {
  changePasswordAPI,
  getUserSubscriptionAPI,
  renewSubscriptionAPI,
  cancelSubscriptionAPI,
  logoutAPI,
  getRewardsAPI,
  getWorkstationsAPI,
  getAccountAPI,
};

export default connect(mapStateToProps, actionCreators)(AccountContainer);
