import React, { useEffect } from "react";
import { APP_SCOPES, ROOT_PATHS } from "Constants/global.constants";
import routes from "Constants/Route.constants";
import { useAppScope } from "Utils/Hooks/useAppScope";
import ProtectedRouteWithTranslation from "../Helpers/ProtectedRouteWithTranslation";

const UserRoute = (props) => {
  const { path, account, history } = props;
  const { organization_machine: organizationMachine, organization_member: organizationMember } =
    account?.account?.attributes || {};

  const { appScope, setAppScope } = useAppScope();

  useEffect(() => {
    if (appScope !== APP_SCOPES.user) {
      history.push(ROOT_PATHS[appScope]);
    }
    if (organizationMachine || organizationMember) {
      setAppScope(APP_SCOPES.team_member);
      history.push(routes.organizationMemberHome);
    }
  }, [path, appScope]);

  return <ProtectedRouteWithTranslation {...props} />;
};

export default UserRoute;
