import { APP_DOWNLOAD_LINKS } from "Constants/global.constants";
import React, { useEffect, useState } from "react";
import { withLocalize } from "react-localize-redux";
import { ConfirmationModal } from "UI";
import { isWindows } from "Utils/Helpers/browser.helpers";
import Electron from "Utils/Helpers/electron.helpers";
import useSessionStorage from "Utils/Hooks/useSessionStorage";

const ElectronVersionModal = ({ translate }) => {
  const [showModal, setShowModal] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useSessionStorage("doNotShowElectronVersionModal", false);

  useEffect(() => {
    if (doNotShowAgain) return;

    const electronVersion = Electron.getElectronVersion();
    // Check if version is less than 30.x.x
    if (electronVersion && electronVersion.startsWith("2") && isWindows) {
      setShowModal(true);
    }
  }, []);

  if (!showModal) return null;

  return (
    <ConfirmationModal
      headerText={translate("electronVersionModal.header")}
      descriptionText={translate("electronVersionModal.description")}
      confirmText={translate("electronVersionModal.action")}
      confirmAction={() => {
        window.location.assign(APP_DOWNLOAD_LINKS.appDownloadLinkForWindows);
        setDoNotShowAgain(true);
        setShowModal(false);
      }}
      closeAction={() => {
        setShowModal(false);
        setDoNotShowAgain(true);
      }}
      secondaryAction={() => {
        setDoNotShowAgain(true);
        setShowModal(false);
      }}
      secondaryText={translate("electronVersionModal.secondaryAction")}
    />
  );
};

export default withLocalize(ElectronVersionModal);
