export const FILE_UPLOAD = "FILE_UPLOAD";
export const FOLDER_UPLOAD = "FOLDER_UPLOAD";

export const GET_USER_FILES_PENDING = "GET_USER_FILES_PENDING";
export const GET_USER_FILES_SUCCESS = "GET_USER_FILES_SUCCESS";
export const GET_USER_FILES_FAILURE = "GET_USER_FILES_FAILURE";

export const GET_RECENT_FILES_PENDING = "GET_RECENT_FILES_PENDING";
export const GET_RECENT_FILES_SUCCESS = "GET_RECENT_FILES_SUCCESS";
export const GET_RECENT_FILES_FAILURE = "GET_RECENT_FILES_FAILURE";

export const GET_MOVE_FILES_PENDING = "GET_MOVE_FILES_PENDING";
export const GET_MOVE_FILES_FAILURE = "GET_MOVE_FILES_FAILURE";

export const GET_FILE_STORAGE_CAPACITY_PENDING = "GET_FILE_STORAGE_CAPACITY_PENDING";
export const GET_FILE_STORAGE_CAPACITY_SUCCESS = "GET_FILE_STORAGE_CAPACITY_SUCCESS";
export const GET_FILE_STORAGE_CAPACITY_FAILURE = "GET_FILE_STORAGE_CAPACITY_FAILURE";

export const DELETE_FILE_PENDING = "DELETE_FILE_PENDING";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const MOVE_FILE_PENDING = "MOVE_FILE_PENDING";
export const MOVE_FILE_SUCCESS = "MOVE_FILE_SUCCESS";
export const MOVE_FILE_FAILURE = "MOVE_FILE_FAILURE";

export const RENAME_FILE_PENDING = "RENAME_FILE_PENDING";
export const RENAME_FILE_SUCCESS = "RENAME_FILE_SUCCESS";
export const RENAME_FILE_FAILURE = "RENAME_FILE_FAILURE";

export const CREATE_FOLDER_PENDING = "CREATE_FOLDER_PENDING";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";

export const DOWNLOAD_FILE_PENDING = "DOWNLOAD_FILE_PENDING";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";

export const UPLOAD_FILE_PENDING = "UPLOAD_FILE_PENDING";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UPLOAD_FILE_PROGRESS = "UPLOAD_FILE_PROGRESS";
export const UPLOAD_FILE_PROGRESS_BAR_UPDATE = "UPLOAD_FILE_PROGRESS_BAR_UPDATE";
export const UPLOAD_FILE_CLEAR = "UPLOAD_FILE_CLEAR";
export const UPLOAD_FILE_CANCEL = "UPLOAD_FILE_CANCEL";

export const UPLOAD_FILES_CLEAR = "UPLOAD_FILES_CLEAR";

export const WORKSTATION_UPLOAD_FILE_SUCCESS = "WORKSTATION_UPLOAD_FILE_SUCCESS";
export const WORKSTATION_UPLOAD_FILE_REMOVE = "WORKSTATION_UPLOAD_FILE_REMOVE";

export const APPLICATION_UPLOAD_FILE_PENDING = "APPLICATION_UPLOAD_FILE_PENDING";
export const APPLICATION_UPLOAD_FILE_SUCCESS = "APPLICATION_UPLOAD_FILE_SUCCESS";
export const APPLICATION_UPLOAD_FILE_REMOVE = "APPLICATION_UPLOAD_FILE_REMOVE";

export const APPLICATION_VERSION_UPLOAD_FILE_PENDING = "APPLICATION_VERSION_UPLOAD_FILE_PENDING";
export const APPLICATION_VERSION_UPLOAD_FILE_SUCCESS = "APPLICATION_VERSION_UPLOAD_FILE_SUCCESS";
export const APPLICATION_VERSION_UPLOAD_FILE_REMOVE = "APPLICATION_VERSION_UPLOAD_FILE_REMOVE";

export const STREAM_SESSION_FILE_UPLOAD_PENDING = "STREAM_SESSION_FILE_UPLOAD_PENDING";
export const STREAM_SESSION_FILE_UPLOAD_SUCCESS = "STREAM_SESSION_FILE_UPLOAD_SUCCESS";
export const STREAM_SESSION_FILE_UPLOAD_REMOVE = "STREAM_SESSION_FILE_UPLOAD_REMOVE";

export const CLEAR_USER_FILES = "CLEAR_USER_FILES";
