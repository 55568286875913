import React from "react";
import { CircularSliderWithChildren } from "./index-circular-slider";
import "./CircularSlider.style.scss";

const STEP_SIZE = 50;

const RoundSlider = (props) => {
  const { minimumAllowedStep, step, setStep, stepCount, displayValue } = props;

  const values = [];
  for (let i = 0; i <= stepCount; i += 1) {
    values.push(i * STEP_SIZE);
  }

  const handleChange = (i) => {
    if (i < minimumAllowedStep) return;
    setStep(i);
  };

  return (
    <CircularSliderWithChildren
      size={220}
      minValue={0}
      maxValue={values.length - 1}
      startAngle={0}
      endAngle={360}
      coerceToInt
      angleType={{
        direction: "cw",
        axis: "+y",
      }}
      handle1={{
        value: step || 0,
        onChange: handleChange,
      }}
      arcColor="#4500A6"
      arcBackgroundColor="#CECECE"
    >
      {displayValue}
    </CircularSliderWithChildren>
  );
};

export default RoundSlider;
