import { getProductOnboardingAPI } from "Actions/Account.actions";
import CreateTemplate from "Components/Organization/Admin/CreateTemplate/CreateTemplate.component";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const CreateTemplateContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductOnboardingAPI());
  }, []);

  return <CreateTemplate {...props} />;
};

export default CreateTemplateContainer;
