import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import _, { isNull } from "lodash";
import moment from "moment";
import { Loader, apiGenerator } from "Utils";
import { classnames, groupOrganizationMachineTypes } from "Utils/Helpers/functions.helpers";
import { Translate } from "react-localize-redux";
import { Button, HoverableTooltip, Icon, Input } from "UI";
import CheckBox from "UI/CheckBox/CheckBox.component";
import UsageLimitForm from "./UsageLimitForm/UsageLimitForm.component";
import ConfigurationOptionCard from "./ConfigurationOptionCard/ConfigurationOptionCard.component";
import PerformanceOptionsTable from "./PerformanceOptionsTable/PerformanceOptionsTable.component";
import "./ConfigurePlan.styles.scss";

const CONFIGURE_PLAN_OPTIONS = {
  availablePerformances: "availablePerformances",
  diskStorage: "diskStorage",
  filesStorage: "filesStorage",
  usageLimit: "usageLimit",
};

const ConfigurePlan = ({ onButtonClick, createComputers, translate, hasError, errorText }) => {
  const [planConfigs, setPlanConfigs] = useState({});
  const [expandingOption, setExpandingOption] = useState(CONFIGURE_PLAN_OPTIONS.diskStorage);
  const { organizationPlan } = useSelector((state) => state.organizations?.getOrganizationPlanCTX) || {};
  const [selectedMachineTypes, setSelectedMachineTypes] = useState([]);
  const [diskStorageStep, setDiskStorageStep] = useState(0);
  const [fileStorageStep, setFileStorageStep] = useState(0);
  const [usageLimit, setUsageLimit] = useState(null);
  const [planName, setPlanName] = useState("");
  const [calculatedPrices, setCalculatedPrices] = useState({});
  const [savedOptions, setSavedOptions] = useState({
    [CONFIGURE_PLAN_OPTIONS.availablePerformances]: false,
    [CONFIGURE_PLAN_OPTIONS.diskStorage]: false,
    [CONFIGURE_PLAN_OPTIONS.filesStorage]: false,
    [CONFIGURE_PLAN_OPTIONS.usageLimit]: false,
  });
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [manageUsageManually, setManageUsageManually] = useState(false);

  const addBalanceFormRef = useRef(null);
  const planNameInputRef = useRef(null);

  const allOptionsSaved = useMemo(() => {
    return Object.values(savedOptions).every((item) => {
      return item === true;
    });
  }, Object.values(savedOptions));

  useEffect(() => {
    if (allOptionsSaved) {
      planNameInputRef.current.focus();
    }
  }, [allOptionsSaved]);

  const diskStorageOptions = organizationPlan?.attributes?.disk_options?.map((option) => {
    return option.attributes.size;
  });

  const fileStorageOptions = organizationPlan?.attributes?.file_storage_options?.map((option) => {
    return option.attributes.size;
  });

  const machineTypes = organizationPlan?.attributes?.pricing?.map((pricing) => {
    return pricing.attributes.machine_type;
  });
  const formatDuration = (duration) => {
    const hours = duration.hours() + duration.days() * 24;
    const minutes = duration.minutes();

    return `${hours ? `${hours} hours` : ""} ${minutes ? `${minutes} minutes` : ""}`;
  };
  const groupedMachineTypes = groupOrganizationMachineTypes(machineTypes || []);

  const onSaveOption = (optionName) => {
    setSavedOptions((prev) => {
      return {
        ...prev,
        [optionName]: true,
      };
    });
  };

  const machineTypeNames = useMemo(() => {
    return _.filter(machineTypes, (machineType) => {
      return selectedMachineTypes.includes(machineType.id);
    })?.map((type) => type.attributes.friendly_name);
  }, [selectedMachineTypes]);

  useEffect(() => {
    if (!organizationPlan) return;

    setPlanConfigs((prev) => {
      return {
        ...prev,
        disk_storage: diskStorageOptions[diskStorageStep],
        file_storage: fileStorageOptions[fileStorageStep],
        machine_type_ids: selectedMachineTypes,
        usage: usageLimit * 60,
      };
    });
  }, [organizationPlan, diskStorageStep, fileStorageStep, selectedMachineTypes, usageLimit]);

  useEffect(() => {
    if (selectedMachineTypes.length === 0) {
      setSavedOptions((prev) => {
        return {
          ...prev,
          [CONFIGURE_PLAN_OPTIONS.availablePerformances]: false,
        };
      });
    }
  }, [selectedMachineTypes]);

  const calculatePricesMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEAT_PLANS_CALCULATE, data);
    },
    onSuccess: (data) => {
      setCalculatedPrices(data.data);
      setIsInitialLoading(false);
    },
  });

  useEffect(() => {
    calculatePricesMutation.mutate(planConfigs);
  }, [planConfigs]);

  const setMachineTypePreviewText = useMemo(() => {
    const string = machineTypeNames?.slice(0, 3)?.join(", ");
    if (machineTypeNames?.length > 3) {
      return `${string}...`;
    }
    return string;
  }, [machineTypeNames]);

  const {
    total_price: totalPrice,
    disk_storage_size: diskStorageSize,
    file_storage_size: fileStorageSize,
    usage_list: usageList,
    usage_price: usagePrice,
  } = calculatedPrices?.attributes || {};

  const lowestPriceMachineType = usageList?.[0]?.attributes?.machine_type?.attributes?.friendly_name;

  const usageLimitPreview = useMemo(() => {
    if (!savedOptions[CONFIGURE_PLAN_OPTIONS.usageLimit]) return null;
    if (!usageLimit || usageLimit === 0) return "Not Defined";
    return `${usageLimit} Hours`;
  }, [usageLimit, savedOptions]);

  if (!organizationPlan || !calculatedPrices) return "loading";

  return (
    <div className="configure-plan-container">
      <div className="plan-configurations">
        <h1>{translate("organizationConfigurePlan.header")}</h1>

        <div className="configuration-options">
          {(expandingOption === CONFIGURE_PLAN_OPTIONS.diskStorage || expandingOption === null) && (
            <ConfigurationOptionCard
              header={translate("organizationConfigurePlan.configurationOptions.diskStorage.header")}
              description={translate("organizationConfigurePlan.configurationOptions.diskStorage.description")}
              selectedPreview={diskStorageOptions ? `${diskStorageOptions[diskStorageStep]} GB` : "75 GB"}
              optionName={CONFIGURE_PLAN_OPTIONS.diskStorage}
              expandingOption={expandingOption}
              setExpandingOption={setExpandingOption}
              expandableContent={
                <div className="storage-option">
                  <div className="storage-buttons">
                    <button
                      type="button"
                      label="Decrement"
                      disabled={diskStorageStep === 0}
                      onClick={() => {
                        setDiskStorageStep((prev) => prev - 1);
                      }}
                    >
                      -
                    </button>
                    <p>{diskStorageOptions[diskStorageStep]} GB</p>
                    <button
                      type="button"
                      label="Increment"
                      disabled={diskStorageStep === diskStorageOptions.length - 1}
                      onClick={() => {
                        setDiskStorageStep((prev) => prev + 1);
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              }
              onBack={() => {
                setExpandingOption(CONFIGURE_PLAN_OPTIONS.availablePerformances);
              }}
              onSave={() => {
                onSaveOption(CONFIGURE_PLAN_OPTIONS.diskStorage);
                setExpandingOption(CONFIGURE_PLAN_OPTIONS.filesStorage);
              }}
              onExpandClose={() => {
                onSaveOption(CONFIGURE_PLAN_OPTIONS.diskStorage);
              }}
              saved={savedOptions[CONFIGURE_PLAN_OPTIONS.diskStorage]}
            />
          )}
          {(expandingOption === CONFIGURE_PLAN_OPTIONS.filesStorage || isNull(expandingOption)) && (
            <ConfigurationOptionCard
              header={translate("organizationConfigurePlan.configurationOptions.fileStorage.header")}
              description={translate("organizationConfigurePlan.configurationOptions.fileStorage.description")}
              selectedPreview={fileStorageOptions ? `${fileStorageOptions[fileStorageStep]} GB` : "5 GB"}
              optionName={CONFIGURE_PLAN_OPTIONS.filesStorage}
              expandingOption={expandingOption}
              setExpandingOption={setExpandingOption}
              expandableContent={
                <div className="storage-option">
                  <div className="storage-buttons files-storage">
                    <button
                      type="button"
                      label="Decrement"
                      disabled={fileStorageStep === 0}
                      onClick={() => {
                        setFileStorageStep((prev) => prev - 1);
                      }}
                    >
                      -
                    </button>
                    <p>{fileStorageOptions[fileStorageStep]} GB</p>
                    <button
                      type="button"
                      label="Increment"
                      disabled={fileStorageStep === fileStorageOptions.length - 1}
                      onClick={() => {
                        setFileStorageStep((prev) => prev + 1);
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              }
              onBack={() => {
                setExpandingOption(CONFIGURE_PLAN_OPTIONS.diskStorage);
              }}
              onExpandClose={() => {
                onSaveOption(CONFIGURE_PLAN_OPTIONS.filesStorage);
              }}
              onSave={() => {
                onSaveOption(CONFIGURE_PLAN_OPTIONS.filesStorage);
                setExpandingOption(CONFIGURE_PLAN_OPTIONS.availablePerformances);
              }}
              saved={savedOptions[CONFIGURE_PLAN_OPTIONS.filesStorage]}
            />
          )}
          {(expandingOption === CONFIGURE_PLAN_OPTIONS.availablePerformances || isNull(expandingOption)) && (
            <ConfigurationOptionCard
              header={translate("organizationConfigurePlan.configurationOptions.availablePerformances.header")}
              description={translate(
                "organizationConfigurePlan.configurationOptions.availablePerformances.description",
              )}
              selectedPreview={setMachineTypePreviewText}
              optionName={CONFIGURE_PLAN_OPTIONS.availablePerformances}
              expandingOption={expandingOption}
              expandableContent={
                <PerformanceOptionsTable
                  selectedMachineTypes={selectedMachineTypes}
                  setSelectedMachineTypes={setSelectedMachineTypes}
                  groupedMachineTypes={groupedMachineTypes}
                  translate={translate}
                />
              }
              setExpandingOption={setExpandingOption}
              onSave={() => {
                if (selectedMachineTypes.length === 0) return;
                onSaveOption(CONFIGURE_PLAN_OPTIONS.availablePerformances);
                setExpandingOption(CONFIGURE_PLAN_OPTIONS.usageLimit);
              }}
              onExpandClose={() => {
                if (selectedMachineTypes.length === 0) return;
                onSaveOption(CONFIGURE_PLAN_OPTIONS.availablePerformances);
              }}
              saveButtonDisabled={selectedMachineTypes.length === 0}
              saved={savedOptions[CONFIGURE_PLAN_OPTIONS.availablePerformances]}
            />
          )}
          {(expandingOption === CONFIGURE_PLAN_OPTIONS.usageLimit || isNull(expandingOption)) && (
            <ConfigurationOptionCard
              header={translate("organizationConfigurePlan.configurationOptions.usageLimit.header")}
              description={translate("organizationConfigurePlan.configurationOptions.usageLimit.description")}
              selectedPreview={usageLimitPreview}
              optionName={CONFIGURE_PLAN_OPTIONS.usageLimit}
              expandingOption={expandingOption}
              setExpandingOption={setExpandingOption}
              expandableContent={
                <div className="usage-limit">
                  <div className="usage-container">
                    <UsageLimitForm
                      ref={addBalanceFormRef}
                      setHour={(e) => {
                        setUsageLimit(e);
                        if (e !== 0) {
                          setManageUsageManually(false);
                        }
                      }}
                      onAnyButtonClick={() => {
                        setManageUsageManually(false);
                      }}
                      hours={[10, 20, 45, 60, 120]}
                      customHourText={`hours of ${lowestPriceMachineType} usage`}
                      translate={translate}
                    />
                  </div>
                  <div className="usage-limit-info">
                    <Translate
                      id="organizationConfigurePlan.configurationOptions.usageLimit.info"
                      data={{ machineType: lowestPriceMachineType }}
                    />
                  </div>
                  <div className="usage-limit-description">
                    <Icon name="info" color="gray-3" />
                    <p>{translate("organizationConfigurePlan.configurationOptions.usageLimit.altInfo")}</p>
                  </div>

                  <CheckBox
                    label={translate("organizationConfigurePlan.configurationOptions.usageLimit.useManual")}
                    checked={manageUsageManually}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addBalanceFormRef.current.reset();
                        setManageUsageManually(true);
                        setUsageLimit(0);
                      } else {
                        setManageUsageManually(false);
                      }
                    }}
                  />
                </div>
              }
              onBack={() => {
                setExpandingOption(CONFIGURE_PLAN_OPTIONS.filesStorage);
              }}
              onSave={() => {
                onSaveOption(CONFIGURE_PLAN_OPTIONS.usageLimit);
                setExpandingOption(null);
              }}
              onExpandClose={() => {
                onSaveOption(CONFIGURE_PLAN_OPTIONS.usageLimit);
              }}
              saved={savedOptions[CONFIGURE_PLAN_OPTIONS.usageLimit]}
              saveButtonDisabled={manageUsageManually ? false : usageLimit === 0}
              isLastItem
            />
          )}
        </div>
      </div>
      <div className="vertical-divider" />
      <div className="plan-budget">
        <div className="payment-details">
          <h1>{translate("teamDashboard.planBudget.header")}</h1>
          {isInitialLoading ? (
            <Loader />
          ) : (
            <>
              <div className="selected-options">
                <h1>
                  <div className="budget-sub-header">
                    <p>{translate("teamDashboard.planBudget.computer.header")}</p>
                  </div>
                  <div className="budget-fee">
                    <span>${parseFloat(totalPrice - usagePrice).toFixed(2)}</span>
                    <p>{translate("teamDashboard.planBudget.computer.usage")}</p>
                  </div>
                </h1>
                <div className="machine-usage">
                  {translate("teamDashboard.planBudget.diskStorage")}
                  <span>{diskStorageSize} GB</span>
                </div>
                <div className="machine-usage">
                  {translate("teamDashboard.planBudget.filesStorage")} <span>{fileStorageSize} GB</span>
                </div>
              </div>
              {usagePrice !== 0 && (
                <div className="selected-options">
                  <h1>
                    <div className="budget-sub-header">
                      <p>{translate("teamDashboard.planBudget.performance.header")}</p>
                    </div>
                    <div className="budget-fee">
                      <span>${usagePrice?.toFixed(2)}</span>
                      <p>{translate("teamDashboard.planBudget.performance.usage")}</p>
                    </div>
                  </h1>

                  <div className="usages-list-table">
                    {usageList?.length === selectedMachineTypes.length &&
                      usageList?.map((usage, index) => {
                        const duration = moment.duration(usage.attributes.usage, "minutes");

                        const humanizedDuration = formatDuration(duration);

                        return (
                          <div className="machine-usage" key={usage.attributes.machine_type.id}>
                            <p className={classnames(["usage-name", index > 0 && "with-equal"])}>
                              {usage.attributes.machine_type.attributes.friendly_name}
                            </p>

                            <div className="budget-fee">
                              <span>{humanizedDuration}</span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {allOptionsSaved && (
          <div className="save-plan">
            <p>Plan Name</p>
            <div className="plan-name-input">
              <Input
                placeholder="Team Computer Plan"
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
                inputRef={planNameInputRef}
              />
              {hasError && (
                <HoverableTooltip content={hasError ? errorText : ""} side="right" open arrow>
                  <Icon name="info" small color="purple-main" />
                </HoverableTooltip>
              )}
            </div>
            <div className="proceed-payment-button">
              <Button
                block
                text={createComputers ? "Proceed to Payment" : "Save Plan"}
                onClick={() => {
                  onButtonClick({ ...planConfigs, name: planName });
                }}
                disabled={!planName || !allOptionsSaved}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigurePlan;
