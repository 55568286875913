import React, { useEffect, useMemo, useState } from "react";
import { SlideModal, DivButton, Switch, ExpandableTable, BoxInput } from "UI";
import MouseClickImage from "Assets/images/appStreaming/mouse-click.png";
import { KEY_MAPPING_OPTIONS } from "Constants/global.constants";
import TapImage from "Assets/images/appStreaming/tap.png";
import CompletionImage from "Assets/images/appStreaming/configuration-completed.png";
import { apiGenerator, classnames } from "Utils";
import CheckBox from "UI/CheckBox/CheckBox.component";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BG_STYLE_BY_APP_FRIENDLY_STATUS } from "Constants/AppStreaming.constants";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import MachineTypeSelection from "../../Configuration/MachineTypeSelection.component";

import "./AppBundleModal.styles.scss";

const APP_CONFIGURATION_STEPS = {
  app_selection: { name: "App Selection", step: 0 },
  performance: { name: "Performance", step: 1 },
  controls: { name: "Controls", step: 2 },
  complete: { name: "Ready", step: 3, disabled: true, showOnCurrentStep: true },
};

const SUBSCRIPTION_BUNDLE_LIMIT_MAPPING = {
  undefined: 2,
  pro: 5,
  enterprise: 25,
};

const APP_SELECTION_TABLE_COLUMNS = [
  { name: "Application", weight: 6 },
  { name: "Status", weight: 4 },
];

const AppBundleModal = ({
  appStreaming,
  onClose,
  getApplicationMachineTypesAPI,
  getApplicationsAPI,
  subscriptionType,
  translate,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [appName, setAppName] = useState("");
  const [selectedMachineType, setSelectedMachineType] = useState();
  const [keyMappingSelection, setKeyMappingSelection] = useState();
  const [canChangeKeyMapping, setCanChangeKeyMapping] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const { getApplicationMachineTypesCTX } = appStreaming;
  const machineTypes =
    getApplicationMachineTypesCTX.data?.pricing?.map((pricingItem) => {
      return { ...pricingItem.attributes.machine_type, ...{ price: pricingItem.attributes.price.toFixed(3) } };
    }) || [];

  const [selectedApplications, setSelectedApplications] = useState([]);

  const { data: applicationsData } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.BUNDLE_ABLE_APPLICATIONS],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.BUNDLE_ABLE_APPLICATIONS);
    },
  });

  const { applications } = applicationsData?.data || { applications: [] };

  const createBundledAppMutation = useMutation({
    mutationKey: [APP_STREAMING_API_ENDPOINTS.APPLICATIONS_CREATE_BUNDLED],
    mutationFn: (data) => {
      return apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.APPLICATIONS_CREATE_BUNDLED, data);
    },
    onSuccess: () => {
      setActiveTab(3);
      getApplicationsAPI();
    },
  });

  useEffect(() => {
    if (selectedApplications.length > 0) {
      getApplicationMachineTypesAPI(selectedApplications[0]);
    }
  }, [selectedApplications]);

  const appSelectionData = useMemo(() => {
    return applications.map((app) => {
      const appStatus = app.attributes.friendly_status;
      return {
        key: app.id,
        application: (
          <CheckBox
            label={app.attributes.name}
            checked={selectedApplications.includes(app.id)}
            onChange={(e) => {
              if (e.target.checked) setSelectedApplications([...selectedApplications, app.id]);
              else setSelectedApplications(selectedApplications.filter((id) => id !== app.id));
            }}
            disabled={
              selectedApplications.length >= SUBSCRIPTION_BUNDLE_LIMIT_MAPPING[subscriptionType] &&
              !selectedApplications.includes(app.id)
            }
          />
        ),
        status: (
          <>
            <div className="status" style={BG_STYLE_BY_APP_FRIENDLY_STATUS[appStatus]} />
            {translate(`appStreamingDashboard.appList.appFriendlyStatus.${appStatus}`)}
          </>
        ),
      };
    });
  }, [JSON.stringify(applications), JSON.stringify(selectedApplications)]);

  const tabs = [
    {
      key: "step0",
      showSidebar: true,
      disablePrevious: true,
      disabled: !appName || selectedApplications.length < 2,
      content: (
        <>
          <h1>{translate("appStreamingModals.appBundle.appSelection.header")}</h1>
          <p>{translate("appStreamingModals.appBundle.appSelection.description")}</p>

          <div className="app-bundle-modal-content">
            <div className="app-selection-table">
              <ExpandableTable data={appSelectionData} columns={APP_SELECTION_TABLE_COLUMNS} />
            </div>
            <div className="input-container">
              <h3>{translate("appStreamingModals.appBundle.appSelection.appName")}</h3>
              <BoxInput
                value={appName}
                onChange={(event) => setAppName(event.target.value)}
                color="purple-light"
                maxLength="18"
                placeholder="Application Name"
              />
            </div>
          </div>
        </>
      ),
    },
    {
      key: "step1",
      showSidebar: true,
      disabled: !selectedMachineType,
      content: (
        <>
          <h1>{translate("appStreamingModals.configuration.performance.header")}</h1>
          <p>{translate("appStreamingModals.configuration.performance.description")}</p>
          <div className="machine-type-cards-container">
            <MachineTypeSelection
              machineTypes={machineTypes}
              selectedMachineType={selectedMachineType}
              setSelectedMachineType={setSelectedMachineType}
              translate={translate}
            />
          </div>
          <p className="pricing-annotation">{translate("appStreamingModals.configuration.performance.info")}</p>
        </>
      ),
    },
    {
      key: "step2",
      showSidebar: true,
      disabled: !keyMappingSelection,
      content: (
        <>
          <h1>{translate("appStreamingModals.configuration.controls.header")}</h1>
          <p>{translate("appStreamingModals.configuration.controls.inputMode")}</p>
          <div className="key-mapping-options">
            <div className="row grid-2-columns">
              <DivButton
                className={classnames([
                  "key-mapping-option",
                  keyMappingSelection === KEY_MAPPING_OPTIONS.CLICK && "selected",
                ])}
                onClick={() => {
                  setKeyMappingSelection(KEY_MAPPING_OPTIONS.CLICK);
                }}
              >
                <img src={MouseClickImage} />
                <h5>{translate("appStreamingModals.configuration.keyMappingSelection.mouse.header")}</h5>
                <p>{translate("appStreamingModals.configuration.keyMappingSelection.mouse.description")}</p>
              </DivButton>
              <DivButton
                className={classnames([
                  "key-mapping-option",
                  keyMappingSelection === KEY_MAPPING_OPTIONS[360] && "selected",
                ])}
                onClick={() => {
                  setKeyMappingSelection(KEY_MAPPING_OPTIONS[360]);
                }}
              >
                <img src={TapImage} />
                <h5>{translate("appStreamingModals.configuration.keyMappingSelection.tap.header")}</h5>
                <p>{translate("appStreamingModals.configuration.keyMappingSelection.tap.description")}</p>
              </DivButton>
            </div>
            <Switch
              text={translate("appStreamingModals.configuration.keyMappingSelection.option")}
              color="aqua-main"
              checked={canChangeKeyMapping}
              onChange={(e) => setCanChangeKeyMapping(e)}
            />
          </div>
        </>
      ),
      onSubmit: () => {
        setSubmitted(true);
        const data = {
          name: appName,
          child_app_ids: selectedApplications,
          machine_type_id: selectedMachineType,
          key_mapping_selection: keyMappingSelection,
        };
        createBundledAppMutation.mutate(data);
      },
    },
    {
      disabled: !submitted,
      key: "step3",
      showSidebar: true,
      disablePrevious: true,
      content: (
        <div className="completion-step">
          <img src={CompletionImage} />
          <h1>{translate("appStreamingModals.appBundle.created.header")}</h1>
          <p>{translate("appStreamingModals.appBundle.created.description")}</p>
          <p>{translate("appStreamingModals.appBundle.created.annotation")}</p>
        </div>
      ),
    },
  ];

  return (
    <SlideModal
      className="app-bundle-modal"
      closeAction={onClose}
      confirmAction={() => {
        onClose();
      }}
      topRightIcon="close"
      contents={tabs}
      topRightIconAction={() => onClose()}
      translate={translate}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      pending={createBundledAppMutation.isLoading}
      steps={APP_CONFIGURATION_STEPS}
      currentStep={activeTab}
      big
    />
  );
};

export default AppBundleModal;
