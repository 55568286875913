import React, { useRef } from "react";

import { Icon, IconButton, TopLoader, ProgressBar, HoverableTooltip } from "UI";
import { shortenFileName, bytesToSize } from "Utils/Helpers/functions.helpers";
import { classnames } from "Utils";
import { FILE_STATUS } from "Constants/global.constants";

import "./FileStatus.styles.scss";

const isStatusLoading = (status) => status === FILE_STATUS.PENDING;

const FileStatus = ({
  fileName,
  fileNameLimit,
  fileSize,
  fileStatus,
  fileProgress,
  errorReason,
  error,
  onCancel,
  block,
  translate,
}) => {
  const toolTipRef = useRef(null);

  const isLoading = fileStatus === FILE_STATUS.PENDING;

  const isFailed = fileStatus === FILE_STATUS.FAILURE;

  const fileStatusClassName = classnames(["file-status", !isStatusLoading(fileStatus) && "complete", block && "block"]);

  const errorTooltipText = error && translate ? translate(`modals.fileUpload.error.${error}`) : errorReason;

  return (
    <div className={fileStatusClassName} key={fileName}>
      {!block &&
        (fileProgress > 0 ? (
          <ProgressBar progress={fileProgress} />
        ) : (
          isLoading && <TopLoader isInsideComponent light />
        ))}
      <div className="file-status-content">
        <Icon name="files-upload" color="black" />
        <div className="file-name">
          {shortenFileName(fileName, fileNameLimit)}
          <span>{bytesToSize(fileSize)}</span>
        </div>
        {block && (
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{
                width: `${fileProgress}%`,
              }}
            />
          </div>
        )}
        {!isLoading && !isFailed && (
          <>
            <Icon iconRef={toolTipRef} name="check" />
          </>
        )}
        {isFailed && errorTooltipText && (
          <HoverableTooltip content={errorTooltipText}>
            <Icon iconRef={toolTipRef} name="error" color="red" />
          </HoverableTooltip>
        )}
        {isFailed && !errorReason && <Icon iconRef={toolTipRef} name="error" color="red" />}
        {onCancel && (
          <IconButton
            name="close"
            onClick={() => {
              onCancel(fileName);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FileStatus;
