import React from "react";
import _ from "lodash";
import moment from "moment";

import { HistoryCard, HistoryTable } from "UI";
import DashboardSubScreenLayout from "Utils/Components/Layouts/DashboardSubScreenLayout/DashboardSubScreenLayout.component";

import { REGIONS_NAMES, REQUEST_STATUS } from "Constants/global.constants";

import NothingToShow from "Assets/images/vagon-nothing.png";

import { convertDateFormat, convertToSelectedTimeZone, normalizeCredits } from "Utils/Helpers/functions.helpers";
import { API_ENDPOINTS } from "Constants/api.constants";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import "./Usage.styles.scss";

const HEADERS = ["Date", "Computer", "Performance", "Region", "Duration", "Charge"];

const KEYS = ["date", "computer_name", "performance", "location", "usage", "balance"];

const generateData = (machineUsage, userTimeZone = null) =>
  _.map(machineUsage, (usage) => {
    const startedAt = usage.attributes.started_at;

    return {
      id: usage.id,
      date: startedAt
        ? convertToSelectedTimeZone(
            `${convertDateFormat(startedAt.substring(0, 10))} ${startedAt.substring(
              startedAt.indexOf("T") + 1,
              startedAt.indexOf("T") + 6,
            )}`,
            userTimeZone,
          )
        : "",
      computer_name: usage.attributes.description,
      performance: usage.attributes.machine_type.attributes.friendly_name,
      location: REGIONS_NAMES[usage.attributes.region],
      usage: moment.utc(usage.attributes.duration * 1000).format("HH:mm:ss"),
      balance: usage.attributes.team_id ? (
        <>
          {parseInt(usage.attributes.cost_in_credit, 10)} <span>credits</span>
        </>
      ) : (
        `$ ${parseFloat(usage.attributes.cost).toFixed(2)}`
      ),
    };
  });

const UsageComponent = (props) => {
  const { account, translate } = props;
  const { time_zone: userTimeZone } = account.account.attributes || {};
  const balance = account.account.attributes.balance || 0;
  const credit = account.account.attributes.credit || 0;
  const {
    data: usageDataInfinite,
    fetchNextPage: fetchNextPageUsages,
    isFetchingNextPage: isFetchingNextPageUsages,
    hasNextPage: hasNextPageUsages,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.MACHINE_USAGE_HISTORY,
    resource: "machine_usages",
  });
  const usageData = generateData(usageDataInfinite, userTimeZone);

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("usageHistory.title")}
      headerDescription={translate("usageHistory.description")}
      balance={!account.isTeamAccount && balance}
      credit={account.isTeamAccount && normalizeCredits(credit)}
      balanceTitle={translate("usageHistory.balanceTitle")}
      translate={translate}
      showBalance
    >
      {usageData?.length > 0 || account.machineUsageHistoryCTX.status === REQUEST_STATUS.PENDING ? (
        <>
          <div className="vg-usage-history-table">
            <HistoryTable
              headers={HEADERS}
              keys={KEYS}
              data={usageData}
              fetchNextPage={fetchNextPageUsages}
              isFetchingNextPage={isFetchingNextPageUsages}
              hasNextPage={hasNextPageUsages}
              session
            />
          </div>
          <div className="vg-usage-history-card">
            {usageData.map((usage) => (
              <HistoryCard key={usage.id} headers={HEADERS} keys={KEYS} item={usage} />
            ))}
          </div>
        </>
      ) : (
        <div className="vg-usage-empty">
          <img src={NothingToShow} alt="Nothing to show" className="stripe-image" />
          <h1>{translate("usageHistory.nothingToShow")}</h1>
          <p>{translate("usageHistory.useVagon")}</p>
        </div>
      )}
    </DashboardSubScreenLayout>
  );
};

export default UsageComponent;
