import React from "react";
import { connect, useDispatch } from "react-redux";
import { ConfirmationModal, TopLoader } from "UI";
import { REQUEST_STATUS } from "Constants/global.constants";
import { resendConfirmationEmailAPI } from "Actions/Account.actions";
import { logoutAPI } from "Actions/Authentication/Login.actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import routes from "Constants/Route.constants";

const UserNotVerifiedModal = (props) => {
  const { account, resendConfirmationEmailAPI, translate, history } = props;
  const { email } = account.account.attributes;
  const dispatch = useDispatch();

  return (
    <>
      {account.resendConfirmationEmailCTX.status === REQUEST_STATUS.PENDING && <TopLoader />}
      <ConfirmationModal
        headerText={
          account.resendConfirmationEmailCTX.status === REQUEST_STATUS.SUCCESS
            ? translate("emailVerification.header.sent")
            : translate("emailVerification.header.default")
        }
        descriptionText={translate("emailVerification.description", { email })}
        confirmText={translate("emailVerification.confirmText")}
        confirmAction={() => {
          if (account.resendConfirmationEmailCTX.status === REQUEST_STATUS.NOT_DEFINED) {
            resendConfirmationEmailAPI(account.accessToken);
          }
        }}
        disableConfirmButton={account.resendConfirmationEmailCTX.status !== REQUEST_STATUS.NOT_DEFINED}
        secondaryText={translate("emailVerification.secondaryText")}
        secondaryAction={() => {
          dispatch(logoutAPI(account.accessToken));
          history.push(routes.login);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const actionCreators = {
  resendConfirmationEmailAPI,
};

export default withRouter(connect(mapStateToProps, actionCreators)(UserNotVerifiedModal));
