import React from "react";
import _, { toInteger } from "lodash";
import "./PerformanceOptionsTable.styles.scss";

const PERFORMANCE_GROUPS = ["a10", "gpu", "cpu", "general"];

const TEXT_COLOR_BY_MACHINE_TYPE = {
  Spark: "#E08989",
  Flame: "#E08989",
  Blaze: "#E08989",
  Lava: "#E08989",
  Magma: "#E08989",
  Planet: "#4500A6",
  Star: "#4500A6",
  Galaxy: "#4500A6",
  Lake: "#45ABB5",
  Sea: "#45ABB5",
  Ocean: "#45ABB5",
  Sand: "#4F4F4F",
};

const TEXT_COLOR_BY_MACHINE_GROUP = {
  a10: "#E08989",
  gpu: "#4500A6",
  cpu: "#45ABB5",
  general: "#4F4F4F",
};

const PerformanceOptionsTable = ({ selectedMachineTypes, setSelectedMachineTypes, groupedMachineTypes, translate }) => {
  return (
    <div className="performance-options-table-container">
      {PERFORMANCE_GROUPS.map((group) => {
        const machineType = groupedMachineTypes[group];
        return (
          <div className="gpu-accelerated-performances" key={group}>
            <p className="performance-header" style={{ color: TEXT_COLOR_BY_MACHINE_GROUP[group] }}>
              {translate(`organizationPlans.configurePlan.performanceTypes.${group}`)}
            </p>
            {_.map(machineType, (item) => {
              const { id } = item;
              const { friendly_name: friendlyName, memory, gpu, cpu, gpu_memory: gpuMemory } = item.attributes;

              return (
                <React.Fragment key={friendlyName}>
                  <div className="machine-type">
                    <div className="row">
                      <div className="cell" style={{ color: TEXT_COLOR_BY_MACHINE_TYPE[friendlyName] }}>
                        {friendlyName}
                      </div>
                      <div className="cell">
                        <p>{cpu}</p>
                        <p>Cores</p>
                      </div>
                      <div className="cell">
                        <p>{toInteger(memory)}GB</p>
                        <p>Ram</p>
                      </div>
                      <div className="cell">
                        {gpu > 0 && (
                          <>
                            <p>{gpu > 1 ? `${gpu} x ${toInteger(gpuMemory)}GB` : `${toInteger(gpuMemory)}GB`}</p>
                            <p>GPU</p>
                          </>
                        )}
                      </div>
                      <div className="cell">
                        <input
                          type="checkbox"
                          checked={selectedMachineTypes.includes(id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedMachineTypes([...selectedMachineTypes, id]);
                            } else {
                              setSelectedMachineTypes(selectedMachineTypes.filter((item) => item !== id));
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="horizontal-divider" />
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default PerformanceOptionsTable;
