import { all, put, takeLatest, takeEvery } from "redux-saga/effects";

import apiGenerator from "Utils/Helpers/api.helpers";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";

import * as AppStreamingConstants from "Constants/AppStreaming.constants";
import { APP_STREAMING_API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";

function* getApplicationsAPISaga() {
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.APPLICATIONS, {
    params: {
      per_page: 100,
    },
  });
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_APPLICATIONS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_APPLICATIONS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_APPLICATIONS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getApplicationsStatsAPISaga(action) {
  const { appID } = action.payload;

  let api;
  if (appID) api = apiGenerator("get")(`${APP_STREAMING_API_ENDPOINTS.APPLICATIONS_STATS}?application_id=${appID}`);
  else api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.APPLICATIONS_STATS);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_APPLICATIONS_STATS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_APPLICATIONS_STATS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_APPLICATIONS_STATS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getApplicationAPISaga(action) {
  const { appID } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.APPLICATION(appID));

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_APPLICATION_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_APPLICATION_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_APPLICATION_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getApplicationStatusAPISaga(action) {
  const { appID } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.APPLICATION_STATUS(appID));

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_APPLICATION_STATUS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_APPLICATION_STATUS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_APPLICATION_STATUS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getVendorApplicationsStatsAPISaga(action) {
  const { startAt, endAt } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_VENDOR_APPLICATIONS_STATS, {
    params: { start_at: startAt, end_at: endAt },
  });

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_APPLICATIONS_STATS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_APPLICATIONS_STATS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_VENDOR_APPLICATIONS_STATS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getMachineStatsAPISaga(action) {
  const { startAt, endAt } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_MACHINE_STATS, {
    params: { start_date: startAt, end_date: endAt },
  });

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_MACHINE_STATS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_MACHINE_STATS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_MACHINE_STATS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getApplicationImagesAPISaga(action) {
  const { appID } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_APPLICATION_IMAGES(appID));

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_APPLICATION_IMAGES_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_APPLICATION_IMAGES_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_APPLICATION_IMAGES_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getApplicationMachineTypesAPISaga(action) {
  const { appID } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_APPLICATION_MACHINE_TYPES(appID));

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_APPLICATION_MACHINE_TYPES_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_APPLICATION_MACHINE_TYPES_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_APPLICATION_MACHINE_TYPES_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getCustomerSessionStatsSaga(action) {
  const { appID } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.CUSTOMER_SESSION_STATS(appID));

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_CUSTOMER_SESSION_STATS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_CUSTOMER_SESSION_STATS_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_CUSTOMER_SESSION_STATS_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* testApplicationSaga(action) {
  const { appID, region } = action.payload;

  const api = apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.TEST_APP(appID), {
    region,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.TEST_APPLICATION_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: AppStreamingConstants.TEST_APPLICATION_FAILURE,
      payload: error,
    });
  }
}

function* getVendorAccountAPISaga() {
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.VENDOR_ACCOUNT);
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_ACCOUNT_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_ACCOUNT_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_VENDOR_ACCOUNT_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getVendorChargeDetailsAPISaga(action) {
  const { startAt, endAt, transactionCategory, detailLevel } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_VENDOR_CHARGE_DETAILS, {
    params: { start_at: startAt, end_at: endAt, transaction_category: transactionCategory, detail_level: detailLevel },
  });
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_CHARGE_DETAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_CHARGE_DETAILS_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_VENDOR_CHARGE_DETAILS_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getVendorBalanceStatsAPISaga(action) {
  const { startAt, endAt } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_VENDOR_BALANCE_STATS, {
    params: { start_at: startAt, end_at: endAt },
  });
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_BALANCE_STATS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_BALANCE_STATS_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_VENDOR_BALANCE_STATS_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getVendorPaymentsAPISaga(action) {
  const { startAt, endAt, paymentType } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_VENDOR_PAYMENTS, {
    params: { start_at: startAt, end_at: endAt, payment_type: paymentType },
  });
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_PAYMENTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_VENDOR_PAYMENTS_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_VENDOR_PAYMENTS_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getCustomerStatsAPISaga(action) {
  const { startAt, endAt } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_CUSTOMER_STATS, {
    params: { start_at: startAt, end_at: endAt },
  });
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_CUSTOMER_STATS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_CUSTOMER_STATS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_CUSTOMER_STATS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getCurrentPlanAPISaga() {
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_CURRENT_PLAN);
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_CURRENT_PLAN_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_CURRENT_PLAN_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_CURRENT_PLAN_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* createStreamAPISaga(action) {
  const { appID, capacities } = action.payload;

  const api = apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.CREATE_STREAM, {
    application_id: appID,
    capacities,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.CREATE_STREAM_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.CREATE_STREAM_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.CREATE_STREAM_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getStreamsAPISaga() {
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_ALL_STREAMS);
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_STREAMS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_STREAMS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_STREAMS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getStreamAPISaga(action) {
  const { streamID } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_STREAM(streamID));

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_STREAM_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_STREAM_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_STREAM_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getStreamCapacitiesAPISaga(action) {
  const { streamID } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_STREAM_CAPACITIES(streamID));
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_STREAM_CAPACITIES_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_STREAM_CAPACITIES_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_STREAM_CAPACITIES_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getAppCapacitiesAPISaga(action) {
  const { appID } = action.payload;
  const api = apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_APPLICATION_CAPACITIES(appID));
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.GET_APP_CAPACITIES_API_SUCCESS,
        payload: { [appID]: response.data?.capacities.map((capacity) => capacity.attributes) },
      });
    } else {
      yield put({
        type: AppStreamingConstants.GET_APP_CAPACITIES_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.GET_APP_CAPACITIES_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* pauseStreamAPISaga(action) {
  const { streamID } = action.payload;
  const api = apiGenerator("put")(APP_STREAMING_API_ENDPOINTS.PAUSE_STREAM(streamID));
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.PAUSE_STREAM_API_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: AppStreamingConstants.GET_STREAMS_API_PENDING,
      });
    } else {
      yield put({
        type: AppStreamingConstants.PAUSE_STREAM_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.PAUSE_STREAM_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* updateStreamAPISaga(action) {
  const { streamID, updateType, data } = action.payload;
  const UPDATE_STREAM_ENDPOINTS = {
    start: APP_STREAMING_API_ENDPOINTS.START_STREAM,
    update: APP_STREAMING_API_ENDPOINTS.UPDATE_STREAM,
    pause: APP_STREAMING_API_ENDPOINTS.PAUSE_STREAM,
  };
  const api = apiGenerator("put")(UPDATE_STREAM_ENDPOINTS[updateType](streamID), data);
  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.UPDATE_STREAM_API_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: AppStreamingConstants.GET_STREAMS_API_PENDING,
      });
    } else {
      yield put({
        type: AppStreamingConstants.UPDATE_STREAM_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AppStreamingConstants.UPDATE_STREAM_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* capacityWarningConsentSaga() {
  const api = apiGenerator("put")(APP_STREAMING_API_ENDPOINTS.CAPACITY_WARNING_CONSENT, {
    capacity_warning_consent: true,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AppStreamingConstants.CAPACITY_WARNING_CONSENT_SUCCESS,
      });
    }
  } catch {
    // do nothing
  }
}

export default function* root() {
  yield all([
    takeLatest(AppStreamingConstants.GET_APPLICATIONS_API_PENDING, getApplicationsAPISaga),
    takeLatest(AppStreamingConstants.GET_APPLICATIONS_STATS_API_PENDING, getApplicationsStatsAPISaga),
    takeLatest(AppStreamingConstants.GET_APPLICATION_API_PENDING, getApplicationAPISaga),
    takeEvery(AppStreamingConstants.GET_APPLICATION_STATUS_API_PENDING, getApplicationStatusAPISaga),
    takeLatest(AppStreamingConstants.GET_VENDOR_APPLICATIONS_STATS_API_PENDING, getVendorApplicationsStatsAPISaga),
    takeLatest(AppStreamingConstants.GET_MACHINE_STATS_API_PENDING, getMachineStatsAPISaga),
    takeLatest(AppStreamingConstants.GET_APPLICATION_IMAGES_API_PENDING, getApplicationImagesAPISaga),
    takeLatest(AppStreamingConstants.GET_APPLICATION_MACHINE_TYPES_API_PENDING, getApplicationMachineTypesAPISaga),
    takeLatest(AppStreamingConstants.GET_CUSTOMER_SESSION_STATS_PENDING, getCustomerSessionStatsSaga),
    takeLatest(AppStreamingConstants.TEST_APPLICATION_PENDING, testApplicationSaga),
    takeLatest(AppStreamingConstants.GET_VENDOR_ACCOUNT_API_PENDING, getVendorAccountAPISaga),
    takeLatest(AppStreamingConstants.GET_VENDOR_CHARGE_DETAILS_PENDING, getVendorChargeDetailsAPISaga),
    takeLatest(AppStreamingConstants.GET_VENDOR_BALANCE_STATS_PENDING, getVendorBalanceStatsAPISaga),
    takeLatest(AppStreamingConstants.GET_VENDOR_PAYMENTS_PENDING, getVendorPaymentsAPISaga),
    takeLatest(AppStreamingConstants.GET_CUSTOMER_STATS_API_PENDING, getCustomerStatsAPISaga),
    takeLatest(AppStreamingConstants.GET_CURRENT_PLAN_API_PENDING, getCurrentPlanAPISaga),
    takeLatest(AppStreamingConstants.CREATE_STREAM_API_PENDING, createStreamAPISaga),
    takeLatest(AppStreamingConstants.GET_STREAMS_API_PENDING, getStreamsAPISaga),
    takeLatest(AppStreamingConstants.GET_STREAM_CAPACITIES_API_PENDING, getStreamCapacitiesAPISaga),
    takeLatest(AppStreamingConstants.GET_APP_CAPACITIES_API_PENDING, getAppCapacitiesAPISaga),
    takeLatest(AppStreamingConstants.PAUSE_STREAM_API_PENDING, pauseStreamAPISaga),
    takeLatest(AppStreamingConstants.UPDATE_STREAM_API_PENDING, updateStreamAPISaga),
    takeLatest(AppStreamingConstants.GET_STREAM_API_PENDING, getStreamAPISaga),
    takeLatest(AppStreamingConstants.CAPACITY_WARNING_CONSENT_PENDING, capacityWarningConsentSaga),
  ]);
}
