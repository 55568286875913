import React from "react";
import { connect } from "react-redux";
import { getApplicationsAPI } from "Actions/AppStreaming.actions";
import AppStreamingUsers from "Components/AppStreaming/Users/AppStreamingUsers.component";
import { getProductOnboardingAPI } from "Actions/Account.actions";

class AppStreamingUsersContainer extends React.Component {
  componentDidMount() {
    const { getApplicationsAPI } = this.props;

    getApplicationsAPI();
    getProductOnboardingAPI();
  }

  render() {
    return <AppStreamingUsers {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  appStreaming: state.appStreaming,
});

const actionCreators = { getApplicationsAPI, getProductOnboardingAPI };

export default connect(mapStateToProps, actionCreators)(AppStreamingUsersContainer);
