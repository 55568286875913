export const ORGANIZATIONS_CLEAR = "ORGANIZATIONS_CLEAR";

export const ORGANIZATION_REGISTER_API_PENDING = "ORGANIZATION_REGISTER_API_PENDING";
export const ORGANIZATION_REGISTER_API_SUCCESS = "ORGANIZATION_REGISTER_API_SUCCESS";
export const ORGANIZATION_REGISTER_API_FAILURE = "ORGANIZATION_REGISTER_API_FAILURE";

export const GET_ORGANIZATION_API_PENDING = "GET_ORGANIZATION_API_PENDING";
export const GET_ORGANIZATION_API_SUCCESS = "GET_ORGANIZATION_API_SUCCESS";
export const GET_ORGANIZATION_API_FAILURE = "GET_ORGANIZATION_API_FAILURE";

export const GET_ORGANIZATION_PLAN_API_PENDING = "GET_ORGANIZATION_PLAN_API_PENDING";
export const GET_ORGANIZATION_PLAN_API_SUCCESS = "GET_ORGANIZATION_PLAN_API_SUCCESS";
export const GET_ORGANIZATION_PLAN_API_FAILURE = "GET_ORGANIZATION_PLAN_API_FAILURE";

export const CREATE_ORGANIZATION_SEATS_API_PENDING = "CREATE_ORGANIZATION_SEAT_API_PENDING";
export const CREATE_ORGANIZATION_SEATS_API_SUCCESS = "CREATE_ORGANIZATION_SEAT_API_SUCCESS";
export const CREATE_ORGANIZATION_SEATS_API_FAILURE = "CREATE_ORGANIZATION_SEAT_API_FAILURE";
export const RESET_CREATE_ORGANIZATION_SEATS_API = "RESET_CREATE_ORGANIZATION_SEAT_API";

export const GET_SEATS_ME_API_PENDING = "GET_SEATS_ME_API_PENDING";
export const GET_SEATS_ME_API_SUCCESS = "GET_SEATS_ME_API_SUCCESS";
export const GET_SEATS_ME_API_FAILURE = "GET_SEATS_ME_API_FAILURE";

export const SET_CREATE_COMPUTERS_CTX = "SET_CREATE_COMPUTERS_CTX";
export const RESET_CREATE_COMPUTERS_CTX = "RESET_CREATE_COMPUTERS_CTX";

export const SET_CREATE_PLAN_CTX = "SET_CREATE_PLAN_CTX";

export const ORGANIZATION_USER_TYPES = {
  owner: "owner",
  admin: "admin",
  member: "member",
};

export const ORGANIZATION_OBJECTS_STATUSES = {
  personalComputer: "personal-computer",
  computerRequired: "computer-required",
};

export const ORGANIZATION_SEAT_STATUSES = {
  active: "active",
  paymentRequired: "payment_required",
};

export const ORGANIZATION_SILVER_IMAGE_SOURCES = {
  seat: "seat",
  pre_installation: "pre_installation",
};

export const BILLING_TABS = {
  PAYMENTS: 0,
  TRANSACTIONS: 1,
  NETWORK: 2,
};
