import { REQUEST_STATUS } from "Constants/global.constants";
import * as OrganizationConstants from "../Constants/Organization.constants";

const initialState = {
  organization: {},
  getOrganizationCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  getOrganizationPlanCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  getSeatsMeCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  createComputersCTX: {},
  createPlanCTX: {},
  createOrganizationSeatsCTX: {},
};

export default function organizations(state = initialState, action) {
  switch (action.type) {
    case OrganizationConstants.ORGANIZATIONS_CLEAR:
      return initialState;
    case OrganizationConstants.GET_ORGANIZATION_API_PENDING:
      return getOrganizationAPIPending(state);
    case OrganizationConstants.GET_ORGANIZATION_API_SUCCESS:
      return getOrganizationAPISuccess(state, action);
    case OrganizationConstants.GET_ORGANIZATION_API_FAILURE:
      return getOrganizationAPIFailure(state, action);
    case OrganizationConstants.GET_ORGANIZATION_PLAN_API_PENDING:
      return getOrganizationPlanAPIPending(state);
    case OrganizationConstants.GET_ORGANIZATION_PLAN_API_SUCCESS:
      return getOrganizationPlanAPISuccess(state, action);
    case OrganizationConstants.GET_ORGANIZATION_PLAN_API_FAILURE:
      return getOrganizationPlanAPIFailure(state, action);
    case OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_PENDING:
      return createOrganizationSeatsAPIPending(state);
    case OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_SUCCESS:
      return createOrganizationSeatsAPISuccess(state, action);
    case OrganizationConstants.CREATE_ORGANIZATION_SEATS_API_FAILURE:
      return createOrganizationSeatsAPIFailure(state, action);
    case OrganizationConstants.RESET_CREATE_ORGANIZATION_SEATS_API:
      return resetCreateOrganizationSeatsAPI(state);

    case OrganizationConstants.GET_SEATS_ME_API_PENDING:
      return getSeatsMeAPIPending(state);
    case OrganizationConstants.GET_SEATS_ME_API_SUCCESS:
      return getSeatsMeAPISuccess(state, action);
    case OrganizationConstants.GET_SEATS_ME_API_FAILURE:
      return getSeatsMeAPIFailure(state, action);
    case OrganizationConstants.SET_CREATE_COMPUTERS_CTX:
      return setCreateComputersCTX(state, action);
    case OrganizationConstants.RESET_CREATE_COMPUTERS_CTX:
      return resetCreateComputersCTX(state);
    case OrganizationConstants.SET_CREATE_PLAN_CTX:
      return setCreatePlanCTX(state, action);
    default:
      return state;
  }
}

function setCreateComputersCTX(state, action) {
  return {
    ...state,
    createComputersCTX: {
      ...state.createComputersCTX,
      ...action.payload,
    },
  };
}

function resetCreateComputersCTX(state) {
  return {
    ...state,
    createComputersCTX: {},
  };
}

function setCreatePlanCTX(state, action) {
  return {
    ...state,
    createPlanCTX: {
      ...action.payload,
    },
  };
}

function getOrganizationAPIPending(state) {
  return {
    ...state,
    getOrganizationCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getOrganizationAPISuccess(state, action) {
  const { organization, accessType } = action.payload;

  return {
    ...state,
    getOrganizationCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
    organization: { ...state.organization, ...organization },
    accessType,
  };
}

function getOrganizationAPIFailure(state, action) {
  return {
    ...state,
    getOrganizationCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: action.payload,
    },
  };
}

function getOrganizationPlanAPIPending(state) {
  return {
    ...state,
    getOrganizationPlanCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getOrganizationPlanAPISuccess(state, action) {
  const { organizationPlan } = action.payload;

  return {
    ...state,
    getOrganizationPlanCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      organizationPlan,
    },
  };
}

function getOrganizationPlanAPIFailure(state, action) {
  return {
    ...state,
    getOrganizationPlanCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: action.payload,
    },
  };
}

function createOrganizationSeatsAPIPending(state) {
  return {
    ...state,
    createOrganizationSeatsCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function createOrganizationSeatsAPISuccess(state, action) {
  return {
    ...state,
    createOrganizationSeatsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function createOrganizationSeatsAPIFailure(state, action) {
  return {
    ...state,
    createOrganizationSeatsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: action.payload,
    },
  };
}

function resetCreateOrganizationSeatsAPI(state) {
  return {
    ...state,
    createOrganizationSeatsCTX: initialState.createOrganizationSeatsCTX,
  };
}

function getSeatsMeAPIPending(state) {
  return {
    ...state,
    getSeatsMeCTX: {
      ...state.getSeatsMeCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getSeatsMeAPISuccess(state, action) {
  return {
    ...state,
    getSeatsMeCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      seat: action.payload,
    },
  };
}

function getSeatsMeAPIFailure(state, action) {
  return {
    ...state,
    getSeatsMeCTX: {
      data: null,
      status: REQUEST_STATUS.FAILURE,
      error: action.payload,
    },
  };
}
