import React, { useEffect } from "react";
import _ from "lodash";
import { Translate } from "react-localize-redux";
import { Icon, HoverableTooltip, IconButton, ProgressBar } from "UI";
import { relativeTime, classnames } from "Utils";
import useSimpleAndDoubleClick from "Utils/Hooks/useSimpleAndDoubleClick.hook";
import { shortenFileName, bytesToSize } from "Utils/Helpers/functions.helpers";
import { FILE_STATUS, FILE_TYPES } from "Constants/global.constants";
import { useSelector } from "react-redux";
import "./FileManagerTable.styles.scss";

const HEADERS = [
  "Name",
  "Size",
  "Last modification",
  <>
    <p>Last sync</p>
    <HoverableTooltip content="The transfer will start when you run your computer">
      <IconButton className="left-action-button" name="info" color="gray-2" />
    </HoverableTooltip>
  </>,
];
const KEYS = ["name", "size", "lastModification", "lastSync"];

const getFileTypeIcon = (objectType, type, white, synced, sync) => {
  let iconName = "file";
  if (objectType === "root") iconName = "shared-folder";
  else if (type === "directory") iconName = "folder";
  else if (type.includes("video")) iconName = "file-video";
  else if (type.includes("image")) iconName = "file-img";
  else if (type.includes("zip")) iconName = "file-zip";
  else if (type.includes("rar")) iconName = "file-zip";
  else if (type.includes("7z")) iconName = "file-zip";

  if (white) {
    if (sync) {
      iconName = "file-arrow-left";
    } else if (!synced && objectType !== "root" && type !== "directory") {
      iconName += "-white";
    }
  }

  return <Icon name={iconName} className="row-icon" />;
};

const getFileActions = (sync, onDownload, translate) => {
  if (sync) {
    return (
      <>
        <ProgressBar progress={100} />
        <Icon name="display" color="gray-4-stroke" />
      </>
    );
  }
  return (
    <HoverableTooltip content={translate("fileManager.download.tooltip")}>
      <IconButton name="file-download" onClick={onDownload} color="gray-2" />
    </HoverableTooltip>
  );
};

const getFileData = ({
  file,
  translate,
  onDownload,
  white,
  minified,
  moreButton,
  selectedFolderItems,
  singleSelection,
  isWorkstationDock,
  userTimeZone,
}) => {
  if (!file) return null;
  const TypeIcon = getFileTypeIcon(
    file.attributes.object_type,
    file.attributes.content_type,
    white,
    file.attributes.last_sync_date,
    file.attributes.sync,
  );

  const lastModificationDate = file.attributes.last_modified_date;

  const size = () => {
    if (file.attributes.size === 0) {
      return "-";
    }
    return bytesToSize(file.attributes.size) || "";
  };

  return {
    id: file.id,
    type: file.attributes.object_type,
    contentType: file.attributes.content_type,
    fileName: file.attributes.name,
    fileSize: file.attributes.size,
    name: (
      <>
        {selectedFolderItems?.length > 0 && !isWorkstationDock && !singleSelection && (
          <div className={classnames(["hovered-checkbox", "show-checkbox"])}>
            <input type="checkbox" checked={selectedFolderItems?.includes(file.id)} />
          </div>
        )}
        {TypeIcon}
        <span className="fileNameContainer">{shortenFileName(file.attributes.name, minified ? 20 : 50)}</span>
      </>
    ),
    size: size(),
    // lastModification: lastModificationDate ? moment(lastModificationDate).format("DD/MM/YYYY") : "",
    lastModification: lastModificationDate ? relativeTime(lastModificationDate, userTimeZone) : "",
    actions: getFileActions(file.attributes.sync, onDownload, translate),
    more: moreButton,
  };
};

const getUploadFileData = (file, translate) => {
  if (file.status === FILE_STATUS.SUCCESS) return null;

  let status;
  if (file.status === FILE_STATUS.FAILURE) {
    const errorReason = (
      <Translate
        id={`modals.fileUpload.error.${file.error}`}
        options={{ onMissingTranslation: () => translate("modals.fileUpload.error.default") }}
      />
    );
    status = (
      <HoverableTooltip content={errorReason}>
        <Icon name="error" color="red" />
      </HoverableTooltip>
    );
  } else {
    status = (
      <>
        <span className="baby-blue">%{Math.round(file.progress)}</span>
        <ProgressBar progress={file.progress} />
        <Icon name="cloud" />
      </>
    );
  }

  return {
    id: file.name,
    name: (
      <>
        <Icon name="file-upload" className="row-icon" /> {shortenFileName(file.name, 15)}
      </>
    ),
    size: file.size,
    status,
  };
};

const FileManagerTable = (props) => {
  const {
    contents,
    upperContents,
    headers = HEADERS,
    keys = KEYS,
    selectedKeys,
    upperKeys,
    selectedRowID,
    setSelectedItem,
    selectedFolderItems,
    setSelectedFolderItems,
    goToFolder,
    onRightClick,
    moreButton,
    onDownload,
    disabledFileFilter,
    minified,
    white,
    singleClick,
    isWorkstationDock,
    singleSelection,
    translate,
  } = props;

  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};

  useEffect(() => {
    if (selectedRowID && setSelectedItem) {
      const file = _.pickBy(contents, (file) => file.id === selectedRowID);
      setSelectedItem(getFileData({ file: file[Object.keys(file)[0]], userTimeZone, translate }));
    }
  }, [contents]);

  const handleClick = useSimpleAndDoubleClick(
    (item) => {
      if (singleSelection) {
        if (selectedFolderItems?.[0] === item.id) setSelectedFolderItems([]);
        else setSelectedFolderItems([item.id]);
      } else {
        const newSelectedItems = _.xor(selectedFolderItems || [], [item.id]);
        setSelectedFolderItems(newSelectedItems);
      }
    },
    (item, type) => {
      if (type !== FILE_TYPES.file) {
        goToFolder(item?.id);
      }
    },
  );

  const handleSingleClick = (item, type) => {
    if (type !== FILE_TYPES.file) {
      goToFolder(item.id);
    }
  };

  const rowData = (tableRowData, rowKeys, selectedRowKeys) => {
    const disabledRow = disabledFileFilter && disabledFileFilter(tableRowData);
    const isSelectedRow = selectedFolderItems?.includes(tableRowData.id);
    const activeKeys = (isSelectedRow && selectedRowKeys) || rowKeys;
    const rowClassName = ["table-body-row", isSelectedRow && "selected-table-body-row", disabledRow && "disabled"]
      .filter(Boolean)
      .join(" ");
    return (
      <div
        key={tableRowData.id}
        className={rowClassName}
        onClick={() => {
          if (!isWorkstationDock && !disabledRow && singleClick) handleSingleClick(tableRowData, tableRowData.type);
          else handleClick(tableRowData, tableRowData.type);
        }}
        onContextMenu={(event) => {
          if (onRightClick) onRightClick(event, tableRowData);
        }}
        tabIndex={0}
        role="button"
        onKeyUp={(event) => handleEnterKeyUp(event, tableRowData)}
        title={tableRowData.fileName}
      >
        {activeKeys.map((key, index) => (
          <div
            key={key}
            className={
              activeKeys.length === 2 && index === 1 ? "table-body-column last-column-dock" : "table-body-column"
            }
          >
            <div className="cell-content">{tableRowData[key] || "-"}</div>
          </div>
        ))}
      </div>
    );
  };
  const upperTableData = () => {
    return _.map(upperContents, (content) => {
      const tableRowData = getUploadFileData(content, translate);
      if (!tableRowData) return null;

      return rowData(tableRowData, upperKeys);
    });
  };

  const tableData = () => {
    return _.map(contents, (content) => {
      let tableRowData = getFileData({
        file: content,
        translate,
        onDownload,
        white,
        minified,
        selectedFolderItems,
        setSelectedFolderItems,
        singleSelection,
        isWorkstationDock,
        userTimeZone,
      });
      if (moreButton) {
        tableRowData = getFileData({
          file: content,
          translate,
          selectedRowID,
          onDownload,
          white,
          minified,
          moreButton: moreButton(tableRowData.id, tableRowData),
          singleSelection,
          isWorkstationDock,
          userTimeZone,
        });
      }
      return rowData(tableRowData, keys, selectedKeys);
    });
  };

  const handleEnterKeyUp = (event, item) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      if (singleSelection) {
        setSelectedItem(item);
      } else {
        const newSelectedItems = _.xor(selectedFolderItems || [], [item.id]);
        setSelectedFolderItems(newSelectedItems);
      }
    }
  };

  return (
    <div className="file-manager-table">
      <div className="table-wrapper">
        <div className="table-header">
          {headers.map((header) => (
            <div key={header} className="table-header-column">
              <div className="cell-content">{header}</div>
            </div>
          ))}
        </div>
        <div className="table-body">
          {upperTableData()}
          {tableData()}
        </div>
      </div>
    </div>
  );
};

export default FileManagerTable;
