import React, { useState } from "react";
import { DivButton, IconButton, TextButton } from "UI";
import { classnames, Loader, useAPIRequest } from "Utils";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import StreamImage from "Assets/images/appStreaming/stream-created.png";

import "./StreamLink.styles.scss";

const StreamLink = ({ translate, onEdit, stream, isPending, appID, config }) => {
  const [activeTab, setActiveTab] = useState(0);

  const { resolution, capacities, parent_stream_id: parentStreamID } = stream.attributes;
  const runMethod = capacities[0]?.attributes?.capacity_type;
  const readableRunMethod = translate(`capacityType.${runMethod}`);
  const readableResolution = translate(`resolution.${resolution}`);

  const { data: apiSecretsData, isAPISecretsLoading } = useAPIRequest({
    endpoint: APP_STREAMING_API_ENDPOINTS.SECRETS,
  });

  const link = (() => {
    const webLink = stream.attributes.connection_link;
    const gameModeEnabled = stream.attributes.key_mapping_selection === "game_mode";
    const iframeLabel = `<iframe src="${webLink}"/>`;
    /* eslint-disable */
    const iframeValue = `<iframe id="vagonFrame" allow="microphone  *; clipboard-read *; clipboard-write *; encrypted-media *;" src="${webLink} "${
      gameModeEnabled ? 'sandbox="allow-pointer-lock"' : ""
    }/>`;
    /* eslint-enable */
    if (activeTab === 0) return { label: webLink, value: webLink };
    else if (activeTab === 1) return { label: iframeLabel, value: iframeValue };

    const manuelLink = { apiKey: apiSecretsData?.id, secretKey: apiSecretsData?.attributes?.secret, appID };
    return manuelLink;
  })();

  if (isPending || isAPISecretsLoading) return <Loader />;

  return (
    <>
      <div className="generated-link-container">
        <img src={StreamImage} />
        <h1>{config ? translate("streamLink.header.configure") : translate("streamLink.header.create")}</h1>
        <div className="tabs">
          <DivButton className={classnames(["tab", activeTab === 0 && "active"])} onClick={() => setActiveTab(0)}>
            {translate("streamLink.options.web")}
          </DivButton>
          <DivButton className={classnames(["tab", activeTab === 1 && "active"])} onClick={() => setActiveTab(1)}>
            {translate("streamLink.options.iframe")}
          </DivButton>
          <DivButton className={classnames(["tab", activeTab === 2 && "active"])} onClick={() => setActiveTab(2)}>
            {translate("streamLink.options.manuel.header")}
          </DivButton>
        </div>
        <div className={classnames(["link-container", activeTab === 2 && "block"])}>
          {activeTab === 2 ? (
            Object.keys(link).map((item, index) => {
              return (
                <React.Fragment key={item}>
                  <div className="link-line">
                    <p>
                      <span>{translate(`streamLink.options.manuel.${item}`)}</span> {link[item]}
                    </p>
                    <IconButton
                      name="copy"
                      color="aqua-main"
                      smaller
                      onClick={() => {
                        navigator.clipboard.writeText(link[item]);
                      }}
                    />
                  </div>
                  {index !== Object.keys(link).length - 1 && <div className="horizontal-divider" />}
                </React.Fragment>
              );
            })
          ) : (
            <>
              <p>{link.label}</p>
              <IconButton
                name="copy"
                color="aqua-main"
                onClick={() => {
                  navigator.clipboard.writeText(link.value);
                }}
                smaller
              />
            </>
          )}
        </div>

        {!parentStreamID && (
          <>
            <h3 style={{ fontWeight: 600, margin: "0 auto" }}>{translate("streamLink.configuration.header")}</h3>
            <p>
              {translate("streamLink.configuration.description", {
                quality: readableResolution,
                runMethod: readableRunMethod,
              })}
              <TextButton
                className="inline-button"
                onClick={onEdit}
                text={translate("streamLink.configuration.action")}
                color="aqua-main"
              />
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default StreamLink;
