import _ from "lodash";
import { BlobReader, BlobWriter, ZipWriter } from "@zip.js/zip.js";
import { MB_TO_BYTE } from "./functions.helpers";

export const canUploadFiles = (files) => {
  const doesContainFolder = _.findKey(files, (file) => file.type === "" && !file.name.includes("."));
  return !doesContainFolder;
};

// Check file sizes, sizeLimit is in MB
export const isFileSizesAllowed = (files, sizeLimit = 2) => {
  return !_.findKey(files, (file) => file.size > MB_TO_BYTE * sizeLimit);
};

export const containsUnAllowedFileTypes = (files, types) => {
  return !!_.findKey(
    files,
    (file) =>
      _.findIndex(types, (type) => {
        return file.type.includes(type);
      }) === -1,
  );
};

export const zipsContainsFileTypes = (zipFiles, types) => {
  return !_.findKey(
    zipFiles,
    (files) =>
      _.findIndex(types, (type) => {
        return _.findIndex(files, (f) => f.includes(type)) !== -1;
      }) === -1,
  );
};

export const firstFileInType = (files, type) => {
  return _.find(files, (file) => file.includes(type));
};

export const containsEmptyFile = (files) => {
  const doesContainEmptyFile = _.findKey(files, (file) => file.size === 0);
  return doesContainEmptyFile;
};

export const convertCSVFileAndDownload = async (files, zipName) => {
  const zipWriter = new ZipWriter(new BlobWriter("application/zip"));

  const promises = files
    .filter((file) => file?.data?.length > 0)
    .map((file) => {
      // Add headers to the csv
      const headers = Object.keys(file?.data?.[0]);
      let csvContent = `${headers.join(",")} \n`;

      file.data.forEach((row) => {
        csvContent += `${Object.values(row).join(",")} \n`;
      });

      const fileBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });

      return zipWriter.add(file.name, new BlobReader(fileBlob));
    });

  await Promise.all(promises);

  const zipFileBlob = await zipWriter.close();

  const link = document.createElement("a");
  link.setAttribute("href", URL.createObjectURL(zipFileBlob));
  link.setAttribute("download", zipName);
  link.click();
};
