import React from "react";
import { REQUEST_STATUS } from "Constants/global.constants";
import { Elements } from "@stripe/react-stripe-js";
import { Loader } from "Utils";

import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "./StripeForm.component";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const stripeFonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@400",
  },
];

class PaymentMethodForm extends React.Component {
  componentDidMount = () => {
    const { account, getPaymentClientSecretAPI } = this.props;
    getPaymentClientSecretAPI(account.accessToken);
  };

  render() {
    const { payment } = this.props;
    const { status } = payment.getPaymentClientSecretCTX;

    switch (status) {
      case REQUEST_STATUS.SUCCESS: {
        return (
          <Elements stripe={stripePromise} options={{ fonts: stripeFonts }}>
            <StripeForm {...this.props} {...this.state} />
          </Elements>
        );
      }
      case REQUEST_STATUS.FAILURE: {
        return <div>An error occurred</div>;
      }
      default: {
        return (
          <div className="payment-method-form">
            <Loader big centered />
          </div>
        );
      }
    }
  }
}

export default PaymentMethodForm;
