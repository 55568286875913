import React from "react";
import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator } from "Utils";
import { ConfirmationModal } from "UI";
import ModalResult from "Components/Organization/Admin/Components/ModalResult/ModalResult.component";

const CreateLastSessionTemplateModal = ({ seat, onClose, translate }) => {
  const createSilverImageMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES, data);
    },
  });

  if (createSilverImageMutation.isSuccess) {
    return (
      <ModalResult
        success={createSilverImageMutation.isSuccess}
        successHeader={translate("organizationHome.confirmationModals.createLastSessionTemplate.success.header")}
        successDescription={translate(
          "organizationHome.confirmationModals.createLastSessionTemplate.success.description",
        )}
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      headerText={translate("organizationHome.confirmationModals.createLastSessionTemplate.confirmation.header")}
      descriptionText={translate(
        "organizationHome.confirmationModals.createLastSessionTemplate.confirmation.description",
      )}
      closeAction={() => {
        createSilverImageMutation.reset();
        onClose();
      }}
      confirmText="Confirm"
      confirmAction={() => {
        createSilverImageMutation.mutate({ seat_id: seat.id });
      }}
      pending={createSilverImageMutation.isLoading}
    />
  );
};

export default CreateLastSessionTemplateModal;
