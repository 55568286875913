import React, { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useMutation } from "@tanstack/react-query";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { Loader, apiGenerator, classnames } from "Utils";
import { DivButton, Modal, NumberInput, TextButton } from "UI";
import ExpandableAnimation from "UI/ExpandableAnimation/ExpandableAnimation.component";
import ModalResult from "../../Components/ModalResult/ModalResult.component";
import "./UsageCreditModal.styles.scss";

const UsageCreditModal = ({ onClose, seat, machineTypeId, translate, history }) => {
  const [hours, setHours] = useState(0);
  const [selectedMachineType, setSelectedMachineType] = useState(machineTypeId);
  const [calculationData, setCalculationData] = useState(null);

  const calculateAdditionalUsagePrice = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS_CALCULATE_ADDITIONAL_USAGE_PRICE(seat.id), data);
    },
    onSuccess: (data) => {
      setCalculationData(data);
    },
  });

  const depositAdditionalUsageMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS_DEPOSIT_ADDITIONAL_USAGE(seat.id), data);
    },
  });

  const { isSuccess, isError } = depositAdditionalUsageMutation;

  useEffect(() => {
    calculateAdditionalUsagePrice.mutate({
      hours,
      machine_type_id: selectedMachineType,
    });
  }, [hours, selectedMachineType]);

  const {
    plan_machine_types: planMachineTypes,
    selected_machine_type: selectedMachineTypeData,
    total_price: totalPrice,
    is_balance_sufficient: isBalanceSufficient,
  } = calculationData?.data || {};

  const content = () => {
    if (!calculationData) {
      return <Loader />;
    }
    return (
      <>
        <div className="usage-content">
          <div className="usage-content-input">
            <NumberInput minInputValue={0} maxInputValue={100} value={hours} onChange={(e) => setHours(e)} />
            <p>hours of {selectedMachineTypeData?.attributes?.friendly_name} Usage</p>
          </div>

          <ExpandableAnimation isOpen={hours !== 0}>
            {planMachineTypes?.length > 1 && <p className="or-section">or can be used as following:</p>}
            <div className="machine-types">
              {planMachineTypes?.map((type) => {
                const { machine_type: machineType, usage_hours: usageHours } = type;
                const usageMinutes = usageHours * 60;
                const usageText =
                  Math.round(usageHours) < 1
                    ? `${Math.round(usageMinutes)} minutes`
                    : `${Math.round(usageHours)} hours`;
                const selected = selectedMachineType === parseInt(machineType.id, 10);
                return (
                  <DivButton
                    className={classnames(["machine-type", selected && "selected"])}
                    onClick={() => {
                      setSelectedMachineType(parseInt(machineType.id, 10));
                    }}
                  >
                    {machineType.attributes.friendly_name} ~ {usageText}
                  </DivButton>
                );
              })}
            </div>
          </ExpandableAnimation>
        </div>

        <ExpandableAnimation isOpen={hours !== 0}>
          <div className="storage-price-info">
            {isBalanceSufficient ? (
              <Translate
                options={{ renderInnerHtml: true }}
                id="organizationHome.usageCreditModal.priceInfo"
                data={{ price: totalPrice.toFixed(2) }}
              />
            ) : (
              <div className="insufficient-balance">
                <Translate id="organizationHome.usageCreditModal.insufficientBalance" />
                <TextButton
                  text="Add Balance"
                  color="purple-main"
                  onClick={() => {
                    history.push({
                      pathname: routes.organizationAdminBilling,
                      state: { showDepositBalanceModal: true },
                    });
                  }}
                />
              </div>
            )}
          </div>
        </ExpandableAnimation>
      </>
    );
  };

  if (isSuccess || isError) {
    return (
      <ModalResult
        success={isSuccess}
        successHeader={translate("organizationHome.usageCreditModal.success.title")}
        successDescription={translate("organizationHome.usageCreditModal.success.description")}
        failureHeader={translate("organizationHome.usageCreditModal.error.title")}
        failureDescription={translate("organizationHome.usageCreditModal.error.description")}
        failure={isError}
        onClose={() => {
          depositAdditionalUsageMutation.reset();
          onClose();
        }}
      />
    );
  }

  return (
    <Modal
      headerText={translate("organizationHome.usageCreditModal.header.title")}
      descriptionText={translate("organizationHome.usageCreditModal.header.description")}
      topRightIcon="close"
      topRightIconAction={onClose}
    >
      <div className="usage-credit-modal-content">
        {isSuccess || isError ? (
          <ModalResult
            success={isSuccess}
            successHeader={translate("organizationHome.usageCreditModal.success.title")}
            successDescription={translate("organizationHome.usageCreditModal.success.description")}
            failureHeader={translate("organizationHome.usageCreditModal.error.title")}
            failureDescription={translate("organizationHome.usageCreditModal.error.description")}
            failure={isError}
            onClose={() => {
              depositAdditionalUsageMutation.reset();
              onClose();
            }}
          />
        ) : (
          <>
            {content()}
            <div className="buttons-container">
              <TextButton text="Cancel" color="gray-3" onClick={onClose} />
              <TextButton
                text="Add Usage"
                color="aqua-main"
                onClick={() => {
                  depositAdditionalUsageMutation.mutate({
                    hours,
                    machine_type_id: selectedMachineType,
                  });
                }}
                disabled={!isBalanceSufficient || hours === 0}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UsageCreditModal;
