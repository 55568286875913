import React from "react";
import { Link } from "react-router-dom";
import { classnames } from "Utils/Helpers/functions.helpers";
import routes from "Constants/Route.constants";

const menuItemClassName = (path, checkValue) => `menu-item ${path === checkValue ? "active" : ""}`;

const MenuItems = (props) => {
  const { path, setShowMenu, mobile, account, appsMenu } = props;
  const balance = parseFloat(account.account && account.account.attributes.balance) || 0;

  const onLinkClick = () => {
    if (setShowMenu) {
      setShowMenu(false);
    }
  };

  return (
    <div className="menu-wrapper">
      {mobile && balance > 0 && (
        <div className="menu-balance">
          Your Balance
          <p>{`${balance.toFixed(2)} USD`}</p>
          <div className="divider" />
        </div>
      )}
      <Link to={routes.home} className={menuItemClassName(path, routes.home)} onClick={onLinkClick}>
        Computer
      </Link>
      {!mobile && <div className="divider" />}
      <Link
        to={routes.files}
        className={classnames([menuItemClassName(path, routes.files), "sidebar-files-menu"])}
        onClick={onLinkClick}
      >
        Files
      </Link>

      <Link to={routes.usage} className={menuItemClassName(path, routes.usage)} onClick={onLinkClick}>
        Sessions
      </Link>
      <Link to={routes.billing} className={menuItemClassName(path, routes.billing)} onClick={onLinkClick}>
        Payments
      </Link>
      <Link to={routes.account} className={menuItemClassName(path, routes.account)} onClick={onLinkClick}>
        Settings
      </Link>

      <div className={`menu-item-bottom ${mobile ? "mobile" : ""}`}>
        {appsMenu()}
        <Link
          to={routes.login}
          className={menuItemClassName(path, routes.login)}
          onClick={() => {
            props.logoutAPI(props.account.accessToken);
            onLinkClick();
          }}
        >
          Sign out
        </Link>
        {!mobile && <div className="divider" />}
        <a className="menu-item support" href="http://help.vagon.io" rel="noopener noreferrer" target="_blank">
          Help / Support
        </a>
      </div>
    </div>
  );
};

export default MenuItems;
