export const GPU_STREAMING_SETTINGS = {
  quality: "standard",
  fps: 30,
};

export const CPU_STREAMING_SETTINGS = {
  quality: "standard",
  fps: 30,
};

export const STREAM_QUALITY_OPTIONS = {
  // "Very Low": "Very Low",
  Low: "low",
  Standard: "standard",
  High: "high",
  // "Very High": "Very High",
};

export const STREAM_PLAYERS = {
  WEBRTC: "webrtc",
  JMUXER: "jmuxer",
  BROADWAYJS: "broadwayjs",
};

export const STREAM_AUDIO_STATES = {
  NOT_COLLECTED: "not_collected",
  WARNING: "warning",
  MUTED: "muted",
  PLAYING: "playing",
};

export const VR_STATES = {
  disabled: "disabled",
  installing: "installing",
  running: "running",
  stopped: "stopped",
  failed: "failed",
};

export const VR_INSTALLATION_STATES = {
  "Essentials:Downloading": 0,
  "Essentials:Extracting": 5,
  "Essentials:Completed": 10,
  "SteamVR:Downloading": 15,
  "SteamVR:Extracting": 20,
  "directx:Downloading": 50,
  "directx:Extracting": 55,
  "directx:Completed": 60,
  "registry:Completed": 75,
  "config:Started": 85,
  "config:Completed": 100,
};

export const VR_BIT_RATES = {
  15: 15000000,
  25: 25000000,
  30: 30000000,
};

export const FPS_OPTIONS = [30, 60];
export const CPU_CUSTOM_RESOLUTIONS = ["Use Local Resolution", "1440x900", "1920x1080"];

export const GPU_CUSTOM_RESOLUTIONS = [
  "Use Local Resolution",
  "1366x768",
  "1440x900",
  "1536x864",
  "1920x1080",
  "2048x1080 | 2K",
  "2560x1080",
  "2560x1440",
  "3840x2160 | 4K",
  "4096x2160 | 4K",
];

export const CUSTOM_RESOLUTION_SIZES = {
  "Use Local Resolution": { localResolution: true },
  "1366x768": { x: 1366, y: 768 },
  "1440x900": { x: 1440, y: 900 },
  "1536x864": { x: 1536, y: 864 },
  "1920x1080": { x: 1920, y: 1080 },
  "2048x1080 | 2K": { x: 2048, y: 1080 },
  "2560x1080": { x: 2560, y: 1080 },
  "2560x1440": { x: 2560, y: 1440 },
  "3840x2160 | 4K": { x: 3840, y: 2160 },
  "4096x2160 | 4K": { x: 4096, y: 2160 },
};
