import React, { useState, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import moment from "moment";
import _ from "lodash";
import { TextButton, Icon, HoverableIconButton } from "UI";
import { FILE_STATUS, LOCAL_STORAGE } from "Constants/global.constants";
import { getItemFromLocalStorage, saveItemToLocalStorage } from "Utils";
import { isSafari, isFirefox } from "Utils/Helpers/browser.helpers";
import DockOnboardingModal from "Components/Workstation/Modals/DockOnboardingModal.component";
import VrDeviceDetectedModal from "../Modals/VrDeviceDetectedModal/VrDeviceDetectedModal.component";
import DockButton from "./DockButton/DockButton.component";
import SettingsTab from "./DockTabs/SettingsTab.component";
import DisplayTab from "./DockTabs/DisplayTab.component";
import FilesTab from "./DockTabs/FilesTab.component";
import VrTab from "./DockTabs/VrTab.component";

import "./WorkstationDock.styles.scss";

const TABS = {
  SETTINGS: { icon: "settings", hoverText: "General" },
  DISPLAY: { icon: "display", hoverText: "Display" },
  FILES: { icon: "file-drag", hoverText: "Files" },
  VR: { icon: "vr-headset", hoverText: "VR" },
};

const TERMINATE_DOCK_CONTENT_TIMEOUT = 800;

const isFileUploading = (files) => {
  return _.findKey(files, (file) => file.status === FILE_STATUS.PENDING);
};

const TabButton = ({ name, onClick, hoverText, isDockOpen, disabled }) => {
  const [showHoverText, setShowHoverText] = useState(!getItemFromLocalStorage(LOCAL_STORAGE.showedDockTabHover, false));

  useEffect(() => {
    let closeHoverTextTimeout;

    if (isDockOpen && showHoverText) {
      closeHoverTextTimeout = setTimeout(() => {
        saveItemToLocalStorage(LOCAL_STORAGE.showedDockTabHover, true);
        setShowHoverText(false);
      }, 4000);
    }
    return () => {
      clearTimeout(closeHoverTextTimeout);
    };
  }, [isDockOpen, showHoverText]);

  return (
    <HoverableIconButton
      hoverText={hoverText}
      onClick={onClick}
      name={name}
      showHoverText={isDockOpen && showHoverText}
      disabled={disabled}
    />
  );
};

const WorkstationDock = (props) => {
  const {
    connected,
    workstationType,
    isDockOpen,
    setIsDockOpen,
    showDockOnboardingModal,
    setShowDockOnboardingModal,
    uploadFiles,
    showFileManager,
    setShowFileManager,
    setShowClipboardModal,
    turnOff,
    pasteToRemote,
    copyFromRemote,
    isDockReady,
    currentSessionAttributes,
    machine,
    handleAutoTurnOffChanged,
    onReturnDashboardClick,
    disableFilesTab,
    blockClipboardEvents,
    vrState,
    vrInstallationState,
    sendIsVrToolsRunningEvent,
    changeGameMode,
    showVrDeviceDetectedModal,
    setShowVrDeviceDetectedModal,
    toggleVREnvironment,
    disableVrTab,
    publicOrganizationComputer,
    translate,
  } = props;

  const dockPosition = "right";

  let sessionStartTime = Date.now();

  if (currentSessionAttributes) {
    sessionStartTime = currentSessionAttributes.start_at || currentSessionAttributes.created_at;
  }

  const showDock = isDockReady && isDockOpen;

  const [renderDockContent, setRenderDockContent] = useState(false);

  const [currentTab, setCurrentTab] = useState(TABS.SETTINGS);

  useEffect(() => {
    let preventRenderTimeout;
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: !isDockOpen,
      });
    }
    if (isDockOpen) {
      setRenderDockContent(true);
      if (isFileUploading(uploadFiles)) {
        setCurrentTab(TABS.FILES);
      }
    } else {
      preventRenderTimeout = setTimeout(() => setRenderDockContent(false), TERMINATE_DOCK_CONTENT_TIMEOUT);
      if (setShowFileManager) {
        setShowFileManager(false);
      }
    }

    return () => {
      clearTimeout(preventRenderTimeout);
    };
  }, [isDockOpen, uploadFiles]);

  const handleCopyTo = () => {
    if (isSafari || isFirefox) {
      setShowClipboardModal(true, false);
    } else {
      pasteToRemote();
    }
    setIsDockOpen(false);
  };

  const handleCopyFrom = () => {
    copyFromRemote();
    if (isSafari) {
      setShowClipboardModal(true, true);
    }
    setIsDockOpen(false);
  };

  useEffect(() => {
    if (disableVrTab && getItemFromLocalStorage(LOCAL_STORAGE.vrEnvironmentEnabled, false)) {
      saveItemToLocalStorage(LOCAL_STORAGE.vrEnvironmentEnabled, false);
    }
  }, [disableVrTab]);

  const dockBarItem = (barItem) => {
    const isSelected = currentTab === barItem;
    const barItemClassName = ["bar-item", isSelected && "selected"].filter(Boolean).join(" ");
    const disabled =
      (barItem === TABS.FILES && disableFilesTab) ||
      (barItem === TABS.VR && (workstationType !== "GPU" || disableVrTab));
    const hidden =
      (barItem === TABS.VR && publicOrganizationComputer) || (barItem === TABS.FILES && publicOrganizationComputer);

    if (hidden) {
      return null;
    }

    return (
      <div className={`${barItemClassName} ${barItem.icon}`} key={barItem.icon}>
        <TabButton
          name={barItem.icon}
          onClick={() => {
            setShowFileManager(false);
            setCurrentTab(barItem);
          }}
          hoverText={barItem.hoverText}
          isDockOpen={isDockOpen}
          disabled={disabled}
        />
      </div>
    );
  };

  const currentTabContents = () => {
    switch (currentTab) {
      case TABS.FILES: {
        return (
          <FilesTab
            isDockOpen={isDockOpen}
            setShowFileManager={setShowFileManager}
            setIsDockOpen={setIsDockOpen}
            {...props}
          />
        );
      }
      case TABS.DISPLAY: {
        return (
          <DisplayTab
            isDockOpen={isDockOpen}
            setIsDockOpen={setIsDockOpen}
            connected={connected}
            workstationType={workstationType}
            {...props}
          />
        );
      }
      case TABS.VR: {
        return (
          <VrTab
            isDockOpen={isDockOpen}
            setIsDockOpen={setIsDockOpen}
            connected={connected}
            vrState={vrState}
            vrInstallationState={vrInstallationState}
            sendIsVrToolsRunningEvent={sendIsVrToolsRunningEvent}
            {...props}
          />
        );
      }
      default: {
        return (
          <SettingsTab
            machine={machine}
            isDockOpen={isDockOpen}
            setIsDockOpen={setIsDockOpen}
            handleCopyTo={handleCopyTo}
            handleCopyFrom={handleCopyFrom}
            blockClipboardEvents={blockClipboardEvents}
            handleAutoTurnOffChanged={handleAutoTurnOffChanged}
            {...props}
          />
        );
      }
    }
  };

  const dockWrapperClassName = [
    "vg-workstation-dock-wrapper",
    showDock && "open",
    showDock && showFileManager && "half-open",
  ]
    .filter(Boolean)
    .join(" ");

  useHotkeys("ctrl+g", () => {
    changeGameMode();
  });

  return (
    <div className="vg-workstation-dock-container">
      <DockButton
        dockPosition={dockPosition}
        isDockOpen={showDock}
        handleCopyTo={handleCopyTo}
        handleCopyFrom={handleCopyFrom}
        setIsDockOpen={(state) => {
          if (!state) {
            setShowFileManager(false);
          }
          setIsDockOpen(state);
        }}
        {...props}
      />
      <div className={dockWrapperClassName}>
        {isDockReady && (
          <div className={`dock ${dockPosition}`}>
            <div className="bar">
              <Icon name="vagon-icon" color="purple-main" />
              {Object.values(TABS).map((barItem) => dockBarItem(barItem))}
            </div>
            {renderDockContent && (
              <div className="dock-content">
                <div className="dock-header">
                  <div className="header-text">{translate("dock.header.title")}</div>
                  <div className="header-content">
                    {moment(moment().diff(moment(new Date(sessionStartTime)).local()))
                      .utc()
                      .format("HH:mm")}
                  </div>
                </div>
                <div className="dock-body">{currentTabContents()}</div>
                {!publicOrganizationComputer && (
                  <div className="dock-footer">
                    <TextButton
                      className="menu-item"
                      text={translate("dock.footer.toDashboard")}
                      onClick={() => {
                        setTimeout(() => {
                          onReturnDashboardClick();
                        }, 1000);
                      }}
                    />
                    <TextButton className="menu-item" text={translate("dock.footer.close")} onClick={turnOff} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {showDockOnboardingModal && (
        <DockOnboardingModal
          translate={translate}
          onExit={() => {
            setShowDockOnboardingModal(false);
            setCurrentTab(TABS.SETTINGS);
          }}
          dockTabs={TABS}
          setCurrentTab={setCurrentTab}
        />
      )}
      {showVrDeviceDetectedModal && (
        <VrDeviceDetectedModal
          onActivateVr={() => {
            setShowVrDeviceDetectedModal(false);
            setIsDockOpen(true);
            setCurrentTab(TABS.VR);
            saveItemToLocalStorage(LOCAL_STORAGE.vrEnvironmentEnabled, true);
            toggleVREnvironment(true);
          }}
          setShowVrDeviceDetectedModal={() => {
            setShowVrDeviceDetectedModal(false);
          }}
          translate={translate}
        />
      )}
    </div>
  );
};

export default WorkstationDock;
