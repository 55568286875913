import React, { useState } from "react";
import { Button, Input, Modal } from "UI";
import { apiGenerator } from "Utils";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useMutation } from "@tanstack/react-query";
import ExpandableAnimation from "UI/ExpandableAnimation/ExpandableAnimation.component";

import "./PowerShellModal.styles.scss";

const PowerShellModal = ({ machine, onClose }) => {
  const [script, setScript] = useState("");

  const runPowerShellMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_RUN_SCRIPT(machine.id), {
        script_body: script,
      });
    },
  });

  const { result } = runPowerShellMutation.data?.data || {};

  return (
    <Modal
      className="powershell-modal"
      closeAction={onClose}
      topRightIcon="close"
      headerText="Run Powershell Script"
      descriptionText="Run custom scripts via Powershell window, remotely"
      topRightIconAction={onClose}
    >
      <Input
        label="Script"
        textarea
        value={script}
        onChange={(e) => {
          setScript(e.target.value);
        }}
      />
      <Button
        icon={runPowerShellMutation.isLoading && "loader"}
        disabled={runPowerShellMutation.isLoading}
        text="Run Powershell Script"
        className="run-script-button"
        onClick={() => {
          runPowerShellMutation.mutate();
        }}
      />
      <ExpandableAnimation isOpen={!!result}>
        <Input label="Response" textarea value={result} disabled />
      </ExpandableAnimation>
    </Modal>
  );
};

export default PowerShellModal;
