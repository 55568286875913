import * as WorkstationConstants from "Constants/Dashboard/Workstations.constants";

export const updateCreateWorkstationConfig = (config) => ({
  type: WorkstationConstants.UPDATE_CREATE_WORKSTATION_CONFIG,
  payload: { config },
});

export const getWorkstationsAPI = (accessToken) => ({
  type: WorkstationConstants.GET_WORKSTATIONS_API_PENDING,
  payload: { accessToken },
});

export const getWorkstationMachineTypesAPI = (accessToken) => ({
  type: WorkstationConstants.GET_WORKSTATION_MACHINE_TYPES_API_PENDING,
  payload: { accessToken },
});

export const getNotificationAPI = () => ({
  type: WorkstationConstants.GET_NOTIFICATIONS_API_PENDING,
});

export const updateWorkstationStatusAPI = (status, machineTypeId) => ({
  type: WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_PENDING,
  payload: { status, machineTypeId },
});

export const resetUpdateWorkstationStatusAPI = () => ({
  type: WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_RESET,
});

export const updateWorkstationAutoStopConfigurationAPI = (autoTurnOffSetting) => ({
  type: WorkstationConstants.UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_PENDING,
  payload: { autoTurnOffSetting },
});

export const getWorkstationCurrentSessionAPI = () => ({
  type: WorkstationConstants.GET_WORKSTATION_CURRENT_SESSION_PENDING,
});

export const getWorkstationSoftwaresAPI = (accessToken) => {
  return {
    type: WorkstationConstants.GET_WORKSTATION_SOFTWARES_PENDING,
    payload: { accessToken },
  };
};

export const installWorkstationSoftwaresAPI = (accessToken, softwares, goldenImageId) => {
  return {
    type: WorkstationConstants.INSTALL_WORKSTATION_SOFTWARES_PENDING,
    payload: { accessToken, softwares, goldenImageId },
  };
};

export const validateWorkstationSoftwaresAPI = (accessToken, softwares, goldenImageId, diskSize, region) => {
  return {
    type: WorkstationConstants.VALIDATE_WORKSTATION_SOFTWARES_PENDING,
    payload: { accessToken, softwares, goldenImageId, diskSize, region },
  };
};

export const getAppStreamingSessionAPI = (id) => ({
  type: WorkstationConstants.GET_APP_STREAMING_SESSION_PENDING,
  payload: { id },
});

export const resetAppStreamingSessionCtx = () => ({
  type: WorkstationConstants.GET_APP_STREAMING_SESSION_RESET,
});
