import React from "react";
import { connect } from "react-redux";
import AppStreamingBillingComponent from "Components/AppStreaming/Billing/AppStreamingBilling.component";
import { getVendorAccountAPI, getApplicationsAPI } from "Actions/AppStreaming.actions";
import { getPaymentMethodsAPI } from "Actions/Dashboard/Payment.actions";
import { getProductOnboardingAPI } from "Actions/Account.actions";

class AppStreamingBillingContainer extends React.Component {
  componentDidMount() {
    const { getVendorAccountAPI, getPaymentMethodsAPI, getApplicationsAPI } = this.props;
    getPaymentMethodsAPI();
    getVendorAccountAPI();
    getApplicationsAPI();
    getProductOnboardingAPI();
  }

  render() {
    return <AppStreamingBillingComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  appStreaming: state.appStreaming,
  payment: state.payment,
});

const actionCreators = {
  getVendorAccountAPI,
  getApplicationsAPI,
  getPaymentMethodsAPI,
  getProductOnboardingAPI,
};

export default connect(mapStateToProps, actionCreators)(AppStreamingBillingContainer);
