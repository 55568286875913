import React from "react";
import IconButton from "UI/IconButton/IconButton.component";

import "./SearchInput.styles.scss";

const SearchInput = ({ value, onChange, placeholder }) => {
  return (
    <div className="vg-search-input-container">
      <input type="text" className="vg-search-input" placeholder={placeholder} value={value} onChange={onChange} />
      <IconButton name="search" small />
    </div>
  );
};

export default SearchInput;
