import React, { useEffect, useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import apiGenerator from "Utils/Helpers/api.helpers";
import StatusBadge from "UI/StatusBadge/StatusBadge.component";
import ObjectsModal from "../Components/ObjectsModal/ObjectsModal.component";
import UserCell from "../Components/UserCell/UserCell.component";

const PER_PAGE = 20;

const USERS_TABLE_COLUMNS = [
  { name: "name", weight: 30, hide: true },
  { name: "status", weight: 20, hide: true },
];

const AssignComputerToMemberModal = ({ onClose, selectedObject, translate }) => {
  const [selected, setSelected] = useState(null);
  const [q, setQ] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [q]);

  const { refetch, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, data } = useInfiniteQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_USERS_ASSIGNABLE],
    queryFn: ({ pageParam = 1 }) => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_USERS_ASSIGNABLE, {
        params: { page: pageParam, per_page: PER_PAGE, q },
      }).then((res) => {
        return res.data;
      });
    },
    getNextPageParam: (lastPage) => lastPage.next_page,
  });

  const assignUserMutation = useMutation({
    mutationKey: [API_ENDPOINTS.ORGANIZATION_USERS_ASSIGN],
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_USERS_ASSIGN, data);
    },
  });

  const flattenAssignableUsers = data?.pages?.flatMap((page) => page?.results);

  const assignableUsers = flattenAssignableUsers?.map((object) => {
    return {
      key: object.email,
      name: (
        <UserCell
          onCheckBoxChange={(e) => {
            if (e.target.checked) setSelected(object.email);
            else setSelected(null);
          }}
          checked={selected === object.email}
          user={object}
        />
      ),
      status: <StatusBadge status={object.type === "user" ? "computer-required" : "pending-invitation"} />,
    };
  });

  return (
    <ObjectsModal
      onClose={onClose}
      columns={USERS_TABLE_COLUMNS}
      data={assignableUsers}
      onConfirm={() => {
        assignUserMutation.mutate({ email: selected, seat_id: selectedObject.attributes.seat.id });
      }}
      disableConfirm={!selected}
      success={assignUserMutation.isSuccess}
      error={assignUserMutation.isError}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      searchQuery={q}
      setSearchQuery={setQ}
      headerText={translate("assignComputerToMemberModal.header.title")}
      successHeader={translate("assignComputerToMemberModal.success.header")}
      successDescription={translate("assignComputerToMemberModal.success.description")}
      emptyContentHeader={translate("assignComputerToMemberModal.emptyContent.title")}
      emptyContentDescription={translate("assignComputerToMemberModal.emptyContent.description")}
    />
  );
};

export default AssignComputerToMemberModal;
