import React from "react";
import { classnames } from "Utils";
import { HoverableTooltip } from "UI";

import "./UsageBar.styles.scss";

const UsageBar = (props) => {
  const {
    firstUsage,
    secondUsage,
    totalUsage,
    topLeftInfo,
    topRightInfo,
    firstUsageHoverContent,
    secondUsageHoverContent,
  } = props;

  const firstUsagePercentage = (firstUsage / totalUsage) * 100;
  const secondUsagePercentage = (secondUsage / totalUsage) * 100;

  const firstUsagePresent = firstUsage && firstUsage > 0;
  const secondUsagePresent = secondUsage && secondUsage > 0;
  const firstUsageFull = firstUsagePercentage === 100;

  const full = firstUsage + secondUsage === totalUsage;

  return (
    <div className="usage-bar-wrapper">
      <div className="usage-info">
        <div className="left-content">
          <p>{topLeftInfo}</p>
        </div>
        <div className="right-content">
          <p>{topRightInfo}</p>
        </div>
      </div>
      <div className="usage-bar">
        <HoverableTooltip
          content={firstUsageHoverContent}
          side="bottom"
          align="start"
          className={classnames(["first-usage", (firstUsageFull || (full && !secondUsagePresent)) && "right-border"])}
          style={{ width: `${firstUsagePercentage}%` }}
        />
        <HoverableTooltip
          content={secondUsageHoverContent}
          side="bottom"
          align="start"
          className={classnames(["second-usage", !firstUsagePresent && "left-border", full && "right-border"])}
          style={{ width: `${secondUsagePercentage}%` }}
        />
      </div>
    </div>
  );
};

export default UsageBar;
