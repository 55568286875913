import { API_ENDPOINTS } from "Constants/api.constants";
import { FEEDBACK_TYPES } from "Constants/global.constants";
import React, { useState } from "react";
import { Modal, Input, Dropdown } from "UI";
import { getClientInfo, useAPI } from "Utils";
import { getLastSuccessfulConnectionType } from "Utils/Helpers/streaming.helpers";

import "./FeedbackModal.styles.scss";

const FEEDBACK_OPTIONS = {
  option1: ["option1"],
  option2: ["option2"],
  option3: ["option3"],
  option4: ["option4"],
  option5: ["option5"],
  option6: ["option6"],
  option7: ["option7"],
};

const FeedbackModal = (props) => {
  const { setShowFeedbackModal, type, feedBackRating, translate } = props;

  const [reason, setReason] = useState(null);
  const [textInput, setTextInput] = useState("");
  const [requested, setRequested] = useState(false);

  const { api: sessionFeedbackAPI } = useAPI({
    type: "post",
    endpoint: API_ENDPOINTS.SESSION_FEEDBACK,
  });

  const { api: subscriptionFeedbackAPI } = useAPI({
    type: "post",
    endpoint: API_ENDPOINTS.SUBSCRIPTION_FEEDBACK,
  });

  const handleEnterKeyUp = (event, callback) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      callback();
      handleFeedbackSend();
    }
  };

  const handleFeedbackSend = () => {
    const reasonInput = reason ? translate(`modals.feedback.options.${type}.${reason}`) : "";
    if (type === FEEDBACK_TYPES.streaming) {
      const clientInfo = getClientInfo();
      sessionFeedbackAPI({
        rating: feedBackRating,
        comment: textInput,
        reason: reasonInput,
        os: clientInfo.os,
        gpu_information: clientInfo.gpu,
        browser_information: clientInfo.browser,
        webrtc_toggle_enabled: true,
        last_successful_connection_type: getLastSuccessfulConnectionType(),
      });
    } else if (type === FEEDBACK_TYPES.endSubscription) {
      subscriptionFeedbackAPI({ comment: textInput, reason: reasonInput });
    } else if (window.Intercom) {
      window.Intercom("trackEvent", `feedback-${type}`, {
        app_id: process.env.REACT_APP_INTERCOM_API_KEY,
        reason: reasonInput,
        reason_desc: textInput,
      });
    }
    setRequested(true);
  };

  const handleClose = () => {
    if (!requested) {
      handleFeedbackSend();
    } else {
      setShowFeedbackModal(false);
    }
  };

  return (
    <Modal
      className="feedback-modal"
      closeOnOverlayClick
      closeAction={handleClose}
      topRightIcon="close"
      topRightIconAction={handleClose}
      buttonText={translate("modals.feedback.buttonText")}
      buttonAction={handleFeedbackSend}
      success={requested}
      successText={translate("modals.feedback.successText")}
      invisibleOverlay
      small
    >
      <div className="feedback-input-label">{translate("modals.feedback.selectionLabel")}</div>
      <Dropdown
        placeholder={translate("modals.feedback.selectionPlaceholder")}
        options={type === "streaming" ? Object.keys(FEEDBACK_OPTIONS).slice(0, 6) : Object.keys(FEEDBACK_OPTIONS)}
        handleSelectedOptionChange={setReason}
        mapFunction={(option) => {
          return translate(`modals.feedback.options.${type}.${option}`);
        }}
        underlined
      />
      <div className="feedback-input-label">{translate("modals.feedback.inputLabel")}</div>
      <Input
        textarea
        placeholder={translate("modals.feedback.inputPlaceholder")}
        maxLength="350"
        value={textInput}
        onChange={(e) => {
          setTextInput(e.target.value);
        }}
        onKeyUp={(event) => handleEnterKeyUp(event, handleFeedbackSend)}
        tabIndex={0}
      />
    </Modal>
  );
};

export default FeedbackModal;
