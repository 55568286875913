import React from "react";
import { Icon, IconButton } from "UI";
import "./CreateTemplateHeader.styles.scss";

const CreateTemplateHeader = (props) => {
  const { iconName, title, description, beta, onCloseButtonClick } = props;

  return (
    <div className="new-computer-header">
      <div className="header-left">
        {iconName && <Icon name={iconName} color="purple-main" />}
        <div className="info">
          <div className={`title${beta ? " beta" : ""}`}>{title}</div>
          <div className="description">{description}</div>
        </div>
      </div>
      <IconButton label="close" onClick={onCloseButtonClick} name="close" />
      <div className="divider" />
    </div>
  );
};

export default CreateTemplateHeader;
