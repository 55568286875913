import React, { useState } from "react";
import { Modal } from "UI";
import TimezoneSelection from "UI/TimezoneSelection/TimezoneSelection.component";
import { useAPI } from "Utils";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useDispatch } from "react-redux";
import { getAccountAPI } from "Actions/Account.actions";

import "./TimezoneModal.styles.scss";

const TimezoneModal = (props) => {
  const { userTimeZone, setShowTimezoneModal } = props;

  const [selectedTimezone, setSelectedTimezone] = useState(false);

  const dispatch = useDispatch();

  const { api: setUserTimeZone } = useAPI({
    endpoint: API_ENDPOINTS.TIME_ZONE,
    type: "put",
    onSuccess: () => {
      setShowTimezoneModal(false);
      dispatch(getAccountAPI());
    },
  });

  const handleClick = () => {
    setUserTimeZone({ time_zone: selectedTimezone.value });
  };

  return (
    <div className="timezone-selection-modal">
      <Modal
        className="timezone-modal"
        closeOnOverlayClick
        closeAction={() => setShowTimezoneModal(false)}
        topRightIcon="close"
        topRightIconAction={() => setShowTimezoneModal(false)}
        headerText="Choose Timezone"
        buttonText="Set Timezone"
        buttonAction={handleClick}
        buttonDisabled={userTimeZone === selectedTimezone?.value}
        small
        centered
      >
        <div className="time-zone-container">
          <TimezoneSelection userTimeZone={userTimeZone} setSelectedTimezone={setSelectedTimezone} />
        </div>
      </Modal>
    </div>
  );
};

export default TimezoneModal;
