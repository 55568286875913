import * as PaymentConstants from "Constants/Dashboard/Payment.constants";

export const getPaymentMethodsAPI = () => ({
  type: PaymentConstants.GET_PAYMENT_METHODS_PENDING,
});

export const setPreferredPaymentMethodAPI = (methodID, accessToken) => ({
  type: PaymentConstants.SET_PREFERRED_PAYMENT_METHOD_PENDING,
  payload: { methodID, accessToken },
});

export const getPaymentClientSecretAPI = (accessToken) => ({
  type: PaymentConstants.GET_PAYMENT_CLIENT_SECRET_PENDING,
  payload: { accessToken },
});

export const createUserPaymentMethodAPI = (accessToken, seti) => ({
  type: PaymentConstants.CREATE_USER_PAYMENT_METHOD_PENDING,
  payload: { accessToken, seti },
});

export const deleteUserPaymentMethodAPI = (accessToken, methodID) => ({
  type: PaymentConstants.DELETE_USER_PAYMENT_METHOD_PENDING,
  payload: { accessToken, methodID },
});

export const resetDeleteUserPaymentMethodAPI = () => ({
  type: PaymentConstants.DELETE_USER_PAYMENT_METHOD_RESET,
});

export const approveUserPaymentMethodAPI = (accessToken) => ({
  type: PaymentConstants.APPROVE_USER_PAYMENT_METHOD_PENDING,
  payload: { accessToken },
});

export const denyUserPaymentMethodAPI = (accessToken) => ({
  type: PaymentConstants.DENY_USER_PAYMENT_METHOD_PENDING,
  payload: { accessToken },
});

export const startPaymentAPI = (accessToken) => ({
  type: PaymentConstants.START_PAYMENT_PENDING,
  payload: { accessToken },
});

export const addBalanceAPI = (amount, isTeamBalance) => ({
  type: PaymentConstants.ADD_BALANCE_PENDING,
  payload: { amount, isTeamBalance },
});

export const resetAddBalanceCTX = (accessToken, amount) => ({
  type: PaymentConstants.ADD_BALANCE_RESET,
  payload: { accessToken, amount },
});

export const payment3DSecureAPI = (
  accessToken,
  paymentIntentId,
  errorStatus,
  completeEndpoint,
  successType,
  failureType,
) => ({
  type: PaymentConstants.PAYMENT_3DSECURE_PENDING,
  payload: {
    accessToken,
    paymentIntentId,
    errorStatus,
    completeEndpoint,
    successType,
    failureType,
  },
});
