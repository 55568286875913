import React from "react";
import FileUploadArea from "../FileUploadArea/FileUploadArea.component";
import "./ImageUploadArea.styles.scss";

const ImageUploadArea = ({
  imageSrc,
  isDragActive,
  onFileDrop,
  uploadText,
  icon = "upload-to-cloud",
  color = "purple-light",
  small,
  error,
  id,
  grayBorder,
  content,
  topRightContent,
  translate,
}) => {
  return (
    <div className="vg-image-upload">
      <FileUploadArea
        id={id || "image"}
        content={content || (imageSrc && <img src={imageSrc} />)}
        handleFileInput={(event) => {
          const { files } = event.target;
          onFileDrop(files);
        }}
        isDragActive={isDragActive}
        dragAndDropText={uploadText || translate("appStreamingModals.appUpload.dragAndDrop")}
        icon={icon}
        color={color}
        small={small}
        error={error}
        uploadText={translate("appStreamingModals.configuration.steps.step1.logo.description")}
        acceptedFiles="image/png, image/jpeg"
        translate={translate}
        grayBorder={grayBorder}
        topRightContent={topRightContent}
        single
      />
    </div>
  );
};

export default ImageUploadArea;
