import React from "react";

import { IconButton, TopLoader } from "UI";
import { Loader, classnames } from "Utils";
import _ from "lodash";

import { REACT_QUERY_REQUEST_STATUS, REQUEST_STATUS } from "Constants/global.constants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { normalizeCredits } from "Utils/Helpers/functions.helpers";

import "./DashboardSubScreenLayout.style.scss";

const DashboardSubScreenLayout = (props) => {
  const {
    account,
    teams,
    headerTitle,
    headerDescription,
    headerRightContent,
    actionContent,
    actionInfo,
    showBalance,
    showCloseIcon,
    onClose,
    balanceTitle,
    pending,
    waitFor,
    children,
    match,
    onDrop,
    onDragEnter,
    onDragLeave,
    onDragOver,
    translate,
    appStreaming,
    loading,
    noScroll,
    fullHeight,
    displayCloseIcon,
  } = props;

  const renderChildren = () => (typeof children === "function" ? children() : children);
  const isLoading =
    _.findIndex(
      waitFor,
      (status) => !status || status === REQUEST_STATUS.PENDING || status === REACT_QUERY_REQUEST_STATUS.LOADING,
    ) !== -1;

  const isAdminPage = match.path.includes("teams");
  const teamCredit = isAdminPage ? teams.team.credit : normalizeCredits(account?.account?.attributes?.credit);
  const balance =
    account.isTeamAccount || isAdminPage
      ? teamCredit || 0
      : parseFloat(account.account?.attributes?.balance || 0).toFixed(2);

  return (
    <>
      {pending && <TopLoader />}
      <div
        className={classnames(["vg-dashboard-sub-screen-layout", noScroll && "no-scroll", fullHeight && "full-height"])}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
      >
        <div className="dashboard-sub-screen-header">
          <div className="header-left">
            <div className="title">{headerTitle}</div>
            <p className="description">{headerDescription}</p>
          </div>
          {showBalance && (
            <div className="header-right">
              <p className="balance-title">{balanceTitle}</p>
              <p className="balance-amount">
                {translate("usageHistory.balanceAmount", {
                  amount: balance,
                  currency: account.isTeamAccount || isAdminPage ? "Credits" : "USD",
                })}
              </p>
            </div>
          )}
          {headerRightContent && <div className="header-right">{headerRightContent}</div>}
          {showCloseIcon && (
            <IconButton
              className={classnames(["close-icon", displayCloseIcon && "display-close-icon"])}
              name="close"
              onClick={onClose}
            />
          )}
        </div>

        <div className={classnames(["dashboard-sub-screen-divider", appStreaming && "appStreaming"])} />

        {isLoading || loading ? <Loader className="sub-screen-loader" /> : renderChildren()}
        {actionContent && (
          <div className="dashboard-sub-screen-action">
            {actionInfo && <div className="action-info">{actionInfo}</div>}
            <div className="action-content">{actionContent}</div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  teams: state.teams,
});

export default connect(mapStateToProps)(withRouter(DashboardSubScreenLayout));
