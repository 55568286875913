import React from "react";

import { Icon } from "UI";
import { shortenFileName } from "Utils";

const preparePath = (path) => {
  return path
    .split("/")
    .map((fileName) => shortenFileName(fileName, 15))
    .join(" > ");
};

const FileBreadcrumb = (props) => {
  const { currentFolder, path, goToFolder } = props;

  const stringPath =
    currentFolder &&
    currentFolder.attributes &&
    (currentFolder.attributes.object_type !== "root" ? currentFolder.attributes.path : "Teams");

  const handleKeyUp = (event, dir) => {
    event.preventDefault();

    if (event.key === "Enter") {
      goToFolder(dir.id);
    }
  };

  return (
    <div className="breadcrumb">
      {stringPath ? (
        <div className="directories">
          {path?.length > 1 ? (
            path.map((dir, index) => {
              return (
                <div
                  className="breadcrumb-button"
                  role="button"
                  tabIndex={0}
                  onKeyUp={(event) => handleKeyUp(event, dir)}
                  key={dir.id}
                  onClick={() => {
                    goToFolder(dir.id);
                  }}
                >
                  {dir.name}
                  {index < path.length - 1 && <Icon name="chevron-right" color="black-stroke black" />}
                </div>
              );
            })
          ) : (
            <div className="current-path">{preparePath(stringPath)}</div>
          )}
        </div>
      ) : (
        <span>Main</span>
      )}
    </div>
  );
};

export default FileBreadcrumb;
