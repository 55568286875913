import { LOCAL_STORAGE, LOCAL_STORAGE_PRIVATE } from "Constants/global.constants";
import { calculateStringHash } from "./functions.helpers";

export const getItemFromLocalStorage = (itemName, defaultValue = undefined) => {
  try {
    const storedState = localStorage.getItem(itemName);

    if (storedState === null || storedState === undefined) {
      localStorage.removeItem(itemName);
      return defaultValue;
    }

    return JSON.parse(storedState);
  } catch (error) {
    return defaultValue;
  }
};

export const removeItemFromLocalStorage = (localStorageItem) => {
  try {
    localStorage.removeItem(localStorageItem);
  } catch (error) {
    // Failed to access local storage
  }
};

export const saveItemToLocalStorage = (itemName, state, override) => {
  try {
    if (override) {
      localStorage.setItem(itemName, JSON.stringify(state));
      return;
    }
    // Check if the state exists
    const storedState = getItemFromLocalStorage(itemName);
    const serializedState = JSON.stringify(typeof storedState === "object" ? Object.assign(storedState, state) : state);

    localStorage.setItem(itemName, serializedState);
  } catch (error) {
    // Ignore write errors.
    removeItemFromLocalStorage(itemName);
  }
};

export const setAccessTokenInLocalStorage = (accessToken) => {
  saveItemToLocalStorage(LOCAL_STORAGE.accessToken, accessToken);
};

export const getAccessTokenFromLocalStorage = () => {
  const accessToken = getItemFromLocalStorage(LOCAL_STORAGE.accessToken);

  return accessToken || "";
};

export const removeKeysFromLocalStorage = (email) => {
  if (email) {
    const emailHash = Math.abs(calculateStringHash(email));
    const localEmailHash = getItemFromLocalStorage(LOCAL_STORAGE.accountHash);

    if (emailHash !== localEmailHash) {
      Object.keys(LOCAL_STORAGE).forEach((localStorageItem) => {
        localStorage.removeItem(localStorageItem);
      });
    }
    saveItemToLocalStorage(LOCAL_STORAGE.accountHash, emailHash);

    return;
  }

  sessionStorage.clear();
  // Remove data from local Storage
  Object.keys(LOCAL_STORAGE_PRIVATE).forEach((localStorageItem) => {
    localStorage.removeItem(localStorageItem);
  });
};

export const saveItemToSessionStorage = (itemName, state) => {
  try {
    const storedState = getItemFromSessionStorage(itemName);
    const serializedState = JSON.stringify(typeof storedState === "object" ? Object.assign(storedState, state) : state);

    sessionStorage.setItem(itemName, serializedState);
  } catch (error) {
    sessionStorage.removeItem(itemName);
  }
};

export const getItemFromSessionStorage = (itemName, defaultValue = undefined) => {
  try {
    const storedState = sessionStorage.getItem(itemName);

    if (storedState === null || storedState === undefined) {
      sessionStorage.removeItem(storedState);
      return defaultValue;
    }

    return JSON.parse(storedState);
  } catch (error) {
    return defaultValue;
  }
};

export const getGaClientId = () => {
  return new Promise((resolve) => {
    try {
      const timeout = setTimeout(() => {
        resolve(null);
      }, 100);

      window.gtag("get", process.env.REACT_APP_GOOGLE_GTAG_ID, "client_id", (id) => {
        clearTimeout(timeout);
        resolve(id);
      });
    } catch (error) {
      resolve(null);
    }
  });
};

export const getGaSessionIdFromCookie = () => {
  const cookies = document.cookie.split(";");
  let gaSessionId = "";

  // eslint-disable-next-line consistent-return
  cookies.forEach((cookie) => {
    if (cookie.includes(`_ga_${process.env.REACT_APP_GA4_MEASUREMENT_ID}`)) {
      const [, sessionId] = cookie.split("=");
      gaSessionId = sessionId;
      return true;
    }
  });

  return gaSessionId;
};

export const getFacebookFbpFromCookie = () => {
  const cookies = document.cookie.split(";");
  let fbp = "";

  // eslint-disable-next-line consistent-return
  cookies.forEach((cookie) => {
    if (cookie.includes("_fbp")) {
      const [, fbpValue] = cookie.split("=");
      fbp = fbpValue;
      return true;
    }
  });

  return fbp;
};

export const getFacebookFbcFromCookie = () => {
  const cookies = document.cookie.split(";");
  let fbc = "";

  // eslint-disable-next-line consistent-return
  cookies.forEach((cookie) => {
    if (cookie.includes("_fbc")) {
      const [, fbcValue] = cookie.split("=");
      fbc = fbcValue;
      return true;
    }
  });

  return fbc;
};
