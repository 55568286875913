import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import AuthenticationRoute from "Utils/Components/Route/AuthenticationRoute";
import LoginContainer from "Containers/Authentication/Login.container";
import AppStreamingLoginFromPluginContainer from "Containers/AppStreaming/Authentication/AppStreamingLoginFromPlugin.container";
import AppStreamingSendTokenToPluginContainer from "Containers/AppStreaming/AppStreamingHome/AppStreamingSendTokenToPlugin.container";
import RegisterContainer from "Containers/Authentication/Register.container";
import VerifyRegisterContainer from "Containers/Authentication/VerifyRegister.container";
import ForgetPasswordContainer from "Containers/Authentication/ForgetPassword.container";
import HomeContainer from "Containers/Dashboard/Home.container";
import FilesContainer from "Containers/Dashboard/Files.container";
import CreateComputerContainer from "Containers/Dashboard/CreateComputer.container";
import AccountContainer from "Containers/Dashboard/Account.container";
import ReferralContainer from "Containers/Dashboard/Referral.container";
import BillingContainer from "Containers/Dashboard/Billing.container";
import UsageContainer from "Containers/Dashboard/Usage.container";
import ForgetPasswordResetContainer from "Containers/Authentication/ForgetPasswordReset.container";
import WorkstationContainer from "Containers/Workstation/Workstation.container";
import WorkstationError from "Components/Workstation/WorkstationCanvas/WorkstationError.component";
import NotFoundComponent from "Utils/Components/404/NotFound.component";
import NotSupportedDevice from "Components/Dashboard/Home/components/NotSupportedDevice/NotSupportedDevice.component";
import IntercomRouteWrapper from "Utils/Wrappers/IntercomRoute.wrapper";
import routes from "Constants/Route.constants";
import AppStreamingContainer from "Containers/AppStreaming/AppStreaming.container";
import AppStreamingHomeContainer from "Containers/AppStreaming/AppStreamingHome/AppStreamingHome.container";
import AppStreamingStreamsContainer from "Containers/AppStreaming/AppStreamingStreams.container";
import AppStreamingBillingContainer from "Containers/AppStreaming/AppStreamingBilling.container";
import AppStreamingUsersContainer from "Containers/AppStreaming/AppStreamingUsers.container";
import UnAuthenticatedRoute from "Utils/Components/Route/UnAuthenticatedRoute";
import SessionExpired from "Components/Workstation/WorkstationCanvas/SessionExpired.component";
import AppStreamingSettings from "Components/AppStreaming/Settings/AppStreamingSettings.component";
import AppStreamingLanding from "Components/AppStreaming/Landing/AppStreamingLanding.component";
import ConfigureStream from "Components/AppStreaming/Streams/ConfigureStream/ConfigureStream.component";
import OrganizationContainer from "Containers/Dashboard/Organization.container";
import OrganizationAdminHome from "Containers/Organization/Admin/Home.container";
import OrganizationAdminCreatePlan from "Containers/Organization/Admin/CreatePlan.container";
import OrganizationAdminPlans from "Containers/Organization/Admin/Plans.container";
import OrganizationAdminTemplates from "Containers/Organization/Admin/Templates.container";
import OrganizationAdminCreateTemplate from "Containers/Organization/Admin/CreateTemplate.container";
import OrganizationAdminCreateComputers from "Containers/Organization/Admin/CreateComputers.container";
import OrganizationAdminBilling from "Containers/Organization/Admin/Billing.container";
import OrganizationComputerContainer from "Containers/Organization/OrganizationComputer.container";
import OrganizationFilesContainer from "Containers/Organization/Shared/Files.container";
import OrganizationAdminStats from "Containers/Organization/Admin/Stats.container";
import OrganizationAdminSettings from "Containers/Organization/Admin/Settings.container";
import OrganizationAdminPermissions from "Components/Organization/Admin/Permissions/Permissions.component";
import OrganizationAdminUsageAnalytics from "Components/Organization/Admin/UsageAnalytics/UsageAnalytics.component";
import OrganizationMemberHome from "Components/Organization/Member/Home/Home.component";
import OrganizationMemberSessions from "Components/Organization/Member/Sessions.component";
import OrganizationMemberBilling from "Components/Organization/Member/Billing/Billing.component";
import OrganizationMemberSettings from "Components/Organization/Member/Settings/Settings.component";
import OrganizationMemberTeamInfo from "Components/Organization/Member/TeamInfo/TeamInfo.component";
import PixelStreamingContainer from "Containers/AppStreaming/PixelStreaming/PixelStreaming.container";
import RenderStreamingContainer from "Containers/AppStreaming/RenderStreaming/RenderStreaming.container";
import AuthenticatedRoute from "Utils/Components/Route/AuthenticatedRoute/AuthenticatedRoute";
import UserRoute from "Utils/Components/Route/AuthenticatedRoute/UserRoute";
import OrganizationAdminRoute from "Utils/Components/Route/AuthenticatedRoute/OrganizationAdminRoute";
import OrganizationMemberRoute from "Utils/Components/Route/AuthenticatedRoute/OrganizationMemberRoute";
import AppStreamingRoute from "Utils/Components/Route/AuthenticatedRoute/AppStreamingRoute";
import AppScope from "Components/AppScope/AppScope.component";
import ErrorComponent from "Utils/Components/Error/Error.component";
import AppStreamingError from "Components/AppStreaming/AppStreamingError/AppStreamingError.component";
import PublicOrganizationComputerContainer from "Containers/Organization/PublicOrganizationComputer.container";
import PublicComputersError from "Components/Organization/PublicComputers/Error/Error.component";
import * as Sentry from "@sentry/react";

export default () => (
  <BrowserRouter>
    <Sentry.ErrorBoundary
      fallback={<ErrorComponent />}
      beforeCapture={(scope) => {
        scope.setLevel("error");
      }}
    >
      <IntercomRouteWrapper>
        <Switch>
          {/* Dashboard Route should be authentication protected. */}
          <AuthenticatedRoute exact path={routes.home} component={HomeContainer} dashboardRoute guard={UserRoute} />
          <AuthenticatedRoute
            exact
            path={routes.homePages}
            component={HomeContainer}
            dashboardRoute
            guard={UserRoute}
          />
          <AuthenticatedRoute exact path={routes.files} component={FilesContainer} dashboardRoute guard={UserRoute} />
          <AuthenticatedRoute
            exact
            path={routes.createComputer}
            component={CreateComputerContainer}
            guard={UserRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.account}
            component={AccountContainer}
            dashboardRoute
            guard={UserRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.referral}
            component={ReferralContainer}
            dashboardRoute
            guard={UserRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.billing}
            component={BillingContainer}
            dashboardRoute
            guard={UserRoute}
          />
          <AuthenticatedRoute exact path={routes.usage} component={UsageContainer} dashboardRoute guard={UserRoute} />
          <AuthenticatedRoute exact path={routes.workstation} component={WorkstationContainer} guard={UserRoute} />
          <AuthenticatedRoute exact path={routes.notSupported} component={NotSupportedDevice} guard={UserRoute} />

          {/* Shared auth routes */}
          <AuthenticatedRoute exact path={routes.suite} component={AppScope} />

          {/* Organization Admin Routes */}
          <AuthenticatedRoute
            exact
            path={routes.organization}
            component={OrganizationContainer}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminHome}
            component={OrganizationAdminHome}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminCreatePlan}
            component={OrganizationAdminCreatePlan}
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminCreateComputers}
            component={OrganizationAdminCreateComputers}
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminPlans}
            component={OrganizationAdminPlans}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminTemplates}
            component={OrganizationAdminTemplates}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminCreateTemplate}
            component={OrganizationAdminCreateTemplate}
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminBilling}
            component={OrganizationAdminBilling}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={`${routes.organizationAdminMemberComputer}/:id`}
            component={OrganizationComputerContainer}
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminFiles}
            component={OrganizationFilesContainer}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminStats}
            component={OrganizationAdminStats}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminSettings}
            component={OrganizationAdminSettings}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminPermissions}
            component={OrganizationAdminPermissions}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationAdminUsageAnalytics}
            component={OrganizationAdminUsageAnalytics}
            dashboardRoute
            guard={OrganizationAdminRoute}
          />

          {/* Organization Member Routes */}
          <AuthenticatedRoute
            exact
            path={routes.organizationMemberTeamInfo}
            component={OrganizationMemberTeamInfo}
            dashboardRoute
            guard={OrganizationMemberRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationMemberHome}
            component={OrganizationMemberHome}
            dashboardRoute
            guard={OrganizationMemberRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationMemberComputer}
            component={OrganizationComputerContainer}
            guard={OrganizationMemberRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationMemberFiles}
            component={OrganizationFilesContainer}
            dashboardRoute
            guard={OrganizationMemberRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationMemberSessions}
            component={OrganizationMemberSessions}
            dashboardRoute
            guard={OrganizationMemberRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationMemberBilling}
            component={OrganizationMemberBilling}
            dashboardRoute
            guard={OrganizationMemberRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.organizationMemberSettings}
            component={OrganizationMemberSettings}
            dashboardRoute
            guard={OrganizationMemberRoute}
          />

          {/* AppStreaming Route */}
          <AuthenticatedRoute
            exact
            path={routes.appStreamingLanding}
            component={AppStreamingLanding}
            dashboardRoute
            guard={AppStreamingRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.appStreamingHome}
            component={AppStreamingHomeContainer}
            dashboardRoute
            guard={AppStreamingRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.appStreamingStreams}
            component={AppStreamingStreamsContainer}
            dashboardRoute
            guard={AppStreamingRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.appStreamingBilling}
            component={AppStreamingBillingContainer}
            dashboardRoute
            guard={AppStreamingRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.appStreamingUsers}
            component={AppStreamingUsersContainer}
            dashboardRoute
            guard={AppStreamingRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.appStreamingSendTokenToPlugin}
            component={AppStreamingSendTokenToPluginContainer}
          />
          <AuthenticatedRoute
            exact
            path={routes.appStreamingSettings}
            component={AppStreamingSettings}
            dashboardRoute
            guard={AppStreamingRoute}
          />
          <AuthenticatedRoute
            exact
            path={routes.appStreamingConfigureStream}
            component={ConfigureStream}
            dashboardRoute
            guard={AppStreamingRoute}
          />
          {/* Login and Register Route. */}
          <AuthenticationRoute path={routes.login} component={LoginContainer} />
          <Redirect exact from={routes.appStreamingLogin} to={routes.login} />
          <AuthenticationRoute exact path={routes.forgetPassword} component={ForgetPasswordContainer} />
          <AuthenticationRoute exact path={routes.forgetPasswordReset} component={ForgetPasswordResetContainer} />
          <AuthenticationRoute exact path={routes.register} component={RegisterContainer} />
          <AuthenticationRoute exact path={routes.appStreamingRegister} component={RegisterContainer} />
          <AuthenticationRoute exact path={routes.verifyRegister} component={VerifyRegisterContainer} />
          <AuthenticationRoute exact path={routes.verifyStreamRegister} component={VerifyRegisterContainer} />
          <AuthenticationRoute
            path={routes.appStreamingLoginFromPlugin}
            component={AppStreamingLoginFromPluginContainer}
          />
          {/* No Authentication Required. */}
          <UnAuthenticatedRoute exact path={routes.appStreaming} component={AppStreamingContainer} />
          <UnAuthenticatedRoute exact path={routes.appStreamingPixel} component={PixelStreamingContainer} />
          <UnAuthenticatedRoute exact path={routes.appStreamingRender} component={RenderStreamingContainer} />
          <UnAuthenticatedRoute exact path={routes.appStreamingSessionExpired} component={SessionExpired} />
          <UnAuthenticatedRoute exact path={routes.appStreamingError} component={AppStreamingError} />
          <UnAuthenticatedRoute
            exact
            path={routes.organizationPublicComputerExpired}
            component={PublicComputersError}
          />
          <UnAuthenticatedRoute
            exact
            path={[
              routes.workstationDuplicate,
              routes.workstation404,
              routes.appStreamingInstallationFailed,
              routes.appStreamingSessionIdle,
              routes.workstationConnectionFailed,
            ]}
            component={WorkstationError}
          />
          <UnAuthenticatedRoute
            exact
            path={routes.organizationPublicComputer}
            component={PublicOrganizationComputerContainer}
          />
          {/* <UnAuthenticatedRoute exact path={routes.maintenance} component={Maintenance} /> */}
          {/* Not Found Component */}
          <Route component={NotFoundComponent} />

          {/* Login and Register Route. */}
          <AuthenticationRoute path={routes.login} component={LoginContainer} />
          <Redirect exact from={routes.appStreamingLogin} to={routes.login} />
          <AuthenticationRoute exact path={routes.forgetPassword} component={ForgetPasswordContainer} />
          <AuthenticationRoute exact path={routes.forgetPasswordReset} component={ForgetPasswordResetContainer} />
          <AuthenticationRoute exact path={routes.register} component={RegisterContainer} />
          <AuthenticationRoute exact path={routes.appStreamingRegister} component={RegisterContainer} />
          <AuthenticationRoute exact path={routes.verifyRegister} component={VerifyRegisterContainer} />
          <AuthenticationRoute exact path={routes.verifyStreamRegister} component={VerifyRegisterContainer} />
          <AuthenticationRoute
            path={routes.appStreamingLoginFromPlugin}
            component={AppStreamingLoginFromPluginContainer}
          />
          {/* Not Found Component */}
          <Route component={NotFoundComponent} />
        </Switch>
      </IntercomRouteWrapper>
    </Sentry.ErrorBoundary>
  </BrowserRouter>
);
