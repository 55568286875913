import { Logger } from "Utils";

export default class SDKInternal {
  static listeners = [];

  static started = false;

  // WebsocketConnection object
  static connection = null;

  static start(websocketConnection, internalMessageCallback = null) {
    this.connection = websocketConnection;
    window.addEventListener("message", (evt) => {
      if (!document || !document.referrer) return;
      // only receive messages from parent
      const parentOrigin = new URL(document.referrer).origin;
      if (evt.origin !== parentOrigin) return;

      if (!evt.data?.target) return;

      if (evt.data.target === "vagon-streams-sdk-internal") {
        if (internalMessageCallback) internalMessageCallback(evt.data.payload);
        return;
      }

      if (evt.data.target !== "vagon-streams-sdk") return;

      // TODO: Only receive messages from a verified origin
      this.connection.sendApplicationEvent(evt.data.payload);
    });
  }

  static sendToAllParentIframes(msg) {
    let currentWindow = window;
    while (currentWindow !== window.top) {
      currentWindow.parent.postMessage(msg, "*");
      currentWindow = currentWindow.parent;
    }
  }

  static setConnected(val) {
    // TODO: Add an onOpen callback to websocket to call this
    if (val === true) this.sendToAllParentIframes("##vagonConnect");
    else this.sendToAllParentIframes("##vagonDisconnect");
  }
}
