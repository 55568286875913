import { useMutation } from "@tanstack/react-query";
import ModalResult from "Components/Organization/Admin/Components/ModalResult/ModalResult.component";
import { API_ENDPOINTS } from "Constants/api.constants";
import { ConfirmationModal } from "UI";
import { apiGenerator } from "Utils";
import React from "react";

const RevokeBalanceModal = ({ seat, onClose, translate }) => {
  const revokeBalanceMutation = useMutation({
    mutationFn: (id) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS_REVOKE_BALANCE(id));
    },
  });

  const { deposited_amount: depositedAmount } = seat?.attributes || {};

  if (revokeBalanceMutation.isSuccess) {
    return (
      <ModalResult
        success={revokeBalanceMutation.isSuccess}
        successHeader={translate("organizationHome.confirmationModals.revokeBalance.success.header")}
        successDescription={translate("organizationHome.confirmationModals.revokeBalance.success.description")}
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      headerText={translate("organizationHome.confirmationModals.revokeBalance.confirmation.header")}
      descriptionText={translate("organizationHome.confirmationModals.revokeBalance.confirmation.description", {
        amount: depositedAmount,
      })}
      closeAction={() => {
        revokeBalanceMutation.reset();
        onClose();
      }}
      confirmText="Confirm"
      confirmAction={() => {
        revokeBalanceMutation.mutate(seat.id);
      }}
      pending={revokeBalanceMutation.isLoading}
    />
  );
};

export default RevokeBalanceModal;
