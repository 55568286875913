import React, { useMemo, useState } from "react";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { Button, IconButton, Modal, NumberInput, HoverableTooltip, Icon } from "UI";
import { classnames, useAPI } from "Utils";

import "./AppBudgetModal.styles.scss";

const MAX_CUSTOM_AMOUNT_FOR_VENDOR = 5000;
const MIN_CUSTOM_AMOUNT = 0;

const AppBudgetModal = ({
  selectedAppID,
  setShowAppBudgetModal,
  getApplicationsCTX,
  getApplicationsAPI,
  appStreaming,
  translate,
}) => {
  const [budgetLimit, setbudgetLimit] = useState(0);

  const applicationLimit = getApplicationsCTX?.data?.applications;
  const selectedApplication = useMemo(() => {
    return applicationLimit.find((app) => app.id === selectedAppID);
  }, [applicationLimit, selectedAppID]);
  const currentBudget = selectedApplication?.attributes?.budget;
  const budgetRatio = currentBudget?.attributes?.ratio;
  const currentBudgetLimit = currentBudget?.attributes?.limit;
  const applications = appStreaming?.getStreamsCTX?.data?.applications;
  const applicationStreams = useMemo(() => {
    const application = applications.find((app) => app.id === selectedApplication.id);
    return application?.attributes?.streams;
  }, [applications, selectedApplication]);
  const streamLimit = applicationStreams?.[0]?.attributes?.budget?.attributes?.limit;

  const { api: getApplicationBudgetAPI } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_BUDGET_APPLICATION_LIMIT(selectedAppID),
    type: "put",
    onSuccess: () => {
      getApplicationsAPI();
    },
  });

  return (
    <>
      <Modal
        className="app-budget-modal"
        closeOnOverlayClick
        topRightIcon="close"
        topRightIconAction={() => setShowAppBudgetModal(false)}
        headerText={translate("appStreamingModals.budgetLimitModal.header")}
        descriptionText={translate("appStreamingModals.budgetLimitModal.description")}
        closeAction={() => setShowAppBudgetModal(false)}
      >
        <div className="app-budget-container">
          <div className={classnames(["app-budget-input", currentBudgetLimit && "disabled"])}>
            <div className="app-budget-input-header">
              <h1>{translate("appStreamingModals.budgetLimitModal.budgetLimit.header")}</h1>
              <HoverableTooltip content="Calculations may not be precise" side="right">
                <Icon name="info" color="gray-4" smaller />
              </HoverableTooltip>
            </div>
            <div className="app-budget-input-container">
              <NumberInput
                value={parseFloat(currentBudgetLimit) || budgetLimit}
                minInputValue={MIN_CUSTOM_AMOUNT}
                maxInputValue={MAX_CUSTOM_AMOUNT_FOR_VENDOR}
                onChange={(event) => setbudgetLimit(event)}
                disabled={currentBudgetLimit}
              />
              <Button
                text={translate("appStreamingModals.budgetLimitModal.budgetLimit.button")}
                color="light-navy"
                onClick={() => {
                  getApplicationBudgetAPI({ limit: budgetLimit });
                }}
                disabled={currentBudgetLimit}
              />
            </div>
          </div>
          {currentBudgetLimit && (
            <div className="app-budget-status" style={{ border: budgetRatio >= 100 && "1px solid #E08989" }}>
              <div className="app-budget-descriptions">
                <h1 style={{ color: budgetRatio >= 100 && "#E08989" }}>
                  {translate(
                    `appStreamingModals.budgetLimitModal.info.header.${budgetRatio >= 100 ? "reached" : "active"}`,
                  )}
                </h1>
                <p>
                  {budgetRatio >= 100
                    ? translate(
                        `appStreamingModals.budgetLimitModal.info.reached.${
                          streamLimit && currentBudgetLimit ? "multipleBudget" : "singleBudget"
                        }`,
                      )
                    : translate(
                        `appStreamingModals.budgetLimitModal.info.active.${
                          streamLimit && currentBudgetLimit ? "multipleBudget" : "singleBudget"
                        }`,
                      )}
                </p>
              </div>

              <div className="app-budget-icons">
                <HoverableTooltip content="Delete">
                  <IconButton
                    name="close"
                    color="gray-3"
                    style={{ height: "1.2rem", width: "1.5rem" }}
                    onClick={() => {
                      getApplicationBudgetAPI({ limit: 0 });
                    }}
                  />
                </HoverableTooltip>
                {budgetRatio >= 100 && (
                  <HoverableTooltip content="Reactivate">
                    <IconButton
                      name="refresh"
                      style={{ height: "1.2rem", width: "1.5rem" }}
                      onClick={() => {
                        getApplicationBudgetAPI({ limit: currentBudgetLimit });
                      }}
                    />
                  </HoverableTooltip>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AppBudgetModal;
