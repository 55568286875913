import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { Button, Icon, Tooltip, BoardingModal } from "UI";
import {
  isMobile,
  isStandaloneMode,
  multiGpuConsentModalShouldOpen,
  saveItemToLocalStorage,
  apiGenerator,
} from "Utils";
import AddHomeScreenTipImage from "Assets/images/homeScreenTip.png";
import ProcessPaymentModal from "Utils/Components/Payment/ProcessPaymentModal/ProcessPaymentModal.component";
import { WORKSTATION_STATUS, WORKSTATION_FRIENDLY_STATUS, LOCAL_STORAGE } from "Constants/global.constants";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useMutation } from "@tanstack/react-query";
import MultiGpuConsentModal from "Utils/Components/MultiGpuConsentModal/MultiGpuConsentModal.component";
import { removeItemFromLocalStorage } from "Utils/Helpers/storage.helpers";

const RESET_ERROR_TIMEOUT = 6000;

const ComputerActionButton = (props) => {
  const {
    account,
    getWorkstationMachineTypesCTX,
    history,
    workstation,
    selectedMachineType,
    isInGracePeriod,
    isInTeamGracePeriod,
    shouldSavePerformance,
    setShouldShowMachineTypeSelection,
    setUserMultiGpuConsentAPI,
    getWorkstationsAPI,
    translate,
  } = props;

  const { status, friendly_status: friendlyStatus } = workstation.attributes.machine.attributes;
  const [isLoading, setIsLoading] = React.useState(false);

  const [shouldDisplayShutDownAction, setShouldDisplayShutDownAction] = React.useState(
    status === WORKSTATION_STATUS.RUNNING,
  );
  const [showShortcutModal, setShowShortcutModal] = React.useState(false);
  const [shouldShowProcessPaymentModal, setShouldShowProcessPaymentModal] = React.useState(false);
  const [shouldRunComputer, setShouldRunComputer] = React.useState(false);
  const [showMultiGpuConsentModal, setShowMultiGpuConsentModal] = useState(false);

  const updateMachineStatusMutation = useMutation({
    mutationFn: ({ status, machineTypeId }) => {
      const endpoint = `${API_ENDPOINTS.WORKSTATIONS}/${status}`;
      const api = apiGenerator("post")(endpoint, status === "start" && { machine_type_id: machineTypeId });
      return api;
    },
    onSuccess: (data, params) => {
      const { status } = params;

      getWorkstationsAPI();

      if (status === "stop") {
        setShouldDisplayShutDownAction(false);
      }
    },
  });

  const hasError = updateMachineStatusMutation.isError;

  React.useEffect(() => {
    let resetErrorTimeout;

    if (updateMachineStatusMutation.isError) {
      resetErrorTimeout = setTimeout(() => {
        updateMachineStatusMutation.reset();
      }, RESET_ERROR_TIMEOUT);
    }

    return () => {
      clearTimeout(resetErrorTimeout);
    };
  }, [updateMachineStatusMutation.status]);

  React.useEffect(() => {
    if (
      [
        WORKSTATION_FRIENDLY_STATUS.CREATING,
        WORKSTATION_FRIENDLY_STATUS.TURNING_ON,
        WORKSTATION_FRIENDLY_STATUS.TURNING_OFF,
        WORKSTATION_FRIENDLY_STATUS.RESIZING_DISK,
        WORKSTATION_FRIENDLY_STATUS.INSTALLING,
        WORKSTATION_FRIENDLY_STATUS.REGION_MIGRATION,
        WORKSTATION_FRIENDLY_STATUS.WARMING_UP,
        WORKSTATION_FRIENDLY_STATUS.WAITING_FOR_GPUS,
      ].includes(friendlyStatus)
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [friendlyStatus]);

  React.useEffect(() => {
    if (
      [
        WORKSTATION_FRIENDLY_STATUS.TURNING_OFF,
        WORKSTATION_FRIENDLY_STATUS.RESIZING_DISK,
        WORKSTATION_FRIENDLY_STATUS.INSTALLING,
        WORKSTATION_FRIENDLY_STATUS.REGION_MIGRATION,
      ].includes(friendlyStatus)
    ) {
      setShouldDisplayShutDownAction(false);
    } else if (status === WORKSTATION_STATUS.RUNNING) {
      setShouldDisplayShutDownAction(true);
    } else if (status === WORKSTATION_STATUS.STOPPING) {
      setShouldDisplayShutDownAction(false);
    }
  }, [friendlyStatus, status]);

  const determineButtonText = () => {
    if (isInTeamGracePeriod) {
      return translate("home.actionButton.buttonText.teamGrace");
    }

    if (isInGracePeriod) {
      return translate("home.actionButton.buttonText.grace");
    }

    if (isLoading && friendlyStatus === WORKSTATION_FRIENDLY_STATUS.OFF) {
      return translate(`home.actionButton.buttonText.${WORKSTATION_FRIENDLY_STATUS.TURNING_ON}`);
    }

    if (isLoading && friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY) {
      return translate(`home.actionButton.buttonText.${WORKSTATION_FRIENDLY_STATUS.TURNING_OFF}`);
    }

    return translate(`home.actionButton.buttonText.${friendlyStatus}`);
  };

  const determineButtonIcon = () => {
    if (isInGracePeriod) {
      return null;
    } else if (isLoading) {
      return "loader";
    } else if (friendlyStatus === WORKSTATION_FRIENDLY_STATUS.OFF) {
      return "play";
    }

    return null;
  };

  const determineButtonHoverText = () => {
    if (hasError) {
      const { client_code: clientCode } = updateMachineStatusMutation.error.response.data;

      return (
        <Translate
          id={`home.actionButton.error.${clientCode}`}
          options={{ onMissingTranslation: () => translate("home.actionButton.error.default") }}
        />
      );
    }

    return null;
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const sessionMachineType = sessionStorage && sessionStorage.getItem(LOCAL_STORAGE.machineType);

    if (isInGracePeriod) {
      setShouldShowProcessPaymentModal(true);
    } else if (status === "stopped" && WORKSTATION_FRIENDLY_STATUS.OFF === friendlyStatus) {
      if (shouldSavePerformance || shouldRunComputer || sessionMachineType || isMobile) {
        const state = multiGpuConsentModalShouldOpen(account, getWorkstationMachineTypesCTX, selectedMachineType);
        setShowMultiGpuConsentModal(state);
        if (state) return;

        updateMachineStatusMutation.mutate({
          status: "start",
          machineTypeId: selectedMachineType,
        });
        setShouldRunComputer(false);
        saveItemToLocalStorage(LOCAL_STORAGE.vrEnvironmentEnabled, false);
        removeItemFromLocalStorage(LOCAL_STORAGE.vrInstallationPercentage);
      } else {
        setShouldShowMachineTypeSelection(true);
        setShouldRunComputer(true);
      }
    } else if (WORKSTATION_FRIENDLY_STATUS.READY === friendlyStatus) {
      if (isMobile && !isStandaloneMode) {
        setShowShortcutModal(true);
      } else {
        history.push(routes.workstation);
      }
    }
  };

  const shutDown = () => {
    updateMachineStatusMutation.mutate({
      status: "stop",
    });
  };

  return (
    <>
      <div className="computer-action-button-container breath">
        <div className="computer-action-button-text">
          {shouldDisplayShutDownAction && (
            <div role="button" className="shut-down-action" onClick={shutDown} onKeyDown={shutDown} tabIndex="-1">
              {translate("home.actionButton.secondaryText.shutDown")}
            </div>
          )}
          {friendlyStatus === WORKSTATION_FRIENDLY_STATUS.TURNING_ON && !shouldDisplayShutDownAction && (
            <div className="description-secondary">{translate("home.actionButton.secondaryText.pending")}</div>
          )}
          {friendlyStatus === WORKSTATION_FRIENDLY_STATUS.INSTALLING && (
            <div className="description-secondary">{translate("home.actionButton.secondaryText.installing")}</div>
          )}
          {isInGracePeriod && (
            <div className="description-secondary">{translate("home.actionButton.secondaryText.graceDescription")}</div>
          )}
          {isInTeamGracePeriod && (
            <div className="description-secondary">
              {translate("home.actionButton.secondaryText.teamGraceDescription")}
            </div>
          )}
          {hasError && (
            <div className="vg-tooltip-hoverable error-container">
              <Icon name="error" color="white" />
              <p>{translate("home.actionButton.buttonText.error")}</p>
              <Tooltip position="left" content={determineButtonHoverText()} showTip />
            </div>
          )}
        </div>
        <Button
          text={determineButtonText()}
          icon={determineButtonIcon()}
          onClick={handleClick}
          disabled={
            updateMachineStatusMutation.isLoading ||
            isLoading ||
            !selectedMachineType ||
            isInTeamGracePeriod ||
            updateMachineStatusMutation.isError
          }
          breath={isLoading && friendlyStatus !== WORKSTATION_FRIENDLY_STATUS.TURNING_OFF}
          tabIndex="0"
        />
      </div>
      {shouldShowProcessPaymentModal && (
        <ProcessPaymentModal
          setShouldShowProcessPaymentModal={setShouldShowProcessPaymentModal}
          translate={translate}
        />
      )}
      {showShortcutModal && (
        <BoardingModal
          headerImages={[AddHomeScreenTipImage]}
          headerTexts={[translate("home.shortcutModal.header")]}
          buttonTexts={[translate("home.shortcutModal.buttonText")]}
          descriptions={[translate("home.shortcutModal.description")]}
          buttonAction={() => {
            setShowShortcutModal(false);
          }}
        />
      )}
      {showMultiGpuConsentModal && (
        <MultiGpuConsentModal
          translate={translate}
          setShowMultiGpuConsentModal={setShowMultiGpuConsentModal}
          setUserMultiGpuConsentAPI={setUserMultiGpuConsentAPI}
          updateMachineStatusMutation={updateMachineStatusMutation}
          setShouldRunComputer={setShouldRunComputer}
          selectedMachineType={selectedMachineType}
        />
      )}
    </>
  );
};

export default withRouter(ComputerActionButton);
