import React from "react";
import { IconButton, Modal } from "UI";

import "./ScreenShotModal.styles.scss";

const ScreenShotModal = ({ screenShot, onClose }) => {
  return (
    <Modal
      big
      className="screen-shot-modal"
      closeAction={onClose}
      topRightIcon="close"
      headerText="Computer Screen Shot"
      topRightIconAction={onClose}
    >
      <div className="screenshot-container">
        <img src={screenShot} alt="Screenshot" />
        <IconButton
          name="fullscreen"
          color="black"
          small
          onClick={() => {
            // Open a new window
            const newWindow = window.open("", "_blank");

            // Write the image into the body of the new window's document
            newWindow.document.write(`
              <style>
                body { margin: 0; }
                img { width: 100vw; height: 100vh; object-fit: contain; }
              </style>
              <img src="${screenShot}" alt="Screenshot" />
            `);
          }}
        />
      </div>
    </Modal>
  );
};

export default ScreenShotModal;
