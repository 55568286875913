import React, { useState } from "react";
import _ from "lodash";
import { AUTO_STOP_OPTIONS, WORKSTATION_FRIENDLY_STATUS, LOCAL_STORAGE } from "Constants/global.constants";
import { Dropdown, Icon, HoverableTooltip } from "UI";
import { apiGenerator, ConnectionStrength } from "Utils";
import AfterCreationOnboardingModal from "Components/Dashboard/AfterCreationOnboardingModal/AfterCreationOnboardingModal.component";

import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";

import ComputerOverviewItem from "./ComputerOverviewItem.component";

const determineAutoTurnOffSettingByWorkstation = (autoStopThreshold) => {
  const autoStopOption = _.findKey(AUTO_STOP_OPTIONS, (option) => option === autoStopThreshold);
  return autoStopOption || "OFF";
};

const ComputerOverview = ({
  seat,
  machine,
  regions,
  diskSize,
  setShowMachineTypeSelection,
  isInGracePeriod,
  refetchMachine,
  translate,
}) => {
  const { friendly_status: friendlyStatus, region } = machine?.attributes || {};

  const { auto_stop_threshold: autoStopThreshold } = seat?.attributes || {};

  const setMachineRegionMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_REGION(machine.id), data);
    },
    onSuccess: () => {
      refetchMachine();
    },
  });

  const updateSeatMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATIONS_SEAT(seat.id), data);
    },
  });

  const [autoTurnOffSetting, setAutoTurnOffSetting] = React.useState(
    determineAutoTurnOffSettingByWorkstation(autoStopThreshold),
  );

  const [showAfterCreationOnboardingModal, setShowAfterCreationOnboardingModal] = useState(null);

  const selectAutoTurnOffOption = (autoTurnOffSetting) => {
    setAutoTurnOffSetting(autoTurnOffSetting);
    updateSeatMutation.mutate({
      auto_stop_threshold: AUTO_STOP_OPTIONS[autoTurnOffSetting],
    });
  };

  const { friendly_name: machineName } = machine?.attributes?.machine_type?.attributes || {};

  const isButtonsDisabled = isInGracePeriod || friendlyStatus !== WORKSTATION_FRIENDLY_STATUS.OFF;

  return (
    <div className="workstation-overview">
      <div className="row">
        <ComputerOverviewItem
          label={translate("home.workstationOverview.performance.label")}
          content={machineName || "..."}
          action={() => {
            setShowMachineTypeSelection(true);
          }}
          actionText={translate("home.workstationOverview.performance.actionText")}
          disabled={isButtonsDisabled || !seat}
          classSelector="workstation-performance-area"
        />

        <ComputerOverviewItem
          label={translate("home.workstationOverview.storage.label")}
          content={seat ? `${diskSize} GB` : "..."}
          classSelector="workstation-storage-area"
          disabled={!seat}
        />
      </div>
      <div className="row">
        <ConnectionStrength
          regions={region ? [region] : regions}
          setRegion={(bestRegion) => {
            if (!region) {
              setMachineRegionMutation.mutate({ region: bestRegion });
              refetchMachine();
            }
          }}
        >
          {({ connectionStrength }) => {
            return (
              <ComputerOverviewItem
                label={translate("home.workstationOverview.connectionPerformance.label")}
                content={translate(`connectionStrength.${connectionStrength}`)}
                actionText={
                  <HoverableTooltip content={translate("home.workstationOverview.connectionPerformance.info")}>
                    <Icon name="info" color="gray-4" />
                  </HoverableTooltip>
                }
              />
            );
          }}
        </ConnectionStrength>

        <ComputerOverviewItem
          label={translate("home.workstationOverview.runInBackground.label")}
          content={
            <Dropdown
              defaultSelected={autoTurnOffSetting}
              options={Object.keys(AUTO_STOP_OPTIONS)}
              mapFunction={(option) => {
                return translate(`autoStopOption.${option}`);
              }}
              handleSelectedOptionChange={selectAutoTurnOffOption}
              disabled={isInGracePeriod || !seat}
            />
          }
        />
      </div>
      {showAfterCreationOnboardingModal && (
        <AfterCreationOnboardingModal
          translate={translate}
          onExit={() => {
            setShowAfterCreationOnboardingModal(false);
            localStorage.setItem(LOCAL_STORAGE.showAfterCreationOnboardingModal, false);
          }}
        />
      )}
    </div>
  );
};

export default ComputerOverview;
