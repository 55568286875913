import React, { useRef, useState } from "react";
import _ from "lodash";
import { Modal } from "UI";
import { FILE_ERROR, UPLOAD_TYPES } from "Constants/global.constants";
import { createFolder, uploadFilesToFolder } from "Actions/Dashboard/Files.actions";
import { Tracker } from "Utils";
import * as MessageHelper from "Utils/Workstation/messageHelper";
import { canUploadFiles, containsEmptyFile } from "Utils/Helpers/file.helpers";
import { useDragged, useResetError } from "Utils/Hooks";
import { Translate } from "react-localize-redux";
import { useDispatch } from "react-redux";
import FileStatus from "../FileStatus/FileStatus.component";
import FileUploadArea from "../FileUploadArea/FileUploadArea.component";
import "./FileUploadModal.styles.scss";

const FileUploadModal = ({
  files,
  onFileDrop,
  cancelFileUpload,
  setShowFileUploadModal,
  descriptionText,
  mobile,
  searchInput,
  currentFolderId,
  isInWorkstation,
  isFolder,
  organizationMachineId,
  translate,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [error, setError] = useState(false);

  const { isDragActive, onDragOver, onDragEnter, onDragLeave, resetDrag } = useDragged();
  useResetError(error, setError);

  const checkFiles = (files) => {
    if (canUploadFiles(files)) {
      if (!containsEmptyFile(files)) {
        onFileDrop(files);
      } else {
        setError(FILE_ERROR.EMPTY_FILE_NOT_ALLOWED);
      }
    }

    resetDrag();
  };

  const onCancel = () => {
    setShowFileUploadModal(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { items, files } = event.dataTransfer;
    _.forEach(items, (item) => {
      const fileEntry = item.webkitGetAsEntry();
      if (fileEntry) {
        if (fileEntry.isFile) {
          const file = _.find(files, (fileItem) => fileItem.name === fileEntry.name);
          dispatch(
            uploadFilesToFolder({
              files: [file],
              currentFolderId: searchInput ? null : currentFolderId,
              organizationMachineId,
              uploadType: organizationMachineId ? UPLOAD_TYPES.WORKSTATION_UPLOAD : UPLOAD_TYPES.FILE_UPLOAD,
            }),
          );
        } else {
          dispatch(
            createFolder(fileEntry.name, searchInput ? null : currentFolderId, fileEntry, organizationMachineId),
          );
          if (isInWorkstation) setError("folderNotAllowed");
        }
      }
    });
    resetDrag();
  };

  const handleFileInput = (event) => {
    const { files } = event.target;
    checkFiles(files);
  };

  const isUploadingFile = files && _.findKey(files, (file) => !file.cancelled);

  const isAllFileCompleted = _.every(
    files,
    (file) => file.status === MessageHelper.fileInfoMessageActionTypes.fileUploadCompleted,
  );

  return (
    <Modal
      closeOnOverlayClick
      closeAction={onCancel}
      topRightIcon="close"
      topRightIconAction={onCancel}
      headerText={translate("modals.fileUpload.header")}
      descriptionText={descriptionText || translate("modals.fileUpload.description")}
      buttonText={translate("modals.fileUpload.confirm")}
      onDrop={handleDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      buttonDisabled={!isAllFileCompleted}
      mobile={mobile}
      fitContent
      blue
    >
      <div className="file-upload-container" ref={containerRef}>
        {isUploadingFile && !isDragActive && !error ? (
          <div className="file-list">
            {_.map(Object.keys(files), (fileName) => {
              const file = files[fileName];
              const { status, size, progress } = file;
              let errorReason = error && (
                <Translate
                  id={`modals.fileUpload.error.${error}`}
                  options={{ onMissingTranslation: () => translate("modals.fileUpload.error.default") }}
                />
              );
              errorReason = isFolder && (
                <Translate
                  id="modals.fileUpload.error.folderNotAllowedOverlay"
                  options={{ onMissingTranslation: () => translate("modals.fileUpload.error.default") }}
                />
              );
              // errorCheck();
              return (
                <FileStatus
                  key={fileName}
                  fileName={fileName}
                  fileSize={size}
                  fileStatus={status}
                  fileProgress={progress}
                  errorReason={errorReason}
                  onCancel={cancelFileUpload}
                  containerRef={containerRef}
                />
              );
            })}
          </div>
        ) : (
          <FileUploadArea
            id="file"
            handleFileInput={handleFileInput}
            error={error}
            isDragActive={isDragActive}
            translate={translate}
          />
        )}
      </div>
    </Modal>
  );
};

export default FileUploadModal;
