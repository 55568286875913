import React, { useState, useEffect } from "react";
import { Icon } from "UI";

import "./ComputerSpace.styles.scss";

const ComputerSpace = (props) => {
  const { baseSpace, totalSpace, fullSpace, error } = props;

  const [usedSpace, setUsedSpace] = useState(0);
  const [full, setFull] = useState(false);

  useEffect(() => {
    if (fullSpace >= totalSpace) {
      setUsedSpace(totalSpace);
      setFull(true);
    } else {
      setUsedSpace(fullSpace);
      setFull(false);
    }
  }, [fullSpace, totalSpace]);

  const baseSpaceBar = Math.max((baseSpace / totalSpace) * 100, 15);
  const usedSpaceBar = (usedSpace / totalSpace) * 100;

  return (
    <div className="computer-space-container">
      <div className="available-space mobile-only">
        <Icon name="info" color="gray-3" /> <p>Available Space: {(totalSpace - fullSpace).toFixed(1)} GB</p>
      </div>
      <div className="computer-space">
        <div className="computer-space-bar empty" />
        <div className={`computer-space-bar used ${full ? "full" : ""}`} style={{ width: `${usedSpaceBar}%` }} />
        <div className={`computer-space-bar base ${full ? "full" : ""}`} style={{ width: `${baseSpaceBar}%` }} />
        {/* <div className="computer-space-bar base" style={{ width: `${baseSpaceBar}%` }} /> */}
      </div>
      {error && <p className="error">{error}</p>}
      <div className="available-space mobile-only-hide">
        <Icon name="info" color="gray-3" /> <p>Available Space: {(totalSpace - fullSpace).toFixed(1)} GB</p>
      </div>
    </div>
  );
};

export default ComputerSpace;
