/* eslint-disable no-unneeded-ternary */
import AppMenuPopUp from "Components/AppStreaming/Home/AppMenuPopUp/AppMenuPopUp.component";
import React, { useEffect, useRef, useState } from "react";
import IconButton from "UI/IconButton/IconButton.component";
import defaultUserImage from "Assets/images/organizations/user.png";
import useImageUpload from "Utils/Hooks/useImageUpload";
import { apiGenerator, classnames, Loader } from "Utils";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getAccountAPI } from "Actions/Account.actions";

import "./ProfilePicture.styles.scss";

const ProfilePicture = (props) => {
  const { account, translate } = props;
  const [editButtonPopUpPosition, setEditButtonPopUpPosition] = useState(null);
  const [sizeLimitError, setSizeLimitError] = useState(false);
  const [file, setFile] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const imageRef = useRef();
  const profilePictureRef = useRef(null);
  const userPicture = account?.account?.attributes?.profile_picture_url || account?.attributes?.profile_picture_url;
  const name = account?.account?.attributes?.name || account?.attributes?.name;

  const dispatch = useDispatch();

  const { loading: isImageUploadLoading } = useImageUpload({
    uploadEndpoint: API_ENDPOINTS.ACCOUNT_USER_IMAGE_UPLOAD,
    completeUploadEndpoint: API_ENDPOINTS.ACCOUNT_USER_IMAGE_UPLOAD_COMPLETED,
    image: file,
    onSuccess: () => {
      dispatch(getAccountAPI());
    },
  });

  const { mutate: deleteImageMutation, isLoading: deleteLoading } = useMutation({
    mutationFn: () => {
      return apiGenerator("delete")(API_ENDPOINTS.ACCOUNT_USER_IMAGE_DELETE);
    },
    onSuccess: () => {
      dispatch(getAccountAPI());
      setEditButtonPopUpPosition(null);
    },
  });

  useEffect(() => {
    if (isImageUploadLoading === true || deleteLoading === true) {
      setShowLoader(true);
    }

    if (userPicture) {
      setShowLoader(false);
    }
  }, [isImageUploadLoading, userPicture, deleteLoading]);

  const handleEditButtonClick = (event) => {
    if (!editButtonPopUpPosition) event.stopPropagation();
    event.preventDefault();
    setEditButtonPopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
  };

  const photoUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const fileSizeInMB = Math.round(file.size / 1024 / 1000).toFixed(2);

    if (fileSizeInMB <= 2) {
      setFile(file);
      setSizeLimitError(false);
    } else {
      setSizeLimitError(true);
    }
    setEditButtonPopUpPosition(null);
  };

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const extractInitials = (name) => {
    const names = name.split(" ");
    if (!name) return "U";
    if (names[0]?.[0] && names[1]?.[0]) return (names[0][0] + names[1][0]).toUpperCase();
    return names[0][0].toUpperCase();
  };

  const decideUserProfileImage = () => {
    if (userPicture) {
      return <img src={userPicture} alt="user" />;
    } else if (name) {
      return <div className="profile-image">{extractInitials(name)}</div>;
    }
    return <img src={defaultUserImage} alt="user" />;
  };

  return (
    <>
      <div className="profile-picture" ref={profilePictureRef}>
        {showLoader ? <Loader /> : decideUserProfileImage()}
        <div className={classnames(["profile-edit-button", sizeLimitError && "shake"])} onClick={handleEditButtonClick}>
          <IconButton name="pencil" color="white-fill" small />
        </div>
        {sizeLimitError && <div className="upload-error">{translate("accountSettings.imageUpload.warning")}</div>}
        {editButtonPopUpPosition && (
          <AppMenuPopUp
            setShowPopUpMenu={() => {
              setEditButtonPopUpPosition(null);
            }}
            content={[
              {
                key: "upload-photo",
                text: (
                  <div className="upload-photo" style={{ display: "flex", gap: "0.6rem", alignItems: "baseline" }}>
                    <i className="far fa-file-image" />
                    {translate("accountSettings.information.popUpMenuActions.upload")}
                    <input
                      ref={imageRef}
                      accept="image/png, image/jpeg"
                      type="file"
                      style={{ display: "none" }}
                      onChange={photoUpload}
                    />
                  </div>
                ),
                onClick: () => {
                  showOpenFileDialog();
                },
              },
              {
                key: "delete-photo",
                text: (
                  <div style={{ display: "flex", gap: "0.6rem", alignItems: "baseline" }}>
                    <i className="far fa-trash-alt" />
                    {translate("accountSettings.information.popUpMenuActions.delete")}
                  </div>
                ),
                onClick: () => {
                  deleteImageMutation();
                },
                disabled: !userPicture,
              },
            ]}
            popUpPosition={editButtonPopUpPosition}
            referencePosRef={profilePictureRef}
            fixedToRight
          />
        )}
      </div>
    </>
  );
};

export default ProfilePicture;
