import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getOrganizationAPI } from "Actions/Organization.actions";
import OrganizationAdminStats from "Components/Organization/Admin/Stats/Stats.component";
import { getProductOnboardingAPI } from "Actions/Account.actions";

const StatsContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationAPI());
    dispatch(getProductOnboardingAPI());
  }, []);

  return <OrganizationAdminStats {...props} />;
};

export default StatsContainer;
