import SessionGraph from "Components/AppStreaming/SessionGraph.component";
import React from "react";
import { Icon, WorldMapCoverage } from "UI";
import { Loader } from "Utils";

import "./ActivityMonitor.styles.scss";

const ActivityMonitor = ({ getApplicationsStatsCTX, translate }) => {
  const customerSessions = getApplicationsStatsCTX.data?.session_stats?.map((dailyData) => {
    return {
      ...dailyData,
      time: dailyData.date.substring(11, 16),
    };
  });

  const activeStreams = getApplicationsStatsCTX.data?.running_machine_count;
  const onlineUsers = getApplicationsStatsCTX.data?.connected_machine_count;

  const regions = getApplicationsStatsCTX.data?.region_stats || [];
  const coveredRegions = regions.map((region) => region.region);
  const activeRegions = regions.filter((region) => region.count > 0);

  if (!getApplicationsStatsCTX.data) return <Loader />;

  return (
    <div className="activity-monitor-container">
      <div className="server-status">
        <h3>{translate("appStreamingDashboard.activity.server")}</h3>
        <WorldMapCoverage coveredRegions={coveredRegions} activeRegions={activeRegions} />
      </div>
      <div className="user-data">
        <h3>{translate("appStreamingDashboard.activity.user.header")}</h3>
        <div>
          <div className="info-item">
            <div className="aqua-light icon-background">
              <Icon name="team" color="aqua-main" small />
            </div>
            <div>
              <p>{onlineUsers}</p>
              <p>{translate("appStreamingDashboard.activity.user.users")}</p>
            </div>
          </div>
          <div className="info-item">
            <div className="baby-powder icon-background">
              <Icon name="location-pin" color="baby-powder" small />
            </div>
            <div>
              <p>{activeStreams}</p>
              <p>{translate("appStreamingDashboard.activity.user.activelyUsed")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="sessions">
        <h3>{translate("appStreamingDashboard.activity.sessions")}</h3>
        <div className="graph-container">
          <SessionGraph timeSlots={customerSessions} translate={translate} />
        </div>
      </div>
    </div>
  );
};

export default ActivityMonitor;
