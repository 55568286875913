import React, { useState } from "react";

import { HistoryTable, HistoryCard } from "UI";
import { Loader } from "Utils";

import "./BillingDetails.styles.scss";
import translate from "Stories/translate";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useAPI } from "Utils/Helpers/api.helpers";
import { classnames } from "Utils/Helpers/functions.helpers";

const BillingDetails = (props) => {
  const { headers, loading, objects, hideDetails, keys, fetchNextPage, isFetchingNextPage, hasNextPage } = props;

  const [selectedPayment, setSelectedPayment] = useState(0);
  const [invoiceId, setInvoiceId] = useState();

  const { api: downloadInvoiceAPI } = useAPI({
    type: "get",
    endpoint: API_ENDPOINTS.PAYMENT_INVOICE,
    onSuccess: (data) => {
      window.open(data.invoice_url, "_blank");
    },
  });

  if (loading) {
    return <Loader big centered />;
  }

  const handleEnterKeyUp = (event) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      downloadInvoiceAPI(null, null, invoiceId);
    }
  };

  return (
    <>
      <div className="payment-history-table">
        <div className={classnames(["history-table", invoiceId === selectedPayment && "selected"])}>
          <HistoryTable
            headers={headers}
            keys={keys}
            data={objects}
            onClick={(object) => {
              setSelectedPayment(object);
              setInvoiceId(objects[object].id);
            }}
            selectedDataId={invoiceId}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            payment
          />
        </div>
        <div className="history-details">
          {!hideDetails && (
            <table className="payment-details">
              <thead>
                <tr>
                  <th>{translate("billing.paymentDetails")}</th>
                </tr>
              </thead>
              {invoiceId && objects[selectedPayment] && objects[selectedPayment].items && (
                <tbody>
                  {objects[selectedPayment].items.map((item) => (
                    <tr key={item.id}>
                      <td>{item.description}</td>
                      <td>{`${item.amount < 0 ? "- " : ""} $ ${Math.abs(item.amount).toFixed(2)}`}</td>
                    </tr>
                  ))}
                  <tr className="total">
                    <td> {translate("billing.total")}</td>
                    <td>{objects[selectedPayment].totalAmount}</td>
                  </tr>
                </tbody>
              )}
            </table>
          )}
          {invoiceId && !hideDetails && (
            <p
              className="download-invoice-button"
              onClick={() => {
                downloadInvoiceAPI(null, null, invoiceId);
              }}
              tabIndex={0}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              onKeyUp={handleEnterKeyUp}
            >
              Download Invoice
            </p>
          )}
        </div>
      </div>
      <div className="payment-history-card">
        {objects.map((object) => (
          <HistoryCard key={object.id} headers={headers} keys={keys} item={object} />
        ))}
      </div>
    </>
  );
};

export default BillingDetails;
