import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { payment3DSecureAPI } from "Actions/Dashboard/Payment.actions";
import { REQUEST_STATUS } from "Constants/global.constants";

import "./Payment3DSecureModal.styles.scss";

const StripePaymentFrame = (props) => {
  const { onFailure, onSuccess, clientSecret } = props;

  const stripe = useStripe();
  const elements = useElements();

  const user = useSelector((state) => state.account.account);

  const onAuthenticationComplete = (e) => {
    if (e.data === "3DS-authentication-complete") {
      e.preventDefault();
      on3DSComplete();
    }
  };

  useEffect(() => {
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("message", onAuthenticationComplete);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (stripe && elements) {
      // Add listener for iframe
      window.addEventListener("message", onAuthenticationComplete);
      // Start 3D payment process
      processPayment();
    }
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("message", onAuthenticationComplete);
    };
  }, [elements, stripe]); // eslint-disable-line

  useEffect(() => {
    if (props.payment.payment3DSecureCTX.status === REQUEST_STATUS.SUCCESS) {
      if (!onSuccess) {
        props.setShowPayment3DSecure(false);
      }
    }
  }, [props.payment.payment3DSecureCTX.status]); // eslint-disable-line

  const on3DSComplete = () => {
    // Hide the 3DS UI
    if (stripe) {
      // Remove iframe
      const frame = document.getElementById("payment-frame");

      // Check the PaymentIntent
      stripe
        .retrievePaymentIntent(clientSecret || props.payment.payment3DSecureCTX.data.client_secret)
        .then((result) => {
          let errorStatus = false;
          if (result.error) {
            // PaymentIntent client secret was invalid
            errorStatus = true;
          } else if (result.paymentIntent.status === "succeeded") {
            // Succeeded
          } else if (result.paymentIntent.status === "requires_payment_method") {
            // Authentication failed
            errorStatus = true;
          }
          props.payment3DSecureAPI(
            props.account.accessToken,
            result.paymentIntent.id,
            errorStatus,
            props.payment.payment3DSecureCTX.completeEndpoint,
            props.payment.payment3DSecureCTX.successType,
            props.payment.payment3DSecureCTX.failureType,
          );

          if (frame) {
            frame.remove();
          }
          if (errorStatus) {
            if (onFailure) {
              onFailure(result.paymentIntent.last_payment_error.message);
            } else {
              props.setShowPayment3DSecure(false);
            }
          } else if (!errorStatus) {
            if (onSuccess) {
              onSuccess();
            } else {
              props.setShowPayment3DSecure(false);
            }
          }
        });
    }
  };

  const processPayment = () => {
    if (stripe) {
      const returnURL = `${window.location.origin}/payment-processing/`;
      stripe
        .confirmCardPayment(
          clientSecret || props.payment.payment3DSecureCTX.data.client_secret,
          { return_url: returnURL },
          { handleActions: false },
        )
        .then((payload) => {
          if (payload.error) {
            // payload.error "payment_intent_authentication_failure"
            on3DSComplete();
          } else {
            const { paymentIntent } = payload;

            if (paymentIntent.status === "requires_action") {
              const iframeElement = document.createElement("iframe");
              iframeElement.src = paymentIntent.next_action && paymentIntent.next_action.redirect_to_url.url;
              iframeElement.width = 500;
              iframeElement.height = 600;
              const paymentFrame = document.getElementById("payment-frame");
              if (paymentFrame) {
                paymentFrame.appendChild(iframeElement);
              }
            } else if (paymentIntent.status === "succeeded") {
              on3DSComplete();
            }
          }
        });
    }
  };
  return <div id="payment-frame" />;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Payment3DSecureModal = (props) => (
  <div className="payment-3d-secure">
    <Elements stripe={stripePromise}>
      <StripePaymentFrame {...props} />
    </Elements>
  </div>
);

const mapStateToProps = (state) => ({
  payment: state.payment,
  account: state.account,
});

const actionCreators = { payment3DSecureAPI };

export default connect(mapStateToProps, actionCreators)(Payment3DSecureModal);
