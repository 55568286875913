import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import moment from "moment";
import { DashboardSubScreenLayout, apiGenerator } from "Utils";
import { ConfirmationModal, Divider, IconButton, TextButton } from "UI";
import { Translate } from "react-localize-redux";
import ProfilePicture from "UI/ProfilePicture/ProfilePicture.component";
import useMatchTimeZone from "Utils/Hooks/useMatchTimeZone";
import TimezoneModal from "Components/Dashboard/Files/Components/TimezoneModal/TimezoneModal.component";

import "./Settings.styles.scss";

const OrganizationAdminSettings = ({ account, organization, translate }) => {
  const [showUpdateSubscriptionConfirmationModal, setShowUpdateSubscriptionConfirmationModal] = useState(false);
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const { email: userEmail, time_zone: userTimeZone } = account?.account?.attributes;
  const { name: organizationName } = organization?.organization?.attributes || {};
  const timezoneLabel = useMatchTimeZone(userTimeZone);
  const [showApiKey, setShowApiKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const {
    data: subscription,
    isSuccess: getSubscriptionSuccess,
    refetch: refetchSubscription,
    isLoading: getSubscriptionLoading,
  } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_SUBSCRIPTION],
    queryFn: () => apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_SUBSCRIPTION),
    retry: false,
  });

  const { data: teamsApiSecretData } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_API_SECRET],
    queryFn: () => apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_API_SECRET),
  });

  const apiKey = teamsApiSecretData?.data?.id;
  const secretKey = teamsApiSecretData?.data?.attributes?.secret;

  const updateRenewalMutation = useMutation({
    mutationFn: () => apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_SUBSCRIPTION_RENEWAL),
    onSuccess: () => {
      refetchSubscription();
    },
  });

  const {
    end_at: subscriptionEndAt,
    renew,
    organization_seats_count: totalSeatsCount,
    will_be_deleted_seats_count: willBeDeletedSeatsCount,
  } = subscription?.data?.attributes || {};

  const subscriptionInfoTextKey = () => {
    if (!getSubscriptionSuccess) {
      return "organizationSettings.footer.planRenewal.noSubscription";
    }

    if (!renew) {
      return "organizationSettings.footer.planRenewal.renew.false";
    }

    if (totalSeatsCount === willBeDeletedSeatsCount) {
      return "organizationSettings.footer.planRenewal.allSeatsWillBeDeleted";
    }

    if (willBeDeletedSeatsCount > 0) {
      return "organizationSettings.footer.planRenewal.someSeatsWillBeDeleted";
    }

    return `organizationSettings.footer.planRenewal.renew.${renew}`;
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("organizationSettings.header.title")}
      headerDescription={translate("organizationSettings.header.description")}
      translate={translate}
      waitFor={[organization.organization !== null]}
      noScroll
      loading={!organization.organization || getSubscriptionLoading}
    >
      <div className="organization-settings-container">
        <p className="header">{translate("organizationSettings.information.team.title")}</p>
        <div className="profile-information-container">
          <div className="profile-container">
            <ProfilePicture account={account} translate={translate} />
            <div className="information-item">
              <p className="label">{translate("organizationSettings.information.team.nameLabel")}</p>
              <p className="name-info">{organizationName}</p>
              <p className="label">{translate("organizationSettings.information.team.emailLabel")}</p>
              <p className="name-info">{userEmail}</p>
            </div>
          </div>
          <div className="additional-information-container">
            <div className="label">{translate("organizationSettings.information.team.timezoneLabel")}</div>
            <div className="time-zone-value-container">
              <p className="info-value">{timezoneLabel}</p>
              <TextButton text="Update" color="purple" onClick={() => setShowTimezoneModal(true)} />
            </div>
          </div>
        </div>

        {getSubscriptionSuccess && (
          <div className="teams-api-section">
            <p className="header">
              {translate("organizationSettings.teamsApi.title")} <span className="new-badge">New</span>
            </p>
            <>
              <p className="label">{translate("organizationSettings.teamsApi.apiKey")}</p>
              <div className="show-link-line">
                <span>{showApiKey ? apiKey : "****************"}</span>
                {showApiKey && (
                  <IconButton
                    name="copy"
                    color="purple-main-stroke"
                    smaller
                    onClick={() => {
                      navigator.clipboard.writeText(apiKey);
                    }}
                  />
                )}
                <IconButton
                  name="eye-open"
                  color="purple-main-stroke"
                  smaller
                  onClick={() => {
                    setShowApiKey(!showApiKey);
                  }}
                />
              </div>

              <p className="label">{translate("organizationSettings.teamsApi.secretKey")}</p>
              <div className="show-link-line">
                <span>{showSecretKey ? secretKey : "****************"}</span>
                {showSecretKey && (
                  <IconButton
                    name="copy"
                    color="purple-main-stroke"
                    smaller
                    onClick={() => {
                      navigator.clipboard.writeText(secretKey);
                    }}
                  />
                )}
                <IconButton
                  name="eye-open"
                  color="purple-main-stroke"
                  smaller
                  onClick={() => {
                    setShowSecretKey(!showSecretKey);
                  }}
                />
              </div>
            </>
            <div className="documentation">
              <Translate
                id="organizationSettings.teamsApi.documentation"
                options={{
                  renderInnerHtml: true,
                }}
              />
            </div>
          </div>
        )}

        <Divider />

        <div className="footer">
          <div className="column">
            <p className="footer-header">{translate("organizationSettings.footer.planRenewal.title")}</p>
            <p className="footer-description">
              <Translate
                id={subscriptionInfoTextKey()}
                data={{
                  date: moment(subscriptionEndAt).format("D MMMM YYYY"),
                  willDeleted: willBeDeletedSeatsCount,
                  willNotDeleted: totalSeatsCount - willBeDeletedSeatsCount,
                  totalSeatsCount,
                }}
              />
            </p>
            <div className="subscription-button">
              {getSubscriptionSuccess && (
                <TextButton
                  text={translate(`organizationSettings.footer.planRenewal.renewalButton.${renew}`)}
                  color="purple"
                  onClick={() => {
                    setShowUpdateSubscriptionConfirmationModal(true);
                  }}
                />
              )}
            </div>
          </div>

          <div className="column">
            <p>{translate("organizationSettings.footer.teamSupport.title")}</p>
            {getSubscriptionSuccess ? (
              <p>{translate("organizationSettings.footer.teamSupport.description")}</p>
            ) : (
              <p>{translate("organizationSettings.footer.teamSupport.noSubscription")}</p>
            )}
          </div>
        </div>
      </div>
      {showUpdateSubscriptionConfirmationModal && (
        <ConfirmationModal
          closeOnOverlayClick
          closeAction={() => setShowUpdateSubscriptionConfirmationModal(false)}
          headerText={translate(`organizationSettings.updateSubscriptionModal.header.title.${renew}`)}
          descriptionText={
            <Translate
              id={`organizationSettings.updateSubscriptionModal.description.${renew}`}
              data={{
                date: moment(subscriptionEndAt).format("D MMMM YYYY"),
              }}
            />
          }
          confirmText="Confirm"
          confirmAction={() => {
            updateRenewalMutation.mutate();
            setShowUpdateSubscriptionConfirmationModal(false);
          }}
          secondaryText={translate("organizationSettings.updateSubscriptionModal.secondaryAction")}
          secondaryAction={() => setShowUpdateSubscriptionConfirmationModal(false)}
        />
      )}
      {showTimezoneModal && <TimezoneModal userTimeZone={userTimeZone} setShowTimezoneModal={setShowTimezoneModal} />}
    </DashboardSubScreenLayout>
  );
};

export default OrganizationAdminSettings;
