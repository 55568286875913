const data = [
  {
    feature: "Application Count",
    basicPlan: "25 Applications",
    proPlan: "50 Applications",
    enterprisePlan: "Unlimited",
  },
  {
    feature: "Single Application Size",
    basicPlan: "25GB",
    proPlan: "50GB",
    enterprisePlan: "Unlimited",
  },
  {
    feature: "Application Versioning",
    basicPlan: "not-check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Fast Launch",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Machine Pool Utilization",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Custom Stream URL",
    basicPlan: "not-check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "White Label Streams",
    basicPlan: "not-check",
    proPlan: "not-check",
    enterprisePlan: "check",
  },
  {
    feature: "Dark Mode",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Custom Connection Messages",
    basicPlan: "not-check",
    proPlan: "not-check",
    enterprisePlan: "check",
  },
  {
    feature: "API Integration",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Additional Account Users",
    basicPlan: "not-check",
    proPlan: "Up to 5 Users",
    enterprisePlan: "20+ Users",
  },
  {
    feature: "Application Bundle",
    basicPlan: "2 Apps / Bundle",
    proPlan: "5 Apps / Bundle",
    enterprisePlan: "Unlimited",
  },
  {
    feature: "Concurrent Capacity per Stream Link",
    basicPlan: "25 per region",
    proPlan: "50 per region",
    enterprisePlan: "Custom",
  },
  {
    feature: "Region Coverage",
    basicPlan: "20+ Regions",
    proPlan: "20+ Regions",
    enterprisePlan: "25+ Regions",
  },
  {
    feature: "Port Access",
    basicPlan: "not-check",
    proPlan: "not-check",
    enterprisePlan: "check",
  },
  {
    feature: "Stream Budget Limiting",
    basicPlan: "not-check",
    proPlan: "not-check",
    enterprisePlan: "check",
  },
  {
    feature: "Application Budget Limiting",
    basicPlan: "not-check",
    proPlan: "not-check",
    enterprisePlan: "check",
  },
  {
    feature: "Password Protection",
    basicPlan: "not-check",
    proPlan: "not-check",
    enterprisePlan: "check",
  },
  {
    feature: "Visitor Email Collection",
    basicPlan: "not-check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Visitor Device Stats",
    basicPlan: "not-check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Scheduled Stream Availability",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Audio / Microphone Support",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Stream Auto Turn Off",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Session Duration Limit",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Streams Files",
    basicPlan: "check",
    proPlan: "check",
    enterprisePlan: "check",
  },
  {
    feature: "Premium Support",
    basicPlan: "not-check",
    proPlan: "not-check",
    enterprisePlan: "check",
  },
  {
    feature: "Roadmap Contribution",
    basicPlan: "not-check",
    proPlan: "not-check",
    enterprisePlan: "check",
  },
];

export default data;
