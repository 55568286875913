import React from "react";
import { connect } from "react-redux";
import { isPhone } from "Utils";
import routes from "Constants/Route.constants";
import FilesComponent from "Components/Dashboard/Files/Files.component";
import FilesComponentMobile from "Components/Dashboard/Files/FilesMobile.component";

import {
  getUserFiles,
  deleteFile,
  renameFile,
  moveFile,
  createFolder,
  downloadFile,
  uploadFilesToFolder,
  cancelFileUpload,
  clearUploadedFile,
  getFileStorageCapacity,
} from "Actions/Dashboard/Files.actions";

import {
  getUserSubscriptionAPI,
  getFileStoragePlansAPI,
  upgradeFileStorageSubscriptionAPI,
} from "Actions/Dashboard/Subscriptions.actions";

import { getWorkstationsAPI, updateCreateWorkstationConfig } from "Actions/Dashboard/Workstations.actions";

import { REQUEST_STATUS } from "Constants/global.constants";

const GET_FILES_POLLING_INTERVAL = 10000;
class FilesContainer extends React.Component {
  componentDidMount() {
    const {
      account,
      workstations,
      getUserFiles,
      getFileStorageCapacity,
      getUserSubscriptionAPI,
      getWorkstationsAPI,
      files,
    } = this.props;

    if (workstations.getWorkstationsCTX.status === REQUEST_STATUS.NOT_DEFINED) {
      getWorkstationsAPI(account.accessToken);
    }

    getUserSubscriptionAPI(account.accessToken);
    getFileStorageCapacity({});
    if (files.userFilesCTX.status === REQUEST_STATUS.SUCCESS) {
      getUserFiles({ isRefresh: true, filter: "" });
    } else {
      getUserFiles({});
    }

    this.filesPollingInterval = setInterval(() => {
      const { files } = this.props;
      const { currentFolder, status } = files.userFilesCTX;

      if (status !== REQUEST_STATUS.PENDING) {
        getUserFiles({ id: currentFolder.id, isRefresh: true });
      }
      getFileStorageCapacity({});
    }, GET_FILES_POLLING_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.filesPollingInterval);
  }

  getFileStoragePlans = () => {
    this.props.getFileStoragePlansAPI(this.props.account.accessToken);
  };

  upgradeFileStorageSubscription = (storageSize) => {
    this.props.upgradeFileStorageSubscriptionAPI(this.props.account.accessToken, storageSize);
  };

  loadFileContents = (id, move, refresh = false) => {
    this.props.getUserFiles({ id, move, isRefresh: refresh });
  };

  searchFiles = (id, filter) => {
    this.props.getUserFiles({ id, move: false, filter, isRefresh: true });
  };

  handleCreateComputerClick = () => {
    const { account, workstations, updateCreateWorkstationConfig, history } = this.props;

    const workstationName = workstations.createWorkstationConfigCTX.workstation_name;
    const userName = account.account.attributes.first_name;
    const generatedName = userName ? `${userName}'s Vagon` : "Your Vagon";

    updateCreateWorkstationConfig({ workstation_name: workstationName || generatedName });

    history.push(routes.createComputerPurpose);
  };

  handleDashboardButtonClick = () => {
    const { history } = this.props;
    history.push(routes.home);
  };

  render() {
    return isPhone ? (
      <FilesComponentMobile
        {...this.props}
        searchFiles={this.searchFiles}
        loadFileContents={this.loadFileContents}
        getFileStoragePlans={this.getFileStoragePlans}
        upgradeFileStorageSubscription={this.upgradeFileStorageSubscription}
        handleCreateComputerClick={this.handleCreateComputerClick}
      />
    ) : (
      <FilesComponent
        {...this.props}
        searchFiles={this.searchFiles}
        loadFileContents={this.loadFileContents}
        getFileStoragePlans={this.getFileStoragePlans}
        upgradeFileStorageSubscription={this.upgradeFileStorageSubscription}
        handleDashboardButtonClick={this.handleDashboardButtonClick}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  workstations: state.workstations,
  files: state.files,
  subscriptions: state.subscriptions,
});

const actionCreators = {
  getUserFiles,
  deleteFile,
  renameFile,
  moveFile,
  createFolder,
  downloadFile,
  uploadFilesToFolder,
  clearUploadedFile,
  cancelFileUpload,
  getWorkstationsAPI,
  updateCreateWorkstationConfig,
  getFileStorageCapacity,
  getUserSubscriptionAPI,
  getFileStoragePlansAPI,
  upgradeFileStorageSubscriptionAPI,
};

export default connect(mapStateToProps, actionCreators)(FilesContainer);
