import React from "react";
import { Icon } from "UI";
import { classnames } from "Utils";

import "./HoverableIconButton.styles.scss";

const HoverableComponent = (props) => {
  const { name, onClick, text, hoverText, showHoverText, isAlpha, isActive, isStroke, disabled } = props;

  return (
    <div
      role="button"
      tabIndex={-1}
      onKeyUp={null}
      className={classnames([
        "hoverable-icon",
        isActive && "active",
        showHoverText && "hover",
        isStroke && "stroke",
        disabled && "disabled",
      ])}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <Icon name={name} />
      {text && <p className="icon-text">{text}</p>}
      <div className={classnames(["hover-panel", isAlpha && "alpha"])}>
        <p>{hoverText}</p>
      </div>
    </div>
  );
};

export default HoverableComponent;
