import React from "react";
import { useDispatch } from "react-redux";
import { setCreateComputersCTX } from "Actions/Organization.actions";
import routes from "Constants/Route.constants";
import { Modal, NumberInput, TextButton } from "UI";
import "./CreateComputersModal.styles.scss";

const NumberInputWithButtons = ({ value, onChange, min, max }) => {
  return (
    <div className="vg-member-number-input">
      <button
        type="button"
        label="Decrement"
        onClick={() => {
          onChange(Math.max(value - 1, min || 0));
        }}
      >
        -
      </button>
      <NumberInput value={value} onChange={onChange} maxInputValue={99} maxDigitNumber={2} minInputValue={1} />
      <button
        type="button"
        label="Increment"
        onClick={() => {
          onChange(Math.min(value + 1, max || 99));
        }}
      >
        +
      </button>
    </div>
  );
};

const CreateComputersModal = ({ createComputerCount, setCreateComputerCount, onClose, history }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      className="create-computers-modal"
      closeAction={onClose}
      topRightIcon="close"
      headerText="Create Computers"
      topRightIconAction={onClose}
    >
      <div className="create-computers-modal-container">
        <p>Select the number of computers you want to create</p>
        <NumberInputWithButtons value={createComputerCount} onChange={setCreateComputerCount} min={1} />
        <div className="increase-computer-number-button">
          <TextButton className="action-buttons" text="Cancel" color="gray-4" onClick={onClose} />
          <TextButton
            className="action-buttons"
            text="Confirm"
            color="aqua-light"
            onClick={() => {
              dispatch(setCreateComputersCTX({ quantity: createComputerCount }));
              history.push(routes.organizationAdminCreateComputersQuickStart);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateComputersModal;
