import React from "react";

import { TextButton } from "UI";

const WorkstationOverviewItem = (props) => {
  const { label, content, secondContent, action, actionText, disabled, classSelector } = props;

  return (
    <div className={`workstation-overview-item ${classSelector}`}>
      <div className="label">{label}</div>
      <div className="item-content">
        <span id="content" className="content">
          {content}
          {secondContent && <span className="second-content"> {secondContent}</span>}
        </span>
        {actionText && <TextButton className="action" text={actionText} onClick={action} disabled={disabled} />}
      </div>
    </div>
  );
};

export default WorkstationOverviewItem;
