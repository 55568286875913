import React from "react";
import { Steps } from "intro.js-react";
import DesktopImage from "Assets/images/onboardingModals/desktop.jpg";
import DockButtonImage from "Assets/images/onboardingModals/dock-button.jpg";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const FirstConnectionOnboardingModal = ({ translate, onExit }) => {
  const steps = [
    {
      title: `<img src=${DesktopImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.firstConnectionOnboardingModal.welcome.header",
      )}</h1><p>${translate("modals.onboardingModals.firstConnectionOnboardingModal.welcome.description")}</p>`,

      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".dock-button",
      title: `<img src=${DockButtonImage} />`,
      intro: `<h1>${translate("modals.onboardingModals.firstConnectionOnboardingModal.dock.header")}</h1><p>${translate(
        "modals.onboardingModals.firstConnectionOnboardingModal.dock.description",
      )}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
  ];
  return <Steps enabled options={{ nextToDone: true }} steps={steps} initialStep={0} onExit={onExit} />;
};

export default FirstConnectionOnboardingModal;
