import { isFirefox, isSafari, Logger } from "Utils";
import * as MessageHelper from "Utils/Workstation/messageHelper";

const MAX_SAMPLE_COUNT = 20;

export default class TestHelper {
  constructor(websocket, videoElement) {
    this.websocket = websocket;
    this.videoElement = videoElement;
  }

  prepareLatencyTest = () => {
    if (isSafari) {
      return;
    }
    const message = MessageHelper.generateTestEventPayload(MessageHelper.testEventCommands.start);
    this.sendMessage(message);
  };

  stopLatencyTest = () => {
    const message = MessageHelper.generateTestEventPayload(MessageHelper.testEventCommands.stop);
    this.sendMessage(message);
  };

  onTestMessage = (message) => {
    switch (message.mt) {
      case MessageHelper.testMessageTypes.ready:
        this.startTest();
        break;
      default:
        break;
    }
  };

  setResolution = (width, height) => {
    this.width = width;
    this.height = height;
  };

  startTest = () => {
    this.sampleCount = 0;
    this.samples = [];
    this.samplesWithoutDrawLatency = [];
    this.drawLatency = [];
    this.pingSamples = [];
    setTimeout(this.getSample, 400);
  };

  startPingTest = () => {
    setTimeout(this.sendPing, 100);
  };

  sendMessage = (message) => {
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(message);
    }
  };

  getSample = () => {
    if (this.samples.length === MAX_SAMPLE_COUNT) {
      this.startPingTest();
      return;
    }
    const canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(this.videoElement, 0, 0, this.width, this.height);
    const p = ctx.getImageData(this.width / 2, this.height / 2, 1, 1).data;
    [this.R, this.G, this.B] = p;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let message = MessageHelper.generateMouseEventPayload(this.width / 2, this.height / 2, 0, 0, 1, false);
    this.sendMessage(message);
    message = MessageHelper.generateMouseEventPayload(this.width / 2, this.height / 2, 0, 0, 1, true);
    this.sendMessage(message);
    this.sampleClick = new Date();
    setTimeout(this.onColorControl, 1);
  };

  onColorControl = () => {
    const drawDateStart = new Date();
    const canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(this.videoElement, 0, 0, this.width, this.height);
    const p = ctx.getImageData(this.width / 2, this.height / 2, 1, 1).data;
    const [newR, newG, newB] = p;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const drawDateEnd = new Date();
    if (newR !== this.R && newG !== this.G && newB !== this.B) {
      const drawLatency = drawDateEnd - drawDateStart;
      this.samples.push(new Date() - this.sampleClick);
      this.samplesWithoutDrawLatency.push(new Date() - this.sampleClick - drawLatency);
      this.drawLatency.push(drawLatency);
      setTimeout(this.getSample, 400);
      return;
    }
    setTimeout(this.onColorControl, 1);
  };

  onPongEvent = () => {
    const latency = new Date() - this.lastPingDate;
    this.pingSamples.push(latency);
    setTimeout(this.sendPing, 100);
  };

  sendPing = () => {
    if (this.pingSamples.length === MAX_SAMPLE_COUNT) {
      this.stopLatencyTest();
      this.showResults();
      return;
    }
    const message = MessageHelper.generatePingEventPayload();
    this.lastPingDate = new Date();
    this.sendMessage(message);
  };

  showResults = () => {
    const streamRequestMessage = MessageHelper.generateRequestStreamEventPayload();
    const details = `
____________BROWSER DETAILS__________________________________________

WIDTH: ${this.width}
HEIGHT: ${this.height}
USER AGENT: ${navigator.userAgent}
HARDWARE CONCURRENCY: ${navigator.hardwareConcurrency}

____________STREAM REQUEST___________________________________________

${streamRequestMessage}

____________TEST RESULTS (SAMPLES)___________________________________

SAMPLES: ${this.samples.join(", ")};
AVAREAGE: ${this.average(this.samples)};
STANDART DEVIATION: ${this.standardDeviation(this.samples)}

____________TEST RESULTS (SAMPLES WITHOUT DRAWLATENCY)_______________

SAMPLES: ${this.samplesWithoutDrawLatency.join(", ")}
AVAREAGE: ${this.average(this.samplesWithoutDrawLatency)}
STANDART DEVIATION: ${this.standardDeviation(this.samplesWithoutDrawLatency)}

____________TEST RESULTS (DRAW LATENCY)______________________________

DRAW LATENCIES: ${this.drawLatency.join(", ")}
AVAREAGE: ${this.average(this.drawLatency)}
STANDART DEVIATION: ${this.standardDeviation(this.drawLatency)}

____________PING RESULTS_____________________________________________

PING LATENCY: ${this.pingSamples.join(", ")}
AVAREAGE: ${this.average(this.pingSamples)}
STANDART DEVIATION: ${this.standardDeviation(this.pingSamples)}

_____________________________________________________________________`;
    Logger.log(details);
    if (!isSafari && !isFirefox) {
      navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          /* write to the clipboard now */
          navigator.clipboard.writeText(details);
        }
      });
    }
  };

  standardDeviation = (values) => {
    const avg = this.average(values);
    const squareDiffs = values.map((value) => {
      const diff = value - avg;
      const sqrDiff = diff * diff;
      return sqrDiff;
    });
    const avgSquareDiff = this.average(squareDiffs);
    const stdDev = Math.sqrt(avgSquareDiff);
    return stdDev.toFixed(2);
  };

  average = (data) => {
    const sum = data.reduce((sum, value) => {
      return sum + value;
    }, 0);
    const avg = sum / data.length;
    return avg.toFixed(2);
  };
}
