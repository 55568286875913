import React from "react";
import OtpInput from "react-otp-input";

import "./OtpInput.styles.scss";

const VgOtpInput = ({ value, setValue, hasError, errorText, disabled, mobile }) => {
  return (
    <div className="vg-otp-input-container">
      <div className="input-line">
        <OtpInput
          value={value}
          onChange={(e) => {
            setValue(e);
          }}
          disabled={disabled}
          hasErrored={hasError}
          errorStyle={{ borderBottom: "1px solid #e08989" }}
          numInputs={6}
          separator={<span> </span>}
          containerStyle={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "1rem",
          }}
          inputStyle={{
            width: "100%",
            minWidth: `${mobile ? "1.2rem" : "1.4rem"}`,
            height: "2rem",
            marginRight: "1rem",
            fontWeight: "bold",
            fontSize: `${mobile ? "1rem" : "2rem"}`,
            border: "none",
            borderBottom: "1px solid rgba(0,0,0,0.3)",
            borderRadius: "0",
            outline: "none",
          }}
          isInputNum
        />
        {errorText && <div className="error-wrapper hide-on-mobile">{errorText}</div>}
      </div>
      {errorText && <div className="error-wrapper-mobile">{errorText}</div>}
    </div>
  );
};

export default VgOtpInput;
