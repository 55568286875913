import React, { useEffect } from "react";
import { Button, Icon } from "UI";
import { withLocalize } from "react-localize-redux";

import "./GoogleSignIn.styles.scss";

const GoogleSignIn = ({ onSuccess, register, translate }) => {
  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        ux_mode: "popup",
        callback,
      });
      window.google.accounts.id.prompt();
    }
  }, [!window.google]);

  const createFakeGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement("div");
    googleLoginWrapper.style.display = "none";
    googleLoginWrapper.classList.add("custom-google-button");

    document.body.appendChild(googleLoginWrapper);

    if (window.google && window.google.accounts) {
      window.google.accounts.id.renderButton(googleLoginWrapper, {});
    }

    const googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");

    return {
      click: () => {
        if (googleLoginWrapperButton) {
          googleLoginWrapperButton.click();
        }
      },
    };
  };

  const handleGoogleLogin = () => {
    createFakeGoogleWrapper().click();
  };

  const callback = (response) => {
    onSuccess(response);
  };

  return (
    <Button
      text={
        <>
          <Icon smaller name="google" />
          <span>{register ? translate("googleSignUpActionText") : translate("googleSignInActionText")}</span>
        </>
      }
      id="google-sign-in-button"
      type="button"
      block
      grayOutline
      className="google-sign-in-button"
      onClick={handleGoogleLogin}
    />
  );
};

export default withLocalize(GoogleSignIn);
