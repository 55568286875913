import React, { useRef, useState, useEffect } from "react";
import "./TopLoader.style.scss";

const RIGHT_OFFSET = 100;
const TRANSITION_DELAY = 300;
const LOADER_BAR_SIZE_INCREASE = 50;

const DEFAULT_TRANSITION_TIME = 2000;
const DEFAULT_LOADER_BAR_MAX_WIDTH = 400;
const DEFAULT_LOADER_BAR_MIN_WIDTH = 100;

const PageLoader = (props) => {
  const {
    ping,
    light,
    isInsideComponent,
    transitionTime = DEFAULT_TRANSITION_TIME,
    maxBarWidth = DEFAULT_LOADER_BAR_MAX_WIDTH,
    minBarWidth = DEFAULT_LOADER_BAR_MIN_WIDTH,
  } = props;

  const [loaderBarWidth, setLoaderBarWidth] = useState(minBarWidth);
  const [loaderBarX, setLoaderBarX] = useState(-minBarWidth);

  const containerRef = useRef(null);
  const barPositionTimeout = useRef(null);
  // Required to get size changes in parent container
  const [containerWidth, setContainerWidth] = useState(0);
  const width = containerRef.current && containerRef.current.clientWidth;

  // Clear timeout
  useEffect(() => {
    return () => {
      clearTimeout(barPositionTimeout.current);
    };
  }, []);

  // Change bar direction when parent width increase
  useEffect(() => {
    updateBar(false, minBarWidth);
    return () => {
      clearTimeout(barPositionTimeout.current);
    };
  }, [containerWidth]); // eslint-disable-line

  // Update containerWidth when parent width changes
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, [containerRef, width]);

  const updateBar = (moveToLeft, barWidth) => {
    const nextBarWidth = barWidth > containerWidth ? minBarWidth : barWidth + LOADER_BAR_SIZE_INCREASE;

    if (moveToLeft) {
      setLoaderBarX(-maxBarWidth);
      setLoaderBarWidth(minBarWidth);
      barPositionTimeout.current = setTimeout(
        () => updateBar(!moveToLeft, nextBarWidth),
        ping ? transitionTime : TRANSITION_DELAY,
      );
    } else {
      setLoaderBarX(containerWidth + RIGHT_OFFSET);
      setLoaderBarWidth(nextBarWidth);
      barPositionTimeout.current = setTimeout(() => updateBar(!moveToLeft, nextBarWidth), transitionTime);
    }
  };

  const transition = ping || loaderBarX > 0;
  const loaderBarClassName = ["loader-bar", transition && "transition", light && "light"].filter(Boolean).join(" ");

  let containerClassName = "page-loader-container";

  if (isInsideComponent) {
    containerClassName += " inside-component";
  }

  return (
    <div className={containerClassName} ref={containerRef}>
      <div
        className={loaderBarClassName}
        style={{
          left: `${loaderBarX}px`,
          width: `${loaderBarWidth}px`,
          transitionDuration: `${transition ? transitionTime : 0}ms`,
        }}
      />
    </div>
  );
};

export default PageLoader;
