import React from "react";

import { Icon, Button, IconButton } from "UI";
import { REGIONS_NAMES } from "Constants/global.constants";
import ConnectionStrength from "Utils/Components/ConnectionStrength/ConnectionStrength.component";
import "./RegionChangeModal.style.scss";

const RegionChangeModal = (props) => {
  const { region, selectedRegion, translate, onClick, onClose, buttonDisabled } = props;

  return (
    <ConnectionStrength regions={[selectedRegion]}>
      {({ connectionStrength }) => (
        <div className="region-change-modal">
          <div className="modal-content">
            <div className="region-section">
              <h3>{translate("regionChange.regionTitle")}</h3>
              <div className="region-change">
                {REGIONS_NAMES[region]}
                <Icon name="arrow-right" />
                {REGIONS_NAMES[selectedRegion]}
              </div>
              {translate("regionChange.regionDescription")}
            </div>
            <div className="divider" />
            <div className="connection-section">
              <h3>{translate("regionChange.connectionTitle")}</h3>
              {translate(`connectionStatus.content.connectionStrength.${connectionStrength}.title`)}
              <p>{translate(`regionChange.connection.${connectionStrength}`)}</p>
            </div>
          </div>
          <Button text={translate("regionChange.buttonText")} onClick={onClick} disabled={buttonDisabled} />
          <IconButton name="close" className="close-button" onClick={onClose} />
        </div>
      )}
    </ConnectionStrength>
  );
};

export default RegionChangeModal;
