import React, { useState } from "react";
import { DivButton, NumberInput } from "UI";
import { Animation, classnames } from "Utils";
import CheckBox from "UI/CheckBox/CheckBox.component";

import "./CapacitySelection.style.scss";

const CAPACITY_TYPES = {
  on_demand: "on_demand",
  balanced: "balanced",
  always_on: "always_on",
};

const RUN_METHOD_FRIENDLY_NAME = {
  on_demand: "Cost Optimized",
  always_on: "Availability Optimized",
  balanced: "Balanced",
};

const CapacitySelection = ({
  translate,
  capacity,
  setCapacity,
  reserveCapacity,
  setReserveCapacity,
  capacityType,
  setCapacityType,
  selectedCapacities,
  hideNumberInput,
  maxCapacity,
  showCapacityWarning,
  capacityWarningConsent,
  setCapacityWarningConsent,
}) => {
  const regionCount = selectedCapacities?.length;
  const alwaysOn = capacityType === CAPACITY_TYPES.always_on;
  const balanced = capacityType === CAPACITY_TYPES.balanced;
  const [runningAnimation, setRunningAnimation] = useState({ key: null });

  return (
    <div className="capacity-selection-container">
      <h1>{translate("appStreamingModals.stream.step1.header")}</h1>
      <p>{translate("appStreamingModals.stream.step1.description")}</p>
      {showCapacityWarning ? (
        <div className="capacity-warning-container">
          <div className="warning-animation-container">
            <Animation animationStyle={{ width: "100%", height: "auto" }} type={capacityType} loop autoplay />
          </div>

          <h2>{translate("configureStream.configurations.capacityType.capacityWarning.header")}</h2>
          <p>
            {translate("configureStream.configurations.capacityType.capacityWarning.text", {
              capacityType: RUN_METHOD_FRIENDLY_NAME[capacityType],
            })}
          </p>
          <CheckBox
            checked={capacityWarningConsent}
            onChange={() => setCapacityWarningConsent(!capacityWarningConsent)}
            label={translate("configureStream.configurations.capacityType.capacityWarning.consent")}
          />
        </div>
      ) : (
        <div className="customize-step-container">
          <div className="capacity-options">
            {Object.keys(CAPACITY_TYPES).map((type) => {
              return (
                <DivButton
                  key={type}
                  onClick={() => {
                    setCapacityType(CAPACITY_TYPES[type]);
                  }}
                  onMouseEnter={() => {
                    setRunningAnimation({ key: type });
                  }}
                  onMouseLeave={() => {
                    setRunningAnimation({ key: null });
                  }}
                  className={classnames([
                    "capacity-option-box",
                    capacityType?.includes(CAPACITY_TYPES[type]) && "selected",
                  ])}
                >
                  <Animation
                    animationStyle={{ width: "100%", height: "auto" }}
                    type={type}
                    loop
                    autoplay={false}
                    isStopped={runningAnimation.key !== type}
                  />
                  <div>
                    <p>{translate(`appStreamingModals.configureStream.step0.optimization.options.${type}.header`)}</p>
                    <p className="small-font">
                      {translate(
                        `appStreamingModals.configureStream.step0.optimization.options.${type}.connectionTime`,
                      )}
                    </p>
                  </div>
                </DivButton>
              );
            })}
          </div>

          {!hideNumberInput && (
            <div>
              <div className={classnames(["capacity-input", alwaysOn && "open"])}>
                <div className="input-box">
                  <NumberInput
                    onChange={setCapacity}
                    value={capacity}
                    minInputValue={0}
                    maxInputValue={maxCapacity}
                    color="gray-border"
                  />
                  <div className="capacity-label">
                    {translate("appStreamingModals.stream.step1.concurrentUser.userCount", {
                      regionCount,
                    })}
                  </div>
                </div>
              </div>

              <div className={classnames(["capacity-input", balanced && "open"])}>
                <div className="input-box">
                  <NumberInput
                    onChange={setReserveCapacity}
                    value={reserveCapacity}
                    minInputValue={0}
                    maxInputValue={maxCapacity}
                    color="gray-border"
                  />
                  <div className="capacity-label">
                    {translate("appStreamingModals.stream.step1.concurrentUser.reserveCapacity", {
                      regionCount,
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {capacityType && (
            <div className="capacity-type-footer">
              <p>{translate(`configureStream.configurations.capacityType.footerText.${capacityType}`)}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CapacitySelection;
