import { STREAM_TYPES } from "Constants/AppStreaming.constants";
import React from "react";
import { BoxInput, TextButton } from "UI";
import { classnames } from "Utils";

const LaunchStep = ({ streamConfigs, setStreamConfigs, streamType, translate }) => {
  const { launchArguments } = streamConfigs;
  const parentStream = streamType === STREAM_TYPES.parent;

  return (
    <div className={classnames(["launch-step", parentStream && "disabled"])}>
      <div className="header-content">
        <p className="header">{translate("appStreamingModals.setLaunchArgs.header")}</p>
        <p className="description">{translate("appStreamingModals.setLaunchArgs.description")}</p>
      </div>
      <div className="arg-input-area">
        <div className="arg-input-line">
          <h3>Arguments</h3>
          <BoxInput
            value={launchArguments || ""}
            onChange={(event) => setStreamConfigs({ launchArguments: event.target.value })}
          />
        </div>
        <div className="link-container">
          <TextButton
            text={translate("appStreamingModals.setLaunchArgs.links.unity")}
            color="aqua-dark"
            onClick={() => {
              window.open("https://docs.unity3d.com/Manual/CommandLineArguments.html", "_blank");
            }}
            className="link-button"
          />
          <TextButton
            text={translate("appStreamingModals.setLaunchArgs.links.ue")}
            color="aqua-dark"
            onClick={() => {
              window.open(
                "https://docs.unrealengine.com/4.27/en-US/ProductionPipelines/CommandLineArguments/",
                "_blank",
              );
            }}
            className="link-button"
          />
        </div>
        <p className="footnote">{translate("appStreamingModals.setLaunchArgs.footNote")}</p>
      </div>
    </div>
  );
};

export default LaunchStep;
