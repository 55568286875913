import GlobalTranslate from "Translations/global.json";
import CreateComputerTranslate from "Translations/createComputer.json";
import DashboardTranslate from "Translations/dashboard.json";
import WorkstationTranslate from "Translations/workstation.json";
import AppStreamingTranslate from "Translations/appStreaming.json";
import OrganizationsTranslate from "Translations/organizations.json";

const translate = (string) => {
  const keys = string.split(".");
  let currentObject = GlobalTranslate;
  if (CreateComputerTranslate[keys[0]]) {
    currentObject = CreateComputerTranslate;
  } else if (DashboardTranslate[keys[0]]) {
    currentObject = DashboardTranslate;
  } else if (WorkstationTranslate[keys[0]]) {
    currentObject = WorkstationTranslate;
  } else if (AppStreamingTranslate[keys[0]]) {
    currentObject = AppStreamingTranslate;
  } else if (OrganizationsTranslate[keys[0]]) {
    currentObject = OrganizationsTranslate;
  }

  keys.forEach((key) => {
    currentObject = currentObject[key];
  });

  return currentObject;
};

export default translate;
