import React from "react";
import { Announcement } from "UI";
import VagonLogo from "Assets/images/logo/vg-white-logo-on-purple-backgroundx4.png";
import { APP_DOWNLOAD_LINKS } from "Constants/global.constants";
import { getGPUTier } from "detect-gpu";

import "./AppDownloadAnnouncement.styles.scss";

const AppDownloadAnnouncement = ({ handleAnnouncementClose, translate }) => {
  return (
    <Announcement
      containerClass="app-download-announcement"
      handleClose={handleAnnouncementClose}
      icon={<img src={VagonLogo} />}
      header={translate("appDownloadAnnouncement.header")}
      description={translate("appDownloadAnnouncement.description")}
      button1={{
        text: translate("appDownloadAnnouncement.buttons.buttonTextForWindows"),
        onClick: () => {
          window.location.assign(APP_DOWNLOAD_LINKS.appDownloadLinkForWindows);
        },
      }}
      button2={{
        text: translate("appDownloadAnnouncement.buttons.buttonTextForMacOS"),
        onClick: () => {
          getGPUTier({ benchmarksURL: "/benchmarks" })
            .then((result) => {
              if (result.gpu.includes("apple m")) {
                window.location.assign(APP_DOWNLOAD_LINKS.appDownloadLinkForAppleSilicon);
              } else {
                window.location.assign(APP_DOWNLOAD_LINKS.appDownloadLinkForAppleIntel);
              }
            })
            .catch(() => {
              window.location.assign(APP_DOWNLOAD_LINKS.appDownloadLinkForAppleIntel);
            });
        },
      }}
    />
  );
};

export default AppDownloadAnnouncement;
