import React, { useState, useEffect, useRef } from "react";

import { Icon } from "UI";
import VagonGift from "Assets/images/referral/vagon-gift.png";
import routes from "Constants/Route.constants";

import "./DashboardHinter.styles.scss";
import { HINTER_TYPES, LOCAL_STORAGE } from "Constants/global.constants";
import { getClientInfo, saveItemToLocalStorage, useAPI } from "Utils";
import { API_ENDPOINTS } from "Constants/api.constants";
import { getLastSuccessfulConnectionType } from "Utils/Helpers/streaming.helpers";

const HINTER_POSITION = {
  CLOSED: "closed",
  HIDDEN: "hidden",
  OPEN: "open",
};

const ReferralHinter = (props) => {
  const { referral, setPosition, translate } = props;

  const { referreReward, referrerReward } = referral.getRewardsCTX.data || {};

  useEffect(() => {
    setPosition(HINTER_POSITION.CLOSED);
  }, []);

  return (
    <>
      <img src={VagonGift} />
      <p>{translate("home.hinter.referral", { referreReward, referrerReward })}</p>
      <Icon name="arrow-right-small" color="gray-3" />
    </>
  );
};

const RateHinter = (props) => {
  const { setPosition, setShowFeedbackModal, feedBackRating, setFeedBackRating, setType, translate } = props;

  useEffect(() => {
    setTimeout(() => setPosition(HINTER_POSITION.OPEN), 100);
  }, []);

  const { api: sessionFeedbackAPI } = useAPI({
    type: "post",
    endpoint: API_ENDPOINTS.SESSION_FEEDBACK,
  });

  const handleRateClick = (feedBackRating) => {
    saveItemToLocalStorage(LOCAL_STORAGE.streamRating, feedBackRating);

    if (feedBackRating < 3) {
      setShowFeedbackModal(true);
    } else {
      const clientInfo = getClientInfo();
      sessionFeedbackAPI({
        rating: feedBackRating,
        os: clientInfo.os,
        gpu_information: clientInfo.gpu,
        browser_information: clientInfo.browser,
        webrtc_toggle_enabled: true,
        last_successful_connection_type: getLastSuccessfulConnectionType(),
      });
    }

    setTimeout(() => setPosition(HINTER_POSITION.HIDDEN), 200);
    setTimeout(() => setType(HINTER_TYPES.REFERRAL), 1200);
  };

  return (
    <>
      <p>{translate("home.hinter.rating")}</p>
      <div className="rating-container">
        {[5, 4, 3, 2, 1].map((id) => (
          <Icon
            key={id}
            name="star"
            className={feedBackRating >= id && "selected"}
            onClick={() => {
              setFeedBackRating(id);
              handleRateClick(id);
            }}
          />
        ))}
      </div>
    </>
  );
};

const DashboardHinter = ({
  isTeamAccount,
  type,
  setType,
  referral,
  setShowFeedbackModal,
  feedBackRating,
  setFeedBackRating,
  history,
  translate,
}) => {
  const [position, setPosition] = useState(HINTER_POSITION.CLOSED);
  const isOpen = position === HINTER_POSITION.OPEN;

  const hintRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (hintRef.current && !hintRef.current.contains(event.target)) {
        if (type === HINTER_TYPES.REFERRAL) {
          setPosition(HINTER_POSITION.CLOSED);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onClick = () => {
    if (isOpen) {
      if (type === HINTER_TYPES.REFERRAL) {
        history.push(routes.referral);
      }
    } else {
      setPosition(HINTER_POSITION.OPEN);
    }
  };

  return (
    <div className="hinter-container">
      {/* eslint-disable  */}
      <div className={["hinter", position, type].filter(Boolean).join(" ")} ref={hintRef} onClick={onClick}>
        {/* eslint-enable  */}
        {type === HINTER_TYPES.REFERRAL && !isTeamAccount && (
          <ReferralHinter referral={referral} setPosition={setPosition} translate={translate} />
        )}
        {type === HINTER_TYPES.RATING && (
          <RateHinter
            setType={setType}
            isOpen={isOpen}
            setPosition={setPosition}
            setShowFeedbackModal={setShowFeedbackModal}
            feedBackRating={feedBackRating}
            setFeedBackRating={setFeedBackRating}
            translate={translate}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardHinter;
