import React from "react";
import { Button, Modal } from "UI";
import ErrorImage from "Assets/images/appStreaming/streams.png";
import { Translate, withLocalize } from "react-localize-redux";

import "./PaymentErrorModal.styles.scss";

const PaymentErrorModal = ({ errorCode, onAction, onClose, translate }) => {
  return (
    <Modal
      className="app-streaming-payment-error-modal"
      closeOnOverlayClick
      closeAction={onClose}
      topRightIcon="close"
      topRightIconAction={onClose}
      descriptionText={
        <div className="error-message-container">
          <img src={ErrorImage} />
          <h1>
            <Translate
              id={`appStreamingModals.paymentErrors.${errorCode}.header`}
              options={{ onMissingTranslation: () => translate("appStreamingModals.stream.errors.default.header") }}
            />
          </h1>
          <p>
            <Translate
              id={`appStreamingModals.paymentErrors.${errorCode}.description`}
              options={{
                onMissingTranslation: () => translate("appStreamingModals.paymentErrors.default.description"),
              }}
            />
          </p>
          <Button
            text={
              <Translate
                id={`appStreamingModals.paymentErrors.${errorCode}.action`}
                options={{ onMissingTranslation: () => translate("appStreamingModals.stream.errors.default.action") }}
              />
            }
            onClick={onAction}
          />
        </div>
      }
    />
  );
};

export default withLocalize(PaymentErrorModal);
