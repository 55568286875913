import React, { useState } from "react";

import { Modal, Slide } from "UI";

import "./BoardingModal.styles.scss";
import "./Modal.style.scss";

const BoardingModal = (props) => {
  const {
    headerImages,
    headerTexts,
    descriptions,
    buttonTexts,
    buttonAction,
    buttonDisabled,
    buttonHidden,
    closeAction,
    blue,
  } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const stepNumber = headerImages?.length;

  const handleNextSlide = () => {
    if (activeIndex === stepNumber - 1) {
      buttonAction();
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <Modal
      className="boarding-modal"
      headerText={
        <>
          {headerImages && (
            <Slide activeIndex={activeIndex} setActiveIndex={setActiveIndex} showDots={stepNumber > 1}>
              {headerImages?.map((image, index) => (
                <img key={index} src={image} /> // eslint-disable-line
              ))}
            </Slide>
          )}
          {headerTexts && (
            <Slide activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
              {headerTexts}
            </Slide>
          )}
        </>
      }
      descriptionText={<Slide activeIndex={activeIndex}>{descriptions}</Slide>}
      buttonText={!buttonHidden && buttonTexts[activeIndex]}
      buttonAction={!buttonHidden && handleNextSlide}
      closeAction={closeAction}
      blue={blue}
      topRightIcon={closeAction && "close"}
      topRightIconAction={closeAction}
      buttonDisabled={buttonDisabled}
      closeOnOverlayClick
      invisibleOverlay
      small
    />
  );
};

export default BoardingModal;
