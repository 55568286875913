import React from "react";
import { isOdd } from "Utils/Helpers/functions.helpers";
import { classnames } from "Utils";
import VendorBasic from "Assets/images/appStreaming/standard-plan.png";
import VendorPro from "Assets/images/appStreaming/pro-plan.png";
import VendorEnterprise from "Assets/images/appStreaming/enterprise-plan.png";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as EmptyCheck } from "./empty-check.svg";

import data from "./plansData";

import "./PlansTable.styles.scss";

const VendorPlansTable = ({ basicPlanButton, proPlanButton, enterprisePlanButton }) => {
  const cellContent = (content) => {
    if (content === "check") {
      return <Check className="icon" />;
    }

    if (content === "not-check") {
      return <EmptyCheck className="icon" />;
    }

    return <p>{content}</p>;
  };

  return (
    <>
      <div className="vendor-plans-table">
        <div className="header-row">
          <div />
          <div className="header-cell">
            <div>
              <img src={VendorBasic} alt="Vendor Basic" />
              <div className="plan-info">
                <p>Basic Plan</p>
                <p>Free for Individuals</p>
              </div>
            </div>
            {basicPlanButton()}
          </div>
          <div className="header-cell">
            <div>
              <img src={VendorPro} alt="Vendor Pro" />
              <div className="plan-info">
                <p>Pro Plan</p>
                <p>+ $99.99 per month</p>
              </div>
            </div>
            {proPlanButton()}
          </div>
          <div className="header-cell">
            <div>
              <img src={VendorEnterprise} alt="Vendor Enterprise" />
              <div className="plan-info">
                <p>Enterprise Plan</p>
                <p>+ $249 per month</p>
              </div>
            </div>
            {enterprisePlanButton()}
          </div>
        </div>
        {data.map((row, index) => {
          return (
            <div className={classnames(["row", isOdd(index) && "gray"])}>
              <div className={classnames(["feature", isOdd(index) && "gray"])}>{row.feature}</div>
              <div className="centered-cell">{cellContent(row.basicPlan)}</div>
              <div className="centered-cell">{cellContent(row.proPlan)}</div>
              <div className="centered-cell">{cellContent(row.enterprisePlan)}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default VendorPlansTable;
