import { combineReducers } from "redux";

import { localizeReducer } from "react-localize-redux";

import registerCTX from "./register";
import referral from "./referral";
import verifyRegisterCTX from "./verifyRegister";
import forgetPasswordCTX from "./forgetPassword";
import forgetPasswordResetCTX from "./forgetPasswordReset";
import login from "./login";
import account from "./account";
import plansCTX from "./Dashboard/plans";
import regionsCTX from "./Dashboard/regions";
import subscriptions from "./Dashboard/subscriptions";
import payment from "./Dashboard/payment";
import workstations from "./Dashboard/workstations";
import files from "./Dashboard/files";
import appStreaming from "./appStreaming";
import organizations from "./organizations";

export default (history) =>
  combineReducers({
    localize: localizeReducer,
    registerCTX,
    referral,
    verifyRegisterCTX,
    forgetPasswordCTX,
    forgetPasswordResetCTX,
    login,
    account,
    plansCTX,
    regionsCTX,
    subscriptions,
    payment,
    workstations,
    files,
    appStreaming,
    organizations,
    history,
  });
