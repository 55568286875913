import React from "react";

import { Button, MachineTypeList } from "UI";
import { REQUEST_STATUS, WORKSTATION_TYPES } from "Constants/global.constants";
import { normalizeCredits } from "Utils";
import routes from "Constants/Route.constants";

const isSelectedMachineTypeInSecondRow = (machineTypes, selectedMachineType) => {
  const secondRowMachineTypeIDs = machineTypes
    .filter((machineType) =>
      WORKSTATION_TYPES.secondRow.includes(machineType.attributes.details.attributes.friendly_name),
    )
    .map((machineType) => parseInt(machineType.id, 10));
  return secondRowMachineTypeIDs.includes(selectedMachineType);
};

const MachineTypeSelection = (props) => {
  const {
    account,
    getWorkstationMachineTypesCTX,
    selectedMachineType,
    setSelectedMachineType,
    shouldShowMachineTypeSelection,
    setShouldShowMachineTypeSelection,
    translate,
    displayAllMachineTypes,
    setDisplayAllMachineTypes,
    shouldSavePerformance,
    history,
  } = props;

  const containerRef = React.useRef(null);

  const balance = account.account.attributes.balance || 0;
  const credit = normalizeCredits(account.account.attributes.credit || 0);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShouldShowMachineTypeSelection(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (shouldShowMachineTypeSelection && getWorkstationMachineTypesCTX.data) {
      const { machine_types: machineTypes } = getWorkstationMachineTypesCTX.data;
      setDisplayAllMachineTypes(isSelectedMachineTypeInSecondRow(machineTypes, selectedMachineType));
    }
  }, [shouldShowMachineTypeSelection]);

  if (
    getWorkstationMachineTypesCTX.status === REQUEST_STATUS.NOT_DEFINED ||
    getWorkstationMachineTypesCTX === REQUEST_STATUS.PENDING ||
    !getWorkstationMachineTypesCTX.data
  ) {
    return null;
  }
  const { machine_types: machineTypes } = getWorkstationMachineTypesCTX.data;
  const findRegionCategory = () => {
    let category = 0; // regions that include GPU+SAND

    const availableMachines = machineTypes.map((machineType) => {
      return {
        name: machineType.attributes.details.attributes.name,
        gpu: machineType.attributes.details.attributes.gpu,
      };
    });

    if (availableMachines.find((machine) => machine.name.startsWith("g5"))) category = 2;
    // regions that include GPU+CPU+SAND+A10
    else if (availableMachines.filter((machine) => machine.gpu === 0).length > 1) category = 1; // standard case GPU+CPU+SAND
    return category;
  };
  const regionCategory = findRegionCategory();
  const hideSeeAll = regionCategory === 0;
  const displayPerformanceOptions = () => {
    if (regionCategory === 1) setDisplayAllMachineTypes(!displayAllMachineTypes);
    // eslint-disable-next-line no-restricted-globals
    else history.push(routes.performance);
  };
  return (
    <div className="machine-type-selection-container" ref={containerRef}>
      <div className="header">{translate("home.machineTypeSelection.header")}</div>
      <MachineTypeList
        displayAll={displayAllMachineTypes}
        machineTypes={machineTypes}
        shouldSavePerformance={shouldSavePerformance}
        selectedMachineType={selectedMachineType}
        setSelectedMachineType={setSelectedMachineType}
      />

      {!hideSeeAll && (
        <Button
          className="toggle-display-all"
          text={translate(
            `home.machineTypeSelection.buttonToggleDisplayAll.${displayAllMachineTypes ? "hide" : "show"}`,
          )}
          onClick={displayPerformanceOptions}
          eventType="seeAllOptions"
          gray
          basic
        />
      )}

      <div className="footer">
        <div className="balance">
          {translate("home.machineTypeSelection.balanceLabel")}{" "}
          <span className="amount">
            {account.isTeamAccount ? (
              <>
                {credit} {translate("home.machineTypeSelection.amountCredit")}
              </>
            ) : (
              <>
                {parseFloat(balance).toFixed(2)} {translate("home.machineTypeSelection.amountCurrency")}
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MachineTypeSelection;
