import React, { useEffect } from "react";
import info1 from "Assets/images/appStreaming/info-1.png";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { DashboardSubScreenLayout, useAPI } from "Utils";
import { Button, Input } from "UI";
import { useDispatch, useSelector } from "react-redux";
import { getAccountAPI } from "Actions/Account.actions";
import routes from "Constants/Route.constants";
import "./AppStreamingLanding.styles.scss";

const AppStreamingLanding = ({ translate, history, sendTokenToPlugin }) => {
  const [name, setName] = React.useState("");
  const dispatch = useDispatch();

  const { account } = useSelector((state) => state.account);
  const { vendor_admin: vendorAdmin, vendor_member: vendorMember } = account?.attributes || {};

  const { api: createVendorAPI, isLoading: createVendorAPILoading } = useAPI({
    type: "post",
    endpoint: APP_STREAMING_API_ENDPOINTS.VENDOR,
    onSuccess: () => {
      dispatch(getAccountAPI());
    },
  });

  useEffect(() => {
    if (!sendTokenToPlugin && (vendorAdmin || vendorMember)) {
      history.push(routes.appStreamingHome);
    }
  }, [vendorAdmin, vendorMember]);

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("appStreamingLanding.header")}
      headerDescription={translate("appStreamingLanding.description")}
      translate={translate}
      noScroll
    >
      <div className="app-streaming-landing-container">
        <div className="landing-content">
          <img src={info1} />
          <p className="landing-info">{translate("appStreamingLanding.body.description")}</p>
          <div className="team-name-input">
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              label={translate("appStreamingLanding.body.label")}
              placeholder={translate("appStreamingLanding.body.placeholder")}
              maxLength={60}
            />
          </div>

          <Button
            text={translate("appStreamingLanding.body.action")}
            big
            onClick={() => {
              createVendorAPI({
                name,
              });
            }}
            disabled={name.length < 1 || createVendorAPILoading}
          />
        </div>
      </div>
    </DashboardSubScreenLayout>
  );
};

export default AppStreamingLanding;
