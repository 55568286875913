import React from "react";
import routes from "Constants/Route.constants";
import _ from "lodash";
import { classnames, isMobile } from "Utils";
import { joinWithComma, minutesToReadableHoursMinutes, pluralize } from "Utils/Helpers/functions.helpers";
import { Button, HistoryCard, SearchInput } from "UI";
import "./QuickStartTable.styles.scss";

const HEADERS = ["Usage", "Disk Storage", "Files Storage", "Performances", "Budget"];

const KEYS = ["usage", "diskStorage", "fileStorage", "performances", "budget"];

const QuickStartTable = ({
  plansData,
  selectedPlanID,
  setSelectedPlanID,
  planSearchQuery,
  setPlanSearchQuery,
  createComputersCTX,
  history,
}) => {
  const seatPlans = plansData?.data?.seat_plans;

  const getUserData = (plan) => {
    const {
      name,
      budget,
      normal_budget: normalBudget,
      has_discount: hasDiscount,
      disk_size: diskSize,
      file_storage_size: fileStorageSize,
      usage,
      machine_types: machineTypes,
      remaining_trial_usage: remainingTrialUsage,
    } = plan.attributes;

    const machineTypeNames = machineTypes?.map((machineType) => machineType.attributes.friendly_name);
    const usageText = () => {
      if (usage === 0) {
        return "Manually Managed";
      }

      if (machineTypes.length === 1) {
        return minutesToReadableHoursMinutes(usage);
      }
      return `Up to ${minutesToReadableHoursMinutes(usage)}`;
    };

    return {
      id: plan.id,
      name,
      usage: usageText(),
      diskStorage: `${diskSize} GB`,
      fileStorage: `${fileStorageSize} GB`,
      performances: <div className="performance-list">{joinWithComma(machineTypeNames)}</div>,
      budget: hasDiscount ? (
        <p>
          ${budget} <span className="unit">first month</span>
          <br />${normalBudget} <span className="unit"> next months</span>
        </p>
      ) : (
        <p>
          ${budget} <span className="unit">per month</span>
        </p>
      ),
      suggested: performance.suggested,
      remainingTrialUsage,
      hasDiscount,
    };
  };

  const handleEnterKeyUp = (tableRowData) => {
    setSelectedPlanID(tableRowData.id);
  };

  const rowData = (tableRowData) => {
    return (
      <div
        className={classnames([
          "quick-start-table",
          selectedPlanID === tableRowData.id && "selected",
          selectedPlanID !== tableRowData.id && selectedPlanID && "active-opacity",
          tableRowData.hasDiscount && tableRowData.remainingTrialUsage < createComputersCTX.quantity && "disabled",
        ])}
        onClick={() => {
          if (tableRowData.hasDiscount && tableRowData.remainingTrialUsage < createComputersCTX.quantity) return;
          setSelectedPlanID(tableRowData.id);
        }}
        onKeyUp={() => {
          if (tableRowData.hasDiscount && tableRowData.remainingTrialUsage < createComputersCTX.quantity) return;
          handleEnterKeyUp(tableRowData);
        }}
        role="button"
        tabIndex="0"
        key={tableRowData.id}
      >
        <div className="header-line">
          <h1>{tableRowData.name}</h1>
          {tableRowData.hasDiscount && (
            <div className={classnames(["trial-badge", selectedPlanID === tableRowData.id && "selected"])}>
              <p>
                {tableRowData.remainingTrialUsage > 0
                  ? `${pluralize(tableRowData.remainingTrialUsage, "Usage")} Remaining`
                  : "No Usages Left"}
              </p>
            </div>
          )}
        </div>
        <div className="table-header">
          {HEADERS.map((header) => (
            <div key={header} className="header-cell">
              <div className="header-cell-content">{header}</div>
            </div>
          ))}
        </div>
        <div key={tableRowData.id} className="table-body">
          {KEYS.map((key) => (
            <div key={key}>
              <div className="cell-content">{tableRowData[key] || "-"}</div>
            </div>
          ))}
        </div>
        <div className="divider" />
      </div>
    );
  };
  return (
    <div className="quick-start-container">
      <SearchInput
        placeholder="Search in Plans"
        iconName="search"
        color="white"
        value={planSearchQuery}
        onChange={(e) => setPlanSearchQuery(e.target.value)}
      />

      <div className="plan-table-container">
        {_.map(seatPlans, (content) => {
          const tableRowData = getUserData(content);

          return isMobile ? (
            <HistoryCard
              key={content?.id}
              headers={HEADERS}
              keys={KEYS}
              item={tableRowData}
              setCard={setSelectedPlanID}
              card={selectedPlanID}
            />
          ) : (
            rowData(tableRowData)
          );
        })}
      </div>
      {selectedPlanID && (
        <div className="button-container">
          <Button
            text="Continue Payment"
            onClick={() => {
              history.push(routes.organizationAdminCreateComputersCreatePayment);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default QuickStartTable;
