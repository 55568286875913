import React from "react";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import { classnames } from "Utils";
import "./DropdownMenu.styles.scss";

const DropdownMenu = ({
  className,
  onOpen,
  onClose,
  children,
  menuItems,
  side,
  sideOffset = 5,
  align = "center",
  alignOffset = 0,
  disabled,
}) => {
  return (
    <RadixDropdownMenu.Root
      onOpenChange={(e) => {
        if (e && onOpen) {
          onOpen();
        }

        if (!e && onClose) {
          onClose();
        }
      }}
    >
      <RadixDropdownMenu.Trigger asChild disabled={disabled}>
        <button type="button" className={classnames(["vg-dropdown-trigger-button"])}>
          {children}
        </button>
      </RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content
          className={classnames(["vg-dropdown-menu-content", className])}
          sideOffset={sideOffset}
          side={side}
          align={align}
          alignOffset={alignOffset}
        >
          {!disabled &&
            menuItems?.map((item) => {
              if (item.hidden) {
                return null;
              }
              return (
                <RadixDropdownMenu.Item
                  className="vg-dropdown-menu-item"
                  onClick={item.onClick}
                  key={item.key || item.label}
                  disabled={item.disabled}
                >
                  {item.label}
                  {item.rightSlot && <div className="right-slot">{item.rightSlot}</div>}
                </RadixDropdownMenu.Item>
              );
            })}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};

export default DropdownMenu;
