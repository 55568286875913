import React from "react";
import { Translate } from "react-localize-redux";
import EmptyFolderImage from "Assets/images/fileManager/cloud-empty-1.png";
import HowToVideo from "Assets/videos/files-how.webm";
import HowToVideoMp4 from "Assets/videos/files-how.mp4";
import EmptyTeamFolderImage from "Assets/images/fileManager/team-folder-empty.png";

export const EmptyFolder = (props) => {
  const { image, video, title, info } = props;
  return (
    <div className="empty-folder">
      {image && <img src={image} alt="Nothing to show" />}
      {!image && video && (
        <video loop autoPlay muted>
          <source src={HowToVideo} type="video/webm" />
          <source src={HowToVideoMp4} type="video/mp4" />
        </video>
      )}
      <h1>{title}</h1>
      {info}
    </div>
  );
};

export const FolderInfo = ({ currentFolder, translate }) => {
  let folder = "emptyFolder";
  let image;
  let video;
  if (currentFolder && currentFolder.attributes) {
    if (currentFolder.attributes.object_type === "root") {
      image = EmptyTeamFolderImage;
      folder = "emptyTeamFolder";
    } else {
      image = EmptyFolderImage;
    }
  } else {
    video = HowToVideo;
  }
  const title = translate(`${folder}.header`);
  const info = (
    <Translate
      id={`${folder}.info`}
      options={{
        renderInnerHtml: true,
      }}
    />
  );
  return <EmptyFolder image={image} video={video} title={title} info={info} />;
};
