import React, { useState } from "react";
import _ from "lodash";
import { AUTO_STOP_OPTIONS, IPC_MESSAGE, LOCAL_STORAGE } from "Constants/global.constants";
import { getItemFromLocalStorage, Tracker, isMobile, saveItemToLocalStorage } from "Utils";
import { TextButton, Dropdown, Switch, HoverableIconButton } from "UI";
import { isInFullScreen, isMacOS } from "Utils/Helpers/browser.helpers";
import { getSwitchCmdPreference, getVerticalScrollDirection } from "Utils/Helpers/streaming.helpers";
import Electron from "Utils/Helpers/electron.helpers";
import "./SettingsTab.styles.scss";

const determineAutoTurnOffSettingByWorkstation = (machine) => {
  if (machine) {
    let { auto_stop_threshold: autoStopThreshold } = machine?.attributes;
    autoStopThreshold ||= machine?.attributes?.seat?.attributes?.auto_stop_threshold;

    if (autoStopThreshold > 0) {
      const autoStopOption = _.findKey(AUTO_STOP_OPTIONS, (option) => option === autoStopThreshold);
      if (autoStopOption) {
        return autoStopOption;
      }
    }
  }

  return "OFF";
};

const SettingsTab = ({
  machine,
  connected,
  handleCopyTo,
  handleCopyFrom,
  isDockOpen,
  isMicEnabled,
  setIsDockOpen,
  changeSoundState,
  toggleMic,
  changeVerticalScrollDirection,
  changeSwitchCmdPreference,
  showScreenKeyboard,
  handleAutoTurnOffChanged,
  translate,
  gameModeEnabled,
  changeGameMode,
  setShowGameModeOnModal,
  toggleFullscreen,
  ctrlAltDel,
  enterPassword,
  blockClipboardEvents,
}) => {
  const [isAudioEnabled, setIsAudioEnabled] = useState(getItemFromLocalStorage(LOCAL_STORAGE.soundEnabled, false));
  const [isScrollDirectionChanged, setIsScrollDirectionChanged] = useState(getVerticalScrollDirection());
  const [useCmdCtrlButton, setUseCmdCtrlButton] = useState(getSwitchCmdPreference());
  const [autoTurnOffSetting, setAutoTurnOffSetting] = useState(determineAutoTurnOffSettingByWorkstation(machine));

  const selectAutoTurnOffOption = (autoTurnOffSetting) => {
    setAutoTurnOffSetting(autoTurnOffSetting);

    handleAutoTurnOffChanged(autoTurnOffSetting);
  };

  const onSoundToggle = (state) => {
    changeSoundState(state);
    setIsAudioEnabled(state);
  };

  return (
    <>
      <h1>{translate("dock.settings.sections.shortcut")}</h1>
      <div className="shortcut-buttons">
        <HoverableIconButton
          name={isAudioEnabled ? "sound-on" : "sound-mute"}
          onClick={() => {
            onSoundToggle(!isAudioEnabled);
          }}
          hoverText={
            isAudioEnabled ? (
              <>
                Sound <span className="purple-main">ON</span>
              </>
            ) : (
              <>
                Sound <span className="red">OFF</span>
              </>
            )
          }
          disabled={!connected}
        />
        <HoverableIconButton
          name={isInFullScreen() ? "active-fullscreen" : "new-fullscreen"}
          onClick={() => {
            toggleFullscreen();
            setIsDockOpen(false);
          }}
          hoverText="Full Screen"
          disabled={!connected}
        />
        <HoverableIconButton
          name="microphone"
          onClick={() => {
            setIsDockOpen(!isDockOpen);
            // Ask electron for mic access
            Electron.sendAsyncMessage(IPC_MESSAGE.MICROPHONE_ACCESS);
            toggleMic();
          }}
          hoverText="Microphone"
          isActive={isMicEnabled}
          isStroke
          disabled={!connected}
        />
        {!isMobile && (
          <HoverableIconButton
            name="gamepad"
            onClick={() => {
              if (!gameModeEnabled && !getItemFromLocalStorage(LOCAL_STORAGE.showedGameModeModal)) {
                setShowGameModeOnModal(true);
              } else {
                changeGameMode();
              }
              setIsDockOpen(false);
            }}
            isAlpha
            text={"Game\nMode"}
            hoverText="Game Mode"
            isActive={gameModeEnabled}
            disabled={!connected}
          />
        )}
      </div>
      <TextButton
        className="menu-item"
        text={translate("dock.settings.keyboard")}
        onClick={() => {
          showScreenKeyboard();
          setIsDockOpen(!isDockOpen);
        }}
        disabled={!connected}
      />
      <TextButton
        className="menu-item"
        text={translate("dock.settings.copyToVagon")}
        onClick={handleCopyTo}
        disabled={!connected}
      />
      <TextButton
        className="menu-item"
        text={translate("dock.settings.copyFromVagon")}
        onClick={handleCopyFrom}
        disabled={!connected || blockClipboardEvents}
      />
      <TextButton
        className="menu-item"
        text={translate("dock.settings.ctrlAltDel")}
        onClick={() => {
          ctrlAltDel();
          setIsDockOpen(!isDockOpen);
        }}
        disabled={!connected}
      />
      <TextButton
        className="menu-item"
        text={translate("dock.settings.enterPassword")}
        onClick={() => {
          enterPassword();
          setIsDockOpen(!isDockOpen);
        }}
        disabled={!connected}
      />
      {isMacOS && (
        <>
          <h1>{translate("dock.keyboardMouse.keyboard")}</h1>
          <Switch
            className="menu-item"
            text={translate("dock.settings.cmdCtrlButton")}
            checked={useCmdCtrlButton}
            onChange={(checked) => {
              changeSwitchCmdPreference(checked);
              setUseCmdCtrlButton(checked);
            }}
            disabled={!connected}
          />
        </>
      )}
      <h1>{translate("dock.keyboardMouse.mouse")}</h1>
      <Switch
        className="menu-item"
        text={translate("dock.settings.scrollDirection")}
        checked={isScrollDirectionChanged}
        onChange={(checked) => {
          changeVerticalScrollDirection(checked);
          setIsScrollDirectionChanged(checked);
        }}
        disabled={!connected}
      />
      <h1>{translate("dock.settings.sections.background")}</h1>
      <Switch
        className="menu-item"
        text={translate("dock.settings.autoTurnOff")}
        checked={autoTurnOffSetting !== "OFF"}
        onChange={(state) => {
          if (state) {
            selectAutoTurnOffOption("AFTER_15_MINUTES");
          } else {
            selectAutoTurnOffOption("OFF");
          }
        }}
      />
      <div className="menu-item">
        <Dropdown
          disabled={autoTurnOffSetting === "OFF"}
          defaultSelected={autoTurnOffSetting}
          mapFunction={(option) => {
            return translate(`autoStopOption.${option}`);
          }}
          options={Object.keys(AUTO_STOP_OPTIONS)}
          handleSelectedOptionChange={selectAutoTurnOffOption}
          underlined
        />
      </div>
    </>
  );
};

export default SettingsTab;
