import React, { useState } from "react";
import { Animation, DashboardSubScreenLayout } from "Utils";
import { useSelector } from "react-redux";
import ChangePasswordForm from "Components/Dashboard/Account/Account/ChangePasswordForm.component";
import { Modal, TextButton } from "UI";
import ProfilePicture from "UI/ProfilePicture/ProfilePicture.component";
import TimezoneModal from "Components/Dashboard/Files/Components/TimezoneModal/TimezoneModal.component";
import useMatchTimeZone from "Utils/Hooks/useMatchTimeZone";
import { useTimezoneSelect } from "react-timezone-select";

import "./Settings.styles.scss";

const Settings = ({ translate }) => {
  const { organization } = useSelector((state) => state.organizations);
  const { account } = useSelector((state) => state.account);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const [showPasswordChangeSuccessModal, setShowPasswordChangeSuccessModal] = useState(false);
  const { name, email, time_zone: userTimeZone } = account?.attributes || {};
  const timezoneLabel = useMatchTimeZone(userTimeZone);

  return (
    <>
      <DashboardSubScreenLayout
        headerTitle={translate("organizationMemberSettings.header.title")}
        headerDescription={translate("organizationMemberSettings.header.description")}
        translate={translate}
        waitFor={[organization !== null]}
        noScroll
      >
        <p className="header">{translate("organizationMemberSettings.information.profile.title")}</p>
        <div className="organization-member-settings-container">
          <div className="organization-member-profile-container">
            <ProfilePicture account={account} translate={translate} />
            <div className="information-item">
              <div className="user-info">
                {name && (
                  <p className="label">{translate("organizationMemberSettings.information.profile.nameLabel")}</p>
                )}
                <p className="name-info">{name}</p>
                <p className="label">{translate("organizationMemberSettings.information.profile.emailLabel")}</p>
                <p className="name-info">{email}</p>
              </div>
              {showChangePasswordForm ? (
                <ChangePasswordForm
                  accessToken={account.accessToken}
                  translate={translate}
                  setShowPasswordChangeSuccess={setShowPasswordChangeSuccessModal}
                  setShowChangePasswordForm={setShowChangePasswordForm}
                />
              ) : (
                <TextButton
                  text="Change Password"
                  color="purple"
                  className="change-password-button"
                  onClick={() => setShowChangePasswordForm(true)}
                />
              )}
            </div>
          </div>
          <div className="additional-information-container">
            <div className="label">{translate("organizationSettings.information.team.timezoneLabel")}</div>
            <div className="time-zone-value-container">
              <p className="info-value">{timezoneLabel}</p>
              <TextButton text="Update" color="purple" onClick={() => setShowTimezoneModal(true)} />
            </div>
          </div>
        </div>
      </DashboardSubScreenLayout>

      {showPasswordChangeSuccessModal && (
        <Modal
          closeOnOverlayClick
          closeAction={() => setShowPasswordChangeSuccessModal(false)}
          headerText={translate("modals.passwordChangeSuccessModal.header")}
          descriptionText={
            <>
              <Animation
                type="success"
                style={{ height: "6rem", width: "6rem", marginLeft: "auto", marginRight: "auto" }}
              />
              {translate("modals.passwordChangeSuccessModal.description")}
            </>
          }
          confirmText={translate("modals.passwordChangeSuccessModal.confirm")}
          confirmAction={() => {
            setShowPasswordChangeSuccessModal(false);
          }}
        />
      )}
      {showTimezoneModal && <TimezoneModal userTimeZone={userTimeZone} setShowTimezoneModal={setShowTimezoneModal} />}
    </>
  );
};

export default Settings;
