import { getProductOnboardingAPI } from "Actions/Account.actions";
import { getPaymentMethodsAPI } from "Actions/Dashboard/Payment.actions";
import { getOrganizationAPI } from "Actions/Organization.actions";
import Billing from "Components/Organization/Admin/Billing/Billing.component";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const BillingContainer = ({ translate }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationAPI());
    dispatch(getPaymentMethodsAPI());
    dispatch(getProductOnboardingAPI());
  }, []);

  return <Billing translate={translate} />;
};

export default BillingContainer;
