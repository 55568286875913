import React from "react";
import { classnames } from "Utils";

import ComputerProgressBar from "./ComputerProgressBar.component";
import DashboardHinter from "./DashboardHinter/DashboardHinter.component";
import ComputerTimer from "./ComputerTimer.component";

const Banner = (props) => {
  const {
    isTeamAccount,
    referral,
    workstation,
    getWorkstationCurrentSessionAPI,
    getWorkstationCurrentSessionCTX,
    updateWorkstationStatusCTX,
    setShowFeedbackModal,
    feedBackRating,
    setFeedBackRating,
    hinterType,
    setHinterType,
    interest,
    small,
    closed,
    history,
    translate,
  } = props;
  const { name } = workstation.attributes.machine.attributes;
  let bannerClassName = "vg-banner";

  if (closed) {
    bannerClassName += " closed";
  } else if (small) {
    bannerClassName += " small";
  }

  return (
    <div className={classnames([bannerClassName, interest, "bg"])}>
      <div className="computer-name">{name}</div>
      <DashboardHinter
        isTeamAccount={isTeamAccount}
        type={hinterType}
        setType={setHinterType}
        referral={referral}
        setShowFeedbackModal={setShowFeedbackModal}
        feedBackRating={feedBackRating}
        setFeedBackRating={setFeedBackRating}
        history={history}
        translate={translate}
      />
      <ComputerProgressBar
        workstation={workstation}
        updateWorkstationStatusCTX={updateWorkstationStatusCTX}
        translate={translate}
      />
      <ComputerTimer
        getWorkstationCurrentSessionAPI={getWorkstationCurrentSessionAPI}
        getWorkstationCurrentSessionCTX={getWorkstationCurrentSessionCTX}
        updateWorkstationStatusCTX={updateWorkstationStatusCTX}
        workstation={workstation}
      />
    </div>
  );
};

export default Banner;
