import React, { useRef, useState } from "react";
import { Steps } from "intro.js-react";
import InviteTeamMember from "Assets/images/onboardingModals/invite-member.jpeg";
import Collaborative from "Assets/images/onboardingModals/collaborative.jpeg";
import MemberInviteImage from "Assets/images/onboardingModals/member-invite.jpg";
import FirstTeamComputerImage from "Assets/images/onboardingModals/first-team-computer.jpg";
import AssignComputerImage from "Assets/images/onboardingModals/assign-computer.jpg";
import ReadyImage from "Assets/images/onboardingModals/ready.jpg";
import { HOME_ONBOARDING_STEPS } from "Constants/teamsOnboarding.constants";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const TeamsHomeOnboardingModal = ({ setCurrentStep, onExit, translate }) => {
  const stepsObject = useRef(null);
  const menuClass = document.getElementById("menu-widescreen-container");
  const [firstStep, setFirstStep] = useState(true);
  const [thirdStep, setThirdStep] = useState(true);
  const [forth, setForthStep] = useState(true);

  const steps = [
    {
      title: `<img src=${Collaborative} />`,
      intro: `<h1>${translate("modals.onboardingModals.teamsOnboardingModal.initial.header")}</h1><p>${translate(
        "modals.onboardingModals.teamsOnboardingModal.initial.description",
      )}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".add-user-button",
      title: `<img src=${InviteTeamMember} />`,
      intro: `<h1>${translate("modals.onboardingModals.teamsOnboardingModal.home.invite.header")}</h1><p>${translate(
        "modals.onboardingModals.teamsOnboardingModal.home.invite.description",
      )}</p>`,

      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".table-row.highlight-third",
      title: `<img src=${MemberInviteImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.home.invitations.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.home.invitations.description")}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".create-computers-button",
      title: `<img src=${FirstTeamComputerImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.home.createComputer.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.home.createComputer.description")}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".table-row.highlight-second",
      title: `<img src=${AssignComputerImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.home.assignComputer.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.home.assignComputer.description")}</p>`,
      position: "bottom",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".table-row.highlight-third",
      title: `<img src=${ReadyImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.home.assignedComputer.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.home.assignedComputer.description")}</p>`,
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
  ];

  const onBeforeChange = (currentStep) => {
    setCurrentStep(currentStep);
    if (currentStep === HOME_ONBOARDING_STEPS.initialStep.step || currentStep === HOME_ONBOARDING_STEPS.addUser.step) {
      setFirstStep(true);
      setThirdStep(true);
      setForthStep(true);
    } else if (currentStep === HOME_ONBOARDING_STEPS.invitedUser.step) {
      menuClass.style.position = "fixed";
      if (firstStep) {
        setTimeout(() => {
          setFirstStep(false);
          stepsObject.current.introJs.goToStep(3);
          stepsObject.current.updateStepElement(HOME_ONBOARDING_STEPS.invitedUser.step);
        }, 10);
      }
      setThirdStep(true);
      setForthStep(true);
    } else if (currentStep === HOME_ONBOARDING_STEPS.createComputer.step) {
      stepsObject.current.updateStepElement(HOME_ONBOARDING_STEPS.createComputer.step);
      setFirstStep(true);
      setThirdStep(true);
      setForthStep(true);
    } else if (currentStep === HOME_ONBOARDING_STEPS.sampleComputer.step) {
      if (thirdStep) {
        setTimeout(() => {
          setThirdStep(false);
          stepsObject.current.introJs.goToStep(5);
          stepsObject.current.updateStepElement(HOME_ONBOARDING_STEPS.sampleComputer.step);
        }, 10);
      }

      setFirstStep(true);
      setForthStep(true);
    } else if (currentStep === HOME_ONBOARDING_STEPS.assignedComputer.step) {
      stepsObject.current.updateStepElement(HOME_ONBOARDING_STEPS.assignedComputer.step);
      if (forth) {
        setTimeout(() => {
          setForthStep(false);
          stepsObject.current.introJs.goToStep(6);
          stepsObject.current.updateStepElement(HOME_ONBOARDING_STEPS.assignedComputer.step);
        }, 10);
      }
      setFirstStep(true);
      setThirdStep(true);
    } else {
      setFirstStep(true);
    }
  };

  return (
    <Steps
      enabled
      options={{ nextToDone: true }}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      onBeforeChange={onBeforeChange}
      ref={stepsObject}
    />
  );
};

export default TeamsHomeOnboardingModal;
