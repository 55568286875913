import React from "react";

import "./MachineTypeList.style.scss";
import { MachineTypeCard } from "UI";
import { WORKSTATION_TYPES } from "Constants/global.constants";

const MachineTypeList = (props) => {
  const { displayAll, machineTypes, shouldSavePerformance, selectedMachineType, setSelectedMachineType } = props;

  const renderMachineTypeByFriendlyName = (machineTypeFriendlyName) => {
    const machineType = machineTypes.find((machineType) => {
      const friendlyName =
        machineType.attributes?.details?.attributes?.friendly_name || machineType?.attributes?.friendly_name;

      return machineTypeFriendlyName === friendlyName;
    });

    if (!machineType) {
      return null;
    }

    return (
      <MachineTypeCard
        key={machineType.id}
        fixedDirection
        machineType={machineType}
        shouldSavePerformance={shouldSavePerformance}
        selectedMachineType={selectedMachineType}
        setSelectedMachineType={setSelectedMachineType}
      />
    );
  };

  if (!machineTypes) {
    return null;
  }

  return (
    <div className="machine-types-container">
      <div className="row">{WORKSTATION_TYPES.firstRow.map(renderMachineTypeByFriendlyName)}</div>
      <div className={`row ${displayAll ? "" : "hide"}`}>
        {WORKSTATION_TYPES.secondRow.map(renderMachineTypeByFriendlyName)}
      </div>
    </div>
  );
};

export default MachineTypeList;
