export const LOGIN_CLEAR = "LOGIN_CLEAR";
export const LOGIN_API_PENDING = "LOGIN_API_PENDING";
export const LOGIN_API_SUCCESS = "LOGIN_API_SUCCESS";
export const LOGIN_API_FAILURE = "LOGIN_API_FAILURE";

export const LOGOUT_API_PENDING = "LOGOUT_API_PENDING";

export const CHECK_FINGERPRINT_PENDING = "CHECK_FINGERPRINT_PENDING";
export const CHECK_FINGERPRINT_SUCCESS = "CHECK_FINGERPRINT_SUCCESS";
export const CHECK_FINGERPRINT_FAILURE = "CHECK_FINGERPRINT_FAILURE";

export const VERIFY_FINGERPRINT_PENDING = "VERIFY_FINGERPRINT_PENDING";
export const VERIFY_FINGERPRINT_SUCCESS = "VERIFY_FINGERPRINT_SUCCESS";
export const VERIFY_FINGERPRINT_FAILURE = "VERIFY_FINGERPRINT_FAILURE";

export const VERIFY_FINGERPRINT_ERROR_CODES = {
  verificationExpired: 4311,
  verificationFailed: 4312,
  wrongVerificationCode: 4313,
};

export const CHECK_FINGERPRINT_ERROR_CODES = {
  fingerprintBlocked: 4314,
};

export const VERIFICATION_TIMEOUT = 120;
