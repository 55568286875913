import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import moment from "moment";
import { useDebounce } from "use-debounce";
import { DashboardSubScreenLayout, apiGenerator, getItemFromLocalStorage, isMobile } from "Utils";
import { convertDateFormat, convertToSelectedTimeZone, isOdd } from "Utils/Helpers/functions.helpers";
import { ExpandableTable, IconButton, Button, SearchInput, HoverableTooltip } from "UI";
import ChromeLogo from "Assets/icons/chrome.svg";
import WindowsLogo from "Assets/icons/windows.svg";
import { ORGANIZATION_SILVER_IMAGE_SOURCES } from "Constants/Organization.constants";
import EditableCell from "UI/EditableCell/EditableCell.component";
import StatusBadge from "UI/StatusBadge/StatusBadge.component";
import AppMenuPopUp from "Components/AppStreaming/Home/AppMenuPopUp/AppMenuPopUp.component";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";
import SoftwareCard from "Components/Dashboard/CreateComputer/CreateComputerPreInstall/SoftwareCard/SoftwareCard.component";
import { ORGANIZATION_TEMPLATES_DUMMY_TEMPLATE_DATA } from "Constants/teamsOnboarding.constants";
import { useSelector } from "react-redux";
import TeamsTemplateOnboardingModal from "Components/Workstation/Modals/TeamsTemplateOnboardingModal.component";
import EmptyContent from "../Components/EmptyContent/EmptyContent.component";
import AssignedSeatsModal from "../Shared/AssignedSeatsModal/AssignedSeatsModal.component";
import AssignTemplateModal from "./AssignTemplateModal/AssignTemplateModal.component";
import DeleteTemplateModal from "./DeleteTemplateModal.component";

import "./Templates.styles.scss";

const Templates = ({ translate, history }) => {
  const [expandingRow, setExpandingRow] = useState(null);
  const [selectedSilverImage, setSelectedSilverImage] = useState(null);
  const [showAssignTemplateModal, setShowAssignTemplateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [highlightedSilverImageId, setHighlightedSilverImageId] = useState(null);
  const [moreButtonPopUpPosition, setMoreButtonPopUpPosition] = useState(null);
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const [showTeamsTemplatesOnboardingModal, setShowTeamsTemplatesOnboardingModal] = useState(null);
  const [showOnboardingLastStep, setShowOnboardingLastStep] = useState(null);
  const [showTeamsCreateTemplateOnboardingModal, setShowTeamsCreateTemplateOnboardingModal] = useState(null);
  const [onboardingModalCurrentStep, setOnboardingModalCurrentStep] = useState(0);
  const [showAssignedSeatsModal, setShowAssignedSeatsModal] = useState(false);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const { productOnboardingCTX, account } = useSelector((state) => state.account);
  const { time_zone: userTimeZone } = account?.attributes || {};
  const { status: productOnboardingStatus, data: productOnboardingData } = productOnboardingCTX || {};
  const checkTemplate = productOnboardingData?.teams?.template_created;

  const showTemplatesOnboarding =
    productOnboardingStatus === REQUEST_STATUS.SUCCESS &&
    !checkTemplate &&
    getItemFromLocalStorage(LOCAL_STORAGE.showTeamsTemplatesOnboardingModal, true);

  const templatesTableRef = useRef(null);
  const location = useLocation();

  const { createdSilverImageId } = location.state || {};

  const { status: selectedSilverImageStatus } = selectedSilverImage?.attributes || {};

  useEffect(() => {
    let timeoutId;
    if (createdSilverImageId) {
      setHighlightedSilverImageId(createdSilverImageId);
      timeoutId = setTimeout(() => {
        setHighlightedSilverImageId(null);
        history.replace({ ...location, state: {} });
      }, 5000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [createdSilverImageId, setHighlightedSilverImageId, history, location]);

  const {
    isInitialLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    data: organizationSilverImagesData,
    refetch,
  } = useInfiniteQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES, debouncedSearchQuery],
    queryFn: ({ pageParam = 1 }) => {
      const params = { page: pageParam, per_page: 25, q: searchQuery };
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES, {
        params,
      }).then((res) => {
        return res.data;
      });
    },
    refetchInterval: 1000 * 20,
    getNextPageParam: (lastPage) => lastPage.next_page,
    keepPreviousData: true,
  });

  const updateSilverImageMutation = useMutation({
    mutationFn: ({ silverImageId, name }) => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGE(silverImageId), { name });
    },
    onSuccess: () => {
      refetch();
    },
  });

  const popUpMenuActions = {
    delete: "delete",
  };

  const appPopUpMenuContent = useMemo(() => {
    return Object.keys(popUpMenuActions).map((key) => {
      return {
        text: translate(`organizationTemplates.templatesTable.popUpMenuActions.${key}`),
        onClick: () => {
          setMoreButtonPopUpPosition(null);
          setShowDeleteTemplateModal(true);
        },
        disabled: selectedSilverImageStatus !== "available",
      };
    });
  }, [selectedSilverImage]);

  const handleMoreButtonClick = (event, item) => {
    if (!moreButtonPopUpPosition) event.stopPropagation();
    event.preventDefault();
    setSelectedSilverImage(item);
    setMoreButtonPopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
  };

  const columns = [
    { name: "Id", weight: 0, hide: true },
    { name: "Template", weight: 10 },
    { name: "Size", weight: 10 },
    { name: "Creation Time", weight: 10 },
    { name: "Status", weight: 10 },
    { name: "Users", weight: 5 },
    { name: "buttons", hide: true, weight: 4 },
  ];

  useEffect(() => {
    if (showTeamsTemplatesOnboardingModal === null && productOnboardingCTX.status === REQUEST_STATUS.SUCCESS) {
      const shouldDisplayAfterCreationOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showTeamsTemplatesOnboardingModal, true);
      const shouldDisplayAssignedOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showTeamsTemplatesOnboardingAssignedModal, false);
      const shouldDisplayTemplateOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showTeamsCreateTemplateOnboardingModal, true);

      setShowTeamsTemplatesOnboardingModal(checkTemplate ? false : shouldDisplayAfterCreationOnboarding);
      setShowOnboardingLastStep(checkTemplate ? false : shouldDisplayAssignedOnboarding);
      setShowTeamsCreateTemplateOnboardingModal(checkTemplate ? false : shouldDisplayTemplateOnboarding);
    }
  }, [productOnboardingCTX.status]);

  const { has_any_silver_image: hasAnySilverImage } = organizationSilverImagesData?.pages?.[0] || {};
  const onboardingActive = showTeamsTemplatesOnboardingModal;
  const data = useMemo(() => {
    return (
      showTemplatesOnboarding ? ORGANIZATION_TEMPLATES_DUMMY_TEMPLATE_DATA : organizationSilverImagesData
    )?.pages?.flatMap((page) => {
      return page?.silver_images?.map((item, index) => {
        const { name, size, created_at: createdAt, status, softwares, source } = item.attributes;

        const expanded = expandingRow === item.id;
        const determineStatus = () => {
          if (status === "available") return "ready_to_assign";
          return status;
        };

        return {
          key: item.id,
          template: (
            <EditableCell
              initialValue={name}
              className={highlightedSilverImageId === item.id ? "highlighted" : undefined}
              onEnter={(value) => {
                updateSilverImageMutation.mutate({ silverImageId: item.id, name: value });
              }}
              disabled={showTeamsTemplatesOnboardingModal || showOnboardingLastStep}
              onCancel={() => {
                updateSilverImageMutation.reset();
              }}
              error={
                updateSilverImageMutation.variables?.silverImageId === item.id && updateSilverImageMutation.isError
                  ? translate("organizationTemplates.templatesTable.updateError")
                  : null
              }
            />
          ),
          size: `${size} GB`,
          "creation time": createdAt
            ? convertToSelectedTimeZone(
                `${convertDateFormat(createdAt.substring(0, 10))} ${createdAt.substring(
                  createdAt.indexOf("T") + 1,
                  createdAt.indexOf("T") + 6,
                )}`,
                userTimeZone,
              )
            : "",
          status: <StatusBadge status={determineStatus()} color={isOdd(index) && "gray"} />,
          users: (
            <div className="assigned-users-cell">
              <IconButton
                name="organizations-user"
                smaller
                color="gray-3"
                onClick={() => {
                  setSelectedSilverImage(item);
                  setShowAssignedSeatsModal(true);
                }}
                disabled={item.attributes.assigned_seats?.length === 0}
              />
              <p>{item.attributes.assigned_seats?.length}</p>
            </div>
          ),
          buttons: (
            <div className="buttons-cell">
              <HoverableTooltip content="Assign">
                <IconButton
                  name="teams-plus"
                  smaller
                  color="gray-4"
                  onClick={() => {
                    if (!onboardingActive) {
                      setSelectedSilverImage(item);
                      setShowAssignTemplateModal(true);
                    }
                  }}
                />
              </HoverableTooltip>
              <HoverableTooltip content="More">
                <IconButton
                  name="more"
                  smaller
                  onClick={(event) => {
                    if (!onboardingActive) handleMoreButtonClick(event, item);
                  }}
                />
              </HoverableTooltip>
              {source === ORGANIZATION_SILVER_IMAGE_SOURCES.pre_installation && (
                <IconButton
                  name="teams-caret-down"
                  smaller
                  onClick={() => {
                    if (!onboardingActive) {
                      if (expandingRow === item.id) setExpandingRow(null);
                      else setExpandingRow(item.id);
                    }
                  }}
                />
              )}
            </div>
          ),
          expanded,
          expandableContent: (
            <div className="preinstalled-apps">
              <p>Preinstalled Apps</p>

              <div className="softwares">
                <SoftwareCard img={WindowsLogo} name="OS" small hideSize />
                <SoftwareCard img={ChromeLogo} name="Chrome" small hideSize />
                {softwares?.map((software) => {
                  const { logo, name } = software.attributes;
                  return (
                    <React.Fragment key={software.id}>
                      <SoftwareCard img={logo} name={name} small hideSize />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          ),
        };
      });
    });
  }, [
    showTeamsTemplatesOnboardingModal,
    showOnboardingLastStep,
    ORGANIZATION_TEMPLATES_DUMMY_TEMPLATE_DATA,
    organizationSilverImagesData,
    expandingRow,
    highlightedSilverImageId,
    updateSilverImageMutation,
    setSelectedSilverImage,
    setShowAssignTemplateModal,
    handleMoreButtonClick,
    onboardingModalCurrentStep,
    setExpandingRow,
    showTemplatesOnboarding,
  ]);

  const showExpandableTable = data?.length > 0 || showTemplatesOnboarding;

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("organizationTemplates.header.title")}
      headerDescription={translate("organizationTemplates.header.description")}
      noScroll
      loading={
        (isInitialLoading && searchQuery === "") ||
        productOnboardingStatus === REQUEST_STATUS.PENDING ||
        !productOnboardingData
      }
    >
      <div className="teams-templates-container" ref={templatesTableRef}>
        <div className="top-bar">
          <div className="left-actions">
            <SearchInput
              placeholder={translate("organizationTemplates.searchInput.placeHolder")}
              iconName="search"
              color="white"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>

          <div className="right-actions">
            {(hasAnySilverImage || (onboardingModalCurrentStep === 1 && onboardingActive)) && (
              <Button
                iconLeft="plus"
                text={translate("organizationTemplates.createTemplateButton.text")}
                onClick={() => {
                  if (!showOnboardingLastStep) history.push(routes.organizationAdminCreateTemplateStart);
                }}
              />
            )}
          </div>
        </div>
        {showExpandableTable ? (
          <ExpandableTable
            className="teams-templates-table"
            columns={columns}
            data={data}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onPageEnd={fetchNextPage}
            hideVerticalBar
            onboarding={showOnboardingLastStep}
            disabledAnimation={showTemplatesOnboarding}
          />
        ) : (
          <EmptyContent
            headerText={translate("organizationTemplates.emptyContent.title")}
            descriptionText={translate("organizationTemplates.emptyContent.description")}
            buttonText={translate("organizationTemplates.createTemplateButton.text")}
            onButtonClick={() => {
              history.push(routes.organizationAdminCreateTemplateStart);
            }}
            buttonIconLeft="plus"
            withoutButton={hasAnySilverImage}
          />
        )}
        {moreButtonPopUpPosition && (
          <AppMenuPopUp
            setShowPopUpMenu={() => {
              setMoreButtonPopUpPosition(null);
            }}
            content={appPopUpMenuContent}
            popUpPosition={moreButtonPopUpPosition}
            referencePosRef={templatesTableRef}
          />
        )}
      </div>
      {showAssignTemplateModal && (
        <AssignTemplateModal
          selectedSilverImageId={selectedSilverImage.id}
          selectedSilverImageSize={selectedSilverImage.attributes.size}
          onClose={() => {
            setShowAssignTemplateModal(false);
            refetch();
          }}
          translate={translate}
        />
      )}
      {showDeleteTemplateModal && (
        <DeleteTemplateModal
          silverImageId={selectedSilverImage.id}
          onClose={() => {
            setShowDeleteTemplateModal(false);
            refetch();
          }}
        />
      )}
      {showTeamsTemplatesOnboardingModal && (
        <TeamsTemplateOnboardingModal
          onExit={() => {
            setShowTeamsTemplatesOnboardingModal(false);
            if (showTeamsCreateTemplateOnboardingModal) {
              history.push(routes.organizationAdminCreateTemplateStart);
              localStorage.setItem(LOCAL_STORAGE.showTeamsTemplatesOnboardingModal, false);
            }
            if (showOnboardingLastStep) {
              localStorage.setItem(LOCAL_STORAGE.showTeamsTemplatesOnboardingAssignedModal, false);
              setShowOnboardingLastStep(false);
              setExpandingRow(null);
            }
          }}
          setCurrentStep={setOnboardingModalCurrentStep}
          setExpandingRow={setExpandingRow}
          showOnboardingLastStep={showOnboardingLastStep}
          translate={translate}
        />
      )}
      {showAssignedSeatsModal && selectedSilverImage && (
        <AssignedSeatsModal
          onClose={() => {
            setShowAssignedSeatsModal(false);
            setSelectedSilverImage(null);
          }}
          seats={selectedSilverImage?.attributes?.assigned_seats}
          translate={translate}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default Templates;
