import React, { useEffect, useState, useRef } from "react";
import { doesEventContainsFiles } from "Utils";
import { Icon } from "UI";

import "./TempFileUploadOverlay.styles.scss";

const UploadInfo = (props) => {
  const { iconName, title, titleBold, description } = props;

  return (
    <div className="upload-info">
      <Icon name={iconName} />
      <h1>
        {title}
        <br />
        <span>{titleBold}</span>
      </h1>
      <p>{description}</p>
    </div>
  );
};

const TempFileUploadOverlay = (props) => {
  const { onFileDrop, translate } = props;

  const overlayRef = useRef(null);
  const [dragDepth, setDragDepth] = React.useState(0);
  const [show, setShow] = useState(false);

  const onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (doesEventContainsFiles(event)) {
      if (overlayRef.current && event.target && overlayRef.current.contains(event.target)) {
        setDragDepth(dragDepth + 1);
      }
    }
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (overlayRef.current && event.target && overlayRef.current.contains(event.target)) {
      if (dragDepth === 1 && show) {
        setShow(false);
      }
      setDragDepth(Math.max(dragDepth - 1, 0));
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    if (files.length > 0) {
      onFileDrop(files);
    }
    setDragDepth(0);
    setShow(false);
  };

  useEffect(() => {
    const onDragOver = (event) => {
      event.preventDefault();
      setShow(true);
    };

    const onDragLeave = (event) => {
      event.preventDefault();
      if (event.target.id === "player") {
        setShow(false);
      }
    };

    window.addEventListener("dragover", onDragOver, true);
    window.addEventListener("dragleave", onDragLeave, true);
    return () => {
      window.removeEventListener("dragover", onDragOver, true);
      window.removeEventListener("dragleave", onDragLeave, true);
    };
  }, [overlayRef]);

  return (
    <div className="app-streaming-file-upload-overlay">
      {(dragDepth > 0 || show) && (
        <div
          className="upload-overlay-container"
          ref={overlayRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="upload-container workstation-upload-container over">
            <UploadInfo
              iconName="upload-to-workstation"
              title={translate("appStreamingFiles.uploadOverlay.title")}
              description={translate("appStreamingFiles.uploadOverlay.text")}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TempFileUploadOverlay;
