import React, { useState } from "react";
import { some, values } from "lodash";

import { IconButton, MachineTypeCard } from "UI";

const isSelectedMachineTypeInList = (selectedMachineType, machineTypes) => {
  return some(
    values(machineTypes),
    (machineType) => parseInt(selectedMachineType, 10) === parseInt(machineType.id, 10),
  );
};

const ComputerPerformanceRow = (props) => {
  const { performanceType, machineTypes, selectedMachineType, setSelectedMachineType, translate } = props;
  const [image, setImage] = useState();

  const [showAllMachineType, setShowAllMachineType] = React.useState(
    isSelectedMachineTypeInList(selectedMachineType, machineTypes),
  );

  React.useEffect(() => {
    // eslint-disable-next-line
    setImage(require(`Assets/images/machineTypes/groups/${performanceType}.png`));
  }, [performanceType]);

  return (
    <div className="computer-performance-row">
      <div className="row-header">
        <div className="img-container mobile-only">
          <img src={image} className="mobile-only" alt={performanceType} />
        </div>
        <div className="divider mobile-only" />
        <div className="title-container">
          <div className="title">{translate(`computerPerformance.performanceTypes.${performanceType}`)}</div>

          {performanceType !== "general" && (
            <span className="description">
              {translate("computerPerformance.performanceTypes.poweredBy.common")}{" "}
              <span className="provider">
                {translate(`computerPerformance.performanceTypes.poweredBy.${performanceType}`)}
              </span>
            </span>
          )}
        </div>
        <IconButton
          onClick={() => {
            setShowAllMachineType(!showAllMachineType);
          }}
          name={showAllMachineType ? "chevron-up-circle" : "chevron-down-circle"}
          className="mobile-only"
          small
        />
      </div>
      {Object.values(machineTypes).map((machineType) => (
        <MachineTypeCard
          key={machineType.id}
          className={`${showAllMachineType ? "" : "mobile-only-hide"}`}
          machineType={machineType}
          selectedMachineType={selectedMachineType}
          setSelectedMachineType={setSelectedMachineType}
          shouldSavePerformance={false}
        />
      ))}
    </div>
  );
};

export default ComputerPerformanceRow;
