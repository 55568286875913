import React from "react";

import { ConfirmationModal } from "UI";

const StreamPreferencesModal = (props) => {
  const { setShowStreamPreferences, submitStreamPreference, translate } = props;

  const onConfirm = () => {
    submitStreamPreference();
  };

  const onCancel = () => {
    setShowStreamPreferences(false);
  };

  return (
    <ConfirmationModal
      headerText={translate("modals.stream.header")}
      descriptionText={translate("modals.stream.description")}
      confirmText={translate("modals.stream.confirm")}
      confirmAction={onConfirm}
      secondaryAction={onCancel}
      secondaryText={translate("modals.stream.cancel")}
      closeAction={onCancel}
    />
  );
};

export default StreamPreferencesModal;
