import React from "react";
import { Icon, Modal } from "UI";
import { isMobile } from "Utils";

import "./PermissionInformationModal.styles.scss";

const PermissionInformationModal = ({ translate, onClose }) => {
  const permissions = [
    {
      id: 1,
      name: translate("organizationPermissions.permissionInformationModal.permissions.internetAccess.header"),
      description: translate(
        "organizationPermissions.permissionInformationModal.permissions.internetAccess.description",
      ),
      icon: <Icon name="connect" color="gray-3" />,
    },
    {
      id: 2,
      name: translate("organizationPermissions.permissionInformationModal.permissions.usageMonitoring.header"),
      description: translate(
        "organizationPermissions.permissionInformationModal.permissions.usageMonitoring.description",
      ),
      icon: <Icon name="monitoring" color="gray-3-stroke" />,
    },
    {
      id: 3,
      name: translate("organizationPermissions.permissionInformationModal.permissions.downloadFromVagon.header"),
      description: translate(
        "organizationPermissions.permissionInformationModal.permissions.downloadFromVagon.description",
      ),
      icon: <Icon name="file-download" color="gray-3" />,
    },
    {
      id: 4,
      name: translate("organizationPermissions.permissionInformationModal.permissions.copyClipboard.header"),
      description: translate(
        "organizationPermissions.permissionInformationModal.permissions.copyClipboard.description",
      ),
      icon: <Icon name="copy" color="gray-3-stroke" />,
    },
    {
      id: 5,
      name: translate("organizationPermissions.permissionInformationModal.permissions.sessionScreenshot.header"),
      description: translate(
        "organizationPermissions.permissionInformationModal.permissions.sessionScreenshot.description",
      ),
      icon: <Icon name="camera" color="gray-3" />,
    },
    {
      id: 6,
      name: translate("organizationPermissions.permissionInformationModal.permissions.powerShellScript.header"),
      description: translate(
        "organizationPermissions.permissionInformationModal.permissions.powerShellScript.description",
      ),
      icon: <Icon name="shell" color="gray-3" />,
    },
  ];

  return (
    <Modal
      className="permission-information-modal"
      headerText={translate("organizationPermissions.permissionInformationModal.header")}
      closeAction={onClose}
      topRightIcon="close"
      topRightIconAction={onClose}
      mobile={isMobile}
    >
      <div className="permission-information-modal-content">
        {permissions.map((permission) => {
          return (
            <div className="permission" key={permission.id}>
              <div className="icon-background">{permission.icon}</div>
              <div className="text">
                <div className="title">{permission.name}</div>
                <div className="description">{permission.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default PermissionInformationModal;
