/* eslint-disable */
let prevButtons = []; // each element is an array of 16 bools - pressed if true
let prevAxes = []; // each element is an array of 4 doubles
let pollInterval = null;

let eventCallback = (_) => {};

// unfortunately, getGamepads isn't supported omn web workers. So, what we need to do is periodically poll
// the gamepads for updates 
const pollGamepads = () => {
  let gps = navigator.getGamepads();
  for(let i=0; i<prevButtons.length; i++) {
    if(!gps[i]) continue; // in case the gamepad was disconnected or somehow removed
    const buttons = gps[i].buttons.map(b => b.pressed);
    const axes = gps[i].axes;
    for(let b=0; b<16; b++) {
      // TODO: Move the payload generation to a helper
      if(prevButtons[i][b] !== buttons[b]) {
        const type = buttons[b] ? 0 : 1;
        const payload = { "$type": "24", "t": type, "g": i, "b":b };
        eventCallback(payload);
        prevButtons[i][b] = buttons[b];
      }
    }
    for(let a=0; a<4; a++) {
      if(prevAxes[i][a] !== axes[a]) {
        const payload = { "$type": "24", "t": 2, "g": i, "b":a, "v":axes[a] };
        eventCallback(payload);
        prevAxes[i][a] = axes[a];
      }
    }
  }
}

// Takes an event callback, which receives events directly in the desired wire format
export const startPolling = (cb) => {
  eventCallback = cb;
  window.addEventListener("gamepadconnected", (evt) => {
    let buttons = [];
    let axes = [];
    for(let i=0; i<16; i++) buttons.push(false);
    for(let i=0; i<4; i++) axes.push(0.0);
  
    prevButtons.push(buttons);
    prevAxes.push(axes);
    // If this was the first gamepad, start polling
    if(prevButtons.length === 1) {
      if(pollInterval === null) pollInterval = setInterval(pollGamepads, 40);
    }
    // By the way, is it better to do this via setInterval or requestAnimationFrame?
    
    const payload = { "$type": "24", "t": 3, "g": evt.gamepad.index };
    eventCallback(payload);
  });
  window.addEventListener("gamepaddisconnected", (evt) => {
    // TODO: Handle gamepad disconnects for multiple gamepads
    // This implementation only handles the single gamepad case correctly
    if(prevButtons.length === 1) {
      if(pollInterval !== null) {
        clearInterval(pollInterval);
        pollInterval = null;
      }
    }
  });
};
