import React, { useEffect, useLayoutEffect } from "react";

import _ from "lodash";
import { ReactComponent as WorldMapSVG } from "Assets/images/world-map-base.svg";
import { MAP_REGIONS } from "./WorldMap.component";
import "./WorldMap.style.scss";

const WorldMapCoverage = ({ id = 1, activeRegions = [], coveredRegions = [], onRegionClick, passiveCoverage }) => {
  useLayoutEffect(() => {
    // Set region buttons on SVG
    _.each(Object.entries(MAP_REGIONS), ([, regionId]) => {
      const element = document.getElementById(`${regionId}-g`);
      if (element) {
        element.id = `${regionId}-g-${id}`;
        element.classList.add("map-point");
      }
      const vgMarker = document.getElementById(regionId);
      if (vgMarker) {
        vgMarker.id = `${regionId}-${id}`;
      }
      const region = document.getElementById(`${regionId}-coverage`);
      const regionLabel = document.getElementById(`${regionId}-label`);
      const vgMarkerCircle = document.getElementById(`${regionId}-circle`);
      if (regionLabel) regionLabel.id = `${regionId}-label-${id}`;
      if (region && vgMarkerCircle) {
        region.id = `${regionId}-coverage-${id}`;
        vgMarkerCircle.id = `${regionId}-circle-${id}`;
      }
    });
    updateRegions();
  }, []);

  useEffect(() => {
    updateRegions();
  }, [activeRegions, coveredRegions]);

  const updateRegions = () => {
    _.each(Object.entries(MAP_REGIONS), ([regionName, regionId]) => {
      const element = document.getElementById(`${regionId}-g-${id}`);
      if (element) {
        if (coveredRegions.includes(regionName)) {
          element.onclick = () => {
            handleRegionClick(regionName);
          };
          element.classList.remove("uncovered");
        } else {
          element.classList.add("uncovered");
          element.onclick = null;
        }
      }

      const vgMarker = document.getElementById(`${regionId}-${id}`);
      const vgMarkerCircle = document.getElementById(`${regionId}-circle-${id}`);
      const regionLabel = document.getElementById(`${regionId}-label-${id}`);
      if (regionLabel) regionLabel.classList.add("region-label");
      if (vgMarker && vgMarkerCircle) {
        if (coveredRegions?.includes(regionName)) {
          vgMarker.classList.add("covered-region");
          vgMarker.classList.remove("uncovered-region");
          vgMarkerCircle.classList.remove("inactive-region");
          vgMarkerCircle.classList.add("covered-region");
        } else {
          vgMarker.classList.remove("covered-region");
          vgMarker.classList.add("uncovered-region");
          vgMarkerCircle.classList.remove("covered-region");
          vgMarkerCircle.classList.add("inactive-region");
        }
        if (passiveCoverage) {
          vgMarkerCircle.classList.remove("active-region");
          vgMarkerCircle.classList.add("inactive-region");
        }
      }

      const region = document.getElementById(`${regionId}-coverage-${id}`);
      if (vgMarkerCircle && region) {
        if (activeRegions?.includes(regionName)) {
          vgMarkerCircle.classList.add("active-region");
          vgMarkerCircle.classList.remove("inactive-region");
          region.classList.remove("region-pointer");
        } else {
          vgMarkerCircle.classList.remove("active-region");
          region.classList.add("region-pointer");
        }
      }
    });
  };

  const handleRegionClick = (clickedRegion) => {
    if (onRegionClick) {
      onRegionClick(clickedRegion);
    }
  };

  return (
    <div className="world-map">
      <WorldMapSVG />
    </div>
  );
};

export default WorldMapCoverage;
