import React, { useState } from "react";
import { isMobile } from "Utils/Helpers/browser.helpers";
import useHandleFirstClick from "Utils/Hooks/useHandleFirstClick";

const FirstClickHandlerWrapper = ({ enabled, onFirstClick }) => {
  const [showBackdrop, setShowBackdrop] = useState(true);

  useHandleFirstClick(!isMobile && enabled, onFirstClick);

  const handleClick = () => {
    onFirstClick();
    setShowBackdrop(false);
  };

  if (!isMobile || !enabled || !showBackdrop) {
    return null;
  }

  return <div style={backdropStyle} onClick={handleClick} />;
};

const backdropStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.0)",
  zIndex: 9999,
};

export default FirstClickHandlerWrapper;
