export const ACTIVE_REGIONS = [
  "Dublin",
  "Frankfurt",
  "North Virginia",
  "Oregon",
  "Northern California",
  "Mumbai",
  "Singapore",
  "Sydney",
  "Tokyo",
  "Bahrain",
  "Hong Kong",
  "Sao Paulo",
  "Seoul",
  "Ohio",
  "Cape Town",
];

export const REGION_LINKS = {
  dublin: "https://dynamodb.eu-west-1.amazonaws.com",
  frankfurt: "https://dynamodb.eu-central-1.amazonaws.com",
  north_virginia: "https://dynamodb.us-east-1.amazonaws.com",
  oregon: "https://dynamodb.us-west-2.amazonaws.com",
  northern_california: "https://dynamodb.us-west-1.amazonaws.com",
  mumbai: "https://dynamodb.ap-south-1.amazonaws.com",
  singapore: "https://dynamodb.ap-southeast-1.amazonaws.com",
  sydney: "https://dynamodb.ap-southeast-2.amazonaws.com",
  tokyo: "https://dynamodb.ap-northeast-1.amazonaws.com",
  bahrain: "https://dynamodb.me-south-1.amazonaws.com",
  hong_kong: "https://dynamodb.ap-east-1.amazonaws.com",
  sao_paulo: "https://dynamodb.sa-east-1.amazonaws.com",
  seoul: "https://dynamodb.ap-northeast-2.amazonaws.com",
  ohio: "https://dynamodb.us-east-2.amazonaws.com",
  montreal: "https://dynamodb.ca-central-1.amazonaws.com",
  stockholm: "https://dynamodb.eu-north-1.amazonaws.com",
  cape_town: "https://dynamodb.af-south-1.amazonaws.com",
  jakarta: "https://dynamodb.ap-southeast-3.amazonaws.com/ping",
  uae: "https://dynamodb.me-central-1.amazonaws.com/ping",
};

export const GET_REGIONS_PENDING = "GET_REGIONS_PENDING";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";
export const GET_REGIONS_FAILURE = "GET_REGIONS_FAILURE";

export const GET_LOCATION_PENDING = "GET_LOCATION_PENDING";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAILURE = "GET_LOCATION_FAILURE";
