import React from "react";

import Icon from "../Icon/Icon.component";
import "./TextButton.style.scss";

const TextButton = (props) => {
  const { text, icon, iconPosition, color, disabled, selected, className: classNameFromProps, ...rest } = props;

  const className = [
    "vg-text-button",
    classNameFromProps,
    color,
    selected && "selected",
    disabled && "disabled",
    iconPosition === "left" && "icon-position-left",
  ]
    .filter(Boolean) // Remove falsy values from classname list to avoid redundant spaces.
    .join(" ");

  return (
    <button className={className} type="button" disabled={disabled} {...rest}>
      <div className="text-button">{text}</div>
      {icon && <Icon name={icon} color={color} />}
    </button>
  );
};

export default TextButton;
