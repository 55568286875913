import React from "react";
import { Modal, Table } from "UI";
import UserCell from "../../Components/UserCell/UserCell.component";
import "./AssignedSeatsModal.styles.scss";

const AssignedSeatsModal = ({ seats, onClose }) => {
  const columns = [
    { name: "Id", weight: 0 },
    { name: "User", weight: 40 },
  ];

  const data = seats?.map((seat) => {
    return {
      id: seat.id,
      user: <UserCell removeCheckbox seat={seat} user={seat.attributes.user} />,
    };
  });

  return (
    <Modal
      className="assigned-seats-modal"
      closeOnOverlayClick
      closeAction={onClose}
      topRightIcon="close"
      topRightIconAction={onClose}
      small
      centered
      headerText="Assigned Users"
      descriptionText={
        <>
          <div className="scrollable-table-container">
            <Table columns={columns} data={data} />
          </div>
        </>
      }
    />
  );
};

export default AssignedSeatsModal;
