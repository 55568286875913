import { FILE_STATUS } from "Constants/global.constants";
import React, { useState } from "react";

import { Modal, LinkButton } from "UI";

import FileStatus from "./FileStatus/FileStatus.component";

const FileDownloadModal = (props) => {
  const { fileName, fileSize, downloadUrl, setShowDownloadModal, mobile, translate } = props;
  const [linkButtonDisabled, setLinkButtonDisabled] = useState(false);

  const downloadButton = () => (
    <LinkButton
      className="vg-button-fake"
      href={downloadUrl}
      download={fileName}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        setLinkButtonDisabled(true);
        setTimeout(() => {
          setShowDownloadModal(false);
        }, 2000);
      }}
      text={translate("modals.fileDownload.downloadText")}
      big
      blue
    />
  );

  const onCancel = () => {
    setShowDownloadModal(false);
  };

  return (
    <Modal
      closeAction={onCancel}
      topRightIcon="close"
      topRightIconAction={onCancel}
      headerText={translate("modals.fileDownload.header")}
      descriptionText={translate("modals.fileDownload.description")}
      buttonText={
        linkButtonDisabled
          ? translate("modals.fileDownload.downloadStarted")
          : translate("modals.fileDownload.buttonText")
      }
      buttonAction={onCancel}
      buttonDisabled={!downloadUrl || linkButtonDisabled}
      overrideButton={!linkButtonDisabled && downloadUrl && downloadButton()}
      fitContent
      blue
      mobile={mobile}
    >
      <FileStatus
        fileName={fileName}
        fileSize={fileSize}
        fileStatus={!downloadUrl && FILE_STATUS.PENDING}
        fileNameLimit={mobile ? 15 : undefined}
      />
    </Modal>
  );
};

export default FileDownloadModal;
