import React from "react";
import { Icon, Modal, TextButton } from "UI";
import { classnames } from "Utils";

import "./ModalResult.styles.scss";

const ModalResult = ({ success, successHeader, successDescription, failureHeader, failureDescription, onClose }) => {
  return (
    <Modal topRightIcon="close" topRightIconAction={onClose}>
      <div className={classnames(["modal-result", !success && "error"])}>
        <Icon name={success ? "organizations-check" : "organizations-cross"} />
        <h1>{success ? successHeader : failureHeader}</h1>
        <p>{success ? successDescription : failureDescription}</p>
        <div className="buttons-container single-button">
          <TextButton className="action-buttons" text="Done" color="aqua-light" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalResult;
