import React from "react";
import { Translate } from "react-localize-redux";
import { useMutation } from "@tanstack/react-query";
import AddHomeScreenTipImage from "Assets/images/homeScreenTip.png";
import { API_ENDPOINTS } from "Constants/api.constants";
import { REQUEST_STATUS, WORKSTATION_STATUS, WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";
import { apiGenerator, isMobile, isStandaloneMode } from "Utils";
import { Button, Icon, Tooltip, BoardingModal } from "UI";

const RESET_ERROR_TIMEOUT = 6000;

const ComputerActionButton = (props) => {
  const { status, friendlyStatus, machine, refetchMachine, translate, onConnect, isLoading: isRequestsLoading } = props;
  const { region } = machine?.attributes || {};

  const [isLoading, setIsLoading] = React.useState(false);

  const [shouldDisplayShutDownAction, setShouldDisplayShutDownAction] = React.useState(
    status === WORKSTATION_STATUS.RUNNING,
  );
  const [showShortcutModal, setShowShortcutModal] = React.useState(false);

  const startComputerMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_START(machine.id));
    },
    onSuccess: () => {
      refetchMachine();
    },
  });

  const stopComputerMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_STOP(machine.id));
    },
    onSuccess: () => {
      refetchMachine();
    },
  });

  const hasError = startComputerMutation.isError || stopComputerMutation.isError;

  React.useEffect(() => {
    let resetErrorTimeout;
    if (REQUEST_STATUS.PENDING) {
      setIsLoading(true);

      if (WORKSTATION_FRIENDLY_STATUS.READY === friendlyStatus) {
        setShouldDisplayShutDownAction(false);
      }
    } else if (REQUEST_STATUS.FAILURE) {
      resetErrorTimeout = setTimeout(() => {
        startComputerMutation.reset();
      }, RESET_ERROR_TIMEOUT);
      setIsLoading(false);
    }

    return () => {
      clearTimeout(resetErrorTimeout);
    };
  }, []);

  React.useEffect(() => {
    if (
      [
        WORKSTATION_FRIENDLY_STATUS.CREATING,
        WORKSTATION_FRIENDLY_STATUS.TURNING_ON,
        WORKSTATION_FRIENDLY_STATUS.TURNING_OFF,
        WORKSTATION_FRIENDLY_STATUS.RESIZING_DISK,
        WORKSTATION_FRIENDLY_STATUS.INSTALLING,
        WORKSTATION_FRIENDLY_STATUS.REGION_MIGRATION,
        WORKSTATION_FRIENDLY_STATUS.WARMING_UP,
        WORKSTATION_FRIENDLY_STATUS.WAITING_FOR_GPUS,
        WORKSTATION_FRIENDLY_STATUS.MODIFYING_NETWORK,
      ].includes(friendlyStatus) ||
      isRequestsLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [friendlyStatus, isRequestsLoading]);

  React.useEffect(() => {
    if (
      [
        WORKSTATION_FRIENDLY_STATUS.TURNING_OFF,
        WORKSTATION_FRIENDLY_STATUS.RESIZING_DISK,
        WORKSTATION_FRIENDLY_STATUS.INSTALLING,
        WORKSTATION_FRIENDLY_STATUS.REGION_MIGRATION,
      ].includes(friendlyStatus)
    ) {
      setShouldDisplayShutDownAction(false);
    } else if (status === WORKSTATION_STATUS.RUNNING) {
      setShouldDisplayShutDownAction(true);
    } else if (status === WORKSTATION_STATUS.STOPPING) {
      setShouldDisplayShutDownAction(false);
    }
  }, [friendlyStatus, status]);

  const determineButtonText = () => {
    if (!friendlyStatus) return "Computer Required";

    if (!region) return "Assigning Region";

    if (isLoading && friendlyStatus === WORKSTATION_FRIENDLY_STATUS.OFF) {
      return translate(`home.actionButton.buttonText.${WORKSTATION_FRIENDLY_STATUS.TURNING_ON}`);
    }

    if (isLoading && friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY) {
      return translate(`home.actionButton.buttonText.${WORKSTATION_FRIENDLY_STATUS.TURNING_OFF}`);
    }

    return translate(`home.actionButton.buttonText.${friendlyStatus}`);
  };

  const determineButtonIcon = () => {
    if (isLoading) {
      return "loader";
    } else if (friendlyStatus === WORKSTATION_FRIENDLY_STATUS.OFF) {
      return "play";
    }

    return null;
  };

  const determineButtonHoverText = () => {
    if (hasError) {
      const clientCode = startComputerMutation.error?.response?.data?.client_code;

      return (
        <Translate
          id={`home.actionButton.error.${clientCode}`}
          options={{ onMissingTranslation: () => translate("home.actionButton.error.default") }}
        />
      );
    }

    return null;
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (status === "stopped" && WORKSTATION_FRIENDLY_STATUS.OFF === friendlyStatus) {
      if (!isMobile) {
        startComputerMutation.mutate();
      }
    } else if (WORKSTATION_FRIENDLY_STATUS.READY === friendlyStatus) {
      if (isMobile && !isStandaloneMode) {
        setShowShortcutModal(true);
      } else {
        onConnect();
      }
    }
  };

  const shutDown = () => {
    stopComputerMutation.mutate();
  };

  return (
    <>
      <div className="computer-action-button-container breath">
        <div className="computer-action-button-text">
          {shouldDisplayShutDownAction && (
            <div role="button" className="shut-down-action" onClick={shutDown} onKeyDown={shutDown} tabIndex="-1">
              {translate("home.actionButton.secondaryText.shutDown")}
            </div>
          )}
          {friendlyStatus === WORKSTATION_FRIENDLY_STATUS.TURNING_ON && !shouldDisplayShutDownAction && (
            <div className="description-secondary">{translate("home.actionButton.secondaryText.pending")}</div>
          )}
          {friendlyStatus === WORKSTATION_FRIENDLY_STATUS.INSTALLING && (
            <div className="description-secondary">{translate("home.actionButton.secondaryText.installing")}</div>
          )}

          {hasError && (
            <div className="vg-tooltip-hoverable error-container">
              <Icon name="error" color="white" />
              <p>{translate("home.actionButton.buttonText.error")}</p>
              <Tooltip position="left" content={determineButtonHoverText()} showTip />
            </div>
          )}
        </div>
        <Button
          text={determineButtonText()}
          icon={determineButtonIcon()}
          onClick={handleClick}
          disabled={
            isLoading ||
            startComputerMutation.isLoading ||
            stopComputerMutation.isLoading ||
            !region ||
            friendlyStatus === WORKSTATION_FRIENDLY_STATUS.PAYMENT_REQUIRED
          }
          breath={isLoading && friendlyStatus !== WORKSTATION_FRIENDLY_STATUS.TURNING_OFF}
          tabIndex="0"
        />
      </div>
      {showShortcutModal && (
        <BoardingModal
          headerImages={[AddHomeScreenTipImage]}
          headerTexts={[translate("home.shortcutModal.header")]}
          buttonTexts={[translate("home.shortcutModal.buttonText")]}
          descriptions={[translate("home.shortcutModal.description")]}
          buttonAction={() => {
            setShowShortcutModal(false);
          }}
        />
      )}
    </>
  );
};

export default ComputerActionButton;
