import { PAYMENT_TYPE } from "Constants/global.constants";
import React from "react";
import { IconButton } from "UI";
import DefaultProvider from "Assets/images/cardServices/creditcard.svg";
import MasterCard from "Assets/images/cardServices/mastercard.svg";
import Amex from "Assets/images/cardServices/amex.svg";
import Discover from "Assets/images/cardServices/discover.svg";
import Paypal from "Assets/images/cardServices/paypal.svg";
import Visa from "Assets/images/cardServices/visa.svg";

import "./CreditCard.styles.scss";

const CreditCard = (props) => {
  const {
    type,
    provider,
    deletable,
    deleteAction,
    changeAction,
    preferred,
    preferable,
    setPreferredAction,
    cardOwner,
    cardPlaceholder,
    cardNumber,
    paymentType,
    setPaymentType,
  } = props;

  /* eslint-disable global-require */

  const retrieveCreditCardProviderLogo = (provider) => {
    switch (provider) {
      //   return require("Assets/images/cardServices/amex.svg");
      case "amex": {
        return Amex;
      }
      case "mastercard": {
        return MasterCard;
      }
      case "discover": {
        return Discover;
      }
      case "paypal": {
        return Paypal;
      }
      case "visa": {
        return Visa;
      }
      default: {
        return DefaultProvider;
      }
    }
  };

  const onCardClick = () => {
    if (setPaymentType) {
      setPaymentType(PAYMENT_TYPE.CREDIT_CARD);
    }

    if (preferable && setPreferredAction) {
      setPreferredAction();
    }
  };

  const creditCardClassName = [
    "credit-card",
    type === "big" && "big",
    !preferred && type !== "big" && "opaque",
    paymentType === PAYMENT_TYPE.BALANCE && "opaque",
    preferable && "preferable",

    paymentType === PAYMENT_TYPE.CREDIT_CARD && "selected",
  ]
    .filter((e) => e)
    .join(" ");

  return (
    /* eslint-disable */
    <div className={creditCardClassName} onClick={onCardClick}>
      {deletable && <IconButton name="trash" onClick={deleteAction} className="deletable" color="white-fill" />}
      {changeAction && (
        <div className="changable" onClick={changeAction}>
          Change
        </div>
      )}
      <div className="credit-card-wrapper">
        <img src={retrieveCreditCardProviderLogo(provider)} alt="Payment Method" className="provider-icon" />
        {preferred && <div className="preferred">Preferred</div>}
        {type === "big" ? (
          <div className="card-owner-name">{cardOwner}</div>
        ) : (
          <div className="credit-card-placeholder">{cardPlaceholder}</div>
        )}
        <div className="credit-card-number">
          <div className="credit-card-number-square" />
          <div className="credit-card-number-square" />
          <div className="credit-card-number-square" />
          <div className="credit-card-number-square" />
          <div className="credit-card-digits">{cardNumber}</div>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
