import React from "react";

import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { bytesToSize } from "Utils/Helpers/functions.helpers";
import { HoverableTooltip, Icon } from "UI";
import { isMobile } from "Utils/Helpers/browser.helpers";
import "./NetworkUsageLimit.styles.scss";

const NetworkUsageLimit = (props) => {
  const { remainingNetworkUsage, networkUsage, totalNetworkUsage, personal, translate, infoTooltipText } = props;

  return (
    <>
      <div className="network-usage-container">
        <div className="circular-progress-bar">
          <CircularProgressbarWithChildren
            value={(remainingNetworkUsage / networkUsage) * 100}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#73d4c7",
            })}
          >
            <div className="circular-progress-bar-text">
              <h1>{bytesToSize(remainingNetworkUsage)}</h1>
              <p>{translate("billing.networkUsage.circularProgressBar.description")}</p>
            </div>
          </CircularProgressbarWithChildren>
          <div className="circular-progress-bar-alt-info">
            {Boolean(totalNetworkUsage) && <p className="total-usage">Total Usage: {bytesToSize(totalNetworkUsage)}</p>}
            {totalNetworkUsage === 0 && <p className="total-usage">Total Usage: 0 GB</p>}
            {infoTooltipText && (
              <HoverableTooltip side={isMobile ? "bottom" : "right"} content={infoTooltipText}>
                <Icon smaller name="info" color="gray-3" />
              </HoverableTooltip>
            )}
          </div>
        </div>
        <div className="vertical-divider" />
        <div className="network-usage-details">
          <div className="network-usage-description">
            <h2>{translate("billing.networkUsage.personal.header")}</h2>

            <p>
              {personal
                ? translate("billing.networkUsage.personal.description")
                : translate("billing.networkUsage.teams.description")}
            </p>
          </div>
          <div className="progress-bar-info">
            <div className="progress-bar-info-rectangle" />
            <p>{translate("billing.networkUsage.progressBar.info")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NetworkUsageLimit;
