import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getMachineUsageHistoryAPI } from "Actions/Account.actions";
import UsageComponent from "Components/Dashboard/Account/Usage/Usage.component";

class TeamsUsageContainer extends React.Component {
  componentDidMount() {
    const { account, getMachineUsageHistoryAPI } = this.props;
    getMachineUsageHistoryAPI(account.accessToken);
  }

  render() {
    return <UsageComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMachineUsageHistoryAPI,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeamsUsageContainer);
