import React, { useState, useEffect } from "react";
import moment from "moment";

import { REQUEST_STATUS, WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";

const calculateTimePassed = (createdAt) => {
  if (createdAt) {
    return moment(moment().diff(moment(new Date(createdAt)).local()))
      .utc()
      .format("HH:mm");
  }

  return null;
};

const ComputerTimer = (props) => {
  const {
    getWorkstationCurrentSessionAPI,
    getWorkstationCurrentSessionCTX,
    updateWorkstationStatusCTX,
    workstation,
  } = props;
  const { friendly_status: friendlyStatus, status } = workstation.attributes.machine.attributes;
  const createdAt =
    getWorkstationCurrentSessionCTX.status === REQUEST_STATUS.SUCCESS &&
    getWorkstationCurrentSessionCTX.data &&
    getWorkstationCurrentSessionCTX.data.attributes.created_at;
  const [timePassed, setTimePassed] = useState(calculateTimePassed(createdAt));
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (status === "running") {
      getWorkstationCurrentSessionAPI();
    }
  }, [status]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (status === "running" && createdAt) {
        setTimePassed(calculateTimePassed(createdAt));
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [createdAt, status]);

  useEffect(() => {
    if (friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY) {
      setShouldRender(true);
      setTimePassed(calculateTimePassed(createdAt));
    } else {
      setShouldRender(false);
    }
  }, [friendlyStatus]); // eslint-disable-line

  useEffect(() => {
    if (
      (updateWorkstationStatusCTX.status === REQUEST_STATUS.PENDING ||
        updateWorkstationStatusCTX.status === REQUEST_STATUS.SUCCESS) &&
      friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY
    ) {
      setShouldRender(false);
    }
  }, [updateWorkstationStatusCTX.status, friendlyStatus]);

  return shouldRender && timePassed && <div className="timer-text">{timePassed}</div>;
};

export default ComputerTimer;
