import React from "react";
import { DashboardLayout, FileWatcher } from "Utils";
import UserNotVerifiedModal from "Utils/Components/Authentication/UserNotVerifiedModal";
import { Route } from "react-router-dom";
import { Translate } from "react-localize-redux";

function ProtectedRouteWithTranslation(props) {
  const { path, dashboardRoute, isUserVerified, component: Component, history, logoutAPI } = props;

  const route = (
    <Translate>
      {({ translate }) => (
        <>
          <Route
            exact
            path={path}
            render={(props) => {
              return <Component {...props} translate={translate} />;
            }}
          />
          <FileWatcher translate={translate} />
        </>
      )}
    </Translate>
  );

  const component = (
    <>
      {route}
      {!isUserVerified && (
        <Translate>{({ translate }) => <UserNotVerifiedModal translate={translate} logoutAPI={logoutAPI} />}</Translate>
      )}
    </>
  );

  if (dashboardRoute) {
    return (
      <DashboardLayout path={path} history={history}>
        {component}
      </DashboardLayout>
    );
  }
  return component;
}

export default ProtectedRouteWithTranslation;
