import React, { useMemo } from "react";
import organizationComputerImage from "Assets/images/organizations/computer.png";
import organizationUserImage from "Assets/images/organizations/user.png";
import { classnames, isMobile } from "Utils";
import "./UserCell.styles.scss";

const UserCell = ({
  user,
  isOwner,
  seat,
  hideCheckbox,
  removeCheckbox,
  onCheckBoxChange,
  checked,
  disableCheckBox,
  disableFade,
}) => {
  const { name, email, profile_picture_url: profilePictureUrl } = user?.attributes || user || {};

  const extractInitials = (name) => {
    const names = name?.split(" ");
    if (!name) return "U";
    if (names[0]?.[0] && names[1]?.[0]) return (names[0][0] + names[1][0]).toUpperCase();
    return names[0][0].toUpperCase();
  };

  const decideUserProfileImage = useMemo(() => {
    return profilePictureUrl ? (
      <img src={profilePictureUrl} alt="user" />
    ) : (
      <div className="profile-image">{extractInitials(name)}</div>
    );
  }, [profilePictureUrl]);

  return (
    <div className="user-cell">
      {!removeCheckbox && (
        <input
          className="checkbox"
          style={{ opacity: hideCheckbox && "0", display: isMobile && "none" }}
          type="checkbox"
          checked={checked}
          onChange={onCheckBoxChange}
          disabled={disableCheckBox}
        />
      )}
      {name || profilePictureUrl ? (
        decideUserProfileImage
      ) : (
        <img src={user ? organizationUserImage : organizationComputerImage} alt="user" />
      )}
      <div className="user-info">
        <div className={classnames(["user-name", !user && !disableFade && "fade"])}>
          {name}
          {isOwner && <span>Owner</span>}
        </div>
        <div className={classnames(["user-email", !user && !disableFade && "fade"])}>
          {email || seat?.attributes?.name}
        </div>
      </div>
    </div>
  );
};

export default UserCell;
