import React from "react";
import { withRouter } from "react-router-dom";
import routes from "Constants/Route.constants";

const APP_STREAMING_URL = "stream";
class IntercomRouteWrapper extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    this.checkRouteAndUpdateIntercom(prevProps, location);
  }

  checkRouteAndUpdateIntercom = (prevProps, location) => {
    const wasWorkstationRoute =
      prevProps.location.pathname === routes.workstation || prevProps.location.pathname.includes(APP_STREAMING_URL);
    const isWorkstationRoute =
      location.pathname === routes.workstation || location.pathname.includes(APP_STREAMING_URL);
    if (wasWorkstationRoute && !isWorkstationRoute && window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: false,
      });
    }
    if (!wasWorkstationRoute && isWorkstationRoute && window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  };

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(IntercomRouteWrapper);
