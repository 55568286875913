import React, { useEffect } from "react";
import "./Tooltip.style.scss";

const HIDE_TIP_TIMEOUT = 4000;

const Tooltip = (props) => {
  const { content, position, refPos, showTip } = props;

  const className = ["vg-tooltip", position, showTip && "visible", refPos && "reference-position"]
    .filter(Boolean)
    .join(" ");

  return (
    <>
      {showTip && (
        <div className={className} style={refPos}>
          {content}
        </div>
      )}
    </>
  );
};

export default Tooltip;
