import { useState, useEffect } from "react";
import { STATUS_TYPE } from "Constants/api.constants";
import apiGenerator from "Utils/Helpers/api.helpers";
import { getStatusCodeFamily } from "Utils/Helpers/saga.helpers";

const useImageUpload = ({ uploadEndpoint, completeUploadEndpoint, image, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const uploadImage = async () => {
    setLoading(true);
    setError(false);

    try {
      const createImageAPI = await apiGenerator("post")(uploadEndpoint, {
        file_name: image.name,
      });

      const contentType = image.type || "application/octet-stream";
      const response = createImageAPI;

      const { key_name: keyName, upload_urls: uploadUrls } = response.data;

      const uploadConfig = {
        headers: {
          "content-type": contentType,
        },
      };

      await apiGenerator("put")(uploadUrls[0], image, uploadConfig);

      const uploadCompletedAPI = await apiGenerator("put")(completeUploadEndpoint, {
        key_name: keyName,
      });

      const uploadCompletedResponse = uploadCompletedAPI;
      const isUploadSuccessFull = getStatusCodeFamily(uploadCompletedResponse.status) === STATUS_TYPE.SUCCESS;

      if (isUploadSuccessFull) {
        onSuccess();
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (image) {
      uploadImage();
    }
  }, [image]);

  return {
    loading,
    error,
  };
};

export default useImageUpload;
