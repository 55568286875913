import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { DashboardSubScreenLayout, FeedbackModal, Animation, apiGenerator } from "Utils";
import {
  FEEDBACK_TYPES,
  REQUEST_STATUS,
  WORKSTATION_FRIENDLY_STATUS,
  WORKSTATION_STATUS,
} from "Constants/global.constants";
import { Divider, ConfirmationModal, TextButton, Button, Modal, Icon, HoverableTooltip } from "UI";
import routes from "Constants/Route.constants";
import VagonGift from "Assets/images/referral/vagon-gift.png";
import ProfilePicture from "UI/ProfilePicture/ProfilePicture.component";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useMutation } from "@tanstack/react-query";
import { useTimezoneSelect } from "react-timezone-select";
import useMatchTimeZone from "Utils/Hooks/useMatchTimeZone";
import TimezoneModal from "Components/Dashboard/Files/Components/TimezoneModal/TimezoneModal.component";
import ChangePasswordForm from "./ChangePasswordForm.component";
import ResetComputerModal from "./Components/ResetComputerModal/ResetComputerModal.component";

import "./Account.styles.scss";

const AccountComponent = (props) => {
  const {
    account,
    referral,
    subscriptions,
    changePasswordAPI,
    renewSubscriptionAPI,
    cancelSubscriptionAPI,
    getAccountAPI,
    logoutAPI,
    history,
    workstations,
    getWorkstationsAPI,
    translate,
  } = props;

  const { accessToken, getAccountCTX, changePasswordCTX } = account;
  const { subscription } = subscriptions;

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showDeleteComputerConfirmationModal, setShowDeleteComputerConfirmationModal] = useState(false);
  const [showDeleteAccountConfirmationModal, setShowDeleteAccountConfirmationModal] = useState(false);
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const [showResetComputerConfirmationModal, setShowResetComputerConfirmationModal] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [showPasswordChangeSuccessModal, setShowPasswordChangeSuccessModal] = useState(false);
  const [showMachineResetSuccessModal, setShowMachineResetSuccessModal] = useState(false);
  const [showSetPasswordConfirmationModal, setShowSetPasswordConfirmationModal] = useState(false);
  const [showUnlinkGoogleConfirmationModal, setShowUnlinkGoogleConfirmationModal] = useState(false);
  const computerStatus = workstations.workstations[0]?.attributes?.machine?.attributes?.status;
  const friendlyStatus = workstations.workstations[0]?.attributes?.machine?.attributes?.friendly_status;
  const ebsStatus = workstations.workstations[0]?.attributes?.machine?.attributes?.disk_status;
  const machineImage = workstations.workstations[0]?.attributes?.machine_image;

  useEffect(() => {
    if (getAccountCTX.status === REQUEST_STATUS.FAILURE) {
      logoutAPI(accessToken);
    }
  }, [getAccountCTX.status]);

  const {
    name,
    email,
    referral_code: referralCode,
    password_set: passwordSet,
    provider,
    time_zone: userTimeZone,
  } = account?.account?.attributes || {};
  const { referreReward, referrerReward } = referral.getRewardsCTX.data;
  const referralLink = `vagon.io/referral?invite=${referralCode}`;
  const timezoneLabel = useMatchTimeZone(userTimeZone);

  const unlinkGoogleMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("put")(API_ENDPOINTS.ACCOUNT_UNLINK_GOOGLE);
    },
    onSuccess: () => {
      getAccountAPI();
    },
  });

  const handleSetPassword = () => {
    logoutAPI(accessToken);
    history.push(routes.forgetPassword);
  };

  const handleUnlinkGoogle = () => {
    unlinkGoogleMutation.mutate();
  };

  const { renew } = subscription?.attributes || {};

  const renewButtonText = renew
    ? translate("accountSettings.deleteComputer.action")
    : translate("accountSettings.deleteComputer.renewAction");

  const renewalSubscriptionText = renew
    ? translate("accountSettings.deleteComputer.description", {
        date: moment(subscription?.attributes?.end_at).format("DD MMMM YYYY"),
      })
    : translate("accountSettings.deleteComputer.renewDescription", {
        date: moment(subscription?.attributes?.end_at).format("DD MMMM YYYY"),
      });

  const renewButtonAction = () => {
    if (renew) {
      cancelSubscriptionAPI(accessToken);
    } else {
      renewSubscriptionAPI(account.accessToken);
    }
  };

  const resetComputerDisabled =
    computerStatus !== WORKSTATION_STATUS.STOPPED ||
    ebsStatus === "hot" ||
    !machineImage ||
    friendlyStatus === WORKSTATION_FRIENDLY_STATUS.REGION_MIGRATION;

  return (
    <>
      <DashboardSubScreenLayout
        headerTitle={translate("accountSettings.header.title")}
        headerDescription={translate("accountSettings.header.description")}
        pending={
          _.findIndex(
            [account.getAccountCTX.status, referral.getRewardsCTX.status],
            (status) => status === REQUEST_STATUS.PENDING,
          ) !== -1
        }
        translate={translate}
      >
        <div className="vg-account-container">
          <div className="account-information-container">
            {!account.isTeamAccount && (
              <>
                <div className="header">{translate("accountSettings.referral.header")}</div>
                <div className="description">
                  {translate("accountSettings.referral.description", { referreReward, referrerReward })}
                </div>
                <div className="referral-box">
                  <div className="referral-box-section">
                    <img src={VagonGift} />
                    <div className="referral-link-container">
                      <h2>{translate("accountSettings.referral.link.header")}</h2>
                      <div className="referral-link">
                        <p>{referralLink}</p>
                        <TextButton
                          text={translate("accountSettings.referral.link.copyButton")}
                          color="purple"
                          onClick={() => {
                            navigator.clipboard.writeText(`https://${referralLink}`);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="referral-box-section">
                    <div className="divider" />
                    <Button
                      text={translate("accountSettings.referral.link.shareButton")}
                      onClick={() => {
                        history.push({ pathname: routes.referral, hash: "#share" });
                      }}
                      textContained
                    />
                    <TextButton
                      text={translate("accountSettings.referral.link.claimButton")}
                      onClick={() => {
                        history.push(routes.referral);
                      }}
                    />
                  </div>
                </div>
                <Divider />
              </>
            )}
            <div className="header">{translate("accountSettings.information.header")}</div>
            {showChangePasswordForm ? (
              <div className="change-password-container">
                <ProfilePicture account={account} translate={translate} />
                <ChangePasswordForm
                  accessToken={accessToken}
                  changePasswordCTX={changePasswordCTX}
                  changePasswordAPI={changePasswordAPI}
                  logoutAPI={logoutAPI}
                  translate={translate}
                  setShowPasswordChangeSuccess={setShowPasswordChangeSuccessModal}
                  setShowChangePasswordForm={setShowChangePasswordForm}
                  passwordSet={passwordSet}
                />
              </div>
            ) : (
              <>
                <div className="profile-information-container">
                  <div className="profile-container">
                    <ProfilePicture account={account} translate={translate} />
                    <div className="information-item">
                      <div className="user-info">
                        {name && <div className="label">{translate("accountSettings.information.name")}</div>}
                        <p>{name}</p>
                        <div className="label">{translate("accountSettings.information.email")}</div>
                        <p>{email}</p>
                      </div>
                      <TextButton
                        className="action"
                        onClick={() => {
                          return passwordSet
                            ? setShowChangePasswordForm(true)
                            : setShowSetPasswordConfirmationModal(true);
                        }}
                        text={
                          passwordSet
                            ? translate("accountSettings.information.action.changePassword")
                            : translate("accountSettings.information.action.setPassword")
                        }
                      />
                    </div>
                  </div>
                  <div className="additional-information-container">
                    {provider === "google" && (
                      <>
                        <div className="label">Google Authentication</div>
                        <div className="google-information">
                          <Icon name="google" small />
                          <div>{translate("accountSettings.information.google")}</div>
                          {passwordSet && (
                            <TextButton
                              text="Unlink"
                              color="purple"
                              onClick={() => {
                                setShowUnlinkGoogleConfirmationModal(true);
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                    <div className="label">{translate("organizationSettings.information.team.timezoneLabel")}</div>
                    <div className="time-zone-value-container">
                      <p className="info-value">{timezoneLabel}</p>
                      <TextButton text="Update" color="purple" onClick={() => setShowTimezoneModal(true)} />
                    </div>
                  </div>
                </div>
              </>
            )}
            {!account.isTeamAccount && (
              <>
                <Divider />
                {subscription && (
                  <div className="delete-actions-container">
                    <div className="delete-computer">
                      <div className="header">{translate("accountSettings.deleteComputer.title")}</div>

                      <div className="description">{renewalSubscriptionText}</div>

                      <div className="reset-computer-text-buttons">
                        <TextButton className="action-reset" onClick={renewButtonAction} text={renewButtonText} />

                        <div className="reset-button-container">
                          <TextButton
                            className="action-reset"
                            onClick={() => setShowResetComputerConfirmationModal(true)}
                            text={translate("accountSettings.resetComputer.buttonText")}
                            disabled={resetComputerDisabled}
                          />
                          {resetComputerDisabled && (
                            <HoverableTooltip
                              side="right"
                              content={translate("accountSettings.resetComputer.disabled")}
                            >
                              <Icon name="info" smaller color="gray-3" />
                            </HoverableTooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="delete-account">
                      <div className="header">{translate("accountSettings.deleteAccount.title")}</div>
                      <div className="description">{translate("accountSettings.deleteAccount.description")}</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </DashboardSubScreenLayout>
      {showFeedbackModal && (
        <FeedbackModal
          setShowFeedbackModal={setShowFeedbackModal}
          type={FEEDBACK_TYPES.endSubscription}
          translate={translate}
        />
      )}
      {showDeleteComputerConfirmationModal && (
        <ConfirmationModal
          headerText={translate("accountSettings.deleteComputer.confirm.header")}
          descriptionText={translate("accountSettings.deleteComputer.confirm.description")}
          confirmText={translate("accountSettings.deleteComputer.confirm.confirm")}
          confirmAction={() => {
            setShowFeedbackModal(true);
            cancelSubscriptionAPI(accessToken);
            setShowDeleteComputerConfirmationModal(false);
          }}
          secondaryText={translate("accountSettings.deleteComputer.confirm.secondary")}
          secondaryAction={() => setShowDeleteComputerConfirmationModal(false)}
          closeAction={() => setShowDeleteComputerConfirmationModal(false)}
          closeOnOverlayClick
        />
      )}
      {showDeleteAccountConfirmationModal && (
        <ConfirmationModal
          headerText={translate("accountSettings.deleteAccount.confirm.header")}
          descriptionText={translate("accountSettings.deleteAccount.confirm.description")}
          confirmText={translate("accountSettings.deleteAccount.confirm.confirm")}
          confirmAction={() => {
            setShowDeleteAccountConfirmationModal(false);
            setShowFeedbackModal(true);
          }}
          secondaryText={translate("accountSettings.deleteAccount.confirm.secondary")}
          secondaryAction={() => setShowDeleteAccountConfirmationModal(false)}
          closeAction={() => setShowDeleteAccountConfirmationModal(false)}
          closeOnOverlayClick
        />
      )}
      {showResetComputerConfirmationModal && (
        <ResetComputerModal
          setShowResetComputerConfirmationModal={setShowResetComputerConfirmationModal}
          setShowMachineResetSuccessModal={setShowMachineResetSuccessModal}
          getWorkstationsAPI={getWorkstationsAPI}
          translate={translate}
        />
      )}
      {showPasswordChangeSuccessModal && (
        <Modal
          closeOnOverlayClick
          closeAction={() => setShowPasswordChangeSuccessModal(false)}
          headerText={translate("modals.passwordChangeSuccessModal.header")}
          descriptionText={
            <>
              <Animation
                type="success"
                style={{ height: "6rem", width: "6rem", marginLeft: "auto", marginRight: "auto" }}
              />
              {translate("modals.passwordChangeSuccessModal.description")}
            </>
          }
          confirmText={translate("modals.passwordChangeSuccessModal.confirm")}
          confirmAction={() => {
            setShowPasswordChangeSuccessModal(false);
          }}
          secondaryText={translate("accountSettings.deleteAccount.confirm.secondary")}
          secondaryAction={() => setShowDeleteAccountConfirmationModal(false)}
        />
      )}
      {showMachineResetSuccessModal && (
        <Modal
          topRightIcon="close"
          topRightIconAction={() => setShowMachineResetSuccessModal(false)}
          closeOnOverlayClick
          headerText={translate("accountSettings.resetComputer.success.header")}
          centeredContent
          descriptionText={
            <>
              <Animation type="success" style={{ height: "6rem", width: "6rem", margin: "0 auto 1rem" }} />
              {translate("accountSettings.resetComputer.success.description")}
            </>
          }
          closeAction={() => {
            setShowMachineResetSuccessModal(false);
          }}
        />
      )}

      {showSetPasswordConfirmationModal && (
        <ConfirmationModal
          topRightIcon="close"
          topRightIconAction={() => setShowSetPasswordConfirmationModal(false)}
          closeOnOverlayClick
          headerText={translate("accountSettings.setPasswordConfirmationModal.header")}
          centeredContent
          descriptionText={translate("accountSettings.setPasswordConfirmationModal.description")}
          closeAction={() => {
            setShowSetPasswordConfirmationModal(false);
          }}
          confirmText={translate("accountSettings.setPasswordConfirmationModal.confirmAction")}
          confirmAction={() => {
            setShowSetPasswordConfirmationModal(false);
            handleSetPassword();
          }}
          secondaryText={translate("accountSettings.setPasswordConfirmationModal.cancelAction")}
          secondaryAction={() => setShowSetPasswordConfirmationModal(false)}
        />
      )}
      {showUnlinkGoogleConfirmationModal && (
        <ConfirmationModal
          topRightIcon="close"
          topRightIconAction={() => setShowUnlinkGoogleConfirmationModal(false)}
          closeOnOverlayClick
          headerText={translate("accountSettings.unlinkGoogleConfirmationModal.header")}
          centeredContent
          descriptionText={translate("accountSettings.unlinkGoogleConfirmationModal.description")}
          closeAction={() => {
            setShowUnlinkGoogleConfirmationModal(false);
          }}
          confirmText={translate("accountSettings.unlinkGoogleConfirmationModal.confirmAction")}
          confirmAction={() => {
            setShowUnlinkGoogleConfirmationModal(false);
            handleUnlinkGoogle();
          }}
          secondaryText={translate("accountSettings.unlinkGoogleConfirmationModal.cancelAction")}
          secondaryAction={() => setShowUnlinkGoogleConfirmationModal(false)}
        />
      )}
      {showTimezoneModal && <TimezoneModal userTimeZone={userTimeZone} setShowTimezoneModal={setShowTimezoneModal} />}
    </>
  );
};

export default AccountComponent;
