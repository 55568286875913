import React from "react";
import { Translate } from "react-localize-redux";
import Play from "Assets/images/appStreaming/play.png";
import { DivButton } from "UI";

import "./UseCaseCard.styles.scss";

const UseCaseCard = ({ background, translate, useCase, onUseCaseClick }) => {
  return (
    <div className="use-case-card">
      <DivButton
        onClick={() => {
          onUseCaseClick(useCase);
        }}
        className="use-case-logo-container"
        style={{ backgroundImage: `url(${background})` }}
      >
        <p>{translate(`appStreamingDashboard.useCases.useCases.${useCase.name}.description`)}</p>
        <img src={Play} alt="use case logo" />
      </DivButton>
      <div className="content">
        <div>
          <Translate
            id={`appStreamingDashboard.useCases.useCases.${useCase.name}.action`}
            options={{
              renderInnerHtml: true,
            }}
            data={{ link: useCase.link }}
          />
        </div>
      </div>
    </div>
  );
};

export default UseCaseCard;
