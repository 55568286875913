import React, { useEffect } from "react";
import { getOrganizationPlanAPI } from "Actions/Organization.actions";
import { useDispatch } from "react-redux";
import CreateComputers from "Components/Organization/Admin/CreateComputers/CreateComputers.component";

const CreateComputersContainer = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizationPlanAPI());
  }, []);

  return <CreateComputers {...props} />;
};

export default CreateComputersContainer;
