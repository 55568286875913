import React, { useState } from "react";

import { Icon } from "UI";
import MenuItems from "./MenuItems.component";
import AppStreamingMenuItems from "./AppStreamingMenuItems.component";
import OrganizationAdminMenuItems from "./OrganizationAdminMenuItems.component";
import OrganizationMemberMenuItems from "./OrganizationMemberMenuItems.component";

const MenuMobile = (props) => {
  const { path } = props;
  const [showMenu, setShowMenu] = useState(false);

  const isAppStreamingPage = path.includes("/stream");
  const isOrganizationAdminPage = path.includes("/team/admin");
  const isOrganizationMemberPage = path.includes("/team/member");

  const handleKeyUp = (event) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      setShowMenu(!showMenu);
    }
  };
  const getMenuItems = () => {
    if (isOrganizationAdminPage) return <OrganizationAdminMenuItems setShowMenu={setShowMenu} {...props} mobile />;
    else if (isAppStreamingPage) return <AppStreamingMenuItems setShowMenu={setShowMenu} {...props} mobile />;
    else if (isOrganizationMemberPage)
      return <OrganizationMemberMenuItems setShowMenu={setShowMenu} {...props} mobile />;

    return <MenuItems {...props} setShowMenu={setShowMenu} mobile />;
  };

  return (
    <div className={`menu-mobile-container ${showMenu ? "open" : "closed"}`}>
      <div className="menu-mobile-header-row">
        <Icon name="vagon-icon" color="purple-main" />
        <i
          onClick={() => setShowMenu(!showMenu)}
          className={showMenu ? "fas fa-times" : "fas fa-bars"}
          role="menu"
          tabIndex={0}
          onKeyUp={handleKeyUp}
        />
      </div>
      {showMenu && getMenuItems()}
    </div>
  );
};

export default MenuMobile;
