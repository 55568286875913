import React from "react";
import { Steps } from "intro.js-react";
import PCImage from "Assets/images/onboardingModals/after-creation-1.jpg";
import PerformanceImage from "Assets/images/onboardingModals/performance-selection.jpg";
import StorageImage from "Assets/images/onboardingModals/additional-storage.jpg";
import FilesImage from "Assets/images/onboardingModals/vagon-files.jpg";
import TeamsImage from "Assets/images/onboardingModals/vagon-teams.jpg";
import RunImage from "Assets/images/onboardingModals/run-computer.jpg";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const AfterCreationOnboardingModal = ({ onExit, translate }) => {
  const steps = [
    {
      title: `<img src=${PCImage} />`,
      intro: `<h1>${translate("modals.onboardingModals.afterCreationOnboardingModal.intro.header")}</h1><p>${translate(
        "modals.onboardingModals.afterCreationOnboardingModal.intro.description",
      )}</p>`,
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".workstation-performance-area",
      title: `<img src=${PerformanceImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.afterCreationOnboardingModal.performance.header",
      )}</h1><p>${translate("modals.onboardingModals.afterCreationOnboardingModal.performance.description")}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".workstation-storage-area",
      title: `<img src=${StorageImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.afterCreationOnboardingModal.storage.header",
      )}</h1><p>${translate("modals.onboardingModals.afterCreationOnboardingModal.storage.description")}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".sidebar-files-menu",
      title: `<img src=${FilesImage} />`,
      intro: `<h1>${translate("modals.onboardingModals.afterCreationOnboardingModal.files.header")}</h1><p>${translate(
        "modals.onboardingModals.afterCreationOnboardingModal.files.description",
      )}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".sidebar-teams-menu",
      title: `<img src=${TeamsImage} />`,
      intro: `<h1>${translate("modals.onboardingModals.afterCreationOnboardingModal.teams.header")}</h1><p>${translate(
        "modals.onboardingModals.afterCreationOnboardingModal.teams.description",
      )}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".computer-action-button-container.breath",
      title: `<img src=${RunImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.afterCreationOnboardingModal.runComputer.header",
      )}</h1><p>${translate("modals.onboardingModals.afterCreationOnboardingModal.runComputer.description")}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
  ];

  return (
    <Steps
      enabled
      options={{ nextToDone: true, disableInteraction: true }}
      steps={steps}
      initialStep={0}
      onExit={onExit}
    />
  );
};

export default AfterCreationOnboardingModal;
