import React from "react";
import "./CheckBox.styles.scss";

const CheckBox = ({ label, checked = false, onChange, disabled }) => (
  <div className="vg-checkbox">
    <input
      type="checkbox"
      name={label}
      checked={checked}
      onChange={onChange}
      className="form-check-input"
      disabled={disabled}
    />
    <label htmlFor="checkbox">{label}</label>
  </div>
);

export default CheckBox;
