import React from "react";
import Lottie from "react-lottie";

import SuccessAnimationData from "Assets/lottie/success.json";
import SuccessPurpleAnimationData from "Assets/lottie/success-purple.json";
import SuccessGrayAnimationData from "Assets/lottie/success-gray.json";
import OnDemandMachineAnimation from "Assets/lottie/on-demand.json";
import BalancedMachineAnimation from "Assets/lottie/balanced.json";
import AlwaysOnMachineAnimation from "Assets/lottie/always-on.json";

import "./Animation.styles.scss";

const ANIMATIONS = {
  success: SuccessAnimationData,
  successPurple: SuccessPurpleAnimationData,
  successGray: SuccessGrayAnimationData,
  on_demand: OnDemandMachineAnimation,
  balanced: BalancedMachineAnimation,
  always_on: AlwaysOnMachineAnimation,
};

const Animation = (props) => {
  const {
    big,
    centered,
    type,
    description,
    style = {},
    animationStyle = {},
    isStopped = false,
    isPaused = false,
    autoplay = true,
    loop = false,
  } = props;
  const containerClassNames = ["vg-animation"];

  const defaultOptions = {
    loop,
    autoplay,
    animationData: ANIMATIONS[type],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const animationSize = {
    height: style.height || 100,
    width: style.width || 100,
  };

  if (big) {
    containerClassNames.push("big");
  }

  if (centered) {
    containerClassNames.push("centered");
  }

  return (
    <div className={containerClassNames.join(" ")} style={style}>
      {!!description && <p>{description}</p>}
      <Lottie
        options={defaultOptions}
        height={animationSize.height}
        width={animationSize.width}
        isStopped={isStopped}
        isPaused={isPaused}
        style={animationStyle}
      />
    </div>
  );
};

export default Animation;
