import React, { useState, useEffect, useRef } from "react";
import { classnames } from "Utils";
import IconButton from "UI/IconButton/IconButton.component";
import Icon from "UI/Icon/Icon.component";

import "./EditableCell.styles.scss";
import HoverableTooltip from "UI/HoverableTooltip/HoverableTooltip.component";

const EditableCell = ({ className: classNameFromProps, initialValue, onEnter, disabled, error, onCancel }) => {
  const [value, setValue] = useState(initialValue);
  const [editingCell, setEditingCell] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setEditingCell(false);
      setValue(initialValue);
    }
    if (e.key === "Enter") {
      setEditingCell(false);

      if (value === "") {
        setValue(initialValue);
        return;
      }

      onEnter(value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (error) {
      setEditingCell(true);
    }
  }, [error]);

  useEffect(() => {
    if (!editingCell && onCancel) {
      onCancel();
    }
  }, [editingCell]);

  const callbackRef = useRef(handleKeyDown);

  useEffect(() => {
    callbackRef.current = handleKeyDown;
  }, [handleKeyDown]);

  useEffect(() => {
    const handleKeyDownRef = (e) => callbackRef.current(e);

    if (editingCell) {
      document.addEventListener("keydown", handleKeyDownRef);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDownRef);
    };
  }, [editingCell]);

  const handleDoubleClick = () => {
    if (disabled) return;
    setEditingCell(true);
  };

  if (editingCell) {
    return (
      <>
        <input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className="vg-editable-cell-input"
          type="text"
          value={value}
          maxLength={20}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            setValue(initialValue);
            setEditingCell(false);
          }}
        />
        {error && (
          <HoverableTooltip content={error} side="right">
            <Icon name="info" color="red" smaller />
          </HoverableTooltip>
        )}
      </>
    );
  }

  return (
    <div className={classnames(["vg-editable-cell-label", classNameFromProps])} onDoubleClick={handleDoubleClick}>
      {value}
      {!disabled && (
        <IconButton
          name="pencil"
          className="vg-editable-cell-icon"
          onClick={() => {
            if (disabled) return;
            setEditingCell(true);
          }}
        />
      )}
    </div>
  );
};

export default EditableCell;
