import React from "react";
import FileUploadArea from "Utils/Components/File/FileUploadArea/FileUploadArea.component";

const AppUploadArea = ({ onFileDrop, uploadText, isDragActive, small, acceptedFiles, single, error, translate }) => {
  const handleFileInput = (event) => {
    const { files } = event.target;
    onFileDrop(files);
  };

  return (
    <FileUploadArea
      id="app"
      handleFileInput={handleFileInput}
      isDragActive={isDragActive}
      dragAndDropText={uploadText || translate("appStreamingModals.appUpload.dragAndDrop")}
      icon="upload-to-cloud"
      color="aqua-main"
      small={small}
      error={error}
      acceptedFiles={acceptedFiles}
      single={single}
      translate={translate}
    />
  );
};

export default AppUploadArea;
