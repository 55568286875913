import React from "react";
import _ from "lodash";
import moment from "moment";
import { HistoryCard, HistoryTable } from "UI";
import DashboardSubScreenLayout from "Utils/Components/Layouts/DashboardSubScreenLayout/DashboardSubScreenLayout.component";
import NothingToShow from "Assets/images/vagon-nothing.png";
import { API_ENDPOINTS } from "Constants/api.constants";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import { convertDateFormat, convertToSelectedTimeZone } from "Utils/Helpers/functions.helpers";
import { useSelector } from "react-redux";
import "Components/Dashboard/Account/Usage/Usage.styles.scss";

const HEADERS = ["Date", "Member", "Performance", "Region", "Duration"];
const KEYS = ["date", "member_name", "performance", "location", "usage"];

const generateData = (machineUsage, userTimeZone) =>
  _.map(machineUsage, (usage) => {
    const startedAt = usage.attributes.start_at;

    return {
      id: usage.id,
      date: startedAt
        ? convertToSelectedTimeZone(
            `${convertDateFormat(startedAt.substring(0, 10))} ${startedAt.substring(
              startedAt.indexOf("T") + 1,
              startedAt.indexOf("T") + 6,
            )}`,
            userTimeZone,
          )
        : "",
      member_name: usage.attributes.user.attributes.email,
      performance: usage.attributes.machine_type.attributes.friendly_name,
      location: usage.attributes.region,
      usage: moment.utc(usage.attributes.duration * 1000).format("HH:mm:ss"),
    };
  });

const OrganizationMemberSessions = (props) => {
  const { translate } = props;
  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};

  const {
    data: sessionData,
    fetchNextPage: fetchNextPageSessions,
    isFetchingNextPage: isFetchingNextPageSessions,
    hasNextPage: hasNextPageSessions,
    isLoading,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_MINE,
    resource: "sessions",
  });

  const usageData = generateData(sessionData, userTimeZone);

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("teamUsageHistory.title")}
      headerDescription={translate("teamUsageHistory.description")}
      balanceTitle={translate("teamUsageHistory.balanceTitle")}
      translate={translate}
      waitFor={[usageData, !isLoading]}
    >
      {sessionData?.length > 0 ? (
        <>
          <div className="vg-usage-history-table">
            <HistoryTable
              headers={HEADERS}
              keys={KEYS}
              data={usageData}
              fetchNextPage={fetchNextPageSessions}
              isFetchingNextPage={isFetchingNextPageSessions}
              hasNextPage={hasNextPageSessions}
              session
            />
          </div>
          <div className="vg-usage-history-card">
            {usageData.map((usage) => (
              <HistoryCard key={usage.id} headers={HEADERS} keys={KEYS} item={usage} />
            ))}
          </div>
        </>
      ) : (
        <div className="vg-usage-empty">
          <img src={NothingToShow} alt="Nothing to show" className="stripe-image" />
          <h1>{translate("teamUsageHistory.nothingToShow")}</h1>
          <p>{translate("teamUsageHistory.useVagon")}</p>
        </div>
      )}
    </DashboardSubScreenLayout>
  );
};

export default OrganizationMemberSessions;
