import React, { useEffect, useState } from "react";
import { API_ENDPOINTS } from "Constants/api.constants";
import StatusBadge from "UI/StatusBadge/StatusBadge.component";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import CheckBox from "UI/CheckBox/CheckBox.component";
import { useMutation } from "@tanstack/react-query";
import { apiGenerator } from "Utils";
import ObjectsModal from "../Components/ObjectsModal/ObjectsModal.component";

const TABLE_COLUMNS = [
  { name: "Template", weight: 40 },
  { name: "Size", weight: 30 },
  { name: "Status", weight: 30 },
];

const TemplatesModal = ({ onClose, selectedSeat, translate }) => {
  const [selected, setSelected] = useState(null);
  const [q, setQ] = useState("");

  const { fetchNextPage, isFetchingNextPage, hasNextPage, data, isLoading, refetch } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES,
    params: {
      q,
    },
    options: {
      overrideQueryKey: [API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES],
    },
    resource: "silver_images",
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [q]);

  const assignTemplateMutation = useMutation({
    mutationKey: [API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES_ASSIGN(selected)],
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES_ASSIGN(selected), data);
    },
  });

  const { isSuccess, isError } = assignTemplateMutation;

  const handleSubmit = () => {
    assignTemplateMutation.mutate({ seat_ids: [selectedSeat.id] });
  };

  const silverImages = data?.map((object) => {
    const { status, name, size } = object.attributes;

    const determineStatus = () => {
      if (status === "available") return "ready_to_assign";
      return status;
    };

    return {
      key: object.id,
      template: (
        <>
          <CheckBox
            checked={selected === object.id}
            onChange={() => {
              if (selected === object.id) {
                setSelected(null);
              } else {
                setSelected(object.id);
              }
            }}
          />
          {name}
        </>
      ),
      size: `${size} GB`,
      status: <StatusBadge status={determineStatus()} />,
    };
  });

  return (
    <ObjectsModal
      onClose={onClose}
      columns={TABLE_COLUMNS}
      data={silverImages}
      onConfirm={() => {
        handleSubmit();
      }}
      disableConfirm={!selected}
      success={isSuccess}
      error={isError}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      searchQuery={q}
      setSearchQuery={setQ}
      headerText={translate("templatesModal.header.title")}
      successHeader={translate("templatesModal.success.header")}
      successDescription={translate("templatesModal.success.description")}
      emptyContentHeader={translate("templatesModal.emptyContent.title")}
      emptyContentDescription={translate("templatesModal.emptyContent.description")}
    />
  );
};

export default TemplatesModal;
