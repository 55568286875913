import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { capitalize } from "lodash";
import { DashboardSubScreenLayout, apiGenerator, localDateFormat } from "Utils";
import { Icon, DatePickerInput, Dropdown } from "UI";
import moment from "moment";
import SessionGraph from "./SessionGraph.component";
import UserSessionTable from "./UserSessionTable.component";
import "./Stats.styles.scss";

const OrganizationAdminStats = ({ translate }) => {
  const [startDate, setStartDate] = useState(moment().startOf("month").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [regionFilter, setRegionFilter] = useState(null);
  const [performanceFilter, setPerformanceFilter] = useState(null);
  const [userFilter, setUserFilter] = useState(null);

  const { data: organizationMachineSessionsData } = useQuery({
    queryKey: [
      API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_STATS,
      startDate,
      endDate,
      userFilter,
      performanceFilter,
      regionFilter,
      startDate,
      endDate,
    ],
    keepPreviousData: true,
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_STATS, {
        params: {
          start_at: startDate ? localDateFormat(startDate) : null,
          end_at: endDate ? localDateFormat(endDate) : null,
          user_id: userFilter?.id,
          region: regionFilter,
          machine_type_id: performanceFilter?.id,
        },
      });
    },
  });

  const { data: filterOptionsData } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_FILTER_OPTIONS],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_FILTER_OPTIONS);
    },
  });

  const customerSessions = Object.entries(organizationMachineSessionsData?.data?.daily_session_count || {}).map(
    ([date, count]) => {
      return {
        count,
        // Remove 0 if it's the beginning of dates and replace month and day locations i.e 05-03-2022 -> 5/3
        time: date.substring(8, 10).replace(/^0+/, "").concat("/", date.substring(5, 7).replace(/^0+/, "")),
      };
    },
  );

  const averageSessionDuration = (organizationMachineSessionsData?.data?.average_session_duration / 60 || 0).toFixed(1);
  const totalSessionDuration = (organizationMachineSessionsData?.data?.total_session_duration / 60 || 0).toFixed(1);

  const userFilterOptions = [{ id: null, email: "All Members" }, ...(filterOptionsData?.data?.users || [])];
  const performanceFilterOptions = [
    { id: null, name: "All Performances" },
    ...(filterOptionsData?.data?.machine_types || []),
  ];

  const regionFilterOptions = ["All Regions", ...(filterOptionsData?.data?.regions || [])];

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("organizationStats.header.title")}
      headerDescription={translate("organizationStats.header.description")}
      translate={translate}
      appStreaming
    >
      <div className="organizations-stats-container">
        <div className="filter-graph-container">
          <div className="filter-row">
            <div className="dropdown-container">
              <Dropdown
                underlined
                options={userFilterOptions}
                mapFunction={(option) => {
                  return option.email;
                }}
                handleSelectedOptionChange={(e) => {
                  if (e.id === null) {
                    setUserFilter(null);
                  } else {
                    setUserFilter(e);
                  }
                }}
                defaultSelected={userFilter}
                placeholder="Team Member"
                selectedOption={userFilter}
                disabled={userFilterOptions.length === 1}
              />
              <Dropdown
                underlined
                options={performanceFilterOptions}
                mapFunction={(option) => option.name}
                handleSelectedOptionChange={(e) => {
                  if (e.id === null) {
                    setPerformanceFilter(null);
                  } else {
                    setPerformanceFilter(e);
                  }
                }}
                defaultSelected={performanceFilter}
                placeholder="Performance"
                disabled={performanceFilterOptions.length === 1}
              />

              <Dropdown
                underlined
                options={regionFilterOptions}
                mapFunction={(option) => capitalize(option)}
                handleSelectedOptionChange={(e) => {
                  if (e === "All Regions") {
                    setRegionFilter(null);
                  } else {
                    setRegionFilter(e);
                  }
                }}
                defaultSelected={regionFilter}
                placeholder="Region"
                disabled={regionFilterOptions.length === 1}
              />
              <DatePickerInput
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </div>

            <div className="users-graph-container content-background white">
              <div className="info-container">
                <div className="info-line">
                  <div className="aqua-light rounded icon-background">
                    <Icon name="team" color="aqua-main" small />
                  </div>
                  <div className="text-container">
                    <p>
                      <span className="bold">{averageSessionDuration}</span>
                      <span className="light">{translate("appStreamingUsers.hours")}</span>
                    </p>
                    <p>avg session duration</p>
                  </div>
                </div>
                <div className="info-line">
                  <div className="baby-powder-background rounded icon-background">
                    <Icon name="hourglass" color="baby-powder" smaller />
                  </div>
                  <div className="text-container">
                    <p>
                      <span className="bold">{totalSessionDuration}</span>
                      <span className="light">{translate("appStreamingUsers.hours")}</span>
                    </p>
                    <p>total duration</p>
                  </div>
                </div>
              </div>

              <div className="graph-container">
                <SessionGraph timeSlots={customerSessions} translate={translate} />
              </div>
            </div>
          </div>
        </div>

        <div className="content-background white stats">
          <UserSessionTable
            startDate={startDate}
            endDate={endDate}
            userFilter={userFilter}
            regionFilter={regionFilter}
            performanceFilter={performanceFilter}
            translate={translate}
          />
        </div>
      </div>
    </DashboardSubScreenLayout>
  );
};

export default OrganizationAdminStats;
