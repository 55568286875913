import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import _ from "lodash";
import { FILE_STATUS, REQUEST_STATUS } from "Constants/global.constants";
import { getRecentFiles, clearUploadedFile } from "Actions/Dashboard/Files.actions";
import VagonSubscriptionImage from "Assets/images/fileManager/file-subscription.png";
import FileManagerTable from "Components/Dashboard/Files/Components/FileManagerTable/FileManagerTable.component";
import { BoxInput, TextButton } from "UI";
import { isMobile, Loader } from "Utils";

import "./FilesTab.styles.scss";

const GET_FILES_POLLING_INTERVAL = 10000;

const SEARCH_INPUT_DEBOUNCE_TIME = 500;
const FILE_LIMIT = 20;

const FilesTab = (props) => {
  const {
    files,
    setShowDownloadModal,
    setShowFileManager,
    setDisableKeyboardActions,
    getRecentFiles,
    clearUploadedFile,
    downloadFile,
    translate,
  } = props;

  const { recentFilesCTX } = files;
  const contents = recentFilesCTX.files;
  const searchRequestTimeout = useRef(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFolderItems, setSelectedFolderItems] = useState([]);
  const selectedRowID = selectedFolderItems.length === 1 ? selectedFolderItems[0] : null;
  const selectedFile = _.find(contents, (item) => item.id === selectedRowID);

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    getRecentFiles(1, FILE_LIMIT, null);
    return () => {
      _.forEach(files.uploadFilesCTX.uploadFiles, (file) => {
        if (file.status === FILE_STATUS.FAILURE) {
          clearUploadedFile(file.name);
        }
      });
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const filesPollingInterval = setInterval(() => {
      const { query, status } = files.recentFilesCTX;

      if (status !== REQUEST_STATUS.PENDING) {
        getRecentFiles(1, FILE_LIMIT, query);
      }
    }, GET_FILES_POLLING_INTERVAL);

    return () => {
      clearInterval(filesPollingInterval);
    };
  }, [files]); // eslint-disable-line

  const handeSearchInputChange = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
    if (!searchInput) {
      getRecentFiles(1, FILE_LIMIT, null);
    } else {
      const searchString = searchInput.toLowerCase();
      clearTimeout(searchRequestTimeout.current);
      searchRequestTimeout.current = setTimeout(
        () => getRecentFiles(1, FILE_LIMIT, searchString),
        SEARCH_INPUT_DEBOUNCE_TIME,
      );
    }
  };

  const handleDownloadFile = () => {
    downloadFile(selectedFile.id, selectedFile.attributes.name, selectedFile.attributes.size);
    setShowDownloadModal(true);
  };

  const fileContent = () => {
    switch (recentFilesCTX.status) {
      case REQUEST_STATUS.SUCCESS: {
        return files.recentFilesCTX.files?.length > 0 ? (
          <FileManagerTable
            contents={files.recentFilesCTX.files}
            upperContents={files.uploadFilesCTX.uploadFiles}
            keys={["name", "lastModification"]}
            upperKeys={["name", "status"]}
            selectedKeys={["name", "actions"]}
            headers={[]}
            selectedRowID={selectedRowID}
            setSelectedItem={setSelectedItem}
            translate={translate}
            onDownload={handleDownloadFile}
            selectedFolderItems={selectedFolderItems}
            setSelectedFolderItems={setSelectedFolderItems}
            isWorkstationDock
            singleSelection
            minified
            white
          />
        ) : (
          <div className="empty-folder">
            <img src={VagonSubscriptionImage} alt="Nothing to show" />
            <h1>{translate("emptyFolder.header")}</h1>
            <p className="empty-folder-info">
              <Translate
                id="emptyFolder.info"
                options={{
                  renderInnerHtml: true,
                }}
              />
            </p>
          </div>
        );
      }
      default:
        return <Loader />;
    }
  };

  return (
    <div className="files-tab">
      <div className="tab-title-item">
        <h1>{translate("dock.files.header")}</h1>
        <TextButton
          text={translate("dock.files.openFileManagerText")}
          onClick={() => setShowFileManager(true)}
          disabled={isMobile}
        />
      </div>
      <div className="menu-item">
        <BoxInput
          className="search-input"
          placeholder={translate("dock.files.fileSearch.placeHolder")}
          iconName="search"
          color="purple-light"
          value={searchInput}
          onChange={handeSearchInputChange}
          onFocus={() => {
            setDisableKeyboardActions(true);
          }}
          onBlur={() => {
            setDisableKeyboardActions(false);
          }}
        />
      </div>
      {fileContent()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  files: state.files,
  account: state.account,
});

const actionCreators = {
  getRecentFiles,
  clearUploadedFile,
};

export default connect(mapStateToProps, actionCreators)(FilesTab);
