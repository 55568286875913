import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useMutation, useQuery } from "@tanstack/react-query";
import NoTransactionsImage from "Assets/images/appStreaming/app-streaming_first review 1.png";
import { API_ENDPOINTS } from "Constants/api.constants";
import moment from "moment";
import {
  AddBalanceModal,
  DashboardSubScreenLayout,
  PaymentMethods,
  apiGenerator,
  classnames,
  convertDateFormat,
  isMobile,
  localDateFormat,
} from "Utils";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import { DatePickerInput, ExpandableTable, IconButton, TextButton, HoverableTooltip } from "UI";
import { ORGANIZATION_BILLING_DUMMY_DATA } from "Constants/teamsOnboarding.constants";
import { getOrganizationPlanAPI } from "Actions/Organization.actions";
import NetworkUsageLimit from "Utils/Components/NetworkUsageLimit/NetworkUsageLimit.component";
import { convertToSelectedTimeZone, gbToBytes } from "Utils/Helpers/functions.helpers";
import { BILLING_TABS } from "Constants/Organization.constants";
import BillingAddressInformation from "../CreateComputers/Payment/BillingAddressInformation.component";
import UserCell from "../Components/UserCell/UserCell.component";
import "./Billing.styles.scss";

const TransactionsTable = ({
  transactions,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  showTeamsBillingOnboardingModal,
  translate,
}) => {
  const transactionsColumns = [
    { name: "Id", weight: 0, hide: true },
    { name: translate("organizationBilling.paymentsTable.columns.date"), weight: 50 },
    { name: translate("organizationBilling.paymentsTable.columns.member"), weight: 50 },
    { name: translate("organizationBilling.paymentsTable.columns.transaction"), weight: 50 },
    { name: translate("organizationBilling.paymentsTable.columns.amount"), weight: 30 },
  ];

  return (
    <ExpandableTable
      loading={!transactions}
      columns={transactionsColumns}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      onPageEnd={fetchNextPage}
      data={showTeamsBillingOnboardingModal ? ORGANIZATION_BILLING_DUMMY_DATA : transactions}
    />
  );
};

const PaymentsTable = ({ payments, isFetchingNextPage, hasNextPage, fetchNextPage, translate }) => {
  const paymentsColumns = [
    { name: "Id", weight: 0, hide: true },
    { name: translate("organizationBilling.paymentsTable.columns.date"), weight: 60 },
    { name: translate("organizationBilling.paymentsTable.columns.transaction"), weight: 60 },
    { name: translate("organizationBilling.paymentsTable.columns.amount"), weight: 60 },
    { name: "buttons", weight: 5, hide: true },
  ];

  return (
    <ExpandableTable
      columns={paymentsColumns}
      data={payments}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      onPageEnd={fetchNextPage}
    />
  );
};

const Billing = ({ translate }) => {
  const [loadingInvoicePaymentID, setLoadingInvoicePaymentID] = useState(null);
  const { organization, getOrganizationPlanCTX } = useSelector((state) => state.organizations);
  const billingInformation = organization?.attributes?.billing_information?.attributes || {};
  const { free_usage: freeUsage } = getOrganizationPlanCTX?.organizationPlan?.attributes?.network || {};
  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};
  const { subscription } = organization?.attributes || {};
  const { end_of_grace_period: endOfGracePeriod } = subscription?.attributes || {};
  const [selectedSeat, setSelectedSeat] = useState(null);
  const dispatch = useDispatch();

  const location = useLocation();

  const [startDate, setStartDate] = useState(moment().startOf("month").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [activeTab, setActiveTab] = useState(0);
  const [showDepositBalanceModal, setShowDepositBalanceModal] = useState(
    location.state?.showDepositBalanceModal || false,
  );

  const balance = parseFloat(organization?.attributes?.balance).toFixed(2) || 0;

  const {
    data: organizationPaymentsData,
    fetchNextPage: fetchNextPagePayments,
    isFetchingNextPage: isFetchingNextPagePayments,
    hasNextPage: hasNextPagePayments,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.ORGANIZATION_PAYMENTS,
    params: {
      start_at: localDateFormat(startDate),
      end_at: localDateFormat(endDate),
    },
    resource: "payments",
  });

  const payments = organizationPaymentsData?.map((payment) => {
    const { payment_type: type, amount, created_at: createdAt } = payment.attributes;
    return {
      key: payment.id,
      id: payment.id,
      date: createdAt
        ? convertToSelectedTimeZone(
            `${convertDateFormat(createdAt.substring(0, 10))} ${createdAt.substring(
              createdAt.indexOf("T") + 1,
              createdAt.indexOf("T") + 6,
            )}`,
            userTimeZone,
          )
        : "",
      transaction: translate(`organizationBilling.payments.paymentTypes.${type}`),
      amount: `${amount >= 0 ? "+" : "-"} $ ${Math.abs(parseFloat(amount)).toFixed(2)}`,
      buttons: (
        <HoverableTooltip side="top" content="Download">
          <IconButton
            name={loadingInvoicePaymentID === payment.id ? "loader" : "file-download"}
            smallest
            onClick={() => {
              setLoadingInvoicePaymentID(payment.id);
              getOrganizationPaymentMutation.mutate(payment.id);
            }}
          />
        </HoverableTooltip>
      ),
    };
  });

  const {
    data: transactionsData,
    fetchNextPage: fetchNextPageTransactions,
    isFetchingNextPage: isFetchingNextTransactions,
    hasNextPage: hasNextPageTransactions,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.ORGANIZATION_TRANSACTIONS,
    params: { start_at: localDateFormat(startDate), end_at: localDateFormat(endDate), detail_level: "seat" },
    resource: "transactions",
  });

  const { data: failedPaymentData, refetch: refetchFailedPaymentData } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_FAILED_PAYMENT],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_FAILED_PAYMENT);
    },
    enabled: Boolean(endOfGracePeriod),
  });

  const { data: seatsData } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_SEATS],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_SEATS);
    },
    onSuccess: (data) => {
      if (!selectedSeat) {
        setSelectedSeat(data.data.seats[0]);
      }
    },
  });

  const { data: seatTotalNetworkUsage } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_SEATS_TOTAL_NETWORK_USAGE(selectedSeat?.id)],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_SEATS_TOTAL_NETWORK_USAGE(selectedSeat?.id));
    },
    enabled: Boolean(selectedSeat?.id),
  });

  const setSeatClassName = (seat) => {
    if (!selectedSeat?.id) return "";
    if (+selectedSeat.id !== +seat.id) return "opacity-50";
    return "";
  };

  const seatsTableData =
    seatsData?.data?.seats.map((seat) => {
      return {
        id: seat.id,
        computer: <UserCell seat={seat} user={seat.attributes.user} removeCheckbox disableFade />,
        onRowClick: () => setSelectedSeat(seat),
        className: setSeatClassName(seat),
      };
    }) || [];

  useEffect(() => {
    dispatch(getOrganizationPlanAPI());
  }, []);

  const selectedSeatTotalNetworkUsage = seatTotalNetworkUsage?.data?.total_network_usage || 0;

  const getOrganizationPaymentMutation = useMutation({
    mutationFn: (paymentID) => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_PAYMENT_INVOICE(paymentID));
    },
    onSuccess: (data) => {
      const link = document.createElement("a");
      link.href = data.data.invoice_url;
      link.download = "invoice.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoadingInvoicePaymentID(null);
    },
    onError: () => {
      setLoadingInvoicePaymentID(null);
    },
  });

  const { minimum_amount: minimumAmount } = failedPaymentData?.data || {};

  const transactions = transactionsData?.map((transaction) => {
    const { day, transaction_type: transactionType, amount, member } = transaction.attributes;
    const transactionName = translate(`organizationBilling.payments.paymentTypes.${transactionType}`);

    return {
      id: transaction.id,
      date: convertDateFormat(day),
      member,
      transaction: transactionName,
      amount: `${amount >= 0 ? "+" : "-"} $ ${Math.abs(parseFloat(amount)).toFixed(2)}`,
    };
  });

  const onAddBalanceSuccess = () => {
    refetchFailedPaymentData();
  };

  const nothingToShow =
    (transactions?.length === 0 && activeTab === BILLING_TABS.TRANSACTIONS) ||
    (payments?.length === 0 && activeTab === BILLING_TABS.PAYMENTS) ||
    (seatsData?.data?.seats.length === 0 && activeTab === BILLING_TABS.NETWORK);

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("organizationBilling.header.title")}
      headerDescription={translate("organizationBilling.header.description")}
      translate={translate}
      waitFor={[organization !== null]}
      appStreaming
    >
      <div className="full-page-container white organization-admin-billing-container">
        <div className="row grid-2-columns">
          <div className="single-item">
            <h3>{translate("organizationBilling.balance.header")}</h3>
            <div className="item-box">
              <div className="item-header">
                <div>
                  <span>$</span> {balance}
                </div>
                <div>
                  <div className="vertical-divider" />
                  <TextButton
                    text={translate("organizationBilling.balance.action")}
                    color="purple"
                    onClick={() => setShowDepositBalanceModal(true)}
                  />
                </div>
              </div>
              <p>{translate("organizationBilling.balance.description")}</p>
              {endOfGracePeriod && (
                <p className="payment-failed-information">
                  <span>Payment Failed: </span> Deposit ${minimumAmount} to renew Team subscription automatically.
                </p>
              )}
              <BillingAddressInformation billingInformation={billingInformation} translate={translate} />
            </div>
          </div>
          <div className="single-item">
            <h3>{translate("organizationBilling.paymentMethods.header")}</h3>
            <PaymentMethods translate={translate} />
          </div>
        </div>
        <div className="row header-line">
          <div className={`organization-single-item ${isMobile ? "mobile" : ""}`}>
            <div className="tab-buttons-container">
              <TextButton
                text={translate("organizationBilling.paymentsTable.header")}
                color={activeTab === BILLING_TABS.PAYMENTS ? "purple" : "gray-3"}
                onClick={() => setActiveTab(BILLING_TABS.PAYMENTS)}
              />
              <TextButton
                text={translate("organizationBilling.transactionsTable.header")}
                color={activeTab === BILLING_TABS.TRANSACTIONS ? "purple" : "gray-3"}
                onClick={() => setActiveTab(BILLING_TABS.TRANSACTIONS)}
              />

              <TextButton
                text={translate("organizationBilling.network.header")}
                color={activeTab === BILLING_TABS.NETWORK ? "purple" : "gray-3"}
                onClick={() => setActiveTab(BILLING_TABS.NETWORK)}
              />
            </div>
            {activeTab !== BILLING_TABS.NETWORK && (
              <DatePickerInput
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                placeHolder="01-02-2024 - 31-03-2024"
              />
            )}
          </div>
        </div>

        {(activeTab !== BILLING_TABS.NETWORK || (activeTab === BILLING_TABS.NETWORK && nothingToShow)) && (
          <div className={classnames(["row", activeTab === 0 && "grid-2-columns"])}>
            <div className="single-item">
              <div className="item-box fixed-height" style={{ padding: "0.5rem" }}>
                {nothingToShow && (
                  <div className="no-transactions-content">
                    <img src={NoTransactionsImage} />
                    <span>{translate("organization.nothingToShow")}</span>
                  </div>
                )}

                {payments?.length !== 0 && activeTab === BILLING_TABS.PAYMENTS && (
                  <PaymentsTable
                    payments={payments}
                    fetchNextPage={fetchNextPagePayments}
                    isFetchingNextPage={isFetchingNextPagePayments}
                    hasNextPage={hasNextPagePayments}
                    translate={translate}
                  />
                )}
                {transactions?.length !== 0 && activeTab === BILLING_TABS.TRANSACTIONS && (
                  <TransactionsTable
                    transactions={transactions}
                    fetchNextPage={fetchNextPageTransactions}
                    isFetchingNextPage={isFetchingNextTransactions}
                    hasNextPage={hasNextPageTransactions}
                    translate={translate}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === BILLING_TABS.NETWORK && !nothingToShow && (
          <div className="network-tab">
            <NetworkUsageLimit
              personal
              remainingNetworkUsage={selectedSeat?.attributes?.network_credit}
              networkUsage={gbToBytes(freeUsage)}
              totalNetworkUsage={selectedSeatTotalNetworkUsage > 100000000 ? selectedSeatTotalNetworkUsage : 0}
              translate={translate}
              infoTooltipText={translate("organizationBilling.network.tooltip")}
            />
            <div className="seats-container">
              <ExpandableTable
                columns={[
                  { name: "Id", weight: 0, hide: true },
                  { name: "Computer", weight: 100 },
                ]}
                data={seatsTableData}
              />
            </div>
          </div>
        )}
      </div>

      {showDepositBalanceModal && (
        <AddBalanceModal
          setShowAddBalanceModal={setShowDepositBalanceModal}
          onAddBalanceSuccess={onAddBalanceSuccess}
          translate={translate}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default Billing;
