import React from "react";
import Joi from "joi";

import { Button, PasswordInput, Icon } from "UI";

import { REQUEST_STATUS } from "Constants/global.constants";
import { useDispatch, useSelector } from "react-redux";
import { logoutAPI } from "Actions/Authentication/Login.actions";
import { changePasswordAPI } from "Actions/Account.actions";

const OldPasswordSchema = Joi.string().min(8).required().messages({
  "string.empty": "Please enter your current password",
  "any.required": "Please enter your current password",
  "string.min": "Please enter a password with at least 8 characters",
});
const NewPasswordSchema = Joi.string().min(8).required().messages({
  "string.empty": "Please enter a new password for your account",
  "any.required": "Please enter a new password for your account",
  "string.min": "Please enter a password with at least 8 characters",
});

const ChangePasswordForm = (props) => {
  const { translate, setShowPasswordChangeSuccess, setShowChangePasswordForm } = props;
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [isStrongPassword, setIsStrongPassword] = React.useState(false);
  const [serverError, setServerError] = React.useState();
  // validation errors:
  const [oldPasswordError, setOldPasswordError] = React.useState();
  const [newPasswordError, setNewPasswordError] = React.useState();
  const [submitted, setSubmitted] = React.useState(false);

  const { changePasswordCTX, accessToken } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (changePasswordCTX.status === REQUEST_STATUS.SUCCESS) {
      setShowPasswordChangeSuccess(true);
      setTimeout(() => {
        dispatch(logoutAPI());
      }, 3000);
    } else if (changePasswordCTX.status === REQUEST_STATUS.FAILURE && submitted) {
      setServerError("An error occurred. Please try again later.");
    }
  }, [changePasswordCTX.status]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setOldPasswordError(OldPasswordSchema.validate(currentPassword).message);
    setNewPasswordError(NewPasswordSchema.validate(newPassword).message);

    if (!isStrongPassword) {
      setNewPasswordError("The new password isn't strong enough");
    } else if (!oldPasswordError && !newPasswordError) {
      dispatch(changePasswordAPI(accessToken, { currentPassword, newPassword }));
    }
  };
  const resetErrors = () => {
    setServerError();
    setOldPasswordError();
    setNewPasswordError();
  };
  return (
    <form onSubmit={handleSubmit} className="change-password-form">
      <Icon className="top-right-close-icon" name="close" onClick={() => setShowChangePasswordForm(false)} />
      <PasswordInput
        label={translate("accountSettings.changePasswordForm.currentPassword.label")}
        placeholder={translate("accountSettings.changePasswordForm.currentPassword.placeholder")}
        value={currentPassword}
        onChange={(event) => {
          setCurrentPassword(event.target.value);
        }}
        hasError={!!oldPasswordError || !!serverError}
        resetErrors={resetErrors}
        errorText={oldPasswordError || serverError}
      />
      <PasswordInput
        label={translate("accountSettings.changePasswordForm.newPassword.label")}
        placeholder={translate("accountSettings.changePasswordForm.newPassword.placeholder")}
        value={newPassword}
        onChange={(event) => {
          setNewPassword(event.target.value);
        }}
        setIsStrongPassword={setIsStrongPassword}
        hasError={!!newPasswordError}
        resetErrors={resetErrors}
        errorText={newPasswordError}
        showStrength
      />
      <Button
        text={translate("accountSettings.changePasswordForm.buttonText")}
        className="button"
        type="submit"
        icon={changePasswordCTX.status === REQUEST_STATUS.PENDING && "loader"}
        disabled={
          changePasswordCTX.status === REQUEST_STATUS.PENDING || !currentPassword || !newPassword || !isStrongPassword
        }
      />
    </form>
  );
};

export default ChangePasswordForm;
