import React, { useState, useRef, useEffect } from "react";
import { Translate } from "react-localize-redux";

import { Modal, Input } from "UI";
import { FILE_TYPES, REQUEST_STATUS } from "Constants/global.constants";

const OrganizationFileRenameModal = (props) => {
  const { renameFileCTX, previousName, fileType, renameFile, setShowRenameFileModal, mobile, translate } = props;

  const inputRef = useRef(null);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [newFileName, setNewFileName] = useState(previousName);

  useEffect(() => {
    inputRef.current.focus();
    if (previousName) {
      inputRef.current.focus();
      const dotPosition = fileType === FILE_TYPES.directory ? -1 : previousName.lastIndexOf(".");
      inputRef.current.selectionStart = 0;
      inputRef.current.selectionEnd = dotPosition;
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (submitted) {
      if (renameFileCTX.status === REQUEST_STATUS.SUCCESS) {
        setShowRenameFileModal(false);
      } else if (renameFileCTX.status === REQUEST_STATUS.FAILURE) {
        setError(renameFileCTX.clientCode);
      }
    }
  }, [renameFileCTX.status]); // eslint-disable-line

  const handleRenameFolder = () => {
    if (!submitted && renameFileCTX.status !== REQUEST_STATUS.PENDING && newFileName) {
      setSubmitted(true);
      renameFile(newFileName);
    }
  };

  const handleEnterKeyUp = (event) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      handleRenameFolder();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "/") {
      event.preventDefault();
    }
  };

  return (
    <Modal
      closeOnOverlayClick
      closeAction={() => setShowRenameFileModal(false)}
      topRightIcon="close"
      topRightIconAction={() => setShowRenameFileModal(false)}
      headerText={translate("modals.renameFile.header")}
      buttonText={translate("modals.renameFile.buttonText")}
      buttonAction={handleRenameFolder}
      buttonDisabled={newFileName === "" || submitted}
      isPending={renameFileCTX.status === REQUEST_STATUS.PENDING}
      errorText={
        error &&
        submitted && (
          <Translate
            id={`modals.renameFile.error.${error}`}
            options={{ onMissingTranslation: () => translate("modals.renameFile.error.default") }}
          />
        )
      }
      mobile={mobile}
      invisibleOverlay
      blue
      small
    >
      <div className="file-rename-modal-content">
        <Input
          inputRef={inputRef}
          placeholder="Name"
          maxLength="50"
          value={newFileName}
          onChange={(e) => {
            if (submitted) setSubmitted(false);
            if (error) setError(false);
            setNewFileName(e.target.value);
          }}
          onKeyUp={handleEnterKeyUp}
          onKeyDown={handleKeyDown}
        />
      </div>
    </Modal>
  );
};

export default OrganizationFileRenameModal;
