import routes from "./Route.constants";

export const ONE_DOLLAR_CREDIT = 100;
export const VAGON_LANDING_PAGE_URL = "https://vagon.io";

export const REQUEST_STATUS = {
  NOT_DEFINED: null,
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export const REACT_QUERY_REQUEST_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export const AUTO_STOP_OPTIONS = {
  AFTER_15_MINUTES: 900,
  AFTER_1_HOUR: 3600,
  AFTER_3_HOURS: 10800,
  AFTER_6_HOURS: 21600,
  OFF: 0,
};

export const APP_STREAMING_AUTO_STOP_OPTIONS = {
  40: 40,
  120: 120,
  300: 300,
  900: 900,
  3600: 3600,
  10800: 10800,
  21600: 21600,
  0: 0,
};

export const MAXIMUM_SESSION_DURATIONS = {
  5: 5,
  10: 10,
  15: 15,
  30: 30,
  60: 60,
  0: 0,
};

export const IDLE_DURATION_OPTIONS = {
  0: 0,
  60000: 60000,
  300000: 300000,
  600000: 600000,
};

export const PIXEL_STREAMING_CONTAINER_COUNTS = {
  1: 1,
  2: 2,
  4: 4,
};

export const RESOLUTION_TYPES = ["res_scale", "res_720p", "res_1080p", "res_2160p"];

export const RS_RESOLUTION_TYPES = ["res_scale", "res_720p", "res_1080p"];

export const ERROR_CODE = {
  GONE: 410,
  FULL_CAPACITY: 440,
  SUBSCRIPTION_NOT_FOUND: 460,
  SUBSCRIPTION_EXPIRED: 461,
  MACHINE_TYPE_WRONG: 463,
  PAYMENT_METHOD_NOT_EXIST: 466,
  PAYMENT_METHOD_NOT_FOUND: 467,
  BALANCE_NEGATIVE: 470,
  BALANCE_INSUFFICIENT: 480,
  BALANCE_LIMIT_REACHED: 481,
  WRONG_SCOPE: 4381,
  VENDOR_ADMIN_NOT_FOUND: 441,
  MAINTENANCE: 577,
  INVALID_GRANT: 4315,
  ACCOUNT_EXISTS: 4316,
  ACCOUNT_NOT_FOUND: 4317,
};

export const WORKSTATION_STATUS = {
  PENDING: "pending",
  RUNNING: "running",
  STOPPING: "stopping",
  STOPPED: "stopped",
};

export const FILE_STATUS = {
  NOT_DEFINED: null,
  PENDING: 0,
  SUCCESS: 1,
  FAILURE: -1,
};

export const FILE_ERROR = {
  ONGOING_PROCESS: "onGoingProcess",
  FILE_TOO_BIG: "fileSizeTooBig",
  FOLDER_NOT_ALLOWED: "folderNotAllowed",
  EMPTY_FILE_NOT_ALLOWED: "emptyFileNotAllowed",
  CANCELLED_BY_USER: "cancelledByUser",
  TOO_MANY_FILES: "tooManyFiles",
  SHOULD_CONTAIN_EXE: "missingExe",
  APP_SIZE_TOO_BIG: "appSizeTooBig",
};

export const FILE_TYPES = {
  directory: "directory",
  root: "root",
  file: "file",
};

export const WORKSTATION_FRIENDLY_STATUS = {
  OFF: "off",
  CREATING: "creating",
  TURNING_ON: "turning_on",
  READY: "ready",
  TURNING_OFF: "turning_off",
  RESIZING_DISK: "resizing_disk",
  INSTALLING: "installing",
  REGION_MIGRATION: "region_migration",
  WARMING_UP: "warming_up",
  CHANGING_PERFORMANCE: "changing_performance",
  WAITING_FOR_GPUS: "waiting_for_gpus",
  PAYMENT_REQUIRED: "payment_required",
  PREPARING_ASSETS: "preparing_assets",
  MODIFYING_NETWORK: "modifying_network",
  LAUNCHING_CONTAINERS: "launching_containers",
};

export const REGIONS_NAMES = {
  dublin: "Dublin",
  frankfurt: "Frankfurt",
  north_virginia: "North Virginia",
  oregon: "Oregon",
  northern_california: "California",
  mumbai: "Mumbai",
  singapore: "Singapore",
  sydney: "Sydney",
  bahrain: "Bahrain",
  tokyo: "Tokyo",
  hong_kong: "Hong Kong",
  sao_paulo: "Sao Paulo",
  seoul: "Seoul",
  ohio: "Ohio",
  montreal: "Montreal",
  stockholm: "Stockholm",
  cape_town: "Cape Town",
  israel: "Tel Aviv",
  jakarta: "Jakarta",
  uae: "Dubai",
  paris: "Paris",
  milan: "Milan",
  london: "London",
};

export const WORKSTATION_TYPES = {
  firstRow: ["Planet", "Star", "Galaxy", "Spark"],
  secondRow: ["Lake", "Sea", "Ocean", "Sand"],
};

export const HINTER_TYPES = {
  REFERRAL: "referral",
  RATING: "rating",
};

export const LOCAL_STORAGE_PRIVATE = {
  scope: "accountScope",
  accessToken: "accessToken",
  machineType: "machineType",
  workstationProgressStep: "workstationProgressStep",
  firstConnection: "firstConnection",
  cardHoldersName: "cardHoldersName",
  referralCode: "referralCode",
};
export const LOCAL_STORAGE = {
  ...LOCAL_STORAGE_PRIVATE,
  accountHash: "accountHash",
  teamsEnabled: "teamsAccount",
  soundEnabled: "soundEnabled",
  userStreamPreferences: "userStreamPreferences",
  advancedStreamSettings: "advancedStreamSettings",
  createComputerConfigs: "createComputerConfigs",
  createTeamConfigs: "createTeamConfigs",
  scrollPreference: "scrollPreference",
  showAdvancedConfigurations: "showAdvancedConfigurations",
  switchCmdPreference: "switchCmdPreference",
  showReferralBoarding: "showReferralBoarding",
  showInviteBoarding: "showInviteBoarding",
  showedGameModeModal: "showedGameModeModal",
  showedDockTabHover: "showedDockTabHover",
  showRequestEarlyAccess: "showRequestEarlyAccess",
  showAppDownloadAnnouncement: "showAppDownloadAnnouncement",
  showTeamFileAnnouncement: "showTeamFileAnnouncement",
  showVagonAppInfo: "showVagonAppInfo",
  streamRating: "streamRating",
  customResolution: "customResolution",
  showTeamsHomeInitialOnboardingModal: "po_ShowTeamsHomeInitial",
  showTeamsHomeOnboardingModal: "po_ShowTeamsHome",
  showTeamsPlansOnboardingModal: "po_ShowTeamsPlans",
  showTeamsTemplatesOnboardingModal: "po_ShowTeamsTemplates",
  showTeamsTemplatesOnboardingAssignedModal: "po_ShowTeamsTemplateAssigned",
  showTeamsCreateTemplateOnboardingModal: "po_ShowTeamsCreateTemplate",
  showTeamsFilesOnboardingModal: "po_ShowTeamsFiles",
  showTeamsBillingOnboardingModal: "po_ShowTeamsBilling",
  showTeamsStatsOnboardingModal: "po_ShowTeamsStats",
  showAfterCreationOnboardingModal: "po_ShowComputerCreate",
  dockOnboardingModalShown: "po_DockShown",
  firstConnectionOnboardingModalShown: "po_ComputerShown",
  showFilesOnboardingModal: "po_ShowFiles",
  showTeamsOnboardingModal: "po_ShowTeamCreate",
  showSubscriptionOnboardingModal: "po_ShowTeamInvite",
  showApplicationOnboardingModal: "po_ShowApplication",
  showStreamsOnboardingModal: "po_ShowStreams",
  showBillingOnboardingModal: "po_ShowBilling",
  showStatsOnboardingModal: "po_ShowStats",
  showSettingsOnboardingModal: "po_ShowSettings",
  connectionCount: "connectionCount",
  appStreamingSettings: "appStreamingSettings",
  appStreamingResolution: "appStreamingResolution",
  appStreamingBitRate: "appStreamingBitRate",
  appStreamingQualitySelection: "appStreamingQualitySelection",
  appSessionId: "appSessionId",
  gpuInfo: "gpuInfo",
  showWelcomeStreamsAnnouncement: "showWelcomeStreamsAnnouncement",
  showStreamConfigAnnouncement: "showStreamConfigAnnouncement",
  webRTCApproval: "webRTCApproval",
  multiGpuConsentApproved: "multiGpuConsentApproved",
  lastSuccessfulConnectionType: "lastSuccessfulConnectionType",
  visitorId: "visitorId",
  debugEnabled: "debugEnabled",
  audioInputDevice: "audioInputDevice",
  audioOutputDevice: "audioOutputDevice",
  gesturesOnboardingModalShown: "gesturesOnboardingModalShown",
  timeZone: "timeZone",
  vrEnvironmentEnabled: "vrEnvironmentEnabled",
  vrBitRate: "vrBitRate",
  vrInstallationPercentage: "vrInstallationPercentage",
};

export const CONNECTION_STATUS = {
  terminated: "terminated",
  waiting: "waiting",
  available: "available",
  connected: "connected",
  cleaning: "cleaning",
  assigned: "assigned",
};

export const SESSION_STORAGE = {
  minLatency: "minLatency",
  minLatencyRegion: "minLatencyRegion",
  clipboardData: "clipboardData",
  soundEnabled: "soundEnabled",
  streamDocksPreviewed: "streamDocksPreviewed",
  micEnabled: "micEnabled",
  source: "source",
};

export const INPUT_LIMITS = {
  name: 30,
  email: 40,
  password: 40,
};

export const DISK_LOCATIONS = {
  ami: "ami",
  ebs: "ebs",
};

export const TRANSACTION_TYPES = {
  undefined: "Transaction",
  deposit: "Account Balance",
  usage_payment: "Computer Usage",
  network_usage: "Network Usage",
  disk_payment: "Computer Storage",
  subscription_payment: "Subscription Payment",
  outstanding_debt: "Debt Payment",
  storage: "File Storage",
  reward: "Invite Reward",
  refund: "Refunded Balance",
  cash_back: "Account Balance",
  transferred_funds: "Usage Credits",
  block_balance: "Secured Personal Balance",
  unblock_balance: "Activated Personal Balance",
  return_team_funds: "Usage Credits Refund",
  team_balance_transaction: "Balance Transaction",
  transfer_to_user: "Credit Transfer",
  transferred_from_user: "Returning Credit",
  team_storage: "Shared Team Storage",
  region_migration: "Region Migration",
};

export const APP_DOWNLOAD_LINKS = {
  appDownloadLinkForWindows: "https://app.vagon.io/apps/Vagon.zip",
  appDownloadLinkForAppleIntel: "https://app.vagon.io/apps/Vagon.dmg",
  appDownloadLinkForAppleSilicon: "https://app.vagon.io/apps/Vagon-arm64.dmg",
  appDownloadLinkForLinux: "https://vagon.io/download",
  appDownloadLinkForOtherPlatforms: "https://vagon.io/download",
};

export const STREAM_TYPE = {
  workstation: 0,
  application: 1,
  collaboration: 2,
  organization: 3,
  pixel_streaming: 4,
  public_organization: 5,
};

export const MACHINE_TYPE_PREFIXES = {
  T4: "g4dn",
  A10: "g5",
  CPU_ACCELERATED: "z1d",
};

export const PAYMENT_METHODS = {
  BANK_TRANSFER: 0,
  CREDIT_CARD: 1,
};

export const IPC_MESSAGE = {
  MICROPHONE_ACCESS: "microphoneAccess",
  HARDWARE_ACCELERATION: "hardwareAcceleration",
};

export const FEEDBACK_TYPES = {
  streaming: "streaming",
  endSubscription: "endSubscription",
};

export const UPLOAD_TYPES = {
  FILE_UPLOAD: "FILE_UPLOAD",
  WORKSTATION_UPLOAD: "WORKSTATION_UPLOAD",
  APPLICATION_UPLOAD: "APPLICATION_UPLOAD",
  APPLICATION_VERSION_UPLOAD: "APPLICATION_VERSION_UPLOAD",
  APPLICATION_LOGO_UPLOAD: "APPLICATION_LOGO_UPLOAD",
  APPLICATION_BANNER_UPLOAD: "APPLICATION_BANNER_UPLOAD",
  STREAM_SESSION_FILE_UPLOAD: "STREAM_SESSION_FILE_UPLOAD",
};

export const KEY_MAPPING_OPTIONS = {
  CLICK: "click",
  360: "game_mode",
};

export const PAYMENT_TYPE = {
  BALANCE: "balance",
  CREDIT_CARD: "credit_card",
};

export const CAPACITY_TYPES = {
  on_demand: "on_demand",
  balanced: "balanced",
  always_on: "always_on",
};

export const OS = {
  linux: "linux",
  windows: "windows",
};

export const LINUX_UN_PERMITTED_EXTENSIONS = [
  "info",
  "map",
  "config",
  "ini",
  "xml",
  "png",
  "jpg",
  "jpeg",
  "gif",
  "level",
  "assets",
  "asset",
  "dll",
  "resS",
  "resource",
  "so",
  "aspx",
  "mdb",
  "debug",
  "json",
  "browser",
  "html",
  "js",
  "css",
  "bat",
  "ico",
  "txt",
  "ps1",
  "tps",
];

export const FILTERED_WORDS_UPLOADED_APP = ["unitycrashhandler", "ue4prereqsetup", "ueprereqsetup", "epicwebhelper"];

// This constant is used to identify the switchman user for demo purposes
export const SWITCHMAN = "7063030e:";

export const ROOT_PATHS = {
  team_member: routes.organizationMemberHome,
  team_admin: routes.organizationAdminHome,
  user: routes.home,
  vendor: routes.appStreamingHome,
  null: routes.suite,
};

export const APP_SCOPES = {
  user: "user",
  vendor: "vendor",
  team_member: "team_member",
  team_admin: "team_admin",
};

export const INTERNAL_SDK_MESSAGES = {
  resize: "##RESIZE##",
  showKeyboard: "##SHOWKEYBOARD##",
  hideKeyboard: "##HIDEKEYBOARD##",
  enableGameMode: "##ENABLEGAMEMODE##",
  disableGameMode: "##DISABLEGAMEMODE##",
  keepAlive: "##KEEPALIVE##",
  shutdown: "##SHUTDOWN##",
  focusIframe: "##vagonFOCUSIFRAME",
  onInstalling: "##vagonONINSTALLING",
  onInstallationFailed: "##vagonONINSTALLATIONFAILED",
  onConnected: "##vagonONCONNECTED",
  onDisconnected: "##vagonONDISCONNECTED",
  onInactive: "##vagonONINACTIVE",
  onFailed: "##vagonONFAILED",
  setQualityStandard: "##SETQUALITY##STANDARD",
  setQualityModerate: "##SETQUALITY##MODERATE",
  setQualityHigh: "##SETQUALITY##HIGH",
  requestSessionInformation: "##REQUESTSESSIONINFORMATION##",
  onSessionInformation: "##vagonONSESSIONINFORMATION##",
  onInit: "##vagonONINITIALIZATION",
  onPreparingAssets: "##vagonONPREPARINGASSETS",
  onPreloadedFilesDownloadCompleted: "##vagonONPRELOADEDFILESDOWNLOADCOMPLETED",
  onSessionExpired: "##vagonONSESSIONEXPIRED",
  onPointerLockEnabled: "##vagonONPOINTERLOCKENABLED",
  onPointerLockDisabled: "##vagonONPOINTERLOCKDISABLED",
};

export const FILE_UPLOAD_STATUS = {
  PENDING: "pending",
  UPLOADING: "uploading",
  SUCCESS: "success",
  FAILURE: "failure",
};

export const REDIRECT_FROM_SOURCES = {
  unity: "unity",
};
