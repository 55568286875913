import React from "react";
import Joi from "joi";
import ReCaptcha from "Utils/Components/Form/ReCaptcha";
import AuthenticationHeader from "Utils/Components/Authentication/AuthenticationHeader/AuthenticationHeader.component";
import { Button, Input, TopLoader } from "UI";
import routes from "Constants/Route.constants";
import { REQUEST_STATUS } from "Constants/global.constants";
import "./ForgetPassword.styles.scss";
import { Animation } from "Utils";

const ValidationSchema = Joi.object({
  email: Joi.string().email({ minDomainSegments: 2, tlds: false }).required().messages({
    "string.empty": "Enter your email address",
    "string.email": "Enter a valid email address",
    "any.required": "Enter your email address",
  }),
});

const ForgetPasswordComponent = (props) => {
  const { history, forgetPasswordCTX, forgetPasswordAPI, translate } = props;
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (forgetPasswordCTX.status === REQUEST_STATUS.FAILURE) {
      if (forgetPasswordCTX.client_code === 404) {
        setError(translate("forgetPassword.error.registeredEmail"));
      } else {
        setError(translate("forgetPassword.error.somethingWentWrong"));
      }
    }
  }, [forgetPasswordCTX]);

  const handleSubmit = (event, reCaptcha) => {
    event.preventDefault();

    const { error: validationError } = ValidationSchema.validate({ email });
    if (validationError) {
      setError(validationError.message);
    } else {
      setError("");
      reCaptcha({ action: "forgetPassword" }, (recaptchaResponse) => {
        forgetPasswordAPI({ email, recaptchaResponse });
      });
    }
  };

  const determineDescription = (translate) => {
    if (forgetPasswordCTX.status === REQUEST_STATUS.SUCCESS) {
      return "";
    } else if (history.location.state && history.location.state.from === routes.forgetPasswordReset) {
      return translate("forgetPassword.error.descriptionLinkExpired");
    }

    return translate("forgetPassword.header.description");
  };

  return (
    <>
      {forgetPasswordCTX.status === REQUEST_STATUS.PENDING && <TopLoader />}
      <ReCaptcha>
        {(reCaptcha) => (
          <div className="vg-forget-password-content form-container">
            <form onSubmit={(event) => handleSubmit(event, reCaptcha)} noValidate>
              <AuthenticationHeader
                title={
                  forgetPasswordCTX.status === REQUEST_STATUS.SUCCESS
                    ? translate("forgetPassword.header.titleSuccess")
                    : translate("forgetPassword.header.title")
                }
                description={determineDescription(translate)}
              />
              <div className="field-group">
                {forgetPasswordCTX.status === REQUEST_STATUS.SUCCESS && (
                  <>
                    <Animation type="successPurple" animationStyle={{ margin: "0 auto 0 -20px" }} />
                    <p className="description">{translate("forgetPassword.header.descriptionSuccess")}</p>
                  </>
                )}
                <Input
                  name="email"
                  type="email"
                  label={translate("forgetPassword.form.email.label")}
                  placeholder={translate("forgetPassword.form.email.placeholder")}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  hasError={!!error}
                  errorText={error}
                  disabled={forgetPasswordCTX.status === REQUEST_STATUS.SUCCESS}
                />
                <div className="form-description">
                  {translate("forgetPassword.support.info")}{" "}
                  <a href="http://help.vagon.io/en/">{translate("forgetPassword.support.linkText")}</a>
                </div>
              </div>
              <Button
                type="submit"
                disabled={
                  forgetPasswordCTX.status === REQUEST_STATUS.PENDING ||
                  forgetPasswordCTX.status === REQUEST_STATUS.SUCCESS ||
                  !email ||
                  reCaptcha === null
                }
                text={
                  forgetPasswordCTX.status === REQUEST_STATUS.SUCCESS
                    ? translate("forgetPassword.form.button.textSuccess")
                    : translate("forgetPassword.form.button.text")
                }
                block
              />
            </form>
          </div>
        )}
      </ReCaptcha>
    </>
  );
};

export default ForgetPasswordComponent;
