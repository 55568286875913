import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  cancelFileUpload,
  createFolder,
  deleteFile,
  downloadFile,
  getFileStorageCapacity,
  moveFile,
  renameFile,
  uploadFilesToFolder,
} from "Actions/Dashboard/Files.actions";
import { getOrganizationAPI, getOrganizationPlanAPI } from "Actions/Organization.actions";
import VagonGracePeriodImage from "Assets/images/fileManager/file-grace.jpg";
import VagonSubscriptionImage from "Assets/images/fileManager/file-subscription.png";
import routes from "Constants/Route.constants";
import { REQUEST_STATUS, FILE_TYPES, FILE_STATUS, LOCAL_STORAGE } from "Constants/global.constants";
import _ from "lodash";
import {
  DashboardSubScreenLayout,
  Loader,
  doesEventContainsFiles,
  bytesToGB,
  isMobile,
  getItemFromLocalStorage,
} from "Utils";
import FileDownloadModal from "Utils/Components/File/FileDownloadModal.component";
import FileUploadModal from "Utils/Components/File/FileUploadModal/FileUploadModal.component";
import ProcessPaymentModal from "Utils/Components/Payment/ProcessPaymentModal/ProcessPaymentModal.component";
import {
  IconButton,
  TextButton,
  HoverableTooltip,
  BoardingModal,
  ConfirmationModal,
  Icon,
  DivButton,
  SearchInput,
} from "UI";
import TeamsFilesOnboardingModal from "Components/Workstation/Modals/TeamsFilesOnboardingModal.component";
import {
  ORGANIZATION_FILES_DUMMY_ARRAY,
  ORGANIZATION_FILES_DUMMY_CURRENT_FOLDER,
  ORGANIZATION_FILES_DUMMY_DATA,
  ORGANIZATION_FILES_POP_UP_POSITION,
} from "Constants/teamsOnboarding.constants";
import { OrganizationFolderInfo } from "./Components/EmptyFolder.component";
import OrganizationFileBreadcrumb from "./Components/FileBreadcrumb/FileBreadcrumb.component";
import FileManagerTable from "./Components/FileManagerTable/FileManagerTable.component";
import FileMoveModal from "./Components/FileMoveModal/FileMoveModal.component";
import OrganizationFileRenameModal from "./Components/FileRenameModal/FileRenameModal.component";
import OrganizationFolderCreateModal from "./Components/FolderCreateModal/FolderCreateModal.component";
import OrganizationMenuPopUp from "./Components/MenuPopUp/MenuPopUp.component";
import UpgradeSharedFileStorageModal from "./Components/UpgradeSharedFileStorageModal/UpgradeSharedFileStorageModal.component";
import FileDuplicationModal from "./FileDuplicationModal.component";
import "./Files.styles.scss";

const SEARCH_INPUT_DEBOUNCE_TIME = 500;

const OrganizationFileActionButton = (props) => {
  const { iconName, action, content, buttonRef, disabled } = props;

  return (
    <HoverableTooltip content={!disabled && content}>
      <IconButton
        buttonRef={buttonRef}
        name={iconName}
        className="selected-file-actions"
        onClick={action}
        disabled={disabled}
      />
    </HoverableTooltip>
  );
};

const OrganizationFiles = (props) => {
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();

  const { searchFiles, loadFileContents, history, translate } = props;

  const { userFilesCTX, moveFilesCTX, uploadFilesCTX, downloadFileCTX, deleteFileCTX, renameFileCTX, createFolderCTX } =
    useSelector((state) => state.files) || {};

  const { productOnboardingCTX } = useSelector((state) => state.account);
  const { file_created: checkCreatedFile } = productOnboardingCTX?.data?.teams || {};

  const { organization, getSeatsMeCTX } = useSelector((state) => state.organizations) || {};
  const { account } = useSelector((state) => state.account) || {};

  const { contents, hash, currentFolder, path, teamFolderInfo, totalStorage, usedStorage } = userFilesCTX;
  const { organization_admin: organizationAdmin } = account?.attributes || {};

  const hasSeat = getSeatsMeCTX?.seat || organizationAdmin;
  const hasAccess = hasSeat;
  const currentFolderId = currentFolder ? currentFolder.id : 0;

  const moreButtonRef = useRef(null);
  const fileManagerContainerRef = useRef(null);
  const searchRequestTimeout = useRef(null);
  const [selectedFolderItems, setSelectedFolderItems] = useState([]);

  const selectedRowID = selectedFolderItems.length === 1 ? selectedFolderItems[0] : null;
  const selectedItem = _.find(contents, (item) => item.id === selectedRowID);
  const teamSharedFolderItem = _.find(contents, (item) => item?.attributes?.name === "Teams Shared Folder");
  const isIncludeTeamSharedFolder = selectedFolderItems.find((item) => item === teamSharedFolderItem?.id);
  const disableActions = !isIncludeTeamSharedFolder;

  const noMachine =
    (organizationAdmin && parseInt(teamFolderInfo?.total, 10) === 0) || (!organizationAdmin && !getSeatsMeCTX?.seat);

  const isSelectedItemNotDirectory =
    selectedItem &&
    selectedItem.attributes.content_type !== FILE_TYPES.directory &&
    selectedItem.attributes.content_type !== FILE_TYPES.root;

  const selectedItems = _.filter(contents, (item) => selectedFolderItems.includes(item.id));

  const deleteEnabled = useMemo(() => {
    return selectedItems.every((item) => item.attributes.deletable);
  }, [selectedItems]);

  const [searchInput, setSearchInput] = useState("");
  const [filteredFiles, setFilteredFiles] = useState(contents);
  const searchedFiles = searchInput ? filteredFiles : null;
  const [popUpPosition, setPopUpPosition] = useState(null);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showRenameFileModal, setShowRenameFileModal] = useState(false);
  const [showFileDownloadModal, setShowFileDownloadModal] = useState(false);
  const [showFileMoveModal, setShowFileMoveModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showProcessPaymentModal, setShowProcessPaymentModal] = useState(false);
  const [showFileDeleteConfirmationModal, setShowFileDeleteConfirmationModal] = useState(false);
  const [showUpgradeSharedFileStorageModal, setShowUpgradeSharedFileStorageModal] = useState(false);
  const [showTeamsFilesOnboardingModal, setShowTeamsFilesOnboardingModal] = useState(null);
  const [onboardingModalCurrentStep, setOnboardingModalCurrentStep] = useState(0);

  const disableUpload = currentFolder?.id === 0 && pathName === routes.organizationAdminFiles;
  const useDummyBreadCrumb = onboardingModalCurrentStep >= 1 && showTeamsFilesOnboardingModal;
  const pending = [
    !showFileMoveModal && userFilesCTX.status,
    userFilesCTX.searchStatus,
    uploadFilesCTX.status,
    downloadFileCTX.status,
    deleteFileCTX.status,
    renameFileCTX.status,
  ].includes(REQUEST_STATUS.PENDING);

  useEffect(() => {
    dispatch(getOrganizationPlanAPI());
  }, []);

  useEffect(() => {
    const onDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (disableUpload || showFileUploadModal || !doesEventContainsFiles(event) || noMachine) return;

      setShowFileUploadModal(true);
    };

    window.addEventListener("dragover", onDragOver);

    return () => {
      window.removeEventListener("dragover", onDragOver);
      clearTimeout(searchRequestTimeout.current);
    };
  }, [disableUpload, noMachine]);

  useEffect(() => {
    // If hash changes filter again
    if (searchInput && contents) {
      const searchString = searchInput.toLowerCase();
      const filteredContents = _.filter(contents, (file) => file.attributes.name.toLowerCase().includes(searchString));
      setFilteredFiles(filteredContents);
    }
  }, [hash]);

  useEffect(() => {
    if (downloadFileCTX.status === REQUEST_STATUS.PENDING) {
      setShowFileDownloadModal(true);
    }
  }, [downloadFileCTX.status]);

  useEffect(() => {
    if (
      showTeamsFilesOnboardingModal === null &&
      productOnboardingCTX.status === REQUEST_STATUS.SUCCESS &&
      organizationAdmin
    ) {
      const shouldDisplayAfterCreationOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showTeamsFilesOnboardingModal, true);

      setTimeout(() => {
        setShowTeamsFilesOnboardingModal(checkCreatedFile ? false : shouldDisplayAfterCreationOnboarding);
      }, 500);
    }
  }, [productOnboardingCTX.status]);

  const onUploadFileDrop = (files) => {
    if (files?.length > 0) {
      dispatch(uploadFilesToFolder({ files, currentFolderId: searchInput ? null : currentFolderId }));
    }
  };

  const goToFolder = (id) => {
    loadFileContents(id);
    setSelectedFolderItems([]);
  };

  const renameFileAction = () => {
    if (!hasAccess) return;

    setShowRenameFileModal(true);
    if (popUpPosition) setPopUpPosition(false);
  };

  const deleteFilesAction = () => {
    if (!isIncludeTeamSharedFolder) {
      selectedFolderItems.forEach((item) => {
        dispatch(deleteFile(item, currentFolderId));
      });
      setSelectedFolderItems([]);
    }
  };

  const moveFileAction = () => {
    if (!hasAccess) return;

    setShowFileMoveModal(true);
    if (popUpPosition) setPopUpPosition(false);
  };

  const downloadFileAction = () => {
    dispatch(downloadFile(selectedItem.id, selectedItem.attributes.name, selectedItem.attributes.size));
    if (popUpPosition) setPopUpPosition(false);
  };

  const handleSearchInputChange = (event) => {
    // if (organization.attributes.isInGracePeriod) return;

    setSelectedFolderItems([]);
    const searchValue = event.target.value;
    setSearchInput(searchValue);

    clearTimeout(searchRequestTimeout.current);
    if (searchValue) {
      const searchString = searchValue.toLowerCase();
      const filteredContents = _.filter(contents, (file) => file.attributes.name.toLowerCase().includes(searchString));
      setFilteredFiles(filteredContents);
      searchRequestTimeout.current = setTimeout(
        () => searchFiles(currentFolderId, searchValue),
        SEARCH_INPUT_DEBOUNCE_TIME,
      );
    } else {
      setFilteredFiles(null);
      searchFiles(currentFolderId, searchValue);
    }
  };

  const handleRightClick = (event, item) => {
    event.preventDefault();

    if (organization.attributes?.isInGracePeriod) return;

    setSelectedFolderItems([item.id]);

    if ((selectedItem || item) && selectedFolderItems.length <= 1 && item.type !== FILE_TYPES.root && disableActions) {
      setPopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
    }
  };

  const handleMoreButtonClick = () => {
    const buttonRect = moreButtonRef.current.getBoundingClientRect();
    const position = { x: buttonRect.right, y: buttonRect.bottom, top: buttonRect.top };

    if (!popUpPosition && popUpPosition !== false) {
      setPopUpPosition(position);
    } else {
      setPopUpPosition(null);
    }
  };

  const showUsageInfo = () => {
    const used = bytesToGB(organizationAdmin ? teamFolderInfo?.in_use : usedStorage || 0).toFixed(2);
    const total = bytesToGB(organizationAdmin ? teamFolderInfo?.total : totalStorage || 0);

    return `${used}  /  ${total} GB`;
  };

  const isDuplicateFilesExist = !_.isEmpty(
    _.filter(uploadFilesCTX.uploadFiles, (file) => file.isDuplicate && !file.progress),
  );

  const fileContent = () => {
    switch (userFilesCTX.status) {
      case REQUEST_STATUS.PENDING:
      case REQUEST_STATUS.SUCCESS: {
        if (!contents) {
          return <Loader />;
        }
        return (
          <>
            {searchInput.length > 0 ? (
              <h1 className="breadcrumb">{translate("fileSearch.results")}</h1>
            ) : (
              <OrganizationFileBreadcrumb
                currentFolder={
                  useDummyBreadCrumb && onboardingModalCurrentStep >= 1
                    ? ORGANIZATION_FILES_DUMMY_CURRENT_FOLDER
                    : currentFolder
                }
                path={useDummyBreadCrumb || onboardingModalCurrentStep >= 1 ? ORGANIZATION_FILES_DUMMY_ARRAY : path}
                goToFolder={goToFolder}
                onBackButton={() => {
                  goToFolder(currentFolder.attributes.parent_id);
                }}
              />
            )}

            {contents && contents.length > 0 ? (
              <FileManagerTable
                // use two conditions in below part.
                contents={
                  useDummyBreadCrumb || (onboardingModalCurrentStep >= 1 && showTeamsFilesOnboardingModal)
                    ? ORGANIZATION_FILES_DUMMY_DATA
                    : searchedFiles || contents
                }
                onRightClick={handleRightClick}
                goToFolder={goToFolder}
                selectedRowID={selectedRowID}
                selectedFolderItems={selectedFolderItems}
                setSelectedFolderItems={setSelectedFolderItems}
                showTeamsFilesOnboardingModal={showTeamsFilesOnboardingModal}
                translate={translate}
              />
            ) : (
              <OrganizationFolderInfo currentFolder={currentFolder} translate={translate} />
            )}
          </>
        );
      }
      case REQUEST_STATUS.FAILURE: {
        return "";
      }
      default:
        return <Loader />;
    }
  };

  const selectedFolderName = () => {
    if (selectedFolderItems?.length === 1) {
      return _.find(userFilesCTX.contents, { id: selectedFolderItems[0] }).attributes.name;
    }
    return translate("selectedFolder.description");
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("header.title")}
      headerDescription={translate("header.description")}
      headerRightContent={
        <>
          <div>
            <p>{showUsageInfo()}</p>
          </div>
          {organizationAdmin && (
            <TextButton
              text={translate("header.headerAction")}
              color="baby-blue"
              onClick={() => {
                setShowUpgradeSharedFileStorageModal(true);
              }}
            />
          )}
        </>
      }
      waitFor={[userFilesCTX.status !== REQUEST_STATUS.PENDING, getSeatsMeCTX.status !== REQUEST_STATUS.PENDING]}
      pending={pending}
      translate={translate}
    >
      <div
        ref={fileManagerContainerRef}
        className="organization-file-manager-container"
        disabled={!hasAccess}
        onDrop={onUploadFileDrop}
      >
        <div className="file-manager-actions-bar">
          <div className="file-manager-left-actions">
            <SearchInput
              placeholder={translate("fileSearch.placeHolder")}
              iconName="search"
              color="white"
              value={searchInput}
              onChange={handleSearchInputChange}
            />

            {/* eslint-disable  */}
            <div className="folder-actions">
              <HoverableTooltip content="Add Folder" disabled={disableUpload}>
                <DivButton
                  className="left-action-button"
                  onClick={() => {
                    if (showTeamsFilesOnboardingModal) {
                      return;
                    }
                    if (!hasAccess) return;
                    setShowCreateFolderModal(true);
                  }}
                  disabled={showTeamsFilesOnboardingModal ? false : disableUpload}
                >
                  <IconButton name="folder-create" />
                </DivButton>
              </HoverableTooltip>
              <HoverableTooltip content="Upload File" disabled={disableUpload}>
                <DivButton
                  className="left-action-button"
                  onClick={() => {
                    if (showTeamsFilesOnboardingModal) {
                      return;
                    }
                    if (!hasAccess) return;
                    setShowFileUploadModal(true);
                  }}
                  disabled={showTeamsFilesOnboardingModal ? false : disableUpload}
                >
                  <IconButton name="files-upload" color="gray-2" />
                </DivButton>
              </HoverableTooltip>
            </div>
            {/* eslint-enable  */}
          </div>
          <div className="file-manager-right-actions">
            {
              // selectedItem && selectedItem.type !== FILE_TYPES.root
              selectedFolderItems.length > 0 && (
                <>
                  <div className="vertical-divider" />
                  {disableActions && (
                    <OrganizationFileActionButton
                      iconName="trash"
                      content="Delete"
                      action={() => {
                        setShowFileDeleteConfirmationModal(true);
                      }}
                      disabled={!deleteEnabled}
                    />
                  )}
                  {
                    <div className="select-file">
                      <Icon name="file-select" />
                      {Boolean(selectedFolderItems) && <p>{selectedFolderItems.length} Selected</p>}
                    </div>
                  }
                  {isSelectedItemNotDirectory && selectedFolderItems.length <= 1 && (
                    <OrganizationFileActionButton
                      iconName="file-download"
                      content="Download"
                      action={downloadFileAction}
                    />
                  )}
                  {/* <FileActionButton iconName="share" content="Share" /> */}
                  {disableActions && (
                    <OrganizationFileActionButton
                      buttonRef={moreButtonRef}
                      iconName="more"
                      content="More"
                      action={(event) => {
                        event.preventDefault();
                        handleMoreButtonClick();
                      }}
                    />
                  )}
                </>
              )
            }
          </div>
        </div>
        {fileContent()}
        {(popUpPosition || (useDummyBreadCrumb && onboardingModalCurrentStep === 2)) && (
          <OrganizationMenuPopUp
            referencePosRef={fileManagerContainerRef}
            position={useDummyBreadCrumb ? ORGANIZATION_FILES_POP_UP_POSITION : popUpPosition}
            moveFile={moveFileAction}
            deleteFile={() => {
              setShowFileDeleteConfirmationModal(true);
              setPopUpPosition(false);
            }}
            deleteFileDisabled={!deleteEnabled}
            renameFile={renameFileAction}
            downloadFile={isSelectedItemNotDirectory && downloadFileAction}
            setShowMenuPopUp={setPopUpPosition}
            selectedFolderItems={selectedFolderItems}
            disable={isIncludeTeamSharedFolder}
            translate={translate}
            showTeamsFilesOnboardingModal={showTeamsFilesOnboardingModal}
          />
        )}
        {showTeamsFilesOnboardingModal && !noMachine && (
          <TeamsFilesOnboardingModal
            setCurrentStep={setOnboardingModalCurrentStep}
            onExit={() => {
              setShowTeamsFilesOnboardingModal(false);
              localStorage.setItem(LOCAL_STORAGE.showTeamsFilesOnboardingModal, false);
            }}
            translate={translate}
          />
        )}
      </div>
      {noMachine && (
        <BoardingModal
          headerImages={[VagonSubscriptionImage]}
          headerTexts={[translate("organizationFiles.needSubscriptionModal.header")]}
          descriptions={[translate("organizationFiles.needSubscriptionModal.description")]}
          buttonTexts={[translate("organizationFiles.needSubscriptionModal.buttonText")]}
          buttonAction={() => {
            history.push(routes.organizationAdminHome);
          }}
          blue
        />
      )}
      {organization?.attributes?.isInGracePeriod && !showProcessPaymentModal && (
        <BoardingModal
          headerImages={[VagonGracePeriodImage]}
          headerTexts={[translate("modals.teamGracePeriod.header")]}
          buttonTexts={[translate("modals.teamGracePeriod.buttonText")]}
          descriptions={[translate("modals.teamGracePeriod.description")]}
          buttonAction={() => {
            setShowProcessPaymentModal(true);
          }}
          buttonHidden
        />
      )}
      {showProcessPaymentModal && (
        <ProcessPaymentModal setShouldShowProcessPaymentModal={setShowProcessPaymentModal} translate={translate} />
      )}
      {showCreateFolderModal && (
        <OrganizationFolderCreateModal
          createFolderCTX={createFolderCTX}
          createFolder={(newFolderName) => {
            dispatch(createFolder(newFolderName, searchInput ? null : currentFolderId));
          }}
          setShowCreateFolderModal={setShowCreateFolderModal}
          translate={translate}
        />
      )}
      {showRenameFileModal && (
        <OrganizationFileRenameModal
          renameFileCTX={renameFileCTX}
          renameFile={(newFileName) => {
            dispatch(renameFile(selectedFolderItems[0], newFileName, currentFolderId));
          }}
          fileType={selectedItem.attributes.content_type}
          previousName={selectedItem.attributes.name}
          setShowRenameFileModal={setShowRenameFileModal}
          translate={translate}
        />
      )}
      {showFileMoveModal && (
        <FileMoveModal
          moveFile={(newFolderId) => {
            selectedFolderItems.forEach((item) => {
              dispatch(moveFile(item, newFolderId, currentFolderId));
            });
            setShowFileMoveModal(false);
            loadFileContents(currentFolderId, true);
            setSelectedFolderItems([]);
          }}
          loadFileContents={loadFileContents}
          moveFilesCTX={moveFilesCTX}
          selectedFileIds={selectedFolderItems}
          setShowFileMoveModal={(showFileMoveModal) => {
            if (!showFileMoveModal) loadFileContents(currentFolderId, true);
            setShowFileMoveModal(showFileMoveModal);
          }}
          translate={translate}
        />
      )}
      {!isDuplicateFilesExist && showFileUploadModal && (
        <FileUploadModal
          setShowFileUploadModal={setShowFileUploadModal}
          files={uploadFilesCTX.uploadFiles}
          onFileDrop={onUploadFileDrop}
          searchInput={searchInput}
          cancelFileUpload={(fileName) => {
            dispatch(cancelFileUpload(fileName));
          }}
          currentFolderId={currentFolderId}
          contents={contents}
          translate={translate}
        />
      )}
      {showFileDownloadModal && (
        <FileDownloadModal
          fileName={downloadFileCTX.name}
          fileSize={downloadFileCTX.size}
          downloadUrl={downloadFileCTX.url}
          setShowDownloadModal={setShowFileDownloadModal}
          translate={translate}
        />
      )}
      {isDuplicateFilesExist &&
        _.map(
          _.filter(uploadFilesCTX.uploadFiles, (file) => file.isDuplicate && file.status === FILE_STATUS.FAILURE),
          (file, fileIndex) => {
            return (
              <FileDuplicationModal
                key={fileIndex}
                file={file}
                uploadFilesToFolder={(e) => {
                  dispatch(uploadFilesToFolder(e));
                }}
                cancelFileUpload={(e) => {
                  dispatch(cancelFileUpload(e));
                }}
                currentFolderId={currentFolderId}
                translate={translate}
              />
            );
          },
        )}
      {showFileDeleteConfirmationModal && (
        <ConfirmationModal
          closeOnOverlayClick
          closeAction={() => setShowFileDeleteConfirmationModal(false)}
          headerText={translate("modals.deleteFile.confirm.header")}
          descriptionText={translate("modals.deleteFile.confirm.description", {
            fileName: selectedFolderName(),
          })}
          confirmText={translate("modals.deleteFile.confirm.confirm")}
          confirmAction={() => {
            deleteFilesAction();
            setShowFileDeleteConfirmationModal(false);
          }}
          secondaryText={translate("modals.deleteFile.confirm.secondary")}
          secondaryAction={() => setShowFileDeleteConfirmationModal(false)}
        />
      )}
      {showUpgradeSharedFileStorageModal && (
        <UpgradeSharedFileStorageModal
          translate={translate}
          onClose={() => {
            setShowUpgradeSharedFileStorageModal(false);
            dispatch(getFileStorageCapacity());
            dispatch(getOrganizationAPI());
          }}
          history={history}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default OrganizationFiles;
