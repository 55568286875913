import React from "react";
import { connect } from "react-redux";
import { getOrganizationAPI } from "Actions/Organization.actions";
import OrganizationAdminSettings from "Components/Organization/Admin/Settings/Settings.component";
import { getAccountAPI } from "Actions/Account.actions";

class SettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getAccountAPI, getOrganizationAPI } = this.props;
    getAccountAPI();
    getOrganizationAPI();
  }

  render() {
    return <OrganizationAdminSettings {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  organization: state.organizations,
});

const actionCreators = {
  getAccountAPI,
  getOrganizationAPI,
};

export default connect(mapStateToProps, actionCreators)(SettingsContainer);
