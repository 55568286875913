import React from "react";
import { Link } from "react-router-dom";
import routes from "Constants/Route.constants";
import { useSelector } from "react-redux";

const menuItemClassName = (path, checkValue, mobile) =>
  `menu-item ${mobile ? "" : "dark-navy"} ${path === checkValue ? "active" : ""}`;

const OrganizationMemberMenuItems = (props) => {
  const { path, setShowMenu, mobile, appsMenu } = props;
  const { account } = useSelector((state) => state.account);
  const { organization_member: organizationMember } = account?.attributes || {};

  const onLinkClick = () => {
    if (setShowMenu) {
      setShowMenu(false);
    }
  };

  return (
    <div className="menu-wrapper">
      <Link
        to={routes.organizationMemberHome}
        className={menuItemClassName(path, routes.organizationMemberHome, mobile)}
        onClick={onLinkClick}
      >
        Team Computer
      </Link>
      {!mobile && <div className="divider" />}
      <Link
        to={routes.organizationMemberFiles}
        className={menuItemClassName(path, routes.organizationMemberFiles, mobile)}
        onClick={onLinkClick}
      >
        Files
      </Link>

      <Link
        to={routes.organizationMemberSessions}
        className={menuItemClassName(path, routes.organizationMemberSessions, mobile)}
        onClick={onLinkClick}
      >
        Sessions
      </Link>

      <Link
        to={routes.organizationMemberBilling}
        className={menuItemClassName(path, routes.organizationMemberBilling, mobile)}
        onClick={onLinkClick}
      >
        Payments
      </Link>

      <Link
        to={routes.organizationMemberSettings}
        className={menuItemClassName(path, routes.organizationMemberSettings, mobile)}
        onClick={onLinkClick}
      >
        Settings
      </Link>
      {organizationMember && (
        <Link
          to={routes.organizationMemberTeamInfo}
          className={menuItemClassName(path, routes.organizationMemberTeamInfo, mobile)}
          onClick={onLinkClick}
        >
          Team
        </Link>
      )}
      <div className="menu-item-bottom">
        {appsMenu({ purpleBackground: !mobile && true })}
        <Link
          to={routes.login}
          className={menuItemClassName(path, routes.login, mobile)}
          onClick={() => {
            props.logoutAPI(props.account.accessToken);
            onLinkClick();
          }}
        >
          Sign out
        </Link>
        {!mobile && <div className="divider" />}
        <a
          className={`menu-item support ${mobile ? "" : "dark-navy"}`}
          href="http://help.vagon.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          Help / Support
        </a>
      </div>
    </div>
  );
};

export default OrganizationMemberMenuItems;
