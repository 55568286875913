import React from "react";
import { connect } from "react-redux";
import { getRewardsAPI, getRewardsStatusAPI, claimRewardsAPI, inviteAPI } from "Actions/Referral.actions";
import ReferralComponent from "Components/Dashboard/Account/Referral/Referral.component";

class ReferralContainer extends React.Component {
  componentDidMount() {
    const { account, getRewardsAPI, getRewardsStatusAPI } = this.props;
    getRewardsAPI(account.accessToken);
    getRewardsStatusAPI(account.accessToken);
  }

  inviteWithEmail = (email, templateId) => {
    const { account, inviteAPI } = this.props;
    inviteAPI(account.accessToken, email, templateId);
  };

  render() {
    return <ReferralComponent inviteWithEmail={this.inviteWithEmail} {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  referral: state.referral,
});

const actionCreators = {
  getRewardsAPI,
  getRewardsStatusAPI,
  claimRewardsAPI,
  inviteAPI,
};

export default connect(mapStateToProps, actionCreators)(ReferralContainer);
