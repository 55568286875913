import Logger from "Utils/Helpers/logger.helpers";

// let GoogleTagManager = window.dataLayer;
const TIMES = {};
export default class Tracker {
  static event(event) {
    return event;
  }

  static time(event) {
    const currentTime = Date.now();
    if (!event.start) {
      const timeDif = currentTime - TIMES[event.type];
      Logger.log(`Event ${event.type} took  ${timeDif / 1000} seconds, ${timeDif} ms`);
      delete TIMES[event.type];

      return timeDif;
    }
    TIMES[event.type] = currentTime;
    return 0;
  }
}
