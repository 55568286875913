import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Translate } from "react-localize-redux";

import { DashboardSubScreenLayout, Loader, doesEventContainsFiles } from "Utils";
import { IconButton, BoxInput, BoardingModal, HoverableTooltip } from "UI";

import EmptyFolderImage from "Assets/images/fileManager/cloud-empty-1.png";
import VagonSubscriptionImage from "Assets/images/fileManager/file-subscription.png";
import VagonGracePeriodImage from "Assets/images/fileManager/file-grace.jpg";

import HowToVideo from "Assets/videos/files-how.webm";
import HowToVideoMp4 from "Assets/videos/files-how.mp4";

import { REQUEST_STATUS, FILE_TYPES } from "Constants/global.constants";

import FileUploadModal from "Utils/Components/File/FileUploadModal/FileUploadModal.component";
import FileDownloadModal from "Utils/Components/File/FileDownloadModal.component";

import FileManagerTable from "./Components/FileManagerTable/FileManagerTable.component";
import FileMoveModal from "./Components/FileMoveModal/FileMoveModal.component";

import FileRenameModal from "./Components/FileRenameModal/FileRenameModal.component";
import FolderCreateModal from "./Components/FolderCreateModal/FolderCreateModal.component";
import MenuPopUp from "./Components/MenuPopUp/MenuPopUp.component";
import FileBreadcrumb from "./Components/FileBreadcrumb/FileBreadcrumb.component";

import "./Files.styles.scss";
import "./FilesMobile.styles.scss";

const SEARCH_INPUT_DEBOUNCE_TIME = 500;

const EmptyFolder = (props) => {
  const { image, video, title, info } = props;
  return (
    <div className="empty-folder">
      {image && <img src={image} alt="Nothing to show" />}
      {!image && video && (
        <video loop autoPlay muted>
          <source src={HowToVideo} type="video/webm" />
          <source src={HowToVideoMp4} type="video/mp4" />
        </video>
      )}
      <h1>{title}</h1>
      {info}
    </div>
  );
};

const MoreButton = (props) => {
  const {
    id,
    rowData,
    fileManagerContainerRef,
    handleMoreButtonClick,
    popUpPosition,
    moveFileAction,
    deleteFilesAction,
    renameFileAction,
    downloadFileAction,
    isSelectedItemNotDirectory,
    setPopUpPosition,
    selectedFolderItems,
    setSelectedFolderItems,
    disablePopUpMenu,
    translate,
  } = props;
  const buttonRef = useRef();

  return (
    <>
      <IconButton
        buttonRef={buttonRef}
        name="more"
        className="selected-file-actions"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setSelectedFolderItems([rowData.id]);
          handleMoreButtonClick(buttonRef, id);
        }}
      />
      {id === selectedFolderItems[0] && popUpPosition && (
        <MenuPopUp
          referencePosRef={fileManagerContainerRef}
          position={popUpPosition}
          moveFile={moveFileAction}
          deleteFile={deleteFilesAction}
          renameFile={renameFileAction}
          downloadFile={isSelectedItemNotDirectory && downloadFileAction}
          setShowMenuPopUp={setPopUpPosition}
          selectedFolderItems={selectedFolderItems}
          disable={disablePopUpMenu}
          translate={translate}
        />
      )}
    </>
  );
};

const FilesComponentMobile = (props) => {
  const {
    account,
    workstations,
    handleCreateComputerClick,
    files,
    searchFiles,
    loadFileContents,
    deleteFile,
    renameFile,
    moveFile,
    downloadFile,
    createFolder,
    uploadFilesToFolder,
    cancelFileUpload,
    clearUploadedFile,
    translate,
  } = props;

  const { userFilesCTX, moveFilesCTX, uploadFilesCTX, downloadFileCTX, deleteFileCTX, renameFileCTX, createFolderCTX } =
    files;

  const { contents, hash, currentFolder, path } = userFilesCTX;

  const hasWorkstation = !!(workstations.workstations && workstations.workstations[0]);
  const hasAccess = hasWorkstation && !account.account.isInGracePeriod;

  const currentFolderId = currentFolder ? currentFolder.id : 0;

  const fileManagerContainerRef = useRef(null);
  const searchRequestTimeout = useRef(null);

  const [selectedFolderItems, setSelectedFolderItems] = useState([]);
  const selectedRowID = selectedFolderItems.length === 1 ? selectedFolderItems[0] : null;
  const selectedItem = _.find(contents, (item) => item.id === selectedRowID);
  const isSelectedItemNotDirectory = selectedItem && selectedItem.type !== FILE_TYPES.directory;
  const teamSharedFolderItem = _.find(contents, (item) => item?.attributes?.name === "Teams Shared Folder");
  const isIncludeTeamSharedFolder = selectedFolderItems.find((item) => item === teamSharedFolderItem?.id);
  const disablePopUpMenu = isIncludeTeamSharedFolder;

  const [searchInput, setSearchInput] = useState("");
  const [filteredFiles, setFilteredFiles] = useState(contents);
  const searchedFiles = searchInput ? filteredFiles : null;

  const [popUpPosition, setPopUpPosition] = useState(null);

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showRenameFileModal, setShowRenameFileModal] = useState(false);
  const [showFileDownloadModal, setShowFileDownloadModal] = useState(false);
  const [showFileMoveModal, setShowFileMoveModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showProcessPaymentModal, setShowProcessPaymentModal] = React.useState(false);

  const pending = [
    !showFileMoveModal && userFilesCTX.status,
    userFilesCTX.searchStatus,
    uploadFilesCTX.status,
    downloadFileCTX.status,
    deleteFileCTX.status,
    renameFileCTX.status,
  ].includes(REQUEST_STATUS.PENDING);

  useEffect(() => {
    const onDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!showFileUploadModal && doesEventContainsFiles(event)) {
        setShowFileUploadModal(true);
      }
    };

    window.addEventListener("dragover", onDragOver);

    return () => {
      window.removeEventListener("dragover", onDragOver);
      clearTimeout(searchRequestTimeout.current);
    };
  }, []);

  useEffect(() => {
    // If hash changes filter again
    if (searchInput && contents) {
      const searchString = searchInput.toLowerCase();
      const filteredContents = _.filter(contents, (file) => file.attributes.name.toLowerCase().includes(searchString));
      setFilteredFiles(filteredContents);
    }
  }, [hash]);

  useEffect(() => {
    if (userFilesCTX.status === REQUEST_STATUS.SUCCESS) {
      setSearchInput("");
    }
  }, [userFilesCTX.status]);

  useEffect(() => {
    if (downloadFileCTX.status === REQUEST_STATUS.PENDING) {
      setShowFileDownloadModal(true);
    }
  }, [downloadFileCTX.status]);

  const onUploadFileDrop = (files) => {
    if (files.length > 0) {
      uploadFilesToFolder({ files, currentFolderId: searchInput ? null : currentFolderId });
    }
  };

  const goToFolder = (id) => {
    loadFileContents(id);
    setSelectedFolderItems([]);
  };

  const renameFileAction = (event) => {
    if (!hasAccess) return;
    event.preventDefault();
    event.stopPropagation();

    setShowRenameFileModal(true);
    if (popUpPosition) setPopUpPosition(false);
  };

  const deleteFilesAction = (event) => {
    event.stopPropagation();
    selectedFolderItems.forEach((item) => {
      deleteFile(item, currentFolderId);
    });
    setSelectedFolderItems([]);
  };

  const moveFileAction = (event) => {
    if (!hasAccess) return;

    event.preventDefault();
    event.stopPropagation();

    setShowFileMoveModal(true);
    if (popUpPosition) setPopUpPosition(false);
  };

  const downloadFileAction = () => {
    downloadFile(selectedFolderItems, selectedItem.fileName, selectedItem.fileSize);
    if (popUpPosition) setPopUpPosition(false);
  };

  const handleSearchInputChange = (event) => {
    if (account.account.isInGracePeriod) return;

    setSelectedFolderItems([]);
    const searchValue = event.target.value;
    setSearchInput(searchValue);

    clearTimeout(searchRequestTimeout.current);

    if (searchValue) {
      const searchString = searchValue.toLowerCase();
      const filteredContents = _.filter(contents, (file) => file.attributes.name.toLowerCase().includes(searchString));
      setFilteredFiles(filteredContents);
      searchRequestTimeout.current = setTimeout(
        () => searchFiles(currentFolderId, searchValue),
        SEARCH_INPUT_DEBOUNCE_TIME,
      );
    } else {
      setFilteredFiles(null);
      // loadFileContents(currentFolderId, false, true);
      searchFiles(currentFolderId, searchValue);
    }
  };

  const handleRightClick = (event, item) => {
    event.preventDefault();
    if (account.account.isInGracePeriod) return;

    setSelectedFolderItems([item.id]);

    if (selectedItem || item) {
      setPopUpPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    onUploadFileDrop(event);
  };

  const handleMoreButtonClick = (moreButtonRef, id) => {
    const buttonRect = moreButtonRef.current.getBoundingClientRect();
    const position = { x: buttonRect.right, y: buttonRect.bottom, top: buttonRect.top };

    if (id === selectedFolderItems) {
      setPopUpPosition(null);
    } else {
      setPopUpPosition(position);
    }
  };

  const fileContent = () => {
    switch (userFilesCTX.status) {
      case REQUEST_STATUS.PENDING:
      case REQUEST_STATUS.SUCCESS: {
        if (!contents) {
          return <Loader />;
        }
        return (
          <>
            <FileBreadcrumb
              currentFolder={currentFolder}
              path={path}
              onBackButton={() => {
                goToFolder(currentFolder.attributes.parent_id);
              }}
              goToFolder={goToFolder}
            />
            {contents && contents.length > 0 ? (
              <FileManagerTable
                contents={searchedFiles || contents}
                keys={["name", "more"]}
                headers={[]}
                onRightClick={handleRightClick}
                goToFolder={goToFolder}
                selectedFolderItems={selectedFolderItems}
                setSelectedFolderItems={setSelectedFolderItems}
                translate={translate}
                moreButton={(id, rowData) => {
                  return (
                    <MoreButton
                      id={id}
                      rowData={rowData}
                      fileManagerContainerRef={fileManagerContainerRef}
                      isSelectedItemNotDirectory={isSelectedItemNotDirectory}
                      handleMoreButtonClick={handleMoreButtonClick}
                      popUpPosition={popUpPosition}
                      moveFileAction={moveFileAction}
                      deleteFilesAction={deleteFilesAction}
                      renameFileAction={renameFileAction}
                      downloadFileAction={downloadFileAction}
                      selectedItem={selectedItem}
                      selectedFolderItems={selectedFolderItems}
                      setSelectedFolderItems={setSelectedFolderItems}
                      setPopUpPosition={setPopUpPosition}
                      disablePopUpMenu={disablePopUpMenu}
                      translate={translate}
                    />
                  );
                }}
                minified
                white
                singleClick
              />
            ) : (
              <EmptyFolder
                image={currentFolder && currentFolder.attributes && EmptyFolderImage}
                video={HowToVideo}
                title={translate("emptyFolder.header")}
                // info={translate("emptyFolder.info")}
                info={
                  <Translate
                    id="emptyFolder.info"
                    options={{
                      renderInnerHtml: true,
                    }}
                  />
                }
              />
            )}
          </>
        );
      }
      case REQUEST_STATUS.FAILURE: {
        return (
          <EmptyFolder
            image={EmptyFolderImage}
            title={translate("emptyFolder.header")}
            info={translate("emptyFolder.info")}
          />
        );
      }
      default:
        return <Loader />;
    }
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("header.title")}
      headerDescription={translate("header.description")}
      waitFor={[workstations.getWorkstationsCTX.status, account.getAccountCTX.status]}
      pending={pending}
      translate={translate}
    >
      <div
        ref={fileManagerContainerRef}
        className="file-manager-container mobile"
        disabled={!hasWorkstation || account.account.isInGracePeriod}
        onDrop={handleDrop}
      >
        <div className="file-manager-actions-bar">
          <div className="file-manager-left-actions">
            <div className="search-input">
              <BoxInput
                placeholder={translate("fileSearch.placeHolder")}
                iconName="search"
                color="purple-light"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
            </div>
            <HoverableTooltip content="Add Folder">
              <div
                className="left-action-button"
                onClick={() => {
                  if (!hasAccess) return;
                  setShowCreateFolderModal(true);
                }}
              >
                <IconButton name="folder-create" />
              </div>
            </HoverableTooltip>
            <HoverableTooltip content="Upload File">
              <div
                className="left-action-button"
                onClick={() => {
                  if (!hasAccess) return;
                  setShowFileUploadModal(true);
                }}
              >
                <IconButton name="files-upload" color="gray-2" />
              </div>
            </HoverableTooltip>
          </div>
        </div>
        {fileContent()}
      </div>
      {!hasWorkstation && (
        <BoardingModal
          headerImages={[VagonSubscriptionImage]}
          headerTexts={[translate("modals.needSubscription.header")]}
          buttonTexts={[translate("modals.needSubscription.buttonText")]}
          descriptions={[translate("modals.needSubscription.description")]}
          buttonAction={handleCreateComputerClick}
          blue
        />
      )}
      {account.account.isInGracePeriod && !showProcessPaymentModal && (
        <BoardingModal
          headerImages={[VagonGracePeriodImage]}
          headerTexts={[translate("modals.gracePeriod.header")]}
          buttonTexts={[translate("modals.gracePeriod.buttonText")]}
          descriptions={[translate("modals.gracePeriod.description")]}
          buttonAction={() => {
            setShowProcessPaymentModal(true);
          }}
          blue
        />
      )}

      {showCreateFolderModal && (
        <FolderCreateModal
          createFolderCTX={createFolderCTX}
          createFolder={(newFolderName) => {
            createFolder(newFolderName, searchInput ? null : currentFolderId);
          }}
          setShowCreateFolderModal={setShowCreateFolderModal}
          translate={translate}
          mobile
        />
      )}
      {showRenameFileModal && (
        <FileRenameModal
          renameFileCTX={renameFileCTX}
          renameFile={(newFileName) => {
            renameFile(selectedFolderItems[0], newFileName, currentFolderId);
          }}
          fileType={selectedItem.attributes.content_type}
          previousName={selectedItem.attributes.name}
          setShowRenameFileModal={setShowRenameFileModal}
          translate={translate}
          mobile
        />
      )}

      {showFileMoveModal && (
        <FileMoveModal
          moveFile={(newFolderId) => {
            selectedFolderItems.forEach((item) => {
              moveFile(item, newFolderId, currentFolderId);
            });
            setShowFileMoveModal(false);
            loadFileContents(currentFolderId, true);
            setSelectedFolderItems([]);
          }}
          loadFileContents={loadFileContents}
          moveFilesCTX={moveFilesCTX}
          selectedFileId={selectedFolderItems}
          setShowFileMoveModal={(showFileMoveModal) => {
            if (!showFileMoveModal) loadFileContents(currentFolderId, true);
            setShowFileMoveModal(showFileMoveModal);
          }}
          translate={translate}
          mobile
        />
      )}
      {showFileUploadModal && (
        <FileUploadModal
          clearUploadedFile={clearUploadedFile}
          setShowFileUploadModal={setShowFileUploadModal}
          files={uploadFilesCTX.uploadFiles}
          cancelFileUpload={cancelFileUpload}
          onFileDrop={onUploadFileDrop}
          translate={translate}
          mobile
        />
      )}
      {showFileDownloadModal && (
        <FileDownloadModal
          fileName={downloadFileCTX.name}
          fileSize={downloadFileCTX.size}
          downloadUrl={downloadFileCTX.url}
          setShowDownloadModal={setShowFileDownloadModal}
          translate={translate}
          mobile
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default FilesComponentMobile;
