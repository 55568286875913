import React from "react";
import AuthenticationHeader from "Utils/Components/Authentication/AuthenticationHeader/AuthenticationHeader.component";
import { Animation } from "Utils";
import AuthenticationLayout from "Utils/Components/Layouts/AuthenticationLayout/AuthenticationLayout";
import { Icon } from "UI";
import { REQUEST_STATUS } from "Constants/global.constants";
import AppStreamingLanding from "../Landing/AppStreamingLanding.component";

import "./SendTokenToPlugin.styles.scss";

const AppStreamingSendTokenToPlugin = (props) => {
  const { translate, status, showLanding } = props;

  return (
    <AuthenticationLayout {...props}>
      {showLanding ? (
        <AppStreamingLanding {...props} sendTokenToPlugin />
      ) : (
        <div className="send-token-to-plugin">
          <div className="vg-register-content form-container">
            <div className="vg-register-success-wrapper">
              <AuthenticationHeader title={translate(`sendTokenToPlugin.${status}.header`)} appStreaming />
              <div className="field-group">
                {status === REQUEST_STATUS.PENDING && <Icon name="loader" />}
                {status === REQUEST_STATUS.SUCCESS && (
                  <Animation type="successPurple" animationStyle={{ margin: "0 auto 0 -20px" }} />
                )}
                {status !== REQUEST_STATUS.PENDING && (
                  <>
                    <p className="description">{translate(`sendTokenToPlugin.${status}.description`)}</p>
                    <p className="vg-register-success-content">{translate(`sendTokenToPlugin.${status}.info`)}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </AuthenticationLayout>
  );
};

export default AppStreamingSendTokenToPlugin;
