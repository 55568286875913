import React from "react";
import { Modal, Table } from "UI";
import "./BundledAppListModal.styles.scss";

const BundledAppListModal = ({ selectedApp, translate, onClose }) => {
  const columns = [
    { name: "Id", weight: 0 },
    { name: translate("appStreamingDashboard.appList.columns.name"), weight: 40 },
  ];

  const { child_app_names: childAppNames } = selectedApp.attributes || {};

  const data = childAppNames?.map((name) => {
    return {
      id: name,
      name,
    };
  });

  return (
    <Modal
      className="bundled-app-list-modal"
      closeOnOverlayClick
      closeAction={onClose}
      topRightIcon="close"
      topRightIconAction={onClose}
      small
      centered
      headerText={translate("appStreamingDashboard.bundledAppList.header")}
      descriptionText={
        <>
          <div className="scrollable-table-container">
            <Table columns={columns} data={data} />
          </div>
        </>
      }
    />
  );
};

export default BundledAppListModal;
