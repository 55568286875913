import { REQUEST_STATUS } from "Constants/global.constants";

import * as ForgetPasswordResetConstants from "Constants/Authentication/ForgetPassword.constants";

const initialState = {
  forgetPasswordReset: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
};

export default function forgetPasswordResetCTX(state = initialState, action) {
  switch (action.type) {
    case ForgetPasswordResetConstants.FORGET_PASSWORD_RESET_CLEAR: {
      return initialState;
    }
    case ForgetPasswordResetConstants.FORGET_PASSWORD_RESET_API_PENDING: {
      return forgetPasswordResetAPIPending(state);
    }
    case ForgetPasswordResetConstants.FORGET_PASSWORD_RESET_API_SUCCESS: {
      return forgetPasswordResetAPISuccess(state);
    }
    case ForgetPasswordResetConstants.FORGET_PASSWORD_RESET_API_FAILURE: {
      return forgetPasswordResetAPIFailure(state, action);
    }
    default: {
      return state;
    }
  }
}

function forgetPasswordResetAPIPending(state) {
  return {
    ...state,
    status: REQUEST_STATUS.PENDING,
  };
}

function forgetPasswordResetAPISuccess(state) {
  return {
    ...state,
    status: REQUEST_STATUS.SUCCESS,
  };
}

function forgetPasswordResetAPIFailure(state, action) {
  return {
    ...state,
    status: REQUEST_STATUS.FAILURE,
    error: action.payload || true,
  };
}
