import React, { useEffect, useRef, useState } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { classnames, isMobile } from "Utils";

import "./HoverableTooltip.styles.scss";

const HoverableTooltip = (props) => {
  const {
    className: classNameFromProps,
    children,
    content,
    side,
    align,
    style,
    disabled,
    sideOffset = 5,
    onClose,
    open,
    arrow,
    avoidCollisions = true,
  } = props;

  const containerRef = useRef();
  const [displayToolTip, setDisplayToolTip] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDisplayToolTip(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={classnames(["vg-tooltip-hoverable", classNameFromProps])} style={style} ref={containerRef}>
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root
          onOpenChange={(e) => {
            if (onClose && !e) onClose(e);
          }}
          open={!isMobile ? open : displayToolTip}
        >
          <Tooltip.Trigger
            asChild
            className="tooltip-children"
            onClick={() => {
              if (isMobile) setDisplayToolTip(!displayToolTip);
            }}
          >
            <div>{children}</div>
          </Tooltip.Trigger>
          {content && !disabled && (
            <Tooltip.Content
              align={align}
              side={side}
              sideOffset={sideOffset}
              avoidCollisions={avoidCollisions}
              className="tooltip-content"
            >
              {content}
              {arrow && <Tooltip.Arrow className="tooltip-arrow" />}
            </Tooltip.Content>
          )}
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  );
};

export default HoverableTooltip;
