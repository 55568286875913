import React, { useState, useEffect } from "react";
import routes from "Constants/Route.constants";
import { LOCAL_STORAGE, VAGON_LANDING_PAGE_URL } from "Constants/global.constants";
import { Divider, IconButton, WorldMap, Icon, SectionScroll, RegionChangeModal, TextButton } from "UI";

import "./CreateComputerConnection.styles.scss";
import CreateComputerAction from "Components/Dashboard/CreateComputer/Components/CreateComputerAction/CreateComputerAction.component";
import ConnectionStrength from "Utils/Components/ConnectionStrength/ConnectionStrength.component";

import { saveItemToLocalStorage } from "Utils/Helpers/storage.helpers";

const CreateComputerConnection = (props) => {
  const { region, setRegion, regionsCTX, getSoftwares, history, translate } = props;

  const [regions, setRegions] = useState([]);
  const [isScrolling, setIsScrolling] = useState(false);
  const [showRegionChangeModal, setShowRegionChangeModal] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState();
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    if (regionsCTX.getRegionsCTX.data) {
      setRegions(regionsCTX.getRegionsCTX.data.map((region) => region.attributes.name));
    }
  }, [regionsCTX]);

  useEffect(() => {
    if (regions && selectedRegion) {
      if (selectedRegion !== region) {
        setShowRegionChangeModal(true);
      } else {
        setShowRegionChangeModal(false);
      }
    }
  }, [regions, selectedRegion]);

  useEffect(() => {
    if (isScrolling && showRegionChangeModal) {
      setShowRegionChangeModal(false);
      setSelectedRegion();
    }
  }, [isScrolling]);

  const onActionButtonClick = () => {
    saveItemToLocalStorage(LOCAL_STORAGE.createComputerConfigs, { region });
    getSoftwares();
    history.push(routes.createComputerPreInstall);
  };

  const isTestingPings = !region;
  const showRegionChange = showRegionChangeModal && region && selectedRegion && activeSection === 1;

  return (
    <div className="connection-container">
      <SectionScroll
        className="connection-content"
        isScrolling={isScrolling}
        setIsScrolling={setIsScrolling}
        setActiveSection={setActiveSection}
      >
        <ConnectionStrength regions={regions} setRegion={setRegion}>
          {({ connectionStrength, checkConnectionStrength }) => (
            <section className="connection-section" id="status">
              <h3>
                {translate(`connectionStatus.content.connectionStrength.${isTestingPings ? "testing" : "title"}`)}
              </h3>
              <div className="connection-status">
                {translate(`connectionStatus.content.connectionStrength.${connectionStrength}.title`)}
                {!isTestingPings && <IconButton name="reload" onClick={checkConnectionStrength} />}
              </div>
              <Divider withLogo isLoading={isTestingPings} />
              <h2>{translate("connectionStatus.content.connectionStrength.description")}</h2>
              <p>{translate(`connectionStatus.content.connectionStrength.${connectionStrength}.description`)}</p>
            </section>
          )}
        </ConnectionStrength>
        <section className="map-section mobile-only-hide" id="connection-map">
          <WorldMap region={region} onRegionClick={setSelectedRegion} country={regionsCTX.locationCTX.data} />
        </section>
      </SectionScroll>
      <CreateComputerAction
        hide={showRegionChange}
        disabled={!region}
        buttonText={translate("connectionStatus.action.button")}
        actionContent={
          !isScrolling && (
            <div className="scroll-page">
              {activeSection === 0 ? (
                <>
                  <p>{translate("connectionStatus.content.section.first.scrollText")}</p>
                  <Icon name="scroll-down" className="scroll" />
                </>
              ) : (
                <>
                  <TextButton
                    text="Region Pricings"
                    color="aqua-main"
                    onClick={() => window.open(`${VAGON_LANDING_PAGE_URL}/region-prices`, "_blank")}
                  />
                </>
              )}
            </div>
          )
        }
        onButtonClick={onActionButtonClick}
      />
      {showRegionChange && (
        <RegionChangeModal
          onClose={() => {
            setShowRegionChangeModal(false);
            setSelectedRegion("");
          }}
          onClick={() => {
            setRegion(selectedRegion);
            setShowRegionChangeModal(false);
          }}
          translate={translate}
          region={region}
          selectedRegion={selectedRegion}
        />
      )}
    </div>
  );
};

export default CreateComputerConnection;
