import React from "react";
import { Steps } from "intro.js-react";
import PCImage from "Assets/images/onboardingModals/sync.jpg";
import TransferImage from "Assets/images/onboardingModals/file-transfer.jpg";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const FilesOnboardingModal = ({ translate, onExit }) => {
  const steps = [
    {
      title: `<img src=${PCImage} />`, // TODO change
      intro: `<h1>${translate("modals.onboardingModals.filesOnboardingModal.sync.header")}</h1><p>${translate(
        "modals.onboardingModals.filesOnboardingModal.sync.description",
      )}</p>`,
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      title: `<img src=${TransferImage} />`,
      intro: `<h1>${translate("modals.onboardingModals.filesOnboardingModal.transfer.header")}</h1><p>${translate(
        "modals.onboardingModals.filesOnboardingModal.transfer.description",
      )}</p>`,
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
  ];
  return <Steps enabled options={{ nextToDone: true }} steps={steps} initialStep={0} onExit={onExit} />;
};

export default FilesOnboardingModal;
