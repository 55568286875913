import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import _ from "lodash";
import { Button, NumberInput } from "UI";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { classnames, isMobile } from "Utils";
import { calculateStripeFee } from "Utils/Helpers/functions.helpers";
import { APP_SCOPES, ONE_DOLLAR_CREDIT, REQUEST_STATUS } from "Constants/global.constants";
import { useAppScope } from "Utils/Hooks/useAppScope";

import "./AddBalanceForm.style.scss";

const MAX_CUSTOM_AMOUNT_FOR_USER = 250;
const MAX_CUSTOM_AMOUNT_FOR_ORGANIZATION = 1000;
const MAX_CUSTOM_AMOUNT_FOR_VENDOR = 5000;
const MAX_HOUR_AMOUNT = 720;
const MIN_HOUR_AMOUNT = 0;
const MIN_CUSTOM_AMOUNT = 1;
const MAX_INPUT_DIGIT = 4;
const MAX_CREDIT_INPUT_DIGIT = 6;

const AddBalanceForm = forwardRef((props, ref) => {
  const {
    small,
    prices,
    isHour,
    setBalance,
    setBalancePrice,
    defaultBalance,
    addBalanceCTX,
    disabled,
    showMobileCustomAmount,
    setShowMobileCustomAmount,
    afterCustomAmountText,
    translate,
  } = props;

  const [selectedAmount, setSelectedAmount] = useState(prices.includes(defaultBalance) ? defaultBalance : undefined);
  const [customAmount, setCustomAmount] = useState(undefined);
  const [isSuggested, setIsSuggested] = useState(false);
  const { appScope } = useAppScope();

  const location = useLocation();

  const customInputRef = useRef(null);

  const appStreaming = appScope === APP_SCOPES.vendor;

  let maxCustomAmount = appStreaming ? MAX_CUSTOM_AMOUNT_FOR_VENDOR : MAX_CUSTOM_AMOUNT_FOR_USER;

  if (location.pathname.includes("team")) {
    maxCustomAmount = MAX_CUSTOM_AMOUNT_FOR_ORGANIZATION;
  }

  const balanceChoice = selectedAmount || customAmount;
  const customAmountSelected = customAmount !== undefined;

  const priceGroups = _.chunk(prices, 2);

  const reset = () => {
    setSelectedAmount(undefined);
    setCustomAmount(undefined);
  };

  useImperativeHandle(ref, () => ({
    reset,
  }));

  useEffect(() => {
    if (customAmount === "" && customInputRef.current) {
      customInputRef.current.focus();
    }
  }, [customAmount, customInputRef, customAmountSelected]);

  useEffect(() => {
    if (setBalance) {
      if (balanceChoice) {
        setBalance(parseInt(balanceChoice, 10));
      } else {
        setBalance(isHour ? MIN_HOUR_AMOUNT : undefined);
      }
    }

    if (setBalancePrice && balanceChoice) {
      setBalancePrice(calculateStripeFee(isHour ? balanceChoice / ONE_DOLLAR_CREDIT : parseInt(balanceChoice, 10)));
    }
  }, [balanceChoice]);

  useEffect(() => {
    if (defaultBalance) {
      setDefaultBalance(defaultBalance);
    }
  }, [defaultBalance]);

  const setDefaultBalance = (price) => {
    if (prices.includes(price)) {
      setSelectedAmount(price);
      setCustomAmount(undefined);
    } else {
      setCustomAmount(price);
      setSelectedAmount(undefined);
    }
  };

  return (
    <div className={`add-balance-form ${small ? "small" : ""}`}>
      {!showMobileCustomAmount && (
        <div className={`select-balance ${selectedAmount || customAmountSelected ? "selected" : ""}`}>
          {priceGroups.map((balanceLine) => (
            <div className="balance-line" key={balanceLine}>
              {balanceLine.map((price) => {
                const selected = selectedAmount === price;
                const buttonText = isHour ? (
                  <p className="balance">
                    {price}
                    <br />
                    <span>Hours</span>
                  </p>
                ) : (
                  `$ ${price}`
                );
                return (
                  <div className="default-credit" key={price}>
                    {isSuggested && selected && (
                      <div className="text-info">
                        <p>{translate("addBalanceForm.suggestionText")}</p>
                      </div>
                    )}
                    <Button
                      basic
                      key={price}
                      className={selected && "selected"}
                      text={buttonText}
                      onClick={() => {
                        // If selected undo selection
                        if (!disabled) {
                          setSelectedAmount(selected ? undefined : price);
                          setCustomAmount(undefined);
                          setIsSuggested(false);
                        }
                      }}
                      disabled={addBalanceCTX?.status === REQUEST_STATUS.PENDING}
                    />
                  </div>
                );
              })}
            </div>
          ))}
          <div className="custom-button-wrapper">
            <Button
              basic
              className={classnames(["custom-balance-button", customAmountSelected && "selected"])}
              text="Custom"
              onClick={() => {
                if (!disabled) {
                  if (isMobile && setShowMobileCustomAmount) {
                    setShowMobileCustomAmount(!showMobileCustomAmount);
                    setCustomAmount(customAmount || "");
                  } else {
                    setSelectedAmount(undefined);
                    setCustomAmount(customAmountSelected ? undefined : "");
                  }
                }
              }}
              disabled={addBalanceCTX?.status === REQUEST_STATUS.PENDING}
            />
          </div>
        </div>
      )}
      {(customAmountSelected || small) && !isMobile && (
        <div className={classnames(["custom-balance", customAmountSelected && "selected", isHour && "credit"])}>
          {!small && `${translate("addBalanceForm.enterAmount")}`}
          <div className="custom-balance-container">
            <NumberInput
              inputRef={customInputRef}
              onChange={setCustomAmount}
              onFocus={(event) => {
                setSelectedAmount(undefined);
                setCustomAmount(customAmount || event.target.value);
              }}
              maxInputValue={isHour ? MAX_HOUR_AMOUNT : maxCustomAmount}
              maxDigitNumber={isHour ? MAX_CREDIT_INPUT_DIGIT : MAX_INPUT_DIGIT}
              minInputValue={isHour ? MIN_HOUR_AMOUNT : MIN_CUSTOM_AMOUNT}
              value={customAmount || 0}
              disabled={addBalanceCTX?.status === REQUEST_STATUS.PENDING}
            />
            {afterCustomAmountText && <p>{afterCustomAmountText}</p>}

            {isSuggested && (
              <div className="custom-balance-text-info">
                <p>{translate("addBalanceForm.suggestionText")}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {(customAmountSelected || small) && isMobile && (
        <div className={classnames(["custom-balance", isHour && "credit"])}>
          {!small && `${translate("addBalanceForm.enterAmount")}`}
          <div className="custom-balance-container">
            <NumberInput
              inputRef={customInputRef}
              onChange={setCustomAmount}
              onFocus={(event) => {
                setSelectedAmount(undefined);
                setCustomAmount(customAmount || event.target.value);
              }}
              maxInputValue={isHour ? MAX_HOUR_AMOUNT : maxCustomAmount}
              maxDigitNumber={isHour ? MAX_CREDIT_INPUT_DIGIT : MAX_INPUT_DIGIT}
              minInputValue={isHour ? MIN_HOUR_AMOUNT : MIN_CUSTOM_AMOUNT}
              value={customAmount || 0}
              inputMode="numeric"
            />
            {isSuggested && (
              <div className="custom-balance-text-info">
                <p>{translate("addBalanceForm.suggestionText")}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default AddBalanceForm;
