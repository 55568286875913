import React, { useEffect, useState, useRef } from "react";
import { classnames, doesEventContainsFiles } from "Utils";
import { Icon } from "UI";

import "./UploadOverlay.styles.scss";
import _ from "lodash";

const DOCK_WIDTH = 400;

const UploadInfo = (props) => {
  const { iconName, title, titleBold, description } = props;

  return (
    <div className="upload-info">
      <Icon name={iconName} />
      <h1>
        {title}
        <br />
        <span>{titleBold}</span>
      </h1>
      <p>{description}</p>
    </div>
  );
};

const UploadOverlay = (props) => {
  const { onToWorkstationDrop, onToFilesDrop, setIsFolder, disableDockUpload, translate } = props;

  const overlayRef = useRef(null);
  const [dragDepth, setDragDepth] = React.useState(0);
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (doesEventContainsFiles(event)) {
      if (overlayRef.current && event.target && overlayRef.current.contains(event.target)) {
        setDragDepth(dragDepth + 1);
      }
    }
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (overlayRef.current && event.target && overlayRef.current.contains(event.target)) {
      if (dragDepth === 1 && show) {
        setShow(false);
      }
      setDragDepth(Math.max(dragDepth - 1, 0));
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { items, files } = event.dataTransfer;
    if (files.length > 0) {
      if (target === "left") {
        onToWorkstationDrop(files);
        _.forEach(items, (item) => {
          const fileEntry = item.webkitGetAsEntry();
          if (!fileEntry.isFile) {
            setIsFolder(true);
          }
        });
      } else {
        onToFilesDrop(files);
      }
    }
    setDragDepth(0);
    setShow(false);
  };

  // TODO: Improve this part
  useEffect(() => {
    const onDragOver = (event) => {
      event.preventDefault();

      setShow(true);

      if (disableDockUpload) {
        setTarget("left");
        return;
      }

      if (overlayRef.current) {
        const overlayRect = overlayRef.current.getBoundingClientRect();
        const isMouseOverLeft = overlayRect.width - event.pageX >= DOCK_WIDTH;
        const isTargetLeft = target === "left";

        if (isTargetLeft !== isMouseOverLeft) {
          setTarget(isMouseOverLeft ? "left" : "right");
        }
      }
    };

    const onDragLeave = (event) => {
      event.preventDefault();
      if (event.target.id === "player") {
        setShow(false);
      }
    };

    window.addEventListener("dragover", onDragOver, true);
    window.addEventListener("dragleave", onDragLeave, true);
    return () => {
      window.removeEventListener("dragover", onDragOver, true);
      window.removeEventListener("dragleave", onDragLeave, true);
    };
  }, [target, overlayRef]);

  return (
    <div className="upload-overlay">
      {(dragDepth > 0 || show) && (
        <div
          className="upload-overlay-container"
          ref={overlayRef}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div
            className={classnames([
              "upload-container workstation-upload-container",
              target === "left" && "over",
              disableDockUpload && "full",
            ])}
          >
            <UploadInfo
              iconName="upload-to-workstation"
              title={translate("uploadOverlay.workstation.title")}
              description={translate("uploadOverlay.workstation.description")}
            />
          </div>
          {!disableDockUpload && (
            <div className={`upload-container dock-upload-container ${target === "right" ? "over" : ""}`}>
              <UploadInfo
                iconName="upload-to-cloud"
                title={translate("uploadOverlay.files.title")}
                description={translate("uploadOverlay.files.description")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadOverlay;
