class Ping {
  /**
   * Creates a Ping instance.
   * @returns {Ping}
   * @constructor
   */
  constructor(opt) {
    this.opt = opt || {};
    this.terminated = false;
    this.favicon = this.opt.favicon || "/favicon.ico";
    this.timeout = this.opt.timeout || 0;
    this.logError = this.opt.logError || false;
  }

  /**
   * Pings source and triggers a callback when completed.
   * @param source Source of the website or server, including protocol and port.
   * @param callback Callback function to trigger when completed. Returns error and ping value.
   * @param count Optional number of ping request before aborting.
   */
  ping(source, callback, count = 0) {
    const self = this;
    self.wasSuccess = false;
    self.terminated = false;
    let img = new Image();
    img.onload = onload;
    img.onerror = onerror;

    const pings = [];
    let timer;
    let counter = count;

    let prev = new Date();

    function newImage() {
      img = new Image();
      img.onload = onload;
      img.onerror = onerror;
      img.src = `${source}${self.favicon}?${+new Date()}`;
    }

    function onImageEvent(e) {
      if (self.terminated) return;

      if (counter === 0) {
        pingCheck.call(self, e);
      } else {
        counter -= 1;
        newImage(self);
        const smallPong = new Date() - prev;

        prev = new Date();
        pings.push(smallPong);
      }
    }

    function onload(e) {
      self.wasSuccess = true;
      onImageEvent(e);
    }

    function onerror(e) {
      self.wasSuccess = false;
      onImageEvent(e);
    }

    if (self.timeout) {
      timer = setTimeout(() => {
        pingCheck.call(self, undefined);
      }, self.timeout);
    }

    /**
     * Times ping and triggers callback.
     */
    function pingCheck() {
      if (timer) {
        clearTimeout(timer);
      }

      if (typeof callback === "function") {
        const averagePing = cleanAverage(pings);
        // When operating in timeout mode, the timeout callback doesn't pass [event] as e.
        // Notice [this] instead of [self], since .call() was used with context
        if (!this.wasSuccess) {
          if (self.logError) {
            console.error("error loading resource"); // eslint-disable-line
          }
          return callback("error", averagePing);
        }
        return callback(null, averagePing);
      }
      return "";
    }
    img.src = `${source}${self.favicon}?${+new Date()}`; // Trigger image load with cache buster
  }

  /**
   * This function sets the field that prevents further call
   */
  terminate() {
    this.terminated = true;
  }
}

/**
 * This function returns average without max and min values
 */
function cleanAverage(array) {
  const sum = array.reduce((a, b) => a + b, 0);
  if (array.length <= 2) return sum / array.length;

  const min = Math.min(...array);
  const max = Math.max(...array);

  return (sum - max - min) / (array.length - 2);
}

export default Ping;
