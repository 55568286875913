import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import {
  createUserPaymentMethodAPI,
  getPaymentClientSecretAPI,
  getPaymentMethodsAPI,
} from "Actions/Dashboard/Payment.actions";
import { createOrganizationSeatsAPI } from "Actions/Organization.actions";
import PoweredByStripe from "Assets/images/stripe/powered_by_stripe.png";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { PAYMENT_TYPE, REQUEST_STATUS } from "Constants/global.constants";
import {
  Loader,
  PaymentMethodForm,
  PaymentMethods,
  PaymentDetails,
  Payment3DSecureModal,
  isMobile,
  apiGenerator,
  classnames,
  Tracker,
} from "Utils";
import { Button, RadioInput, TopLoader } from "UI";
import BillingAddressInformation from "./BillingAddressInformation.component";
import "./Payment.styles.scss";

const Payment = (props) => {
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.CREDIT_CARD);
  const { history, translate } = props;
  const { payment, organizations, account } = useSelector((state) => state);
  const { createComputersCTX, organization } = organizations;
  const billingInformation = organization?.attributes?.billing_information?.attributes || {};
  const { selectedInvitationIds, selectedUserIds, quantity: totalMachineCount } = createComputersCTX;

  const dispatch = useDispatch();
  const calculateTotalAmountMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS_CALCULATE, data);
    },
  });

  const {
    total_amount: totalAmount,
    seat_price_details: seatPriceDetails,
    payment_fee: paymentFee,
    quantity,
    total_seat_price: totalSeatPrice,
    name,
    can_pay_with_balance: canPayWithBalance,
  } = calculateTotalAmountMutation.data?.data || {};

  useEffect(() => {
    dispatch(getPaymentMethodsAPI());
    calculateTotalAmountMutation.mutate({
      quantity: totalMachineCount || (selectedUserIds?.length || 0) + (selectedInvitationIds?.length || 0),
      seat_plan_id: createComputersCTX.seat_plan_id,
    });
  }, [createComputersCTX]);

  const { balance } = organization?.attributes || {};

  const [showPayment3DSecure, setShowPayment3DSecure] = useState(false);
  const [isPaymentPermissionGiven, setIsPaymentPermissionGiven] = useState(false);
  const [isPaymentMethodFormComplete, setIsPaymentMethodFormComplete] = useState(false);
  const [isCardInputComplete, setIsCardInputComplete] = useState(false);

  const [pending, setPending] = useState();
  const [errorAddPayment, setErrorAddPayment] = useState(false);
  const [errorAddPaymentEnd, setErrorAddPaymentEnd] = useState(false);
  const [error, setError] = useState(false);

  const paymentMethodFormRef = useRef(null);

  const hasPaymentMethods = payment.getPaymentMethodsCTX.data && payment.getPaymentMethodsCTX.data.length > 0;
  const readyToComplete = true;
  const balancePayment = paymentType === PAYMENT_TYPE.BALANCE;

  const totalAmountToPay = (totalAmount - (paymentType === PAYMENT_TYPE.BALANCE ? paymentFee : 0))?.toFixed(2);

  const planPaymentLineItems = [
    {
      key: 1,
      name: "Computer Plan",
    },
    {
      key: 2,
      name,
      amount: seatPriceDetails?.data?.attributes?.total_price,
    },
  ];

  const paymentLineItems = [
    {
      key: 4,
      name: `${quantity} x Computers`,
      amount: totalSeatPrice,
    },
    {
      key: 5,
      name: "Payment Fee",
      amount: paymentFee,
      discounted: balancePayment,
    },
  ];

  useEffect(() => {
    if (
      payment.payment3DSecureCTX.status === REQUEST_STATUS.PENDING &&
      payment.payment3DSecureCTX.data &&
      payment.payment3DSecureCTX.data.payment_required === true
    ) {
      setShowPayment3DSecure(true);
    } else if (payment.payment3DSecureCTX.status === REQUEST_STATUS.FAILURE) {
      setShowPayment3DSecure(false);
    }
  }, [payment.payment3DSecureCTX]);

  useEffect(() => {
    if (organizations.createOrganizationSeatsCTX.status === REQUEST_STATUS.FAILURE) {
      setError("Something went wrong");
      setPending(false);
    }
  }, [organizations.createOrganizationSeatsCTX]);

  useEffect(() => {
    if (payment.createUserPaymentMethodCTX.status !== REQUEST_STATUS.PENDING) {
      setPending(false);
      setError(false);
    }
  }, [payment.createUserPaymentMethodCTX]);

  const onAddPaymentButtonClick = () => {
    if (isPaymentMethodFormComplete && paymentMethodFormRef.current) {
      if (!pending) {
        setPending(true);
        paymentMethodFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
      }
    }
  };

  useEffect(() => {
    if (organizations.createOrganizationSeatsCTX.status === REQUEST_STATUS.SUCCESS) {
      setShowPayment3DSecure(false);

      Tracker.event({ type: "subscriptionCreate", action: "success" });
      window.fbq("track", "Subscribe", {
        currency: "USD",
      });

      history.push({
        pathname: routes.organizationAdminHome,
        state: {
          computerCreateSuccess: true,
        },
      });
    }
  }, [organizations.createOrganizationSeatsCTX.status]);

  const handleCreatePayment = () => {
    if (readyToComplete) {
      setPending(true);
      dispatch(
        createOrganizationSeatsAPI({
          quantity: createComputersCTX.quantity,
          user_ids: createComputersCTX.selectedUserIds,
          invitation_ids: createComputersCTX.selectedInvitationIds,
          seat_plan_id: createComputersCTX.seat_plan_id,
          pay_with_balance: paymentType === PAYMENT_TYPE.BALANCE,
        }),
      );
    }
  };

  const handle3DSecureFailure = (errorMessage) => {
    setErrorAddPaymentEnd(errorMessage);
    setShowPayment3DSecure(false);
  };

  const paymentInformation = () => {
    const isPaymentInformationReady = payment.getPaymentMethodsCTX.status === REQUEST_STATUS.SUCCESS;

    if (isPaymentInformationReady) {
      return hasPaymentMethods ? (
        <PaymentMethods paymentType={paymentType} setPaymentType={setPaymentType} translate={translate} />
      ) : (
        <>
          <h2>{translate("payment.header.paymentMethod")}</h2>
          <PaymentMethodForm
            formRef={paymentMethodFormRef}
            setError={setErrorAddPayment}
            setPending={setPending}
            setIsCardInputComplete={setIsCardInputComplete}
            setIsPaymentMethodFormComplete={setIsPaymentMethodFormComplete}
            account={account}
            payment={payment}
            createUserPaymentMethodAPI={(accessToken, seti) => {
              dispatch(createUserPaymentMethodAPI(accessToken, seti));
            }}
            getPaymentClientSecretAPI={(data) => {
              dispatch(getPaymentClientSecretAPI(data));
            }}
            translate={translate}
          />
          <RadioInput
            checked={isPaymentPermissionGiven}
            onChange={() => {
              setIsPaymentPermissionGiven(!isPaymentPermissionGiven);
            }}
            label={translate("addPaymentForm.paymentPermission")}
          />
          <Button
            text={translate("payment.action.addPaymentMethodButton")}
            errorText={errorAddPayment && (errorAddPayment?.message || translate("addPaymentForm.error.default"))}
            disabled={!isCardInputComplete || !isPaymentMethodFormComplete || !isPaymentPermissionGiven || pending}
            onClick={onAddPaymentButtonClick}
            block
          />
        </>
      );
    }
    return <Loader big centered />;
  };

  return (
    <div className="team-payment-container">
      {pending && <TopLoader />}
      <div className="payment-informations">
        <div className="payment-card-info">
          <div className="payment-method-information information-section">{paymentInformation()}</div>
          {canPayWithBalance && (
            <div className="alternative-payments">
              <div
                className={classnames(["pay-with-balance", paymentType === PAYMENT_TYPE.BALANCE && "selected"])}
                onClick={() => {
                  setPaymentType(PAYMENT_TYPE.BALANCE);
                }}
                tabIndex={0}
                role="button"
              >
                <p>{translate("teamPayment.paymentOptions.balance")}</p>
                <p1>${parseFloat(balance).toFixed(2)}</p1>
              </div>
            </div>
          )}
          {hasPaymentMethods && (
            <div className="billing-info">
              <h2>{translate("teamPayment.content.billingHeader")}</h2>
              <BillingAddressInformation billingInformation={billingInformation} translate={translate} />
            </div>
          )}
        </div>

        <div className="summary information-section">
          <PaymentDetails
            hide={!hasPaymentMethods && isMobile}
            items={planPaymentLineItems}
            totalAmount={`$ ${seatPriceDetails?.data?.attributes?.total_price}`}
            subHeaderText={translate("teamPayment.content.paymentDetailsSecondSubHeader")}
          />

          <PaymentDetails
            hide={!hasPaymentMethods && isMobile}
            items={paymentLineItems}
            totalAmount={`$ ${totalAmountToPay}`}
            totalAmountText={translate("teamPayment.content.total")}
          />

          <div className="stripe-info">
            <img src={PoweredByStripe} alt="Powered by Stripe" className="stripe-image" />
          </div>
          {readyToComplete && (
            <Button
              text={translate("teamPayment.action.completeButton", { amount: totalAmountToPay })}
              errorText={errorAddPaymentEnd?.error || error}
              disabled={(!hasPaymentMethods && paymentType === PAYMENT_TYPE.CREDIT_CARD) || pending}
              onClick={handleCreatePayment}
              block
            />
          )}
        </div>
      </div>
      {showPayment3DSecure && (
        <Payment3DSecureModal
          {...props}
          onSuccess={() => {
            setShowPayment3DSecure(false);
          }}
          onFailure={handle3DSecureFailure}
          setShowPayment3DSecure={setShowPayment3DSecure}
        />
      )}
    </div>
  );
};

export default Payment;
