import React, { useRef, useState } from "react";
import { Steps } from "intro.js-react";
import TeamFoldersImage from "Assets/images/onboardingModals/team-folder.jpg";
import CreateUploadImage from "Assets/images/onboardingModals/create-upload.jpg";
import OperationsImage from "Assets/images/onboardingModals/operations.jpg";
import { FILES_ONBOARDING_STEPS } from "Constants/teamsOnboarding.constants";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const TeamsFilesOnboardingModal = ({ setCurrentStep, onExit, translate }) => {
  const stepsObject = useRef(null);
  const backgroundClass = document.getElementById("dashboard-content-container");
  const menuClass = document.getElementById("menu-widescreen-container");
  const [firstStep, setFirstStep] = useState(true);

  const steps = [
    {
      element: ".table-body",
      title: `<img src=${TeamFoldersImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.files.userFiles.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.files.userFiles.description")}</p>`,
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".folder-actions",
      title: `<img src=${CreateUploadImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.files.fileIcons.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.files.fileIcons.description")}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-hanging-files",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".file-menu-pop-up",
      title: `<img src=${OperationsImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.files.moreOptions.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.files.moreOptions.description")}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
  ];

  const onBeforeChange = (currentStep) => {
    setCurrentStep(currentStep);

    if (currentStep === FILES_ONBOARDING_STEPS.folders.step) {
      backgroundClass.style.overflow = "hidden";
      menuClass.style.overflow = "hidden";
      setFirstStep(true);
    } else if (currentStep === FILES_ONBOARDING_STEPS.buttons.step) {
      setFirstStep(true);
      stepsObject.current.updateStepElement(FILES_ONBOARDING_STEPS.buttons.step);
    } else if (currentStep === FILES_ONBOARDING_STEPS.popup.step) {
      if (firstStep) {
        setFirstStep(false);
        setTimeout(() => {
          stepsObject.current.updateStepElement(FILES_ONBOARDING_STEPS.popup.step);
          stepsObject.current.introJs.goToStep(3);
        }, 10);
      }
    }
  };

  const onBeforeExit = () => {
    backgroundClass.style.overflow = "visible";
    menuClass.style.overflow = "visible";
  };

  return (
    <Steps
      enabled
      options={{ nextToDone: true }}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      onBeforeChange={onBeforeChange}
      onBeforeExit={onBeforeExit}
      ref={stepsObject}
    />
  );
};

export default TeamsFilesOnboardingModal;
