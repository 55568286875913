import React from "react";
import { WorldMapCoverage, Icon } from "UI";
import { classnames } from "Utils";
import { Translate } from "react-localize-redux";
import { dolarToCents } from "../../../../Utils/Helpers/functions.helpers";

import "./CreateStreamModal.styles.scss";

const ChooseRegion = ({
  coveredRegions,
  selectedCapacities,
  onRegionClick,
  minSessionCostPerMinute,
  maxSessionCostPerMinute,
  newDailyCost,
  translate,
  regionPricingLoading,
}) => {
  return (
    <>
      <h1>{translate("appStreamingModals.stream.step0.header")}</h1>
      <p>{translate("appStreamingModals.stream.step0.description")}</p>
      <div className="region-step">
        <WorldMapCoverage
          coveredRegions={coveredRegions}
          activeRegions={selectedCapacities}
          onRegionClick={onRegionClick}
          passiveCoverage
        />
        {Number.isFinite(minSessionCostPerMinute) && (
          <div className={classnames(["region-pricing-info", !regionPricingLoading && "visible"])}>
            <div>
              {minSessionCostPerMinute === maxSessionCostPerMinute ? (
                <Translate
                  id="appStreamingModals.stream.step0.sessionSingleCost"
                  options={{
                    renderInnerHtml: true,
                  }}
                  data={{ cost: dolarToCents(minSessionCostPerMinute) }}
                />
              ) : (
                <Translate
                  id="appStreamingModals.stream.step0.sessionCost"
                  options={{
                    renderInnerHtml: true,
                  }}
                  data={{
                    minCost: dolarToCents(minSessionCostPerMinute),
                    maxCost: dolarToCents(maxSessionCostPerMinute),
                  }}
                />
              )}
            </div>
            <Icon name="plus" color="aqua-main-stroke" tiny />
            <div>
              <Translate
                id={
                  newDailyCost === 0
                    ? "appStreamingModals.stream.step0.maintenancePaid"
                    : "appStreamingModals.stream.step0.storageCost"
                }
                options={{
                  renderInnerHtml: true,
                }}
                data={{ avgCost: newDailyCost }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChooseRegion;
