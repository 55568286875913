import React, { useState } from "react";
import { Loader } from "Utils";
import DashboardSubScreenLayout from "Utils/Components/Layouts/DashboardSubScreenLayout/DashboardSubScreenLayout.component";
import { groupMachineTypeUsages } from "Utils/Helpers/functions.helpers";
import { Button } from "UI";
import ComputerPerformanceRow from "./ComputerPerformanceRow.component";
import "./ComputerPerformance.style.scss";

const ComputerPerformance = (props) => {
  const { setShowMachineTypeSelection, machineTypesUsages, selectedMachineType, region, handleSave, translate } = props;
  const [pseudoSelectedMachineType, setPseudoSelectedMachineType] = useState(parseInt(selectedMachineType, 10));

  if (!machineTypesUsages) {
    return <Loader />;
  }

  const groupedMachineTypes = groupMachineTypeUsages(machineTypesUsages);

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("computerPerformance.header.title")}
      headerDescription={translate("computerPerformance.header.description")}
      actionContent={
        <Button
          block
          text="Save"
          onClick={() => {
            handleSave(pseudoSelectedMachineType);
          }}
        />
      }
      translate={translate}
      showCloseIcon
      onClose={() => {
        setShowMachineTypeSelection(false);
      }}
    >
      <div className="vg-computer-performance">
        {["a10", "gpu", "cpu", "general"].map(
          (performanceType) =>
            groupedMachineTypes[performanceType] && (
              <ComputerPerformanceRow
                key={performanceType}
                performanceType={performanceType}
                machineTypes={groupedMachineTypes[performanceType]}
                selectedMachineType={pseudoSelectedMachineType}
                setSelectedMachineType={setPseudoSelectedMachineType}
                translate={translate}
              />
            ),
        )}
      </div>
    </DashboardSubScreenLayout>
  );
};

export default ComputerPerformance;
