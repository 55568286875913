// import { API_ENDPOINTS } from "Constants/api.constants";
import React, { useCallback, useRef } from "react";
import { classnames } from "Utils";
import "./HistoryTable.styles.scss";

const HistoryTable = (props) => {
  const {
    headers,
    keys,
    data,
    onClick,
    selectedDataId,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    session,
    payment,
  } = props;

  const observer = useRef();
  const lastRowRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, hasNextPage],
  );

  const handleClick = (object) => {
    if (onClick) {
      onClick(object);
    }
  };

  return (
    <div className="vg-history-table">
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody className={classnames([session && "session-scroll", payment && "payment-scroll"])}>
          {data.map((object, idx) => (
            <tr
              className={classnames([selectedDataId === object.id && "selected-data-row"])}
              key={object.id}
              ref={data.length - 1 === idx ? lastRowRef : null}
              onClick={() => {
                handleClick(idx);
              }}
            >
              {keys.map((key) => (
                <td key={key}>{object[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HistoryTable;
