import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "UI";
import { motion } from "framer-motion";
import { useGesture } from "@use-gesture/react";
import useOrientationChange from "Utils/Hooks/useOrientationChange";

import "./KeyboardButton.styles.scss";

const KeyboardButton = ({ setShowKeyboardShortcuts }) => {
  const defaultXY = [20, 20];
  const [isDragging, setIsDragging] = useState(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const inputRef = useRef(null);
  const onBlurTimeoutRef = useRef(null);
  const motionRef = useRef(null);
  const [lastXY, setLastXY] = useState([0, 0]);
  const [xy, setXY] = useState(defaultXY);

  const preventDefault = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isDragging) {
      document.body.addEventListener("touchmove", preventDefault, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventDefault);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventDefault);
    };
  }, [isDragging]);

  const handleOrientationChange = () => {
    setXY(defaultXY);
  };

  useOrientationChange(handleOrientationChange);

  const bind = useGesture(
    {
      onDrag: ({ movement: [mx, my] }) => {
        if (!isDragging) setIsDragging(true);
        setXY([mx + lastXY[0], my + lastXY[1]]);
      },
      onDragEnd: () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Calculate distances to corners
        const distances = {
          topLeft: Math.sqrt(xy[0] ** 2 + xy[1] ** 2),
          topRight: Math.sqrt((windowWidth - xy[0]) ** 2 + xy[1] ** 2),
          bottomLeft: Math.sqrt(xy[0] ** 2 + (windowHeight - xy[1]) ** 2),
          bottomRight: Math.sqrt((windowWidth - xy[0]) ** 2 + (windowHeight - xy[1]) ** 2),
        };

        // Find the closest corner
        const closestCorner = Object.keys(distances).reduce((a, b) => (distances[a] < distances[b] ? a : b));
        const divWidth = motionRef.current.offsetWidth;
        const divHeight = motionRef.current.offsetHeight;

        // Set position based on closest corner
        switch (closestCorner) {
          case "topLeft":
            setXY([20, 20]);
            setLastXY([20, 20]);
            break;
          case "topRight":
            setXY([windowWidth - divWidth - 20, 20]);
            setLastXY([windowWidth - divWidth - 20, 20]);
            break;
          case "bottomLeft":
            setXY([20, windowHeight - divHeight - 20]);
            setLastXY([20, windowHeight - divHeight - 20]);
            break;
          case "bottomRight":
            setXY([windowWidth - divWidth - 20, windowHeight - divHeight - 20]);
            setLastXY([windowWidth - divWidth - 20, windowHeight - divHeight - 20]);
            break;
          default:
            break;
        }
        setIsDragging(false);
      },
    },
    {
      drag: { pointer: { touch: true } },
    },
  );

  const style = {
    x: xy[0],
    y: xy[1],
  };

  return (
    <motion.div className="mobile-keyboard-container" style={style} {...bind()} ref={motionRef}>
      <input
        ref={inputRef}
        onBlur={() => {
          if (isKeyboardOpen) {
            clearTimeout(onBlurTimeoutRef.current);
            onBlurTimeoutRef.current = setTimeout(() => {
              if (setShowKeyboardShortcuts) setShowKeyboardShortcuts(false);
            }, 200);
            setIsKeyboardOpen(false);
          }
        }}
      />
      <IconButton
        name="keyboard"
        onClick={() => {
          inputRef.current.focus();
          setIsKeyboardOpen(true);
          if (setShowKeyboardShortcuts) setShowKeyboardShortcuts(true);
        }}
      />
    </motion.div>
  );
};

export default KeyboardButton;
