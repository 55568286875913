import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Button, IconButton, TextButton, BoardingModal } from "UI";
import DashboardSubScreenLayout from "Utils/Components/Layouts/DashboardSubScreenLayout/DashboardSubScreenLayout.component";
import { isMobile, getItemFromLocalStorage, saveItemToLocalStorage } from "Utils";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";

import boardingImage1 from "Assets/images/referral/boarding-1.png";
import boardingImage2 from "Assets/images/referral/boarding-2.png";
import boardingImage3 from "Assets/images/referral/boarding-3.png";
import VagonGift from "Assets/images/referral/vagon-gift.png";
import RewardLimitImage from "Assets/images/referral/reward-limit.jpg";
import RewardEarnedImage from "Assets/images/referral/reward-earned.png";

import SendReferralEmailModal from "./SendReferralEmailModal/SendReferralEmailModal.component";

import "./Referral.styles.scss";

/* eslint-disable */
const URL_ENCODED_MESSAGE =
  "Hey!%20I%E2%80%99d%20like%20to%20give%20you%20$5%20on%20Vagon!%20It%E2%80%99s%20an%20awesome%20cloud%20computer%20that%20you%20can%20run%20any%20apps.%0A%0AGive%20it%20a%20try%0A%0A";
/* eslint-enable */

const SOCIAL_MEDIA_URLS = (referralCode) => {
  return {
    Facebook: `https://www.facebook.com/sharer/sharer.php?u=https://vagon.io/referral?invite=${referralCode}`,
    Twitter: `https://twitter.com/intent/tweet?url=https://vagon.io/referral?invite=${referralCode}&text=${URL_ENCODED_MESSAGE}`,
    Telegram: `https://telegram.me/share/url?url=${URL_ENCODED_MESSAGE}https://vagon.io/referral?invite=${referralCode}`,
    Whatsapp: `https://wa.me/send?text=&text=${URL_ENCODED_MESSAGE}https://vagon.io/referral?invite=${referralCode}`,
  };
};

const MOBILE_SOCIAL_MEDIA_URLS = (referralCode) => {
  return {
    Facebook: `https://www.facebook.com/sharer/sharer.php?u=https://vagon.io/referral?invite=${referralCode}`,
    Twitter: `https://twitter.com/intent/tweet?url=https://vagon.io/referral?invite=${referralCode}&text=${URL_ENCODED_MESSAGE}`,
    Messenger: `fb-messenger://share/?link=https://vagon.io/referral?invite=${referralCode}`,
    Whatsapp: `whatsapp://send?text=${URL_ENCODED_MESSAGE}https://vagon.io/referral?invite=${referralCode}`,
  };
};

const SocialMediaButton = (props) => {
  const { name, url } = props;
  return (
    <div className="social-media-button">
      <a
        href={url}
        target="popup"
        onClick={() => {
          window.open(url, "popup", "width=600,height=600");
          return false;
        }}
      >
        <IconButton name={name.toLowerCase()} />
        <TextButton text={name} />
      </a>
    </div>
  );
};

const ReferralInfoItem = (props) => {
  const { title, text, img } = props;

  return (
    <div className="referral-info-item">
      <img src={img} />
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
};

const ReferralDetailItem = (props) => {
  const { title, value } = props;

  return (
    <div className="referral-detail-item">
      <h2>{title}</h2>
      <p>{value}</p>
    </div>
  );
};

const ReferralLimitReminder = (props) => {
  const { title, text, img } = props;

  return (
    <div className="referral-limit-reminder">
      <img src={img} />
      <div className="referral-limit-text">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

const ReferralComponent = (props) => {
  const { account, referral, inviteWithEmail, claimRewardsAPI, history, translate } = props;

  const { referral_code: referralCode } = account.account.attributes;
  const { unclaimedRewards, totalRewards, registerCount, givenRewards, monthlyEarnedRewards } =
    referral.getRewardsStatusCTX.data || {};
  const { referreReward, referrerReward, messageTemplates, totalRewardLimit, monthlyRewardLimit } =
    referral.getRewardsCTX.data || {};

  const usedReferral = registerCount > 0 || totalRewards > 0;
  const referralLimitReached = registerCount >= totalRewardLimit || monthlyEarnedRewards >= monthlyRewardLimit;

  const [showBoardingModal, setShowBoardingModal] = useState(
    getItemFromLocalStorage(LOCAL_STORAGE.showReferralBoarding) !== false && !usedReferral,
  );

  const [rewardsClaimed, setRewardsClaimed] = useState(false);
  const [showRewardsClaimedModal, setShowRewardsClaimedModal] = useState(false);

  useEffect(() => {
    if (rewardsClaimed && referral.claimRewardsCTX.status === REQUEST_STATUS.SUCCESS) {
      setShowRewardsClaimedModal(true);
    }
  }, [referral.claimRewardsCTX.status, rewardsClaimed]);

  const closeBoardingModal = () => {
    setShowBoardingModal(false);
    saveItemToLocalStorage(LOCAL_STORAGE.showReferralBoarding, false);
  };

  const referralLink = `vagon.io/referral?invite=${referralCode}`;

  const showSendEmailModal = window.location.hash === "#share";

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("referral.title")}
      headerDescription={translate("referral.description")}
      translate={translate}
      waitFor={[referral.getRewardsCTX.status]}
      pending={
        _.findIndex(
          [referral.claimRewardsCTX.status, referral.getRewardsStatusCTX.status],
          (status) => status === REQUEST_STATUS.PENDING,
        ) !== -1
      }
    >
      <div className="referral-container">
        <div className="referral-info">
          <img src={VagonGift} />
          <h1>{translate("referral.information.title", { referreReward, referrerReward })}</h1>
          <p>{translate("referral.information.description", { referreReward, referrerReward })}</p>
        </div>
        <Button
          className="referral-send-email"
          text={translate("referral.sendEmail")}
          onClick={() => {
            history.replace({ hash: "share" });
          }}
        />

        <div className="referral-details-container">
          <div className="referral-details">
            <div className="details-section">
              <div className="referral-link-section">
                <h2>{translate("referral.link.header")}</h2>
                <div className="referral-link">
                  <p>{referralLink}</p>
                  <TextButton
                    text={translate("referral.link.copyButton")}
                    color="purple"
                    onClick={() => {
                      navigator.clipboard.writeText(`https://${referralLink}`);
                    }}
                  />
                </div>
              </div>
              <div className="social-media-section">
                {isMobile
                  ? _.map(MOBILE_SOCIAL_MEDIA_URLS(referralCode), (url, media) => {
                      return <SocialMediaButton key={media} name={media} url={url} />;
                    })
                  : _.map(SOCIAL_MEDIA_URLS(referralCode), (url, media) => {
                      return <SocialMediaButton key={media} name={media} url={url} />;
                    })}
              </div>
            </div>
            <div className="divider" />
            {usedReferral ? (
              <div className="details-section">
                <div className="details-section-row">
                  <ReferralDetailItem title={translate("referral.details.registered")} value={registerCount} />
                  <ReferralDetailItem title={translate("referral.details.given")} value={`$${givenRewards}`} />
                </div>
                <div className="details-section-row">
                  <ReferralDetailItem title={translate("referral.details.earned")} value={`$${totalRewards}`} />
                  <ReferralDetailItem title={translate("referral.details.unclaimed")} value={`$${unclaimedRewards}`} />
                  {unclaimedRewards > 0 && !rewardsClaimed && (
                    <TextButton
                      text={translate("referral.details.claim")}
                      color="purple"
                      onClick={() => {
                        setRewardsClaimed(true);
                        claimRewardsAPI(account.accessToken);
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="details-section wide">
                <div className="details-section-row centered">
                  <ReferralInfoItem
                    title={translate("referral.info.header1")}
                    text={translate("referral.info.description1")}
                    img={boardingImage1}
                  />
                  <ReferralInfoItem
                    title={translate("referral.info.header2")}
                    text={translate("referral.info.description2")}
                    img={boardingImage2}
                  />
                  <ReferralInfoItem
                    title={translate("referral.info.header3")}
                    text={translate("referral.info.description3")}
                    img={boardingImage3}
                  />
                </div>
              </div>
            )}
          </div>
          {referralLimitReached && (
            <ReferralLimitReminder
              img={RewardLimitImage}
              title={translate("referral.referralLimit.title")}
              text={translate("referral.referralLimit.description")}
            />
          )}
        </div>
      </div>
      {showSendEmailModal && !showBoardingModal && (
        <SendReferralEmailModal
          messageTemplates={messageTemplates}
          inviteCTX={referral.inviteCTX}
          inviteWithEmail={inviteWithEmail}
          setShowSendEmailModal={() => {
            history.replace({ hash: "" });
          }}
          translate={translate}
        />
      )}
      {showBoardingModal && (
        <BoardingModal
          headerImages={[boardingImage1, boardingImage2, boardingImage3]}
          headerTexts={[
            translate("referral.boarding.header1"),
            translate("referral.boarding.header2"),
            translate("referral.boarding.header3"),
          ]}
          descriptions={[
            translate("referral.boarding.description1"),
            translate("referral.boarding.description2"),
            translate("referral.boarding.description3"),
          ]}
          buttonTexts={[
            translate("referral.boarding.buttonText1"),
            translate("referral.boarding.buttonText2"),
            translate("referral.boarding.buttonText3"),
          ]}
          buttonAction={closeBoardingModal}
          closeAction={closeBoardingModal}
        />
      )}
      {showRewardsClaimedModal && (
        <BoardingModal
          headerImages={[RewardEarnedImage]}
          headerTexts={[translate("modals.rewardsClaimed.header")]}
          descriptions={[translate("modals.rewardsClaimed.description")]}
          buttonTexts={[translate("modals.rewardsClaimed.buttonText")]}
          buttonAction={() => {
            setShowRewardsClaimedModal(false);
          }}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default ReferralComponent;
