import React from "react";

import { LOCAL_STORAGE } from "Constants/global.constants";
import { RadioInput } from "UI";
import "./MachineTypeCard.styles.scss";
import { connect } from "react-redux";
import { saveItemToLocalStorage } from "Utils";

const BG_COLOR_BY_MACHINE_TYPE = {
  Spark: "#E08989",
  Flame: "#E08989",
  Blaze: "#E08989",
  Lava: "#E08989",
  Magma: "#E08989",
  // Planet: "#ABB3FF",
  Planet: "#4500A6",
  // Star: "#756AFB",
  Star: "#4500A6",
  Galaxy: "#4500A6",
  // Lake: "#A8E2DA",
  Lake: "#45ABB5",
  // Sea: "#73D4C7",
  Sea: "#45ABB5",
  Ocean: "#45ABB5",
  Sand: "#4F4F4F",
};

const BG_COLOR_BY_MACHINE_TYPE_FOR_APP_STREAMING = {
  Planet: "#000000",
  Star: "#ABB3FF",
  Galaxy: "#ABB3FF",
  Lake: "#73D4C7",
  Sea: "#73D4C7",
  Ocean: "#73D4C7",
  Sand: "#4F4F4F",
  Spark: "#E08989",
  Flame: "#E08989",
  Blaze: "#E08989",
  Lava: "#E08989",
  Magma: "#E08989",
  Comet: "#000000",
};

const DEFAULT_IMAGE = require("Assets/images/machineTypes/Default.png");

const MachineTypeCard = ({
  account,
  className,
  fixedDirection,
  machineType,
  shouldSavePerformance,
  selectedMachineType,
  setSelectedMachineType,
  minimized,
  appStreaming,
  isDefault,
  disabled,
}) => {
  const { details, price_by_hour: price, possible_usage_with_balance: remainingBalance } = machineType.attributes || {};
  const {
    cpu,
    gpu,
    gpu_memory: gpuMemory,
    memory,
    friendly_name: friendlyName,
    stream_friendly_name: streamFriendlyName,
  } = details?.attributes || machineType.attributes;

  const { readableUsage } = machineType || {};

  const [image, setImage] = React.useState(null);
  const isSelected = selectedMachineType === parseInt(machineType.id, 10);
  const cardContainerClassName = [
    "vg-machine-type-card",
    className,
    !fixedDirection && "mobile-horizontal",
    selectedMachineType && isSelected && "selected",
    selectedMachineType && !isSelected && "not-selected",
    minimized && "minimized",
    disabled && "disabled",
  ]
    .filter(Boolean)
    .join(" ");

  React.useEffect(() => {
    try {
      // eslint-disable-next-line
      setImage(require(`Assets/images/machineTypes/${friendlyName}.png`));
    } catch {
      setImage(DEFAULT_IMAGE);
    }
  }, [friendlyName]);

  const selectMachineType = () => {
    if (shouldSavePerformance) {
      saveItemToLocalStorage(LOCAL_STORAGE.machineType, machineType.id);
    }
    if (setSelectedMachineType) {
      setSelectedMachineType(parseInt(machineType.id, 10));
    }
  };

  const teamUsageLeft = remainingBalance ? `${remainingBalance} min. left` : "No usage left";

  const organizationMachine = account?.account?.attributes?.organization_machine;

  const getFooterContent = () => {
    if (readableUsage) return readableUsage;

    if (minimized) return streamFriendlyName;
    else if (organizationMachine) return teamUsageLeft;
    return `$${price} / hour`;
  };

  const getContainerStyles = () => {
    const styles = {
      color: !minimized ? BG_COLOR_BY_MACHINE_TYPE[friendlyName] : BG_COLOR_BY_MACHINE_TYPE_FOR_APP_STREAMING,
    };
    if (appStreaming && isSelected) styles.borderColor = BG_COLOR_BY_MACHINE_TYPE_FOR_APP_STREAMING[friendlyName];
    return styles;
  };
  return (
    // eslint-disable-next-line
    <div
      className={cardContainerClassName}
      onClick={selectMachineType}
      onKeyDown={selectMachineType}
      style={getContainerStyles()}
    >
      {!minimized && machineType.attributes.remaining_credit > 0 && (
        <div
          className="free-usage"
          style={{
            backgroundColor: BG_COLOR_BY_MACHINE_TYPE[friendlyName],
            borderColor: BG_COLOR_BY_MACHINE_TYPE[friendlyName],
          }}
        >
          {machineType.attributes.remaining_credit} mins Free
        </div>
      )}
      {!minimized && (
        <div className="type">
          <div className="name">{friendlyName}</div>
          <img className="image" src={image} alt={friendlyName} />
        </div>
      )}
      {minimized && (
        <RadioInput
          checked={isDefault || isSelected}
          onChange={() => {
            if (selectedMachineType === parseInt(machineType.id, 10)) {
              setSelectedMachineType(null);
            } else {
              selectMachineType();
            }
          }}
          label=""
          className="machine-card-radio-input"
          color="aqua-main"
          centered
        />
      )}
      <div className="details">
        <div>{cpu} cores</div>
        <div>{gpu ? `${gpu > 1 ? `${gpu} x ` : ""}${parseInt(gpuMemory, 10)}GB GPU` : null}</div>
        <div>{parseInt(memory, 10)}GB RAM</div>
      </div>
      <div
        className="price"
        style={
          !appStreaming
            ? { backgroundColor: BG_COLOR_BY_MACHINE_TYPE[friendlyName] }
            : { backgroundColor: BG_COLOR_BY_MACHINE_TYPE_FOR_APP_STREAMING[friendlyName] }
        }
      >
        {getFooterContent()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps, null)(MachineTypeCard);
