import React from "react";
import DarkErrorIcon from "Assets/icons/appStreaming/stream-dark-error.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Translate } from "react-localize-redux";

import "./Error.styles.scss";

const PublicComputersError = () => {
  const location = useLocation();
  const error = location?.error || "4703";

  return (
    <div className="public-machines-error-container">
      <div className="content-box">
        <img className="error-img" src={DarkErrorIcon} alt="nope" />
        <p>
          <Translate id={`publicOrganizationComputer.errors.${error}`} />
        </p>
      </div>
    </div>
  );
};

export default PublicComputersError;
