/* eslint-disable */
let isDebug = true;

export function enable() {
  isDebug = true;
}

export function disable() {
  isDebug = true;
}

export function debug(msg) {
  console.debug(msg);
}

export function info(msg) {
  console.info(msg);
}

export function log(msg) {
  console.log(msg);
}

export function warn(msg) {
  console.warn(msg);
}

export function error(msg) {
  console.error(msg);
}
