import { all, put, takeLatest } from "redux-saga/effects";

import apiGenerator from "Utils/Helpers/api.helpers";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";

import * as ReferralConstants from "Constants/Referral.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";

function* getRewardsAPISaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.REWARDS_STATUS);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ReferralConstants.GET_REWARDS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: ReferralConstants.GET_REWARDS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: ReferralConstants.GET_REWARDS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getRewardsStatusAPISaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.REWARDS_USER_STATUS);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ReferralConstants.GET_REWARDS_STATUS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: ReferralConstants.GET_REWARDS_STATUS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: ReferralConstants.GET_REWARDS_STATUS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* claimRewardAPISaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.REWARDS_CLAIM);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ReferralConstants.CLAIM_REWARDS_API_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: ReferralConstants.GET_REWARDS_STATUS_API_PENDING,
        payload: { accessToken },
      });
    } else {
      yield put({
        type: ReferralConstants.CLAIM_REWARDS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: ReferralConstants.CLAIM_REWARDS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* inviteAPISaga(action) {
  const { accessToken, email, templateId = 0 } = action.payload;

  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.REWARDS_INVITE, { email, message_id: templateId });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: ReferralConstants.INVITE_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: ReferralConstants.INVITE_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: ReferralConstants.INVITE_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ReferralConstants.GET_REWARDS_API_PENDING, getRewardsAPISaga),
    takeLatest(ReferralConstants.GET_REWARDS_STATUS_API_PENDING, getRewardsStatusAPISaga),
    takeLatest(ReferralConstants.CLAIM_REWARDS_API_PENDING, claimRewardAPISaga),
    takeLatest(ReferralConstants.INVITE_API_PENDING, inviteAPISaga),
  ]);
}
