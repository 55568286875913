import { all, put, takeLatest } from "redux-saga/effects";

import apiGenerator from "Utils/Helpers/api.helpers";
import { Tracker } from "Utils";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";

import * as AccountConstants from "Constants/Account.constants";
import * as SubscriptionsConstants from "Constants/Dashboard/Subscriptions.constants";
import * as WorkstationConstants from "Constants/Dashboard/Workstations.constants";
import * as PaymentConstants from "Constants/Dashboard/Payment.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";

function* getUserSubscriptionSaga(action) {
  const { accessToken } = action.payload;
  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.SUBSCRIPTIONS);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* updateUserSubscriptionSaga(action) {
  const { accessToken, isPayAsYouGoEnabled } = action.payload;

  const requestPayload = {
    pay_as_you_go: isPayAsYouGoEnabled,
  };

  const api = apiGenerator("patch", accessToken)(API_ENDPOINTS.SUBSCRIPTIONS, requestPayload);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: SubscriptionsConstants.UPDATE_USER_SUBSCRIPTION_API_SUCCESS,
      });
      yield put({
        type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_PENDING,
        payload: { accessToken },
      });
    } else {
      yield put({
        type: SubscriptionsConstants.UPDATE_USER_SUBSCRIPTION_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: SubscriptionsConstants.UPDATE_USER_SUBSCRIPTION_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* cancelSubscriptionSaga(action) {
  const { accessToken } = action.payload;
  const api = apiGenerator("patch", accessToken)(API_ENDPOINTS.UNSUBSCRIBE);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: SubscriptionsConstants.CANCEL_USER_SUBSCRIPTION_API_SUCCESS,
      });
      yield put({
        type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_PENDING,
        payload: { accessToken },
      });
    } else {
      yield put({
        type: SubscriptionsConstants.CANCEL_USER_SUBSCRIPTION_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: SubscriptionsConstants.CANCEL_USER_SUBSCRIPTION_API_FAILURE,
      payload: error,
    });
  }
}

function* getUserSubscriptionPaymentInformationSaga(action) {
  const { accessToken, configs } = action.payload;
  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.GET_PAYMENT_INFORMATION, configs);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* renewSubscriptionSaga(action) {
  const { accessToken } = action.payload;
  const api = apiGenerator("patch", accessToken)(API_ENDPOINTS.RENEW_SUBSCRIPTION);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: SubscriptionsConstants.RENEW_USER_SUBSCRIPTION_API_SUCCESS,
      });
      yield put({
        type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_PENDING,
        payload: { accessToken },
      });
    } else {
      yield put({
        type: SubscriptionsConstants.RENEW_USER_SUBSCRIPTION_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: SubscriptionsConstants.RENEW_USER_SUBSCRIPTION_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* createUserWorkstationAsSubscriptionSaga(action) {
  const { accessToken, configs, balance, paymentType } = action.payload;
  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.SUBSCRIPTIONS, {
    ...configs,
    amount: balance,
    payment_type: paymentType,
  });
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      Tracker.event({ type: "subscriptionCreate", action: "success" });
      window.fbq("track", "Subscribe", {
        currency: "USD",
      });
      if (response.data.payment_required) {
        yield put({
          type: PaymentConstants.PAYMENT_3DSECURE_REQUESTED,
          payload: {
            accessToken,
            data: response.data,
            completeEndpoint: API_ENDPOINTS.SUBSCRIPTIONS_COMPLETE,
            successType: SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_SUCCESS,
            successMessage: SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_SUCCESS_MESSAGE,
            failureType: SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_FAILURE,
          },
        });
        yield put({
          type: WorkstationConstants.GET_WORKSTATIONS_API_PENDING,
          payload: { accessToken },
        });
      } else {
        // No payment required
        yield put({
          type: SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_SUCCESS,
        });
      }
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_FAILURE,
      payload: error,
    });
  }
}

function* upgradeDiskSizeSubscriptionSaga(action) {
  const { accessToken, diskSize, paymentMethod } = action.payload;

  const api = apiGenerator("patch", accessToken)(API_ENDPOINTS.UPGRADE_DISK_SIZE_SUBSCRIPTION, {
    disk_size: diskSize,
    payment_type: paymentMethod,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_SUCCESS,
      });
      yield put({
        type: AccountConstants.GET_ACCOUNT_API_PENDING,
        payload: { accessToken },
      });
      yield put({
        type: WorkstationConstants.GET_WORKSTATIONS_API_PENDING,
        payload: { accessToken },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_FAILURE,
      payload: error,
    });
  }
}

function* upgradeFileStorageSubscriptionSaga(action) {
  const { accessToken, storageSize } = action.payload;

  const api = apiGenerator("patch", accessToken)(API_ENDPOINTS.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API, {
    storage_size: storageSize,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_SUCCESS,
      });
      yield put({
        type: SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_PENDING,
        payload: { accessToken },
      });
      yield put({
        type: SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_RESET,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_FAILURE,
      payload: error,
    });
    yield put({
      type: SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_RESET,
    });
  }
}

function* getFileStorageSubscriptionPlansSaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.GET_FILE_STORAGE_SUBSCRIPTION_PLANS);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: SubscriptionsConstants.GET_FILE_STORAGE_SUBSCRIPTION_PLANS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: SubscriptionsConstants.GET_FILE_STORAGE_SUBSCRIPTION_PLANS_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(SubscriptionsConstants.GET_USER_SUBSCRIPTION_API_PENDING, getUserSubscriptionSaga),
    takeLatest(SubscriptionsConstants.UPDATE_USER_SUBSCRIPTION_API_PENDING, updateUserSubscriptionSaga),
    takeLatest(SubscriptionsConstants.CANCEL_USER_SUBSCRIPTION_API_PENDING, cancelSubscriptionSaga),
    takeLatest(SubscriptionsConstants.RENEW_USER_SUBSCRIPTION_API_PENDING, renewSubscriptionSaga),
    takeLatest(
      SubscriptionsConstants.GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_PENDING,
      getUserSubscriptionPaymentInformationSaga,
    ),
    takeLatest(
      SubscriptionsConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_PENDING,
      createUserWorkstationAsSubscriptionSaga,
    ),
    takeLatest(SubscriptionsConstants.UPGRADE_DISK_SIZE_SUBSCRIPTION_API_PENDING, upgradeDiskSizeSubscriptionSaga),
    takeLatest(
      SubscriptionsConstants.UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_PENDING,
      upgradeFileStorageSubscriptionSaga,
    ),
    takeLatest(SubscriptionsConstants.GET_FILE_STORAGE_SUBSCRIPTION_PLANS_PENDING, getFileStorageSubscriptionPlansSaga),
  ]);
}
