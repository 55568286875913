import React, { useEffect, useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionHistoryAPI, getUserPaymentHistoryAPI } from "Actions/Account.actions";
import { getPaymentMethodsAPI } from "Actions/Dashboard/Payment.actions";
import { getSeatsMeAPI } from "Actions/Organization.actions";
import { REQUEST_STATUS, TRANSACTION_TYPES } from "Constants/global.constants";
import Chart from "chart.js/auto";
import _ from "lodash";
import moment from "moment";
import { DashboardSubScreenLayout, PaymentMethods, AddBalanceModal } from "Utils";
import BillingDetails from "Utils/Components/BillingDetails/BillingDetails.component";
import NetworkUsageLimit from "Utils/Components/NetworkUsageLimit/NetworkUsageLimit.component";
import { joinWithCommaAnd, minutesToHours } from "Utils/Helpers/functions.helpers";
import { TextButton } from "UI";
import "./Billing.styles.scss";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import { API_ENDPOINTS } from "Constants/api.constants";

Chart.register();

const HEADERS = ["Date", "Invoice Number", "Description", "Amount"];

const KEYS = ["date", "invoiceNumber", "description", "totalAmount"];

const TRANSACTION_HEADERS = ["Date", "Transaction", "Description", "Amount"];

const TRANSACTION_KEYS = ["date", "invoiceNumber", "description", "amount"];

const USAGE_STATES = {
  usagePresent: "usagePresent",
  noSeat: "noSeat",
  noUsage: "noUsage",
};

const PAGES = {
  payments: 0,
  transactions: 1,
  networkUsage: 2,
};

const sortItems = (items) =>
  items.sort((a, b) => {
    if (!b) return 1;
    return b.amount - a.amount;
  });

const sumDiscountAndSubscription = (items) => {
  let discount = 0;
  let subscription = 0;
  let editedItems = [];
  items.forEach((item) => {
    if (item.description === "Subscription") {
      subscription = item.amount;
    } else if (item.description === "Discount") {
      discount = item.amount;
    }
  });
  if (subscription !== 0 && discount !== 0) {
    editedItems = items.filter((item) => item.description !== "Subscription" && item.description !== "Discount");

    editedItems.push({
      id: 0,
      description: "Subscription",
      amount: (parseFloat(subscription) + parseFloat(discount)).toFixed(2),
    });
    return editedItems;
  }
  return items;
};

const getBillingItemObjects = (billingItems) => {
  return _.map(billingItems, (billingItem) => ({
    id: billingItem.id,
    description: billingItem.attributes.description,
    amount: billingItem.attributes.amount,
  }));
};

const getBillingDetailsComponentObjects = (data) => {
  const filteredBilling = _.filter(data, (payment) => payment.attributes.amount !== "0.0");

  return _.map(filteredBilling, (payment) => {
    const billingItems = getBillingItemObjects(payment.attributes.items);
    return {
      id: payment.id,
      date: moment(payment.attributes.created_at).format("DD/MM/YYYY"),
      invoiceNumber: payment.attributes.invoice_number,
      description: payment.attributes.description,
      totalAmount: `$ ${payment.attributes.total_amount}`,
      items: sortItems(sumDiscountAndSubscription(billingItems)),
    };
  });
};

const getTransactionDetailsComponentObjects = (data) => {
  const filteredBilling = _.filter(data, (payment) => payment.attributes.amount !== "0.0");

  return _.map(filteredBilling, (payment) => {
    const positiveAmount = payment.attributes.amount > 0;
    return {
      id: payment.id,
      date: moment(payment.attributes.day).format("DD/MM/YYYY"),
      invoiceNumber: `VGN-${`00000${payment.id}`.slice(-5)}`,
      description: TRANSACTION_TYPES[payment.attributes.transaction_type],
      amount: `${positiveAmount ? "+" : "-"} $ ${Math.abs(payment.attributes.amount).toFixed(2)}`,
    };
  });
};

const BillingComponent = ({ translate }) => {
  const { account, subscriptions, payment } = useSelector((state) => state);
  const { seat, status: getSeatsMeCTXStatus } = useSelector((state) => state.organizations.getSeatsMeCTX) || {};
  const {
    remaining_usage: remainingUsage,
    maximum_usage: maximumUsage,
    deposited_usage: depositedUsage,
    plan,
    subscription_end_at: subscriptionEndAt,
  } = seat?.attributes || {};

  const machineTypes =
    plan?.attributes?.machine_types?.map((machineType) => machineType.attributes.friendly_name) || [];

  const [showAddBalanceModal, setShowAddBalanceModal] = useState(false);
  const [activePage, setActivePage] = useState(PAGES.payments);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  const dispatch = useDispatch();

  const balance = parseFloat(account.account.attributes.balance || account.account.attributes.debt).toFixed(2);
  const remainingNetworkUsage = subscriptions?.subscription?.attributes?.remaining_network_usage;
  const networkUsage = subscriptions?.subscription?.attributes?.plan?.attributes?.network?.free_usage;
  const planUsage = plan?.attributes.usage;
  const paymentMethods = payment.getPaymentMethodsCTX.data;

  const {
    data: paymentHistory,
    fetchNextPage: fetchNextPagePayments,
    isFetchingNextPage: isFetchingNextPagePayments,
    hasNextPage: hasNextPagePayments,
    isLoading: paymentHistoryLoading,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.PAYMENT_HISTORY,
    resource: "payments",
  });

  const {
    data: transactionHistory,
    fetchNextPage: fetchNextPageTransactions,
    isFetchingNextPage: isFetchingNextPageTransactions,
    hasNextPage: hasNextPageTransactions,
    isLoading: transactionHistoryLoading,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.TRANSACTION_HISTORY,
    resource: "transactions",
  });

  useEffect(() => {
    dispatch(getSeatsMeAPI());
    dispatch(getPaymentMethodsAPI());
    dispatch(getUserPaymentHistoryAPI());
    dispatch(getTransactionHistoryAPI());
  }, []);

  const usageState = useMemo(() => {
    if (!seat) {
      return USAGE_STATES.noSeat;
    }

    if (planUsage === 0 && depositedUsage === 0) {
      return USAGE_STATES.noUsage;
    }

    return USAGE_STATES.usagePresent;
  }, [seat, planUsage, depositedUsage]);

  const graphData = useMemo(() => {
    if (usageState !== USAGE_STATES.usagePresent) {
      return [0, 1, 0];
    }

    const planUsage = remainingUsage - depositedUsage;
    const used = remainingUsage - maximumUsage - depositedUsage;

    return [planUsage, used, depositedUsage];
  }, [seat, planUsage, depositedUsage, remainingUsage, maximumUsage]);

  const data = {
    labels: ["Remaining", "Used", "Additional"],
    datasets: [
      {
        data: graphData,
        backgroundColor: ["#47c2b5", "#f2f2f2", "#abb3ff"],
        cutout: "70%",
      },
    ],
  };
  const options = {
    responsive: true,
    events: [],
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const transactionsData = getTransactionDetailsComponentObjects(transactionHistory);
  const paymentsData = getBillingDetailsComponentObjects(paymentHistory);

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("organizationMemberBilling.title")}
      headerDescription={translate("organizationMemberBilling.description")}
      showBalance={false}
      waitFor={[account.transactionHistoryCTX.status, account.paymentHistoryCTX.status, getSeatsMeCTXStatus]}
      translate={translate}
    >
      <div className="organization-member-billing-container">
        <div className="billing-summary-container">
          <div className="team-info-container">
            <p className="header">Team Usages</p>
            <div className="team-usage-progress-container">
              {seat && (
                <div className="chart-container">
                  <Doughnut data={data} options={options} />
                  <div className="remaining-info">
                    {Boolean(remainingUsage) && usageState === USAGE_STATES.usagePresent && (
                      <>
                        {remainingUsage > 60 ? (
                          <>
                            <p>{minutesToHours(remainingUsage)}</p>
                            <span>Hours</span>
                          </>
                        ) : (
                          <>
                            <p>{remainingUsage}</p>
                            <span>Minutes</span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="team-usage-info-container">
                <p>{translate(`organizationMemberBilling.usageInfo.${usageState}`)}</p>
                {seat && (
                  <div className="color-explanations">
                    <div className="color-explanation">
                      <div className="color aqua" />
                      <p>Plan Usage</p>
                    </div>
                    <div className="color-explanation">
                      <div className="color baby-powder" />
                      <p>Additional Credits</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {seat && (
              <>
                <div className="divider" />
                <div className="alt-info">
                  <p>Available Performances: {seat ? joinWithCommaAnd(machineTypes) : "Not Selected"}</p>
                  <p>
                    {seat ? (
                      <>Computer Plan will be renewed on {moment(subscriptionEndAt).format("DD MMMM YYYY")}</>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </>
            )}
          </div>
          {balance > 0 && (
            <div className="personal-info-container">
              <div className="header-and-button">
                <p>Personal</p>
                {paymentMethods?.length > 0 && (
                  <TextButton
                    color="aqua-main"
                    text={showPaymentMethods ? "Personal Balance" : "Payment Methods"}
                    onClick={() => {
                      setShowPaymentMethods((prev) => !prev);
                    }}
                  />
                )}
              </div>
              {showPaymentMethods ? (
                <div className="payment-container">
                  <PaymentMethods hideAddPaymentMethod translate={translate} />
                </div>
              ) : (
                <div className="transactions-container">
                  <div className="header">{translate("organizationMemberBilling.balance.header")}</div>
                  <div className="balance-information-container">
                    <div className="balance-information-content">
                      <div className="balance-header">{translate("organizationMemberBilling.balance.title")}</div>
                      <div className="balance-amount">{balance} USD</div>
                    </div>
                  </div>
                  <div className="description">
                    <div className="divider" />
                    {translate("organizationMemberBilling.balance.description")}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {(paymentsData.length > 0 || transactionsData.length > 0) && (
          <div className="billing-history-container">
            <div className="billing-history-titles">
              <TextButton
                color={activePage === PAGES.payments ? "purple" : ""}
                disabled={activePage === PAGES.payments}
                text={translate("organizationMemberBilling.billingHistoryTabs.pastPayments")}
                onClick={() => setActivePage(PAGES.payments)}
              />
              <TextButton
                color={activePage === PAGES.transactions ? "purple" : ""}
                disabled={activePage === PAGES.transactions}
                text={translate("organizationMemberBilling.billingHistoryTabs.pastTransactions")}
                onClick={() => setActivePage(PAGES.transactions)}
              />
            </div>
            {activePage === PAGES.payments && (
              <BillingDetails
                loading={paymentHistoryLoading}
                headers={HEADERS}
                objects={paymentsData}
                fetchNextPage={fetchNextPagePayments}
                isFetchingNextPage={isFetchingNextPagePayments}
                hasNextPage={hasNextPagePayments}
                keys={KEYS}
              />
            )}
            {activePage === PAGES.transactions && (
              <BillingDetails
                loading={transactionHistoryLoading}
                headers={TRANSACTION_HEADERS}
                objects={transactionsData}
                fetchNextPage={fetchNextPageTransactions}
                isFetchingNextPage={isFetchingNextPageTransactions}
                hasNextPage={hasNextPageTransactions}
                keys={TRANSACTION_KEYS}
                hideDetails
              />
            )}
            {activePage === PAGES.networkUsage && (
              <NetworkUsageLimit
                remainingNetworkUsage={remainingNetworkUsage}
                networkUsage={networkUsage}
                translate={translate}
                personal
              />
            )}
          </div>
        )}
        {showAddBalanceModal && (
          <AddBalanceModal
            setShowAddBalanceModal={setShowAddBalanceModal}
            accessToken={account.accessToken}
            translate={translate}
          />
        )}
      </div>
    </DashboardSubScreenLayout>
  );
};

export default BillingComponent;
