import * as WorkstationConstants from "Constants/Dashboard/Workstations.constants";
import { REQUEST_STATUS, LOCAL_STORAGE } from "Constants/global.constants";
import * as PaymentConstants from "Constants/Dashboard/Payment.constants";
import * as LoginConstants from "Constants/Authentication/Login.constants";
import { saveItemToLocalStorage, getItemFromLocalStorage } from "Utils/Helpers/storage.helpers";

const initialState = {
  createWorkstationConfigCTX: {
    workstation_name: null,
    disk_size: 75,
    region: null,
    balance: null,
    ...getItemFromLocalStorage(LOCAL_STORAGE.createComputerConfigs),
  },
  getWorkstationsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  getNotificationsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  getWorkstationMachineTypesCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
  },
  updateWorkstationStatusCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  updateWorkstationAutoStopConfigurationCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  getSoftwaresCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: [],
    error: false,
  },
  installSoftwaresCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  getWorkstationCurrentSessionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    data: {},
  },
  getAppStreamingSessionCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    data: {},
    failureCount: 0,
  },
  workstations: [],
  notifications: [],
};

export default function workstations(state = initialState, action) {
  switch (action.type) {
    case WorkstationConstants.UPDATE_CREATE_WORKSTATION_CONFIG:
      return updateCreateWorkstationConfig(state, action);
    case WorkstationConstants.GET_WORKSTATIONS_API_PENDING:
      return getWorkstationsAPIPending(state);
    case WorkstationConstants.GET_WORKSTATIONS_API_SUCCESS:
      return getWorkstationsAPISuccess(state, action);
    case WorkstationConstants.GET_WORKSTATIONS_API_FAILURE:
      return getWorkstationsAPIFailure(state);
    case WorkstationConstants.GET_NOTIFICATIONS_API_PENDING:
      return getNotificationsAPIPending(state);
    case WorkstationConstants.GET_NOTIFICATIONS_API_SUCCESS:
      return getNotificationsAPISuccess(state, action);
    case WorkstationConstants.GET_NOTIFICATIONS_API_FAILURE:
      return getNotificationsAPIFailure(state);
    case WorkstationConstants.GET_WORKSTATION_MACHINE_TYPES_API_PENDING:
      return getWorkstationMachineTypesAPIPending(state);
    case WorkstationConstants.GET_WORKSTATION_MACHINE_TYPES_API_SUCCESS:
      return getWorkstationMachineTypesAPISuccess(state, action);
    case WorkstationConstants.GET_WORKSTATION_MACHINE_TYPES_API_FAILURE:
      return getWorkstationMachineTypesAPIFailure(state);
    case WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_PENDING:
      return updateWorkstationStatusAPIPending(state);
    case WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_SUCCESS:
      return updateWorkstationStatusAPISuccess(state);
    case WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_FAILURE:
      return updateWorkstationStatusAPIFailure(state, action);
    case PaymentConstants.ADD_BALANCE_SUCCESS:
    case WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_RESET:
      // When balance is added, get rid of the 'insufficient balance' error when running workstation
      return resetUpdateWorkstationStatusAPI(state);
    case WorkstationConstants.UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_PENDING:
      return updateWorkstationAutoStopConfigurationAPIPending(state);
    case WorkstationConstants.UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_SUCCESS:
      return updateWorkstationAutoStopConfigurationAPISuccess(state);
    case WorkstationConstants.UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_FAILURE:
      return updateWorkstationAutoStopConfigurationAPIFailure(state);
    case WorkstationConstants.GET_WORKSTATION_CURRENT_SESSION_PENDING:
      return getWorkstationCurrentStationPending(state);
    case WorkstationConstants.GET_WORKSTATION_CURRENT_SESSION_SUCCESS:
      return getWorkstationCurrentStationSuccess(state, action);
    case WorkstationConstants.GET_WORKSTATION_CURRENT_SESSION_FAILURE:
      return getWorkstationCurrentStationFailure(state);
    case WorkstationConstants.GET_WORKSTATION_SOFTWARES_PENDING:
      return getWorkstationSoftwaresPending(state);
    case WorkstationConstants.GET_WORKSTATION_SOFTWARES_SUCCESS:
      return getWorkstationSoftwaresSuccess(state, action);
    case WorkstationConstants.GET_WORKSTATION_SOFTWARES_FAILURE:
      return getWorkstationSoftwaresFailure(state);
    case WorkstationConstants.INSTALL_WORKSTATION_SOFTWARES_PENDING:
      return installWorkstationSoftwaresPending(state);
    case WorkstationConstants.INSTALL_WORKSTATION_SOFTWARES_SUCCESS:
      return installWorkstationSoftwaresSuccess(state, action);
    case WorkstationConstants.INSTALL_WORKSTATION_SOFTWARES_FAILURE:
      return installWorkstationSoftwaresFailure(state);
    case WorkstationConstants.VALIDATE_WORKSTATION_SOFTWARES_SUCCESS:
      return validateWorkstationSoftwaresSuccess(state, action);
    case WorkstationConstants.GET_APP_STREAMING_SESSION_PENDING:
      return getAppStreamingSessionPending(state);
    case WorkstationConstants.GET_APP_STREAMING_SESSION_SUCCESS:
      return getAppStreamingSessionSuccess(state, action);
    case WorkstationConstants.GET_APP_STREAMING_SESSION_FAILURE:
      return getAppStreamingSessionFailure(state, action);
    case WorkstationConstants.GET_APP_STREAMING_SESSION_RESET:
      return getAppStreamingSessionReset(state);
    case LoginConstants.LOGOUT_API_PENDING:
      return initialState;
    default:
      return state;
  }
}

function updateCreateWorkstationConfig(state, action) {
  const { config } = action.payload;
  saveItemToLocalStorage(LOCAL_STORAGE.createComputerConfigs, config);

  return {
    ...state,
    createWorkstationConfigCTX: {
      ...state.createWorkstationConfigCTX,
      ...config,
    },
  };
}

function getWorkstationsAPIPending(state) {
  let workstationsCTXStatus = REQUEST_STATUS.PENDING;
  switch (state.getWorkstationsCTX.status) {
    case REQUEST_STATUS.NOT_DEFINED:
      workstationsCTXStatus = REQUEST_STATUS.NOT_DEFINED;
      break;
    case REQUEST_STATUS.FAILURE:
      workstationsCTXStatus = REQUEST_STATUS.FAILURE;
      break;
    default:
      workstationsCTXStatus = REQUEST_STATUS.PENDING;
  }

  return {
    ...state,
    getWorkstationsCTX: {
      status: workstationsCTXStatus,
      error: false,
    },
  };
}

function getWorkstationsAPISuccess(state, action) {
  const { workstations, notifications } = action.payload;
  let updateWorkstationStatusCTX = { ...state.updateWorkstationStatusCTX };

  if (state.updateWorkstationStatusCTX.status === REQUEST_STATUS.PENDING) {
    updateWorkstationStatusCTX = {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    };
  }

  return {
    ...state,
    getWorkstationsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
    updateWorkstationStatusCTX,
    workstations,
    notifications,
  };
}

function getWorkstationsAPIFailure(state) {
  return {
    ...state,
    getWorkstationsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
    workstations: [],
  };
}

function getNotificationsAPIPending(state) {
  return {
    ...state,
    getNotificationsCTX: {
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function getNotificationsAPISuccess(state, action) {
  const { notifications } = action.payload;

  return {
    ...state,
    getNotificationsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
    notifications,
  };
}

function getNotificationsAPIFailure(state) {
  return {
    ...state,
    getNotificationsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getWorkstationMachineTypesAPIPending(state) {
  return {
    ...state,
    getWorkstationMachineTypesCTX: {
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function getWorkstationMachineTypesAPISuccess(state, action) {
  return {
    ...state,
    getWorkstationMachineTypesCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function getWorkstationMachineTypesAPIFailure(state) {
  return {
    ...state,
    getWorkstationMachineTypesCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function updateWorkstationStatusAPIPending(state) {
  return {
    ...state,
    updateWorkstationStatusCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function updateWorkstationStatusAPISuccess(state) {
  return {
    ...state,
    updateWorkstationStatusCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function updateWorkstationStatusAPIFailure(state, action) {
  return {
    ...state,
    updateWorkstationStatusCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: action.payload,
    },
  };
}

function resetUpdateWorkstationStatusAPI(state) {
  return {
    ...state,
    updateWorkstationStatusCTX: {
      status: REQUEST_STATUS.NOT_DEFINED,
      error: false,
    },
  };
}

function updateWorkstationAutoStopConfigurationAPIPending(state) {
  return {
    ...state,
    updateWorkstationAutoStopConfigurationCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function updateWorkstationAutoStopConfigurationAPISuccess(state) {
  return {
    ...state,
    updateWorkstationAutoStopConfigurationCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function updateWorkstationAutoStopConfigurationAPIFailure(state) {
  return {
    ...state,
    updateWorkstationAutoStopConfigurationCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getWorkstationCurrentStationPending(state) {
  return {
    ...state,
    getWorkstationCurrentSessionCTX: {
      ...state.getWorkstationCurrentSessionCTX,
      error: false,
    },
  };
}

function getWorkstationCurrentStationFailure(state) {
  return {
    ...state,
    getWorkstationCurrentSessionCTX: {
      ...state.getWorkstationCurrentSessionCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getWorkstationCurrentStationSuccess(state, action) {
  return {
    ...state,
    getWorkstationCurrentSessionCTX: {
      ...state.getWorkstationCurrentSessionCTX,
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
      error: false,
    },
  };
}

function getWorkstationSoftwaresPending(state) {
  return {
    ...state,
    getSoftwaresCTX: {
      status: REQUEST_STATUS.PENDING,
      data: [],
      error: false,
    },
  };
}

function getWorkstationSoftwaresSuccess(state, action) {
  const { softwares, golden_images: packages } = action.payload;
  return {
    ...state,
    getSoftwaresCTX: {
      status: REQUEST_STATUS.SUCCESS,
      softwares,
      packages,
      error: false,
    },
  };
}

function getWorkstationSoftwaresFailure(state) {
  return {
    ...state,
    getSoftwaresCTX: {
      status: REQUEST_STATUS.FAILURE,
      data: [],
      error: true,
    },
  };
}

function installWorkstationSoftwaresPending(state) {
  return {
    ...state,
    installSoftwaresCTX: {
      ...state.installSoftwaresCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function installWorkstationSoftwaresSuccess(state) {
  return {
    ...state,
    installSoftwaresCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function installWorkstationSoftwaresFailure(state) {
  return {
    ...state,
    installSoftwaresCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function validateWorkstationSoftwaresSuccess(state, action) {
  const {
    validated,
    installation_time: estimatedTime,
    silver_image_size: estimatedSize,
    installer_size: installerSize,
  } = action.payload;
  return {
    ...state,
    installSoftwaresCTX: {
      ...state.installSoftwaresCTX,
      validated,
      estimatedTime,
      estimatedSize,
      installerSize,
    },
  };
}

function getAppStreamingSessionPending(state) {
  return {
    ...state,
    getAppStreamingSessionCTX: {
      ...state.getAppStreamingSessionCTX,
      error: false,
    },
  };
}

function getAppStreamingSessionSuccess(state, action) {
  return {
    ...state,
    getAppStreamingSessionCTX: {
      ...state.getAppStreamingSessionCTX,
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
      error: false,
      failureCount: 0,
    },
  };
}

function getAppStreamingSessionFailure(state, action) {
  return {
    ...state,
    getAppStreamingSessionCTX: {
      ...state.getAppStreamingSessionCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
      data: action.payload,
      failureCount: state.getAppStreamingSessionCTX.failureCount + 1,
    },
  };
}

function getAppStreamingSessionReset(state) {
  return {
    ...state,
    getAppStreamingSessionCTX: {
      ...initialState.getAppStreamingSessionCTX,
    },
  };
}
