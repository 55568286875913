import React from "react";
import { classnames, Loader } from "Utils";
import "./Table.styles.scss";

const Table = ({ columns, data, loading, footer, className: classNameFromProps, hideColumnNames }) => {
  // when the first column of each row is not unique a hidden first column can be given with weight = 0
  // this column won't be displayed but will be used to create unique row and cell keys

  const shownColumns = columns.filter((col) => col.weight !== 0 || (col.customWeight && col.customWeight !== 0));
  const columnNames = shownColumns.map((col) => (col.hide ? "" : col.name));
  const keyNames = shownColumns.map((col) => col.name.toLowerCase());
  const allKeyNames = columns.map((col) => col.name.toLowerCase());
  const firstColumnKey = allKeyNames[0];

  const totalWeight = shownColumns.reduce((acc, col) => {
    return acc + col.weight;
  }, 0);
  const columnWidths = Object.fromEntries(
    shownColumns.map((col) => {
      let percent = Math.round(100 * (col.weight / totalWeight));
      if (col.customWeight) percent = Math.round(col.customWeight);
      return [col.name, `${percent}%`];
    }),
  );
  const headerCells = columnNames.map((name) => {
    const columnWidth = { width: columnWidths[name] };
    const key = `header-${name}`;
    return (
      <th key={key} style={columnWidth}>
        {!hideColumnNames && name}
      </th>
    );
  });

  const bodyRows = data?.map((rowData) => {
    const rowKey = rowData[firstColumnKey]; // Assuming the first column of each row do not change and are unique
    const rowCells = keyNames.map((key) => {
      const cellKey = `body-${rowKey}-${key}`;

      const columnWidth = {};
      return (
        <td key={cellKey} style={columnWidth}>
          {rowData[key]}
        </td>
      );
    });

    return <tr key={rowKey}>{rowCells}</tr>;
  });
  if (loading) {
    return <Loader />;
  }

  const footerCells = () => {
    const footerCells = [];
    const colCount = shownColumns.length;
    if (footer.length === 1) return <td colSpan={shownColumns.length}>{footer[0]}</td>;
    if (footer.length === 2) {
      footerCells.push(<td key={footer[0]}>{footer[0]}</td>);
      for (let i = 0; i < colCount - 2; i += 1) {
        footerCells.push(<td key={i} />);
      }
      footerCells.push(<td key={footer[1]}>{footer[1]}</td>);
      return footerCells;
    }
    return footer?.map((cellData) => <td key={cellData}>{cellData}</td>);
  };

  return (
    <div className="vg-table-container">
      <table className={classnames(["vg-table", classNameFromProps])}>
        <thead>
          <tr>{headerCells}</tr>
        </thead>
        <tbody>{bodyRows}</tbody>
        {footer && (
          <tfoot>
            <tr>{footerCells()}</tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default Table;
