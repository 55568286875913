export const APPLICATIONS_ONBOARDING_STEPS = {
  applicationsInitial: { step: 0 },
  createApplication: { step: 1 },
  applicationsTab: { step: 2 },
};

export const STREAMS_ONBOARDING_STEPS = {
  appChoose: { step: 0 },
  sampleStream: { step: 1 },
};

export const BILLING_ONBOARDING_STEPS = {
  billingTab: { step: 0 },
  balance: { step: 1 },
  paymentMethod: { step: 2 },
  summaryTable: { step: 3 },
  transactionsTable: { step: 4 },
  paymentsRow: { step: 5 },
};

export const STATS_ONBOARDING_STEPS = {
  statsTab: { step: 0 },
  graph: { step: 1 },
  sessions: { step: 2 },
  filter: { step: 3 },
};

export const SETTINGS_ONBOARDING_STEPS = {
  plans: { step: 0 },
  users: { step: 1 },
};

export const APPLICATION_DUMMY_DATA = [
  {
    attributes: {
      name: "Sample Application",
      friendly_status: "no_streams",
      status: "no_streams",
      highlightClass: "sample-application",
    },
    id: "1",
    type: "vendor_application",
  },
];

export const APPLICATION_REGION_DUMMY_DATA = ["dublin", "frankfurt", "north_virginia", "stockholm", "montreal"];

export const APPLICATION_STATS_DUMMY_DATA = {
  CONNECTED_USERS: "3",
  ACTIVE_STREAMS: "2",
};

export const STREAMS_APPS_DUMMY_DATA = {
  applications: [
    {
      attributes: {
        dummy_logo_url:
          "https://stg-application-streaming-assets-bucket.s3.eu-west-1.amazonaws.com/default-logos/vagon-streams-apps-dummy-02.png",
        id: 10001,
        logo_url: null,
        name: "Sample Game",
        highlightClass: "sample-app",
      },
    },
    {
      attributes: {
        dummy_logo_url:
          "https://stg-application-streaming-assets-bucket.s3.eu-west-1.amazonaws.com/default-logos/vagon-streams-apps-dummy-04.png",
        id: 10001,
        logo_url: null,
        name: "Sample Application",
      },
    },
  ],
};

export const STREAMS_DUMMY_DATA = [
  {
    attributes: {
      name: "Sample Game",
      status: "active",
      streams: [
        {
          id: "1",
          attributes: {
            budget: null,
            default_machine_type: {},
            child_streams: [],
            name: "Sample Game",
            status: "active",
            id: "1",
            type: "vendor_stream",
            sum_of_all_capacities: 100,
            sum_of_connected_machines: 0,
            sum_of_all_used_capacities: 0,
            boost_enabled: false,
            boost_ready: false,
            pixel_streaming_enabled: false,
            streams: [
              {
                attributes: {},
                id: "1",
                type: "vendor_stream",
              },
            ],
            budget_limit_reached: false,
            url_domain: "www.stream1.com",
            uid: "stream1",
            connection_link: "https://streams.vagon.io/streams/sample-game",
            capacities: [
              {
                attributes: {
                  assignable_machine_count: 0,
                  capacity_type: "on_demand",
                  machine_type_id: 11,
                  region: "dublin",
                  reserve_capacity: 0,
                  total_capacity: 10,
                  used_capacity: 0,
                },
                id: "dublin",
                type: "vendor_capacity",
              },
            ],
            highlightClass: "sample-stream",
          },
          type: "application_streams",
        },
      ],
    },
    id: "1",
    type: "application_streams",
  },
];

export const CREATE_STREAMS_DUMMY_DATA = {
  attributes: {
    capacities: [
      {
        attributes: {
          capacity_type: "on_demand",
        },
      },
    ],
    connection_link: "https://streams.vagon.io/streams/my-project",
    resolution: "res_1080p",
  },
};

export const CREATE_STREAMS_CAPACITY_DUMMY_DATA = [
  { region: "dublin", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "frankfurt", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  {
    region: "north_virginia",
    machine_type_id: 11,
    capacity_type: "on_demand",
    reserve_capacity: 0,
    total_capacity: 0,
  },
  { region: "oregon", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  {
    region: "northern_california",
    machine_type_id: 11,
    capacity_type: "on_demand",
    reserve_capacity: 0,
    total_capacity: 0,
  },
  { region: "mumbai", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "singapore", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "sydney", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "bahrain", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "tokyo", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "sao_paulo", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "hong_kong", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "ohio", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "stockholm", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "montreal", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "seoul", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "cape_town", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "london", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "paris", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
  { region: "milan", machine_type_id: 11, capacity_type: "on_demand", reserve_capacity: 0, total_capacity: 0 },
];

export const BILLING_MULTIPLE_PAYMENTS_DUMMY_DATA = {
  data: [
    {
      attributes: {
        amount: "1.0",
        created_at: "2023-11-22T09:09:38.877Z",
        invoice_number: "0949EF76-0023",
        payment_type: "deposit",
        status: "paid",
        highlightClass: "onboarding-payment",
      },
      id: "284",
      type: "vendor_payment",
    },
    {
      attributes: {
        amount: "14.0",
        created_at: "2023-11-22T09:02:18.368Z",
        invoice_number: "0949EF76-0022",
        payment_type: "deposit",
        status: "paid",
      },
      id: "283",
      type: "vendor_payment",
    },
    {
      attributes: {
        amount: "7.0",
        created_at: "2023-11-22T09:02:18.368Z",
        invoice_number: "0949EF76-0022",
        payment_type: "deposit",
        status: "paid",
      },
      id: "283",
      type: "vendor_payment",
    },
    {
      attributes: {
        amount: "26.0",
        created_at: "2023-11-22T09:02:18.368Z",
        invoice_number: "0949EF76-0022",
        payment_type: "deposit",
        status: "paid",
      },
      id: "283",
      type: "vendor_payment",
    },
    {
      attributes: {
        amount: "50.0",
        created_at: "2023-11-22T09:02:18.368Z",
        invoice_number: "0949EF76-0022",
        payment_type: "deposit",
        status: "paid",
      },
      id: "283",
      type: "vendor_payment",
    },
  ],
};

export const STATS_SESSIONS_DUMMY_DATA = [
  {
    attributes: {
      application_name: "Sample Game",
      visitor: "CUS_6420",
      start_at: "2024-02-17T05:48:20.507Z",
      region: "Dublin",
      performance: "Starter",
      duration: "72",
      key: "001",
      connection_status: "terminated",
      status: "stopped",
      stream_name: "Stream #385",
      cost: "12.4",
    },
    id: "001",
  },
  {
    attributes: {
      application_name: "Sample Game",
      visitor: "CUS_6421",
      start_at: "2024-02-13T18:43:20.507Z",
      region: "Frankfurt",
      performance: "Starter",
      duration: "51",
      key: "002",
      connection_status: "terminated",
      status: "stopped",
      stream_name: "Stream #385",
      cost: "20.42",
    },
    id: "002",
  },
  {
    attributes: {
      application_name: "Sample Game",
      visitor: "CUS_6422",
      start_at: "2024-02-06T14:02:20.507Z",
      region: "Frankfurt",
      performance: "Starter",
      duration: "102",
      key: "003",
      connection_status: "terminated",
      status: "stopped",
      stream_name: "Stream #385",
      cost: "10.0",
    },
    id: "003",
  },
  {
    attributes: {
      application_name: "Sample Game",
      visitor: "CUS_6423",
      start_at: "2024-02-02T15:14:20.507Z",
      region: "Dublin",
      performance: "Starter",
      duration: "52",
      key: "004",
      connection_status: "terminated",
      status: "stopped",
      stream_name: "Stream #385",
      cost: "15.0",
    },
    id: "004",
  },
  {
    attributes: {
      application_name: "Sample Game",
      visitor: "CUS_6424",
      start_at: "2024-02-02T19:23:20.507Z",
      region: "Frankfurt",
      performance: "Starter",
      duration: "158",
      key: "005",
      connection_status: "terminated",
      status: "stopped",
      stream_name: "Stream #385",
      cost: "27.74",
    },
    id: "005",
  },
];

export const STATS_SESSIONS_FILTERED_DUMMY_DATA = [
  {
    attributes: {
      application_name: "Sample Game",
      visitor: "CUS_6420",
      start_at: "2024-02-17T05:48:20.507Z",
      region: "Dublin",
      performance: "Starter",
      duration: "72",
      key: "001",
      connection_status: "terminated",
      status: "stopped",
      stream_name: "Stream #385",
      cost: "12.4",
    },
    id: "001",
  },

  {
    attributes: {
      application_name: "Sample Game",
      visitor: "CUS_6423",
      start_at: "2024-02-02T15:14:20.507Z",
      region: "Dublin",
      performance: "Starter",
      duration: "52",
      key: "004",
      connection_status: "terminated",
      status: "stopped",
      stream_name: "Stream #385",
      cost: "15.0",
    },
    id: "004",
  },
];

export const STATS_GRAPH_DUMMY_DATA = [
  { count: 0, time: "1/02" },
  { count: 2, time: "2/02" },
  { count: 0, time: "3/02" },
  { count: 0, time: "4/02" },
  { count: 0, time: "5/02" },
  { count: 1, time: "6/02" },
  { count: 0, time: "7/02" },
  { count: 0, time: "8/02" },
  { count: 0, time: "9/02" },
  { count: 0, time: "10/02" },
  { count: 0, time: "11/02" },
  { count: 0, time: "12/02" },
  { count: 1, time: "13/02" },
  { count: 0, time: "14/02" },
  { count: 0, time: "15/02" },
  { count: 0, time: "16/02" },
  { count: 1, time: "17/02" },
  { count: 0, time: "18/02" },
  { count: 0, time: "19/02" },
  { count: 0, time: "20/02" },
  { count: 0, time: "21/02" },
];

export const STATS_GRAPH_INFO_DUMMY_DATA = {
  ACTIVE_STREAMS: "5",
  TOTAL_DURATION: "7.5",
};

export const STATS_GRAPH_FILTERED_DUMMY_DATA = [
  { count: 0, time: "1/02" },
  { count: 1, time: "2/02" },
  { count: 0, time: "3/02" },
  { count: 0, time: "4/02" },
  { count: 0, time: "5/02" },
  { count: 0, time: "6/02" },
  { count: 0, time: "7/02" },
  { count: 0, time: "8/02" },
  { count: 0, time: "9/02" },
  { count: 0, time: "10/02" },
  { count: 0, time: "11/02" },
  { count: 0, time: "12/02" },
  { count: 0, time: "13/02" },
  { count: 0, time: "14/02" },
  { count: 0, time: "15/02" },
  { count: 0, time: "16/02" },
  { count: 1, time: "17/02" },
  { count: 0, time: "18/02" },
  { count: 0, time: "19/02" },
  { count: 0, time: "20/02" },
  { count: 0, time: "21/02" },
];

export const STATS_GRAPH_INFO_FILTERED_DUMMY_DATA = {
  ACTIVE_STREAMS: "2",
  TOTAL_DURATION: "2",
};

export const SETTINGS_USER_INVITE = [
  {
    attributes: {
      email: "vagon+user@vagon.io",
    },
    id: "001",
    type: "vendor_admin_invitation",
  },
];
