import React from "react";
import DummyAppLogo from "Assets/images/appStreaming/dummy-app-logo.png";
import { classnames } from "../../../../Utils";
import { DivButton, Icon } from "../../../../UI";

import "./AppListCard.styles.scss";

const AppListCard = ({ name, logo, onClick, withoutCaret, selected, highlightClass, bundledApp }) => {
  return (
    <div className={classnames(["app-list-card-container", highlightClass === "sample-app" && "sample-app"])}>
      <DivButton className={classnames(["horizontal-app-card", selected && "selected"])} onClick={onClick}>
        <div className="image-container">
          <img src={logo || DummyAppLogo} alt="" />
        </div>
        <div className="app-name">
          <p>{name}</p>
          {bundledApp && <div className="bundled-app-badge">App Bundle</div>}
        </div>
        {!withoutCaret && <Icon name="right-empty-caret" className="app-caret" smaller />}
      </DivButton>
    </div>
  );
};

export default AppListCard;
