export const isElectronApp = window.require && window.require("electron");

const electron = window.require ? window.require("electron") : {};

export default class Electron {
  static sendAsyncMessage(msg) {
    const { ipcRenderer } = electron;
    if (ipcRenderer) ipcRenderer.send("asynchronous-message", msg);
  }

  static sendSyncMessage(msg) {
    const { ipcRenderer } = electron;
    if (ipcRenderer) ipcRenderer.send("synchronous-message", msg);
  }

  static getElectronVersion() {
    if (isElectronApp) {
      const version = process.versions.electron;
      return version;
    }

    return null;
  }
}
