export default class LinuxSignaler {
  constructor(url, onConnected, onDisconnected, onOffer, onCandidate, sessionId) {
    this.url = url;
    this.onConnected = onConnected;
    this.onDisconnected = onDisconnected;
    this.onOffer = onOffer;
    this.onCandidate = onCandidate;
    this.sessionId = sessionId;
  }

  connect = () => {
    this.socket = new WebSocket(this.url);
    this.socket.addEventListener("open", () => {
      this.onConnected();
    });
    this.socket.addEventListener("close", this.onDisconnected);
    this.socket.addEventListener("message", this.onMessage);
  };

  disconnect = () => {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  };

  onMessage = (evt) => {
    if (evt.data === "HELLO") return;

    const parsed = JSON.parse(evt.data);
    if (parsed.sdp && parsed.sdp.type === "offer") {
      this.onOffer(parsed.sdp.sdp);
      return;
    }

    if (parsed.ice) {
      this.onCandidate(parsed.ice);
    }
  };

  sendInit = () => {
    this.socket.send("HELLO 1"); // TODO: Don't hardcode the session id
    // TODO: Restart connection if we don't get an answer in a number of seconds
  };

  sendAnswer = (answer) => {
    const msg = {
      sdp: {
        type: "answer",
        sdp: answer.sdp,
      },
    };
    this.socket.send(JSON.stringify(msg));
  };

  sendCandidate = (ice) => {
    const msg = {
      ice: {
        candidate: ice.candidate,
        sdpMid: ice.sdpMid,
        sdpMLineIndex: ice.sdpMLineIndex,
      },
    };
    this.socket.send(JSON.stringify(msg));
  };

  sendDisconnected = () => {};
}
