import React from "react";
import { classnames } from "Utils";
import { Button, IconButton, TextButton } from "UI";
import "./ConfigurationOptionCard.styles.scss";

const ConfigurationOptionCard = ({
  header,
  description,
  selectedPreview,
  expandableContent,
  optionName,
  expandingOption,
  setExpandingOption,
  onBack,
  onSave,
  onExpandClose,
  saveButtonDisabled,
  saved,
  isLastItem,
}) => {
  const expanded = optionName === expandingOption;
  const handleExpand = () => {
    if (expanded) {
      onExpandClose();
      setExpandingOption(null);
    } else setExpandingOption(optionName);
  };

  return (
    <>
      <div className={classnames(["configuration-option-card", expanded && "expanded"])}>
        <div className="preview" onClick={handleExpand}>
          <div className="info">
            <p className="option-name">{header}</p>
            <p className="option-description">{description}</p>
          </div>
          <div className="action-info">
            <p>{selectedPreview}</p>
            <IconButton
              name={saved ? "check" : "teams-plus"}
              small
              color={saved && "aqua-main"}
              onClick={handleExpand}
            />
          </div>
        </div>
        <div className={classnames(["content", expanded && "expanded"])}>
          {expandableContent}
          <div className="buttons-container">
            <TextButton text="Back" color="aqua-main" disabled={!onBack} onClick={onBack} />
            <Button text="Save Selection" color="aqua-main" onClick={onSave} disabled={saveButtonDisabled} />
          </div>
        </div>
      </div>

      {!expanded && !isLastItem && <div className="horizontal-divider" />}
    </>
  );
};

export default ConfigurationOptionCard;
