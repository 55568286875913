import { all, put, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import apiGenerator from "Utils/Helpers/api.helpers";
import { Tracker } from "Utils";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";

import * as WorkstationConstants from "Constants/Dashboard/Workstations.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";
import { AUTO_STOP_OPTIONS } from "Constants/global.constants";

function* getWorkstationsAPISaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.WORKSTATIONS);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.GET_WORKSTATIONS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: WorkstationConstants.GET_WORKSTATIONS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: WorkstationConstants.GET_WORKSTATIONS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getWorkstationCurrentSessionSaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.CURRENT_SESSION);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.GET_WORKSTATION_CURRENT_SESSION_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: WorkstationConstants.GET_WORKSTATION_CURRENT_SESSION_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: WorkstationConstants.GET_WORKSTATION_CURRENT_SESSION_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getNotificationsAPISaga() {
  const api = apiGenerator("get")(API_ENDPOINTS.NOTIFICATIONS);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.GET_NOTIFICATIONS_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: WorkstationConstants.GET_NOTIFICATIONS_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: WorkstationConstants.GET_NOTIFICATIONS_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getAppStreamingSessionSaga(action) {
  const { id } = action.payload;
  const api = apiGenerator("get")(API_ENDPOINTS.APP_STREAMING_SESSION(id));

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.GET_APP_STREAMING_SESSION_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: WorkstationConstants.GET_APP_STREAMING_SESSION_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: WorkstationConstants.GET_APP_STREAMING_SESSION_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getWorkstationMachineTypesAPISaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.WORKSTATION_MACHINE_TYPES);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.GET_WORKSTATION_MACHINE_TYPES_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: WorkstationConstants.GET_WORKSTATION_MACHINE_TYPES_API_SUCCESS,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: WorkstationConstants.GET_WORKSTATION_MACHINE_TYPES_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* updateWorkstationStatusAPISaga(action) {
  const { status, machineTypeId } = action.payload;
  const endpoint = `${API_ENDPOINTS.WORKSTATIONS}/${status}`;

  const api = apiGenerator("post")(endpoint, status === "start" && { machine_type_id: machineTypeId });

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_SUCCESS,
      });
      yield put({
        type: WorkstationConstants.GET_WORKSTATIONS_API_PENDING,
      });
    } else {
      const error = apiErrorHandler({ response });

      yield put({
        type: WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_FAILURE,
        payload: error,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_FAILURE,
      payload: error,
    });
  }
}

function* updateWorkstationAutoStopConfigurationSaga(action) {
  const { accessToken, autoTurnOffSetting } = action.payload;
  const endpoint = `${API_ENDPOINTS.WORKSTATIONS}/auto-stop`;

  try {
    const payload = {
      enabled: !!(AUTO_STOP_OPTIONS[autoTurnOffSetting] !== AUTO_STOP_OPTIONS.OFF),
      threshold: AUTO_STOP_OPTIONS[autoTurnOffSetting] || 0,
    };

    if (payload) {
      const api = apiGenerator("post", accessToken)(endpoint, payload);

      const response = yield api;

      if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
        yield put({
          type: WorkstationConstants.UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_SUCCESS,
        });
        yield put({
          type: WorkstationConstants.GET_WORKSTATIONS_API_PENDING,
          payload: { accessToken },
        });
      } else {
        yield put({
          type: WorkstationConstants.UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_FAILURE,
          payload: apiErrorHandler({ response }),
        });
      }
    }
  } catch (err) {
    yield put({
      type: WorkstationConstants.UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getWorkstationSoftwaresSaga(action) {
  const { accessToken } = action.payload;
  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.SOFTWARES);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.GET_WORKSTATION_SOFTWARES_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: WorkstationConstants.GET_WORKSTATION_SOFTWARES_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: WorkstationConstants.GET_WORKSTATION_SOFTWARES_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* installWorkstationSoftwaresSaga(action) {
  const { accessToken, softwares, goldenImageId } = action.payload;

  if (!goldenImageId && (!softwares || softwares.length < 1)) {
    return;
  }

  const softwareIds = _.map(softwares, (sw) => parseInt(sw, 10));

  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.INSTALL_SOFTWARES, {
    softwares: softwareIds,
    golden_image_id: goldenImageId,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.INSTALL_WORKSTATION_SOFTWARES_SUCCESS,
      });
      yield put({
        type: WorkstationConstants.GET_WORKSTATIONS_API_PENDING,
        payload: { accessToken },
      });
    } else {
      const error = apiErrorHandler({ response });

      yield put({
        type: WorkstationConstants.INSTALL_WORKSTATION_SOFTWARES_FAILURE,
        payload: error,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: WorkstationConstants.INSTALL_WORKSTATION_SOFTWARES_FAILURE,
      payload: error,
    });
  }
}

function* validateWorkstationSoftwaresSaga(action) {
  const { accessToken, softwares, goldenImageId, diskSize, region } = action.payload;
  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.VALIDATE_SOFTWARES, {
    softwares,
    golden_image_id: goldenImageId,
    disk_size: diskSize,
    region,
  });

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: WorkstationConstants.VALIDATE_WORKSTATION_SOFTWARES_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: WorkstationConstants.VALIDATE_WORKSTATION_SOFTWARES_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: WorkstationConstants.VALIDATE_WORKSTATION_SOFTWARES_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(WorkstationConstants.GET_WORKSTATIONS_API_PENDING, getWorkstationsAPISaga),
    takeLatest(WorkstationConstants.GET_WORKSTATION_MACHINE_TYPES_API_PENDING, getWorkstationMachineTypesAPISaga),
    takeLatest(WorkstationConstants.UPDATE_WORKSTATION_STATUS_API_PENDING, updateWorkstationStatusAPISaga),
    takeLatest(WorkstationConstants.GET_NOTIFICATIONS_API_PENDING, getNotificationsAPISaga),
    takeLatest(
      WorkstationConstants.UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_PENDING,
      updateWorkstationAutoStopConfigurationSaga,
    ),
    takeLatest(WorkstationConstants.GET_WORKSTATION_CURRENT_SESSION_PENDING, getWorkstationCurrentSessionSaga),
    takeLatest(WorkstationConstants.GET_APP_STREAMING_SESSION_PENDING, getAppStreamingSessionSaga),
    takeLatest(WorkstationConstants.GET_WORKSTATION_SOFTWARES_PENDING, getWorkstationSoftwaresSaga),
    takeLatest(WorkstationConstants.INSTALL_WORKSTATION_SOFTWARES_PENDING, installWorkstationSoftwaresSaga),
    takeLatest(WorkstationConstants.VALIDATE_WORKSTATION_SOFTWARES_PENDING, validateWorkstationSoftwaresSaga),
  ]);
}
