import React from "react";
import "./Avatar.styles.scss";

import digitalArtImage from "Assets/images/purposes/digital-art.png";
import motionImage from "Assets/images/purposes/motion.png";
import gameImage from "Assets/images/purposes/game.png";
import architectureImage from "Assets/images/purposes/architecture.png";
import engineeringImage from "Assets/images/purposes/engineering.png";
import otherImage from "Assets/images/purposes/other.png";
import { classnames } from "Utils";

const AVATAR_IMAGES = {
  0: digitalArtImage,
  1: motionImage,
  2: gameImage,
  3: architectureImage,
  4: engineeringImage,
  5: otherImage,
};

const Avatar = (props) => {
  const { img, imgKey, content, size = 30 } = props;

  return (
    <div className={classnames(["vg-avatar"])} style={{ width: size, height: size }}>
      {!img ? <p>{content} </p> : <img src={img || AVATAR_IMAGES[imgKey || 0]} />}
    </div>
  );
};

export const AvatarGroup = (props) => {
  const { amount = 4, size } = props;
  return (
    <div className="vg-avatar-group">
      {[...Array(amount).keys()].map((index) => (
        <Avatar key={index} imgKey={index} size={size} />
      ))}
    </div>
  );
};

export default Avatar;
