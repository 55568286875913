import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BillingComponent from "Components/Dashboard/Account/Billing/Billing.component";
import {
  payment3DSecureAPI,
  getPaymentMethodsAPI,
  getPaymentClientSecretAPI,
  createUserPaymentMethodAPI,
} from "Actions/Dashboard/Payment.actions";
import { getWorkstationsAPI, getWorkstationMachineTypesAPI } from "Actions/Dashboard/Workstations.actions";
import { getUserSubscriptionAPI } from "Actions/Dashboard/Subscriptions.actions";
import { getUserPaymentHistoryAPI, getTransactionHistoryAPI, getAccountAPI } from "Actions/Account.actions";
import { REQUEST_STATUS } from "Constants/global.constants";

class BillingContainer extends React.Component {
  componentDidMount() {
    const {
      workstations,
      getPaymentMethodsAPI,
      getWorkstationsAPI,
      getUserPaymentHistoryAPI,
      getTransactionHistoryAPI,
      getWorkstationMachineTypesAPI,
      getUserSubscriptionAPI,
    } = this.props;
    getPaymentMethodsAPI();
    getUserPaymentHistoryAPI();
    getTransactionHistoryAPI();
    getWorkstationMachineTypesAPI();
    getUserSubscriptionAPI();

    if (workstations.getWorkstationsCTX.status === REQUEST_STATUS.NOT_DEFINED) {
      getWorkstationsAPI();
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      account,
      payment,

      getAccountAPI,
      getUserPaymentHistoryAPI,
      getTransactionHistoryAPI,
    } = this.props;
    // Adding balance successfully
    if (
      prevProps.payment.addBalanceCTX.status === REQUEST_STATUS.PENDING &&
      payment.addBalanceCTX.status === REQUEST_STATUS.SUCCESS
    ) {
      getAccountAPI(account.accessToken);
      getUserPaymentHistoryAPI(account.accessToken);
      getTransactionHistoryAPI(account.accessToken);
    }
  };

  render() {
    return <BillingComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  workstations: state.workstations,
  payment: state.payment,
  account: state.account,
  subscriptions: state.subscriptions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      payment3DSecureAPI,
      getAccountAPI,
      getPaymentMethodsAPI,
      getPaymentClientSecretAPI,
      createUserPaymentMethodAPI,
      getUserPaymentHistoryAPI,
      getTransactionHistoryAPI,
      getWorkstationsAPI,
      getWorkstationMachineTypesAPI,
      getUserSubscriptionAPI,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BillingContainer);
