import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { getOrganizationAPI, getOrganizationPlanAPI, setCreateComputersCTX } from "Actions/Organization.actions";
import { getAccountAPI } from "Actions/Account.actions";
import { ORGANIZATION_OBJECTS_STATUSES, ORGANIZATION_SEAT_STATUSES } from "Constants/Organization.constants";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { APP_SCOPES, WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";
import apiGenerator from "Utils/Helpers/api.helpers";
import { isOdd } from "Utils/Helpers/functions.helpers";
import { Divider, ExpandableTable, HoverableTooltip, IconButton } from "UI";
import EditableCell from "UI/EditableCell/EditableCell.component";
import { useAppScope } from "Utils/Hooks/useAppScope";
import StatusBadge from "UI/StatusBadge/StatusBadge.component";
import { ORGANIZATION_HOME_DUMMY_DATA } from "Constants/teamsOnboarding.constants";
import AppMenuPopUp from "Components/AppStreaming/Home/AppMenuPopUp/AppMenuPopUp.component";
import { isMobile } from "Utils";
import ChooseRegionModal from "../../Components/ChooseRegionModal/ChooseRegionModal.component";
import UserCell from "../../Components/UserCell/UserCell.component";
import AssignComputerToMemberModal from "../AssignComputerToMemberModal.component";
import AssignMemberToComputerModal from "../AssignMemberToComputerModal.component";
import ComputerStorageModal from "../ComputerStorageModal/ComputerStorageModal.component";
import FileStorageModal from "../FileStorageModal/FileStorageModal.component";
import SwitchComputerOwnerModal from "../SwitchComputerOwnerModal/SwitchComputerOwnerModal.component";
import UsageCreditModal from "../UsageCreditModal/UsageCreditModal.component";
import ActivateComputerModal from "./ConfirmationModals/ActivateComputerModal.component";
import CreateLastSessionTemplateModal from "./ConfirmationModals/CreateLastSessionTemplateModal.component";
import DeleteComputerModal from "./ConfirmationModals/DeleteComputerModal.component";
import RemoveUserModal from "./ConfirmationModals/RemoveUserModal.component";
import RevokeAccessModal from "./ConfirmationModals/RevokeAccessModal.component";
import RevokeBalanceModal from "./ConfirmationModals/RevokeBalanceModal.component";
import CancelInvitationModal from "./ConfirmationModals/CancelInvitationModal.component";
import ExpandedObject from "./ExpandedObject.component";
import TemplatesModal from "../TemplatesModal.component";
import UpdatePerformancesModalComponent from "../../Components/UpdatePerformancesModal/UpdatePerformancesModal.component";

import "./OrganizationObjectsTable.styles.scss";

const OrganizationObjectsTable = ({
  organizationObjectsData,
  refetch,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  selectedUsers,
  setSelectedUsers,
  onboardingModalCurrentStep,
  showTeamsHomeOnboardingModal,
  translate,
  history,
}) => {
  const teamUserManagerTableRef = useRef(null);
  const [moreButtonPopUpPosition, setMoreButtonPopUpPosition] = useState(null);
  const [selectPerformancePopUpPosition, setSelectPerformancePopUpPosition] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [expandedSeat, setExpandedSeat] = useState(null);
  const [showAssignComputerModal, setShowAssignComputerModal] = useState(false);
  const [showAssignMemberModal, setShowAssignMemberModal] = useState(false);
  const [showSwitchComputerOwnerModal, setShowSwitchComputerOwnerModal] = useState(false);
  const [showRevokeAccessConfirmationModal, setShowRevokeAccessConfirmationModal] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [showRevokeBalanceModal, setShowRevokeBalanceModal] = useState(false);
  const [showCreateLastSessionTemplateModal, setShowCreateLastSessionTemplateModal] = useState(false);
  const [showChooseRegionModal, setShowChooseRegionModal] = useState(false);
  const [showDeleteComputerModal, setShowDeleteComputerModal] = useState(false);
  const [showComputerStorageModal, setShowComputerStorageModal] = useState(false);
  const [showFileStorageModal, setShowFileStorageModal] = useState(false);
  const [showUsageCreditModal, setShowUsageCreditModal] = useState(false);
  const [showActivateComputerModal, setShowActivateComputerModal] = useState(false);
  const [showCancelInvitationModal, setShowCancelInvitationModal] = useState(false);
  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  const [showUpdatePerformancesModal, setShowUpdatePerformancesModal] = useState(false);

  const currentUserSeat = useSelector((state) => state.organizations.getSeatsMeCTX.seat);
  const { setAppScope } = useAppScope();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationPlanAPI());
  }, []);

  const {
    seat: selectedSeat,
    user: selectedUser,
    machine: selectedMachine,
    invitation: selectedInvitation,
  } = selectedObject?.attributes || {};

  const startComputerMutation = useMutation({
    mutationFn: (id) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_START(id));
    },
    onSuccess: () => {
      refetch();
    },
  });

  const stopComputerMutation = useMutation({
    mutationFn: (id) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_STOP(id));
    },
    onSuccess: () => {
      refetch();
    },
  });

  const updateMachineMutation = useMutation({
    mutationFn: ({ machineId, name }) => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_MACHINES(machineId), { name });
    },
  });

  const setMachineTypeMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_MACHINE_TYPE(selectedMachine.id), data);
    },
    onSuccess: () => {
      refetch();
      if (selectedMachine?.attributes?.region) {
        startComputerMutation.mutate(selectedMachine.id);
        setSelectedObject(null);
      } else {
        setShowChooseRegionModal(true);
      }
    },
  });

  const handleMoreButtonClick = (event, item) => {
    setSelectPerformancePopUpPosition(null);
    if (!moreButtonPopUpPosition) event.stopPropagation();
    event.preventDefault();
    setSelectedObject(item);
    setMoreButtonPopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
  };

  const handleStartComputerClick = (event, item) => {
    setMoreButtonPopUpPosition(null);
    if (!selectPerformancePopUpPosition) event.stopPropagation();
    event.preventDefault();
    setSelectedObject(item);
    setSelectPerformancePopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
  };

  const popUpMenuActions = {
    assign_computer: () => {
      setShowAssignComputerModal(true);
    },
    create_computer: () => {
      if (selectedUser) {
        dispatch(setCreateComputersCTX({ selectedUserIds: [selectedUser.id] }));
      } else {
        dispatch(setCreateComputersCTX({ selectedInvitationIds: [selectedInvitation.id] }));
      }

      history.push(routes.organizationAdminCreateComputersQuickStart);
    },
    manage_usage_credits: () => {
      setShowUsageCreditModal(true);
    },
    revoke_usage_credits: () => {
      setShowRevokeBalanceModal(true);
    },
    save_last_session_template: () => {
      setShowCreateLastSessionTemplateModal(true);
    },
    manage_computer_storage: () => {
      setShowComputerStorageModal(true);
    },
    manage_file_storage: () => {
      setShowFileStorageModal(true);
    },
    assign_computer_to_member: () => {
      setShowAssignMemberModal(true);
    },
    revoke_access: () => {
      setShowRevokeAccessConfirmationModal(true);
    },
    switch_computer_owner: () => {
      setShowSwitchComputerOwnerModal(true);
    },
    set_computer_for_deletion: () => {
      setShowDeleteComputerModal(true);
    },
    delete_user: () => {
      setShowRemoveUserModal(true);
    },
    activate_computer: () => {
      setShowActivateComputerModal(true);
    },
    cancel_invitation: () => {
      setShowCancelInvitationModal(true);
    },
    assign_image: () => {
      setShowTemplatesModal(true);
    },
    update_machine_types: () => {
      setShowUpdatePerformancesModal(true);
    },
  };

  const appPopUpMenuContent = useMemo(() => {
    return Object.keys(selectedObject?.attributes?.menu_items || {}).map((key) => {
      return {
        text: translate(`organizationObjectsTable.popUpMenuItems.${key}`),
        hide: selectedObject?.attributes?.menu_items[key].hide,
        disabled: selectedObject?.attributes?.menu_items[key].disabled || selectedUsers.length > 1,
        onClick: () => {
          popUpMenuActions[key]();
          setMoreButtonPopUpPosition(null);
        },
      };
    });
  }, [selectedObject, selectedUsers]);

  const performanceOptionsMenuContent = useMemo(() => {
    if (!selectedSeat) return [];

    const options = [
      {
        text: (
          <>
            <div className="pop-up-header">
              <p>Select Performance</p>
            </div>
            <Divider />
          </>
        ),
        disableHover: true,
      },
    ];
    const data = selectedSeat.attributes.plan.attributes.machine_types
      .sort((a, b) => {
        return a.attributes.cpu - b.attributes.cpu;
      })
      .map((machineType) => {
        const { friendly_name: machineTypeName } = machineType.attributes;
        return {
          text: machineTypeName,
          onClick: () => {
            setMachineTypeMutation.mutate({ machine_type_id: machineType.id });
            setSelectPerformancePopUpPosition(null);
          },
        };
      });
    return [...options, ...data];
  }, [selectedObject]);

  const handleConnectButtonClick = (machine) => {
    if (currentUserSeat?.attributes?.machine?.id === machine?.id) {
      setAppScope(APP_SCOPES.team_member);
      history.push(routes.organizationMemberComputer);
    } else {
      history.push(`${routes.organizationAdminMemberComputer}/${machine.id}`);
    }
  };

  const columns = [
    { name: "User", weight: 30 },
    { name: "Status", weight: 20 },
    { name: "Computer", weight: 20 },
    { name: "Plan", weight: 20 },
    { name: "buttons", weight: 10, hide: true, showOnHover: true },
  ];

  const mergedData = [...organizationObjectsData, ...ORGANIZATION_HOME_DUMMY_DATA];
  const organizationData = useMemo(() => {
    if (!showTeamsHomeOnboardingModal) {
      return organizationObjectsData;
    }
    return mergedData.filter((item) => {
      if (onboardingModalCurrentStep < 2 && item?.attributes?.user_type !== "owner") {
        return false;
      } else if (
        onboardingModalCurrentStep > 1 &&
        onboardingModalCurrentStep < 4 &&
        item?.attributes?.user_type !== "owner" &&
        item?.id !== "onBoardingMember"
      ) {
        return false;
      } else if (
        onboardingModalCurrentStep === 4 &&
        item?.attributes?.user_type !== "owner" &&
        item?.id !== "onboardingMachine" &&
        item?.id !== "onBoardingMember"
      ) {
        return false;
      } else if (
        onboardingModalCurrentStep === 5 &&
        item?.attributes?.user_type !== "owner" &&
        item?.id !== "assignedUser"
      ) {
        return false;
      }
      return true;
    });
  }, [showTeamsHomeOnboardingModal, mergedData, onboardingModalCurrentStep, organizationObjectsData]);

  const data = organizationData?.map((item, index) => {
    const {
      user,
      machine,
      seat,
      status,
      menu_items: menuItems,
      user_type: userType,
      invitation,
      highlightClass,
    } = item?.attributes;
    const { plan } = seat?.attributes || {};
    const isOwner = userType === "owner";
    const expanded = expandedSeat === seat?.id;
    const paymentRequired = seat?.attributes?.status === ORGANIZATION_SEAT_STATUSES.paymentRequired;
    const { remaining_usage: remainingUsage } = seat?.attributes || {};

    const hideCheckbox = status === ORGANIZATION_OBJECTS_STATUSES.personalComputer;
    const { friendly_status: friendlyStatus } = machine?.attributes || {};
    return {
      key: item.id,
      user: (
        <UserCell
          hideCheckbox={hideCheckbox || isMobile}
          checked={!!selectedUsers.find((user) => user.id === item.id)}
          onCheckBoxChange={() => {
            const checked = selectedUsers.find((user) => user.id === item.id);
            const selectedUsersDup = [...selectedUsers];
            if (showTeamsHomeOnboardingModal) {
              return;
            }
            if (checked) {
              setSelectedUsers(selectedUsersDup.filter((user) => user.id !== item.id));
            } else {
              setSelectedUsers([...selectedUsersDup, item]);
            }
          }}
          user={user || invitation}
          seat={seat}
          isOwner={isOwner}
        />
      ),
      status: <StatusBadge status={status} color={isOdd(index) && "gray"} />,
      computer: (
        <EditableCell
          initialValue={machine?.attributes?.name}
          onEnter={(value) => {
            updateMachineMutation.mutate({ machineId: machine.id, name: value });
          }}
          disabled={showTeamsHomeOnboardingModal}
        />
      ),
      plan: <span>{plan?.attributes?.name}</span>,
      buttons: (
        <div className="icon-buttons">
          {friendlyStatus === "ready" && (
            <HoverableTooltip content="Connect">
              <IconButton
                name="monitor"
                color="gray-4-stroke"
                onClick={() => !showTeamsHomeOnboardingModal && handleConnectButtonClick(machine)}
              />
            </HoverableTooltip>
          )}
          {(friendlyStatus === WORKSTATION_FRIENDLY_STATUS.TURNING_ON ||
            friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY) && (
            <HoverableTooltip content="Pause">
              <IconButton
                name="stream-pause"
                onClick={() => {
                  if (!showTeamsHomeOnboardingModal) stopComputerMutation.mutate(machine.id);
                }}
              />
            </HoverableTooltip>
          )}
          {friendlyStatus === "off" && (
            <HoverableTooltip content="Run Computer">
              <IconButton
                name="stream-play"
                onClick={(event) => {
                  if (!showTeamsHomeOnboardingModal) handleStartComputerClick(event, item);
                }}
                disabled={remainingUsage <= 0 || paymentRequired}
              />
            </HoverableTooltip>
          )}
          {menuItems && (
            <HoverableTooltip content="More">
              <IconButton
                name="more"
                onClick={(event) => !showTeamsHomeOnboardingModal && handleMoreButtonClick(event, item)}
              />
            </HoverableTooltip>
          )}
          {seat && (
            <IconButton
              name="teams-caret-down"
              onClick={() => {
                if (showTeamsHomeOnboardingModal) {
                  return;
                }
                if (expanded) setExpandedSeat(null);
                else setExpandedSeat(seat.id);
              }}
            />
          )}
        </div>
      ),
      expanded,
      expandableContent: <ExpandedObject machine={machine} seat={seat} plan={plan} />,
      highlightClass,
    };
  });

  return (
    <>
      <div className="team-user-manager-table-container" ref={teamUserManagerTableRef}>
        <ExpandableTable
          className="team-user-manager-table"
          data={data}
          columns={columns}
          onPageEnd={() => {
            fetchNextPage();
          }}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
        {moreButtonPopUpPosition && (
          <AppMenuPopUp
            setPopUpPosition={setMoreButtonPopUpPosition}
            content={appPopUpMenuContent}
            popUpPosition={moreButtonPopUpPosition}
            referencePosRef={teamUserManagerTableRef}
          />
        )}
        {selectPerformancePopUpPosition && (
          <AppMenuPopUp
            className="select-performance-pop-up"
            setPopUpPosition={setSelectPerformancePopUpPosition}
            content={performanceOptionsMenuContent}
            popUpPosition={selectPerformancePopUpPosition}
            referencePosRef={teamUserManagerTableRef}
          />
        )}
        {showAssignComputerModal && (
          <AssignMemberToComputerModal
            onClose={() => {
              refetch();
              setShowAssignComputerModal(false);
              dispatch(getAccountAPI());
            }}
            selectedObject={selectedObject}
            translate={translate}
          />
        )}
        {showAssignMemberModal && (
          <AssignComputerToMemberModal
            onClose={() => {
              refetch();
              setShowAssignMemberModal(false);
              dispatch(getAccountAPI());
            }}
            selectedObject={selectedObject}
            translate={translate}
          />
        )}
        {showSwitchComputerOwnerModal && (
          <SwitchComputerOwnerModal
            onClose={() => {
              refetch();
              setShowSwitchComputerOwnerModal(false);
              dispatch(getAccountAPI());
            }}
            selectedObject={selectedObject}
            translate={translate}
          />
        )}
        {showRevokeAccessConfirmationModal && (
          <RevokeAccessModal
            onClose={() => {
              setShowRevokeAccessConfirmationModal(false);
              refetch();
              if (currentUserSeat && currentUserSeat.id === selectedSeat.id) {
                dispatch(getAccountAPI());
              }
            }}
            seat={selectedSeat}
            translate={translate}
          />
        )}
        {showRevokeBalanceModal && (
          <RevokeBalanceModal
            onClose={() => {
              setShowRevokeBalanceModal(false);
              refetch();
            }}
            seat={selectedSeat}
            translate={translate}
          />
        )}
        {showRemoveUserModal && (
          <RemoveUserModal
            user={selectedUser}
            onClose={() => {
              setShowRemoveUserModal(false);
              refetch();
            }}
            translate={translate}
          />
        )}

        {showCreateLastSessionTemplateModal && (
          <CreateLastSessionTemplateModal
            onClose={() => {
              setShowCreateLastSessionTemplateModal(false);
              refetch();
            }}
            seat={selectedSeat}
            translate={translate}
          />
        )}
        {showDeleteComputerModal && (
          <DeleteComputerModal
            seat={selectedSeat}
            onClose={() => {
              setShowDeleteComputerModal(false);
              refetch();
            }}
            willCancelSubscription={
              selectedObject?.attributes?.menu_items?.set_computer_for_deletion?.will_cancel_subscription
            }
            translate={translate}
          />
        )}
        {showActivateComputerModal && (
          <ActivateComputerModal
            seat={selectedSeat}
            onClose={() => {
              setShowActivateComputerModal(false);
              dispatch(getOrganizationAPI());
              refetch();
            }}
            willActivateSubscription={
              selectedObject?.attributes?.menu_items?.activate_computer?.will_activate_subscription
            }
            translate={translate}
          />
        )}
        {showChooseRegionModal && (
          <ChooseRegionModal
            selectedSeat={selectedSeat}
            onClose={() => {
              setShowChooseRegionModal(false);
              setSelectedObject(null);
            }}
            runComputerAction={() => {
              setShowChooseRegionModal(false);
              startComputerMutation.mutate(selectedMachine.id);
              setSelectedObject(null);
            }}
            translate={translate}
          />
        )}
        {showComputerStorageModal && (
          <ComputerStorageModal
            diskSize={selectedSeat?.attributes?.disk_size}
            seat={selectedSeat}
            onClose={() => {
              setShowComputerStorageModal(false);
              refetch();
            }}
            translate={translate}
            history={history}
          />
        )}
        {showFileStorageModal && (
          <FileStorageModal
            fileStorageSize={selectedSeat?.attributes?.file_storage_size}
            seat={selectedSeat}
            onClose={() => {
              setShowFileStorageModal(false);
              refetch();
            }}
            translate={translate}
            history={history}
          />
        )}
        {showUsageCreditModal && (
          <UsageCreditModal
            seat={selectedSeat}
            machineTypeId={selectedMachine?.attributes?.machine_type_id}
            onClose={() => {
              setShowUsageCreditModal(false);
              refetch();
            }}
            translate={translate}
            history={history}
          />
        )}
        {showCancelInvitationModal && (
          <CancelInvitationModal
            onClose={() => {
              setShowCancelInvitationModal(false);
              refetch();
            }}
            invitationId={selectedObject.attributes.invitation.id}
            translate={translate}
          />
        )}
        {showTemplatesModal && (
          <TemplatesModal
            onClose={() => {
              setShowTemplatesModal(false);
              refetch();
            }}
            selectedSeat={selectedSeat}
            translate={translate}
          />
        )}

        {showUpdatePerformancesModal && (
          <UpdatePerformancesModalComponent
            plan={selectedSeat.attributes.plan}
            seat={selectedSeat}
            onClose={() => {
              setShowUpdatePerformancesModal(false);
              refetch();
            }}
            translate={translate}
          />
        )}
      </div>
    </>
  );
};

export default OrganizationObjectsTable;
