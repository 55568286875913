export const logger = (msg, ...rest) => {
  const isDebugModeEnabled = document.debugMode && document.debugMode === "+++debug+++";
  if (process.env.NODE_ENV !== "production" || isDebugModeEnabled) {
    if (rest.length > 0) {
      console.log(msg, rest); // eslint-disable-line
    } else {
      console.log(msg); // eslint-disable-line
    }
  }
};

export default class Logger {
  static isTestEnv =
    process.env.REACT_APP_API_BASE_URL.includes("staging") || process.env.REACT_APP_API_BASE_URL.includes("sandbox");

  static log(msg, ...rest) {
    const isDebugModeEnabled = document.debugMode && document.debugMode === "+++debug+++";
    if (process.env.NODE_ENV !== "production" || this.isTestEnv || isDebugModeEnabled) {
      if (rest.length > 0) {
        console.log(msg, rest); // eslint-disable-line
      } else {
        console.log(msg); // eslint-disable-line
      }
    }
  }

  static error(msg, ...rest) {
    const isDebugModeEnabled = document.debugMode && document.debugMode === "+++debug+++";
    if (process.env.NODE_ENV !== "production" || this.isTestEnv || isDebugModeEnabled) {
      if (rest.length > 0) {
        console.error(msg, rest); // eslint-disable-line
      } else {
        console.error(msg); // eslint-disable-line
      }
    }
  }

  static warn(msg, ...rest) {
    const isDebugModeEnabled = document.debugMode && document.debugMode === "+++debug+++";
    if (process.env.NODE_ENV !== "production" || this.isTestEnv || isDebugModeEnabled) {
      if (rest.length > 0) {
        console.warn(msg, rest); // eslint-disable-line
      } else {
        console.warn(msg); // eslint-disable-line
      }
    }
  }
}
