import React from "react";

import { Input, IconButton, Button } from "UI";
import "./BoxInput.style.scss";

const BoxInput = (props) => {
  const { className, inputRef, value, iconName, color, onSubmit, buttonText, buttonClick, buttonDisabled, ...rest } =
    props;

  const wrapperClassName = ["box-input-wrapper", className, color].filter(Boolean).join(" ");

  return (
    <div className={wrapperClassName}>
      <Input inputRef={inputRef} value={value} onSubmit={onSubmit} {...rest} />
      {iconName && <IconButton name={iconName} onClick={onSubmit} color="white-stroke" />}
      {buttonText && <Button text={buttonText} color="light-navy" onClick={buttonClick} disabled={buttonDisabled} />}
    </div>
  );
};

export default BoxInput;
