export const GET_USER_SUBSCRIPTION_API_PENDING = "GET_USER_SUBSCRIPTION_API_PENDING";
export const GET_USER_SUBSCRIPTION_API_SUCCESS = "GET_USER_SUBSCRIPTION_API_SUCCESS";
export const GET_USER_SUBSCRIPTION_API_FAILURE = "GET_USER_SUBSCRIPTION_API_FAILURE";

export const UPDATE_USER_SUBSCRIPTION_API_PENDING = "UPDATE_USER_SUBSCRIPTION_API_PENDING";
export const UPDATE_USER_SUBSCRIPTION_API_SUCCESS = "UPDATE_USER_SUBSCRIPTION_API_SUCCESS";
export const UPDATE_USER_SUBSCRIPTION_API_FAILURE = "UPDATE_USER_SUBSCRIPTION_API_FAILURE";

export const CANCEL_USER_SUBSCRIPTION_API_PENDING = "CANCEL_USER_SUBSCRIPTION_API_PENDING";
export const CANCEL_USER_SUBSCRIPTION_API_SUCCESS = "CANCEL_USER_SUBSCRIPTION_API_SUCCESS";
export const CANCEL_USER_SUBSCRIPTION_API_FAILURE = "CANCEL_USER_SUBSCRIPTION_API_FAILURE";

export const RENEW_USER_SUBSCRIPTION_API_PENDING = "RENEW_USER_SUBSCRIPTION_API_PENDING";
export const RENEW_USER_SUBSCRIPTION_API_SUCCESS = "RENEW_USER_SUBSCRIPTION_API_SUCCESS";
export const RENEW_USER_SUBSCRIPTION_API_FAILURE = "RENEW_USER_SUBSCRIPTION_API_FAILURE";

export const UPGRADE_DISK_SIZE_SUBSCRIPTION_API_PENDING = "UPGRADE_DISK_SIZE_SUBSCRIPTION_API_PENDING";
export const UPGRADE_DISK_SIZE_SUBSCRIPTION_API_SUCCESS = "UPGRADE_DISK_SIZE_SUBSCRIPTION_API_SUCCESS";
export const UPGRADE_DISK_SIZE_SUBSCRIPTION_API_FAILURE = "UPGRADE_DISK_SIZE_SUBSCRIPTION_API_FAILURE";
export const UPGRADE_DISK_SIZE_SUBSCRIPTION_API_RESET = "UPGRADE_DISK_SIZE_SUBSCRIPTION_API_RESET";

export const UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_PENDING = "UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_PENDING";
export const UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_SUCCESS = "UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_SUCCESS";
export const UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_FAILURE = "UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_FAILURE";
export const UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_RESET = "UPGRADE_FILE_STORAGE_SUBSCRIPTION_API_RESET";

export const GET_FILE_STORAGE_SUBSCRIPTION_PLANS_PENDING = "GET_FILE_STORAGE_SUBSCRIPTION_PLANS_PENDING";
export const GET_FILE_STORAGE_SUBSCRIPTION_PLANS_SUCCESS = "GET_FILE_STORAGE_SUBSCRIPTION_PLANS_SUCCESS";
export const GET_FILE_STORAGE_SUBSCRIPTION_PLANS_FAILURE = "GET_FILE_STORAGE_SUBSCRIPTION_PLANS_FAILURE";

export const GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_PENDING = "GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_PENDING";
export const GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_SUCCESS = "GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_SUCCESS";
export const GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_FAILURE = "GET_USER_SUBSCRIPTION_PAYMENT_INFORMATION_FAILURE";

export const CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_PENDING = "CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_PENDING";
export const CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_SUCCESS = "CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_SUCCESS";
export const CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_FAILURE = "CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_FAILURE";

export const CLEAR_SUBSCRIPTION_WORKSTATION = "CLEAR_SUBSCRIPTION_WORKSTATION";

export const CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_SUCCESS_MESSAGE =
  "Your payment has been done successfully. You can start using your vagon computer.";
export const UPGRADE_DISK_SIZE_SUBSCRIPTION_API_SUCCESS_MESSAGE =
  "Your payment for additional storage to your computer has been done successfully. You can use your computer with more storage.";
