import React from "react";

const useSimpleAndDoubleClick = (actionSimpleClick, actionDoubleClick, delay = 250) => {
  const [click, setClick] = React.useState(0);
  const [args, setArgs] = React.useState([]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) actionSimpleClick(...args);
      setClick(0);
    }, delay);

    // click in a delay < 250 = double click detected
    if (click === 2) actionDoubleClick(...args);

    return () => {
      clearTimeout(timer);
    };
  }, [click]);

  return (...args) => {
    setArgs(args);
    setClick((prev) => prev + 1);
  };
};

export default useSimpleAndDoubleClick;
