import React, { useState } from "react";
import { ConfirmationModal, RadioInput } from "UI";

import "./MultiGpuConsentModal.styles.scss";

const MultiGpuConsentModal = (props) => {
  const {
    setShowMultiGpuConsentModal,
    setUserMultiGpuConsentAPI,
    updateMachineStatusMutation,
    setShouldRunComputer,
    selectedMachineType,
    translate,
  } = props;

  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    setShowMultiGpuConsentModal(false);
  };

  const handleConfirm = () => {
    if (checked) setUserMultiGpuConsentAPI(true);
    setShowMultiGpuConsentModal(false);
    updateMachineStatusMutation.mutate({ status: "start", machineTypeId: selectedMachineType });
    setShouldRunComputer(false);
  };

  return (
    <ConfirmationModal
      className="multi-gpu-consent-modal"
      closeAction={handleClose}
      headerText={translate("modals.multiGpuConsent.title")}
      descriptionText={
        <>
          <div className="description-content">
            <p>{translate("modals.multiGpuConsent.description.main")}</p>
            <p>{translate("modals.multiGpuConsent.description.secondary")}</p>
            <RadioInput
              label={translate("modals.multiGpuConsent.doNotShow")}
              className="inline-option"
              color="aqua-main"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
          </div>
        </>
      }
      confirmText={translate("modals.multiGpuConsent.confirm")}
      confirmAction={handleConfirm}
    />
  );
};

export default MultiGpuConsentModal;
