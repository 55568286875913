import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { convertDateFormat, useAPI } from "Utils";
import { convertCSVFileAndDownload } from "Utils/Helpers/file.helpers";

const useAppStreamingBillingCsv = ({
  startAt,
  endAt,
  applicationFilter,
  streamNameFilter,
  transactionFilter,
  vendorBalanceStats,
  vendorPayments,
  chargeDetails,
  translate,
}) => {
  const { api: getAllChargeDetailsAPI } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_VENDOR_CHARGE_DETAILS,
    type: "get",
    onSuccess: (data) => {
      convertCSVFileAndDownload(
        [
          { name: "summary.csv", data: vendorBalanceStatsCsvFormat(vendorBalanceStats?.data?.transactions) },
          { name: "transactions.csv", data: transactionsCsvFormat(data?.transactions) },
          { name: "payments.csv", data: paymentsCsvFormat(vendorPayments) },
        ],
        "VagonStreams-Transactions.zip",
      );
    },
  });

  const vendorBalanceStatsCsvFormat = (data) => {
    return data?.map((transaction) => {
      const { transaction_type: type, amount } = transaction;
      return {
        transaction: translate(`appStreamingBilling.chargeDetails.transactionTypes.${type}`),
        amount: `${amount >= 0 ? "+" : "-"} $ ${Math.abs(parseFloat(amount)).toFixed(2)}`,
      };
    });
  };

  const transactionsCsvFormat = (data) => {
    return (
      data?.map((transaction) => {
        const {
          transaction_type: transactionType,
          amount,
          day,
          vendor_application_name: appName,
          stream_name: streamName,
        } = transaction.attributes;
        const capitalizedItemName = translate(`appStreamingBilling.chargeDetails.transactionTypes.${transactionType}`);
        return {
          id: transaction.id,
          date: convertDateFormat(day),
          application: appName,
          stream: streamName,
          transaction: capitalizedItemName,
          amount: `${amount >= 0 ? "+" : "-"} $ ${Math.abs(parseFloat(amount)).toFixed(2)}`,
        };
      }) || []
    );
  };

  const paymentsCsvFormat = (data) => {
    return (
      data?.map((payment) => {
        const {
          amount,
          created_at: createdAt,
          payment_type: paymentType,
          invoice_number: invoiceNumber,
        } = payment.attributes;
        return {
          id: payment.id,
          date: convertDateFormat(createdAt),
          amount,
          transaction: translate(`appStreamingBilling.payments.paymentTypes.${paymentType}`),
          invoice_number: invoiceNumber,
        };
      }) || []
    );
  };

  const generateCsv = () => {
    getAllChargeDetailsAPI({
      params: {
        start_at: startAt,
        end_at: endAt,
        detail_level: "stream",
        application_id: applicationFilter?.id,
        stream_id: streamNameFilter?.id,
        transaction_type: transactionFilter === "allTransactions" ? null : transactionFilter,
        without_pagination: true,
      },
    });
  };

  const enabled =
    vendorBalanceStats?.data?.transactions?.length > 0 || vendorPayments?.length > 0 || chargeDetails?.length > 0;

  return { generateCsv, enabled };
};

export default useAppStreamingBillingCsv;
