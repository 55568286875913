import React from "react";

import { IconButton } from "UI";
import routes from "Constants/Route.constants";
import SideImage from "./Components/SideImage.component";

import "./Authentication.style.scss";

const AuthenticationLayout = (props) => {
  const { children, match, history } = props;

  const showBackButton = ![
    routes.login,
    routes.register,
    routes.appStreamingRegister,
    routes.appStreamingLoginFromPlugin,
    routes.appStreamingSendTokenToPlugin,
    routes.suite,
  ].includes(match.path);

  return (
    <div className="authentication-layout">
      <SideImage />
      <div className="content">
        {showBackButton && (
          <IconButton
            name="arrow-left-small"
            className="back-button mobile-only-hide"
            onClick={() => {
              history.push(routes.login);
            }}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default AuthenticationLayout;
