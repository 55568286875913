import React from "react";
import { ExpandableTable } from "UI";
import { convertDateFormat } from "Utils";
import NoTransactionsImage from "Assets/images/appStreaming/app-streaming_first review 1.png";

const TransactionsTable = ({ chargeDetailProps, className, translate }) => {
  const columns = [
    { name: "Id", weight: 0, hide: true },
    { name: translate("appStreamingBilling.deposits.columns.date"), weight: 50 },
    { name: translate("appStreamingBilling.deposits.columns.application"), weight: 50 },
    { name: translate("appStreamingBilling.deposits.columns.stream"), weight: 50 },
    { name: translate("appStreamingBilling.deposits.columns.transaction"), weight: 50 },
    { name: translate("appStreamingBilling.deposits.columns.cost"), weight: 50 },
  ];

  const tableData =
    chargeDetailProps?.data?.map((transaction) => {
      const {
        transaction_type: transactionType,
        amount,
        day,
        vendor_application_name: appName,
        stream_name: streamName,
      } = transaction.attributes;
      const capitalizedItemName = translate(`appStreamingBilling.chargeDetails.transactionTypes.${transactionType}`);
      return {
        id: transaction.id,
        date: convertDateFormat(day),
        application: appName,
        stream: streamName,
        transaction: capitalizedItemName,
        amount: `${amount >= 0 ? "+" : "-"} $ ${Math.abs(parseFloat(amount)).toFixed(2)}`,
      };
    }) || [];

  if (tableData?.length === 0)
    return (
      <div className="no-transactions-content">
        <img src={NoTransactionsImage} />
        <span>{translate("appStreamingBilling.noTransaction")}</span>
      </div>
    );

  return (
    <ExpandableTable
      isLoading={chargeDetailProps.isLoading}
      columns={columns}
      data={tableData}
      onPageEnd={chargeDetailProps.fetchNextPage}
      isFetchingNextPage={chargeDetailProps.isFetchingNextPage}
      hasNextPage={chargeDetailProps.hasNextPage}
      className={className}
    />
  );
};

export default TransactionsTable;
