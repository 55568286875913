import React, { useRef, useState } from "react";
import { Steps } from "intro.js-react";
import streamsWelcomeImage from "Assets/images/onboardingModals/streams-welcome.jpg";
import uploadApplicationImage from "Assets/images/onboardingModals/upload-application.jpg";
import configureApplicationImage from "Assets/images/onboardingModals/configure-application.jpg";
import { APPLICATIONS_ONBOARDING_STEPS } from "Constants/StreamsOnboarding.constants";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const StreamsApplicationsOnboardingModal = ({ translate, setCurrentStep, setDisplayApplication, onExit }) => {
  const scrollId = document.getElementById("dashboard-content-container");
  const [firstStep, setFirstStep] = useState(true);
  const [secondStep, setSecondStep] = useState(true);
  const [thirdStep, setThirdStep] = useState(true);
  const stepsObject = useRef(null);
  const steps = [
    {
      title: `<img src=${streamsWelcomeImage} />`,
      intro: `<h1>${translate("streamsOnboardingModal.applications.initial.header")}</h1><p>${translate(
        "streamsOnboardingModal.applications.initial.description",
      )}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".vg-button.upload-app-button.clickable",
      title: `<img src=${uploadApplicationImage} />`,
      intro: `<h1>${translate("streamsOnboardingModal.applications.upload.header")}</h1><p>${translate(
        "streamsOnboardingModal.applications.upload.description",
      )}</p>`,

      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".div-button-container.app-card-container.sample-application.clickable",
      title: `<img src=${configureApplicationImage} />`,
      intro: `<h1>${translate("streamsOnboardingModal.applications.configure.header")}</h1><p>${translate(
        "streamsOnboardingModal.applications.configure.description",
      )}</p>`,
      position: "right",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
  ];

  const onBeforeChange = (currentStep) => {
    setCurrentStep(currentStep);
    scrollId.style.overflowY = "hidden";

    if (currentStep === APPLICATIONS_ONBOARDING_STEPS.applicationsInitial.step) {
      setSecondStep(true);
      setThirdStep(true);
      setDisplayApplication(false);
      if (firstStep) {
        setFirstStep(false);
        setTimeout(() => {
          stepsObject.current.updateStepElement(APPLICATIONS_ONBOARDING_STEPS.applicationsInitial.step);
          stepsObject.current.introJs.goToStep(1);
        }, 10);
      }
    } else if (currentStep === APPLICATIONS_ONBOARDING_STEPS.createApplication.step) {
      setFirstStep(true);
      setThirdStep(true);
      setDisplayApplication(false);
      if (secondStep) {
        setSecondStep(false);
        setTimeout(() => {
          stepsObject.current.updateStepElement(APPLICATIONS_ONBOARDING_STEPS.createApplication.step);
          stepsObject.current.introJs.goToStep(2);
        }, 10);
      }
    } else if (currentStep === APPLICATIONS_ONBOARDING_STEPS.applicationsTab.step) {
      setDisplayApplication(true);
      setFirstStep(true);
      setSecondStep(true);
      if (thirdStep) {
        setThirdStep(false);
        setTimeout(() => {
          stepsObject.current.updateStepElement(APPLICATIONS_ONBOARDING_STEPS.applicationsTab.step);
          stepsObject.current.introJs.goToStep(3);
        }, 10);
      }
    } else {
      setDisplayApplication(false);
    }
  };

  const onBeforeExit = () => {
    scrollId.style.overflowY = "visible";
  };

  return (
    <Steps
      enabled
      options={{ nextToDone: true }}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      onBeforeChange={onBeforeChange}
      onBeforeExit={onBeforeExit}
      ref={stepsObject}
    />
  );
};

export default StreamsApplicationsOnboardingModal;
