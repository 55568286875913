import React from "react";
import { connect } from "react-redux";

import routes from "Constants/Route.constants";
import { verifyRegisterAPI } from "Actions/Authentication/VerifyRegister.actions";
import { REQUEST_STATUS } from "Constants/global.constants";
import VerifyRegisterComponent from "Components/Authentication/VerifyRegister/VerifyRegister.component";
import { isVendorAccount } from "Utils/Helpers/saga.helpers";

class VerifyRegisterContainer extends React.Component {
  componentDidMount() {
    const { verifyRegisterAPI, match } = this.props;
    verifyRegisterAPI(match.params.code);
  }

  componentDidUpdate(prevProps) {
    const { verifyRegisterCTX, history } = this.props;
    if (
      verifyRegisterCTX.status === REQUEST_STATUS.SUCCESS &&
      prevProps.verifyRegisterCTX.status === REQUEST_STATUS.PENDING
    ) {
      history.push({
        pathname: routes.login,
        state: { from: routes.verifyRegister },
      });
    }
  }

  render() {
    return <VerifyRegisterComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  verifyRegisterCTX: state.verifyRegisterCTX,
});

const actionCreators = {
  verifyRegisterAPI,
};

export default connect(mapStateToProps, actionCreators)(VerifyRegisterContainer);
