import React from "react";
import { Link } from "react-router-dom";

import { Input, Button, TopLoader, PasswordInput } from "UI";
import routes from "Constants/Route.constants";
import AuthenticationHeader from "Utils/Components/Authentication/AuthenticationHeader/AuthenticationHeader.component";
import { REQUEST_STATUS } from "Constants/global.constants";

import "./VerifyRegister.styles.scss";
import { isVendorAccount } from "Utils/Helpers/saga.helpers";

const VerifyRegisterComponent = (props) => {
  const { verifyRegisterCTX, history, translate } = props;

  switch (verifyRegisterCTX.status) {
    case REQUEST_STATUS.FAILURE: {
      return (
        <div className="vg-verify-register-content form-container">
          <div className="vg-verify-register-failure-wrapper">
            <AuthenticationHeader
              title={translate("verifyRegister.header.failureHeader")}
              description={translate("verifyRegister.header.failureDescription")}
            />
            <div className="field-group">
              <p className="vg-register-failure-content">{translate("verifyRegister.failure.content.line_1")}</p>
              <p className="vg-register-failure-content">{translate("verifyRegister.failure.content.line_2")}</p>
              <p className="vg-register-failure-content">{translate("verifyRegister.failure.content.line_3")}</p>
            </div>
            <Button
              onClick={() => {
                history.push(routes.login);
              }}
              text={translate("verifyRegister.form.button.text")}
              block
            />
          </div>
        </div>
      );
    }
    default: {
      return (
        <>
          <TopLoader />

          <div className="vg-verify-register-content form-container">
            <form noValidate>
              <AuthenticationHeader title={translate("verifyRegister.header.pendingState")} />
              <div className="field-group">
                <Input
                  type="email"
                  disabled
                  label={translate("verifyRegister.form.email.label")}
                  placeholder={translate("verifyRegister.form.email.placeholder")}
                />
                <PasswordInput
                  disabled
                  label={translate("verifyRegister.form.password.label")}
                  placeholder={translate("verifyRegister.form.password.placeholder")}
                />
                <Link to={routes.forgetPassword}>{translate("verifyRegister.form.forgotPassword.link")}</Link>
              </div>
              <Button type="submit" disabled text={translate("login.form.button.text")} block />
            </form>
          </div>
        </>
      );
    }
  }
};

export default VerifyRegisterComponent;
