import { ERROR_CODE } from "Constants/global.constants";
import routes, { STREAMING_ROUTES } from "Constants/Route.constants";
import { removeKeysFromLocalStorage } from "Utils/Helpers/storage.helpers";
import Logger from "./logger.helpers";

export function getStatusCodeFamily(status) {
  return parseInt(String(status).charAt(0), 10);
}

export const isVendorAccount = () => {
  return document.location.pathname.includes("stream");
};

export const isOrganizationAccount = () => {
  return document.location.pathname.includes("team/admin");
};

export const getAPIEndpoint = (endpoint) => {
  if (isVendorAccount()) {
    return `/vendor${endpoint}`;
  }

  if (isOrganizationAccount()) {
    return `/organizations${endpoint}`;
  }
  return endpoint;
};

export function apiErrorHandler(err) {
  const baseUrl = process.env.REACT_APP_ELECTRON ? "#" : "";

  if (err?.response?.status === ERROR_CODE.MAINTENANCE && !document.location.pathname.includes(routes.maintenance)) {
    document.location.href = baseUrl + routes.maintenance;
  }

  if (err.response && err.response.status === 401) {
    removeKeysFromLocalStorage();
    document.location.href = `${baseUrl}/login`;
  }

  Logger.error("Error:", err, err.response);
  return err.response ? err.response.data : { client_code: 700 };
}
