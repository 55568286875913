import React, { useRef, useState } from "react";
import { Steps } from "intro.js-react";
import UsagePlansImage from "Assets/images/onboardingModals/usage-plans.jpg";
import NewPlanImage from "Assets/images/onboardingModals/new-plan.jpg";
import { PLANS_ONBOARDING_STEPS } from "Constants/teamsOnboarding.constants";
import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const TeamsPlansOnboardingModal = ({ onExit, setOnboardingModalCurrentStep, translate }) => {
  const stepsObject = useRef(null);
  const [firstStep, setFirstStep] = useState(true);
  const tableClass = document.getElementById("table-body");
  const menuClass = document.getElementById("menu-widescreen-container");
  menuClass.style.position = "fixed";

  const steps = [
    {
      element: ".entire-table-row",
      title: `<img src=${UsagePlansImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.plans.planDetails.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.plans.planDetails.description")}</p>`,
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".right-actions",
      title: `<img src=${NewPlanImage} />`,
      intro: `<h1>${translate(
        "modals.onboardingModals.teamsOnboardingModal.plans.createNewPlan.header",
      )}</h1><p>${translate("modals.onboardingModals.teamsOnboardingModal.plans.createNewPlan.description")}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-hanging",
      highlightClass: "myHighlightClass",
    },
  ];

  const onBeforeChange = (currentStep) => {
    setOnboardingModalCurrentStep(currentStep);
    if (currentStep === PLANS_ONBOARDING_STEPS.plansTab.step) {
      if (tableClass) tableClass.style.overflowY = "hidden";
      if (firstStep) {
        setTimeout(() => {
          setFirstStep(false);
          stepsObject.current.introJs.goToStep(PLANS_ONBOARDING_STEPS.extendButton.step);
          stepsObject.current.updateStepElement(PLANS_ONBOARDING_STEPS.plansTab.step);
        }, 200);
      }
    } else if (currentStep === PLANS_ONBOARDING_STEPS.extendButton.step) {
      setFirstStep(true);
    }
  };

  const onAfterChange = (currentStep) => {
    if (currentStep === PLANS_ONBOARDING_STEPS.extendButton.step) {
      stepsObject.current.updateStepElement(PLANS_ONBOARDING_STEPS.extendButton.step);
    }
  };

  const onBeforeExit = () => {
    tableClass.style.overflowY = "auto";
  };

  return (
    <Steps
      enabled
      options={{ nextToDone: true }}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      onAfterChange={onAfterChange}
      onBeforeChange={onBeforeChange}
      onBeforeExit={onBeforeExit}
      ref={stepsObject}
    />
  );
};

export default TeamsPlansOnboardingModal;
