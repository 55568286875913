import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Joi from "joi";
import { Translate } from "react-localize-redux";
import { REQUEST_STATUS } from "Constants/global.constants";

import VagonGift from "Assets/images/referral/vagon-gift.png";
import ReferralSuccessImage from "Assets/images/referral/referral-success.png";
import { Modal, BoardingModal, Input, Slide, IconButton } from "UI";

import "./SendReferralEmailModal.styles.scss";

const ValidationSchema = Joi.object({
  email: Joi.string()
    .email({
      minDomainSegments: 2,
      tlds: false,
    })
    .required(),
});

const SendReferralEmailModal = (props) => {
  const { messageTemplates, inviteWithEmail, inviteCTX, setShowSendEmailModal, translate } = props;

  const [email, setEmail] = useState("");
  const [activeTemplateIndex, setActiveTemplateIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const inputRef = useRef(null);

  const templateNumber = messageTemplates.length || 0;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (submitted) {
      if (inviteCTX.status === REQUEST_STATUS.SUCCESS) {
        // setShowCreateFolderModal(false);
      } else if (inviteCTX.status === REQUEST_STATUS.FAILURE) {
        setError(inviteCTX.clientCode);
      }
    }
  }, [inviteCTX.status]);

  const handleInvite = () => {
    if (!submitted) {
      // Check Email
      const { error: validationError } = ValidationSchema.validate({
        email,
      });

      if (validationError) {
        setError("invalidEmail");
      } else {
        inviteWithEmail(email, activeTemplateIndex);
        setSubmitted(true);
      }
    }
  };

  const handleEnterKeyUp = (event) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      handleInvite();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "/") {
      event.preventDefault();
    }
  };

  if (submitted && inviteCTX.status === REQUEST_STATUS.SUCCESS) {
    return (
      <BoardingModal
        headerImages={[ReferralSuccessImage]}
        headerTexts={[translate("modals.sendReferralEmail.success.header")]}
        descriptions={[translate("modals.sendReferralEmail.success.description", { email })]}
        buttonTexts={[translate("modals.sendReferralEmail.success.buttonText")]}
        buttonAction={() => setShowSendEmailModal(false)}
      />
    );
  }

  return (
    <Modal
      closeAction={() => setShowSendEmailModal(false)}
      topRightIcon="close"
      topRightIconAction={() => setShowSendEmailModal(false)}
      buttonText={translate("modals.sendReferralEmail.confirm")}
      buttonAction={handleInvite}
      buttonDisabled={email === "" || submitted || error}
      errorText={
        error && (
          <Translate
            id={`modals.sendReferralEmail.error.${error}`}
            options={{ onMissingTranslation: () => translate("modals.sendReferralEmail.error.default") }}
          />
        )
      }
      isPending={inviteCTX.status === REQUEST_STATUS.PENDING}
      closeOnOverlayClick
      invisibleOverlay
      small
    >
      <div className="send-referral-modal-content">
        <img src={VagonGift} />
        <h1>{translate("modals.sendReferralEmail.header")}</h1>
        <div className="referral-template">
          <IconButton
            name="chevron-left"
            color="gray-5"
            onClick={() => {
              setActiveTemplateIndex((activeTemplateIndex - 1 + templateNumber) % templateNumber);
            }}
          />
          <Slide activeIndex={activeTemplateIndex}>
            {_.map(messageTemplates, (template) => (
              <p>{template}</p>
            ))}
          </Slide>
          <IconButton
            name="chevron-right"
            color="gray-5"
            onClick={() => {
              setActiveTemplateIndex((activeTemplateIndex + 1 + templateNumber) % templateNumber);
            }}
          />
        </div>
        <div className="referral-email-input">
          <Input
            inputRef={inputRef}
            label={translate("modals.sendReferralEmail.emailInputLabel")}
            placeholder={translate("modals.sendReferralEmail.emailInputPlaceholder")}
            maxLength="50"
            value={email}
            onChange={(e) => {
              if (submitted) setSubmitted(false);
              if (error) setError(false);
              setEmail(e.target.value);
            }}
            onKeyUp={handleEnterKeyUp}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SendReferralEmailModal;
