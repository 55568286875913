/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from "react";
import useOrientationChange from "Utils/Hooks/useOrientationChange";
import { Icon, IconButton } from "UI";
import { classnames, isSafari } from "Utils";

import "./DockButton.styles.scss";

const POSITION_CHANGE_THRESHOLD = 60;
const SHOW_SHORTCUTS_TIMEOUT = 100;

const DockButton = (props) => {
  const { dockPosition, isDockOpen, showScreenKeyboard, toggleFullscreen, setIsDockOpen, handleCopyTo } = props;

  const [showShortcuts, setShowShortcuts] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ y: window.innerHeight / 2 });

  const mouseMoveEventHandlerRef = useRef(null);
  const mouseUpEventHandlerRef = useRef(null);
  const mouseOverTimeoutRef = useRef(null);

  const handleMouseOver = () => {
    clearTimeout(mouseOverTimeoutRef.current);

    if (!isDockOpen && !isDragging) {
      mouseOverTimeoutRef.current = setTimeout(() => {
        setShowShortcuts(true);
      }, SHOW_SHORTCUTS_TIMEOUT);
    }
  };

  const handleOrientationChange = () => {
    setPosition({ y: window.innerHeight / 2 });
  };

  useOrientationChange(handleOrientationChange);

  const handleMouseLeave = () => {
    clearTimeout(mouseOverTimeoutRef.current);
    setShowShortcuts(false);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isDockOpen) {
      clearTimeout(mouseOverTimeoutRef.current);

      setShowShortcuts(false);
      return;
    }

    document.removeEventListener("mousemove", mouseMoveEventHandlerRef.current);
    document.removeEventListener("mouseup", mouseUpEventHandlerRef.current);

    const handleMouseMove = (event) => {
      document.getElementById("root").style.cursor = "move";
      if (Math.abs(position.y - event.clientY) > POSITION_CHANGE_THRESHOLD) {
        setPosition({ y: Math.min(Math.max(0, event.clientY), window.innerHeight - POSITION_CHANGE_THRESHOLD) });
        clearTimeout(mouseOverTimeoutRef.current);
        if (!isDragging) {
          setIsDragging(true);
        }
      }
    };

    const handleMouseUp = (event) => {
      event.preventDefault();
      event.stopPropagation();
      document.getElementById("root").style.cursor = "default";

      setTimeout(() => {
        setIsDragging(false);
      }, 300);

      document.removeEventListener("mousemove", mouseMoveEventHandlerRef.current);
      document.removeEventListener("mouseup", mouseUpEventHandlerRef.current);
    };

    mouseMoveEventHandlerRef.current = handleMouseMove;
    mouseUpEventHandlerRef.current = handleMouseUp;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchMove = (event) => {
    const touchY = event.touches[0].clientY;

    setPosition({ y: Math.min(Math.max(0, touchY)) });
    clearTimeout(mouseOverTimeoutRef.current);
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isDragging) {
      document.body.addEventListener("touchmove", preventDefault, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventDefault);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventDefault);
    };
  }, [isDragging]);

  return (
    <div
      className={classnames([
        "dock-button-container",
        dockPosition,
        isDockOpen && "open",
        isDragging && "dragging",
        showShortcuts && "shortcuts",
      ])}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      onTouchMove={handleTouchMove}
      onTouchStart={() => setIsDragging(true)}
      onTouchEnd={() => setIsDragging(false)}
      onTouchCancel={() => setIsDragging(false)}
      style={{ top: `${isDockOpen ? window.innerHeight / 2 : position.y}px` }}
    >
      <div
        role="button"
        className="dock-button"
        tabIndex={-1}
        onKeyUp={null}
        onClick={(event) => {
          event.stopPropagation();
          if (!isDragging) {
            setIsDockOpen(!isDockOpen);
          }
        }}
      >
        <IconButton className={isDockOpen ? "invisible" : "visible"} name="settings" preventKeyEvent />
        <IconButton className={isDockOpen ? "visible" : "invisible"} name="chevron-right" preventKeyEvent />
      </div>
      <div
        className="shortcut top"
        onClick={(event) => {
          event.stopPropagation();
          if (showShortcuts) {
            showScreenKeyboard();
          }
          setShowShortcuts(false);
        }}
      >
        <Icon color="white" name="keyboard" />
      </div>
      <div
        className="shortcut mid"
        onClick={(event) => {
          event.stopPropagation();
          if (showShortcuts) {
            toggleFullscreen();
          }
          setShowShortcuts(false);
        }}
      >
        <Icon color="white" name="fullscreen" />
      </div>
      <div
        className="shortcut bottom"
        onClick={(event) => {
          event.stopPropagation();
          if (showShortcuts) {
            handleCopyTo();
          }
          setShowShortcuts(false);
        }}
      >
        <Icon color="white" name="copy-to" />
      </div>
      <div className="blur" />
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            {!isSafari && <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />}
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -9" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default DockButton;
