import { isSafari } from "Utils/Helpers/browser.helpers";
import { useEffect } from "react";

const useOrientationChange = (handleOrientationChange) => {
  useEffect(() => {
    const addEventListener = (event, handler) => {
      if (isSafari) {
        window.addEventListener(event, handler);
      } else if (window.screen.orientation) {
        window.screen.orientation.addEventListener(event, handler);
      }
    };

    const removeEventListener = (event, handler) => {
      if (isSafari) {
        window.removeEventListener(event, handler);
      } else if (window.screen.orientation) {
        window.screen.orientation.removeEventListener(event, handler);
      }
    };

    addEventListener(isSafari ? "orientationchange" : "change", handleOrientationChange);

    return () => {
      removeEventListener(isSafari ? "orientationchange" : "change", handleOrientationChange);
    };
  }, [handleOrientationChange, isSafari]);
};

export default useOrientationChange;
