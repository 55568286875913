import React, { useEffect } from "react";
import { connect } from "react-redux";

const prevProps = {};

const IntercomWrapper = (props) => {
  const { account: accountCTX, payment, subscriptions, workstations: workstationsCTX, children } = props;

  const { account, getAccountPredefinedCTX, machineUsageHistoryCTX } = accountCTX;
  const { getPaymentMethodsCTX } = payment;
  const { subscription } = subscriptions;
  const { workstations, getWorkstationMachineTypesCTX } = workstationsCTX;

  useEffect(() => {
    if (!window.Intercom) return;

    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_API_KEY,
    });
  }, []);

  useEffect(() => {
    if (!window.Intercom) return;

    const isAccountChanged = !prevProps.account || prevProps.account?.attributes?.email !== account?.attributes?.email;

    if (account && isAccountChanged) {
      const currentUser = account.attributes;
      window.Intercom("update", {
        app_id: process.env.REACT_APP_INTERCOM_API_KEY,
        email: currentUser.email,
        name: currentUser.name,
        verified: currentUser.verified,
      });
      if (currentUser.subscriptions[0]) {
        window.Intercom("update", {
          app_id: process.env.REACT_APP_INTERCOM_API_KEY,
          "subs-start-date": currentUser.subscriptions[0].attributes.start_at,
          "subs-end-date": currentUser.subscriptions[0].attributes.end_at,
        });
      }
      prevProps.account = account;
    }
  }, [account]);

  useEffect(() => {
    if (!window.Intercom) return;

    if (
      machineUsageHistoryCTX.data &&
      getWorkstationMachineTypesCTX.data &&
      getWorkstationMachineTypesCTX.data.machine_types
    ) {
      const machineUsages = {};
      let totalUsage = 0;
      machineUsageHistoryCTX.data.forEach((usage) => {
        let usageMin = usage.attributes.duration / 60;
        const machineType = usage.attributes.machine_type.id;
        if (machineUsages[machineType] != null) {
          usageMin += machineUsages[machineType];
        }
        machineUsages[machineType] = usageMin;
      });
      if (machineUsageHistoryCTX.data.length !== 0) {
        getWorkstationMachineTypesCTX.data.machine_types.forEach((machine) => {
          const friendlyName = machine.attributes.details.attributes.friendly_name;
          if (machineUsages[machine.id]) {
            machineUsages[`ws-usage-${friendlyName.toLowerCase()}`] = machineUsages[machine.id];
            totalUsage += machineUsages[machine.id];
            delete machineUsages[machine.id];
          } else {
            machineUsages[`ws-usage-${friendlyName.toLowerCase()}`] = 0;
          }
        });

        const intercomObject = {
          app_id: process.env.REACT_APP_INTERCOM_API_KEY,
          "ws-usage-total": totalUsage,
        };
        Object.assign(intercomObject, machineUsages);

        window.Intercom("update", intercomObject);
      }
    }
  }, [machineUsageHistoryCTX, getWorkstationMachineTypesCTX]);

  useEffect(() => {
    if (!window.Intercom) return;

    if (getPaymentMethodsCTX && getPaymentMethodsCTX.data && !prevProps.getPaymentMethodsCTX) {
      window.Intercom("update", {
        app_id: process.env.REACT_APP_INTERCOM_API_KEY,
        "has-payment-method": !!getPaymentMethodsCTX.data[0],
      });
      prevProps.getPaymentMethodsCTX = getPaymentMethodsCTX;
    }
  }, [getPaymentMethodsCTX]);

  useEffect(() => {
    if (!window.Intercom) return;

    if (getAccountPredefinedCTX && getAccountPredefinedCTX.data && !prevProps.getAccountPredefinedCTX) {
      window.Intercom("update", {
        app_id: process.env.REACT_APP_INTERCOM_API_KEY,
        "ws-plan": getAccountPredefinedCTX.data.attributes.name,
      });
      prevProps.getAccountPredefinedCTX = getAccountPredefinedCTX;
    }
  }, [getAccountPredefinedCTX]);

  useEffect(() => {
    if (!window.Intercom) return;

    if (subscription && !prevProps.subscription) {
      window.Intercom("update", {
        app_id: process.env.REACT_APP_INTERCOM_API_KEY,
        "ws-creation-date": subscription.attributes.start_at,
      });
      prevProps.subscription = subscription;
    }
  }, [subscription]);

  useEffect(() => {
    if (!window.Intercom) return;

    if (workstations && workstations[0] && !prevProps.workstations) {
      window.Intercom("update", {
        app_id: process.env.REACT_APP_INTERCOM_API_KEY,
        "ws-count": workstations.length,
        "ws-name": workstations[0].attributes.machine.attributes.name,
      });
      prevProps.workstations = workstations;
    }
  }, [workstations]);

  useEffect(() => {
    if (!window.Intercom) return;

    if (
      getWorkstationMachineTypesCTX &&
      getWorkstationMachineTypesCTX.data &&
      getWorkstationMachineTypesCTX.data.machine_types
    ) {
      const remainingCredits = {};
      let remainingTotalCredits = 0;
      getWorkstationMachineTypesCTX.data.machine_types.forEach((machine) => {
        const friendlyName = machine.attributes.details.attributes.friendly_name;
        remainingCredits[`ws-left-${friendlyName.toLowerCase()}`] = machine.attributes.remaining_credit;
        remainingTotalCredits += machine.attributes.remaining_credit;
      });

      const intercomObject = {
        app_id: process.env.REACT_APP_INTERCOM_API_KEY,
        "ws-left-total": remainingTotalCredits,
      };
      Object.assign(intercomObject, remainingCredits);

      window.Intercom("update", intercomObject);
    }
  }, [getWorkstationMachineTypesCTX]);

  return <>{children}</>;
};

const mapStateToProps = (state) => ({
  account: state.account,
  payment: state.payment,
  subscriptions: state.subscriptions,
  workstations: state.workstations,
});

export default connect(mapStateToProps, null)(IntercomWrapper);
