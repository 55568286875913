import { useState, useEffect } from "react";
import { useTimezoneSelect } from "react-timezone-select";

const useMatchTimeZone = (userTimeZone) => {
  const [timezoneLabel, setTimezoneLabel] = useState("(UTC+0:00) UTC");

  const timezones = Intl.supportedValuesOf("timeZone").reduce((acc, timezone) => {
    acc[timezone] = timezone;
    return acc;
  }, {});

  const { options } = useTimezoneSelect({ labelStyle: "original", timezones, displayValue: "UTC" });

  useEffect(() => {
    const foundOption = options.find((opt) => opt.value === userTimeZone);
    const label = userTimeZone ? foundOption?.label.replace(/\//g, " / ").replace(/_/g, " ") : "(UTC+0:00) UTC";

    setTimezoneLabel(label);
  }, [userTimeZone, options]);

  return timezoneLabel;
};

export default useMatchTimeZone;
