import { getWorkstationsAPI } from "Actions/Dashboard/Workstations.actions";
import { useDispatch } from "react-redux";
import { API_ENDPOINTS } from "Constants/api.constants";
import routes from "Constants/Route.constants";
import React from "react";
import { ConfirmationModal } from "UI";
import { useAPI } from "Utils";

const BalanceExceedModal = (props) => {
  const { notificationId, translate } = props;
  const dispatch = useDispatch();

  const { api: balanceExceedNotificationAPI } = useAPI({
    type: "put",
    endpoint: API_ENDPOINTS.READ_NOTIFICATION(notificationId),
    onSuccess: () => {
      dispatch(getWorkstationsAPI());
    },
    onErr: () => {
      dispatch(getWorkstationsAPI());
    },
  });

  return (
    <ConfirmationModal
      headerText={translate("modals.balanceExceed.header")}
      descriptionText={translate("modals.balanceExceed.description")}
      confirmText={translate("modals.balanceExceed.confirm")}
      confirmAction={() => {
        balanceExceedNotificationAPI(notificationId);
        window.open(routes.billing, "_blank");
      }}
      secondaryText={translate("modals.balanceExceed.cancel")}
      secondaryAction={() => {
        balanceExceedNotificationAPI(notificationId);
      }}
      closeAction={() => {
        balanceExceedNotificationAPI(notificationId);
      }}
    />
  );
};

export default BalanceExceedModal;
