import { LOCAL_STORAGE } from "Constants/global.constants";
import { getItemFromLocalStorage } from "Utils/Helpers/storage.helpers";

export const eventTypes = {
  undefined: "0",
  keyboardEvent: "1",
  mouseEvent: "2",
  windowResizeEvent: "3",
  requestStreamEvent: "4",
  enterPasswordEvent: "5",
  setAutoStopEvent: "6",
  ctrlAltDelEvent: "7",
  showScreenKeyboardEvent: "8",
  pasteToRemoteEvent: "9",
  copyFromRemoteEvent: "10",
  fileUploadEvent: "11",
  fileUploadCompletedEvent: "12",
  fileUploadCancelEvent: "13",
  setClientTokenEvent: "14",
  startDriverInstallEvent: "15",
  pongEvent: "16",
  stopWarmUpEvent: "17",
  testEvent: "18",
  pingEvent: "19",
  downloadFileEvent: "20",
  touchEvent: "21",
  requestKeyFrameEvent: "22",
  applicationEvent: "25",
  keyboardLayoutChangeEvent: "26",
  requestTempFilesEvent: "27",
  requestTempFileDownloadEvent: "28",
  openVagonExplorerEvent: "29",
  toggleVRModeEvent: "30",
  vrInputEvent: "31",
  vrFovEvent: "32",
  vrInstallationEvent: "33",
  vrToolsStopEvent: "34",
  isVrToolsRunningEvent: "35",
  vrBitrateEvent: "36",
};

export const generateKeyboardPayload = (key, keyCode, code, isUp) => {
  const message = {
    $type: eventTypes.keyboardEvent,
    k: key,
    kc: keyCode,
    c: code,
    u: isUp,
  };
  return JSON.stringify(message);
};

export const generateMouseEventPayload = (x, y, wheelDeltaX, wheelDeltaY, button, isUp, gameMode) => {
  let buttonType = "";

  if (button === 1) {
    // Left click
    buttonType = "l";
  } else if (button === 2) {
    // Middle click
    buttonType = "m";
  } else if (button === 3) {
    // Right click
    buttonType = "r";
  }
  const message = {
    $type: eventTypes.mouseEvent,
    x,
    y,
    wdx: Math.round(wheelDeltaX),
    wdy: Math.round(wheelDeltaY),
    b: buttonType,
    u: isUp,
    dm: gameMode,
  };
  return JSON.stringify(message);
};

export const generateWindowResizeEventPayload = (x, y, retryConnectionCount = 0) => {
  const message = {
    $type: eventTypes.windowResizeEvent,
    x,
    y,
    rc: retryConnectionCount,
  };
  return JSON.stringify(message);
};

export const generateRequestStreamEventPayload = (player) => {
  const advancedStreamingSettings = getItemFromLocalStorage(LOCAL_STORAGE.advancedStreamSettings, {});

  const message = {
    $type: eventTypes.requestStreamEvent,
    player,
  };

  Object.assign(message, advancedStreamingSettings);

  return JSON.stringify(message);
};

export const generateRequestStreamEventPayloadForAppStream = (
  appName,
  appDirectory,
  appArguments,
  appResetArguments,
  sessionId,
  player,
) => {
  const advancedStreamSettings = getItemFromLocalStorage(LOCAL_STORAGE.advancedStreamSettings, {});

  const message = {
    $type: eventTypes.requestStreamEvent,
    quality: advancedStreamSettings.quality,
    player,
  };
  // TODO: Write a proper interface for sending those properties and remove this temporary solution
  Object.assign(message, advancedStreamSettings);

  const appStreamConfig = {
    st: 1,
    an: appName,
    ad: appDirectory,
    aa: appArguments,
    ara: appResetArguments,
    asid: sessionId,
  };
  Object.assign(message, appStreamConfig);
  return JSON.stringify(message);
};

export const generateEnterPasswordEventPayload = (password, useLock, useCtrlAltDel) => {
  const message = {
    $type: eventTypes.enterPasswordEvent,
    p: password,
    ul: useLock,
    ucad: useCtrlAltDel,
  };
  return JSON.stringify(message);
};

export const generateSetAutoStopEventPayload = (enabled, threshold) => {
  const message = {
    $type: eventTypes.setAutoStopEvent,
    e: enabled,
    t: threshold,
  };
  return JSON.stringify(message);
};

export const generateStopWarmUpEventPayload = () => {
  const message = {
    $type: eventTypes.stopWarmUpEvent,
  };
  return JSON.stringify(message);
};

export const generateCtrlAltDelEventPayload = () => {
  const message = {
    $type: eventTypes.ctrlAltDelEvent,
  };
  return JSON.stringify(message);
};

export const generateShowScreenKeyboardEventPayload = () => {
  const message = {
    $type: eventTypes.showScreenKeyboardEvent,
  };
  return JSON.stringify(message);
};

export const generatePasteToRemoteEventPayload = (text, pasteExecute) => {
  const message = {
    $type: eventTypes.pasteToRemoteEvent,
    t: text,
    pe: pasteExecute,
  };
  return JSON.stringify(message);
};

export const generateCopyFromRemoteEventPayload = () => {
  const message = {
    $type: eventTypes.copyFromRemoteEvent,
  };
  return JSON.stringify(message);
};

export const generateFileUploadEventPayload = (file) => {
  const message = {
    $type: eventTypes.fileUploadEvent,
    fn: file.name,
    fs: file.size,
    cs: 200,
  };
  return JSON.stringify(message);
};

export const generateFileUploadCompletedEventPayload = () => {
  const message = {
    $type: eventTypes.fileUploadCompletedEvent,
  };
  return JSON.stringify(message);
};

export const generateFileUploadCancelEventPayload = () => {
  const message = {
    $type: eventTypes.fileUploadCancelEvent,
  };
  return JSON.stringify(message);
};

export const generateSetClientTokenEventPayload = (accessToken) => {
  const message = {
    $type: eventTypes.setClientTokenEvent,
    t: accessToken,
    bu: process.env.REACT_APP_API_BASE_URL,
  };
  return JSON.stringify(message);
};

export const generatePongEventPayload = () => {
  const message = {
    $type: eventTypes.pongEvent,
  };
  return JSON.stringify(message);
};

export const generateTestEventPayload = (command) => {
  const message = {
    $type: eventTypes.testEvent,
    c: command,
  };
  return JSON.stringify(message);
};

export const generatePingEventPayload = () => {
  const message = {
    $type: eventTypes.pingEvent,
  };
  return JSON.stringify(message);
};

export const generateDownloadFileEventPayload = (url, filename) => {
  const message = {
    $type: eventTypes.downloadFileEvent,
    u: url,
    fn: filename,
  };
  return JSON.stringify(message);
};

export const generateTouch = (identifier, x, y, isCancelled, isUp, isMove = false) => {
  const touch = {
    x,
    y,
    i: identifier,
    u: isUp,
    c: isCancelled,
    m: isMove,
  };
  return touch;
};

export const generateTouchEventMessage = (touches) => {
  const message = {
    $type: eventTypes.touchEvent,
    tl: touches,
  };
  return JSON.stringify(message);
};

export const generateRequestKeyFrameEventMessage = () => {
  const message = {
    $type: eventTypes.requestKeyFrameEvent,
  };
  return JSON.stringify(message);
};

export const generateApplicationEventMessage = (payload) => {
  const message = {
    $type: eventTypes.applicationEvent,
    p: payload,
  };
  return JSON.stringify(message);
};

export const generateKeyboardLayoutChangeEventMessage = (layout) => {
  const message = {
    $type: eventTypes.keyboardLayoutChangeEvent,
    l: layout,
  };
  return JSON.stringify(message);
};

export const generateRequestTempFilesEventMessage = () => {
  const message = {
    $type: eventTypes.requestTempFilesEvent,
  };
  return JSON.stringify(message);
};

export const generateRequestTempFileDownloadEventMessage = (fileName) => {
  const message = {
    $type: eventTypes.requestTempFileDownloadEvent,
    fn: fileName,
  };
  return JSON.stringify(message);
};

export const generateOpenVagonExplorerEventMessage = () => {
  const message = {
    $type: eventTypes.openVagonExplorerEvent,
  };
  return JSON.stringify(message);
};

export const generateApplicationEventShutdownMessage = () => {
  const message = {
    $type: eventTypes.applicationEvent,
    p: JSON.stringify({
      $type: sdkMessageTypes.shutdown,
    }),
  };
  return JSON.stringify(message);
};

export const generateApplicationEventSessionInformationMessage = (data) => {
  const payload = {
    $type: sdkMessageTypes.sessionInformation,
    ...data,
  };
  const message = {
    $type: eventTypes.applicationEvent,
    p: JSON.stringify(payload),
  };
  return JSON.stringify(message);
};

export const generateApplicationEventConnectionMessage = (connected) => {
  const payload = {
    $type: sdkMessageTypes.connection,
    isConnected: connected,
  };
  const message = {
    $type: eventTypes.applicationEvent,
    p: JSON.stringify(payload),
  };
  return JSON.stringify(message);
};

export const generateToggleVRModeEventMessage = (mode) => {
  const message = {
    $type: eventTypes.toggleVRModeEvent,
    VRMode: mode,
  };
  return JSON.stringify(message);
};

export const generateXRDevicesEventMessage = (timestamp, devices, inputs) => {
  const message = {
    $type: eventTypes.vrInputEvent,
    t: {
      tt: timestamp,
      d: devices,
      i: inputs,
    },
  };
  return JSON.stringify(message);
};

export const generateXRFovEventMessage = (ipdM, leftFov, rightFov) => {
  const message = {
    $type: eventTypes.vrFovEvent,
    i: ipdM,
    f: [
      {
        l: leftFov.left,
        r: leftFov.right,
        u: leftFov.up,
        d: leftFov.down,
      },
      {
        l: rightFov.left,
        r: rightFov.right,
        u: rightFov.up,
        d: rightFov.down,
      },
    ],
  };
  return JSON.stringify(message);
};

export const generateVRInstallationEventMessage = () => {
  const message = {
    $type: eventTypes.vrInstallationEvent,
  };
  return JSON.stringify(message);
};

export const generateStopVRToolsEventMessage = () => {
  const message = {
    $type: eventTypes.vrToolsStopEvent,
  };
  return JSON.stringify(message);
};

export const generateIsVrToolsRunningEventMessage = () => {
  const message = {
    $type: eventTypes.isVrToolsRunningEvent,
  };
  return JSON.stringify(message);
};

export const generateVRBitrateEventMessage = (bitrate) => {
  const message = {
    $type: eventTypes.vrBitrateEvent,
    b: bitrate,
  };
  return JSON.stringify(message);
};

export const parseMessageString = (message) => JSON.parse(message);

export const messageTypes = {
  _undefined: "0",
  keyboardLayout: "1",
  screenReady: "2",
  showCanvas: "3",
  windowsResizedCompleted: "4",
  info: "5",
  clipboard: "6",
  fileInfo: "7",
  cursorIconChanged: "8",
  ping: "9",
  streamStats: "10",
  actionTrigger: "11",
  test: "12",
  pong: "13",
  multipleConnection: "14",
  mousePosition: "15",
  applicationMessage: "16",
  tempFilesList: "17",
  vrToolsInstallationState: "19",
  vrToolsStatus: "20",
};

export const fileInfoMessageActionTypes = {
  _undefined: 0,
  fileUploadStarted: 1,
  fileUploadCompleted: 2,
  chunkProcessCompleted: 3,
  downloadFromUrl: 4,
  downloadFromUrlStarted: 5,
  fileUploadCancelled: 6,
  fileUploadError: 7,
  createFileError: 8,
};

export const infoMessageTypes = {
  log: 0,
  toast: 1,
  information: 2,
  error: 3,
  loadingText: 4,
  driverUpdate: 5,
  driverInstallation: 6,
};

export const actionTriggerTypes = {
  showYouTubePopUp: 0,
  showVimeoPopUp: 1,
  endSession: 2,
  openUrl: 3,
  showKeyboard: 4,
  hideKeyboard: 5,
  enableGameMode: 6,
  disableGameMode: 7,
  keepAlive: 8,
  resize: 9,
  focusIframe: 10,
  setQuality: 11,
  requestSessionInformation: 12,
  onShutDown: 13,
  onPreloadedFilesDownloadCompleted: 14,
  vrToolsUpdateNeeded: 15,
  vrToolsInstalationFailed: 16,
  vrToolsInstallationSuccess: 17,
  vrToolsNotRunning: 18,
};

export const testMessageTypes = {
  ready: 0,
  stopped: 1,
};

export const testEventCommands = {
  start: 0,
  stop: 1,
};

export const sdkMessageTypes = {
  shutdown: "14",
  connection: "15",
  sessionInformation: "19",
};
