export const HOME_ONBOARDING_STEPS = {
  initialStep: { step: 0 },
  addUser: { step: 1 },
  invitedUser: { step: 2 },
  createComputer: { step: 3 },
  sampleComputer: { step: 4 },
  assignedComputer: { step: 5 },
};

export const PLANS_ONBOARDING_STEPS = {
  plansTab: { step: 0 },
  extendButton: { step: 1 },
  details: { step: 2 },
  createPlan: { step: 3 },
  newPlan: { step: 4 },
};

export const TEMPLATES_ONBOARDING_STEPS = {
  templatesTab: { step: 0 },
  templateDetail: { step: 1 },
  createButton: { step: 2 },
  chooseMethod: { step: 0 },
  appSelection: { step: 1 },
  createTemplate: { step: 2 },
  assignTemplate: { step: 0 },
};

export const FILES_ONBOARDING_STEPS = {
  folders: { step: 0 },
  buttons: { step: 1 },
  popup: { step: 2 },
};

export const BILLING_ONBOARDING_STEPS = {
  billingTab: { step: 0 },
  balance: { step: 1 },
  paymentMethod: { step: 2 },
  transactionDetails: { step: 3 },
};

export const STATS_ONBOARDING_STEPS = {
  statsTab: { step: 0 },
  graph: { step: 1 },
  table: { step: 2 },
  filters: { step: 3 },
};

export const ORGANIZATION_HOME_DUMMY_DATA = [
  {
    id: "onboardingMachine",
    attributes: {
      user: null,
      machine: {
        id: "Machine2",
        attributes: {
          friendly_status: "Offline",
          name: "Sample Computer",
        },
      },
      seat: {
        id: "4",
        attributes: {
          name: "Sample Computer",
          friendly_name: "Sample Computer",
          plan: {
            attributes: {
              name: "Teams Trial Plan",
            },
          },
          remaining_usage: 50,
          status: "Inactive",
        },
      },
      status: "idle-computer",
      menu_items: ["Item3", "Item4"],
      user_type: null,
      invitation: null,
      name: "Sample Computer",
      highlightClass: "highlight-second",
    },
  },
  {
    id: "onBoardingMember",
    attributes: {
      user: {
        name: "Vincent van Gogh",
        email: "vincent@vagon.io",
      },
      machine: {
        id: "Machine2",
        attributes: {
          friendly_status: "Offline",
        },
      },
      seat: {
        id: "Seat2",
        attributes: {
          plan: {
            attributes: {},
          },
          remaining_usage: 50,
          status: "Inactive",
        },
      },
      status: "pending-invitation",
      menu_items: ["Item3", "Item4"],
      user_type: "member",
      invitation: "Invitation2",
      highlightClass: "highlight-third",
    },
  },
  {
    id: "assignedUser",
    attributes: {
      user: {
        name: "Vincent van Gogh",
        email: "vincent@vagon.io",
      },
      machine: {
        id: "Machine2",
        attributes: {
          friendly_status: "Offline",
          name: "Sample Computer",
        },
      },
      seat: {
        id: "Seat2",
        attributes: {
          plan: {
            attributes: {
              name: "Teams Trial Plan",
            },
          },
          remaining_usage: 50,
          status: "Inactive",
        },
      },
      status: "computer_assigned",
      menu_items: ["Item3", "Item4"],
      user_type: "member",
      invitation: "Invitation2",
      highlightClass: "highlight-third",
    },
  },
];

export const ORGANIZATION_PLANS_DUMMY_DATA = [
  {
    id: "1",
    attributes: {
      name: "Sample Plan",
      budget: 153.79,
      disk_size: 225,
      file_storage_size: 50,
      usage: 3600,
      usage_list: [
        {
          machine_type: { id: 1 },
          usage: 1,
        },
        {
          machine_type: { id: 2 },
          usage: 1,
        },
      ],
      organization_id: 1,
      assigned_seats: [],
      highlightClass: "highlight-sample-plan",
    },
  },
];

export const ORGANIZATION_TEMPLATES_DUMMY_TEMPLATE_DATA = {
  pageParams: [undefined],
  pages: [
    {
      client_code: 200,
      count: 4,
      has_any_silver_image: true,
      next_page: null,
      page: 1,
      silver_images: [
        {
          attributes: {
            assigned_seats: [],
            assigned_to_any_seat: false,
            created_at: "2023-12-20T11:55:47.216Z",
            name: "Template #65",
            size: 20,
            softwares: [
              {
                attributes: {
                  installation_time: 1,
                  logo: "https://non-prod-vagon-assets-bucket.s3.eu-west-1.amazonaws.com/softwares/blender.png",
                  name: "Blender",
                  size: 0.7,
                  tags: ["Architecture", "Modelling"],
                  version: "1",
                },
                id: "1",
                type: "software",
              },
            ],
            source: "pre_installation",
            status: "available",
            updated_at: "2023-12-20T11:55:47.222Z",
          },
          id: "65",
          type: "silver_image",
        },
      ],
      timestamp: "2023-12-05T20:19:23Z",
    },
  ],
};

export const ORGANIZATION_TEMPLATES_DUMMY_DATA = [
  {
    id: "1",
    type: "software",
    attributes: {
      installation_time: 1,
      logo: "https://non-prod-vagon-assets-bucket.s3.eu-west-1.amazonaws.com/softwares/blender.png",
      name: "Blender",
      size: 0.7,
      tags: ["Architecture", "Modelling"],
      version: "1",
    },
  },
];

export const ORGANIZATION_FILES_DUMMY_DATA = [
  {
    id: "001",
    type: "file",
    attributes: {
      content_type: "directory",
      deletable: true,
      file_storage_size: null,
      file_storage_usage: null,
      last_modified_date: null,
      name: "Sample Folder",
      object_type: "directory",
      parent_id: null,
      path: "/Sample Folder",
      region: "dublin",
      size: 0,
      status: "upload_completed",
    },
  },
  {
    id: "002",
    type: "file",
    attributes: {
      content_type: "directory",
      deletable: true,
      file_storage_size: null,
      file_storage_usage: null,
      last_modified_date: null,
      name: "Sample File.pdf",
      object_type: "directory",
      parent_id: null,
      path: "/Sample File",
      region: "dublin",
      size: 10,
      status: "upload_completed",
      highlightClass: "sample-file",
    },
  },
];

export const ORGANIZATION_FILES_DUMMY_ARRAY = [
  { id: 0, name: "Home" },
  { id: "545", name: "Teams Shared Folder" },
];

export const ORGANIZATION_FILES_DUMMY_CURRENT_FOLDER = {
  attributes: {
    content_type: "directory",
    name: "Teams Shared Folder",
    object_type: "root",
  },
};

export const ORGANIZATION_FILES_POP_UP_POSITION = { top: 324, x: 540, y: 390 };

export const ORGANIZATION_BILLING_DUMMY_DATA = [
  {
    id: "1",
    date: "05-02-2024",
    member: "Michelangelo",
    transaction: "Computer Payment",
    amount: "-20.00",
  },
  {
    id: "2",
    date: "09-02-2024",
    member: "Michelangelo",
    transaction: "Additional Usage",
    amount: "-2.34",
  },
  {
    id: "3",
    date: "13-02-2024",
    member: "Michelangelo",
    transaction: "File Storage",
    amount: "-15.00",
  },
  {
    id: "4",
    date: "05-03-2024",
    member: "Michelangelo",
    transaction: "Computer Payment",
    amount: "-20.00",
  },
  {
    id: "5",
    date: "21-03-2024",
    member: "Michelangelo",
    transaction: "Computer Payment",
    amount: "-15.00",
  },
];

export const ORGANIZATION_STATS_DUMMY_DATA = [
  {
    attributes: {
      duration: 121,
      machine_type: {
        attributes: {
          friendly_name: "Sand",
        },
        id: "0",
      },
      region: "Dublin",
      start_at: "2024-02-10T16:14:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "0",
    type: "machine_session",
  },
  {
    attributes: {
      duration: 35,
      machine_type: {
        attributes: {
          friendly_name: "Planet",
        },
        id: "1",
      },
      region: "Frankfurt",
      start_at: "2024-02-10T13:16:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "1",
    type: "machine_session",
  },
  {
    attributes: {
      duration: 58,
      machine_type: {
        attributes: {
          friendly_name: "Planet",
        },
        id: "2",
      },
      region: "Dublin",
      start_at: "2024-02-16T07:52:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "2",
    type: "machine_session",
  },
  {
    attributes: {
      duration: 104,
      machine_type: {
        attributes: {
          friendly_name: "Sand",
        },
        id: "3",
      },
      region: "Dublin",
      start_at: "2024-02-18T16:37:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "3",
    type: "machine_session",
  },
  {
    attributes: {
      duration: 391,
      machine_type: {
        attributes: {
          friendly_name: "Planet",
        },
        id: "4",
      },
      region: "Frankfurt",
      start_at: "2024-02-21T19:20:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "4",
    type: "machine_session",
  },
  {
    attributes: {
      duration: 125,
      machine_type: {
        attributes: {
          friendly_name: "Planet",
        },
        id: "5",
      },
      region: "Frankfurt",
      start_at: "2024-02-21T20:23:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "5",
    type: "machine_session",
  },
];

export const ORGANIZATION_STATS_FILTERED_DUMMY_DATA = [
  {
    attributes: {
      duration: 35,
      machine_type: {
        attributes: {
          friendly_name: "Planet",
        },
        id: "1",
      },
      region: "Frankfurt",
      start_at: "2024-02-10T13:16:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "1",
    type: "machine_session",
  },
  {
    attributes: {
      duration: 391,
      machine_type: {
        attributes: {
          friendly_name: "Planet",
        },
        id: "4",
      },
      region: "Frankfurt",
      start_at: "2024-02-21T19:20:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "4",
    type: "machine_session",
  },
  {
    attributes: {
      duration: 125,
      machine_type: {
        attributes: {
          friendly_name: "Planet",
        },
        id: "5",
      },
      region: "Frankfurt",
      start_at: "2024-02-21T20:23:33.516Z",
      user: {
        id: "230b932c-b573-44d5-9b4d-56fda493f56b",
        type: "user",
        attributes: { email: "team_member@vagon.io" },
      },
      was_activity_watcher_enabled: true,
    },
    id: "5",
    type: "machine_session",
  },
];

export const ORGANIZATION_STATS_GRAPH_DUMMY_DATA = [
  { count: 0, time: "1/02" },
  { count: 0, time: "2/02" },
  { count: 0, time: "3/02" },
  { count: 0, time: "4/02" },
  { count: 0, time: "5/02" },
  { count: 0, time: "6/02" },
  { count: 0, time: "7/02" },
  { count: 0, time: "8/02" },
  { count: 0, time: "9/02" },
  { count: 0, time: "10/02" },
  { count: 0, time: "11/02" },
  { count: 0, time: "12/02" },
  { count: 0, time: "13/02" },
  { count: 0, time: "14/02" },
  { count: 2, time: "15/02" },
  { count: 1, time: "16/02" },
  { count: 0, time: "17/02" },
  { count: 1, time: "18/02" },
  { count: 0, time: "19/02" },
  { count: 0, time: "20/02" },
  { count: 2, time: "21/02" },
  { count: 0, time: "22/02" },
  { count: 0, time: "23/02" },
  { count: 0, time: "24/02" },
];

export const ORGANIZATION_STATS_GRAPH_FILTERED_DUMMY_DATA = [
  { count: 0, time: "1/02" },
  { count: 0, time: "2/02" },
  { count: 0, time: "3/02" },
  { count: 0, time: "4/02" },
  { count: 0, time: "5/02" },
  { count: 0, time: "6/02" },
  { count: 0, time: "7/02" },
  { count: 0, time: "8/02" },
  { count: 0, time: "9/02" },
  { count: 1, time: "10/02" },
  { count: 0, time: "11/02" },
  { count: 0, time: "12/02" },
  { count: 0, time: "13/02" },
  { count: 0, time: "14/02" },
  { count: 0, time: "15/02" },
  { count: 0, time: "16/02" },
  { count: 0, time: "17/02" },
  { count: 0, time: "18/02" },
  { count: 0, time: "19/02" },
  { count: 0, time: "20/02" },
  { count: 2, time: "21/02" },
  { count: 0, time: "22/02" },
  { count: 0, time: "23/02" },
  { count: 0, time: "24/02" },
];

export const ORGANIZATION_STATS_DURATION_DUMMY_DATA = {
  average_session_duration: 2.3,
  total_session_duration: 14.1,
};

export const ORGANIZATION_STATS_DURATION_FILTERED_DUMMY_DATA = {
  average_session_duration: 3,
  total_session_duration: 9.1,
};

export const ORGANIZATION_STATS_GRAPH_INITIAL_VALUE = [{ count: 0, time: "1/12" }];
