import React from "react";

import "./LinkButton.style.scss";

const LinkButton = (props) => {
  const { text, block, big, blue, className: classNameFromProps, ...rest } = props;

  const className = ["vg-link-button", classNameFromProps, block && "block", big && "big", blue && "blue"]
    .filter(Boolean) // Remove falsy values from classname list to avoid redundant spaces.
    .join(" ");

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a className={className} type="button" {...rest}>
      {text}
    </a>
  );
};

export default LinkButton;
