export const GET_REWARDS_API_PENDING = "GET_REWARDS_API_PENDING";
export const GET_REWARDS_API_SUCCESS = "GET_REWARDS_API_SUCCESS";
export const GET_REWARDS_API_FAILURE = "GET_REWARDS_API_FAILURE";

export const GET_REWARDS_STATUS_API_PENDING = "GET_REWARDS_STATUS_API_PENDING";
export const GET_REWARDS_STATUS_API_SUCCESS = "GET_REWARDS_STATUS_API_SUCCESS";
export const GET_REWARDS_STATUS_API_FAILURE = "GET_REWARDS_STATUS_API_FAILURE";

export const CLAIM_REWARDS_API_PENDING = "CLAIM_REWARDS_API_PENDING";
export const CLAIM_REWARDS_API_SUCCESS = "CLAIM_REWARDS_API_SUCCESS";
export const CLAIM_REWARDS_API_FAILURE = "CLAIM_REWARDS_API_FAILURE";

export const INVITE_API_PENDING = "INVITE_API_PENDING";
export const INVITE_API_SUCCESS = "INVITE_API_SUCCESS";
export const INVITE_API_FAILURE = "INVITE_API_FAILURE";
