import React, { useEffect, useMemo, useState } from "react";
import { DashboardSubScreenLayout, apiGenerator, isMobile } from "Utils";
import { DivButton, Dropdown, HoverableTooltip, Icon } from "UI";
import axios from "axios";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useQuery } from "@tanstack/react-query";
import Computer from "Assets/images/organizations/computer-duration.png";
import MeteredDuration from "Assets/images/organizations/metered-duration.png";
import AFKDuration from "Assets/images/organizations/afk-duration.png";
import ActiveDuration from "Assets/images/organizations/active-duration.png";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import routes from "Constants/Route.constants";
import UserSessionTable from "../Stats/UserSessionTable.component";
import AnalyticChartTable from "./AnalyticChartTable/AnalyticChartTable.component";
import EmptyContent from "../Components/EmptyContent/EmptyContent.component";
import AnalyticInformationModal from "./AnalyticInformationModal/AnalyticInformationModal.component";

import "./UsageAnalytics.styles.scss";

const UsageInfoCard = ({ value, info, img }) => {
  return (
    <div className="usage-info-card">
      <img src={img} alt="usage-info-card-icon" />
      <div className="text-container">
        <p>
          <span className="bold">{value}</span>
          <span className="light"> minutes</span>
        </p>
        <p>{info}</p>
      </div>
    </div>
  );
};

const UsageAnalytics = ({ translate }) => {
  const location = useLocation();
  const { session } = location?.state || {};
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [showAnalyticInformationModal, setShowAnalyticInformationModal] = useState(false);
  const history = useHistory();

  // clear state after setting selectedSession
  useEffect(() => {
    if (!session) return;
    setSelectedSession(session);
    history.replace({
      ...location,
      state: {
        ...location.state,
        session: null,
      },
    });
  }, [session]);

  const {
    data: usageStats,
    isLoading: usageStatsLoading,
    isInitialLoading,
  } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_USAGE_STATS],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_USAGE_STATS);
    },
  });

  const { data: usageStatFilters } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_USAGE_STAT_FILTERS, selectedSeat],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_MACHINE_SESSIONS_USAGE_STAT_FILTERS, {
        params: {
          seat_id: selectedSeat?.id,
        },
      });
    },
  });

  const { data: activityWatcherLogsStatsUrls, isLoading: activityWatcherLogsStatsUrlsLoading } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_ACTIVITY_WATCHER_LOGS_STATS_URLS],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_ACTIVITY_WATCHER_LOGS_STATS_URLS);
    },
    refetchOnWindowFocus: false,
  });

  const organizationStatsUrl = activityWatcherLogsStatsUrls?.data?.organization;
  const seatStatsUrl = activityWatcherLogsStatsUrls?.data?.seats?.[selectedSeat?.id];
  const sessionStatsUrl = activityWatcherLogsStatsUrls?.data?.sessions?.[selectedSession?.id];

  const { data: activityWatcherLogs, isLoading: activityWatcherLogsLoading } = useQuery({
    queryKey: ["activityWatcherLogs", seatStatsUrl, sessionStatsUrl, organizationStatsUrl],
    queryFn: () => {
      const url = sessionStatsUrl || seatStatsUrl || organizationStatsUrl;
      return axios.get(url);
    },
    refetchInterval: 0,
    retry: false,
    keepPreviousData: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const {
    used_apps: usedApps,
    used_titles: usedTitles,
    total_afk_time: totalAfkTime,
    afk_time: afkTime,
    active_time: activeTime,
  } = activityWatcherLogs?.data || {};

  const activeTimeMinutes = parseInt((activeTime || 0) / 60, 10);
  const afkTimeMinutes = parseInt((afkTime || totalAfkTime || 0) / 60, 10);

  const usageColumns = [
    { name: "id", weight: 0, hide: true },
    { name: "Computer", weight: 1 },
    { name: "Usage", weight: 1 },
  ];

  const totalDuration = useMemo(() => {
    if (selectedSession) return selectedSession?.attributes?.duration;
    if (selectedSeat) {
      const statSeat = usageStats?.data?.stats?.find((stat) => {
        return +stat.seat.id === +selectedSeat.id;
      });

      return statSeat?.duration || 0;
    }
    return usageStats?.data?.stats?.reduce((acc, curr) => {
      return acc + curr.duration;
    }, 0);
  }, [usageStats, selectedSeat, selectedSession]);

  const usageData = usageStats?.data?.stats?.map((stat) => {
    const { seat, duration } = stat;
    const { name, deleted } = seat.attributes;

    const setClassName = () => {
      if (!selectedSeat) return "";
      if (+selectedSeat.id !== +seat.id) return "opacity-50";
      return "";
    };

    return {
      key: seat.id,
      computer: (
        <div className="machine-name-cell">
          {name}
          {deleted && (
            <HoverableTooltip content="Archived">
              <Icon name="info" className="info-icon" smaller color="gray-4" />
            </HoverableTooltip>
          )}
        </div>
      ),
      usage: duration,
      onRowClick: () => {
        if (selectedSession) setSelectedSession(null);
        if (selectedSeat?.id === seat.id) {
          setSelectedSeat(null);
          return;
        }
        setSelectedSeat(seat);
      },
      className: setClassName(),
    };
  });

  useEffect(() => {
    if (selectedSeat?.id) {
      setSelectedSession(null);
    }
  }, [selectedSeat?.id]);

  const appUsageColumns = [
    { name: "id", weight: 0, hide: true },
    { name: "Application", weight: 1 },
    { name: "Usage", weight: 1 },
  ];

  const appUsageData = usedApps
    ?.filter((app) => {
      return app.usage > 0 || app.total_time > 0 || app.time > 0;
    })
    ?.map((app, index) => {
      const { app: name, total_time: usage, time } = app;
      let formattedName = name;

      try {
        formattedName = formattedName.endsWith(".exe")
          ? formattedName.substring(0, formattedName.length - 4)
          : formattedName;
        formattedName = formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
      } catch {
        formattedName = name;
      }

      return {
        key: index,
        application: formattedName,
        usage: (usage || time) / 60,
      };
    });

  const windowUsageColumns = [
    { name: "id", weight: 0, hide: true },
    { name: "Window Title", weight: 1 },
    { name: "Usage", weight: 1 },
  ];

  const windowUsageData = usedTitles
    ?.filter((app) => {
      if (!app.title || app.title === "") return false;
      return app.usage > 0 || app.total_time > 0 || app.time > 0;
    })
    ?.map((app, index) => {
      const { title: name, time: usage } = app;
      let formattedName = name;

      try {
        if (name.startsWith("C:")) {
          formattedName = formattedName.substring(formattedName.lastIndexOf("\\") + 1);
          formattedName = formattedName.endsWith(".exe")
            ? formattedName.substring(0, formattedName.length - 4)
            : formattedName;
          formattedName = formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
        }
      } catch {
        formattedName = name;
      }

      return {
        key: index,
        "window title": formattedName,
        usage: usage / 60,
      };
    });

  const computersResetKey = {
    id: "reset",
    attributes: {
      name: "All Computers",
    },
  };

  const sessionsResetKey = {
    id: "All Sessions",
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("organizationUsageAnalytics.header.title")}
      headerDescription={translate("organizationUsageAnalytics.header.description")}
      loading={isInitialLoading}
      noScroll={!usageData?.length > 0}
      translate={translate}
    >
      <div className="usage-analytics-container">
        {usageData?.length > 0 ? (
          <>
            <div className="filter-row">
              <div className="dropdown-container">
                <Dropdown
                  defaultSelected={selectedSeat}
                  options={usageStatFilters?.data?.seats}
                  handleSelectedOptionChange={(e) => {
                    setSelectedSeat(e);
                  }}
                  mapFunction={(seat) => {
                    return seat.attributes.name;
                  }}
                  placeholder="Computer"
                  resetKey={computersResetKey}
                  underlined
                  disabled={usageStatFilters?.data?.seats?.length === 0}
                />
                <Dropdown
                  defaultSelected={selectedSession}
                  options={usageStatFilters?.data?.sessions}
                  handleSelectedOptionChange={(e) => {
                    setSelectedSession(e);
                  }}
                  mapFunction={(session) => {
                    if (session.id === sessionsResetKey.id) return session.id;
                    return `Session #${session.id}`;
                  }}
                  placeholder="Session"
                  resetKey={sessionsResetKey}
                  underlined
                  disabled={usageStatFilters?.data?.sessions?.length === 0}
                />
              </div>
              <DivButton
                className="info-button-container"
                onClick={() => {
                  setShowAnalyticInformationModal(true);
                }}
              >
                <Icon name="info" color="gray-4" />
              </DivButton>
            </div>

            <div className="usage-info-cards">
              <UsageInfoCard img={Computer} value={totalDuration} info="Total Session Duration" translate={translate} />
              <UsageInfoCard
                img={MeteredDuration}
                value={activeTimeMinutes + afkTimeMinutes}
                info="Metered Duration"
                translate={translate}
              />
              <UsageInfoCard
                img={ActiveDuration}
                value={activeTimeMinutes}
                info="Active Duration"
                translate={translate}
              />
              <UsageInfoCard
                img={AFKDuration}
                value={afkTimeMinutes}
                info="User Not Active / AFK"
                translate={translate}
              />
            </div>

            <div className="analytic-charts-container">
              {isMobile ? (
                <>
                  <AnalyticChartTable
                    data={usageData}
                    columns={usageColumns}
                    translate={translate}
                    isLoading={usageStatsLoading}
                    isError={usageStats?.data?.stats?.length === 0}
                  />
                  <UserSessionTable
                    seatFilter={selectedSeat}
                    selectedSession={selectedSession}
                    usageAnalytics
                    setSelectedSession={(session) => {
                      if (selectedSession?.id === session.id) {
                        setSelectedSession(null);
                      } else {
                        setSelectedSession(session);
                      }
                    }}
                    translate={translate}
                  />
                </>
              ) : (
                <div className="upper-row">
                  <AnalyticChartTable
                    data={usageData}
                    columns={usageColumns}
                    translate={translate}
                    isLoading={usageStatsLoading}
                    isError={usageStats?.data?.stats?.length === 0}
                  />
                  <UserSessionTable
                    seatFilter={selectedSeat}
                    selectedSession={selectedSession}
                    usageAnalytics
                    setSelectedSession={(session) => {
                      if (selectedSession?.id === session.id) {
                        setSelectedSession(null);
                      } else {
                        setSelectedSession(session);
                      }
                    }}
                    translate={translate}
                  />
                </div>
              )}
              {isMobile ? (
                <>
                  <AnalyticChartTable
                    data={appUsageData}
                    columns={appUsageColumns}
                    translate={translate}
                    isLoading={activityWatcherLogsLoading}
                    isError={
                      !(activityWatcherLogsStatsUrlsLoading || activityWatcherLogsLoading) &&
                      (!appUsageData || appUsageData.length === 0)
                    }
                  />
                  <AnalyticChartTable
                    data={windowUsageData}
                    columns={windowUsageColumns}
                    translate={translate}
                    isLoading={activityWatcherLogsLoading}
                    isError={
                      !(activityWatcherLogsStatsUrlsLoading || activityWatcherLogsLoading) &&
                      (!windowUsageData || windowUsageData.length === 0)
                    }
                  />
                </>
              ) : (
                <div className="lower-row">
                  <AnalyticChartTable
                    data={appUsageData}
                    columns={appUsageColumns}
                    translate={translate}
                    isLoading={activityWatcherLogsLoading}
                    isError={
                      !(activityWatcherLogsStatsUrlsLoading || activityWatcherLogsLoading) &&
                      (!appUsageData || appUsageData.length === 0)
                    }
                  />
                  <AnalyticChartTable
                    data={windowUsageData}
                    columns={windowUsageColumns}
                    translate={translate}
                    isLoading={activityWatcherLogsLoading}
                    isError={
                      !(activityWatcherLogsStatsUrlsLoading || activityWatcherLogsLoading) &&
                      (!windowUsageData || windowUsageData.length === 0)
                    }
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <EmptyContent
            headerText={translate("organizationUsageAnalytics.emptyContent.title")}
            descriptionText={translate("organizationUsageAnalytics.emptyContent.description")}
            buttonText={translate("organizationUsageAnalytics.emptyContent.buttonText")}
            onButtonClick={() => {
              history.push(routes.organizationAdminHome);
            }}
          />
        )}
      </div>
      {showAnalyticInformationModal && (
        <AnalyticInformationModal
          translate={translate}
          onClose={() => {
            setShowAnalyticInformationModal(false);
          }}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default UsageAnalytics;
