import React from "react";

import { Icon } from "UI";
import { classnames } from "Utils";
import MenuItems from "./MenuItems.component";
import AppStreamingMenuItems from "./AppStreamingMenuItems.component";
import OrganizationAdminMenuItems from "./OrganizationAdminMenuItems.component";
import OrganizationMemberMenuItems from "./OrganizationMemberMenuItems.component";

const MenuWidescreen = (props) => {
  const { path } = props;
  const isAppStreamingPage = path.includes("/stream");
  const isOrganizationAdminPage = path.includes("/team/admin");
  const isOrganizationMemberPage = path.includes("/team/member");

  const getMenuItems = () => {
    if (isOrganizationAdminPage) return <OrganizationAdminMenuItems {...props} />;
    else if (isAppStreamingPage) return <AppStreamingMenuItems {...props} />;
    else if (isOrganizationMemberPage) return <OrganizationMemberMenuItems {...props} />;
    return <MenuItems {...props} />;
  };

  const iconColor = () => {
    if (isOrganizationAdminPage || isOrganizationMemberPage) return "white";
    return "purple-main";
  };

  return (
    <div
      className={classnames([
        "menu-widescreen-container",
        (isOrganizationAdminPage || isOrganizationMemberPage) && "blue",
        isAppStreamingPage && "app-streaming-menu",
      ])}
      id="menu-widescreen-container"
    >
      <Icon name="vagon-icon" color={iconColor()} />
      {getMenuItems()}
    </div>
  );
};

export default MenuWidescreen;
