import { isFirefox } from "Utils/Helpers/browser.helpers";

let chunks;
let isPlaying;
let startTime;
let lastChunkOffset;
let audioContext;
let bufferSize;
let initCompleted;

export const init = () => {
  initCompleted = false;
};

export const clear = () => {
  isPlaying = false;
  startTime = 0;
  lastChunkOffset = 0;
  bufferSize = 5;
  chunks = [];
};

export const unlock = () => {
  if (!initCompleted) {
    window.AudioContext = window.AudioContext
    || window.webkitAudioContext
    || window.mozAudioContext
    || window.oAudioContext
    || window.msAudioContext;
    audioContext = new AudioContext({
      latencyHint: "interactive",
    });
    // audioContext.onstatechange = () => console.log(audioContext.state);
    audioContext.suspend();
    initCompleted = true;
  }
  audioContext.resume();
};

const createChunk = (chunk) => {
  const source = audioContext.createBufferSource();
  source.buffer = chunk;
  source.connect(audioContext.destination);
  source.onended = (e) => {
    chunks.splice(chunks.indexOf(source), 1);
    if (chunks.length === 0) {
      isPlaying = false;
      startTime = 0;
      lastChunkOffset = 0;
    }
  };
  return source;
};

export const addChunkInternal = (data) => {
  if (isPlaying && (chunks.length > bufferSize)) {
    // throw away
  } else if (isPlaying && (chunks.length <= bufferSize)) { // schedule & add right now
    const chunk = createChunk(data);
    chunk.start(startTime + lastChunkOffset);
    lastChunkOffset += (chunk.buffer.duration - calculateGap());
    chunks.push(chunk);
  } else if ((chunks.length < (bufferSize / 2)) && !isPlaying) { // add & don't schedule
    const chunk = createChunk(data);
    chunks.push(chunk);
  } else { // add & schedule entire buffer
    isPlaying = true;
    const chunk = createChunk(data);
    chunks.push(chunk);
    startTime = audioContext.currentTime;
    lastChunkOffset = 0;
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      chunk.start(startTime + lastChunkOffset);
      lastChunkOffset += (chunk.buffer.duration - calculateGap());
    }
  }
};

export const addChunk = (data) => {
  if (!initCompleted) {
    return;
  }
  audioContext.decodeAudioData(data, (buffer) => {
    addChunkInternal(buffer);
  });
};

const calculateGap = () => {
  if (!isFirefox) {
    return 0;
  }
  return (startTime + lastChunkOffset) * 0.8;
};
