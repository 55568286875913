import React, { useEffect, useState } from "react";
import { useClickOutside } from "Utils/Hooks";
import { isInFullScreen } from "Utils/Helpers/browser.helpers";
import Electron from "Utils/Helpers/electron.helpers";
import { saveItemToLocalStorage, classnames, isMobile } from "Utils";
import { Icon, DivButton } from "UI";
import { LOCAL_STORAGE, IPC_MESSAGE, SESSION_STORAGE } from "Constants/global.constants";
import { getItemFromSessionStorage } from "Utils/Helpers/storage.helpers";
import { BIT_RATES } from "Constants/AppStreaming.constants";
import { useSelector } from "react-redux";

import "./AppStreamingDock.styles.scss";

const AppStreamingDock = ({
  isDockOpen,
  isMicEnabled,
  soundEnabled,
  setIsDockOpen,
  changeGameMode,
  changeSoundState,
  gameModeEnabled,
  changeableKeyMapping,
  quality,
  changeableQuality,
  toggleFullscreen,
  submitStreamPreference,
  toggleMic,
  translate,
  dockColor,
  dockPosition,
  filesEnabled,
  isFilesDockOpen,
  setIsFilesDockOpen,
  connected,
  clickToStart,
  pixelStreaming,
}) => {
  const [selectedQuality, setSelectedQuality] = useState(quality);
  const clickOutsideRef = useClickOutside(null, () => setIsDockOpen(false));

  const { sound, microphone } =
    useSelector((state) => state.workstations.getAppStreamingSessionCTX.data?.attributes) || {};

  const changeQualityPreference = (quality) => {
    if (quality.length === 0) return;
    saveItemToLocalStorage(LOCAL_STORAGE.appStreamingBitRate, BIT_RATES[quality]);
    saveItemToLocalStorage(LOCAL_STORAGE.appStreamingQualitySelection, quality);
    submitStreamPreference({ bitrate: BIT_RATES[quality] });
  };

  const qualityOpts = Object.keys(BIT_RATES);
  const availableOpts = qualityOpts.slice(0, qualityOpts.indexOf(quality) + 1);

  const dockWrapperClassName = classnames(["vg-app-streaming-dock-wrapper", isDockOpen && "open", dockPosition]);

  useEffect(() => {
    if (clickToStart) return;
    if (getItemFromSessionStorage(SESSION_STORAGE.streamDocksPreviewed)) return;

    if (connected) {
      setIsDockOpen(true);
      setTimeout(() => {
        setIsDockOpen(false);
      }, 3000);
    }
  }, [connected, clickToStart]);

  useEffect(() => {
    if (!isDockOpen) {
      // blur if focus is in this container
      if (document.activeElement && document.activeElement.closest(".vg-app-streaming-dock-container")) {
        document.activeElement.blur(); // remove focus from the button
      }
    }
  }, [isDockOpen]);

  return (
    <div
      onMouseEnter={() => setIsDockOpen(true)}
      onMouseLeave={() => setIsDockOpen(false)}
      className={classnames(["vg-app-streaming-dock-container", "mobile-only-hide", dockPosition])}
      ref={clickOutsideRef}
    >
      <div className={dockWrapperClassName}>
        <div className="app-streaming-dock ">
          <div className={classnames(["app-streaming-dock-content", dockPosition])}>
            {sound !== "off" && (
              <DivButton
                className="app-streaming-dock-menu-button"
                onClick={() => {
                  changeSoundState(!soundEnabled);
                  // setIsSoundOn(!isSoundOn);
                }}
              >
                <Icon name={soundEnabled ? "sound-on" : "sound-mute"} color={soundEnabled ? "purple-main" : "gray-2"} />
              </DivButton>
            )}
            <DivButton
              className="app-streaming-dock-menu-button"
              onClick={() => {
                toggleFullscreen();
                setIsDockOpen(false);
              }}
            >
              <Icon
                name={isInFullScreen() ? "active-fullscreen" : "new-fullscreen"}
                color={isInFullScreen() ? "purple-main" : "gray-2"}
              />
            </DivButton>
            {microphone !== "off" && !pixelStreaming && (
              <DivButton
                className="app-streaming-dock-menu-button"
                onClick={() => {
                  setIsDockOpen(!isDockOpen);
                  // Ask electron for mic access
                  Electron.sendAsyncMessage(IPC_MESSAGE.MICROPHONE_ACCESS);
                  toggleMic(!isMicEnabled);
                }}
              >
                <Icon name="microphone" color={isMicEnabled ? "purple-main-stroke" : "gray-2-stroke"} />
              </DivButton>
            )}
            {!isMobile && changeableKeyMapping && (
              <DivButton
                className={classnames(["app-streaming-dock-menu-button larger", gameModeEnabled && "active"])}
                onClick={() => {
                  changeGameMode();
                  setIsDockOpen(false);
                }}
              >
                <Icon name="view-mode" color={gameModeEnabled ? "purple-main" : "gray-2"} />
                <div>{translate("appStreamingDockMenu.view")}</div>
              </DivButton>
            )}
            {filesEnabled && (
              <DivButton
                className={classnames(["app-streaming-dock-menu-button", isFilesDockOpen && "active"])}
                onClick={() => {
                  setIsFilesDockOpen(!isFilesDockOpen);
                }}
              >
                <Icon name="file-drag" color={isFilesDockOpen ? "purple-main" : "gray-2"} />
              </DivButton>
            )}
            {changeableQuality && availableOpts.length > 1 && (
              <div className="app-streaming-dock-menu-button larger" style={{ backgroundColor: dockColor }}>
                <Icon name="monitor" color="gray-2-stroke" />
                <div>{translate("appStreamingDockMenu.quality")}</div>
                <div className="hoverable-menu">
                  {availableOpts.map((opt) => (
                    <DivButton
                      key={opt}
                      className="hoverable-item"
                      onClick={() => {
                        setSelectedQuality(opt);
                        changeQualityPreference(opt);
                      }}
                    >
                      {translate(`appStreamingDockMenu.${opt}`)}
                      {selectedQuality === opt && <Icon name="check-mark" color="aqua-main-stroke" tiny />}
                    </DivButton>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStreamingDock;
