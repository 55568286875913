import React from "react";
import { connect } from "react-redux";

import RegisterComponent from "Components/Authentication/Register/Register.component";

import {
  registerAPI,
  registerClear,
  organizationRegisterAPI,
  organizationRegisterClear,
  appStreamingRegisterAPI,
} from "Actions/Authentication/Register.actions";

class RegisterContainer extends React.Component {
  componentDidMount() {
    const { registerClear } = this.props;
    registerClear();
  }

  render() {
    const { location } = this.props;

    return (
      <RegisterComponent
        referralCode={this.referralCode}
        {...this.props}
        appStreaming={location.pathname.includes("stream")}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  registerCTX: state.registerCTX,
});

const actionCreators = {
  registerAPI,
  registerClear,
  organizationRegisterAPI,
  organizationRegisterClear,
  appStreamingRegisterAPI,
};

export default connect(mapStateToProps, actionCreators)(RegisterContainer);
