import React, { useEffect, useState } from "react";

import { Modal } from "UI";

import { FILE_TYPES, REQUEST_STATUS } from "Constants/global.constants";
import FileManagerTable from "../FileManagerTable/FileManagerTable.component";
import FileBreadcrumb from "../FileBreadcrumb/FileBreadcrumb.component";

import "./FileMoveModal.styles.scss";

const FileMoveModal = (props) => {
  const {
    moveFile,
    loadFileContents,
    moveFilesCTX,
    selectedFileId,
    selectedFileIds,
    setShowFileMoveModal,
    mobile,
    translate,
  } = props;
  const { contents, currentFolder, status, path } = moveFilesCTX;

  const [selectedItems, setSelectedItems] = useState([]);
  const selectedRowID = selectedItems[0];

  const targetFolderId = selectedRowID || (currentFolder ? currentFolder.id : 0);

  useEffect(() => {
    goToFolder(currentFolder.id);
  }, []);

  const disabledFileFilter = (file) => {
    if (selectedFileIds) {
      return (file.type !== FILE_TYPES.directory && file.type !== FILE_TYPES.root) || selectedFileIds.includes(file.id);
    }
    return (file.type !== FILE_TYPES.directory && file.type !== FILE_TYPES.root) || file.id === selectedFileId;
  };

  const goToFolder = (id) => {
    loadFileContents(id, true);
    setSelectedItems([]);
  };

  return (
    <Modal
      closeOnOverlayClick
      closeAction={() => setShowFileMoveModal(false)}
      topRightIcon="close"
      topRightIconAction={() => setShowFileMoveModal(false)}
      headerText={
        <FileBreadcrumb
          path={path}
          goToFolder={goToFolder}
          currentFolder={currentFolder}
          onBackButton={() => {
            goToFolder(currentFolder.attributes.parent_id);
          }}
        />
      }
      buttonText={
        selectedItems.length === 0
          ? translate("modals.moveFile.moveFolderButtonText")
          : translate("modals.moveFile.moveHereButtonText")
      }
      buttonAction={() => {
        moveFile(parseInt(targetFolderId, 10));
      }}
      isPending={status === REQUEST_STATUS.PENDING}
      mobile={mobile}
      blue
      invisibleOverlay
      small
    >
      <div className="file-move-modal-content">
        <FileManagerTable
          contents={contents}
          keys={["name"]}
          headers={[]}
          goToFolder={goToFolder}
          selectedRowID={selectedRowID}
          selectedFolderItems={selectedItems}
          setSelectedFolderItems={setSelectedItems}
          translate={translate}
          disabledFileFilter={disabledFileFilter}
          singleSelection
        />
      </div>
    </Modal>
  );
};

export default FileMoveModal;
