import React from "react";
import { Link } from "react-router-dom";
import routes from "Constants/Route.constants";
import { useSelector } from "react-redux";
import { classnames } from "Utils/Helpers/functions.helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Icon } from "UI";
import DropdownMenu from "UI/DropdownMenu/DropdownMenu.component";
import { isMobile } from "Utils/Helpers/browser.helpers";

const menuItemClassName = (path, checkValue, mobile, disabled) =>
  `menu-item ${mobile ? "" : "dark-navy"} ${path === checkValue ? "active" : ""} ${disabled ? "disabled" : ""}`;

const OrganizationMenuItems = (props) => {
  const { path, setShowMenu, mobile, appsMenu } = props;
  const { account } = useSelector((state) => state.account);
  const { organization_admin: organizationAdmin } = account?.attributes || {};
  const history = useHistory();

  const onLinkClick = () => {
    if (setShowMenu) {
      setShowMenu(false);
    }
  };

  const analyticsMenuItems = [
    {
      label: "Session Stats",
      onClick: () => {
        history.push(routes.organizationAdminStats);
        if (isMobile) setShowMenu(false);
      },
      rightSlot: path === routes.organizationAdminStats && <Icon name="apps-check" color="green" smaller />,
    },
    {
      label: "Application Usage",
      onClick: () => {
        history.push(routes.organizationAdminUsageAnalytics);
        if (isMobile) setShowMenu(false);
      },
      rightSlot: path === routes.organizationAdminUsageAnalytics && <Icon name="apps-check" color="green" smaller />,
    },
  ];

  return (
    <div className="menu-wrapper">
      <Link
        to={routes.organizationAdminHome}
        className={classnames([
          menuItemClassName(path, routes.organizationAdminHome, mobile, !organizationAdmin),
          "team-console",
        ])}
        onClick={onLinkClick}
      >
        Team Console
      </Link>
      {!mobile && <div className={classnames(["divider", !organizationAdmin && "disabled"])} />}
      <Link
        to={routes.organizationAdminPlans}
        className={classnames([
          menuItemClassName(path, routes.organizationAdminPlans, mobile, !organizationAdmin),
          "plans",
        ])}
        onClick={onLinkClick}
      >
        Plans
      </Link>
      <Link
        to={routes.organizationAdminTemplates}
        className={classnames([
          menuItemClassName(path, routes.organizationAdminTemplates, mobile, !organizationAdmin),
          "templates",
        ])}
        onClick={onLinkClick}
      >
        Templates
      </Link>
      <Link
        to={routes.organizationAdminFiles}
        className={classnames([
          menuItemClassName(path, routes.organizationAdminFiles, mobile, !organizationAdmin),
          "files",
        ])}
        onClick={onLinkClick}
      >
        Files
      </Link>
      <Link
        to={routes.organizationAdminPermissions}
        className={menuItemClassName(path, routes.organizationAdminPermissions, mobile, !organizationAdmin)}
        onClick={onLinkClick}
      >
        Permissions
      </Link>
      <DropdownMenu
        menuItems={analyticsMenuItems}
        side={mobile ? "top" : "right"}
        sideOffset={-20}
        align="end"
        alignOffset={4}
        disabled={!organizationAdmin}
      >
        <div
          className={classnames([
            menuItemClassName(
              path,
              path === routes.organizationAdminStats
                ? routes.organizationAdminStats
                : routes.organizationAdminUsageAnalytics,
              mobile,
              !organizationAdmin,
            ),
            "monitoring",
          ])}
        >
          <p>Monitoring</p>
          <Icon name="chevron-right" color="gray-3" smaller />
        </div>
      </DropdownMenu>
      <Link
        to={routes.organizationAdminBilling}
        className={classnames([
          menuItemClassName(path, routes.organizationAdminBilling, mobile, !organizationAdmin),
          "billing",
        ])}
        onClick={onLinkClick}
      >
        Billing
      </Link>
      <Link
        to={routes.organizationAdminSettings}
        className={menuItemClassName(path, routes.organizationAdminSettings, mobile, !organizationAdmin)}
        onClick={onLinkClick}
      >
        Settings
      </Link>

      <div className="menu-item-bottom">
        {appsMenu({ purpleBackground: !mobile && true })}
        <Link
          to={routes.login}
          className={menuItemClassName(path, routes.login, mobile)}
          onClick={() => {
            props.logoutAPI(props.account.accessToken);
            onLinkClick();
          }}
        >
          Sign out
        </Link>
        {!mobile && <div className="divider" />}
        <a
          className={`menu-item support ${mobile ? "" : "dark-navy"}`}
          href="http://help.vagon.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          Help / Support
        </a>
      </div>
    </div>
  );
};

export default OrganizationMenuItems;
