import React from "react";
import { useMutation } from "@tanstack/react-query";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator, isMobile } from "Utils";
import { convertDateFormat, convertToSelectedTimeZone, isOdd } from "Utils/Helpers/functions.helpers";
import { ExpandableTable, TextButton } from "UI";
import moment from "moment";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import StatusBadge from "UI/StatusBadge/StatusBadge.component";
import { useSelector } from "react-redux";
import UserCell from "../../Components/UserCell/UserCell.component";
import "./TemplateFromComputer.styles.scss";

const TemplateFromComputer = ({ history }) => {
  const {
    data: seats,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.ORGANIZATIONS_SEATS_IMAGE_CREATABLE,
    resource: "seats",
  });

  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};

  const createSilverImageMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES, data);
    },
    onSuccess: (resp) => {
      history.push({
        pathname: routes.organizationAdminTemplates,
        state: { createdSilverImageId: resp.data.id },
      });
    },
  });

  const columns = [
    { name: "Id", weight: 0, hide: true },
    { name: "User", weight: 10 },
    { name: "Status", weight: 5 },
    { name: "Computer", weight: isMobile ? 5 : 8 },
    { name: "Last Session", weight: isMobile ? 8 : 5 },
    { name: "button", hide: true, weight: 5 },
  ];

  const data = seats?.map((seat, index) => {
    const { user, machine } = seat.attributes;
    const { last_session_start_at: lastSessionStartAt, status, name } = machine.attributes;

    return {
      id: seat.id,
      key: seat.id,
      user: <UserCell user={user} seat={seat} removeCheckbox />,
      status: <StatusBadge status={status} color={isOdd(index) && "gray"} />,
      computer: name,
      "last session": lastSessionStartAt
        ? convertToSelectedTimeZone(
            `${convertDateFormat(lastSessionStartAt.substring(0, 10))} ${lastSessionStartAt.substring(
              lastSessionStartAt.indexOf("T") + 1,
              lastSessionStartAt.indexOf("T") + 6,
            )}`,
            userTimeZone,
          )
        : "-",
      button: (
        <TextButton
          text="Choose as Template"
          color="purple"
          onClick={() => {
            createSilverImageMutation.mutate({ seat_id: seat.id });
          }}
        />
      ),
    };
  });

  return (
    <div className="image-from-computer-container">
      <ExpandableTable
        columns={columns}
        data={data}
        hasNextPage={hasNextPage}
        onPageEnd={() => {
          fetchNextPage();
        }}
        isFetchingNextPage={isFetchingNextPage}
        hoverColumnName={isMobile ? "NULL" : "button"}
        isLoading={isLoading}
      />
    </div>
  );
};

export default TemplateFromComputer;
