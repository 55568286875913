import React from "react";
import VrHeadsetImage from "Assets/images/vr-headset.png";
import { Button, Modal } from "UI";

import "./VrDeviceDetectedModal.styles.scss";

const VrDeviceDetectedModal = (props) => {
  const { setShowVrDeviceDetectedModal, onActivateVr, translate } = props;

  return (
    <Modal
      className="vr-device-detected-modal"
      topRightIconAction={() => setShowVrDeviceDetectedModal(false)}
      topRightIcon="close"
    >
      <img src={VrHeadsetImage} alt="Nothing to show" />
      <h1>{translate("modals.vrDetected.header")}</h1>
      <p>{translate("modals.vrDetected.description")}</p>
      <Button purpleLight onClick={onActivateVr} text={translate("modals.vrDetected.action")} />
    </Modal>
  );
};

export default VrDeviceDetectedModal;
