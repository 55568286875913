import * as LoginConstants from "Constants/Authentication/Login.constants";
import * as RegisterConstants from "Constants/Authentication/Register.constants";
import * as OrganizationConstants from "Constants/Organization.constants";
import * as AppStreamingConstants from "Constants/AppStreaming.constants";
import { REQUEST_STATUS } from "Constants/global.constants";

const initialState = {
  status: REQUEST_STATUS.NOT_DEFINED,
  error: false,
  client_code: null,
};

export default function registerCTX(state = initialState, action) {
  switch (action.type) {
    case RegisterConstants.REGISTER_CLEAR: {
      return initialState;
    }
    case RegisterConstants.REGISTER_API_PENDING: {
      return registerAPIPending(state);
    }
    case RegisterConstants.REGISTER_API_SUCCESS: {
      return registerAPISuccess(state, action);
    }
    case RegisterConstants.REGISTER_API_FAILURE: {
      return registerAPIFailure(state, action);
    }
    case AppStreamingConstants.APP_STREAMING_REGISTER_API_PENDING: {
      return registerAPIPending(state);
    }
    case AppStreamingConstants.APP_STREAMING_REGISTER_API_SUCCESS: {
      return registerAPISuccess(state);
    }
    case AppStreamingConstants.APP_STREAMING_REGISTER_API_FAILURE: {
      return registerAPIFailure(state, action);
    }
    case OrganizationConstants.ORGANIZATION_REGISTER_API_PENDING: {
      return registerAPIPending(state);
    }
    case OrganizationConstants.ORGANIZATION_REGISTER_API_SUCCESS: {
      return registerAPISuccess(state);
    }
    case OrganizationConstants.ORGANIZATION_REGISTER_API_FAILURE: {
      return registerAPIFailure(state, action);
    }
    case LoginConstants.LOGOUT_API_PENDING:
      return initialState;
    default: {
      return state;
    }
  }
}

function registerAPIPending(state) {
  return {
    ...state,
    status: REQUEST_STATUS.PENDING,
    error: false,
    client_code: null,
  };
}

function registerAPISuccess(state, action) {
  return {
    ...state,
    status: REQUEST_STATUS.SUCCESS,
    client_code: null,
    data: action?.payload,
    error: false,
  };
}

function registerAPIFailure(state, action) {
  return {
    ...state,
    status: REQUEST_STATUS.FAILURE,
    error: true,
    client_code: action.payload.client_code,
  };
}
