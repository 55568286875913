import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { forgetPasswordResetAPI, forgetPasswordResetClear } from "Actions/Authentication/ForgetPassword.actions";
import ForgetPasswordResetComponent from "Components/Authentication/ForgetPassword/ForgetPasswordReset.component";

class ForgetPasswordResetContainer extends React.Component {
  componentDidMount() {
    const { forgetPasswordResetClear } = this.props;
    forgetPasswordResetClear();
  }

  render() {
    return <ForgetPasswordResetComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({ forgetPasswordResetCTX: state.forgetPasswordResetCTX });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ forgetPasswordResetAPI, forgetPasswordResetClear }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordResetContainer);
