import { all, put, takeLatest } from "redux-saga/effects";

import apiGenerator from "Utils/Helpers/api.helpers";
import { Tracker } from "Utils";

import { getStatusCodeFamily, apiErrorHandler, getAPIEndpoint, isVendorAccount } from "Utils/Helpers/saga.helpers";

import * as AccountConstants from "Constants/Account.constants";
import * as OrganizationConstants from "Constants/Organization.constants";
import * as PaymentConstants from "Constants/Dashboard/Payment.constants";
import * as AppStreamingConstants from "Constants/AppStreaming.constants";

import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";

function* getPaymentMethodsAPISaga() {
  const api = apiGenerator("get")(getAPIEndpoint(API_ENDPOINTS.GET_USER_PAYMENT_METHODS));
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PaymentConstants.GET_PAYMENT_METHODS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: PaymentConstants.GET_PAYMENT_METHODS_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* setPreferredPaymentMethodSaga(action) {
  const { methodID } = action.payload;
  const api = apiGenerator("post")(getAPIEndpoint(API_ENDPOINTS.SET_PREFERRED_PAYMENT_METHOD(methodID)));

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PaymentConstants.SET_PREFERRED_PAYMENT_METHOD_SUCCESS,
        payload: methodID,
      });
      yield put({
        type: PaymentConstants.GET_PAYMENT_METHODS_PENDING,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: PaymentConstants.SET_PREFERRED_PAYMENT_METHOD_FAILURE,
      payload: error,
    });
  }
}

function* getPaymentClientSecretSaga() {
  const api = apiGenerator("get")(getAPIEndpoint(API_ENDPOINTS.GET_PAYMENT_CLIENT_SECRET));

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PaymentConstants.GET_PAYMENT_CLIENT_SECRET_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: PaymentConstants.GET_PAYMENT_CLIENT_SECRET_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* createUserPaymentMethodSaga(action) {
  const { seti } = action.payload;
  const api = apiGenerator("post")(getAPIEndpoint(API_ENDPOINTS.CREATE_USER_PAYMENT_METHOD), { intent_id: seti });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PaymentConstants.CREATE_USER_PAYMENT_METHOD_SUCCESS,
      });
      // Re-request payment methods when a payment method is added.

      yield put({
        type: PaymentConstants.GET_PAYMENT_METHODS_PENDING,
      });
    } else {
      const error = apiErrorHandler({ response });

      yield put({
        type: PaymentConstants.CREATE_USER_PAYMENT_METHOD_FAILURE,
        payload: error,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: PaymentConstants.CREATE_USER_PAYMENT_METHOD_FAILURE,
      payload: error,
    });
  }
}

function* deleteUserPaymentMethodSaga(action) {
  const { methodID } = action.payload;
  const api = apiGenerator("delete")(getAPIEndpoint(API_ENDPOINTS.DELETE_USER_PAYMENT_METHOD(methodID)));

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PaymentConstants.DELETE_USER_PAYMENT_METHOD_SUCCESS,
      });
      yield put({
        type: PaymentConstants.GET_PAYMENT_METHODS_PENDING,
      });
      yield put({
        type: AppStreamingConstants.GET_VENDOR_ACCOUNT_API_PENDING,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: PaymentConstants.DELETE_USER_PAYMENT_METHOD_FAILURE,
      payload: error,
    });
  }
}

function* approvePaymentPermissionSaga() {
  const api = apiGenerator("post")(API_ENDPOINTS.APPROVE_USER_PAYMENT_METHOD);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PaymentConstants.APPROVE_USER_PAYMENT_METHOD_SUCCESS,
      });
      yield put({
        type: PaymentConstants.GET_PAYMENT_METHODS_PENDING,
      });
    } else {
      const { client_code: clientCode } = response.data;
      yield put({
        type: PaymentConstants.APPROVE_USER_PAYMENT_METHOD_FAILURE,
        payload: { clientCode },
      });
    }
  } catch (err) {
    yield put({
      type: PaymentConstants.APPROVE_USER_PAYMENT_METHOD_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* denyPaymentPermissionSaga() {
  const api = apiGenerator("post")(API_ENDPOINTS.DENY_USER_PAYMENT_METHOD);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PaymentConstants.DENY_USER_PAYMENT_METHOD_SUCCESS,
      });
      yield put({
        type: PaymentConstants.GET_PAYMENT_METHODS_PENDING,
      });
    } else {
      const { client_code: clientCode } = response.data;
      yield put({
        type: PaymentConstants.DENY_USER_PAYMENT_METHOD_SUCCESS,
        payload: { clientCode },
      });
    }
  } catch (err) {
    yield put({
      type: PaymentConstants.DENY_USER_PAYMENT_METHOD_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* startPaymentSaga() {
  const api = apiGenerator("post")(API_ENDPOINTS.START_PAYMENT);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      if (response.data.payment_required) {
        yield put({
          type: PaymentConstants.PAYMENT_3DSECURE_REQUESTED,
          payload: {
            data: response.data,
            completeEndpoint: API_ENDPOINTS.COMPLETE_PAYMENT,
            successType: PaymentConstants.START_PAYMENT_SUCCESS,
            successMessage: PaymentConstants.START_PAYMENT_SUCCESS_MESSAGE,
            failureType: PaymentConstants.START_PAYMENT_FAILURE,
          },
        });
      } else {
        yield put({
          type: PaymentConstants.START_PAYMENT_SUCCESS,
        });
      }
    }
  } catch (err) {
    yield put({
      type: PaymentConstants.START_PAYMENT_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* payment3DSecureSaga(action) {
  const { paymentIntentId, errorStatus, completeEndpoint, successType, failureType } = action.payload;
  const api = apiGenerator("post")(completeEndpoint, {
    payment_intent_id: paymentIntentId,
  });

  try {
    const response = yield api;
    if (!errorStatus && getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PaymentConstants.PAYMENT_3DSECURE_SUCCESS,
      });

      yield put({
        type: successType,
      });

      // When a payment is made, re-request account to ensure the account is up to date if any subscription related debts are paid.
      yield put({
        type: AccountConstants.GET_ACCOUNT_API_PENDING,
      });
      yield put({
        type: OrganizationConstants.GET_ORGANIZATION_API_PENDING,
      });
      if (isVendorAccount()) {
        yield put({
          type: AppStreamingConstants.GET_VENDOR_ACCOUNT_API_PENDING,
        });
      }
    } else {
      yield put({
        type: PaymentConstants.PAYMENT_3DSECURE_FAILURE,
      });
      yield put({
        type: failureType,
        payload: { error: "3D Secure Payment Response Failure" },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: PaymentConstants.PAYMENT_3DSECURE_FAILURE,
      payload: error,
    });
    yield put({
      type: failureType,
      payload: {
        ...error,
        error: "3D Secure Payment Request Failure",
      },
    });
  }
}

function* addBalanceSaga(action) {
  const { amount, isTeamBalance } = action.payload;

  const requestBody = {};
  if (isTeamBalance) {
    requestBody.credit = amount;
  } else {
    requestBody.amount = amount;
  }

  const api = apiGenerator("post")(
    isTeamBalance ? API_ENDPOINTS.TEAM_ADD_BALANCE : getAPIEndpoint(API_ENDPOINTS.ADD_BALANCE),
    requestBody,
  );

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      if (response.data.payment_required) {
        yield put({
          type: PaymentConstants.PAYMENT_3DSECURE_REQUESTED,
          payload: {
            data: response.data,
            completeEndpoint: isTeamBalance
              ? API_ENDPOINTS.TEAM_COMPLETE_ADD_BALANCE
              : getAPIEndpoint(API_ENDPOINTS.COMPLETE_ADD_BALANCE),
            successType: PaymentConstants.ADD_BALANCE_SUCCESS,
            // successMessage: PaymentConstants.CREATE_USER_WORKSTATION_AS_SUBSCRIPTION_SUCCESS_MESSAGE,
            failureType: PaymentConstants.ADD_BALANCE_FAILURE,
          },
        });
      } else {
        yield put({
          type: PaymentConstants.ADD_BALANCE_SUCCESS,
        });
      }
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: PaymentConstants.ADD_BALANCE_FAILURE,
      payload: error,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(PaymentConstants.GET_PAYMENT_METHODS_PENDING, getPaymentMethodsAPISaga),
    takeLatest(PaymentConstants.SET_PREFERRED_PAYMENT_METHOD_PENDING, setPreferredPaymentMethodSaga),
    takeLatest(PaymentConstants.GET_PAYMENT_CLIENT_SECRET_PENDING, getPaymentClientSecretSaga),
    takeLatest(PaymentConstants.CREATE_USER_PAYMENT_METHOD_PENDING, createUserPaymentMethodSaga),
    takeLatest(PaymentConstants.DELETE_USER_PAYMENT_METHOD_PENDING, deleteUserPaymentMethodSaga),
    takeLatest(PaymentConstants.APPROVE_USER_PAYMENT_METHOD_PENDING, approvePaymentPermissionSaga),
    takeLatest(PaymentConstants.DENY_USER_PAYMENT_METHOD_PENDING, denyPaymentPermissionSaga),
    takeLatest(PaymentConstants.START_PAYMENT_PENDING, startPaymentSaga),
    takeLatest(PaymentConstants.PAYMENT_3DSECURE_PENDING, payment3DSecureSaga),
    takeLatest(PaymentConstants.ADD_BALANCE_PENDING, addBalanceSaga),
  ]);
}
