import { useMutation, useQuery } from "@tanstack/react-query";
import {
  downloadFileAPISuccess,
  setShowFileProgressBar,
  clearWorkstationUploadedFile,
} from "Actions/Dashboard/Files.actions";

import {
  ToastNotificationContainer,
  ToastNotificationContent,
} from "Components/Workstation/ToastNotification/ToastNotification";
import WorkstationCanvas from "Components/Workstation/WorkstationCanvas/WorkstationCanvas.component";
import WorkstationDock from "Components/Workstation/WorkstationDock/WorkstationDock.component";
import KeyboardShortcuts from "Components/Workstation/WorkstationDock/KeyboardShortcuts/KeyboardShortcuts.component";
import ResizeButton from "Components/Workstation/ResizeButton/ResizeButton.component";
import { API_ENDPOINTS } from "Constants/api.constants";
import {
  AUTO_STOP_OPTIONS,
  DISK_LOCATIONS,
  LOCAL_STORAGE,
  STREAM_TYPE,
  WORKSTATION_FRIENDLY_STATUS,
  WORKSTATION_STATUS,
} from "Constants/global.constants";
import React, { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import routes from "Constants/Route.constants";
import { apiGenerator, getItemFromLocalStorage, isMobile, Logger, saveItemToLocalStorage, Tracker } from "Utils";
import { removeItemFromLocalStorage } from "Utils/Helpers/storage.helpers";
import { applyAdvancedStreamingSettings, applyScrollDirectionPreference } from "Utils/Helpers/streaming.helpers";
import WebsocketConnection from "Utils/Workstation/WebsocketConnection";
import FilesContainer from "Containers/Organization/Shared/Files.container";
import {
  BalanceExceedModal,
  FileDownloadModal,
  StreamPreferencesModal,
  SyncClipboardModal,
} from "Components/Workstation/Modals";
import WorkstationCursor from "Components/Workstation/WorkstationCursor/WorkstationCursor.component";
import { ConfirmationModal } from "UI";
import IframeModal from "Components/Workstation/Modals/IframeModal/IframeModal.component";
import FirstConnectionOnboardingModal from "Components/Workstation/Modals/FirstConnectionOnboardingModal.component";
import GameModeOnModal from "Components/Workstation/Modals/GameModeOnModal.component";
import KeyboardButton from "Components/Workstation/WorkstationDock/KeyboardButton/KeyboardButton.component";
import useOrientationChange from "Utils/Hooks/useOrientationChange";
import { Translate } from "react-localize-redux";

const PublicOrganizationComputerContainer = ({ history, translate, match }) => {
  const [connected, setConnected] = useState(false);
  const [loaderContent, setLoaderContent] = useState("Connecting...");
  const [isDockOpen, setIsDockOpen] = useState(false);
  const [isMicEnabled, setIsMicEnabled] = useState(true);
  const [showFileManager, setShowFileManager] = useState(false);
  const [terminateFileManager, setTerminateFileManager] = useState(true);
  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);
  const [showStreamPreferences, setShowStreamPreferences] = useState(false);
  const [showClipboardModal, setShowClipboardModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showBalanceExceedModal, setShowBalanceExceedModal] = useState(false);
  const [showGameModeOnModal, setShowGameModeOnModal] = useState(false);
  const [showFirstConnectionOnboardingModal, setShowFirstConnectionOnboardingModal] = useState(false);
  const [showDockOnboardingModal, setShowDockOnboardingModal] = useState(false);
  const [customResolution, setCustomResolution] = useState(getItemFromLocalStorage(LOCAL_STORAGE.customResolution));
  const [gameModeEnabled, setGameModeEnabled] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);
  const [cursorImage, setCursorImage] = useState("");
  const [showWebRTCConnectionFailureInformationModal, setShowWebRTCConnectionFailureInformationModal] = useState(false);
  const websocketConnectionRef = useRef(null);
  const duplicateTabChannelRef = useRef(null);
  const closeFileManagerTimeoutRef = useRef(null);

  const dispatch = useDispatch();
  const { files } = useSelector((state) => state);
  const machineUid = match.params.uid;
  const isOrganizationAdminConnectingMember = false;

  const {
    data: machineData,
    isSuccess: fetchMachineSuccess,
    refetch: refetchMachine,
  } = useQuery({
    queryKey: ["organizationComputerContainer", API_ENDPOINTS.ORGANIZATION_PUBLIC_MACHINES(machineUid)],
    queryFn: () => apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_PUBLIC_MACHINES(machineUid)),
    enabled: !!machineUid,
    refetchInterval: 60000,
    retry: false,
    onError: (error) => {
      const { client_code: clientCode } = error?.response?.data;
      history.push({
        pathname: routes.organizationPublicComputerExpired,
        error: clientCode || "4702",
      });
    },
  });

  const updateSeatMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATIONS_SEAT(machine?.attributes?.seat?.id), data);
    },
  });

  const machine = machineData?.data;
  const {
    current_session: currentSession,
    status,
    friendly_status: friendlyStatus,
    password,
    seat,
  } = machine?.attributes || {};

  const {
    auto_stop_threshold: autoStopThreshold,
    analytics_collection_enabled: analyticsCollectionEnabled,
    clipboard_enabled: clipboardEnabled,
  } = seat?.attributes || {};

  const blockClipboardEvents = !clipboardEnabled;

  useEffect(() => {
    if (fetchMachineSuccess && status !== WORKSTATION_STATUS.RUNNING) {
      history.push(routes.organizationPublicComputerExpired);
    }
  }, [status]);

  useEffect(() => {
    dispatch(setShowFileProgressBar(false));

    if (!duplicateTabChannelRef.current) {
      duplicateTabChannelRef.current = new BroadcastChannel("duplicate-tab-channel");
      duplicateTabChannelRef.current.postMessage("Workstation open at another tab.");
      duplicateTabChannelRef.current.onmessage = () => {
        onDuplicateTab();
      };
    }

    document.addEventListener("visibilitychange", onVisibilityStateChange, true);

    return () => {
      dispatch(setShowFileProgressBar(true));
      Logger.log("Workstation|ComponentWillUnmount");
      if (websocketConnectionRef.current) {
        websocketConnectionRef.current.closeSockets();
        websocketConnectionRef.current.stopStreaming();
      }

      document.removeEventListener("visibilitychange", onVisibilityStateChange, true);

      duplicateTabChannelRef.current.close();
    };
  }, []);

  const handleResize = () => {
    if (customResolution?.localResolution) {
      resizeWindow();
    }
  };

  useOrientationChange(handleResize);

  useEffect(() => {
    document.addEventListener("mozfullscreenchange", handleResize);

    document.addEventListener("webkitfullscreenchange", handleResize);

    return () => {
      document.removeEventListener("mozfullscreenchange", handleResize);
      document.removeEventListener("webkitfullscreenchange", handleResize);
    };
  }, [JSON.stringify(customResolution)]);

  useEffect(() => {
    if (fetchMachineSuccess && friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY) {
      applyStreamingPreferences();
      initializeSocket();
    }
  }, [fetchMachineSuccess, friendlyStatus]);

  useEffect(() => {
    if (Object.keys(files.workstationUploadFilesCTX.uploadedFiles) !== 0 && connected) {
      const { uploadedFiles } = files.workstationUploadFilesCTX;
      Object.keys(uploadedFiles).forEach((fileName) => {
        websocketConnectionRef.current.sendDownloadFileEvent(uploadedFiles[fileName].downloadUrl, fileName);
        dispatch(clearWorkstationUploadedFile(fileName));
      });
    }
  }, [JSON.stringify(files.workstationUploadFilesCTX.uploadedFiles)]);

  const applyStreamingPreferences = (options) => {
    let workstationType = "GPU";
    const currentSessionAttributes = currentSession.attributes;

    if (currentSessionAttributes) {
      if (currentSessionAttributes.machine_type.attributes.gpu === 0) {
        workstationType = "CPU";
      }
    }

    applyAdvancedStreamingSettings(workstationType, options);
  };

  const controlWorkstationStatus = () => {
    refetchMachine();
  };

  const showToastNotification = (props) => {
    const { header, description } = props;
    toast.dismiss();
    toast(<ToastNotificationContent header={header} description={description} />);
  };

  const setConnectingState = (content = "Connecting...") => {
    setLoaderContent(content);
  };

  const onConnection = () => {
    setConnected(true);

    if (machine) {
      const workstationLastDiskLocation = machine.attributes.last_started_from;
      if (workstationLastDiskLocation === DISK_LOCATIONS.ebs) {
        websocketConnectionRef.current.sendStopWarmUpEvent();
      }
    }
  };

  const onVisibilityStateChange = () => {
    if (!websocketConnectionRef.current) {
      return;
    }

    if (document.visibilityState === "visible" && websocketConnectionRef.current.isFirstFrameMuxingCompleted) {
      websocketConnectionRef.current.requestKeyFrame();
    }
  };

  const onDownload = (file) => {
    if (file) {
      dispatch(downloadFileAPISuccess(file));
      setShowDownloadModal(true);
    }
  };

  const onDuplicateTab = () => {
    history.push({
      pathname: routes.workstationDuplicate,
      state: { overrideDashboardRoute: dashboardRoute, overrideRetryRoute: match.url },
    });
  };

  const changeCursorPosition = (x, y) => {
    setCursorX(x);
    setCursorY(y);
  };

  const ctrlAltDel = () => {
    websocketConnectionRef.current.sendCtrlAltDeleteEvent();
  };

  const resizeWindow = () => {
    Tracker.time({ type: "connect", start: true });
    setLoaderContent("Setting Screen Resolution...");
    setConnected(false);
  };

  useEffect(() => {
    if (!connected && loaderContent === "Setting Screen Resolution...") {
      initializeSocket();
    }
  }, [loaderContent, connected]);

  const setDisplayResolution = (resolution) => {
    if (resolution) {
      saveItemToLocalStorage(LOCAL_STORAGE.customResolution, resolution, true);
      websocketConnectionRef.current.setDisplayResolution(resolution);
      setCustomResolution(resolution);
    } else {
      setCustomResolution(null);
      removeItemFromLocalStorage(LOCAL_STORAGE.customResolution);
      websocketConnectionRef.current.setDisplayResolution(null);
    }

    resizeWindow();
  };

  const onInitConnection = () => {
    setConnectingState();
    setConnected(false);
  };

  const showScreenKeyboard = () => {
    websocketConnectionRef.current.sendShowScreenKeyboardEvent();
  };

  const pasteToRemote = () => {
    websocketConnectionRef.current.pasteToRemote();
  };

  const copyFromRemote = () => {
    websocketConnectionRef.current.copyFromRemote();
  };

  const enterFullScreen = () => {
    const docElement = document.documentElement;
    // Toggle fullscreen if not already in fullscreen
    if (docElement.requestFullScreen) {
      docElement.requestFullScreen();
    } else if (docElement.mozRequestFullScreen) {
      docElement.mozRequestFullScreen();
    } else if (docElement.webkitRequestFullScreen) {
      docElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (docElement.msRequestFullscreen) {
      docElement.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  };

  const toggleFullscreen = () => {
    if (
      document.fullscreenElement /* Standard syntax */ ||
      document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */ ||
      document.mozFullScreenElement /* Firefox syntax */ ||
      document.msFullscreenElement /* IE/Edge syntax */
    ) {
      exitFullScreen();
    } else {
      enterFullScreen();
    }
  };

  const enterPassword = () => {
    websocketConnectionRef.current.sendEnterPasswordEvent();
  };

  const initializeSocket = () => {
    const autoStopEnabled = autoStopThreshold > 0;

    if (websocketConnectionRef.current && websocketConnectionRef.current.websocket) {
      // Closing socket will restart it automatically.
      Logger.log("Close socket");
      websocketConnectionRef.current.closeSockets();
      websocketConnectionRef.current.restartConnection();
    } else {
      websocketConnectionRef.current = new WebsocketConnection({
        password,
        controlWorkstationStatus,
        showNotification: showToastNotification,
        autoStopEnabled,
        autoStopThreshold,
        setConnectingState,
        onConnection,
        onInitConnection,
        onDownload,
        customResolution,
        setIframeSrc,
        onDuplicateTab,
        streamType: STREAM_TYPE.public_organization,
        machineDetails: machine,
        changeCursorPosition,
        setCursorImage,
        onWebRTCConnectionFailure: () => {
          history.push({
            pathname: routes.workstationConnectionFailed,
            state: { overrideDashboardRoute: dashboardRoute, overrideRetryRoute: match.url },
          });
        },
        blockClipboardEvents,
        translate,
        publicAccessId: machineUid,
      });
    }
  };

  const workstationType = () => {
    let workstationType = "GPU";
    const currentSessionAttributes = currentSession?.attributes;

    if (currentSessionAttributes) {
      if (currentSessionAttributes?.machine_type?.attributes?.gpu === 0) {
        workstationType = "CPU";
      }
    }
    return workstationType;
  };

  const setDisableKeyboardActions = (disableKeyboardActions) => {
    if (websocketConnectionRef.current) {
      websocketConnectionRef.current.setDisableKeyboardActions(disableKeyboardActions);
    }
  };

  const dashboardRoute = routes.home;

  const onReturnDashboardClick = () => {
    history.push(dashboardRoute);
  };

  const changeSoundState = (state) => {
    saveItemToLocalStorage(LOCAL_STORAGE.soundEnabled, state);
    websocketConnectionRef.current.audioStateChanged();
  };

  const changeVerticalScrollDirection = (inverseDirection) => {
    applyScrollDirectionPreference(inverseDirection);
    websocketConnectionRef.current.changeScrollPreference();
  };

  const changeSwitchCmdPreference = (switchCmdPreference) => {
    websocketConnectionRef.current.changeCmdPreference(switchCmdPreference);
  };

  const toggleMic = () => {
    websocketConnectionRef.current.changeMicrophoneState(!isMicEnabled);
    setIsMicEnabled((prevState) => !prevState);
  };

  const onPointerLockChanged = () => {
    document.removeEventListener("pointerlockchange", onPointerLockChanged, false);
    showToastNotification({
      header: translate("gameMode.disabled.header"),
      description: translate("gameMode.disabled.description"),
    });
    changeGameMode();
  };

  const changeGameMode = () => {
    websocketConnectionRef.current.changeGameMode(!gameModeEnabled);
    setGameModeEnabled((prevState) => !prevState);
    if (!gameModeEnabled) {
      setTimeout(() => {
        showToastNotification({
          header: translate("gameMode.enabled.header"),
          description: translate("gameMode.enabled.description"),
        });
      }, 10);
      setTimeout(() => {
        document.addEventListener("pointerlockchange", onPointerLockChanged, false);
      }, 100);
    }
  };

  const firefoxSafariOnPaste = (event) => {
    event.preventDefault();
    websocketConnectionRef.current.pasteTextToRemote(event.clipboardData.getData("text"));
    setShowClipboardModal(false);
  };

  const firefoxSafariOnCopy = (event) => {
    event.preventDefault();

    if (blockClipboardEvents) {
      showToastNotification({ description: translate("organizationComputer.clipboardDisabledMessage") });
      return;
    }

    websocketConnectionRef.current.pasteTextToRemote(
      event.clipboardData.setData("text/plain", sessionStorage.getItem("clipboardData")),
    );
    setShowClipboardModal(false);
  };

  const submitStreamPreference = () => {
    applyStreamingPreferences();
    setShowStreamPreferences(false);
    setLoaderContent("Applying Stream Preferences...");
    setIsDockOpen(false);
    websocketConnectionRef.current.closeSockets();
    websocketConnectionRef.current.restartConnection();
  };

  const handleCloseFirstConnectionOnboardingModal = () => {
    setShowFirstConnectionOnboardingModal(false);
    localStorage.setItem(LOCAL_STORAGE.firstConnectionOnboardingModalShown, true);
    setIsDockOpen(true);
    setTimeout(() => {
      setShowDockOnboardingModal(true);
    }, 1000);
  };

  const handleAutoTurnOffChanged = (autoTurnOff) => {
    updateSeatMutation.mutate({
      auto_stop_threshold: AUTO_STOP_OPTIONS[autoTurnOff],
    });
  };

  const handleSetShowFileManager = (state) => {
    if (state !== showFileManager) {
      if (state) {
        clearTimeout(closeFileManagerTimeoutRef.current);
      } else {
        closeFileManagerTimeoutRef.current = setTimeout(() => {
          setTerminateFileManager(true);
        }, 800);
      }
      setShowFileProgressBar(state);

      setDisableKeyboardActions(state);

      setShowFileManager(state);
      setTerminateFileManager(false);
    }
  };

  useEffect(() => {
    if (websocketConnectionRef.current?.setBlockClipboardEvents) {
      websocketConnectionRef.current.setBlockClipboardEvents(blockClipboardEvents);
    }
  }, [blockClipboardEvents, websocketConnectionRef.current?.setBlockClipboardEvents]);

  const machineHasMultiGpu = machine?.machine_type?.attributes?.gpu > 1;

  useHotkeys("ctrl+shift+l+s", () => {
    websocketConnectionRef.current.testHelper.prepareLatencyTest();
  });

  return (
    <div className="workstation-container">
      <WorkstationCanvas
        isConnected={connected}
        loaderContent={
          friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY || !friendlyStatus
            ? loaderContent
            : translate(`statusBadge.status.${friendlyStatus}`)
        }
        overrideLoadingText={
          friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY || !friendlyStatus ? null : (
            <Translate
              id={`publicOrganizationComputer.loadingTexts.${friendlyStatus}`}
              onMissingTranslation={() => null}
            />
          )
        }
        machineHasMultiGpu={machineHasMultiGpu}
        translate={translate}
        showConnectionTransition
        analyticsCollectionEnabled={analyticsCollectionEnabled}
      />
      <WorkstationDock
        isDockOpen={isDockOpen}
        isMicEnabled={isMicEnabled}
        workstationType={workstationType()}
        setIsDockOpen={setIsDockOpen}
        connected={connected}
        uploadFiles={files.uploadFilesCTX.uploadFiles}
        setShowKeyboardShortcuts={setShowKeyboardShortcuts}
        ctrlAltDel={ctrlAltDel}
        displayResolution={customResolution}
        setDisplayResolution={setDisplayResolution}
        resizeWindow={resizeWindow}
        showScreenKeyboard={showScreenKeyboard}
        pasteToRemote={pasteToRemote}
        copyFromRemote={copyFromRemote}
        toggleFullscreen={toggleFullscreen}
        enterPassword={enterPassword}
        machine={machine}
        showFileManager={showFileManager}
        setShowGameModeOnModal={setShowGameModeOnModal}
        setShowDownloadModal={setShowDownloadModal}
        setShowFileManager={handleSetShowFileManager}
        showDockOnboardingModal={showDockOnboardingModal}
        setShowDockOnboardingModal={setShowDockOnboardingModal}
        setDisableKeyboardActions={setDisableKeyboardActions}
        setShowStreamPreferences={setShowStreamPreferences}
        setShowClipboardModal={setShowClipboardModal}
        getWorkstationCurrentSessionCTX={machine}
        changeSoundState={changeSoundState}
        changeVerticalScrollDirection={changeVerticalScrollDirection}
        changeSwitchCmdPreference={changeSwitchCmdPreference}
        gameModeEnabled={gameModeEnabled}
        changeGameMode={changeGameMode}
        toggleMic={toggleMic}
        submitStreamPreference={submitStreamPreference}
        isDockReady={machine}
        currentSessionAttributes={currentSession?.attributes}
        handleAutoTurnOffChanged={handleAutoTurnOffChanged}
        onReturnDashboardClick={onReturnDashboardClick}
        disableFilesTab={isOrganizationAdminConnectingMember}
        disableVrTab
        blockClipboardEvents={blockClipboardEvents}
        history={history}
        translate={translate}
        publicOrganizationComputer
      />
      {showKeyboardShortcuts && (
        <KeyboardShortcuts
          websocketConnection={websocketConnectionRef.current}
          setShowKeyboardShortcuts={setShowKeyboardShortcuts}
        />
      )}
      {connected && isMobile && <KeyboardButton setShowKeyboardShortcuts={setShowKeyboardShortcuts} />}
      <ToastNotificationContainer />
      {showFileManager && !terminateFileManager && (
        <div className={`workstation-file-manager-container ${showFileManager ? "open" : ""}`}>
          <FilesContainer translate={translate} />
        </div>
      )}
      {showStreamPreferences && (
        <StreamPreferencesModal
          setShowStreamPreferences={setShowStreamPreferences}
          submitStreamPreference={() => {
            localStorage.setItem(LOCAL_STORAGE.webRTCApproval, true);
          }}
          translate={translate}
        />
      )}
      {connected && showClipboardModal && (
        <SyncClipboardModal
          setShowClipboardModal={setShowClipboardModal}
          clipboardModalCopyMode
          firefoxSafariOnPaste={firefoxSafariOnPaste}
          firefoxSafariOnCopy={firefoxSafariOnCopy}
          translate={translate}
        />
      )}
      {connected && customResolution?.localResolution && <ResizeButton resizeWindow={resizeWindow} />}
      {connected && showDownloadModal && (
        <FileDownloadModal
          fileName={files.downloadFileCTX.name}
          fileSize={files.downloadFileCTX.size}
          downloadUrl={files.downloadFileCTX.url}
          setShowDownloadModal={setShowDownloadModal}
          translate={translate}
        />
      )}
      <WorkstationCursor visible={gameModeEnabled} x={cursorX} y={cursorY} cursorImage={cursorImage} />
      {connected && showBalanceExceedModal && (
        <BalanceExceedModal
          setShowBalanceExceedModal={setShowBalanceExceedModal}
          notificationId={machine?.notifications?.[0]?.id}
          translate={translate}
        />
      )}
      {connected && showGameModeOnModal && (
        <GameModeOnModal
          setShowGameModeOnModal={setShowGameModeOnModal}
          changeGameMode={changeGameMode}
          translate={translate}
        />
      )}
      {connected && showFirstConnectionOnboardingModal && (
        <FirstConnectionOnboardingModal translate={translate} onExit={handleCloseFirstConnectionOnboardingModal} />
      )}
      {iframeSrc && <IframeModal setShowIframeModal={setIframeSrc} src={iframeSrc} />}
      {showWebRTCConnectionFailureInformationModal && (
        <ConfirmationModal
          headerText={translate("modals.WebRTCConnectionFailure.header")}
          descriptionText={translate("modals.WebRTCConnectionFailure.description")}
          confirmText={translate("modals.WebRTCConnectionFailure.confirm")}
          confirmAction={() => {
            setShowWebRTCConnectionFailureInformationModal(false);
          }}
          closeAction={() => {
            setShowWebRTCConnectionFailureInformationModal(false);
          }}
        />
      )}
    </div>
  );
};

export default PublicOrganizationComputerContainer;
