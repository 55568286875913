import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tunnel: process.env.REACT_APP_SENTRY_TUNNEL,
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["*.vagon.io", "vagon.io"],
    initialScope: {
      level: "warning",
    },
  });
}

export default Sentry;
