import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Dropdown, IconButton, Input, TextButton } from "UI";
import { streamTextsOrder } from "Constants/AppStreaming.constants";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { isMobile, useAPI } from "Utils";

import "./StreamTexts.styles.scss";

const StreamTexts = (props) => {
  const { id, translate } = props;

  const [texts, setTexts] = useState({});
  const [selectedTextKey, setSelectedTextKey] = useState(null);

  const {
    api: getTextsAPI,
    data: textsData,
    isLoading: getTextsLoading,
  } = useAPI({
    type: "get",
    endpoint: APP_STREAMING_API_ENDPOINTS.STREAM_TEXTS(id),
    onSuccess: (res) => {
      setTexts({ ...res.texts });
    },
  });

  const initialTexts = textsData?.texts;
  const defaultTexts = textsData?.default_texts;

  const orderedDefaultTexts = () => {
    const hash = {};
    if (!defaultTexts) return hash;
    streamTextsOrder.forEach((key) => {
      if (defaultTexts[key]) {
        hash[key] = defaultTexts[key];
      }
    });
    return hash;
  };

  useEffect(() => {
    if (defaultTexts && !selectedTextKey) {
      setSelectedTextKey(Object.keys(orderedDefaultTexts())[0]);
    }
  }, [defaultTexts]);

  const updateButtonDisabled = _.isEqual(texts?.[selectedTextKey], initialTexts?.[selectedTextKey]);
  const resetButtonDisabled = _.isEqual(defaultTexts?.[selectedTextKey], texts?.[selectedTextKey]);

  const checkTextLength = texts[selectedTextKey]?.length > 4 && texts[selectedTextKey]?.length < 100;

  const { api: updateTextsAPI, isLoading: updateTextsLoading } = useAPI({
    type: "put",
    endpoint: APP_STREAMING_API_ENDPOINTS.STREAM_TEXTS(id),
    onSuccess: () => {
      getTextsAPI();
    },
  });

  useEffect(() => {
    getTextsAPI();
  }, []);

  return (
    <>
      <div className="configuration-option customize-texts">
        <div className="header-content">
          <p className="header">{translate("configureStream.configurations.streamTexts.header")}</p>
        </div>

        <div className="texts-content">
          <Dropdown
            className="text-dropdown"
            underlined
            defaultSelected={selectedTextKey}
            disabled={!updateButtonDisabled}
            options={Object.keys(orderedDefaultTexts())}
            mapFunction={(option) => {
              return translate(`configureStream.configurations.streamTexts.options.${option}`);
            }}
            handleSelectedOptionChange={(e) => {
              setSelectedTextKey(e);
            }}
          />

          <div className="text-input">
            <Input
              value={texts[selectedTextKey]?.replace(/\n/g, " ") || ""}
              onChange={(e) => {
                const newTexts = { ...texts };
                newTexts[selectedTextKey] = e.target.value;
                setTexts(newTexts);
              }}
            />
          </div>

          {!isMobile && (
            <div className="actions">
              <IconButton
                name="reset"
                smaller
                color="grey-3"
                className={resetButtonDisabled && "hide"}
                disabled={resetButtonDisabled}
                onClick={() => {
                  const newTexts = { ...texts };
                  newTexts[selectedTextKey] = defaultTexts[selectedTextKey];
                  setTexts(newTexts);
                  updateTextsAPI({ ...newTexts });
                }}
              />

              <TextButton
                text="Save"
                onClick={() => {
                  updateTextsAPI({ ...texts });
                }}
                className={(updateButtonDisabled || resetButtonDisabled) && "hide"}
                disabled={!checkTextLength || updateButtonDisabled || updateTextsLoading || getTextsLoading}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StreamTexts;
