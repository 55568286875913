import React from "react";
import { Button } from "UI";
import routes from "Constants/Route.constants";
import logo from "Assets/images/logo/vg-logo-black.svg";
import NothingToShow from "Assets/images/vagon-nothing.png";

import "./Error.styles.scss";

const ErrorComponent = () => {
  return (
    <div className="error-component-container">
      <img src={NothingToShow} className="nothing-img" />
      <div className="error-info-container">
        <img src={logo} alt="Vagon" className="logo" />
        <p className="something-went-wrong">Something went wrong</p>
        <p>Looks like something went wrong.</p>
        <p>Our team is notified and working on it.</p>
        <Button
          text="Go to Dashboard"
          className="vg-button"
          onClick={() => {
            window.location.href = routes.home;
          }}
          block
        />
      </div>
    </div>
  );
};

export default ErrorComponent;
