import apiGenerator from "Utils/Helpers/api.helpers";
import { useInfiniteQuery } from "@tanstack/react-query";

const DEFAULT_PER_PAGE = 25;

const useCustomInfiniteQuery = ({ endpoint, params = {}, resource, options }) => {
  const query = useInfiniteQuery({
    queryKey: options?.overrideQueryKey || [endpoint, ...Object.values(params)],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await apiGenerator("get")(endpoint, {
        params: {
          page: pageParam,
          per_page: DEFAULT_PER_PAGE,
          ...params,
        },
      });
      return res.data;
    },
    getNextPageParam: (lastPage) => lastPage.next_page,
    ...options,
  });

  const { data } = query;

  const flattenedData = data?.pages?.flatMap((page) => page[resource]) || [];

  return { ...query, data: flattenedData };
};

export default useCustomInfiniteQuery;
