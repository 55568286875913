import React, { useState } from "react";

import routes from "Constants/Route.constants";
import { LOCAL_STORAGE } from "Constants/global.constants";
import { saveItemToLocalStorage } from "Utils/Helpers/storage.helpers";
import CreateComputerAction from "Components/Dashboard/CreateComputer/Components/CreateComputerAction/CreateComputerAction.component";
import { PurposeList } from "UI";

import "./CreateComputerPurpose.styles.scss";

const CreateComputerPurpose = (props) => {
  const { account, setUserInterestsAPI, history, translate } = props;
  const [selectedPurpose, setSelectedPurpose] = useState(account.account.attributes.interests[0]);

  const onActionButtonClick = () => {
    saveItemToLocalStorage(LOCAL_STORAGE.createComputerConfigs, { purpose: selectedPurpose });

    setUserInterestsAPI(selectedPurpose);
    history.push(routes.createComputerConnection);
  };

  return (
    <div className="purpose-container">
      <PurposeList selectedPurpose={selectedPurpose} setSelectedPurpose={setSelectedPurpose} translate={translate} />
      <CreateComputerAction
        disabled={!selectedPurpose}
        buttonText={translate("purposeOfUse.action.button")}
        onButtonClick={onActionButtonClick}
      />
    </div>
  );
};

export default CreateComputerPurpose;
