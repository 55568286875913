import React from "react";
import NothingToShow from "Assets/images/vagon-nothing.png";
import { Button } from "UI";

import "./NotSupportedDevice.styles.scss";
import { withRouter } from "react-router-dom";
import routes from "Constants/Route.constants";
import translate from "Stories/translate";

const NotSupportedDevice = ({ history }) => {
  return (
    <div className="not-supported-container">
      <div className="not-supported-info">
        <img src={NothingToShow} alt="Nothing to show" />
        <h1>{translate("notSupported.header")}</h1>
        <p>{translate("notSupported.description")}</p>
      </div>
      <Button text="Return to Dashboard" onClick={() => history.push(routes.home)} big />
    </div>
  );
};

export default withRouter(NotSupportedDevice);
