export const UPDATE_CREATE_WORKSTATION_CONFIG = "UPDATE_CREATE_WORKSTATION_CONFIG";

export const GET_WORKSTATIONS_API_PENDING = "GET_WORKSTATIONS_API_PENDING";
export const GET_WORKSTATIONS_API_SUCCESS = "GET_WORKSTATIONS_API_SUCCESS";
export const GET_WORKSTATIONS_API_FAILURE = "GET_WORKSTATIONS_API_FAILURE";

export const GET_NOTIFICATIONS_API_PENDING = "GET_NOTIFICATIONS_API_PENDING";
export const GET_NOTIFICATIONS_API_SUCCESS = "GET_NOTIFICATIONS_API_SUCCESS";
export const GET_NOTIFICATIONS_API_FAILURE = "GET_NOTIFICATIONS_API_FAILURE";

export const GET_WORKSTATION_MACHINE_TYPES_API_PENDING = "GET_WORKSTATION_MACHINE_TYPES_API_PENDING";
export const GET_WORKSTATION_MACHINE_TYPES_API_SUCCESS = "GET_WORKSTATION_MACHINE_TYPES_API_SUCCESS";
export const GET_WORKSTATION_MACHINE_TYPES_API_FAILURE = "GET_WORKSTATION_MACHINE_TYPES_API_FAILURE";

export const UPDATE_WORKSTATION_STATUS_API_PENDING = "UPDATE_WORKSTATION_STATUS_API_PENDING";
export const UPDATE_WORKSTATION_STATUS_API_SUCCESS = "UPDATE_WORKSTATION_STATUS_API_SUCCESS";
export const UPDATE_WORKSTATION_STATUS_API_FAILURE = "UPDATE_WORKSTATION_STATUS_API_FAILURE";
export const UPDATE_WORKSTATION_STATUS_API_RESET = "UPDATE_WORKSTATION_STATUS_API_RESET";

export const UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_PENDING = "UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_PENDING";
export const UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_SUCCESS = "UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_SUCCESS";
export const UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_FAILURE = "UPDATE_WORKSTATION_AUTO_STOP_CONFIGURATION_FAILURE";

export const GET_WORKSTATION_CURRENT_SESSION_PENDING = "GET_WORKSTATION_CURRENT_SESSION_PENDING";
export const GET_WORKSTATION_CURRENT_SESSION_SUCCESS = "GET_WORKSTATION_CURRENT_SESSION_SUCCESS";
export const GET_WORKSTATION_CURRENT_SESSION_FAILURE = "GET_WORKSTATION_CURRENT_SESSION_FAILURE";

export const GET_WORKSTATION_SOFTWARES_PENDING = "GET_WORKSTATION_SOFTWARES_PENDING";
export const GET_WORKSTATION_SOFTWARES_SUCCESS = "GET_WORKSTATION_SOFTWARES_SUCCESS";
export const GET_WORKSTATION_SOFTWARES_FAILURE = "GET_WORKSTATION_SOFTWARES_FAILURE";

export const INSTALL_WORKSTATION_SOFTWARES_PENDING = "INSTALL_WORKSTATION_SOFTWARES_PENDING";
export const INSTALL_WORKSTATION_SOFTWARES_SUCCESS = "INSTALL_WORKSTATION_SOFTWARES_SUCCESS";
export const INSTALL_WORKSTATION_SOFTWARES_FAILURE = "INSTALL_WORKSTATION_SOFTWARES_FAILURE";

export const VALIDATE_WORKSTATION_SOFTWARES_PENDING = "VALIDATE_WORKSTATION_SOFTWARES_PENDING";
export const VALIDATE_WORKSTATION_SOFTWARES_SUCCESS = "VALIDATE_WORKSTATION_SOFTWARES_SUCCESS";
export const VALIDATE_WORKSTATION_SOFTWARES_FAILURE = "VALIDATE_WORKSTATION_SOFTWARES_FAILURE";

export const GET_APP_STREAMING_SESSION_PENDING = "GET_APP_STREAMING_SESSION_PENDING";
export const GET_APP_STREAMING_SESSION_SUCCESS = "GET_APP_STREAMING_SESSION_SUCCESS";
export const GET_APP_STREAMING_SESSION_FAILURE = "GET_APP_STREAMING_SESSION_FAILURE";
export const GET_APP_STREAMING_SESSION_RESET = "GET_APP_STREAMING_SESSION_RESET";
