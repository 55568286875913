import _ from "lodash";
import { REQUEST_STATUS, FILE_STATUS } from "Constants/global.constants";
import * as FilesConstants from "Constants/Dashboard/Files.constants";
import { LOGOUT_API_PENDING } from "Constants/Authentication/Login.constants";
import { calculateStringHash } from "Utils";

const initialState = {
  userFilesCTX: {
    files: {},
    hash: null,
    contents: null,
    currentFolder: null,
    path: [],
    status: REQUEST_STATUS.NOT_DEFINED,
    searchStatus: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  moveFilesCTX: {
    contents: null,
    currentFolder: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  recentFilesCTX: {
    files: {},
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  deleteFileCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  renameFileCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    clientCode: null,
  },
  moveFileCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  createFolderCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    clientCode: null,
  },
  downloadFileCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  uploadFilesCTX: {
    uploadFiles: {},
    showProgressBar: true,
  },
  workstationUploadFilesCTX: {
    uploadedFiles: {},
  },
  appUploadFilesCTX: {
    uploadedFiles: {},
  },
  appVersionFilesCTX: {
    uploadedFiles: {},
  },
  streamSessionFilesCTX: {
    uploadedFiles: {},
  },
};

export default function files(state = initialState, action) {
  switch (action.type) {
    case FilesConstants.GET_USER_FILES_PENDING: {
      return getUserFilesPending(state, action);
    }
    case FilesConstants.GET_USER_FILES_SUCCESS: {
      return getUserFilesSuccess(state, action);
    }
    case FilesConstants.GET_USER_FILES_FAILURE: {
      return getUserFilesFailure(state, action);
    }
    case FilesConstants.GET_MOVE_FILES_PENDING: {
      return getMoveFilesPending(state);
    }
    case FilesConstants.GET_MOVE_FILES_FAILURE: {
      return getMoveFilesFailure(state);
    }
    case FilesConstants.GET_FILE_STORAGE_CAPACITY_SUCCESS: {
      return getFileStorageCapacitySuccess(state, action);
    }
    case FilesConstants.GET_RECENT_FILES_PENDING: {
      return getRecentFilePending(state, action);
    }
    case FilesConstants.GET_RECENT_FILES_SUCCESS: {
      return getRecentFileSuccess(state, action);
    }
    case FilesConstants.GET_RECENT_FILES_FAILURE: {
      return getRecentFileFailure(state);
    }
    case FilesConstants.DELETE_FILE_PENDING: {
      return deleteFilePending(state);
    }
    case FilesConstants.DELETE_FILE_SUCCESS: {
      return deleteFileSuccess(state);
    }
    case FilesConstants.DELETE_FILE_FAILURE: {
      return deleteFileFailure(state);
    }
    case FilesConstants.RENAME_FILE_PENDING: {
      return renameFilePending(state);
    }
    case FilesConstants.RENAME_FILE_SUCCESS: {
      return renameFileSuccess(state);
    }
    case FilesConstants.RENAME_FILE_FAILURE: {
      return renameFileFailure(state, action);
    }
    case FilesConstants.MOVE_FILE_PENDING: {
      return moveFilePending(state);
    }
    case FilesConstants.MOVE_FILE_SUCCESS: {
      return moveFileSuccess(state);
    }
    case FilesConstants.MOVE_FILE_FAILURE: {
      return moveFileFailure(state);
    }
    case FilesConstants.CREATE_FOLDER_PENDING: {
      return createFolderPending(state);
    }
    case FilesConstants.CREATE_FOLDER_SUCCESS: {
      return createFolderSuccess(state);
    }
    case FilesConstants.CREATE_FOLDER_FAILURE: {
      return createFolderFailure(state, action);
    }
    case FilesConstants.DOWNLOAD_FILE_PENDING: {
      return downloadFilePending(state, action);
    }
    case FilesConstants.DOWNLOAD_FILE_SUCCESS: {
      return downloadFileSuccess(state, action);
    }
    case FilesConstants.DOWNLOAD_FILE_FAILURE: {
      return downloadFileFailure(state);
    }
    case FilesConstants.UPLOAD_FILE_PENDING: {
      return uploadFilesGeneratorPending(state, action);
    }
    case FilesConstants.UPLOAD_FILE_SUCCESS: {
      return uploadFileSuccess(state, action);
    }
    case FilesConstants.UPLOAD_FILE_FAILURE: {
      return uploadFileFailure(state, action);
    }
    case FilesConstants.UPLOAD_FILE_CLEAR: {
      return uploadFileClear(state, action);
    }
    case FilesConstants.UPLOAD_FILES_CLEAR: {
      return uploadFilesClear(state);
    }
    case FilesConstants.UPLOAD_FILE_CANCEL: {
      return uploadFileCancel(state, action);
    }
    case FilesConstants.UPLOAD_FILE_PROGRESS: {
      return uploadFileProgress(state, action);
    }
    case FilesConstants.UPLOAD_FILE_PROGRESS_BAR_UPDATE: {
      return uploadFileProgressBarUpdate(state, action);
    }
    case FilesConstants.WORKSTATION_UPLOAD_FILE_SUCCESS: {
      return uploadWorkstationFileSuccess(state, action);
    }
    case FilesConstants.WORKSTATION_UPLOAD_FILE_REMOVE: {
      return uploadWorkstationFileRemove(state, action);
    }
    case FilesConstants.APPLICATION_UPLOAD_FILE_PENDING: {
      return uploadApplicationFilePending(state);
    }
    case FilesConstants.APPLICATION_UPLOAD_FILE_SUCCESS: {
      return uploadApplicationFileSuccess(state, action);
    }
    case FilesConstants.APPLICATION_UPLOAD_FILE_REMOVE: {
      return uploadApplicationFileRemove(state, action);
    }
    case FilesConstants.APPLICATION_VERSION_UPLOAD_FILE_PENDING: {
      return uploadApplicationVersionFilePending(state);
    }
    case FilesConstants.APPLICATION_VERSION_UPLOAD_FILE_SUCCESS: {
      return uploadApplicationVersionFileSuccess(state, action);
    }
    case FilesConstants.APPLICATION_VERSION_UPLOAD_FILE_REMOVE: {
      return uploadApplicationVersionFileRemove(state, action);
    }
    case FilesConstants.STREAM_SESSION_FILE_UPLOAD_PENDING: {
      return uploadStreamSessionFilePending(state);
    }
    case FilesConstants.STREAM_SESSION_FILE_UPLOAD_SUCCESS: {
      return uploadStreamSessionFileSuccess(state, action);
    }
    case FilesConstants.STREAM_SESSION_FILE_UPLOAD_REMOVE: {
      return uploadStreamSessionFileRemove(state, action);
    }

    case FilesConstants.CLEAR_USER_FILES: {
      return clearUserFiles(state);
    }

    case LOGOUT_API_PENDING:
      return logout();
    default: {
      return state;
    }
  }
}

function logout() {
  return {
    ...initialState,
    uploadFilesCTX: {
      uploadFiles: {},
      showProgressBar: true,
    },
  };
}

function getUserFilesPending(state, action) {
  const { isRefresh, query } = action.payload;

  return {
    ...state,
    userFilesCTX: {
      ...state.userFilesCTX,
      status: isRefresh ? REQUEST_STATUS.SUCCESS : REQUEST_STATUS.PENDING,
      searchStatus: query ? REQUEST_STATUS.PENDING : state.userFilesCTX.searchStatus,
    },
  };
}

function getMoveFilesPending(state) {
  return {
    ...state,
    moveFilesCTX: {
      ...state.moveFilesCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function getUserFilesSuccess(state, action) {
  const { files, currentFolder, move, isRefresh, search } = action.payload;
  const newFiles = { ...state.userFilesCTX.files };
  if (!search) {
    if (currentFolder) newFiles[currentFolder.id] = files;
    else newFiles[0] = files;
  }

  let newPath = [];
  if (!search) {
    newPath = move ? state.moveFilesCTX.path : state.userFilesCTX.path;
    const indexInPath = _.findIndex(newPath, (folder) => folder.id === currentFolder.id);
    if (indexInPath >= 0) {
      newPath = _.slice(newPath, 0, indexInPath + 1);
    } else {
      newPath.push({
        id: currentFolder.id,
        name: currentFolder.attributes ? currentFolder.attributes.name : "Home",
      });
    }
  }

  if (!move) {
    const hash = calculateStringHash(JSON.stringify(files));

    if (isRefresh && state.userFilesCTX.currentFolder && currentFolder.id !== state.userFilesCTX.currentFolder.id) {
      return state;
    }

    return {
      ...state,
      userFilesCTX: {
        ...state.userFilesCTX,
        status: REQUEST_STATUS.SUCCESS,
        searchStatus: search ? REQUEST_STATUS.SUCCESS : state.userFilesCTX.searchStatus,
        files: newFiles,
        contents: files,
        path: search ? state.userFilesCTX.path : newPath,
        hash,
        currentFolder,
        search,
      },
      moveFilesCTX: {
        status: REQUEST_STATUS.SUCCESS,
        contents: isRefresh ? state.moveFilesCTX.contents : files,
        currentFolder: isRefresh ? state.moveFilesCTX.currentFolder : currentFolder,
        path: isRefresh ? state.moveFilesCTX.path : newPath,
      },
    };
  }

  return {
    ...state,
    userFilesCTX: {
      ...state.userFilesCTX,
      files: newFiles,
    },
    moveFilesCTX: {
      ...state.userMovesFileCTX,
      status: REQUEST_STATUS.SUCCESS,
      contents: files,
      currentFolder,
      path: newPath,
    },
  };
}

function getUserFilesFailure(state, action) {
  const { query } = action.payload;
  return {
    ...state,
    userFilesCTX: {
      ...state.userFilesCTX,
      status: REQUEST_STATUS.FAILURE,
      searchStatus: query ? REQUEST_STATUS.FAILURE : state.userFilesCTX.searchStatus,
    },
  };
}

function getMoveFilesFailure(state) {
  return {
    ...state,
    moveFilesCTX: {
      ...state.moveFilesCTX,
      status: REQUEST_STATUS.FAILURE,
    },
  };
}

function getFileStorageCapacitySuccess(state, action) {
  const { totalStorage, usedStorage, teamFolderInfo } = action.payload;
  return {
    ...state,
    userFilesCTX: {
      ...state.userFilesCTX,
      totalStorage,
      usedStorage,
      teamFolderInfo,
    },
  };
}

function getRecentFilePending(state, action) {
  const { query } = action.payload;
  return {
    ...state,
    recentFilesCTX: {
      ...state.recentFilesCTX,
      status: state.recentFilesCTX.status === REQUEST_STATUS.SUCCESS ? REQUEST_STATUS.SUCCESS : REQUEST_STATUS.PENDING,
      query,
    },
  };
}

function getRecentFileSuccess(state, action) {
  const { files } = action.payload;
  return {
    ...state,
    recentFilesCTX: {
      ...state.recentFilesCTX,
      status: REQUEST_STATUS.SUCCESS,
      files,
    },
  };
}

function getRecentFileFailure(state) {
  return {
    ...state,
    recentFilesCTX: {
      ...state.recentFilesCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function deleteFilePending(state) {
  return {
    ...state,
    deleteFileCTX: {
      ...state.deleteFileCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function deleteFileSuccess(state) {
  return {
    ...state,
    deleteFileCTX: {
      ...state.deleteFileCTX,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function deleteFileFailure(state) {
  return {
    ...state,
    deleteFileCTX: {
      ...state.deleteFileCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function moveFilePending(state) {
  return {
    ...state,
    moveFileCTX: {
      ...state.moveFileCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function moveFileSuccess(state) {
  return {
    ...state,
    userFilesCTX: {
      ...state.userFilesCTX,
      files: {},
    },
    moveFileCTX: {
      ...state.moveFileCTX,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function moveFileFailure(state) {
  return {
    ...state,
    moveFileCTX: {
      ...state.moveFileCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function renameFilePending(state) {
  return {
    ...state,
    renameFileCTX: {
      ...state.renameFileCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function renameFileSuccess(state) {
  return {
    ...state,
    renameFileCTX: {
      ...state.renameFileCTX,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function renameFileFailure(state, action) {
  return {
    ...state,
    renameFileCTX: {
      ...state.renameFileCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
      clientCode: action.payload.client_code,
    },
  };
}

function createFolderPending(state) {
  return {
    ...state,
    createFolderCTX: {
      ...state.syncFolderCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function createFolderSuccess(state) {
  return {
    ...state,
    createFolderCTX: {
      ...state.createFolderCTX,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function createFolderFailure(state, action) {
  const { error } = action.payload;
  return {
    ...state,
    createFolderCTX: {
      ...state.createFolderCTX,
      status: REQUEST_STATUS.FAILURE,
      error,
      clientCode: action.payload.client_code,
    },
  };
}
function downloadFilePending(state, action) {
  const { fileName, fileSize } = action.payload;
  return {
    ...state,
    downloadFileCTX: {
      ...state.downloadFileCTX,
      name: fileName,
      size: fileSize,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function downloadFileSuccess(state, action) {
  const { fileName, fileSize, url } = action.payload;

  return {
    ...state,
    downloadFileCTX: {
      ...state.downloadFileCTX,
      name: fileName || state.downloadFileCTX.name,
      size: fileSize || state.downloadFileCTX.size,
      url,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function downloadFileFailure(state) {
  return {
    ...state,
    downloadFileCTX: {
      ...state.downloadFileCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function uploadFilesGeneratorPending(state, action) {
  const { files, uploadType } = action.payload;
  const prevFiles = state.uploadFilesCTX.uploadFiles;
  // const newFiles = {};
  _.forEach(files, (file) => {
    const fileCopy = file;
    fileCopy.fileName = file.fileName || file.name;
    fileCopy.status = FILE_STATUS.PENDING;
    fileCopy.error = false;
    fileCopy.progress = 0;
    fileCopy.cancelled = false;
    fileCopy.uploadType = uploadType;

    prevFiles[fileCopy.fileName] = file;
  });

  return {
    ...state,
    uploadFilesCTX: {
      ...state.uploadFilesCTX,
      uploadFiles: prevFiles,
    },
  };
}

function uploadFileSuccess(state, action) {
  const { name } = action.payload;

  const files = state.uploadFilesCTX.uploadFiles;

  files[name].status = FILE_STATUS.SUCCESS;

  return {
    ...state,
    uploadFilesCTX: {
      ...state.uploadFilesCTX,
      uploadFiles: files,
    },
  };
}

function uploadFileFailure(state, action) {
  const { name, error } = action.payload;

  const files = state.uploadFilesCTX.uploadFiles;

  // Don't update if file not exist
  if (!files[name]) {
    return state;
  }

  files[name].status = FILE_STATUS.FAILURE;
  files[name].error = error.client_code || true;
  files[name].isDuplicate = error.client_code === 451;

  return {
    ...state,
    uploadFilesCTX: {
      ...state.uploadFilesCTX,
      uploadFiles: files,
    },
  };
}

function uploadFileClear(state, action) {
  const { fileName } = action.payload;

  const files = state.uploadFilesCTX.uploadFiles;
  delete files[fileName];

  return {
    ...state,
    uploadFilesCTX: {
      ...state.uploadFilesCTX,
      uploadFiles: files,
    },
  };
}

function uploadFilesClear(state) {
  const files = state.uploadFilesCTX.uploadFiles;
  const uploadedFiles = _.filter(files, (file) => file.progress === 100);

  _.forEach(uploadedFiles, (file) => {
    delete files[file.fileName];
  });

  return {
    ...state,
    uploadFilesCTX: {
      ...state.uploadFilesCTX,
      uploadFiles: files,
    },
  };
}

function uploadFileCancel(state, action) {
  const { fileName } = action.payload;

  const files = state.uploadFilesCTX.uploadFiles;

  // Do not keep cancelled file
  delete files[fileName];

  return {
    ...state,
    uploadFilesCTX: {
      ...state.uploadFilesCTX,
      uploadFiles: files,
    },
  };
}

function uploadFileProgress(state, action) {
  const { fileName, progress } = action.payload;

  const files = state.uploadFilesCTX.uploadFiles;

  // Don't update if file not exist
  if (!files[fileName]) {
    return state;
  }

  files[fileName].progress = progress;
  return {
    ...state,
    uploadFilesCTX: {
      ...state.uploadFilesCTX,
      uploadFiles: files,
    },
  };
}

function uploadFileProgressBarUpdate(state, action) {
  const { showProgressBar } = action.payload;

  return {
    ...state,
    uploadFilesCTX: {
      ...state.uploadFilesCTX,
      showProgressBar,
    },
  };
}

function uploadWorkstationFileSuccess(state, action) {
  const { name, downloadUrl } = action.payload;

  const files = state.workstationUploadFilesCTX.uploadedFiles;

  files[name] = { downloadUrl };

  return {
    ...state,
    workstationUploadFilesCTX: {
      ...state.workstationUploadFilesCTX,
      uploadedFiles: files,
    },
  };
}

function uploadWorkstationFileRemove(state, action) {
  const { fileName } = action.payload;

  const files = state.workstationUploadFilesCTX.uploadedFiles;

  delete files[fileName];

  return {
    ...state,
    workstationUploadFilesCTX: {
      ...state.workstationUploadFilesCTX,
      uploadedFiles: files,
    },
  };
}

function uploadApplicationFilePending(state) {
  return {
    ...state,
    appUploadFilesCTX: {
      ...state.appUploadFilesCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function uploadApplicationFileSuccess(state, action) {
  const { name, fileContents, id, os } = action.payload;

  const files = state.appUploadFilesCTX.uploadedFiles;

  files[id] = { name, contents: fileContents[name] };

  return {
    ...state,
    appUploadFilesCTX: {
      ...state.appUploadFilesCTX,
      uploadedFiles: files,
      lastAppId: id,
      os,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function uploadApplicationFileRemove(state, action) {
  const { fileId } = action.payload;

  const files = state.appUploadFilesCTX.uploadedFiles;

  delete files[fileId];

  return {
    ...state,
    appUploadFilesCTX: {
      ...state.appUploadFilesCTX,
      uploadedFiles: files,
      lastAppId: null,
      os: null,
      status: REQUEST_STATUS.NOT_DEFINED,
    },
  };
}

function uploadApplicationVersionFilePending(state) {
  return {
    ...state,
    appVersionFilesCTX: {
      ...state.appVersionFilesCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function uploadApplicationVersionFileSuccess(state, action) {
  const { name, fileContents, id } = action.payload;

  const files = state.appVersionFilesCTX.uploadedFiles;

  files[id] = { name, contents: fileContents[name] };

  return {
    ...state,
    appVersionFilesCTX: {
      ...state.appVersionFilesCTX,
      uploadedFiles: files,
      lastExecutableId: id,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function uploadApplicationVersionFileRemove(state, action) {
  const { fileId } = action.payload;

  const files = state.appVersionFilesCTX.uploadedFiles;

  delete files[fileId];

  return {
    ...state,
    appVersionFilesCTX: {
      ...state.appVersionFilesCTX,
      uploadedFiles: files,
      lastExecutableId: null,
      status: REQUEST_STATUS.NOT_DEFINED,
    },
  };
}

function uploadStreamSessionFilePending(state) {
  return {
    ...state,
    streamSessionFilesCTX: {
      ...state.streamSessionFilesCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function uploadStreamSessionFileSuccess(state, action) {
  const { name, id, downloadUrl } = action.payload;

  const files = state.streamSessionFilesCTX.uploadedFiles;

  files[id] = { name, downloadUrl };

  return {
    ...state,
    streamSessionFilesCTX: {
      ...state.streamSessionFilesCTX,
      uploadedFiles: files,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function uploadStreamSessionFileRemove(state, action) {
  const { fileId } = action.payload;

  const files = state.streamSessionFilesCTX.uploadedFiles;

  delete files[fileId];

  return {
    ...state,
    streamSessionFilesCTX: {
      ...state.streamSessionFilesCTX,
      uploadedFiles: files,
      status: REQUEST_STATUS.NOT_DEFINED,
    },
  };
}

function clearUserFiles(state) {
  return {
    ...state,
    userFilesCTX: initialState.userFilesCTX,
  };
}
