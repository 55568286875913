import React, { useState, useRef } from "react";
import { Modal, Table, IconButton, HoverableTooltip } from "UI";
import { BG_STYLE_BY_APP_FRIENDLY_STATUS } from "Constants/AppStreaming.constants";
import AppMenuPopUp from "Components/AppStreaming/Home/AppMenuPopUp/AppMenuPopUp.component";
import "./AppListModal.styles.scss";

const ActionButton = (props) => {
  const { iconName, action, content, buttonRef } = props;

  return (
    <HoverableTooltip content={content}>
      <IconButton buttonRef={buttonRef} name={iconName} className="selected-file-actions" onClick={action} />
    </HoverableTooltip>
  );
};

const AppListModal = ({
  appList,
  selectedApp,
  setSelectedApp,
  onShare,
  onConfigure,
  setShowDeleteAppConfirmationModal,
  setShowAppList,
  translate,
}) => {
  const listContainerRef = useRef(null);
  const [popUpPosition, setPopUpPosition] = useState(null);

  const columns = [
    { name: "Id", weight: 0 },
    { name: translate("appStreamingDashboard.appList.columns.name"), weight: 40 },
    { name: translate("appStreamingDashboard.appList.columns.status"), weight: 40 },
    { name: "buttons", weight: 20, hide: true },
  ];

  const handleMoreButtonClick = (event, item) => {
    if (!popUpPosition) event.stopPropagation();
    event.preventDefault();
    setSelectedApp(item);

    if (selectedApp || item) {
      setPopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
    }
  };

  const data = appList.map((singleApp) => {
    const appID = singleApp.id;
    const appName = singleApp.attributes.name;
    const appStatus = singleApp.attributes.friendly_status;
    return {
      id: appID,
      name: appName,
      status: (
        <>
          <div className="status" style={BG_STYLE_BY_APP_FRIENDLY_STATUS[appStatus]} />
          {translate(`appStreamingDashboard.appList.appFriendlyStatus.${appStatus}`)}
        </>
      ),
      buttons: (
        <div className="icon-buttons">
          <ActionButton
            iconName="share"
            action={() => onShare(singleApp)}
            content={translate("appStreamingIconButtons.share")}
          />
          <ActionButton
            iconName="dashboard"
            action={() => onConfigure(singleApp)}
            content={translate("appStreamingIconButtons.configure")}
          />
          <ActionButton
            iconName="more"
            action={(event) => handleMoreButtonClick(event, singleApp)}
            content={translate("appStreamingIconButtons.more")}
          />
        </div>
      ),
    };
  });

  const onClose = () => {
    setShowAppList(false);
    setSelectedApp({});
  };

  return (
    <Modal
      className="app-list-modal"
      closeOnOverlayClick
      closeAction={onClose}
      topRightIcon="close"
      topRightIconAction={onClose}
      headerText={translate("appStreamingDashboard.appList.header")}
      descriptionText={
        <>
          <div className="scrollable-table-container" ref={listContainerRef}>
            <Table columns={columns} data={data} />
            {popUpPosition && (
              <AppMenuPopUp
                setShowPopUpMenu={() => setPopUpPosition(null)}
                className="app-list-pop-up-menu"
                content={[
                  selectedApp.attributes.status === "active" && {
                    text: translate("appStreamingDashboard.app.menuPopUp.delete"),
                    onClick: (event) => {
                      event.stopPropagation();
                      setPopUpPosition(null);
                      setShowAppList(false);
                      setShowDeleteAppConfirmationModal(true);
                    },
                  },
                ]}
                popUpPosition={popUpPosition}
                referencePosRef={listContainerRef}
              />
            )}
          </div>
        </>
      }
      overrideButton={<></>}
    />
  );
};

export default AppListModal;
