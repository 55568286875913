import React, { useEffect, useState } from "react";
import Joi from "joi";
import _ from "lodash";
import { Input, PasswordInput, Button, TextButton, TopLoader, Slide } from "UI";
import ReCaptcha from "Utils/Components/Form/ReCaptcha";
import routes from "Constants/Route.constants";
import AuthenticationHeader from "Utils/Components/Authentication/AuthenticationHeader/AuthenticationHeader.component";
import { APP_SCOPES, ERROR_CODE, INPUT_LIMITS, REQUEST_STATUS } from "Constants/global.constants";
import { useFocusNext } from "Utils/Hooks";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import GoogleSignIn from "Utils/Components/GoogleSignIn/GoogleSignIn.component";
import { Animation, classnames } from "Utils";

import "./Register.styles.scss";

const nameValidationErrors = {
  "string.empty": "Enter your name",
  "any.required": "Enter your name",
  "string.pattern.base": "Enter a valid name",
};

const emailValidationErrors = {
  "string.empty": "Enter your email address",
  "string.email": "Enter a valid email address",
  "any.required": "Enter your email address",
};

const passwordValidationErrors = {
  "string.empty": "Set a password for your account",
  "any.required": "Set a password for your account",
  "string.min": "Password must be at least 8 characters",
};

const ValidationSchema = Joi.object({
  name: Joi.string()
    .min(3)
    .max(30)
    .required()
    .pattern(/(?=^[^ '\-,.])[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)
    .messages(nameValidationErrors),
  email: Joi.string()
    .email({
      minDomainSegments: 2,
      tlds: false,
    })
    .required()
    .messages(emailValidationErrors),
  password: Joi.string().min(8).required().messages(passwordValidationErrors),
});

const TeamValidationSchema = Joi.object({
  companyName: Joi.string().min(3).required().messages({ "string.empty": "Set a Company Name for your account" }),
  jobTitle: Joi.string().min(3).required().messages({ "string.empty": "Set a Job Title for your account" }),
});

// Todo apply translate
const backendErrorGenerator = (clientCode) => {
  switch (clientCode) {
    case 435:
      return "Registrations from this email domain is restricted.";
    case 4316:
      return "There is already an account with this email address. We are redirecting you to the login page.";
    default:
      return "There was a problem with your registration, please contact us.";
  }
};

const RegisterComponent = (props) => {
  const {
    history,
    registerCTX,
    registerAPI,
    appStreamingRegisterAPI,
    organizationRegisterAPI,
    registerClear,
    appStreaming,
    translate,
  } = props;
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const [error, setError] = useState(null);

  const [activeAccountFormIndex, setActiveAccountFormIndex] = useState(0);

  const [submitted, setSubmitted] = useState(false);

  const { focusNextRef, focusFirstEmpty } = useFocusNext();

  const isBusiness = window.location.hash === "#business";

  const urlParams = new URLSearchParams(window.location.search);
  const adminInvitation = urlParams.get("admin_invitation");
  const referrer = urlParams.get("invite");
  const referralCode = urlParams.get("referralCode");

  useEffect(() => {
    if (registerCTX.status === REQUEST_STATUS.FAILURE && registerCTX.error) {
      setError({
        email: backendErrorGenerator(registerCTX.client_code),
      });
      if (registerCTX.client_code === ERROR_CODE.ACCOUNT_EXISTS) {
        setTimeout(() => {
          registerClear();
          history.push(routes.login);
        }, 3000);
      }
    } else {
      if (registerCTX.status === REQUEST_STATUS.NOT_DEFINED) {
        setPassword("");
      }
      setError(null);

      if (registerCTX.status === REQUEST_STATUS.SUCCESS && registerCTX.data?.accessToken) {
        history.push("/");
      }
    }
  }, [registerCTX]);

  const setIsBusiness = (state) => {
    if (state) {
      history.push({ hash: "#business" });
    } else {
      history.push({ hash: "" });
    }
  };

  const resetForm = () => {
    setPassword("");
    setError(null);
  };

  const registerUser = async (recaptchaResponse) => {
    const { data: visitorData } = await getFingerprint();

    const data = {
      name,
      email,
      password,
      referralCode,
      promoCode: null,
      isTermsOfServiceChecked: true,
      recaptchaResponse,
      companyName: isBusiness || appStreaming ? companyName : undefined,
      visitorData,
      initialScope: appStreaming ? APP_SCOPES.vendor : null,
    };

    if (appStreaming) {
      appStreamingRegisterAPI({
        name,
        email,
        password,
        recaptchaResponse,
        companyName,
        visitorData,
        referrer,
      });
      return;
    }

    if (isBusiness) {
      organizationRegisterAPI(data);
      return;
    }

    registerAPI(data);
  };

  const getFingerprint = () => {
    return new Promise((resolve) => {
      const fpPromise = FingerprintJS.load();
      fpPromise
        .then((fp) => fp.get())
        .then((fingerprintFreeData) => {
          resolve({ data: fingerprintFreeData });
        });
    });
  };

  const handleSubmit = (event, reCaptcha) => {
    event.preventDefault();
    setSubmitted(true);
    setTimeout(() => {
      setSubmitted(false);
    }, 1000);

    // Form Errors
    const { error: validationError } = ValidationSchema.validate({
      name,
      email,
      password,
    });
    // Team form Errors
    const { error: teamValidationError } = TeamValidationSchema.validate({
      companyName,
      jobTitle,
    });

    if (isBusiness && teamValidationError) {
      if (teamValidationError) {
        setActiveAccountFormIndex(0);

        const teamErrorString = _.replace(teamValidationError, "ValidationError: ", "");

        if (_.includes(teamErrorString, "companyName")) {
          setError({
            companyName: teamErrorString,
          });
        }

        if (_.includes(teamErrorString, "jobTitle")) {
          setError({
            jobTitle: teamErrorString,
          });
        }
      }
    } else if (validationError) {
      const errorString = _.replace(validationError, "ValidationError: ", "");

      if (_.includes(nameValidationErrors, errorString)) {
        if (isBusiness) {
          setActiveAccountFormIndex(0);
        }

        setError({
          name: errorString,
        });
      }

      if (_.includes(emailValidationErrors, errorString)) {
        setError({
          email: errorString,
        });
      }

      if (_.includes(passwordValidationErrors, errorString)) {
        setError({
          password: errorString,
        });
      }
    } else if (!isStrongPassword) {
      // Prevent submission if new password is not valid
      setError({ password: translate("register.form.error.weakPassword") });
    } else {
      setError(null);
      reCaptcha({ action: "register" }, (recaptchaResponse) => {
        registerUser(recaptchaResponse);
      });
    }
  };

  const googleSignUp = (reCaptcha, assertion) => {
    reCaptcha({ action: "register" }, async (recaptchaResponse) => {
      const { data: visitorData } = await getFingerprint();
      const data = {
        name,
        email,
        password,
        referralCode,
        promoCode: null,
        isTermsOfServiceChecked: true,
        recaptchaResponse,
        visitorData,
        assertion,
        provider: "google",
        initialScope: appStreaming ? APP_SCOPES.vendor : null,
      };

      registerAPI(data);
    });
  };

  const emailAndPasswordForm = () => (
    <>
      <Input
        inputRef={focusNextRef}
        type="email"
        autoComplete="email"
        maxLength={INPUT_LIMITS.email}
        label={translate("register.form.email.label")}
        placeholder={
          isBusiness ? translate("register.form.email.teamPlaceholder") : translate("register.form.email.placeholder")
        }
        value={email}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        hasError={error && error.email}
        errorText={error && error.email}
        onFocus={() => {
          if (isBusiness && activeAccountFormIndex === 0) {
            focusFirstEmpty();
          }
        }}
      />
      <PasswordInput
        inputRef={focusNextRef}
        showStrength
        setIsStrongPassword={setIsStrongPassword}
        label={translate("register.form.password.label")}
        placeholder={translate("register.form.password.placeholder")}
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
        hasError={error && error.password}
        errorText={error && error.password}
      />
    </>
  );

  const personalAccountForm = () => (
    <>
      <Input
        inputRef={focusNextRef}
        type="text"
        maxLength={INPUT_LIMITS.name}
        label={translate("register.form.name.label")}
        placeholder={translate("register.form.name.placeholder")}
        value={name}
        onChange={(event) => {
          setName(event.target.value);
          setFirstName(event.target.value.split(" ")[0]);
        }}
        hasError={error && error.name}
      />
      {emailAndPasswordForm()}
    </>
  );

  const vendorAccountForm = () => (
    <>
      <Input
        inputRef={focusNextRef}
        type="text"
        maxLength={INPUT_LIMITS.name}
        label={translate("register.form.name.label")}
        placeholder={translate("register.form.name.placeholder")}
        value={name}
        onChange={(event) => {
          setName(event.target.value);
          setFirstName(event.target.value.split(" ")[0]);
        }}
        hasError={error && error.name}
      />
      {!adminInvitation && (
        <Input
          inputRef={focusNextRef}
          type="text"
          label={translate("appStreamingRegister.form.company.label")}
          placeholder={translate("appStreamingRegister.form.company.placeholder")}
          value={companyName}
          onChange={(event) => {
            setCompanyName(event.target.value);
          }}
          hasError={error && error.companyName}
        />
      )}
      {emailAndPasswordForm()}
    </>
  );

  const teamAccountForm = () => (
    <>
      <Slide showDots activeIndex={activeAccountFormIndex} setActiveIndex={setActiveAccountFormIndex}>
        <>
          <Input
            inputRef={focusNextRef}
            type="text"
            maxLength={INPUT_LIMITS.name}
            label={translate("register.form.name.label")}
            placeholder={translate("register.form.name.teamPlaceholder")}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
              setFirstName(event.target.value.split(" ")[0]);
            }}
            hasError={error && error.name}
          />
          <Input
            inputRef={focusNextRef}
            type="text"
            label={translate("register.form.company.label")}
            placeholder={translate("register.form.company.placeholder")}
            value={companyName}
            onChange={(event) => {
              setCompanyName(event.target.value);
            }}
            hasError={error && error.companyName}
          />
          <Input
            inputRef={focusNextRef}
            type="text"
            label={translate("register.form.title.label")}
            placeholder={translate("register.form.title.placeholder")}
            value={jobTitle}
            onKeyDown={(event) => {
              if (event.key === "Tab" || event.key === "Enter") {
                event.preventDefault();
                if (name && companyName && jobTitle) {
                  setActiveAccountFormIndex(1);
                }
                focusFirstEmpty();
              }
            }}
            onChange={(event) => {
              setJobTitle(event.target.value);
            }}
            hasError={error && error.jobTitle}
          />
        </>
        {emailAndPasswordForm()}
      </Slide>
    </>
  );

  const getForm = () => {
    if (isBusiness) return teamAccountForm();
    if (appStreaming) return vendorAccountForm();
    return personalAccountForm();
  };

  let defaultWelcomeMessage = referralCode
    ? translate("register.header.welcomeWithReferral")
    : translate("register.header.welcomeWithoutName");

  if (isBusiness) {
    defaultWelcomeMessage = translate("register.header.welcomeTeams");
  }

  const getTitle = () => {
    if (appStreaming) return translate("appStreamingRegister.header.welcome");
    if (firstName === "") return defaultWelcomeMessage;
    return translate("register.header.welcomeWithName", { name: firstName });
  };

  return registerCTX.status === REQUEST_STATUS.SUCCESS ? (
    <div className="vg-register-content form-container">
      <div className="vg-register-success-wrapper">
        <AuthenticationHeader title={translate("registerCompleted.header.completed")} appStreaming={appStreaming} />
        <div className="field-group">
          <Animation type="successPurple" animationStyle={{ margin: "0 auto 0 -20px" }} />
          <p className="description">{translate("registerCompleted.header.description")}</p>
          <p className="vg-register-success-content">{translate("registerCompleted.content.line_1")}</p>
          <p className="vg-register-success-content vg-register-success-email">
            {translate("registerCompleted.content.line_2", { email })}
          </p>
          <p className="vg-register-success-content">{translate("registerCompleted.content.line_3")}</p>
          <TextButton
            text={translate("registerCompleted.changeEmail")}
            color="purple"
            onClick={() => registerClear()}
          />
        </div>
        <div className="divider" />
      </div>
      <div className="vg-register-success-bottom">
        <span className="vg-register-success-bottom-content">{translate("registerCompleted.bottomContent.text")} </span>
        <TextButton
          text={translate("registerCompleted.bottomContent.buttonText")}
          color="purple"
          onClick={() => {
            history.push(routes.login);
          }}
        />
      </div>
    </div>
  ) : (
    <>
      {registerCTX.status === REQUEST_STATUS.PENDING && <TopLoader />}
      <ReCaptcha>
        {(reCaptcha) => (
          <div className="vg-register-content form-container">
            <form onSubmit={(event) => handleSubmit(event, reCaptcha)} noValidate>
              <AuthenticationHeader
                title={getTitle()}
                description={translate("register.header.description")}
                descriptionActionText={
                  !appStreaming
                    ? translate("register.header.descriptionActionText")
                    : translate("appStreamingRegister.header.descriptionActionText")
                }
                descriptionAction={() => {
                  history.push(routes.login);
                }}
                appStreaming={appStreaming}
              />

              <div className="field-group">
                <GoogleSignIn
                  onSuccess={(e) => {
                    const assertion = e.credential;
                    googleSignUp(reCaptcha, assertion);
                  }}
                  register
                />
                <hr className="hr-text" data-content="or" />
                {!appStreaming && (
                  <div className={classnames(["account-type", isBusiness && "team"])}>
                    <TextButton
                      className={!isBusiness && "active"}
                      text="Individual"
                      onClick={() => {
                        setIsBusiness(false);
                        resetForm();
                      }}
                      icon="individual"
                      iconPosition="left"
                    />
                    <div className="divider" />
                    <TextButton
                      className={isBusiness && "active"}
                      text="Business"
                      onClick={() => {
                        setIsBusiness(true);
                        setActiveAccountFormIndex(0);
                        resetForm();
                      }}
                      icon="team"
                      iconPosition="left"
                    />
                    <div className="account-type-bar" />
                  </div>
                )}

                {getForm()}
              </div>
              {isBusiness ? (
                <Button
                  type="button"
                  disabled={
                    registerCTX.status === REQUEST_STATUS.PENDING ||
                    jobTitle === "" ||
                    name === "" ||
                    companyName === ""
                  }
                  text={
                    activeAccountFormIndex === 0
                      ? translate("register.form.button.next")
                      : translate("register.form.button.text")
                  }
                  onClick={(event) => {
                    if (activeAccountFormIndex === 0) {
                      setActiveAccountFormIndex(1);
                    } else {
                      handleSubmit(event, reCaptcha);
                    }
                  }}
                  block
                />
              ) : (
                <Button
                  type="submit"
                  disabled={
                    submitted ||
                    registerCTX.status === REQUEST_STATUS.PENDING ||
                    registerCTX.status === REQUEST_STATUS.SUCCESS ||
                    reCaptcha === null
                  }
                  text={translate("register.form.button.text")}
                  block
                />
              )}
            </form>
            <div className="vg-terms-and-conditions">
              <span className="vg-terms-and-conditions-content">
                {appStreaming
                  ? translate("register.form.appStreamingTermsAndConditions.text")
                  : translate("register.form.termsAndConditions.text")}{" "}
                {appStreaming ? (
                  <>
                    <a rel="noopener noreferrer" target="_blank" href="https://vagon.io/terms-and-conditions">
                      {translate("register.form.appStreamingTermsAndConditions.terms")}
                    </a>
                    {" and "}
                    <a rel="noopener noreferrer" target="_blank" href="https://vagon.io/streams/terms-and-conditions">
                      {translate("register.form.appStreamingTermsAndConditions.streamsTerms")}
                    </a>
                  </>
                ) : (
                  <a rel="noopener noreferrer" target="_blank" href="https://vagon.io/terms-and-conditions">
                    {translate("register.form.termsAndConditions.terms")}
                  </a>
                )}
              </span>
            </div>
          </div>
        )}
      </ReCaptcha>
    </>
  );
};

export default RegisterComponent;
