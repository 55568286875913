import { all, call, put, takeLatest } from "redux-saga/effects";
import apiGenerator from "Utils/Helpers/api.helpers";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";

import * as AccountConstants from "Constants/Account.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";
import {
  getFacebookFbcFromCookie,
  getFacebookFbpFromCookie,
  getGaClientId,
  getGaSessionIdFromCookie,
} from "Utils/Helpers/storage.helpers";

function* getAccountAPISaga() {
  const clientId = yield call(getGaClientId);
  const sessionId = getGaSessionIdFromCookie();
  const fbp = getFacebookFbpFromCookie();
  const fbc = getFacebookFbcFromCookie();

  const api = apiGenerator("get")(API_ENDPOINTS.ACCOUNT, {
    params: {
      ga_client_id: clientId,
      ga_session_id: sessionId,
      fbp,
      fbc,
    },
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.GET_ACCOUNT_API_SUCCESS,
        payload: { account: response.data },
      });
    } else {
      yield put({
        type: AccountConstants.GET_ACCOUNT_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.GET_ACCOUNT_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getAccountPredefinedAPISaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.ACCOUNT_PREDEFINED);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.GET_ACCOUNT_PREDEFINED_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AccountConstants.GET_ACCOUNT_PREDEFINED_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.GET_ACCOUNT_PREDEFINED_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* applyPromoCodeAPISaga(action) {
  const { accessToken, code } = action.payload;

  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.APPLY_PROMOCODE, { code });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.APPLY_PROMOCODE_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AccountConstants.APPLY_PROMOCODE_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.APPLY_PROMOCODE_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* setUserProfileAPISaga(action) {
  const { interests, rememberMachineType, multiGpuConsent } = action.payload;

  const api = apiGenerator("patch")(API_ENDPOINTS.ACCOUNT_PROFILE, {
    interests,
    multi_gpu_consent: multiGpuConsent,
    remember_machine_type: rememberMachineType,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.SET_USER_PROFILE_API_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AccountConstants.SET_USER_PROFILE_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.SET_USER_PROFILE_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* changePasswordAPISaga(action) {
  const { accessToken, requestPayload } = action.payload;

  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.CHANGE_PASSWORD, requestPayload);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.CHANGE_PASSWORD_API_SUCCESS,
      });
    } else {
      const error = apiErrorHandler({ response });

      yield put({
        type: AccountConstants.CHANGE_PASSWORD_API_FAILURE,
        payload: error,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: AccountConstants.CHANGE_PASSWORD_API_FAILURE,
      payload: error,
    });
  }
}

function* getUserPaymentHistorySaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.PAYMENT_HISTORY);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.GET_USER_PAYMENT_HISTORY_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.GET_USER_PAYMENT_HISTORY_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getTransactionHistorySaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.TRANSACTION_HISTORY);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.GET_TRANSACTION_HISTORY_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.GET_TRANSACTION_HISTORY_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getFailedPaymentSaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.FAILED_PAYMENT);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.GET_FAILED_PAYMENT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.GET_FAILED_PAYMENT_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getMachineUsageHistorySaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.MACHINE_USAGE_HISTORY);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.GET_MACHINE_USAGE_HISTORY_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.GET_MACHINE_USAGE_HISTORY_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* resendConfirmationEmailSaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.RESEND_CONFIRMATION);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.RESEND_CONFIRMATION_EMAIL_API_SUCCESS,
      });
    } else {
      yield put({
        type: AccountConstants.RESEND_CONFIRMATION_EMAIL_API_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.RESEND_CONFIRMATION_EMAIL_API_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* getProductOnboardingSaga(action) {
  const { accessToken } = action.payload;

  const api = apiGenerator("get", accessToken)(API_ENDPOINTS.GET_PRODUCT_ONBOARDING);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: AccountConstants.GET_PRODUCT_ONBOARDING_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: AccountConstants.GET_PRODUCT_ONBOARDING_FAILURE,
        payload: apiErrorHandler({ response }),
      });
    }
  } catch (err) {
    yield put({
      type: AccountConstants.GET_PRODUCT_ONBOARDING_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(AccountConstants.GET_ACCOUNT_API_PENDING, getAccountAPISaga),
    takeLatest(AccountConstants.GET_ACCOUNT_PREDEFINED_API_PENDING, getAccountPredefinedAPISaga),
    takeLatest(AccountConstants.SET_USER_PROFILE_API_PENDING, setUserProfileAPISaga),
    takeLatest(AccountConstants.APPLY_PROMOCODE_API_PENDING, applyPromoCodeAPISaga),
    takeLatest(AccountConstants.CHANGE_PASSWORD_API_PENDING, changePasswordAPISaga),
    takeLatest(AccountConstants.GET_USER_PAYMENT_HISTORY_PENDING, getUserPaymentHistorySaga),
    takeLatest(AccountConstants.GET_TRANSACTION_HISTORY_PENDING, getTransactionHistorySaga),
    takeLatest(AccountConstants.GET_FAILED_PAYMENT_PENDING, getFailedPaymentSaga),
    takeLatest(AccountConstants.GET_MACHINE_USAGE_HISTORY_PENDING, getMachineUsageHistorySaga),
    takeLatest(AccountConstants.RESEND_CONFIRMATION_EMAIL_API_PENDING, resendConfirmationEmailSaga),
    takeLatest(AccountConstants.GET_PRODUCT_ONBOARDING_PENDING, getProductOnboardingSaga),
  ]);
}
