import { REQUEST_STATUS } from "Constants/global.constants";
import * as RegionsConstants from "Constants/Regions.constants";
import * as LoginConstants from "Constants/Authentication/Login.constants";

const initialState = {
  getRegionsCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  locationCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
};

export default function region(state = initialState, action) {
  switch (action.type) {
    case RegionsConstants.GET_REGIONS_PENDING: {
      return getRegionsPending(state);
    }
    case RegionsConstants.GET_REGIONS_SUCCESS: {
      return getRegionsSuccess(state, action);
    }
    case RegionsConstants.GET_REGIONS_FAILURE: {
      return getRegionsFailure(state);
    }
    case RegionsConstants.GET_LOCATION_PENDING: {
      return getLocationPending(state);
    }
    case RegionsConstants.GET_LOCATION_SUCCESS: {
      return getLocationSuccess(state, action);
    }
    case RegionsConstants.GET_LOCATION_FAILURE: {
      return getLocationFailure(state);
    }
    case LoginConstants.LOGOUT_API_PENDING:
      return logout();
    default:
      return state;
  }
}

function logout() {
  return initialState;
}

function getRegionsPending(state) {
  return {
    ...state,
    getRegionsCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getRegionsSuccess(state, action) {
  return {
    ...state,
    getRegionsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload.regions,
      error: false,
    },
  };
}

function getRegionsFailure(state) {
  return {
    ...state,
    getRegionsCTX: {
      ...state.getRegionsCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getLocationPending(state) {
  return {
    ...state,
    locationCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getLocationSuccess(state, action) {
  return {
    ...state,
    locationCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload.country,
      error: false,
    },
  };
}

function getLocationFailure(state) {
  return {
    ...state,
    locationCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}
