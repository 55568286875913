import {
  APP_STREAMING_AUTO_STOP_OPTIONS,
  MAXIMUM_SESSION_DURATIONS,
  CAPACITY_TYPES,
  IDLE_DURATION_OPTIONS,
} from "Constants/global.constants";
import React, { useState, useEffect } from "react";
import { Switch, Dropdown, NumberInput, IconButton, Icon, HoverableTooltip } from "UI";
import { classnames } from "Utils";
import { STREAM_TYPES } from "Constants/AppStreaming.constants";
import "./LimitationsStep.styles.scss";

const MAX_CUSTOM_AMOUNT_FOR_VENDOR = 5000;
const MIN_CUSTOM_AMOUNT = 0;

const LimitationsStep = ({
  streamConfigs,
  setStreamConfigs,
  appStreaming,
  isVendorEnterprise,
  streamType,
  translate,
}) => {
  const childStream = streamType === STREAM_TYPES.child;
  const { maximumSessionDuration, autoTurnOffDuration, idleDuration, budget, budgetRefresh } = streamConfigs;
  const { capacityType } = streamConfigs.capacities[0] || {};
  const [maximumSessionDurationEnabled, setMaximumSessionDurationEnabled] = useState(false);
  const [clickedTrash, setClickedTrash] = useState();
  const [budgetLimit, setBudgetLimit] = useState(parseFloat(budget?.attributes?.limit || 0));
  const applications = appStreaming?.getApplicationsCTX?.data?.applications;
  const budgetRatio = streamConfigs?.budget?.attributes?.ratio;
  const streamId = streamConfigs?.applicationId;
  const findRelatedApplication = applications.find((app) => app.id === String(streamId));
  const applicationLimit = findRelatedApplication?.attributes?.budget?.attributes?.limit;
  const streamLimit = budget?.attributes?.limit;

  const [autoTurnOffEnabled, setAutoTurnOffEnabled] = useState(false);

  useEffect(() => {
    setMaximumSessionDurationEnabled(maximumSessionDuration !== 0);
  }, [maximumSessionDuration]);

  useEffect(() => {
    setAutoTurnOffEnabled(streamConfigs.autoTurnOffDuration !== 0);
  }, [autoTurnOffDuration]);

  useEffect(() => {
    if (maximumSessionDurationEnabled && maximumSessionDuration === 0) {
      setStreamConfigs({ maximumSessionDuration: MAXIMUM_SESSION_DURATIONS[15] });
    }
    if (autoTurnOffEnabled && autoTurnOffDuration === 0) {
      setStreamConfigs({ autoTurnOffDuration: APP_STREAMING_AUTO_STOP_OPTIONS[120] });
    }
  }, [maximumSessionDurationEnabled, autoTurnOffEnabled]);

  useEffect(() => {
    setBudgetLimit(parseFloat(budget?.attributes?.limit || 0));
  }, [budget]);

  return (
    <>
      <div className={classnames(["configuration-option switch-dropdown", childStream && "disabled"])}>
        <div>
          <Switch
            reverseText
            text={translate("configureStream.configurations.maxSessionDuration.header")}
            checked={maximumSessionDurationEnabled}
            onChange={(e) => {
              setMaximumSessionDurationEnabled(e);
              if (!e) setStreamConfigs({ maximumSessionDuration: MAXIMUM_SESSION_DURATIONS[0] });
            }}
          />
          <p className="description">{translate("configureStream.configurations.maxSessionDuration.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            defaultSelected={maximumSessionDuration?.toString()}
            options={Object.keys(MAXIMUM_SESSION_DURATIONS)}
            mapFunction={(option) => {
              return translate(`configureStream.maxSessionDuration.options.${option}`);
            }}
            handleSelectedOptionChange={(option) => {
              setStreamConfigs({ maximumSessionDuration: parseInt(option, 10) });
            }}
          />
        </div>
      </div>
      <div className={classnames(["configuration-option switch-dropdown", childStream && "disabled"])}>
        <div>
          <Switch
            reverseText
            text={translate("configureStream.configurations.autoTurnOff.header")}
            checked={capacityType === CAPACITY_TYPES.always_on ? false : autoTurnOffEnabled}
            onChange={(e) => {
              setAutoTurnOffEnabled(e);
              if (!e) setStreamConfigs({ autoTurnOffDuration: APP_STREAMING_AUTO_STOP_OPTIONS[0] });
            }}
            disabled={capacityType === CAPACITY_TYPES.always_on}
          />
          <p className="description">{translate("configureStream.configurations.autoTurnOff.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            defaultSelected={
              capacityType === CAPACITY_TYPES.always_on || !autoTurnOffEnabled
                ? "0"
                : APP_STREAMING_AUTO_STOP_OPTIONS[autoTurnOffDuration]
            }
            options={Object.values(APP_STREAMING_AUTO_STOP_OPTIONS)}
            mapFunction={(option) => {
              return translate(`configureStream.autoTurnOffDuration.options.${option}`);
            }}
            handleSelectedOptionChange={(e) => setStreamConfigs({ autoTurnOffDuration: parseInt(e, 10) })}
            disabled={capacityType === CAPACITY_TYPES.always_on}
          />
        </div>
      </div>
      <div className={classnames(["configuration-option switch-dropdown", childStream && "disabled"])}>
        <div>
          <Switch
            reverseText
            text={translate("configureStream.configurations.idleDuration.header")}
            checked={idleDuration !== 0}
            onChange={(e) => {
              if (e) setStreamConfigs({ idleDuration: IDLE_DURATION_OPTIONS[600000] });
              else setStreamConfigs({ idleDuration: 0 });
            }}
          />
          <p className="description">{translate("configureStream.configurations.idleDuration.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            defaultSelected={idleDuration?.toString()}
            options={Object.keys(IDLE_DURATION_OPTIONS)}
            mapFunction={(option) => {
              return translate(`configureStream.configurations.idleDuration.options.${option}`);
            }}
            handleSelectedOptionChange={(option) => {
              setStreamConfigs({ idleDuration: parseInt(option, 10) });
            }}
          />
        </div>
      </div>
      <div
        className={classnames([
          "configuration-option",
          "budget-limit",
          !isVendorEnterprise && "disabled",
          childStream && "disabled",
        ])}
      >
        <div className="budget-limit-container">
          <div className="with-badge">
            <p>{translate("configureStream.configurations.budgetLimit.header")}</p>
            <HoverableTooltip content="Calculations may not be precise" side="top">
              <Icon name="info" color="gray-4" smaller />
            </HoverableTooltip>
            <div className="enterprise-badge">Enterprise</div>
          </div>
          <p className="description">{translate("configureStream.configurations.budgetLimit.description")}</p>
        </div>

        <div className={classnames(["input-box", budget && !clickedTrash && "disabled"])}>
          <NumberInput
            value={budgetLimit}
            minInputValue={MIN_CUSTOM_AMOUNT}
            maxInputValue={MAX_CUSTOM_AMOUNT_FOR_VENDOR}
            onChange={(e) => {
              setBudgetLimit(e);
              setStreamConfigs({ budgetLimit: e });
            }}
            inputMode="numeric"
            disabled={budget && !clickedTrash}
          />
        </div>
      </div>
      {budget && !clickedTrash && (
        <div
          className="app-budget-status"
          style={{ border: !budgetRefresh && budgetRatio >= 100 && "1px solid #E08989" }}
        >
          <div className="app-budget-descriptions">
            <h1 style={{ color: !budgetRefresh && budgetRatio >= 100 && "#E08989" }}>
              {translate(
                `configureStream.configurations.budgetLimit.status.header.${
                  !budgetRefresh && budgetRatio >= 100 ? "reached" : "active"
                }`,
              )}
            </h1>
            <p>
              {budgetRatio >= 100 && !budgetRefresh
                ? translate(
                    `configureStream.configurations.budgetLimit.reached.${
                      applicationLimit && streamLimit && !clickedTrash ? "multipleBudget" : "singleBudget"
                    }`,
                  )
                : translate(
                    `configureStream.configurations.budgetLimit.active.${
                      applicationLimit && streamLimit && !clickedTrash ? "multipleBudget" : "singleBudget"
                    }`,
                  )}
            </p>
          </div>

          <div className="app-budget-icons">
            <HoverableTooltip content="Delete">
              <IconButton
                name="close"
                color="gray-3"
                style={{ height: "1.2rem", width: "1.5rem" }}
                onClick={() => {
                  setClickedTrash(true);
                  setBudgetLimit(0);
                  setStreamConfigs({ budgetLimit: 0 });
                }}
              />
            </HoverableTooltip>
            {budgetRatio >= 100 && !budgetRefresh && (
              <HoverableTooltip content="Reactivate">
                <IconButton
                  name="refresh"
                  style={{ height: "1.2rem", width: "1.5rem" }}
                  onClick={() => {
                    setStreamConfigs({ budgetRefresh: true });
                  }}
                />
              </HoverableTooltip>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LimitationsStep;
