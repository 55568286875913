import React from "react";
import { classnames } from "Utils";
import "./ExpandableAnimation.styles.scss";

const ExpandableAnimation = ({ children, isOpen }) => {
  return (
    <div className={classnames(["animation-wrapper", isOpen && "open"])}>
      <div className="animation-content">{children}</div>
    </div>
  );
};

export default ExpandableAnimation;
