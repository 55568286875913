import React, { useState } from "react";
import { Icon } from "UI";

import "./SoftwareCard.styles.scss";

const SoftwareCard = (props) => {
  const {
    name,
    version,
    softwares,
    img,
    images,
    size,
    small,
    selected,
    disabled,
    error,
    onClick,
    wide,
    hideSize,
    hideText,
  } = props;

  const [showFullName, setShowFullName] = useState(null);

  const handleEnterKeyUp = (event) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      if (onClick) {
        onClick(event);
      }
    }
  };

  const getFullName = () => {
    if (softwares) {
      return softwares.map((sw) => <p key={sw.id}>{sw.attributes.name}</p>);
    }
    return (
      <>
        <p>{name}</p> <p>{version}</p>
      </>
    );
  };

  const softwareImage = images ? (
    <div className="software-images">
      {images.map((img, id) => (
        <img key={id} src={img} /> // eslint-disable-line
      ))}
    </div>
  ) : (
    <img className={showFullName ? "hidden" : ""} src={img} />
  );

  return (
    <div className="software-card-container">
      <div
        className={[
          "software-card",
          small && "small",
          selected && "selected",
          disabled && "disabled",
          wide && "wide",
          error && "shake",
        ]
          .filter(Boolean)
          .join(" ")}
        onClick={onClick}
        tabIndex={0}
        role="button"
        onKeyUp={handleEnterKeyUp}
      >
        {!small && (
          <Icon
            name="info"
            color="gray-5"
            onMouseEnter={() => setShowFullName(true)}
            onMouseLeave={() => setShowFullName(false)}
          />
        )}
        <div className="software">
          {softwareImage}
          {!hideText && (
            <>
              {showFullName ? (
                getFullName()
              ) : (
                <>
                  <p>{name}</p>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {small && !hideSize && <p className="software-size">{size} GB</p>}
    </div>
  );
};

export default SoftwareCard;
