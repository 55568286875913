import { REQUEST_STATUS } from "Constants/global.constants";
import * as PaymentConstants from "Constants/Dashboard/Payment.constants";
import * as LoginConstants from "Constants/Authentication/Login.constants";

const initialState = {
  getPaymentMethodsCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  setPreferredPaymentMethodCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  getPaymentClientSecretCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  createUserPaymentMethodCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    client_code: null,
  },
  deleteUserPaymentMethodCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    id: null,
    error: false,
    client_code: null,
  },
  approveUserPaymentCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  payment3DSecureCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    id: null,
    error: false,
    client_code: null,
  },
  startPaymentCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  addBalanceCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    client_code: null,
  },
};

export default function payment(state = initialState, action) {
  switch (action.type) {
    case PaymentConstants.GET_PAYMENT_METHODS_PENDING: {
      return getPaymentMethodsPending(state);
    }
    case PaymentConstants.GET_PAYMENT_METHODS_SUCCESS: {
      return getPaymentMethodsSuccess(state, action);
    }
    case PaymentConstants.GET_PAYMENT_METHODS_FAILURE: {
      return getPaymentMethodsFailure(state);
    }
    case PaymentConstants.SET_PREFERRED_PAYMENT_METHOD_PENDING: {
      return setPreferredPaymentMethodPending(state);
    }
    case PaymentConstants.SET_PREFERRED_PAYMENT_METHOD_SUCCESS: {
      return setPreferredPaymentMethodSuccess(state);
    }
    case PaymentConstants.SET_PREFERRED_PAYMENT_METHOD_FAILURE: {
      return setPreferredPaymentMethodFailure(state);
    }
    case PaymentConstants.GET_PAYMENT_CLIENT_SECRET_PENDING: {
      return getPaymentClientSecretPending(state);
    }
    case PaymentConstants.GET_PAYMENT_CLIENT_SECRET_SUCCESS: {
      return getPaymentClientSecretSuccess(state, action);
    }
    case PaymentConstants.GET_PAYMENT_CLIENT_SECRET_FAILURE: {
      return getPaymentClientSecretFailure(state);
    }
    case PaymentConstants.CREATE_USER_PAYMENT_METHOD_PENDING: {
      return createUserPaymentMethodPending(state);
    }
    case PaymentConstants.CREATE_USER_PAYMENT_METHOD_SUCCESS: {
      return createUserPaymentMethodSuccess(state);
    }
    case PaymentConstants.CREATE_USER_PAYMENT_METHOD_FAILURE: {
      return createUserPaymentMethodFailure(state, action);
    }
    case PaymentConstants.DELETE_USER_PAYMENT_METHOD_PENDING: {
      return deleteUserPaymentMethodPending(state, action);
    }
    case PaymentConstants.DELETE_USER_PAYMENT_METHOD_SUCCESS: {
      return deleteUserPaymentMethodSuccess(state);
    }
    case PaymentConstants.DELETE_USER_PAYMENT_METHOD_FAILURE: {
      return deleteUserPaymentMethodFailure(state, action);
    }
    case PaymentConstants.DELETE_USER_PAYMENT_METHOD_RESET: {
      return deleteUserPaymentMethodReset(state);
    }
    case PaymentConstants.APPROVE_USER_PAYMENT_METHOD_PENDING: {
      return approveUserPaymentPending(state, action);
    }
    case PaymentConstants.APPROVE_USER_PAYMENT_METHOD_SUCCESS: {
      return approveUserPaymentSuccess(state);
    }
    case PaymentConstants.APPROVE_USER_PAYMENT_METHOD_FAILURE: {
      return approveUserPaymentFailure(state, action);
    }
    case PaymentConstants.START_PAYMENT_PENDING: {
      return startPaymentPending(state, action);
    }
    case PaymentConstants.START_PAYMENT_SUCCESS: {
      return startPaymentSuccess(state);
    }
    case PaymentConstants.START_PAYMENT_FAILURE: {
      return startPaymentFailure(state, action);
    }
    case PaymentConstants.PAYMENT_3DSECURE_REQUESTED: {
      return payment3DSecureRequested(state, action);
    }
    case PaymentConstants.PAYMENT_3DSECURE_PENDING: {
      return payment3DSecurePending(state, action);
    }
    case PaymentConstants.PAYMENT_3DSECURE_SUCCESS: {
      return payment3DSecureSuccess(state);
    }
    case PaymentConstants.PAYMENT_3DSECURE_FAILURE: {
      return payment3DSecureFailure(state, action);
    }
    case PaymentConstants.ADD_BALANCE_PENDING: {
      return addBalancePending(state, action);
    }
    case PaymentConstants.ADD_BALANCE_SUCCESS: {
      return addBalanceSuccess(state);
    }
    case PaymentConstants.ADD_BALANCE_FAILURE: {
      return addBalanceFailure(state, action);
    }
    case PaymentConstants.ADD_BALANCE_RESET: {
      return addBalanceReset(state);
    }
    case LoginConstants.LOGOUT_API_PENDING:
      return logout();
    default: {
      return state;
    }
  }
}

function logout() {
  return initialState;
}

function getPaymentMethodsPending(state) {
  return {
    ...state,
    getPaymentMethodsCTX: {
      ...state.getPaymentMethodsCTX,
      status: REQUEST_STATUS.PENDING,
    },
    // createUserPaymentMethodCTX: {
    //   ...state.createUserPaymentMethodCTX,
    //   status: REQUEST_STATUS.NOT_DEFINED,
    //   error: false,
    // },
  };
}

function getPaymentMethodsSuccess(state, action) {
  return {
    ...state,
    getPaymentMethodsCTX: {
      ...state.getPaymentMethodsCTX,
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload.payment_methods,
    },
  };
}

function getPaymentMethodsFailure(state) {
  return {
    ...state,
    getPaymentMethodsCTX: {
      ...state.getPaymentMethodsCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function setPreferredPaymentMethodPending(state) {
  return {
    ...state,
    setPreferredPaymentMethodCTX: {
      ...state.setPreferredPaymentMethodCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function setPreferredPaymentMethodSuccess(state) {
  return {
    ...state,
    setPreferredPaymentMethodCTX: {
      ...state.setPreferredPaymentMethodCTX,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function setPreferredPaymentMethodFailure(state) {
  return {
    ...state,
    setPreferredPaymentMethodCTX: {
      ...state.setPreferredPaymentMethodCTX,
      status: REQUEST_STATUS.FAILURE,
    },
  };
}

function getPaymentClientSecretPending(state) {
  return {
    ...state,
    getPaymentClientSecretCTX: {
      ...state.getPaymentClientSecretCTX,
      status: REQUEST_STATUS.PENDING,
      data: null,
    },
    createUserPaymentMethodCTX: {
      ...state.createUserPaymentMethodCTX,
      status: REQUEST_STATUS.NOT_DEFINED,
      error: false,
    },
  };
}

function getPaymentClientSecretSuccess(state, action) {
  return {
    ...state,
    getPaymentClientSecretCTX: {
      ...state.getPaymentClientSecretCTX,
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload.attributes,
    },
  };
}

function getPaymentClientSecretFailure(state) {
  return {
    ...state,
    getPaymentClientSecretCTX: {
      ...state.getPaymentClientSecretCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function createUserPaymentMethodPending(state) {
  return {
    ...state,
    createUserPaymentMethodCTX: {
      ...state.createUserPaymentMethodCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function createUserPaymentMethodSuccess(state) {
  return {
    ...state,
    createUserPaymentMethodCTX: {
      ...state.createUserPaymentMethodCTX,
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function createUserPaymentMethodFailure(state, action) {
  return {
    ...state,
    createUserPaymentMethodCTX: {
      ...state.createUserPaymentMethodCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
      client_code: action.payload.client_code,
    },
  };
}

function deleteUserPaymentMethodPending(state, action) {
  return {
    ...state,
    deleteUserPaymentMethodCTX: {
      ...state.deleteUserPaymentMethodCTX,
      status: REQUEST_STATUS.PENDING,
      id: action.payload.methodID,
    },
  };
}

function deleteUserPaymentMethodSuccess(state) {
  return {
    ...state,
    deleteUserPaymentMethodCTX: {
      ...state.deleteUserPaymentMethodCTX,
      status: REQUEST_STATUS.SUCCESS,
      id: null,
    },
  };
}

function deleteUserPaymentMethodFailure(state, action) {
  return {
    ...state,
    deleteUserPaymentMethodCTX: {
      ...state.deleteUserPaymentMethodCTX,
      status: REQUEST_STATUS.FAILURE,
      id: null,
      client_code: action.payload.client_code,
    },
  };
}

function deleteUserPaymentMethodReset(state) {
  return {
    ...state,
    deleteUserPaymentMethodCTX: {
      status: REQUEST_STATUS.NOT_DEFINED,
      id: null,
      error: false,
      client_code: null,
    },
  };
}

function approveUserPaymentPending(state) {
  return {
    ...state,
    approveUserPaymentCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function approveUserPaymentSuccess(state) {
  return {
    ...state,
    approveUserPaymentCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function approveUserPaymentFailure(state) {
  return {
    ...state,
    approveUserPaymentCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function startPaymentPending(state) {
  return {
    ...state,
    startPaymentCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function startPaymentSuccess(state) {
  return {
    ...state,
    startPaymentCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function startPaymentFailure(state) {
  return {
    ...state,
    startPaymentCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function payment3DSecureRequested(state, action) {
  return {
    ...state,
    payment3DSecureCTX: {
      ...state.payment3DSecureCTX,
      status: REQUEST_STATUS.PENDING,
      data: action.payload.data,
      completeEndpoint: action.payload.completeEndpoint,
      successType: action.payload.successType,
      successMessage: action.payload.successMessage,
      failureType: action.payload.failureType,
    },
  };
}

function payment3DSecurePending(state) {
  return {
    ...state,
    payment3DSecureCTX: {
      ...state.payment3DSecureCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function payment3DSecureSuccess(state) {
  return {
    ...state,
    payment3DSecureCTX: {
      ...state.payment3DSecureCTX,
      status: REQUEST_STATUS.SUCCESS,
    },
  };
}

function payment3DSecureFailure(state, action) {
  return {
    ...state,
    payment3DSecureCTX: {
      ...state.payment3DSecureCTX,
      status: REQUEST_STATUS.FAILURE,
      client_code: action.client_code,
    },
  };
}

function addBalancePending(state) {
  return {
    ...state,
    addBalanceCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function addBalanceSuccess(state) {
  return {
    ...state,
    addBalanceCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function addBalanceFailure(state, action) {
  return {
    ...state,
    addBalanceCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
      client_code: action.payload.client_code,
    },
  };
}
function addBalanceReset(state) {
  return {
    ...state,
    addBalanceCTX: {
      status: REQUEST_STATUS.NOT_DEFINED,
      error: false,
      client_code: null,
    },
    payment3DSecureCTX: {
      status: REQUEST_STATUS.NOT_DEFINED,
      id: null,
      error: false,
      client_code: null,
    },
  };
}
