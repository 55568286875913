import React from "react";
import { Icon } from "UI";
import { classnames } from "Utils";
import "./Input.style.scss";

const Input = (props) => {
  const {
    label,
    disabled,
    placeholder,
    inputValue,
    value,
    onChange,
    onIconClick,
    icon,
    textarea,
    centered,
    white,
    big,
    hasError,
    errorText,
    className,
    inputRef,
    color,
    rightContent,
    maxLength,
    ...rest
  } = props;

  const inputClassName = classnames([
    "vg-input",
    className,
    hasError && "error",
    (icon || hasError) && "iconed",
    white && "white",
    centered && "centered",
    big && "big",
    textarea && "textarea",
    disabled && "disabled",
    color,
  ]);

  const inputFieldValue = inputValue !== undefined ? inputValue : value;

  return (
    <div className="vg-input">
      <div className="input-wrapper">
        {label && <span className="label">{label}</span>}
        <div className="input-line">
          {textarea ? (
            <textarea
              ref={inputRef}
              className={inputClassName}
              disabled={disabled}
              placeholder={placeholder}
              value={inputFieldValue}
              onChange={onChange}
              maxLength={maxLength}
              {...rest}
            />
          ) : (
            <input
              ref={inputRef}
              className={inputClassName}
              disabled={disabled}
              placeholder={placeholder}
              value={inputFieldValue}
              onChange={onChange}
              maxLength={maxLength}
              {...rest}
            />
          )}

          {hasError ? (
            <>
              <Icon className="hide-on-mobile shake" name="error" color="purple-main" onClick={onIconClick} small />
              {errorText && <div className="error-wrapper hide-on-mobile">{errorText}</div>}
            </>
          ) : (
            icon && <Icon name={icon} onClick={onIconClick} small />
          )}
        </div>
        {hasError && <div className="error-wrapper-mobile">{errorText}</div>}
      </div>
    </div>
  );
};

export default Input;
