import * as AccountConstants from "Constants/Account.constants";

export const getAccountAPI = (accessToken) => ({
  type: AccountConstants.GET_ACCOUNT_API_PENDING,
  payload: { accessToken },
});

export const getAccountPredefinedAPI = (accessToken) => ({
  type: AccountConstants.GET_ACCOUNT_PREDEFINED_API_PENDING,
  payload: { accessToken },
});

export const applyPromoCodeAPI = (accessToken, code) => ({
  type: AccountConstants.APPLY_PROMOCODE_API_PENDING,
  payload: { accessToken, code },
});

export const setUserInterestsAPI = (interest) => {
  return {
    type: AccountConstants.SET_USER_PROFILE_API_PENDING,
    payload: { interests: [interest] },
  };
};

export const setUserMultiGpuConsentAPI = (multiGpuConsent) => {
  return {
    type: AccountConstants.SET_USER_PROFILE_API_PENDING,
    payload: { multiGpuConsent },
  };
};

export const setUserRememberMachineTypeAPI = (rememberMachineType) => {
  return {
    type: AccountConstants.SET_USER_PROFILE_API_PENDING,
    payload: { rememberMachineType },
  };
};

export const changePasswordAPI = (accessToken, changePasswordForm) => {
  const requestPayload = {
    current_password: changePasswordForm.currentPassword,
    new_password: changePasswordForm.newPassword,
    new_password_confirmation: changePasswordForm.newPasswordConfirmation || changePasswordForm.newPassword,
  };

  return {
    type: AccountConstants.CHANGE_PASSWORD_API_PENDING,
    payload: { accessToken, requestPayload },
  };
};

export const getUserPaymentHistoryAPI = (accessToken) => ({
  type: AccountConstants.GET_USER_PAYMENT_HISTORY_PENDING,
  payload: { accessToken },
});

export const getTransactionHistoryAPI = (accessToken) => ({
  type: AccountConstants.GET_TRANSACTION_HISTORY_PENDING,
  payload: { accessToken },
});

export const getFailedPaymentAPI = (accessToken) => ({
  type: AccountConstants.GET_FAILED_PAYMENT_PENDING,
  payload: { accessToken },
});

export const getMachineUsageHistoryAPI = (accessToken) => ({
  type: AccountConstants.GET_MACHINE_USAGE_HISTORY_PENDING,
  payload: { accessToken },
});

export const resendConfirmationEmailAPI = (accessToken) => ({
  type: AccountConstants.RESEND_CONFIRMATION_EMAIL_API_PENDING,
  payload: { accessToken },
});

export const getProductOnboardingAPI = (accessToken) => ({
  type: AccountConstants.GET_PRODUCT_ONBOARDING_PENDING,
  payload: { accessToken },
});
