import { all, put, takeLatest } from "redux-saga/effects";

import apiGenerator from "Utils/Helpers/api.helpers";
import {
  getStatusCodeFamily,
  apiErrorHandler,
} from "Utils/Helpers/saga.helpers";

import * as PlansConstants from "Constants/Dashboard/Plans.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";

function* getPlansAPISaga(action) {
  const api = apiGenerator("get")(API_ENDPOINTS.PLANS);
  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: PlansConstants.GET_PLANS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    yield put({
      type: PlansConstants.GET_PLANS_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

export default function* root() {
  yield all([takeLatest(PlansConstants.GET_PLANS_PENDING, getPlansAPISaga)]);
}
