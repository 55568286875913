import React from "react";
import { Translate } from "react-localize-redux";
import panelImage from "Assets/images/vg-authentication-left-panel.jpg";
import panelReferralImage from "Assets/images/vg-authentication-referral.jpg";
import panelTeamsImage from "Assets/images/vg-authentication-teams.jpg";
import panelAppStreamingImage from "Assets/images/appStreaming/login-left-panel.jpg";
import { LOCAL_STORAGE } from "Constants/global.constants";
import { getItemFromLocalStorage, classnames } from "Utils";
import { isVendorAccount } from "Utils/Helpers/saga.helpers";

const SideImage = (props) => {
  const { showAuthor } = props;

  let leftPanelImage = getItemFromLocalStorage(LOCAL_STORAGE.referralCode) ? panelReferralImage : panelImage;

  if (window.location.hash === "#teams") {
    leftPanelImage = panelTeamsImage;
  }

  const isAppStreaming = isVendorAccount();

  if (isAppStreaming) {
    leftPanelImage = panelAppStreamingImage;
  }

  return (
    <div className={classnames(["side-image-container", isVendorAccount() && "app-streaming"])}>
      {isAppStreaming && <Translate id="layout.sideImage.appStreaming" options={{ renderInnerHtml: true }} />}
      <img alt="Vagon" className="side-image" src={leftPanelImage} />
      {showAuthor && (
        <div className="side-image-title">
          <Translate id="layout.sideImage.title" />
          <br />
          <span className="author-title">Tom Dixon,</span>
          <br />
          Furniture Designer
        </div>
      )}
    </div>
  );
};

export default SideImage;
