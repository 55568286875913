import React, { useState } from "react";
import { Translate } from "react-localize-redux";

import "./PasswordInput.style.scss";
import { Input } from "UI";
import zxcvbn from "zxcvbn";
import { INPUT_LIMITS } from "Constants/global.constants";

const PASSWORD_STRENGTH_TEXTS = (strength) => {
  if (!strength) {
    return "";
  }

  return <Translate id={`passwordInput.strength.${strength}`} />;
};

const PasswordInput = (props) => {
  const {
    label,
    disabled,
    icon,
    type,
    hasError,
    onChange,
    errorText,
    value,
    showStrength,
    setIsStrongPassword,
    resetErrors,
    overrideIcon,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [passwordForm, setPasswordForm] = React.useState({
    status: "",
    error: false,
    errorMessage: "",
  });

  const onIconClick = () => {
    setShowPassword(!showPassword);
  };

  const checkPasswordEntropy = (password) => {
    let status;
    let error = false;
    if (password) {
      const result = zxcvbn(password);
      if (result.score < 2 || password.length < 8) {
        // Password is too weak
        error = true;
        status = "weak";
      } else if (result.score === 2) {
        status = "average";
      } else {
        // Password entropy is enough
        status = "strong";
      }
    }
    if (setIsStrongPassword) {
      setIsStrongPassword(!error);
    }

    setPasswordForm({
      status,
      error,
      errorMessage: "",
    });
  };

  const iconName = showPassword ? "eye-closed" : "eye-open";

  return (
    <div className="password-input-wrapper">
      <Input
        className={value ? "" : "empty"}
        disabled={disabled}
        type={showPassword ? "text" : "password"}
        icon={overrideIcon || value ? iconName : ""}
        onChange={(event) => {
          onChange(event);
          if (showStrength) {
            checkPasswordEntropy(event.target.value);
          }
        }}
        value={value}
        onIconClick={onIconClick}
        hasError={hasError}
        errorText={errorText}
        label={label}
        maxLength={INPUT_LIMITS.password}
        autoComplete="current-password"
        onFocus={() => {
          if (hasError && resetErrors) {
            resetErrors();
          }
        }}
        {...rest}
      />
      {showStrength && (
        <div className="password-strength">
          <div className={`password-strength-bar ${passwordForm.status}`} />
          <span>{PASSWORD_STRENGTH_TEXTS(passwordForm.status)}</span>
        </div>
      )}
    </div>
  );
};

export default PasswordInput;
