import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, NumberInput } from "UI";
import WorkstationCreditUsagesComponent from "./WorkstationUsages/WorkstationUsages.component";

import "./CreditCalculatorModal.styles.scss";

const CreditCalculatorModal = (props) => {
  const { teams, setRecommendedCredit, setShowCalculatorModal, translate } = props;

  const [usageHours, setUsageHours] = useState(0);
  const [selectedMachineId, setSelectedMachineId] = useState();
  const [computerPriceByHour, setComputerPriceByHour] = useState();

  const teamConfig = teams.createTeamConfigCTX;
  const machineTypes = teams?.getTeamSubscriptionPaymentInformationCTX?.data?.plan?.attributes?.machine_types || [];
  const { seats } = teamConfig;

  const calculatedPrice = usageHours * computerPriceByHour;

  const onCancel = () => {
    setShowCalculatorModal(false);
  };

  const priceForMember = () => {
    if (calculatedPrice >= 1000) {
      return `${(calculatedPrice / 1000).toFixed(2)}k`;
    }
    return calculatedPrice;
  };

  const priceForTeam = () => {
    return `${((calculatedPrice * seats).toFixed(1) / 1000).toFixed(2)}k`;
  };

  const recommendedCredit = (calculatedPrice) => {
    if (calculatedPrice > 20000) {
      const rounded = Math.round(calculatedPrice / 10000) * 10000;
      if (rounded >= calculatedPrice) {
        setRecommendedCredit(rounded);
      } else {
        setRecommendedCredit(rounded + 5000);
      }
    } else if (calculatedPrice > 15000) {
      setRecommendedCredit(20000);
    } else if (calculatedPrice > 10000) {
      setRecommendedCredit(15000);
    } else if (calculatedPrice > 5000) {
      setRecommendedCredit(10000);
    } else {
      setRecommendedCredit(5000);
    }
  };

  return (
    <Modal
      className="team-create-calculator-modal"
      headerText={translate("team.form.credit.calculatorModal.header")}
      headerColor="gray-1"
      descriptionText={translate("team.form.credit.calculatorModal.description")}
      closeOnOverlayClick
      closeAction={onCancel}
      topRightIcon="close"
      topRightIconAction={onCancel}
      buttonText={
        !calculatedPrice ? (
          translate("team.form.credit.calculatorModal.button")
        ) : (
          <>
            <p>{priceForMember()} for a member</p>
            <p>
              {priceForTeam()} for {seats} members
            </p>
          </>
        )
      }
      buttonAction={() => {
        recommendedCredit(calculatedPrice * seats);
        setShowCalculatorModal(false);
      }}
      buttonDisabled={!calculatedPrice}
      centeredContent
    >
      <div className="calculator-container">
        <div className="hours-text-input">
          <NumberInput value={usageHours} onChange={(hours) => setUsageHours(hours)} centered />
          <p>hours per seat</p>
        </div>
        <WorkstationCreditUsagesComponent
          usages={machineTypes}
          setComputerPriceByHour={setComputerPriceByHour}
          setSelectedMachineId={setSelectedMachineId}
          selectedMachineId={selectedMachineId}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  teams: state.teams,
});

export default connect(mapStateToProps, null)(CreditCalculatorModal);
