import { REQUEST_STATUS } from "Constants/global.constants";
import * as PlansConstants from "Constants/Dashboard/Plans.constants";
import * as LoginConstants from "Constants/Authentication/Login.constants";

const initialState = {
  data: null,
  status: REQUEST_STATUS.NOT_DEFINED,
  error: false,
};

export default function plansCTX(state = initialState, action) {
  switch (action.type) {
    case PlansConstants.GET_PLANS_PENDING: {
      return getPlansPending(state);
    }
    case PlansConstants.GET_PLANS_SUCCESS: {
      return getPlansSuccess(state, action);
    }
    case PlansConstants.GET_PLANS_FAILURE: {
      return getPlansFailure(state);
    }
    case LoginConstants.LOGOUT_API_PENDING:
      return logout();
    default: {
      return state;
    }
  }
}

function logout() {
  return initialState;
}

function getPlansPending(state) {
  return {
    ...state,
    status: REQUEST_STATUS.PENDING,
  };
}

function getPlansSuccess(state, action) {
  return {
    ...state,
    status: REQUEST_STATUS.SUCCESS,
    data: action.payload,
  };
}

function getPlansFailure(state) {
  return {
    ...state,
    status: REQUEST_STATUS.FAILURE,
    error: true,
  };
}
