import React from "react";
import { Translate } from "react-localize-redux";
import moment from "moment";
import {
  convertDateFormat,
  convertToSelectedTimeZone,
  joinWithCommaAnd,
  minutesToReadableHoursMinutes,
} from "Utils/Helpers/functions.helpers";
import { HoverableTooltip, Icon } from "UI";
import { useSelector } from "react-redux";
import { REGIONS_NAMES } from "Constants/global.constants";

const ExpandedObject = ({ machine, seat, plan }) => {
  const { machine_types: machineTypes } = plan?.attributes || {};
  const machineTypeNames = machineTypes?.map((machineType) => machineType.attributes.friendly_name);
  const region = machine?.attributes?.region;
  const { friendly_name: machineTypeName } = machine?.attributes?.machine_type?.attributes || {};
  const { current_session: currentSession } = machine?.attributes || {};
  const {
    subscription_end_at: subscriptionEndAt,
    remaining_usage: remainingUsage,
    deposited_usage: depositedUsage,
    renew: seatRenew,
    disk_size: diskSize,
    file_storage_size: fileStorageSize,
  } = seat?.attributes || {};

  const { renew: organizationSubscriptionRenew } =
    useSelector((state) => state.organizations.organization?.attributes?.subscription?.attributes) || {};
  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};

  const renew = seatRenew && organizationSubscriptionRenew;
  const remainingPlanUsage = remainingUsage - depositedUsage;

  return (
    <div className="expanded-object-info-container">
      <div className="expanded-content">
        <div className="header">
          Computer Details
          <HoverableTooltip
            side="right"
            content={
              renew ? (
                <Translate
                  id="organizationObjectsTable.expandedObjectInfo.computerDetails.renewalDate"
                  options={{
                    renderInnerHtml: true,
                  }}
                  data={{
                    date: subscriptionEndAt
                      ? convertToSelectedTimeZone(
                          `${convertDateFormat(subscriptionEndAt.substring(0, 10))} ${subscriptionEndAt.substring(
                            subscriptionEndAt.indexOf("T") + 1,
                            subscriptionEndAt.indexOf("T") + 6,
                          )}`,
                          userTimeZone,
                        )
                      : "",
                  }}
                />
              ) : (
                <Translate
                  id="organizationObjectsTable.expandedObjectInfo.computerDetails.deletedAt"
                  options={{
                    renderInnerHtml: true,
                  }}
                  data={{
                    date: subscriptionEndAt
                      ? convertToSelectedTimeZone(
                          `${convertDateFormat(subscriptionEndAt.substring(0, 10))} ${subscriptionEndAt.substring(
                            subscriptionEndAt.indexOf("T") + 1,
                            subscriptionEndAt.indexOf("T") + 6,
                          )}`,
                          userTimeZone,
                        )
                      : "",
                  }}
                />
              )
            }
          >
            <Icon name="info" small color={renew ? "gray-3" : "red"} />
          </HoverableTooltip>
        </div>

        <Translate
          id="organizationObjectsTable.expandedObjectInfo.computerDetails.availablePerformances"
          options={{
            renderInnerHtml: true,
          }}
          data={{ machineTypeNames: machineTypeNames ? joinWithCommaAnd(machineTypeNames) : "" }}
        />
        <Translate
          id="organizationObjectsTable.expandedObjectInfo.computerDetails.region"
          options={{
            renderInnerHtml: true,
          }}
          data={{ region: REGIONS_NAMES[region] || "Not Assigned" }}
        />

        <Translate
          id="organizationObjectsTable.expandedObjectInfo.computerDetails.storage"
          options={{
            renderInnerHtml: true,
          }}
          data={{ diskSize, fileStorageSize }}
        />
      </div>
      <div>
        <div className="header">
          Usage Details
          <HoverableTooltip
            side="right"
            content={
              <Translate
                id="organizationObjectsTable.expandedObjectInfo.computerDetails.usageDetailInfo"
                options={{
                  renderInnerHtml: true,
                }}
                data={{
                  date: subscriptionEndAt
                    ? convertToSelectedTimeZone(
                        `${convertDateFormat(subscriptionEndAt.substring(0, 10))} ${subscriptionEndAt.substring(
                          subscriptionEndAt.indexOf("T") + 1,
                          subscriptionEndAt.indexOf("T") + 6,
                        )}`,
                        userTimeZone,
                      )
                    : "",
                }}
              />
            }
          >
            <Icon name="info" small color="gray-3" />
          </HoverableTooltip>
        </div>
        {remainingPlanUsage && remainingPlanUsage > 0 ? (
          <Translate
            id="organizationObjectsTable.expandedObjectInfo.computerDetails.remainingPlanUsage"
            options={{
              renderInnerHtml: true,
            }}
            data={{
              hours: minutesToReadableHoursMinutes(remainingPlanUsage),
              machineTypeName: machineTypeName || "Star",
            }}
          />
        ) : (
          <Translate
            id="organizationObjectsTable.expandedObjectInfo.computerDetails.noUsageLeft"
            options={{
              renderInnerHtml: true,
            }}
          />
        )}
        {depositedUsage && depositedUsage > 0 ? (
          <Translate
            id="organizationObjectsTable.expandedObjectInfo.computerDetails.additionalUsage"
            options={{
              renderInnerHtml: true,
            }}
            data={{
              hours: minutesToReadableHoursMinutes(depositedUsage),
              machineTypeName: machineTypeName || "Star",
            }}
          />
        ) : (
          <Translate
            id="organizationObjectsTable.expandedObjectInfo.computerDetails.noAdditionalUsage"
            options={{
              renderInnerHtml: true,
            }}
          />
        )}
        {currentSession && (
          <Translate
            id="organizationObjectsTable.expandedObjectInfo.computerDetails.lastSession"
            options={{
              renderInnerHtml: true,
            }}
            data={{
              date: currentSession.attributes.start_at
                ? convertToSelectedTimeZone(
                    `${convertDateFormat(
                      currentSession.attributes.start_at.substring(0, 10),
                    )} ${currentSession.attributes.start_at.substring(
                      currentSession.attributes.start_at.indexOf("T") + 1,
                      currentSession.attributes.start_at.indexOf("T") + 6,
                    )}`,
                    userTimeZone,
                  )
                : "",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ExpandedObject;
