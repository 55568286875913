import React, { useEffect } from "react";
import Landing from "Components/Organization/Admin/Landing/Landing.component";
import { useDispatch, useSelector } from "react-redux";

import { getOrganizationAPI } from "Actions/Organization.actions";
import routes from "Constants/Route.constants";
import { REQUEST_STATUS } from "Constants/global.constants";
import { getAccountAPI } from "Actions/Account.actions";

const OrganizationContainer = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const { account } = useSelector((state) => state.account);
  const { accessType, getOrganizationCTX } = useSelector((state) => state.organizations);

  const { organization_invitation_present: organizationInvitationPresent } = account.attributes;

  useEffect(() => {
    dispatch(getOrganizationAPI());
    dispatch(getAccountAPI());
  }, []);

  useEffect(() => {
    if (organizationInvitationPresent) return;

    if (!organizationInvitationPresent && ["owner", "admin"].includes(accessType)) {
      history.push(routes.organizationAdminHome);
    }
  }, [accessType, organizationInvitationPresent]);

  if (getOrganizationCTX.status && getOrganizationCTX.status !== REQUEST_STATUS.PENDING && !accessType) {
    return <Landing {...props} />;
  }

  if (organizationInvitationPresent) {
    return <Landing {...props} />;
  }

  return null;
};

export default OrganizationContainer;
