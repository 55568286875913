import { getLocationAPI } from "Actions/Dashboard/Regions.actions";
import React, { useEffect, useState } from "react";

import { ConfirmationModal, RegionChangeModal, WorldMap } from "UI";
import { DashboardSubScreenLayout, apiGenerator } from "Utils";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { ERROR_CODE, WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";

import "./MigrateRegion.styles.scss";

const MigrateRegion = ({ workstation, translate, onClose }) => {
  const [newRegion, setNewRegion] = useState();
  const [showMigrateRegionConfirmation, setShowMigrateRegionConfirmationModal] = useState(false);
  const [balanceError, setBalanceError] = useState(false);

  const { region, friendly_status: friendlyStatus } = workstation?.attributes?.machine?.attributes || {};
  const location = useSelector((state) => state.regionsCTX.locationCTX.data);

  const { data: regionChangePriceData } = useQuery({
    queryKey: API_ENDPOINTS.WORKSTATION_CHANGE_REGION_PRICE,
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.WORKSTATION_CHANGE_REGION_PRICE);
    },
  });

  const { balance_enough: balanceEnough, price } = regionChangePriceData?.data || {};

  const changeRegionMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("post")(API_ENDPOINTS.WORKSTATION_CHANGE_REGION, {
        region: newRegion,
      });
    },
    onSuccess: () => {
      setNewRegion(null);
      onClose();
    },
    onError: (error) => {
      if (ERROR_CODE.BALANCE_INSUFFICIENT === error?.response?.data?.client_code) {
        setBalanceError(true);
        setTimeout(() => {
          setBalanceError(false);
          changeRegionMutation.reset();
        }, 3000);
      }
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocationAPI());
  }, []);

  const descriptionText =
    friendlyStatus === WORKSTATION_FRIENDLY_STATUS.READY
      ? translate("migrateRegion.confirmationModal.description.machineRunning", {
          price,
        })
      : translate("migrateRegion.confirmationModal.description.machineStopped", {
          price,
        });

  const balanceNotEnoughText =
    !balanceEnough && translate("migrateRegion.confirmationModal.description.balanceNotEnough");

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("migrateRegion.header.title")}
      headerDescription={translate("migrateRegion.header.description")}
      noScroll
      showCloseIcon
      onClose={onClose}
    >
      <div className="migrate-region-container">
        <WorldMap
          region={newRegion || region}
          onRegionClick={(r) => {
            setNewRegion(r);
          }}
          country={location}
        />

        {newRegion && (
          <RegionChangeModal
            onClose={() => {
              setNewRegion(null);
            }}
            onClick={() => {
              setShowMigrateRegionConfirmationModal(true);
            }}
            buttonDisabled={!newRegion || newRegion === region || changeRegionMutation.isLoading}
            translate={translate}
            region={region}
            selectedRegion={newRegion}
          />
        )}
      </div>
      {showMigrateRegionConfirmation && (
        <ConfirmationModal
          className="migrate-region-confirmation-modal"
          headerText={translate("migrateRegion.confirmationModal.header")}
          descriptionText={
            <>
              <p>{descriptionText}</p>
              {balanceError && <p className="error">{balanceNotEnoughText}</p>}
            </>
          }
          confirmText="Confirm"
          confirmAction={() => {
            changeRegionMutation.mutate();
          }}
          closeAction={() => {
            setShowMigrateRegionConfirmationModal(false);
          }}
          secondaryText={translate("accountSettings.resetComputer.confirm.secondary")}
          secondaryAction={() => {
            setShowMigrateRegionConfirmationModal(false);
          }}
          disableConfirmButton={changeRegionMutation.isLoading || changeRegionMutation.isError || balanceError}
          closeOnOverlayClick
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default MigrateRegion;
