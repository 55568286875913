import React from "react";

import { ConfirmationModal } from "UI";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useAPIRequest } from "Utils";

const FileDuplicationModal = (props) => {
  const { file, translate, cancelFileUpload, uploadFilesToFolder, currentFolderId } = props;

  // ToDo: Edge case when user delete one of the duplicate files
  const { data: fileStatData } = useAPIRequest({
    type: "post",
    endpoint: API_ENDPOINTS.GET_FILE_STAT,
    body: { file_name: file.name, parent_id: currentFolderId },
  });

  const handleKeepBothFiles = () => {
    // Generate new file name with number
    const nameParts = file.name.split(".");
    const nameWithoutExt = nameParts.slice(0, -1).join(".");
    const extension = nameParts[nameParts.length - 1];
    const newFileName = `${nameWithoutExt} (${fileStatData.file_count}).${extension}`;

    // Copy file data and add fileName field, since name field can not be updated
    const newFile = file;
    newFile.fileName = newFileName;
    uploadFilesToFolder({ files: [newFile], currentFolderId });
    cancelFileUpload(file.name);
  };

  const handleReplaceFiles = () => {
    cancelFileUpload(file.name);
    uploadFilesToFolder({ files: [file], currentFolderId, overwrite: true });
  };

  const handleCancel = () => {
    cancelFileUpload(file.name);
  };

  return (
    <ConfirmationModal
      closeOnOverlayClick
      closeAction={handleCancel}
      headerText={translate("modals.transferExistingFile.confirm.header")}
      descriptionText={`${file.name} ${translate("modals.transferExistingFile.confirm.description")}`}
      confirmText={translate("modals.transferExistingFile.confirm.keepBoth")}
      confirmAction={handleKeepBothFiles}
      secondaryConfirmText={translate("modals.transferExistingFile.confirm.replace")}
      secondaryConfirmAction={handleReplaceFiles}
      secondaryText={translate("modals.transferExistingFile.confirm.secondary")}
      secondaryAction={handleCancel}
    />
  );
};

export default FileDuplicationModal;
