import React, { useState, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getSeatsMeAPI } from "Actions/Organization.actions";
import { API_ENDPOINTS } from "Constants/api.constants";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";
import { minutesToReadableHoursMinutes, pollingIntervalByFriendlyStatus } from "Utils/Helpers/functions.helpers";
import { useDispatch, useSelector } from "react-redux";
import routes from "Constants/Route.constants";
import { ORGANIZATION_SEAT_STATUSES } from "Constants/Organization.constants";
import { Loader, classnames, getItemFromLocalStorage, isElectronApp, isTablet, saveItemToLocalStorage } from "Utils";
import AppDownloadAnnouncement from "Components/Dashboard/Home/AppDownloadAnnouncement/AppDownloadAnnouncement";
import { capitalize } from "lodash";
import { Icon } from "UI";
import apiGenerator from "Utils/Helpers/api.helpers";
import UsageBar from "./UsageBar/UsageBar.component";
import ComputerOverview from "../Components/ComputerOverview.component";
import ComputerPerformance from "../Components/ComputerPerformance/ComputerPerformance.component";
import ComputerActionButton from "../Components/ComputerActionButton.component";
import ComputerProgressBar from "../Components/ComputerProgressBar.component";

import "./Home.styles.scss";

const Home = ({ history, translate }) => {
  const dispatch = useDispatch();
  const [friendlyStatus, setFriendlyStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { seat, status: getSeatsMeCTXStatus } = useSelector((state) => state.organizations.getSeatsMeCTX) || {};
  const { account } = useSelector((state) => state);
  const planName = seat?.attributes?.plan?.attributes?.name;
  const machineId = seat?.attributes?.machine?.id;

  const [showMachineTypeSelection, setShowMachineTypeSelection] = useState(false);
  const [showAppDownloadAnnouncement, setShowAppDownloadAnnouncement] = useState(
    !isElectronApp && getItemFromLocalStorage(LOCAL_STORAGE.showAppDownloadAnnouncement, true) && !isTablet,
  );

  const refetchMachineInterval = useMemo(() => {
    return pollingIntervalByFriendlyStatus(friendlyStatus);
  }, [friendlyStatus]);

  const { data, refetch: refetchMachine } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_MACHINES(machineId)],
    queryFn: () => apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_MACHINES(machineId)),
    enabled: Boolean(machineId),
    refetchInterval: refetchMachineInterval,
    onSuccess: (data) => {
      setIsLoading(false);
      setFriendlyStatus(data?.data?.attributes?.friendly_status);
    },
  });

  const machine = data?.data;
  const machineTypeName = machine?.attributes?.machine_type?.attributes?.friendly_name;

  useEffect(() => {
    if (!machine) {
      setFriendlyStatus(null);
    }
  }, [machine]);

  const { refetch: refetchSeat } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_SEATS_ME],
    queryFn: () => dispatch(getSeatsMeAPI()),
  });

  useEffect(() => {
    if (getSeatsMeCTXStatus === REQUEST_STATUS.FAILURE) {
      setIsLoading(false);
    }
  }, [getSeatsMeCTXStatus]);

  const setMachineTypeMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_MACHINE_TYPE(machine.id), data);
    },
    onSuccess: () => {
      refetchSeat();
      refetchMachine();
      setShowMachineTypeSelection(false);
    },
  });

  const {
    remaining_usage: remainingUsage,
    maximum_usage: maximumUsage,
    deposited_usage: depositedUsage,
    regions,
    status: seatStatus,
  } = seat?.attributes || {};

  const paymentRequired = seatStatus === ORGANIZATION_SEAT_STATUSES.paymentRequired;

  if (showMachineTypeSelection) {
    return (
      <ComputerPerformance
        setShowMachineTypeSelection={setShowMachineTypeSelection}
        machineTypesUsages={seat?.attributes?.machine_types_usages}
        selectedMachineType={machine?.attributes?.machine_type?.id}
        handleSave={(machineTypeId) => {
          setMachineTypeMutation.mutate({ machine_type_id: machineTypeId });
        }}
        translate={translate}
      />
    );
  }

  if (isLoading) return <Loader />;

  return (
    <div className="organization-member-home">
      <div className="vg-banner-container">
        <div className={classnames(["vg-banner", "other", "bg"])}>
          <div className="computer-name">
            <p>{machine?.attributes?.name || "Team Computer"}</p>
            <div className="teams-badge">TEAMS</div>
          </div>
          <ComputerProgressBar friendlyStatus={friendlyStatus} translate={translate} />
          {!seat && (
            <div className="info-container">
              <Icon name="error" color="gray-3" />
              <p>A Team Computer has not been assigned, yet. Contact your Team Admin to start using Vagon.</p>
            </div>
          )}
        </div>
        <ComputerActionButton
          accessToken={account.accessToken}
          status={machine?.attributes?.status}
          friendlyStatus={paymentRequired ? seatStatus : friendlyStatus}
          machine={machine}
          isLoading={isLoading}
          refetchMachine={refetchMachine}
          onConnect={() => {
            history.push(routes.organizationMemberComputer);
          }}
          translate={translate}
        />
      </div>
      {showAppDownloadAnnouncement && !isElectronApp && (
        <AppDownloadAnnouncement
          handleAnnouncementClose={() => {
            setShowAppDownloadAnnouncement(false);
            saveItemToLocalStorage(LOCAL_STORAGE.showAppDownloadAnnouncement, false);
          }}
          translate={translate}
        />
      )}
      {seat && !paymentRequired && (
        <UsageBar
          plan={planName}
          totalUsage={maximumUsage + depositedUsage}
          firstUsage={remainingUsage - depositedUsage}
          secondUsage={depositedUsage}
          topLeftInfo={
            <span>
              Plan: <span>{seat?.attributes?.plan?.attributes?.name}</span>
            </span>
          }
          topRightInfo={
            remainingUsage > 0 &&
            `${minutesToReadableHoursMinutes(remainingUsage)} of ${capitalize(machineTypeName)} Usage`
          }
          firstUsageHoverContent="Plan Credits"
          secondUsageHoverContent="Additional Usages"
        />
      )}

      <ComputerOverview
        seat={seat}
        accessToken={account.accessToken}
        machine={machine}
        diskSize={seat?.attributes?.disk_size}
        regions={regions}
        refetchMachine={refetchMachine}
        machineUsageHistoryCTX={account.machineUsageHistoryCTX}
        account={account}
        setShouldShowStorageScreen={() => {}}
        setShowMachineTypeSelection={setShowMachineTypeSelection}
        isInGracePeriod={false}
        translate={translate}
      />
    </div>
  );
};

export default Home;
