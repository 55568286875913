import React, { useState } from "react";
import _ from "lodash";

import DashboardSubScreenLayout from "Utils/Components/Layouts/DashboardSubScreenLayout/DashboardSubScreenLayout.component";
import { Loader, saveItemToLocalStorage } from "Utils";
import { groupMachineTypes, normalizeCredits } from "Utils/Helpers/functions.helpers";
import { Button, RadioInput } from "UI";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";

import ComputerPerformanceRow from "./ComputerPerformanceRow.component";
import "./ComputerPerformance.style.scss";

const ComputerPerformance = (props) => {
  const {
    account,
    getWorkstationMachineTypesCTX,
    selectedMachineType,
    setSelectedMachineType,
    setShouldShowComputerPerformanceScreen,
    shouldSavePerformance,
    setShouldSavePerformance,
    setUserRememberMachineTypeAPI,
    translate,
  } = props;

  const balance = account.account.attributes.balance || 0;
  const credit = account.account.attributes.credit || 0;

  // Keep your own state and setter internally so that
  // the state in upper level component isn't updated
  // until save button is clicked.
  const [pseudoSelectedMachineType, setPseudoSelectedMachineType] = useState(selectedMachineType);

  const rememberMachineTypeChangeDebounceRef = React.useRef(
    _.debounce((shouldSavePerformance) => setUserRememberMachineTypeAPI(shouldSavePerformance), 1000),
  );

  if (getWorkstationMachineTypesCTX.status !== REQUEST_STATUS.SUCCESS) {
    return <Loader />;
  }

  const handleSave = () => {
    saveItemToLocalStorage(LOCAL_STORAGE.machineType, pseudoSelectedMachineType);
    setSelectedMachineType(pseudoSelectedMachineType);
    setShouldShowComputerPerformanceScreen(false);
    // setUserRememberMachineTypeAPI(account.accessToken, true);
    sessionStorage.setItem(LOCAL_STORAGE.machineType, pseudoSelectedMachineType);
  };

  const toggleShouldSavePerformance = () => {
    const shouldSavePerformanceToggled = !shouldSavePerformance;

    setShouldSavePerformance(shouldSavePerformanceToggled);

    rememberMachineTypeChangeDebounceRef.current(shouldSavePerformanceToggled);
  };

  const machineTypes = getWorkstationMachineTypesCTX.data.machine_types;

  const groupedMachineTypes = groupMachineTypes(machineTypes);

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("computerPerformance.header.title")}
      headerDescription={translate("computerPerformance.header.description")}
      balance={!account.isTeamAccount && balance}
      credit={account.isTeamAccount && normalizeCredits(credit)}
      balanceTitle={translate("usageHistory.balanceTitle")}
      translate={translate}
      showBalance
      showCloseIcon
      onClose={() => {
        setShouldShowComputerPerformanceScreen(false);
      }}
      actionContent={<Button block disabled={!selectedMachineType} text="Save" onClick={handleSave} />}
      actionInfo={
        <RadioInput
          checked={shouldSavePerformance}
          onChange={toggleShouldSavePerformance}
          label={translate("home.machineTypeSelection.radioLabelSaveSelection")}
        />
      }
    >
      <div className="vg-computer-performance">
        {["a10", "gpu", "cpu", "general"].map(
          (performanceType) =>
            groupedMachineTypes[performanceType] && (
              <ComputerPerformanceRow
                key={performanceType}
                performanceType={performanceType}
                machineTypes={groupedMachineTypes[performanceType]}
                selectedMachineType={pseudoSelectedMachineType}
                setSelectedMachineType={setPseudoSelectedMachineType}
                translate={translate}
              />
            ),
        )}
      </div>
    </DashboardSubScreenLayout>
  );
};

export default ComputerPerformance;
