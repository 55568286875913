import React, { useState } from "react";
import { Icon } from "UI";
import "./FileUploadArea.styles.scss";
import { Translate } from "react-localize-redux";
import { classnames } from "Utils";

const FileUploadArea = ({
  id,
  handleFileInput,
  isDragActive,
  dragAndDropText,
  icon = "file-drag",
  color = "purple-light",
  small,
  error,
  acceptedFiles,
  single,
  content,
  grayBorder,

  topRightContent,
  translate,
}) => {
  const componentClassName = classnames([
    "vg-file-drag-drop",
    color,
    small && "small",
    error && "error",
    isDragActive && "file-dragged",
    grayBorder && "gray-border",
  ]);

  const [value, setValue] = useState("");

  const inputId = `file-upload-input-${id}`;

  return (
    <div className={componentClassName}>
      <label className="file-upload-input-label" htmlFor={inputId}>
        <input
          type="file"
          id={inputId}
          name={inputId}
          value={value}
          multiple={!single}
          accept={acceptedFiles}
          onClick={() => {
            setValue("");
          }}
          onChange={(event) => {
            setValue(event.target.value);
            event.preventDefault();
            event.stopPropagation();
            handleFileInput(event);
          }}
        />
      </label>
      {content || (
        <>
          {<Icon name={icon} color={error ? "red" : color} />}
          {error ? (
            <p>
              <Translate
                id={`modals.fileUpload.error.${error}`}
                options={{ onMissingTranslation: () => translate("modals.fileUpload.error.default") }}
              />
            </p>
          ) : (
            <div className="file-drag-drop-desc">
              <p>
                {dragAndDropText || translate("modals.fileUpload.dragAndDrop")}{" "}
                <span className="file-upload-input-text">{translate("modals.fileUpload.browse")}</span>
              </p>
            </div>
          )}
        </>
      )}
      {topRightContent && <div className="top-right-content">{topRightContent}</div>}
    </div>
  );
};

export default FileUploadArea;
