import React, { useRef } from "react";
import Joi from "joi";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { COUNTRY_LIST_ALPHA } from "Constants/countries.constants";
import { Input, Dropdown } from "UI";
import { getItemFromLocalStorage, saveItemToLocalStorage } from "Utils";
import { removeItemFromLocalStorage } from "Utils/Helpers/storage.helpers";

import "./StripeForm.styles.scss";

const COUNTRY_LIST = Object.keys(COUNTRY_LIST_ALPHA);

const StripeCardCreateOptions = () => {
  return {
    style: {
      base: {
        fontSize: "14px",
        color: "#000",
        fontWeight: 300,
        fontFamily: "Inter, sans-serif",
        letterSpacing: "0.01rem",
        "::placeholder": {
          color: "#828282",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    hideIcon: true,
  };
};

const StripeForm = (props) => {
  const {
    account,
    setPending,
    setError,
    createUserPaymentMethodAPI,
    formRef,
    divided,
    showBillingSection,
    setShowBillingSection,
    setBrand,
    setIsCardInputComplete,
    setIsPaymentMethodFormComplete,
    setCardHolderName,
    translate,
  } = props;

  const cardHolderInputRef = useRef(null);
  const countryInputRef = useRef(null);

  const [billingDetails, setBillingDetails] = React.useState({
    name: "",
    addressLine: "",
    country: "",
    city: "",
  });

  const [errors, setErrors] = React.useState({
    nameError: "",
    addressLineError: "",
    countryError: "",
    cityError: "",
  });

  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    const cardHoldersName = getItemFromLocalStorage("cardHoldersName");
    if (cardHoldersName) {
      if (cardHoldersName === "undefined") {
        removeItemFromLocalStorage("cardHoldersName");
        return;
      }
      if (setCardHolderName) {
        setCardHolderName(cardHoldersName);
      }
      setBillingDetails({
        ...billingDetails,
        name: cardHoldersName,
      });
    }
  }, []); //eslint-disable-line

  React.useEffect(() => {
    if (setIsPaymentMethodFormComplete) {
      setIsPaymentMethodFormComplete(
        billingDetails.name && billingDetails.addressLine && billingDetails.country && billingDetails.city,
      );
    }
  }, [billingDetails]);

  const validate = () => {
    const nameError = Joi.string()
      .min(3)
      .max(30)
      .required()
      .pattern(/(?=^[^ '\-,.])[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/)
      .validate(billingDetails.name).error;
    const addressLineError = Joi.string().min(6).required().validate(billingDetails.addressLine).error;
    const countryError = Joi.string()
      .valid(...COUNTRY_LIST)
      .validate(billingDetails.country).error;
    const cityError = Joi.string().min(3).required().validate(billingDetails.city).error;

    setErrors({
      nameError,
      addressLineError,
      countryError,
      cityError,
    });
    return nameError || addressLineError || countryError || cityError;
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const cardElement = elements.getElement("card");
    saveItemToLocalStorage("cardHoldersName", billingDetails.name);
    if (stripe) {
      const userEmail = props.account.account.attributes.email;

      const error = validate();
      if (!error) {
        stripe
          .confirmCardSetup(props.payment.getPaymentClientSecretCTX.data.secret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: billingDetails.name,
                email: userEmail,
                address: {
                  city: billingDetails.city,
                  country: COUNTRY_LIST_ALPHA[billingDetails.country],
                  line1: billingDetails.addressLine,
                },
              },
            },
          })
          .then((payload) => {
            if (payload.error) {
              setError(payload.error);
              setPending(false);
            } else if (payload.setupIntent && payload.setupIntent.status === "succeeded") {
              createUserPaymentMethodAPI(account.accessToken, payload.setupIntent.id);
            }
          });
      } else {
        setPending(false);
      }
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit} noValidate>
      <div className={`payment-method-form ${divided ? `divided ${showBillingSection ? "billing-part" : ""}` : ""}`}>
        <div className="payment-section card-section">
          <Input
            inputRef={cardHolderInputRef}
            className="card-input"
            label={translate("addPaymentForm.cardHoldersName")}
            placeholder={translate("addPaymentForm.cardHoldersName")}
            maxLength="30"
            value={billingDetails.name}
            onChange={(event) => {
              setBillingDetails({
                ...billingDetails,
                name: event.target.value,
              });
              if (setCardHolderName) {
                setCardHolderName(event.target.value);
              }
              setError(false);
            }}
            hasError={errors.nameError}
            autoComplete="name"
          />
          <span className="card-input-label">Card Number</span>
          <CardElement
            options={StripeCardCreateOptions()}
            onChange={(event) => {
              if (setIsCardInputComplete) {
                setIsCardInputComplete(event.complete);
              }
              if (event.brand && setBrand) {
                setBrand(event.brand);
              }
              setError(false);
            }}
          />
        </div>
        <div className="payment-section billing-section">
          <Input
            className="card-input"
            autoComplete="address-line1"
            placeholder={translate("addPaymentForm.billingAddress")}
            label={translate("addPaymentForm.billingAddress")}
            maxLength="70"
            value={billingDetails.addressLine}
            onChange={(event) => {
              setBillingDetails({
                ...billingDetails,
                addressLine: event.target.value,
              });
            }}
            onKeyDown={(event) => {
              if (event.key === "Tab" && setShowBillingSection) {
                setShowBillingSection(true);
              }
            }}
            hasError={errors.addressLineError}
            onFocus={() => {
              if (divided && cardHolderInputRef.current && !showBillingSection) {
                cardHolderInputRef.current.focus();
              }
            }}
          />
          <div className="country-input-line">
            <Dropdown
              editable
              resetOnOpen
              className="card-input"
              placeholder={translate("addPaymentForm.country")}
              label={translate("addPaymentForm.country")}
              maxLength="30"
              options={COUNTRY_LIST}
              value={billingDetails.country}
              handleSelectedOptionChange={(value) => {
                setBillingDetails({
                  ...billingDetails,
                  country: value,
                });
              }}
              onChange={(event) => {
                setBillingDetails({
                  ...billingDetails,
                  country: event.target.value,
                });
              }}
              hasError={errors.countryError}
            />
            <Input
              className="card-input"
              placeholder={translate("addPaymentForm.city")}
              label={translate("addPaymentForm.city")}
              maxLength="30"
              value={billingDetails.city}
              onChange={(event) => {
                setBillingDetails({
                  ...billingDetails,
                  city: event.target.value,
                });
              }}
              hasError={errors.cityError}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StripeForm;
