import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { LocalizeProvider } from "react-localize-redux";
import LocalizationWrapper from "Utils/Wrappers/Localization.wrapper";
import IntercomWrapper from "Utils/Wrappers/Intercom.wrapper";
import { QueryClientProvider } from "@tanstack/react-query";
import { AppScopeContextProvider } from "Utils/Hooks/useAppScope";
import ElectronVersionModal from "Utils/Components/ElectronVersionModal/ElectronVersionModal.component";
import queryClient from "Utils/Helpers/queryClient";
import AOS from "aos";
import AppRoutes from "./Routes/routes";
import "aos/dist/aos.css";
import "./Assets/styles/global.scss";
import "./Assets/fontawesome/css/all.min.css";
import "./Configs/sentry";

import store from "./Configs/store";

import * as serviceWorker from "./Utils/Workers/serviceWorker";

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
AOS.init();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AppScopeContextProvider>
        <LocalizeProvider store={store}>
          <IntercomWrapper>
            <LocalizationWrapper>
              <AppRoutes />
              <ElectronVersionModal />
            </LocalizationWrapper>
          </IntercomWrapper>
        </LocalizeProvider>
      </AppScopeContextProvider>
    </QueryClientProvider>
  </Provider>,
);

serviceWorker.unregister();
