import Peripherals from "Utils/Workstation/Peripherals";
import * as MessageHelper from "Utils/Workstation/messageHelper";
import Hammer from "hammerjs";
import { LOCAL_STORAGE, SESSION_STORAGE, STREAM_TYPE } from "Constants/global.constants";
import { Logger, Tracker, isSafari, isFirefox, isMobile } from "Utils";
import WebRTC from "Utils/Connection/linuxWebrtc";
import { getVerticalScrollDirection } from "Utils/Helpers/streaming.helpers";
import {
  getAccessTokenFromLocalStorage,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "Utils/Helpers/storage.helpers";
import { STREAM_PLAYERS } from "Constants/streaming.constants";
import TestHelper from "./testHelper";
import SDKInternal from "./SDKInternal";

let checkFrameReceivedTimer;
let restartConnectionTimeout;

export default class LinuxWebsocketConnection {
  constructor(props) {
    Logger.log("LINUX WORKED");
    const {
      wsUri,
      password,
      controlWorkstationStatus,
      showNotification,
      autoStopEnabled,
      autoStopThreshold,
      setConnectingState,
      onConnection,
      onInitConnection,
      onDownload,
      customResolution,
      setIframeSrc,
      endSession,
      onDuplicateTab,
      streamType,
      machineDetails,
      launchFlags,
      changeCursorPosition,
      setCursorImage,
      onWebRTCConnectionFailure,
    } = props;
    this.wsUri = wsUri;
    this.password = password;
    this.controlWorkstationStatus = controlWorkstationStatus;
    this.showNotification = showNotification;
    this.autoStopEnabled = autoStopEnabled;
    this.autoStopThreshold = autoStopThreshold;
    this.setConnectingState = setConnectingState;
    this.onConnection = onConnection;
    this.onInitConnection = onInitConnection;
    this.onDownload = onDownload;
    this.retryConnectionCount = 0;
    this.closed = false;
    this.isFirstFrameMuxingCompleted = false;
    this.fps = 30;
    this.customResolution = customResolution;
    this.streamType = streamType || STREAM_TYPE.workstation;
    this.machineDetails = machineDetails || null;
    this.setIframeSrc = setIframeSrc;
    this.endSession = endSession;
    this.onDuplicateTab = onDuplicateTab;
    this.firstFrame = null;
    this.frameBuffer = [];
    this.isPlayerInitialized = false;
    this.audioCTX = null;
    this.micSource = null;
    this.micProcessor = null;
    this.launchFlags = launchFlags || "";
    this.changeCursorPosition = changeCursorPosition;
    this.setCursorImage = setCursorImage;
    this.onWebRTCConnectionFailure = onWebRTCConnectionFailure;

    if (window.WebSocket) {
      this.init();
      Tracker.time({ type: "connect", start: true });
    } else {
      Logger.error("This browser does not support websockets.");
    }
    SDKInternal.start(this);
    return this;
  }

  init = () => {
    this.useWebRtc = true;
    this.videoElement = document.getElementById("player");

    this.websocket = new WebRTC(
      this.wsUri,
      this.appSessionId(),
      this.videoElement,
      null,
      null,
      this.streamType,
      this.machineDetails.id,
    );

    Logger.log("Websocket|Initialize websocket connection.", {
      player: document.getElementById("player"),
      websocket: this.websocket,
      WebRTC: this.useWebRtc,
    });

    this.closed = false;
    this.websocket.binaryType = "arraybuffer";
    this.peripherals = new Peripherals(this.websocket, this.videoElement);
    this.testHelper = new TestHelper(this.websocket, this.videoElement);
    this.peripherals.setDisableKeyboardActions(false);
    this.peripherals.setDisableMetaKey(this.streamType === STREAM_TYPE.application);
    if (this.videoElement) {
      this.videoElement.style.cursor = "auto";
    }

    this.onInitConnection();
    this.initializeEventHandlers();
  };

  initializeEventHandlers = () => {
    this.websocket.onopen = (e) => {
      this.onOpen(e);
    };

    this.websocket.onclose = this.onWebsocketClose;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onerror = this.onError;
  };

  initJMuxer = () => {
    // There is no need for linux
    // We should keep this for AppStreamingContainer
  };

  destroyJMuxer = () => {
    // There is no need for linux
    // We should keep this for AppStreamingContainer
  };

  initializeStream = () => {
    this.fps = getItemFromLocalStorage(LOCAL_STORAGE.advancedStreamSettings, {}).fps || 30;
    Logger.log("Websocket| FPS: ", this.fps);
    this.initJMuxer();
  };

  initializePlayer = () => {
    if (this.websocket?.readyState !== WebSocket.OPEN) {
      return;
    }
    document.getElementById("in-hidden").style.display = "flex";
    this.videoElement.style.display = "none";
    this.initializeCanvasEventHandlers();
    this.setConnectingState();
    this.changeScrollPreference();
    this.isPlayerInitialized = true;
  };

  requestKeyFrame = () => {
    // No need for linux.
    // we should keep for AppStreaming.container
  };

  initializeCanvasEventHandlers = () => {
    document.addEventListener("contextmenu", Peripherals.preventEventDefault, true);
    this.videoElement.addEventListener("mousedown", this.peripherals.mouseHandler, true);
    this.videoElement.addEventListener("mouseup", this.peripherals.mouseHandler, true);
    this.videoElement.addEventListener("mousemove", this.peripherals.mouseMoveHandler, true);
    this.videoElement.addEventListener("wheel", this.peripherals.mouseWheelHandler, true);
    this.videoElement.addEventListener("mouseout", this.peripherals.mouseOutHandler, true);

    // this.videoElement.addEventListener("touchstart", this.peripherals.simulateTouchStart, true);
    // this.videoElement.addEventListener("touchend", this.peripherals.simulateTouchEnd, true);
    // this.videoElement.addEventListener("touchmove", this.peripherals.simulateTouchMove, true);
    // this.videoElement.addEventListener("touchcancel", this.peripherals.simulateTouchCancelled, true);

    this.videoElement.addEventListener("touchstart", this.peripherals.touchHandler, true);
    this.videoElement.addEventListener("touchend", this.peripherals.touchHandler, true);
    this.videoElement.addEventListener("touchmove", this.peripherals.touchMoveHandler, true);

    this.touchManager = new Hammer.Manager(this.videoElement, {
      recognizers: [
        // RecognizerClass, [options], [recognizeWith, ...], [requireFailure, ...]
        [Hammer.Pan, { enable: true, pointers: 2, threshold: 20 }],
        [Hammer.Rotate, { enable: false }],
        [Hammer.Pinch, { enable: false }],
        [Hammer.Swipe, { enable: false, direction: Hammer.DIRECTION_ALL, pointers: 2 }],
      ],
    });

    this.touchManager.on("pan", this.peripherals.touchSwipeHandler);

    document.addEventListener("keydown", this.peripherals.keyDownHandler, true);
    document.addEventListener("keyup", this.peripherals.keyUpHandler, true);

    window.addEventListener("focus", this.peripherals.onFocus, true);

    if (isSafari) {
      window.addEventListener("resize", this.playerTransformForSafari, true);
    }
  };

  onOpen = () => {
    const enterPasswordPayload = MessageHelper.generateEnterPasswordEventPayload(this.password, true, true);
    this.sendMessageToSocket(enterPasswordPayload);

    if (this.streamType === STREAM_TYPE.workstation) {
      const autoStopPayload = MessageHelper.generateSetAutoStopEventPayload(
        this.autoStopEnabled,
        this.autoStopThreshold,
      );
      this.sendMessageToSocket(autoStopPayload);
      const setClientTokenPayload = MessageHelper.generateSetClientTokenEventPayload(getAccessTokenFromLocalStorage());
      this.sendMessageToSocket(setClientTokenPayload);
    } else {
      const autoStopPayload = MessageHelper.generateSetAutoStopEventPayload(false, 0);
      this.sendMessageToSocket(autoStopPayload);
    }

    this.initializeStream();
  };

  onClose = () => {
    Logger.log("Websocket| Onclose");
    this.destroyJMuxer();

    this.clearEventHandlers();

    if (this.videoElement) {
      this.videoElement.style.display = "none";
    }
    this.controlWorkstationStatus();
    clearTimeout(checkFrameReceivedTimer);
    clearTimeout(restartConnectionTimeout);

    this.stopMicrophone();
    if (this.videoElement?.srcObject) {
      this.videoElement.srcObject.getVideoTracks().forEach((track) => {
        track.stop();
        this.videoElement.srcObject.removeTrack(track);
      });
      this.videoElement.srcObject.getAudioTracks().forEach((track) => {
        track.stop();
        this.videoElement.srcObject.removeTrack(track);
      });
      this.videoElement.srcObject = null;
    }
    this.isPlayerInitialized = false;
    SDKInternal.setConnected(false);
  };

  onWebsocketClose = () => {
    this.onClose();
    this.restartConnection();
  };

  closeSockets = () => {
    if (this.websocket) this.websocket.close();
    this.websocket = null;
  };

  stopStreaming = () => {
    clearTimeout(restartConnectionTimeout);
    this.closed = true;
  };

  restartConnection = () => {
    clearTimeout(restartConnectionTimeout);
    if (!this.closed) {
      restartConnectionTimeout = setTimeout(this.init, 1000);
    }
    Logger.log("Connection | restartConnection worked.", this.retryConnectionCount);
  };

  playerTransformForSafari = () => {
    // If browser is mobile there will be no resize
    if (isMobile) {
      return;
    }

    const actualRatio = this.customX / this.customY;
    const targetRatio = window.innerWidth / window.innerHeight;

    const adjustmentRatio = targetRatio / actualRatio;

    let adjustmentRatioY = 1;
    let adjustmentRatioX = 1;

    if (targetRatio > actualRatio) {
      // Change X
      adjustmentRatioX = adjustmentRatio;
    } else {
      // Change Y
      adjustmentRatioY = 1 / adjustmentRatio;
    }
    // this.videoElement.width = adjustmentRatioX * this.videoElement.width;
    // this.videoElement.height = adjustmentRatioY * this.videoElement.height;
    this.videoElement.style.transform = `scale(${adjustmentRatioX},${adjustmentRatioY})`;
  };

  onMessage = (e) => {
    if (typeof e.data === "string" || e.data instanceof String) {
      this.onStringTypeMessage(e);
    } else {
      this.onBinaryTypeMessage(e);
    }
  };

  onStringTypeMessage = (e) => {
    const message = MessageHelper.parseMessageString(e.data);
    let requestStreamPayload = {};
    Logger.log("Websocket|Received message:", e.data);
    const msgType = message.$type.toString();
    switch (msgType) {
      case MessageHelper.messageTypes.screenReady:
        Logger.log("Websocket|Screen ready.");
        this.sendMessageWindowsResized();
        if (this.useWebRtc && isSafari) {
          this.videoElement.style.objectFit = "contain";
        } else {
          this.videoElement.style.objectFit = "fill";
        }
        break;
      // Window resize is completed
      case MessageHelper.messageTypes.windowsResizedCompleted:
        this.isFirstFrameMuxingCompleted = false;
        this.frameBuffer = [];
        requestStreamPayload = this.createRequestStreamMessage();
        this.peripherals.setCustomResolution(message.x, message.y);
        this.testHelper.setResolution(message.x, message.y);
        Logger.log("Websocket|Send stream request:", requestStreamPayload, message);
        this.sendMessageToSocket(requestStreamPayload);
        this.customX = message.x;
        this.customY = message.y;
        this.initializePlayer();
        break;
      case MessageHelper.messageTypes.clipboard:
        // We can not clear clipboard with javascript due to security.
        // So we just use one space to handle clipboard sync
        if (message.t === "") {
          message.t = " ";
        }
        if (isSafari) {
          sessionStorage.setItem(SESSION_STORAGE.clipboardData, message.t);
          break;
        }
        if (isFirefox) {
          navigator.clipboard.writeText(message.t);
        }
        this.processClipboardMessage(message);
        break;
      case MessageHelper.messageTypes.cursorIconChanged:
        if (message.d) {
          this.videoElement.style.cursor = "auto";
        } else if (message.css !== "") {
          this.videoElement.style.cursor = message.css;
        } else {
          this.videoElement.style.cursor = `url(data:image/png;base64,${message.cimg}) ${message.x} ${message.y}, auto`;
        }

        if (message.cimg !== "") {
          // so that default cursors can also be displayed on game mode
          this.setCursorImage(message.cimg);
        }

        break;
      case MessageHelper.messageTypes.fileInfo:
        if (message.at === MessageHelper.fileInfoMessageActionTypes.downloadFromUrl) {
          const { du, fn, s } = message;
          Logger.log("Websocket| Download file", du, fn, s);
          this.onDownload({ fileName: fn, fileSize: s, downloadUrl: du });
        } else if (message.at === MessageHelper.fileInfoMessageActionTypes.downloadFromUrlStarted) {
          const { fn, s } = message;
          Logger.log("Websocket| Download file started", fn, s);
          this.onDownload({ fileName: fn, fileSize: s });
        }
        break;
      case MessageHelper.messageTypes.info:
        this.onInfoMessage(message);
        break;
      case MessageHelper.messageTypes.ping:
        this.sendMessageToSocket(MessageHelper.generatePongEventPayload());
        break;
      case MessageHelper.messageTypes.pong:
        this.testHelper.onPongEvent();
        break;
      case MessageHelper.messageTypes.streamStats: {
        Logger.log("Websocket| stats", message.f, message.b, Date.now() - message.ms);
        break;
      }
      case MessageHelper.messageTypes.actionTrigger:
        this.onActionTriggerMessage(message);
        break;
      case MessageHelper.messageTypes.test:
        this.testHelper.onTestMessage(message);
        break;
      case MessageHelper.messageTypes.showCanvas:
        this.videoElement.style.display = "flex";
        // eslint-disable-next-line no-case-declarations
        const diff = Tracker.time({ type: "connect", start: false });
        this.onConnection({ timeToConnect: diff });
        try {
          this.videoElement.play();
        } catch (e) {
          Logger.error("Websocket| Error on play", e);
        }
        this.retryConnectionCount = 0;
        saveItemToLocalStorage(LOCAL_STORAGE.lastSuccessfulConnectionType, "webrtc");
        if (this.streamType === STREAM_TYPE.application && this.machineDetails.attributes.keyboard_layout) {
          this.sendMessageToSocket(
            MessageHelper.generateKeyboardLayoutChangeEventMessage(this.machineDetails.attributes.keyboard_layout),
          );
        }
        break;
      case MessageHelper.messageTypes.multipleConnection:
        this.closeSockets();
        this.onDuplicateTab();
        break;
      case MessageHelper.messageTypes.mousePosition:
        {
          const clientWidth = document?.documentElement?.clientWidth;
          const clientHeight = document?.documentElement?.clientHeight;

          const scaledX = (message.x / this.customX) * clientWidth;
          const scaledY = (message.y / this.customY) * clientHeight;

          this.changeCursorPosition(scaledX, scaledY);
        }
        break;
      case MessageHelper.messageTypes.applicationMessage:
        SDKInternal.sendToAllParentIframes(message.p);
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line no-unused-vars
  onBinaryTypeMessage = (e) => {
    // No need for linux
  };

  onInfoMessage = (message) => {
    switch (message.mt) {
      case MessageHelper.infoMessageTypes.log:
        Logger.log("Websocket|Info message received:", message.t);
        break;
      case MessageHelper.infoMessageTypes.toast:
        this.showNotification(message.t);
        break;
      case MessageHelper.infoMessageTypes.loadingText:
        this.setConnectingState(message.t);
        break;
      default:
    }
  };

  static onError(e) {
    Logger.error("Websocket Error: ", e);
  }

  sendMessageWindowsResized = () => {
    const resolution = this.sanitizeResolution();

    if (this.videoElement) {
      this.videoElement.height = window.innerHeight;
      this.videoElement.width = window.innerWidth;
    }

    Logger.log("Websocket|Window resized: ", this.customResolution, resolution.x, resolution.y);
    this.peripherals.windowsResizeHandler(resolution.x, resolution.y, this.retryConnectionCount);
  };

  setDisplayResolution = (resolution) => {
    this.customResolution = resolution;
    Logger.log("Websocket|Set custom resolution stream request:", resolution);
  };

  sanitizeResolution = () => {
    const width = 2 * Math.floor(window.innerWidth / 2);
    const height = 2 * Math.floor(window.innerHeight / 2);
    const newResolution = this.customResolution || { x: width, y: height };
    return newResolution;
  };

  clearEventHandlers = () => {
    document.removeEventListener("keydown", this.peripherals.keyDownHandler, true);
    document.removeEventListener("keyup", this.peripherals.keyUpHandler, true);
    document.removeEventListener("contextmenu", Peripherals.preventEventDefault, true);
    window.removeEventListener("focus", this.peripherals.onFocus, true);

    if (this.videoElement) {
      this.videoElement.removeEventListener("mousedown", this.peripherals.mouseDownHandler, true);
      this.videoElement.removeEventListener("mouseup", this.peripherals.mouseUpHandler, true);
      this.videoElement.removeEventListener("mousemove", this.peripherals.mouseMoveHandler, true);
      this.videoElement.removeEventListener("wheel", this.peripherals.mouseWheelHandler, true);
      this.videoElement.removeEventListener("mouseout", this.peripherals.mouseOutHandler, true);

      // this.videoElement.removeEventListener("touchstart", this.peripherals.simulateTouchStart, true);
      // this.videoElement.removeEventListener("touchend", this.peripherals.simulateTouchEnd, true);
      // this.videoElement.removeEventListener("touchmove", this.peripherals.simulateTouchMove, true);
      // this.videoElement.removeEventListener("touchcancel", this.peripherals.simulateTouchCancelled, true);
      this.videoElement.removeEventListener("touchstart", this.peripherals.touchHandler, true);
      this.videoElement.removeEventListener("touchend", this.peripherals.touchHandler, true);
      this.videoElement.removeEventListener("touchmove", this.peripherals.touchMoveHandler, true);
      this.touchManager.remove("pan");
    }
    if (isSafari) {
      window.removeEventListener("resize", this.playerTransformForSafari, true);
    }

    this.peripherals.clearIntervals();
  };

  sendMessageToSocket = (msg) => {
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(msg);
    }
  };

  sendStopWarmUpEvent = () => {
    const payload = MessageHelper.generateStopWarmUpEventPayload();
    this.sendMessageToSocket(payload);
  };

  sendCtrlAltDeleteEvent = () => {
    const payload = MessageHelper.generateCtrlAltDelEventPayload();
    this.sendMessageToSocket(payload);
  };

  sendShowScreenKeyboardEvent = () => {
    const payload = MessageHelper.generateShowScreenKeyboardEventPayload();
    this.sendMessageToSocket(payload);
  };

  sendEnterPasswordEvent = () => {
    const enterPasswordPayload = MessageHelper.generateEnterPasswordEventPayload(this.password, false, false);
    this.sendMessageToSocket(enterPasswordPayload);
  };

  sendApplicationEvent = (payload) => {
    const wrappedPayload = MessageHelper.generateApplicationEventMessage(payload);
    this.sendMessageToSocket(wrappedPayload);
  };

  pasteToRemote = () => {
    navigator.clipboard.readText().then((text) => {
      const payload = MessageHelper.generatePasteToRemoteEventPayload(text, false);
      this.sendMessageToSocket(payload);
      this.showNotification("Clipboard synced.");
    });
  };

  setDisableKeyboardActions = (disable) => {
    this.peripherals.setDisableKeyboardActions(disable);
  };

  pasteTextToRemote = (text) => {
    const payload = MessageHelper.generatePasteToRemoteEventPayload(text, false);
    this.sendMessageToSocket(payload);
    this.showNotification("Clipboard synced.");
  };

  copyFromRemote = () => {
    const payload = MessageHelper.generateCopyFromRemoteEventPayload();
    this.sendMessageToSocket(payload);
  };

  processClipboardMessage = (message) => {
    // Create new element
    const el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = message.t;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
    if (message.t !== " ") {
      this.showNotification("Clipboard synced.");
    }
  };

  startMicrophone = async () => {
    if (this.audioCTX === null) {
      this.audioCTX = new (window.AudioContext || window.webkitAudioContext)();
    }
    if (this.audioCTX.state === "suspended") {
      await this.audioCTX.resume();
    }
    const constraints = {
      sampleRate: isSafari ? 44100 : 48000,
      channelCount: 1,
      latency: 0.05,
    };
    window.navigator.mediaDevices
      .getUserMedia({ audio: constraints, video: false })
      .then((stream) => {
        this.websocket.startMicrophone(stream);
      })
      .catch((err) => {
        Logger.log(`Could not add audio input: ${err}`);
        this.micSource = null;
        this.micProcessor = null;
      });
  };

  stopMicrophone = async () => {
    if (this.websocket) {
      this.websocket.stopMicrophone();
    }
  };

  changeMicrophoneState = async (state) => {
    if (state) {
      if (this.micSource === null && this.micProcessor === null) {
        await this.startMicrophone();
      }
    } else {
      await this.stopMicrophone();
    }
  };

  audioStateChanged = () => {
    const state = getItemFromLocalStorage(LOCAL_STORAGE.soundEnabled, false);
    this.videoElement.muted = !state;
  };

  changeScrollPreference = () => {
    const scrollPreference = getVerticalScrollDirection();
    this.peripherals.setVerticalScrollDirection(scrollPreference);
  };

  changeCmdPreference = (cmdPreference) => {
    saveItemToLocalStorage(LOCAL_STORAGE.switchCmdPreference, cmdPreference);
    this.peripherals.setSwitchCmdCtrlPreference(cmdPreference);
  };

  changeGameMode = (request) => {
    if (isMobile) {
      return;
    }
    if (request) {
      this.videoElement.focus();
      this.videoElement.requestPointerLock();
      this.peripherals.changeGameMode(true);
    } else {
      document.exitPointerLock();
      this.peripherals.changeGameMode(false);
    }
  };

  createRequestStreamMessage = () => {
    switch (this.streamType) {
      case STREAM_TYPE.application:
        return MessageHelper.generateRequestStreamEventPayloadForAppStream(
          this.machineDetails.attributes.application.attributes.executable_name,
          this.machineDetails.attributes.application.attributes.path,
          `${this.machineDetails.attributes.application.attributes.launch_arguments} ${this.launchFlags}`,
          this.machineDetails.attributes.application.attributes.restart_arguments,
          this.appSessionId(),
          false,
          STREAM_PLAYERS.WEBRTC,
        );
      default:
        return MessageHelper.generateRequestStreamEventPayload(false, STREAM_PLAYERS.WEBRTC);
    }
  };

  appSessionId = (override = false) => {
    let appSessionId = getItemFromLocalStorage(LOCAL_STORAGE.appSessionId);
    if (!appSessionId || override) {
      appSessionId = Math.random().toString(36).substr(2, 9);
    }
    saveItemToLocalStorage(LOCAL_STORAGE.appSessionId, appSessionId);
    return appSessionId;
  };

  onActionTriggerMessage = (message) => {
    switch (message.at) {
      case MessageHelper.actionTriggerTypes.showVimeoPopUp:
      case MessageHelper.actionTriggerTypes.showYouTubePopUp:
        this.setIframeSrc(message.p);
        break;
      case MessageHelper.actionTriggerTypes.endSession:
        this.appSessionId(true);
        this.closeSockets();
        this.endSession();
        break;
      case MessageHelper.actionTriggerTypes.openUrl:
        window.open(message.p, "_blank");
        break;
      default:
        break;
    }
  };

  sendDownloadFileEvent = (url, filename) => {
    const message = MessageHelper.generateDownloadFileEventPayload(url, filename);
    this.sendMessageToSocket(message);
  };
}
