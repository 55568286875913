import React from "react";
import { Icon, TextButton, Button } from "UI";
import _ from "lodash";
import "./Announcement.styles.scss";

const Announcement = ({ containerClass, handleClose, icon, header, description, inLineButton, ...buttons }) => {
  const fullClassName = `announcement d-flex ai-center ${containerClass}`;

  return (
    <div className={fullClassName}>
      {handleClose && (
        <Icon
          name="close"
          onClick={handleClose}
          className={Object.keys(buttons).length !== 0 ? "announcement-top-right-icon" : "right-close-icon"}
        />
      )}
      {icon}
      <div className="announcement-content">
        <h3>{header}</h3>
        <p>
          {description}{" "}
          {inLineButton && (
            <span onClick={inLineButton.onClick} className="inline-button">
              {inLineButton.text}
            </span>
          )}
        </p>
      </div>
      {Object.keys(buttons).length !== 0 && <div className="vertical-divider" />}
      <div className="announcement-button-container d-flex">
        {_.map(Object.values(buttons), (button) => {
          return button.id ? (
            <Button
              className="announcement-buttons"
              id={button.id}
              text={button.text}
              key={button.text}
              color="purple"
              onClick={button.onClick}
              disabled={button.disabled}
            />
          ) : (
            <TextButton
              id={button.id}
              className="announcement-buttons"
              text={button.text}
              key={button.text}
              color="purple"
              onClick={button.onClick}
              disabled={button.disabled}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Announcement;
