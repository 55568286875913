import React from "react";

import { Button } from "UI";
import "./CreateComputerAction.styles.scss";

const CreateComputerAction = (props) => {
  const { hide, buttonText, onButtonClick, actionContent, disabled } = props;

  return (
    <div className={`create-computer-action ${hide ? "hidden" : ""}`}>
      {actionContent}
      <Button text={buttonText} onClick={onButtonClick} disabled={disabled} big />
    </div>
  );
};

export default CreateComputerAction;
