import React, { useEffect, useRef } from "react";

const ReCaptcha = (props) => {
  const { children } = props;
  const retryTimeoutRef = useRef(null);

  const onLoad = () => {
    if (window.grecaptcha) {
      try {
        window.grecaptcha.render("reCaptcha", {
          className: "g-recaptcha",
          sitekey: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          size: "invisible",
        });
      } catch (err) {
        if (!`${err}`.includes("rendered")) {
          retryTimeoutRef.current = setTimeout(onLoad, 300);
        }
      }
    } else {
      retryTimeoutRef.current = setTimeout(onLoad, 300);
    }
  };

  useEffect(() => {
    const reCaptchaScript = document.getElementById("reCaptchaScript");
    if (!reCaptchaScript) {
      const head = document.getElementsByTagName("head")[0];
      const reCaptcha = document.createElement("script");
      reCaptcha.src = "https://www.google.com/recaptcha/api.js";
      reCaptcha.id = "reCaptchaScript";
      reCaptcha.async = true;
      reCaptcha.defer = true;
      head.appendChild(reCaptcha);
    }

    onLoad();

    return () => {
      clearTimeout(retryTimeoutRef.current);
    };
  }, []);

  const recaptchaCall = ({ action }, callback, attempt = 1) => {
    if (window.grecaptcha && window.grecaptcha.ready) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute({ action })
          .then((accessToken) => {
            callback(accessToken);
          })
          .catch(() => {
            callback(null);
          });
      });
    } else if (attempt <= 3) {
      setTimeout(() => recaptchaCall({ action }, callback, attempt + 1), 1000);
    } else {
      callback(null);
    }
  };

  const renderChildren = () => (typeof children === "function" ? children(recaptchaCall) : children);

  return (
    <>
      {renderChildren()}
      <div id="reCaptcha" />
    </>
  );
};

export default ReCaptcha;
