import React from "react";
import { Icon } from "UI";
import WorkstationCanvas from "./WorkstationCanvas.component";

const SessionExpired = ({ translate }) => {
  return (
    <div className="workstation-container">
      <WorkstationCanvas
        isConnected={false}
        translate={translate}
        animatedBackground
        white
        customLoader={
          <>
            <div className="session-expired-notification">
              <Icon name="warning" style={{ width: "5rem", height: "5rem" }} />
              <p>{translate("sessionExpired.message")}</p>
            </div>
            <Icon name="vagon-logo" color="gray-2" className="logo" />
          </>
        }
      />
    </div>
  );
};

export default SessionExpired;
