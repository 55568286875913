import React, { useRef, useEffect } from "react";

import _ from "lodash";
import "./WorldMap.style.scss";
import { ReactComponent as WorldMapSVG } from "Assets/images/world-map.svg";
import { ReactComponent as Location } from "Assets/icons/location.svg";

export const MAP_REGIONS = {
  oregon: "vg-oregon",
  north_virginia: "vg-north-virginia",
  dublin: "vg-dublin",
  frankfurt: "vg-frankfurt",
  northern_california: "vg-california",
  mumbai: "vg-mumbai",
  singapore: "vg-singapore",
  sydney: "vg-sydney",
  bahrain: "vg-bahrain",
  tokyo: "vg-tokyo",
  sao_paulo: "vg-sao-paulo",
  hong_kong: "vg-hong-kong",
  ohio: "vg-ohio",
  stockholm: "vg-stockholm",
  montreal: "vg-canada",
  seoul: "vg-seoul",
  cape_town: "vg-cape-town",
  jakarta: "vg-jakarta",
  uae: "vg-uae",
};

const WorldMap = (props) => {
  const { region, onRegionClick, country } = props;

  const locationMark = useRef(null);

  useEffect(() => {
    // Set region buttons on SVG
    _.each(Object.entries(MAP_REGIONS), ([regionName, regionId]) => {
      const element = document.getElementById(regionId);

      // next 8 lines of code are for adding a transparent rectangle to the region to make it clickable
      const bbox = element.getBBox();
      const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      rect.setAttribute("x", bbox.x);
      rect.setAttribute("y", bbox.y);
      rect.setAttribute("width", bbox.width);
      rect.setAttribute("height", bbox.height);
      rect.setAttribute("fill", "transparent");
      element.insertBefore(rect, element.firstChild);

      if (element) {
        element.classList.add("map-point");
        if (regionName === region) {
          element.classList.add("selected");
        }
        element.onclick = () => {
          changeRegion(regionName);
        };
      }
    });
  }, []);

  useEffect(() => {
    if (locationMark.current) {
      const locationMarkElement = locationMark.current;
      if (country) {
        // Add Vagon icon to country
        const countryNode = document.getElementById(country);
        if (countryNode) {
          const coordinates = findCountryCenter(countryNode);
          const worldMap = document.getElementById("world-map-svg");

          locationMarkElement.setAttribute("height", 30);
          locationMarkElement.setAttribute("width", 30);

          locationMarkElement.setAttribute("x", coordinates.x - 20);
          locationMarkElement.setAttribute("y", coordinates.y - 20);
          worldMap.appendChild(locationMarkElement);
        } else {
          locationMarkElement.setAttribute("display", "none");
        }
      }
    }
  }, [locationMark, country]);

  useEffect(() => {
    updateRegions();
  }, [region]);

  const findCountryCenter = (countryNode) => {
    const box = countryNode.getBBox();
    return {
      x: box.x + box.width / 2,
      y: box.y + box.height / 2,
    };
  };

  const updateRegions = () => {
    _.each(Object.entries(MAP_REGIONS), ([regionName, regionId]) => {
      const element = document.getElementById(regionId);
      if (element) {
        if (region !== regionName) {
          element.classList.remove("selected");
        } else {
          element.classList.add("selected");
        }
      }
    });
  };

  const changeRegion = (clickedRegion) => {
    if (onRegionClick) {
      onRegionClick(clickedRegion);
    }
  };

  return (
    <div className="world-map">
      <Location className={`location-mark ${country ? "" : "hidden"}`} ref={locationMark} />
      <WorldMapSVG />
    </div>
  );
};

export default WorldMap;
