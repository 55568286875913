export const GET_PAYMENT_METHODS_PENDING = "GET_PAYMENT_METHODS_PENDING";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE";

export const SET_PREFERRED_PAYMENT_METHOD_PENDING = "SET_PREFERRED_PAYMENT_METHOD_PENDING";
export const SET_PREFERRED_PAYMENT_METHOD_SUCCESS = "SET_PREFERRED_PAYMENT_METHOD_SUCCESS";
export const SET_PREFERRED_PAYMENT_METHOD_FAILURE = "SET_PREFERRED_PAYMENT_METHOD_FAILURE";

export const GET_PAYMENT_CLIENT_SECRET_PENDING = "GET_PAYMENT_CLIENT_SECRET_PENDING";
export const GET_PAYMENT_CLIENT_SECRET_SUCCESS = "GET_PAYMENT_CLIENT_SECRET_SUCCESS";
export const GET_PAYMENT_CLIENT_SECRET_FAILURE = "GET_PAYMENT_CLIENT_SECRET_FAILURE";

export const CREATE_USER_PAYMENT_METHOD_PENDING = "CREATE_USER_PAYMENT_METHOD_PENDING";
export const CREATE_USER_PAYMENT_METHOD_SUCCESS = "CREATE_USER_PAYMENT_METHOD_SUCCESS";
export const CREATE_USER_PAYMENT_METHOD_FAILURE = "CREATE_USER_PAYMENT_METHOD_FAILURE";

export const DELETE_USER_PAYMENT_METHOD_PENDING = "DELETE_USER_PAYMENT_METHOD_PENDING";
export const DELETE_USER_PAYMENT_METHOD_SUCCESS = "DELETE_USER_PAYMENT_METHOD_SUCCESS";
export const DELETE_USER_PAYMENT_METHOD_FAILURE = "DELETE_USER_PAYMENT_METHOD_FAILURE";
export const DELETE_USER_PAYMENT_METHOD_RESET = "DELETE_USER_PAYMENT_METHOD_RESET";

export const APPROVE_USER_PAYMENT_METHOD_PENDING = "APPROVE_USER_PAYMENT_METHOD_PENDING";
export const APPROVE_USER_PAYMENT_METHOD_SUCCESS = "APPROVE_USER_PAYMENT_METHOD_SUCCESS";
export const APPROVE_USER_PAYMENT_METHOD_FAILURE = "APPROVE_USER_PAYMENT_METHOD_FAILURE";

export const DENY_USER_PAYMENT_METHOD_PENDING = "DENY_USER_PAYMENT_METHOD_PENDING";
export const DENY_USER_PAYMENT_METHOD_SUCCESS = "DENY_USER_PAYMENT_METHOD_SUCCESS";
export const DENY_USER_PAYMENT_METHOD_FAILURE = "DENY_USER_PAYMENT_METHOD_FAILURE";

export const PAYMENT_3DSECURE_REQUESTED = "PAYMENT_3DSECURE_REQUESTED";
export const PAYMENT_3DSECURE_PENDING = "PAYMENT_3DSECURE_PENDING";
export const PAYMENT_3DSECURE_SUCCESS = "PAYMENT_3DSECURE_SUCCESS";
export const PAYMENT_3DSECURE_FAILURE = "PAYMENT_3DSECURE_FAILURE";

export const START_PAYMENT_PENDING = "START_PAYMENT_PENDING";
export const START_PAYMENT_SUCCESS = "START_PAYMENT_SUCCESS";
export const START_PAYMENT_SUCCESS_MESSAGE =
  "Your payment has been done successfully. You can continue using your vagon computer.";
export const START_PAYMENT_FAILURE = "START_PAYMENT_FAILURE";

export const ADD_BALANCE_PENDING = "ADD_BALANCE_PENDING";
export const ADD_BALANCE_SUCCESS = "ADD_BALANCE_SUCCESS";
export const ADD_BALANCE_FAILURE = "ADD_BALANCE_FAILURE";
export const ADD_BALANCE_RESET = "ADD_BALANCE_RESET";
