import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getOrganizationPlanAPI } from "Actions/Organization.actions";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import _ from "lodash";
import { useDebounce } from "use-debounce";
import { apiGenerator, DashboardSubScreenLayout, getItemFromLocalStorage, isMobile } from "Utils";
import {
  groupOrganizationMachineTypes,
  minutesToReadableHoursMinutes,
  pluralize,
} from "Utils/Helpers/functions.helpers";
import { Button, ExpandableTable, HoverableTooltip, Icon, IconButton, SearchInput } from "UI";
import EditableCell from "UI/EditableCell/EditableCell.component";
import AppMenuPopUp from "Components/AppStreaming/Home/AppMenuPopUp/AppMenuPopUp.component";
import TeamsPlansOnboardingModal from "Components/Workstation/Modals/TeamsPlansOnboardingModal.component";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";
import EmptyContent from "../Components/EmptyContent/EmptyContent.component";
import UpdatePerformancesModal from "../Components/UpdatePerformancesModal/UpdatePerformancesModal.component";
import AssignedSeatsModal from "../Shared/AssignedSeatsModal/AssignedSeatsModal.component";
import "./Plans.styles.scss";

const PERFORMANCE_GROUPS = ["cpu", "gpu", "a10"];

const PLANS_TABLE_COLUMNS = [
  { name: "Id", weight: 0, hide: true },
  { name: "Plan", weight: 15 },
  { name: "Usage", weight: 15 },
  { name: "Computer", weight: 5 },
  { name: "Files", weight: 5 },
  { name: "Budget", weight: 8 },
  { name: "Users", weight: 5 },
  { name: "buttons", hide: true, weight: 3 },
];

const Plans = ({ translate, history }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [moreButtonPopUpPosition, setMoreButtonPopUpPosition] = useState(null);
  const [highlightedPlanId, setHighlightedPlanId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [expandedPlanID, setExpandedPlanID] = useState(null);
  const [showUpdatePerformancesModal, setShowUpdatePerformancesModal] = useState(false);
  const [showTeamsPlansOnboardingModal, setShowTeamsPlansOnboardingModal] = useState(null);
  const [onboardingModalCurrentStep, setOnboardingModalCurrentStep] = useState(0);

  const [showAssignedSeatsModal, setShowAssignedSeatsModal] = useState(false);
  const dispatch = useDispatch();

  const { organizationPlan } = useSelector((state) => state.organizations?.getOrganizationPlanCTX) || {};
  const { productOnboardingCTX } = useSelector((state) => state.account);
  const checkPlan = productOnboardingCTX?.data?.teams?.seat_plan_created;

  const machineTypes = organizationPlan?.attributes?.pricing?.map((pricing) => {
    return pricing.attributes.machine_type;
  });

  const groupedMachineTypes = groupOrganizationMachineTypes(machineTypes, true);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const location = useLocation();
  const plansTableRef = useRef(null);

  const { createdPlanId } = location.state || {};

  useEffect(() => {
    dispatch(getOrganizationPlanAPI());
    if (createdPlanId) {
      setHighlightedPlanId(createdPlanId);
      setTimeout(() => {
        setHighlightedPlanId(null);
        history.replace();
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (showTeamsPlansOnboardingModal === null && productOnboardingCTX?.status === REQUEST_STATUS.SUCCESS) {
      const shouldDisplayAfterCreationOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showTeamsPlansOnboardingModal, true);

      setShowTeamsPlansOnboardingModal(checkPlan ? false : shouldDisplayAfterCreationOnboarding);
    }
  }, [productOnboardingCTX?.status]);

  const {
    data: plansData,
    isInitialLoading,
    refetch,
  } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_SEAT_PLANS, debouncedSearchQuery],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_SEAT_PLANS, { params: { q: searchQuery } });
    },
    keepPreviousData: true,
  });

  const updatePlanMutation = useMutation({
    mutationFn: ({ planId, name }) => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_SEAT_PLAN(planId), { name });
    },
    onSuccess: () => {
      refetch();
    },
  });

  const deletePlanMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("delete")(API_ENDPOINTS.ORGANIZATION_SEAT_PLAN(selectedPlan.id));
    },
    onSuccess: () => {
      refetch();
    },
  });

  const { seat_plans: seatPlans, has_any_seat_plan: hasAnySeatPlan } = plansData?.data || {};
  const showTable = seatPlans?.length > 0;

  const plansTableData = seatPlans?.map((item) => {
    const {
      name,
      budget,
      disk_size: diskSize,
      file_storage_size: fileStorageSize,
      usage,
      usage_list: usageList,
      organization_id: organizationId,
      remaining_trial_usage: remainingTrialUsage,
      has_discount: hasDiscount,
      normal_budget: normalBudget,
      highlightClass,
    } = item.attributes;
    const highlighted = highlightedPlanId === item.id;

    const usageText = () => {
      if (usage === 0) {
        return "No Usage Included";
      }

      if (usageList?.length === 1) {
        return minutesToReadableHoursMinutes(usage);
      }
      return `Up to ${minutesToReadableHoursMinutes(usage)}`;
    };

    return {
      id: item.id,
      key: item.id,
      plan: (
        <div className="plan-name">
          {hasDiscount ? (
            <>
              <p>{name}</p>
              <HoverableTooltip
                side="right"
                content={
                  <div className="trial-plan-info">
                    <p>Trial Plan</p>
                    <p>
                      {remainingTrialUsage > 0
                        ? `${pluralize(remainingTrialUsage, "Usage")} Remaining`
                        : "No Usages Left"}
                    </p>
                  </div>
                }
              >
                <Icon name="info" smaller color="gray-3" />
              </HoverableTooltip>
            </>
          ) : (
            <EditableCell
              initialValue={name}
              className={highlighted ? "highlighted" : undefined}
              onEnter={(value) => {
                updatePlanMutation.mutate({ planId: item.id, name: value });
              }}
              disabled={!organizationId || showTeamsPlansOnboardingModal}
              onCancel={() => {
                if (updatePlanMutation.isError) updatePlanMutation.reset();
              }}
              error={
                updatePlanMutation.variables?.planId === item.id && updatePlanMutation.isError
                  ? translate("organizationPlans.plansTable.planUpdateError")
                  : undefined
              }
            />
          )}
        </div>
      ),
      usage: usageText(),
      computer: `${diskSize} GB`,
      files: `${fileStorageSize} GB`,
      budget: (
        <div className="price">
          <p>{`$${parseFloat(budget).toFixed(2)}/month`}</p>
          {hasDiscount && (
            <HoverableTooltip
              side="right"
              content={
                <p>
                  Renewal Price <span>${normalBudget}/month</span>
                </p>
              }
            >
              <Icon name="info" smaller color="gray-3" />
            </HoverableTooltip>
          )}
        </div>
      ),
      users: (
        <div className="assigned-users-cell">
          <IconButton
            name="organizations-user"
            smaller
            color="gray-3"
            onClick={() => {
              setSelectedPlan(item);
              setShowAssignedSeatsModal(true);
            }}
            disabled={item.attributes.assigned_seats?.length === 0}
          />
          <p>{item.attributes.assigned_seats?.length}</p>
        </div>
      ),
      buttons: (
        <div className="button-cell">
          <HoverableTooltip content={!hasDiscount && "More"}>
            <IconButton
              name="more"
              smaller
              disabled={hasDiscount}
              onClick={(event) => {
                if (!showTeamsPlansOnboardingModal) handleMoreButtonClick(event, item);
              }}
            />
          </HoverableTooltip>

          <IconButton
            className="expand-button"
            name="teams-caret-down"
            smaller
            onClick={() => {
              if (!showTeamsPlansOnboardingModal) setExpandedPlanID(expandedPlanID === item.id ? null : item.id);
            }}
          />
        </div>
      ),
      expanded: expandedPlanID === item.id,
      expandableContent: (
        <>
          {PERFORMANCE_GROUPS.map((group) => {
            const machineTypes = groupedMachineTypes[group];

            return (
              <div className="machine-types" key={group}>
                <div className="header">
                  <h1>{translate(`organizationPlans.machineTypeGroups.${group}.title`)}</h1>

                  <HoverableTooltip side="top" content={translate(`organizationPlans.machineTypeGroups.${group}.info`)}>
                    <Icon name="info" smaller color="gray-3" />
                  </HoverableTooltip>
                </div>
                {_.map(machineTypes, (item) => {
                  const usage = usageList.find((usage) => usage.machine_type.id === parseInt(item.id, 10));

                  return (
                    <div className="duration" key={item.id}>
                      <p className={!usage ? "disabled" : undefined}>{item.attributes.friendly_name}</p>
                      {usage && (
                        <>
                          <p>-</p>
                          <p>
                            {usage && usage?.usage > 0
                              ? minutesToReadableHoursMinutes(usage.usage)
                              : "No Usage Included"}
                          </p>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      ),
      highlightClass,
    };
  });

  const popUpMenuActions = {
    updatePerformances: {
      action: () => {
        setShowUpdatePerformancesModal(true);
        setMoreButtonPopUpPosition(null);
      },
    },
    delete: {
      action: () => {
        deletePlanMutation.mutate();
        setMoreButtonPopUpPosition(null);
      },
      disabled: !selectedPlan?.attributes?.deletable || selectedPlan?.attributes?.has_discount,
    },
  };

  const menuPopUpContent = useMemo(() => {
    return Object.keys(popUpMenuActions).map((key) => {
      return {
        text: translate(`organizationPlans.plansTable.popUpMenuActions.${key}`),
        disabled: popUpMenuActions[key].disabled,
        onClick: popUpMenuActions[key].action,
      };
    });
  }, [selectedPlan]);

  const handleMoreButtonClick = (event, item) => {
    if (!moreButtonPopUpPosition) event.stopPropagation();
    event.preventDefault();
    setSelectedPlan(item);
    setMoreButtonPopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
  };

  return (
    <DashboardSubScreenLayout
      headerTitle={translate("organizationPlans.header.title")}
      headerDescription={translate("organizationPlans.header.description")}
      noScroll
      loading={isInitialLoading && searchQuery === ""}
    >
      <div className="teams-plans-container" ref={plansTableRef}>
        <div className="top-bar">
          <div className="left-actions">
            <SearchInput
              placeholder={translate("organizationPlans.searchInput.placeHolder")}
              iconName="search"
              color="white"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>

          <div className="right-actions">
            {hasAnySeatPlan && (
              <Button
                iconLeft="plus"
                text={translate("organizationPlans.createPlanButton.text")}
                onClick={() => {
                  if (!showTeamsPlansOnboardingModal) history.push(routes.organizationAdminCreatePlan);
                }}
              />
            )}
          </div>
        </div>
        {showTable ? (
          <>
            <ExpandableTable
              className="teams-plans-table"
              columns={PLANS_TABLE_COLUMNS}
              data={plansTableData}
              onboardingModalCurrentStep={onboardingModalCurrentStep}
              onboarding={showTeamsPlansOnboardingModal}
            />
            {moreButtonPopUpPosition && (
              <AppMenuPopUp
                content={menuPopUpContent}
                popUpPosition={moreButtonPopUpPosition}
                setPopUpPosition={setMoreButtonPopUpPosition}
                referencePosRef={plansTableRef}
              />
            )}
          </>
        ) : (
          <EmptyContent
            headerText={translate("organizationPlans.emptyContent.title")}
            descriptionText={translate("organizationPlans.emptyContent.description")}
            buttonText={translate("organizationPlans.createPlanButton.text")}
            onButtonClick={() => {
              history.push(routes.organizationAdminCreatePlan);
            }}
            buttonIconLeft="plus"
            withoutButton={hasAnySeatPlan}
          />
        )}
      </div>

      {showUpdatePerformancesModal && (
        <UpdatePerformancesModal
          plan={selectedPlan}
          onClose={() => {
            refetch();
            setShowUpdatePerformancesModal(false);
          }}
          translate={translate}
        />
      )}
      {showTeamsPlansOnboardingModal && (
        <TeamsPlansOnboardingModal
          onExit={() => {
            setShowTeamsPlansOnboardingModal(false);
            localStorage.setItem(LOCAL_STORAGE.showTeamsPlansOnboardingModal, false);
          }}
          setOnboardingModalCurrentStep={setOnboardingModalCurrentStep}
          translate={translate}
        />
      )}
      {showAssignedSeatsModal && selectedPlan && (
        <AssignedSeatsModal
          onClose={() => {
            setShowAssignedSeatsModal(false);
            setSelectedPlan(null);
          }}
          seats={selectedPlan?.attributes?.assigned_seats}
          translate={translate}
        />
      )}
    </DashboardSubScreenLayout>
  );
};

export default Plans;
