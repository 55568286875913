import React, { useState } from "react";

import { useMutation, useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { Icon, Modal, TextButton, WorldMapCoverage } from "UI";
import { apiGenerator } from "Utils";

import "./ChooseRegionModal.styles.scss";

const ChooseRegionModal = ({ selectedSeat, onClose, runComputerAction, translate }) => {
  const [selectedRegion, setSelectedRegion] = useState(null);

  const { data: seatData } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATIONS_SEAT(selectedSeat?.id)],
    queryFn: () => apiGenerator("get")(API_ENDPOINTS.ORGANIZATIONS_SEAT(selectedSeat?.id)).then((res) => res.data),
  });

  const { machine } = seatData?.attributes || {};
  const { regions } = machine?.attributes?.machine_type?.attributes || {};

  const setMachineRegionMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_MACHINES_REGION(machine?.id), data);
    },
  });

  const { isSuccess, isError } = setMachineRegionMutation;

  const buttons = () => {
    if (isSuccess) {
      return (
        <>
          <TextButton color="gray-3" text="Close" onClick={onClose} />
          <TextButton color="aqua-main" text="Run Computer" onClick={runComputerAction} />
        </>
      );
    }

    if (isError) {
      return (
        <>
          <TextButton color="gray-3" text="Close" onClick={onClose} />
          <TextButton color="aqua-main" text="Done" onClick={onClose} />
        </>
      );
    }

    return (
      <>
        <TextButton color="gray-3" text="Close" onClick={onClose} />
        <TextButton
          color="aqua-main"
          text="Submit"
          onClick={() => {
            setMachineRegionMutation.mutate({
              region: selectedRegion,
            });
          }}
          disabled={!selectedRegion}
        />
      </>
    );
  };

  return (
    <>
      <Modal
        className="organization-choose-region-modal"
        topRightIcon="close"
        topRightIconAction={onClose}
        headerText={translate("organizationChooseRegionModal.header.title")}
        descriptionText={translate("organizationChooseRegionModal.header.description")}
        overrideButton={<div className="buttons-container">{buttons()}</div>}
        big
      >
        {isSuccess || isError ? (
          <div className="result-content">
            <Icon name={setMachineRegionMutation.isSuccess ? "organizations-check" : "organizations-cross"} />
            <div className="texts">
              <h1>{translate(`organizationChooseRegionModal.result.${isSuccess ? "success" : "error"}.header`)}</h1>
              <p>{translate(`organizationChooseRegionModal.result.${isSuccess ? "success" : "error"}.description`)}</p>
            </div>
          </div>
        ) : (
          <WorldMapCoverage
            coveredRegions={regions}
            activeRegions={[selectedRegion]}
            onRegionClick={(e) => {
              setSelectedRegion(e);
            }}
            passiveCoverage
          />
        )}
      </Modal>
    </>
  );
};

export default ChooseRegionModal;
