import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import React, { useEffect } from "react";
import { ExpandableTable, HoverableTooltip, IconButton } from "UI";
import { convertDateFormat, useAPI } from "Utils";
import NoTransactionsImage from "Assets/images/appStreaming/app-streaming_first review 1.png";
import { convertToSelectedTimeZone } from "Utils/Helpers/functions.helpers";
import { useSelector } from "react-redux";

const PaymentsTable = ({ disabled, vendorPaymentsProps, translate }) => {
  const [selectedPaymentID, setSelectedPaymentID] = React.useState(null);
  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};
  const paymentColumns = [
    { name: "Id", weight: 0, hide: true },
    { name: translate("appStreamingBilling.payments.columns.date"), weight: 50 },
    { name: translate("appStreamingBilling.payments.columns.paymentType"), weight: 50 },
    { name: translate("appStreamingBilling.payments.columns.invoiceNumber"), weight: 50 },
    { name: translate("appStreamingBilling.payments.columns.amount"), weight: 50 },
  ];

  const { api: getVendorInvoiceAPI } = useAPI({
    type: "get",
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_VENDOR_PAYMENT_INVOICE(selectedPaymentID),
    onSuccess: (data) => {
      const link = document.createElement("a");
      link.href = data.invoice_url;
      link.download = "invoice.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSelectedPaymentID(null);
    },
  });

  useEffect(() => {
    if (selectedPaymentID) {
      getVendorInvoiceAPI();
    }
  }, [selectedPaymentID]);

  const vendorPaymentsData = vendorPaymentsProps?.data;

  const tableData = vendorPaymentsData?.map((payment) => {
    const {
      amount,
      payment_type: paymentType,
      invoice_number: invoiceNumber,
      created_at: createdAt,
      highlightClass,
    } = payment.attributes;

    return {
      id: payment.id,
      transaction: translate(`appStreamingBilling.payments.paymentTypes.${paymentType}`),
      "invoice number": invoiceNumber,
      date: createdAt
        ? convertToSelectedTimeZone(
            `${convertDateFormat(createdAt.substring(0, 10))} ${createdAt.substring(
              createdAt.indexOf("T") + 1,
              createdAt.indexOf("T") + 6,
            )}`,
            userTimeZone,
          )
        : "",
      amount: (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          $ {amount}{" "}
          <HoverableTooltip side="top" content="Download">
            <IconButton
              name={selectedPaymentID === payment.id ? "loader" : "file-download"}
              smallest
              onClick={() => {
                if (!disabled) setSelectedPaymentID(payment.id);
              }}
            />
          </HoverableTooltip>
        </div>
      ),
      highlightClass,
    };
  });

  if (tableData?.length === 0)
    return (
      <div className="no-transactions-content">
        <img src={NoTransactionsImage} />
        <span>{translate("appStreamingBilling.noTransaction")}</span>
      </div>
    );

  return (
    <ExpandableTable
      isLoading={vendorPaymentsProps.isLoading}
      columns={paymentColumns}
      data={tableData}
      onPageEnd={vendorPaymentsProps.fetchNextPage}
      isFetchingNextPage={vendorPaymentsProps.isFetchingNextPage}
      hasNextPage={vendorPaymentsProps.hasNextPage}
    />
  );
};

export default PaymentsTable;
