import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "Translations/global.json";

class LocalizationWrapper extends React.Component {
  constructor(props) {
    super(props);
    const { initialize } = props;

    initialize({
      languages: [{ name: "English", code: "en" }],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
      },
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withLocalize(LocalizationWrapper);
