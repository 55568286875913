import React, { useState } from "react";

import { Purpose } from "UI";

import digitalArtImage from "Assets/images/purposes/digital-art.png";
import motionImage from "Assets/images/purposes/motion.png";
import gameImage from "Assets/images/purposes/game.png";
import architectureImage from "Assets/images/purposes/architecture.png";
import engineeringImage from "Assets/images/purposes/engineering.png";
import otherImage from "Assets/images/purposes/other.png";

import "./PurposeList.styles.scss";

const PURPOSE_IMAGES = {
  digital_art: digitalArtImage,
  motion: motionImage,
  game: gameImage,
  architecture: architectureImage,
  engineering: engineeringImage,
  other: otherImage,
};

const PurposeList = (props) => {
  const { translate, selectedPurpose, setSelectedPurpose } = props;

  const [active, setActive] = useState(false);

  return (
    <div
      className={`purpose-list ${active ? "active" : ""}`}
      onMouseOver={() => setActive(true)}
      onFocus={() => setActive(true)}
    >
      {Object.keys(PURPOSE_IMAGES).map((purposeName) => (
        <Purpose
          key={purposeName}
          purposeName={purposeName}
          purposeText={translate(`purposes.${purposeName}`)}
          purposeImage={PURPOSE_IMAGES[purposeName]}
          isSelected={selectedPurpose === purposeName}
          setSelectedPurpose={setSelectedPurpose}
        />
      ))}
    </div>
  );
};

export default PurposeList;
