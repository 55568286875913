import { useEffect, useState } from "react";

const IdleTracker = ({ reset, idleDuration, onIdleTimeEnd, warningTimeOffset, onWarningTimeEnd }) => {
  const [resetCounter, setResetCounter] = useState(0);

  let idleTimer = null;
  let warningTimer = null;

  useEffect(() => {
    resetIdleTimer();
    window.addEventListener("mousemove", resetIdleTimer);
    window.addEventListener("keydown", resetIdleTimer);
    window.addEventListener("scroll", resetIdleTimer);
    window.addEventListener("touchstart", resetIdleTimer);
    window.addEventListener("touchmove", resetIdleTimer);
    window.addEventListener("gamepadconnected", resetIdleTimer);

    return () => {
      clearTimeout(idleTimer);
      clearTimeout(warningTimer);
      window.removeEventListener("mousemove", resetIdleTimer);
      window.removeEventListener("keydown", resetIdleTimer);
      window.removeEventListener("scroll", resetIdleTimer);
      window.removeEventListener("touchstart", resetIdleTimer);
      window.removeEventListener("touchmove", resetIdleTimer);
      window.removeEventListener("gamepadconnected", resetIdleTimer);
    };
  }, [resetCounter]);

  useEffect(() => {
    setResetCounter(resetCounter + 1);
  }, [reset]);

  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    clearTimeout(warningTimer);
    idleTimer = setTimeout(handleIdleAction, idleDuration);
    warningTimer = setTimeout(handleWarningAction, idleDuration - warningTimeOffset);
  };

  const handleIdleAction = () => {
    onIdleTimeEnd();
  };

  const handleWarningAction = () => {
    onWarningTimeEnd();
  };

  return null;
};

export default IdleTracker;
