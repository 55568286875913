import React, { useEffect } from "react";
import CreatePlan from "Components/Organization/Admin/CreatePlan/CreatePlan.component";
import { getOrganizationPlanAPI } from "Actions/Organization.actions";
import { useDispatch } from "react-redux";

const CreatePlanContainer = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganizationPlanAPI());
  }, []);

  return <CreatePlan {...props} />;
};

export default CreatePlanContainer;
