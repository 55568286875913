import React, { useState, useEffect, useRef } from "react";

import { Button, Modal, ConfirmationModal } from "UI";
import { Loader, PaymentInfoLine } from "Utils";
import { Translate } from "react-localize-redux";

import Cloud1 from "Assets/images/fileManager/cloud-storage-1.png";
import Cloud2 from "Assets/images/fileManager/cloud-storage-2.png";
import Cloud3 from "Assets/images/fileManager/cloud-storage-3.png";
import Cloud4 from "Assets/images/fileManager/cloud-storage-4.png";

import { REQUEST_STATUS } from "Constants/global.constants";

import "./UpgradeFileStorageModal.styles.scss";

const SUCCESS_CLOSE_TIMEOUT_TIME = 3000;

const PLAN_IMAGES = {
  50: Cloud1,
  250: Cloud2,
  500: Cloud3,
  1000: Cloud4,
  default: Cloud3,
};

const BG_COLOR_BY_SIZE = {
  50: "#94A1F5",
  250: "#5E73FF",
  500: "#455ED4",
  1000: "#2641BF",
  default: "#2641BF",
};

const StorageOptionCard = (props) => {
  const { name, selected, image, price, bgColor, disabled, onClick } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`storage-option-card ${selected ? "selected" : ""}`}
      onClick={onClick}
      disabled={disabled}
      role="button"
    >
      <div className="type">
        <div className="name">{name}</div>
        <img src={image} alt="Storage option" />
      </div>
      <div className="price" style={{ backgroundColor: bgColor }}>
        {price}
      </div>
    </div>
  );
};

const UpgradeFileStorageModal = (props) => {
  const {
    remainingDaysToRenew,
    upgradeFileStorageSubscription,
    upgradeFileStorageSubscriptionCTX,
    getFileStorageSubscriptionPlansCTX,
    setShowUpgradeFileStorageModal,
    translate,
  } = props;

  const successCloseTimeoutRef = useRef(null);
  const [selectedPlan, setSelectedPlan] = useState();

  const [currentPlan, setCurrentPlan] = useState(); // TODO: add this logic in backend

  const [success, setSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState(null);

  // const { client_code: error } = upgradeFileStorageSubscriptionCTX;

  useEffect(() => {
    return () => {
      clearTimeout(successCloseTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (getFileStorageSubscriptionPlansCTX.status === REQUEST_STATUS.SUCCESS) {
      let currentPrice = 0;
      getFileStorageSubscriptionPlansCTX.data.forEach((plan) => {
        if (!plan.attributes.upgradable && plan.attributes.price > currentPrice) {
          currentPrice = plan.attributes.price;
        }
      });
      setCurrentPlan({ price: currentPrice });
    }
  }, [getFileStorageSubscriptionPlansCTX.status]);

  useEffect(() => {
    if (upgradeFileStorageSubscriptionCTX.status === REQUEST_STATUS.SUCCESS) {
      // setShowUpgradeFileStorageModal(false);
      setSuccess(true);
      successCloseTimeoutRef.current = setTimeout(
        () => setShowUpgradeFileStorageModal(false),
        SUCCESS_CLOSE_TIMEOUT_TIME,
      );
    } else if (upgradeFileStorageSubscriptionCTX.status === REQUEST_STATUS.FAILURE) {
      setError(upgradeFileStorageSubscriptionCTX.client_code);
    }
  }, [upgradeFileStorageSubscriptionCTX]);

  const isPending = upgradeFileStorageSubscriptionCTX.status === REQUEST_STATUS.PENDING;

  const selectedPlanSize = selectedPlan ? selectedPlan.size : 0;

  const handleConfirm = () => {
    upgradeFileStorageSubscription(selectedPlanSize);
  };

  if (showConfirm) {
    return (
      <ConfirmationModal
        headerText={translate("modals.upgradeStorage.confirm.header")}
        descriptionText={
          <>
            <div>{translate("modals.upgradeStorage.confirm.description")}</div>
          </>
        }
        errorText={
          error && (
            <Translate
              id={`modals.upgradeStorage.error.${error}`}
              options={{ onMissingTranslation: () => translate("modals.upgradeStorage.error.default") }}
            />
          )
        }
        confirmText={translate("addStorage.confirm.confirm")}
        confirmAction={handleConfirm}
        closeAction={() => setShowUpgradeFileStorageModal(false)}
        disableConfirmButton={
          upgradeFileStorageSubscriptionCTX.status === REQUEST_STATUS.PENDING ||
          upgradeFileStorageSubscriptionCTX.status === REQUEST_STATUS.FAILURE
        }
        secondaryText={translate("modals.upgradeStorage.confirm.secondary")}
        secondaryAction={() => {
          setShowConfirm(false);
          setError(null);
        }}
        success={success}
        successText={translate("modals.upgradeStorage.confirm.successMessage")}
        pending={upgradeFileStorageSubscriptionCTX.status === REQUEST_STATUS.PENDING}
        blue
      />
    );
  }

  return (
    <Modal
      closeOnOverlayClick
      closeAction={() => setShowUpgradeFileStorageModal(false)}
      topRightIcon="close"
      topRightIconAction={() => setShowUpgradeFileStorageModal(false)}
      headerText={translate("modals.upgradeStorage.header")}
      descriptionText={translate("modals.upgradeStorage.description")}
      overrideButton={
        <div className="upgrade-file-modal-action">
          {selectedPlan && (
            <PaymentInfoLine
              price={parseFloat(((selectedPlan.price - currentPlan.price) * remainingDaysToRenew) / 30).toFixed(2)}
              translate={translate}
            />
          )}
          <Button
            big
            blue
            className="action"
            text={translate("modals.upgradeStorage.buttonText")}
            onClick={() => {
              setShowConfirm(true);
            }}
            disabled={!selectedPlan || isPending}
          />
        </div>
      }
    >
      <div className="upgrade-file-storage-modal-content">
        {getFileStorageSubscriptionPlansCTX.status !== REQUEST_STATUS.SUCCESS ? (
          <Loader />
        ) : (
          <>
            {getFileStorageSubscriptionPlansCTX.data.map((plan) => {
              let name = `${plan.attributes.size} GB`;
              if (plan.attributes.size >= 1000) {
                name = `${plan.attributes.size / 1000} TB`;
              }
              return (
                <StorageOptionCard
                  key={plan.id}
                  name={name}
                  image={PLAN_IMAGES[plan.id] || PLAN_IMAGES.default}
                  price={translate("modals.upgradeStorage.price", { price: plan.attributes.price })}
                  selected={plan.attributes.size === selectedPlanSize}
                  onClick={() => {
                    if (plan.attributes.upgradable)
                      setSelectedPlan({ size: plan.attributes.size, price: plan.attributes.price });
                  }}
                  bgColor={BG_COLOR_BY_SIZE[plan.attributes.size]}
                  disabled={!plan.attributes.upgradable}
                />
              );
            })}
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpgradeFileStorageModal;
