import React from "react";
import Icon from "UI/Icon/Icon.component";
import { classnames } from "Utils";

import "./RadioInput.style.scss";

const RadioInput = ({ checked, label, className, onChange, isCheckMark, disabled, color, square, centered }) => {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) {
      onChange();
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleEnterKeyUp = (event) => {
    event.preventDefault();
    // Toggle showMenu on Enter Key
    if (event.key === "Enter") {
      handleClick(event);
    }
  };

  return (
    <div
      className={classnames([
        className,
        "vg-radio-input",
        checked && "checked",
        disabled && "disabled",
        isCheckMark && "check-mark",
        square && "square",
        centered && "centered",
        color,
      ])}
      onClick={handleClick}
      tabIndex={0}
      role="button"
      onKeyUp={handleEnterKeyUp}
    >
      <input id="radio" type="radio" checked={checked} onChange={handleChange} disabled={disabled} />
      <label htmlFor="radio">{label}</label>
      <div className={classnames(["check", square && "square"])} />
      {isCheckMark && checked && <Icon name="check-mark" />}
    </div>
  );
};

export default RadioInput;
