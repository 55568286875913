import { all, fork } from "redux-saga/effects";

import RegisterSagas from "./register.saga";
import VerifyRegisterSagas from "./verifyRegister.saga";
import ForgetPasswordSagas from "./forgetPassword.saga";
import ForgetPasswordResetSagas from "./forgetPasswordReset.saga";
import LoginSagas from "./login.saga";
import AccountSagas from "./account.saga";
import ReferralSagas from "./referral.saga";
import PlansSagas from "./Dashboard/plans.saga";
import RegionsSagas from "./Dashboard/regions.saga";
import PaymentSagas from "./Dashboard/payment.saga";
import SubscriptionsSagas from "./Dashboard/subscriptions.saga";
import WorkstationsSagas from "./Dashboard/workstation.saga";
import FilesSagas from "./Dashboard/files.saga";
import AppStreamingSagas from "./appStreaming.saga";
import OrganizationsSagas from "./organizations.saga";
// RootSaga
export default function* root() {
  yield all([
    fork(RegisterSagas),
    fork(ReferralSagas),
    fork(VerifyRegisterSagas),
    fork(ForgetPasswordSagas),
    fork(ForgetPasswordResetSagas),
    fork(LoginSagas),
    fork(AccountSagas),
    fork(PlansSagas),
    fork(RegionsSagas),
    fork(PaymentSagas),
    fork(SubscriptionsSagas),
    fork(WorkstationsSagas),
    fork(FilesSagas),
    fork(AppStreamingSagas),
    fork(OrganizationsSagas),
  ]);
}
