import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      barPercentage: 0.1,
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        color: "#F8F8F8",
        drawBorder: false,
      },
      min: 0,

      ticks: {
        // forces step size to be 50 units
        stepSize: 1,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const getBarColors = () => {
  const colors = [];
  colors.length = 24;
  colors.fill("");
  return colors.map((elem, idx) => (idx % 2 === 0 ? "#ABB3FF" : "rgba(171, 179, 255, 0.5)"));
};

const SessionGraph = ({ timeSlots }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = {
    labels: timeSlots.map((singleSlot) => singleSlot.time),
    datasets: [
      {
        label: "Session",
        data: timeSlots.map((singleSlot) => singleSlot.count),
        barPercentage: "0.5",
        backgroundColor: getBarColors(),
      },
    ],
  };

  return <Bar data={data} options={options} key={windowWidth} />;
};

export default SessionGraph;
