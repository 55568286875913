import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "UI";
import { Logger, isSafari } from "Utils";
import { isAndroid, isMobile } from "Utils/Helpers/browser.helpers";

import "./ResizeButton.styles.scss";

const ResizeButton = ({ setShowResizeButton, resizeWindow }) => {
  const [fadeoutAnimation, setFadeoutAnimation] = useState(true);
  const resizeTimer = useRef(null);

  useEffect(() => {
    resizeTimer.current = setTimeout(() => {
      setShowResizeButton(false);
    }, 7000);

    return () => {
      clearTimeout(resizeTimer.current);
    };
  }, []); // eslint-disable-line

  const handleButtonHover = () => {
    setFadeoutAnimation(false);
    // cancel the previous timer.
    clearTimeout(resizeTimer.current);
  };

  const handleMouseLeave = () => {
    setFadeoutAnimation(true);
    resizeTimer.current = setTimeout(() => {
      setShowResizeButton(false);
    }, 6000);
  };

  return (
    <Button
      className={`resize-button ${fadeoutAnimation ? "fadeout-animation" : ""}`}
      onMouseEnter={handleButtonHover}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        setShowResizeButton(false);
        resizeWindow();
      }}
      text="Set Resolution"
    />
  );
};

const ResizeButtonWrapper = ({ resizeWindow }) => {
  // initial state must be null for Safari to prevent unlimited resize
  const [showResizeButton, setShowResizeButton] = useState(isSafari || isAndroid ? null : false);

  const debounceShowResizeButton = _.debounce(() => {
    Logger.log("WorkstationContainer| resize debounce", showResizeButton);
    handleResolutionChange(true);
  }, 500);

  useEffect(() => {
    window.addEventListener("resize", debounceShowResizeButton);
    document.addEventListener("visibilitychange", handleVisibilityStateChange);

    return () => {
      window.removeEventListener("resize", debounceShowResizeButton);
      document.removeEventListener("visibilitychange", handleVisibilityStateChange);
    };
  }, [showResizeButton]); // eslint-disable-line

  const handleVisibilityStateChange = () => {
    setShowResizeButton(null);
  };

  const handleResolutionChange = (showButton = true) => {
    if (isMobile) {
      if (showResizeButton !== null) resizeWindow();
      setShowResizeButton(false);
    } else {
      setShowResizeButton(showButton && showResizeButton !== null);
    }
  };

  return showResizeButton && <ResizeButton setShowResizeButton={setShowResizeButton} resizeWindow={resizeWindow} />;
};

export default ResizeButtonWrapper;
