export const GET_ACCOUNT_API_PENDING = "GET_ACCOUNT_API_PENDING";
export const GET_ACCOUNT_API_SUCCESS = "GET_ACCOUNT_API_SUCCESS";
export const GET_ACCOUNT_API_FAILURE = "GET_ACCOUNT_API_FAILURE";

export const GET_ACCOUNT_PREDEFINED_API_PENDING = "GET_ACCOUNT_PREDEFINED_API_PENDING";
export const GET_ACCOUNT_PREDEFINED_API_SUCCESS = "GET_ACCOUNT_PREDEFINED_API_SUCCESS";
export const GET_ACCOUNT_PREDEFINED_API_FAILURE = "GET_ACCOUNT_PREDEFINED_API_FAILURE";

export const APPLY_PROMOCODE_API_PENDING = "APPLY_PROMOCODE_API_PENDING";
export const APPLY_PROMOCODE_API_SUCCESS = "APPLY_PROMOCODE_API_SUCCESS";
export const APPLY_PROMOCODE_API_FAILURE = "APPLY_PROMOCODE_API_FAILURE";

export const SET_USER_PROFILE_API_PENDING = "SET_USER_PROFILE_API_PENDING";
export const SET_USER_PROFILE_API_SUCCESS = "SET_USER_PROFILE_API_SUCCESS";
export const SET_USER_PROFILE_API_FAILURE = "SET_USER_PROFILE_API_FAILURE";

export const CHANGE_PASSWORD_API_PENDING = "CHANGE_PASSWORD_API_PENDING";
export const CHANGE_PASSWORD_API_SUCCESS = "CHANGE_PASSWORD_API_SUCCESS";
export const CHANGE_PASSWORD_API_FAILURE = "CHANGE_PASSWORD_API_FAILURE";

export const GET_USER_PAYMENT_HISTORY_PENDING = "GET_USER_PAYMENT_HISTORY_PENDING";
export const GET_USER_PAYMENT_HISTORY_SUCCESS = "GET_USER_PAYMENT_HISTORY_SUCCESS";
export const GET_USER_PAYMENT_HISTORY_FAILURE = "GET_USER_PAYMENT_HISTORY_FAILURE";

export const GET_TRANSACTION_HISTORY_PENDING = "GET_TRANSACTION_HISTORY_PENDING";
export const GET_TRANSACTION_HISTORY_SUCCESS = "GET_TRANSACTION_HISTORY_SUCCESS";
export const GET_TRANSACTION_HISTORY_FAILURE = "GET_TRANSACTION_HISTORY_FAILURE";

export const GET_FAILED_PAYMENT_PENDING = "GET_FAILED_PAYMENTS_PENDING";
export const GET_FAILED_PAYMENT_SUCCESS = "GET_FAILED_PAYMENTS_SUCCESS";
export const GET_FAILED_PAYMENT_FAILURE = "GET_FAILED_PAYMENTS_FAILURE";

export const GET_MACHINE_USAGE_HISTORY_PENDING = "GET_MACHINE_USAGE_HISTORY_PENDING";
export const GET_MACHINE_USAGE_HISTORY_SUCCESS = "GET_MACHINE_USAGE_HISTORY_SUCCESS";
export const GET_MACHINE_USAGE_HISTORY_FAILURE = "GET_MACHINE_USAGE_HISTORY_FAILURE";

export const RESEND_CONFIRMATION_EMAIL_API_PENDING = "RESEND_CONFIRMATION_EMAIL_API_PENDING";
export const RESEND_CONFIRMATION_EMAIL_API_SUCCESS = "RESEND_CONFIRMATION_EMAIL_API_SUCCESS";
export const RESEND_CONFIRMATION_EMAIL_API_FAILURE = "RESEND_CONFIRMATION_EMAIL_API_FAILURE";

export const GET_PRODUCT_ONBOARDING_PENDING = "GET_PRODUCT_ONBOARDING_PENDING";
export const GET_PRODUCT_ONBOARDING_SUCCESS = "GET_PRODUCT_ONBOARDING_SUCCESS";
export const GET_PRODUCT_ONBOARDING_FAILURE = "GET_PRODUCT_ONBOARDING_FAILURE";
