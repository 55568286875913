import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";

import { CreditCard, Modal, Icon } from "UI";
import { AddPaymentMethodModal, PaymentDetails, Payment3DSecureModal, Loader } from "Utils";
import { getFailedPaymentAPI } from "Actions/Account.actions";
import { startPaymentAPI } from "Actions/Dashboard/Payment.actions";
import { REQUEST_STATUS } from "Constants/global.constants";
import PoweredByStripe from "Assets/images/stripe/powered_by_stripe.png";

import "./ProcessPaymentModal.styles.scss";

const ProcessPaymentModal = (props) => {
  const { account, payment, setShouldShowProcessPaymentModal, getFailedPaymentAPI, startPaymentAPI, translate } = props;
  const { startPaymentCTX } = payment || {};

  const [shouldShowAddPaymentMethodModal, setShouldShowAddPaymentMethodModal] = useState(false);
  const [showPayment3DSecure, setShowPayment3DSecure] = useState(false);
  const [totalAmountToPay, setTotalAmountToPay] = useState();
  const [error, setError] = useState(false);

  const paymentMethods = payment.getPaymentMethodsCTX.data || [];
  const preferredPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.attributes.preferred);
  const { failedPayment, failedPaymentStatus } = account.paymentHistoryCTX;
  const failedPaymentItems = (failedPayment && failedPayment.attributes.items) || [];

  useEffect(() => {
    getFailedPaymentAPI();
  }, []);

  useEffect(() => {
    if (failedPayment) {
      setTotalAmountToPay(failedPayment.attributes.total_amount);
    }
  }, [failedPayment]);

  useEffect(() => {
    if (startPaymentCTX.status === REQUEST_STATUS.FAILURE) {
      const errorCode = startPaymentCTX.error;
      // Set error message
      setError(errorCode);
    } else {
      setError(false);
    }
  }, [startPaymentCTX]);

  useEffect(() => {
    if (payment.payment3DSecureCTX.status === REQUEST_STATUS.PENDING) {
      if (payment.payment3DSecureCTX.data && props.payment.payment3DSecureCTX.data.payment_required === true) {
        setShowPayment3DSecure(true);
      }
    } else if (payment.payment3DSecureCTX.status === REQUEST_STATUS.SUCCESS) {
      setShouldShowProcessPaymentModal(false);
    }
  }, [payment.payment3DSecureCTX.status]); //eslint-disable-line

  const handleClose = () => {
    setShouldShowProcessPaymentModal(false);
  };

  const handleSubmit = () => {
    startPaymentAPI(account.accessToken);
  };

  if (shouldShowAddPaymentMethodModal) {
    return (
      <AddPaymentMethodModal setShowAddPaymentMethodModal={setShouldShowAddPaymentMethodModal} translate={translate} />
    );
  }

  if (showPayment3DSecure) {
    return (
      <Payment3DSecureModal
        onSuccess={() => {
          setShowPayment3DSecure(false);
        }}
        setShowPayment3DSecure={setShowPayment3DSecure}
      />
    );
  }

  const renderCreditCard = () => {
    if (
      payment.getPaymentMethodsCTX.status === REQUEST_STATUS.PENDING ||
      payment.createUserPaymentMethodCTX.status === REQUEST_STATUS.PENDING
    ) {
      return <Loader />;
    } else if (!preferredPaymentMethod) {
      return (
        <div
          className="add-new-payment-method"
          role="button"
          tabIndex="-1"
          onKeyDown={() => {
            setShouldShowAddPaymentMethodModal(true);
          }}
          onClick={() => {
            setShouldShowAddPaymentMethodModal(true);
          }}
        >
          <Icon name="plus" />
        </div>
      );
    }

    return (
      <CreditCard
        cardNumber={preferredPaymentMethod.attributes.last_four_digit}
        provider={preferredPaymentMethod.attributes.brand}
        // changeAction={(event) => {
        //   event.stopPropagation();
        //   setShouldShowAddPaymentMethodModal(true);
        // }}
        cardPlaceholder="Credit Card"
        preferred
        type="big"
      />
    );
  };

  return (
    <Modal
      closeOnOverlayClick
      closeAction={handleClose}
      topRightIcon="close"
      topRightIconAction={handleClose}
      headerText={translate("processPaymentModal.header")}
      descriptionText={translate("processPaymentModal.description")}
      buttonText={`${translate("processPaymentModal.buttonPrefix")} $${totalAmountToPay}`}
      buttonAction={handleSubmit}
      buttonDisabled={startPaymentCTX.status === REQUEST_STATUS.PENDING || !preferredPaymentMethod}
      errorText={
        error && (
          <Translate
            id={`processPaymentModal.error.${error}`}
            options={{ onMissingTranslation: () => translate("addBalanceModal.error.default") }}
          />
        )
      }
      errorable
    >
      <div className="vg-process-payment-modal">
        <div className="credit-card-section">
          {renderCreditCard()}
          <div className="stripe-info">
            <img src={PoweredByStripe} alt="Powered by Stripe" className="stripe-image" />
          </div>
        </div>
        {failedPaymentStatus === REQUEST_STATUS.PENDING ? (
          <Loader />
        ) : (
          <PaymentDetails
            headerText={translate("processPaymentModal.content.title")}
            items={failedPaymentItems.map((item) => {
              const paymentDetailName =
                item.attributes.description + (item.attributes.quantity > 1 ? ` x ${item.attributes.quantity}` : "");
              return {
                key: item.id,
                name: paymentDetailName,
                amount: item.attributes.amount,
              };
            })}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  payment: state.payment,
});

const actionCreators = {
  getFailedPaymentAPI,
  startPaymentAPI,
};

export default connect(mapStateToProps, actionCreators)(ProcessPaymentModal);
