import { useEffect } from "react";

const useHandleFirstClick = (enabled, callback) => {
  useEffect(() => {
    if (enabled) {
      const handleClick = () => {
        callback();
        document.removeEventListener("click", handleClick);
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }

    return undefined;
  }, [enabled, callback]);
};

export default useHandleFirstClick;
