import * as LoginConstants from "Constants/Authentication/Login.constants";
import * as ReferralConstants from "Constants/Referral.constants";
import { REQUEST_STATUS } from "Constants/global.constants";

const initialState = {
  getRewardsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
  },
  getRewardsStatusCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  claimRewardsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  inviteCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    clientCode: null,
    error: false,
  },
};

export default function referral(state = initialState, action) {
  switch (action.type) {
    case LoginConstants.LOGOUT_API_PENDING:
      return logoutAPIPending();
    case ReferralConstants.GET_REWARDS_API_PENDING:
      return getRewardsAPIPending(state);
    case ReferralConstants.GET_REWARDS_API_SUCCESS:
      return getRewardsAPISuccess(state, action);
    case ReferralConstants.GET_REWARDS_API_FAILURE:
      return getRewardsAPIFailure(state);
    case ReferralConstants.GET_REWARDS_STATUS_API_PENDING:
      return getRewardsStatusAPIPending(state);
    case ReferralConstants.GET_REWARDS_STATUS_API_SUCCESS:
      return getRewardsStatusAPISuccess(state, action);
    case ReferralConstants.GET_REWARDS_STATUS_API_FAILURE:
      return getRewardsStatusAPIFailure(state);
    case ReferralConstants.CLAIM_REWARDS_API_PENDING:
      return claimRewardsAPIPending(state);
    case ReferralConstants.CLAIM_REWARDS_API_SUCCESS:
      return claimRewardsAPISuccess(state);
    case ReferralConstants.CLAIM_REWARDS_API_FAILURE:
      return claimRewardsAPIFailure(state);
    case ReferralConstants.INVITE_API_PENDING:
      return inviteAPIPending(state);
    case ReferralConstants.INVITE_API_SUCCESS:
      return inviteAPISuccess(state);
    case ReferralConstants.INVITE_API_FAILURE:
      return inviteAPIFailure(state, action);
    default:
      return state;
  }
}

function logoutAPIPending() {
  return initialState;
}

function getRewardsAPIPending(state) {
  return {
    ...state,
    getRewardsCTX: {
      ...state.getRewardsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getRewardsAPISuccess(state, action) {
  const {
    referrer_signup_reward: referrerReward,
    referre_signup_reward: referreReward,
    invite_messages: messageTemplates,
    has_rewards: isRewardsActive,
    monthly_reward_limit: monthlyRewardLimit,
    total_reward_limit: totalRewardLimit,
  } = action.payload;

  return {
    ...state,
    getRewardsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: { referrerReward, referreReward, messageTemplates, isRewardsActive, monthlyRewardLimit, totalRewardLimit },
    },
  };
}

function getRewardsAPIFailure(state) {
  return {
    ...state,
    getRewardsCTX: {
      ...state.getRewardsCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getRewardsStatusAPIPending(state) {
  return {
    ...state,
    getRewardsStatusCTX: {
      ...state.getRewardsStatusCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getRewardsStatusAPISuccess(state, action) {
  const {
    unclaimed_balance: unclaimedRewards,
    total_earned_balance: totalRewards,
    gifted_balance: givenRewards,
    referral_count: registerCount,
    monthly_earned_balance: monthlyEarnedRewards,
  } = action.payload;

  return {
    ...state,
    getRewardsStatusCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: { unclaimedRewards, totalRewards, givenRewards, registerCount, monthlyEarnedRewards },
    },
  };
}

function getRewardsStatusAPIFailure(state) {
  return {
    ...state,
    getRewardsStatusCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function claimRewardsAPIPending(state) {
  return {
    ...state,
    claimRewardsCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function claimRewardsAPISuccess(state) {
  return {
    ...state,
    claimRewardsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function claimRewardsAPIFailure(state) {
  return {
    ...state,
    claimRewardsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
      data: null,
    },
  };
}

function inviteAPIPending(state) {
  return {
    ...state,
    inviteCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function inviteAPISuccess(state) {
  return {
    ...state,
    inviteCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function inviteAPIFailure(state, action) {
  return {
    ...state,
    inviteCTX: {
      status: REQUEST_STATUS.FAILURE,
      clientCode: action.payload.client_code,
      error: true,
    },
  };
}
