import React, { useEffect } from "react";
import { APP_SCOPES, ROOT_PATHS } from "Constants/global.constants";
import { useAppScope } from "Utils/Hooks/useAppScope";
import ProtectedRouteWithTranslation from "Utils/Components/Route/Helpers/ProtectedRouteWithTranslation";

const OrganizationMemberRoute = (props) => {
  const { path, account, history } = props;
  const { appScope, setAppScope } = useAppScope();

  const { organization_admin: organizationAdmin, organization_machine: organizationMachine } =
    account?.account?.attributes || {};

  useEffect(() => {
    if (appScope !== APP_SCOPES.team_member) {
      history.push(ROOT_PATHS[appScope]);
      return;
    }

    if (!organizationMachine && organizationAdmin) {
      setAppScope(APP_SCOPES.user);
    }
  }, [path, account.accessToken, account.getAccountCTX.status, organizationMachine, appScope]);

  return <ProtectedRouteWithTranslation {...props} />;
};

export default OrganizationMemberRoute;
