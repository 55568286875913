import { REQUEST_STATUS } from "Constants/global.constants";
import * as ForgetPasswordConstants from "Constants/Authentication/ForgetPassword.constants";

const initialState = {
  status: REQUEST_STATUS.NOT_DEFINED,
  error: false,
  client_code: null,
};

export default function forgetPasswordCTX(state = initialState, action) {
  switch (action.type) {
    case ForgetPasswordConstants.FORGET_PASSWORD_CLEAR: {
      return initialState;
    }
    case ForgetPasswordConstants.FORGET_PASSWORD_API_PENDING: {
      return forgetPasswordAPIPending(state);
    }
    case ForgetPasswordConstants.FORGET_PASSWORD_API_SUCCESS: {
      return forgetPasswordAPISuccess(state);
    }
    case ForgetPasswordConstants.FORGET_PASSWORD_API_FAILURE: {
      return forgetPasswordAPIFailure(state, action);
    }
    default: {
      return state;
    }
  }
}

function forgetPasswordAPIPending(state) {
  return {
    ...state,
    status: REQUEST_STATUS.PENDING,
    error: false,
    client_code: null,
  };
}

function forgetPasswordAPISuccess(state) {
  return {
    ...state,
    status: REQUEST_STATUS.SUCCESS,
    error: false,
    client_code: null,
  };
}

function forgetPasswordAPIFailure(state, action) {
  const { client_code } = action.payload;
  return {
    ...state,
    status: REQUEST_STATUS.FAILURE,
    error: true,
    client_code,
  };
}
