import React from "react";
import logo from "Assets/images/logo/vg-logo-black.svg";
import streamsLogo from "Assets/icons/appStreaming/streams-logo.svg";
import "./AuthenticationHeader.style.scss";

const AuthenticationHeader = (props) => {
  const { title, description, descriptionActionText, descriptionAction, appStreaming } = props;
  return (
    <div className="vg-authentication-header">
      {appStreaming ? (
        <img className="logo" src={streamsLogo} alt="Vagon" />
      ) : (
        <img className="logo" src={logo} alt="Vagon" />
      )}
      <div className="title">{title}</div>
      <div className="description">
        <span>{description}</span>
        {descriptionActionText && descriptionAction && (
          <>
            {" "}
            <span
              className="description-action-text"
              onClick={descriptionAction}
              role="button"
              tabIndex="0"
              onKeyUp={(event) => {
                event.preventDefault();
                if (event.key === "Enter") {
                  descriptionAction();
                }
              }}
            >
              {descriptionActionText}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthenticationHeader;
