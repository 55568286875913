import { getOrganizationAPI } from "Actions/Organization.actions";
import { REQUEST_STATUS } from "Constants/global.constants";
import { DashboardSubScreenLayout } from "Utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "UI";

import Shape1 from "Assets/images/elements/purpleBall.png";
import Shape2 from "Assets/images/elements/purpleBlock.png";

import "./TeamInfo.styles.scss";

const TeamInfo = ({ translate }) => {
  const dispatch = useDispatch();

  const { getOrganizationCTX, organization } = useSelector((state) => state.organizations);
  const { members, name } = organization?.attributes || {};

  useEffect(() => {
    if (getOrganizationCTX.status === REQUEST_STATUS.NOT_DEFINED) {
      dispatch(getOrganizationAPI());
    }
  }, [getOrganizationCTX.status]);

  return (
    <DashboardSubScreenLayout 
      headerTitle="Teams" 
      headerDescription="Here is the rest of your team, more features to come soon" 
      translate={translate}
    >
      <div className="member-team-info-container">
        <img className="left-corner" src={Shape2} />
        <img className="right-corner" src={Shape1} />
        <p>Team</p>
        <h1>{name}</h1>
        <div className="team-members-container">
          {members?.map((member) => (
            <Button key={member.id} text={member.attributes.name || member.attributes.email?.split("@")[0]} />
          ))}
        </div>
      </div>
    </DashboardSubScreenLayout>
  );
};

export default TeamInfo;
