import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";
import { getItemFromLocalStorage } from "Utils/Helpers/storage.helpers";
import React, { useEffect, useState } from "react";
import AppStreamingSendTokenToPlugin from "Components/AppStreaming/SendTokenToPlugin/SendTokenToPlugin.component";
import { useSelector } from "react-redux";

export default function AppStreamingSendTokenToPluginContainer(props) {
  const [status, setStatus] = useState(REQUEST_STATUS.PENDING);

  const { account } = useSelector((state) => state.account);
  const { id } = account || {};
  const { vendor_admin: vendorAdmin, vendor_member: vendorMember, source } = account?.attributes || {};

  const showLanding = !(vendorAdmin || vendorMember);

  const sendToken = async () => {
    const token = getItemFromLocalStorage(LOCAL_STORAGE.accessToken);
    if (!token) {
      setStatus(REQUEST_STATUS.FAILURE);
      return;
    }

    let url = `http://localhost:48246/token?t=${token}`;

    if (source) {
      url += `&source=${source}`;
      url += `&uid=${id}`;
    }

    try {
      const response = await fetch(url, { mode: "no-cors" });

      if (!response.status === 200) {
        setStatus(REQUEST_STATUS.FAILURE);
        return;
      }

      setStatus(REQUEST_STATUS.SUCCESS);
    } catch {
      setStatus(REQUEST_STATUS.FAILURE);
    }
  };

  useEffect(() => {
    if (!showLanding) {
      sendToken();
    }
  }, [showLanding]);

  return <AppStreamingSendTokenToPlugin status={status} showLanding={showLanding} {...props} />;
}
