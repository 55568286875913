import React from "react";
import Joi from "joi";
import AuthenticationHeader from "Utils/Components/Authentication/AuthenticationHeader/AuthenticationHeader.component";
import { Button, PasswordInput, TopLoader } from "UI";
import { Animation } from "Utils";
import routes from "Constants/Route.constants";
import { ERROR_CODE, REQUEST_STATUS } from "Constants/global.constants";

const ValidationSchema = Joi.object({
  password: Joi.string().min(8).required().messages({
    "string.empty": "Set a password for your account",
    "any.required": "Set a password for your account",
    "string.min": "Password must be at least 8 characters",
  }),
});

const ForgetPasswordResetComponent = (props) => {
  const { match, forgetPasswordResetAPI, forgetPasswordResetCTX, translate, history } = props;
  const [password, setPassword] = React.useState("");
  const [isStrongPassword, setIsStrongPassword] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (forgetPasswordResetCTX.status === REQUEST_STATUS.SUCCESS) {
      setTimeout(() => {
        history.push(routes.login);
      }, 1000);
    } else if (forgetPasswordResetCTX.status === REQUEST_STATUS.FAILURE) {
      if (forgetPasswordResetCTX.error) {
        const { client_code: clientCode } = forgetPasswordResetCTX.error;

        if (clientCode && clientCode === ERROR_CODE.GONE) {
          history.push({
            pathname: routes.forgetPassword,
            state: {
              from: routes.forgetPasswordReset,
            },
          });
        }
      }

      setError(translate("forgetPasswordReset.error.default"));
    }
  }, [history, forgetPasswordResetCTX.status]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const { error: validationError } = ValidationSchema.validate({ password });

    if (validationError) {
      setError(validationError.message);
    } else if (!isStrongPassword) {
      // Prevent submission if new password is not valid
      setError(translate("forgetPasswordReset.error.weakPassword"));
    } else {
      setError("");
      forgetPasswordResetAPI({ password }, match.params.code);
    }
  };

  return (
    <>
      {forgetPasswordResetCTX.status === REQUEST_STATUS.PENDING && <TopLoader />}
      <div className="vg-forget-password-reset-content form-container">
        <form onSubmit={handleSubmit} noValidate>
          <AuthenticationHeader
            title={
              forgetPasswordResetCTX.status === REQUEST_STATUS.SUCCESS
                ? translate("forgetPasswordReset.header.titleSuccess")
                : translate("forgetPasswordReset.header.title")
            }
            description={
              forgetPasswordResetCTX.status === REQUEST_STATUS.SUCCESS ? (
                <>
                  {translate("forgetPasswordReset.header.descriptionSuccess")}
                  <Animation type="success" style={{ height: "6rem", width: "6rem" }} />
                </>
              ) : (
                translate("forgetPasswordReset.header.description")
              )
            }
          />
          <div className="field-group">
            <PasswordInput
              label={translate("forgetPasswordReset.form.password.label")}
              placeholder={translate("forgetPasswordReset.form.password.placeholder")}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              hasError={!!error}
              errorText={error}
              setIsStrongPassword={setIsStrongPassword}
              showStrength
            />
          </div>
          <Button
            type="submit"
            disabled={forgetPasswordResetCTX.status === REQUEST_STATUS.PENDING || !password}
            text={translate("forgetPasswordReset.form.button.text")}
            block
          />
        </form>
      </div>
    </>
  );
};

export default ForgetPasswordResetComponent;
