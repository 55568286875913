import React from "react";
import { connect } from "react-redux";

import LoginComponent from "Components/Authentication/Login/Login.component";
import { loginAPI, loginClear } from "Actions/Authentication/Login.actions";
import { saveItemToSessionStorage } from "Utils/Helpers/storage.helpers";
import { REDIRECT_FROM_SOURCES, SESSION_STORAGE } from "Constants/global.constants";

class AppStreamingLoginFromPluginContainer extends React.Component {
  componentDidMount() {
    const { loginClear } = this.props;
    loginClear();

    saveItemToSessionStorage(SESSION_STORAGE.source, REDIRECT_FROM_SOURCES.unity);
  }

  render() {
    return <LoginComponent appStreaming loginFromPlugin {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  login: state.login,
});

const actionCreators = {
  loginAPI,
  loginClear,
};

export default connect(mapStateToProps, actionCreators)(AppStreamingLoginFromPluginContainer);
