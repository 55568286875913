import React, { useState, useEffect, useRef } from "react";
import Joi from "joi";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { Modal } from "UI";
import { REQUEST_STATUS } from "Constants/global.constants";
import { useAPI } from "Utils/Helpers/api.helpers";
import { API_ENDPOINTS, APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { getOrganizationAPI } from "Actions/Organization.actions";
import { getVendorAccountAPI } from "Actions/AppStreaming.actions";
import { COUNTRY_LIST_ALPHA } from "Constants/countries.constants";
import AddBillingAddressForm from "../AddBillingAddressForm/AddBillingAddressForm.component";

const COUNTRY_LIST = Object.keys(COUNTRY_LIST_ALPHA);

const AddBillingAddressModal = (props) => {
  const {
    getOrganizationAPI,
    getVendorAccountAPI,
    payment,
    billingInformation,
    setShowAddBillingAddressModal,
    onClose,
    translate,
    vendor,
  } = props;

  const [billingDetails, setBillingDetails] = React.useState({
    companyName: billingInformation.company_name || "",
    taxId: billingInformation.tax_id || "",
    country: billingInformation.country || "",
    addressLine1: billingInformation.address_line_one || "",
    addressLine2: billingInformation.address_line_two || "",
    city: billingInformation.city || "",
    postalCode: billingInformation.postal_code || "",
  });

  const [error, setError] = React.useState();

  const [errors, setErrors] = React.useState({
    nameError: "",
    taxIdError: "",
    countryError: "",
    addressLineError: "",
    cityError: "",
    postalCodeError: "",
  });

  const [pending, setPending] = useState();
  const [isBillingAddressFormComplete, setIsBillingAddressFormComplete] = useState(false);

  const paymentMethodFormRef = useRef(null);

  const { api: updateBillingInformationAPI } = useAPI({
    type: "put",
    endpoint: vendor
      ? APP_STREAMING_API_ENDPOINTS.UPDATE_VENDOR_BILLING_INFO
      : API_ENDPOINTS.ORGANIZATION_BILLING_DETAILS_DEFAULT,
    onSuccess: () => {
      if (vendor) {
        getVendorAccountAPI();
      } else {
        getOrganizationAPI();
      }
      setShowAddBillingAddressModal(false);
    },
    onErr: (error) => {
      setError({ clientCode: error.client_code });
      setPending(false);
    },
  });

  useEffect(() => {
    if (payment.createUserPaymentMethodCTX.status !== REQUEST_STATUS.PENDING) {
      setPending(false);
      if (payment.createUserPaymentMethodCTX.status === REQUEST_STATUS.SUCCESS) {
        setShowAddBillingAddressModal(false);
      } else if (payment.createUserPaymentMethodCTX.error) {
        setError({ clientCode: payment.createUserPaymentMethodCTX.client_code });
      }
    }
  }, [payment.createUserPaymentMethodCTX]);

  const validate = () => {
    const companyNameError = Joi.string().min(3).max(30).required().validate(billingDetails.companyName).error;
    const taxIdError = Joi.string().max(20).validate(billingDetails.taxId).error;
    const addressLineError = Joi.string().min(6).required().validate(billingDetails.addressLine1).error;
    const countryError = Joi.string()
      .valid(...COUNTRY_LIST)
      .validate(billingDetails.country).error;
    const cityError = Joi.string().min(3).required().validate(billingDetails.city).error;
    const postalCodeError = Joi.string().max(15).validate(billingDetails.postalCode).error;

    setErrors({
      companyNameError,
      taxIdError,
      countryError,
      addressLineError,
      cityError,
      postalCodeError,
    });
    return companyNameError || taxIdError || countryError || addressLineError || cityError || postalCodeError;
  };

  const onActionButtonClick = () => {
    if (isBillingAddressFormComplete && !pending) {
      const error = validate();
      if (!error) {
        setPending(true);
        updateBillingInformationAPI({
          company_name: billingDetails.companyName,
          tax_id: billingDetails.taxId,
          address_line_one: billingDetails.addressLine1,
          address_line_two: billingDetails.addressLine2,
          postal_code: billingDetails.postalCode,
          city: billingDetails.city,
          country: billingDetails.country,
        });
      }
    }
  };

  const handleClose = () => {
    if (pending) return;

    if (onClose) {
      onClose();
    } else {
      setShowAddBillingAddressModal(false);
    }
  };

  return (
    <Modal
      className="centered"
      closeOnOverlayClick
      closeAction={handleClose}
      topRightIcon="close"
      topRightIconAction={handleClose}
      headerText={translate("addBillingAddressForm.header")}
      buttonText={translate("addBillingAddressForm.complete")}
      buttonDisabled={pending || !isBillingAddressFormComplete}
      buttonAction={onActionButtonClick}
      errorText={
        error && (
          <Translate
            id={`addBillingAddressForm.error.${error.clientCode}`}
            options={{ onMissingTranslation: () => translate("addBillingAddressForm.error.default") }}
          />
        )
      }
      small
      errorable
    >
      <AddBillingAddressForm
        formRef={paymentMethodFormRef}
        billingDetails={billingDetails}
        setBillingDetails={setBillingDetails}
        setPending={setPending}
        setIsBillingAddressFormComplete={setIsBillingAddressFormComplete}
        errors={errors}
        setError={setError}
        translate={translate}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  payment: state.payment,
});

const actionCreators = {
  getOrganizationAPI,
  getVendorAccountAPI,
};

export default connect(mapStateToProps, actionCreators)(AddBillingAddressModal);
