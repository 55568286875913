import React, { useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import Tracker from "Utils/Helpers/tracker.helpers";
import AuthenticationLayout from "Utils/Components/Layouts/AuthenticationLayout/AuthenticationLayout";
import authenticationTranslations from "Translations/authentication.json";
import appStreamingTranslations from "Translations/appStreaming.json";
import routes from "Constants/Route.constants";
import { connect } from "react-redux";
import { useAPIRequest } from "Utils/Helpers/api.helpers";
import { API_ENDPOINTS } from "Constants/api.constants";

const AuthenticationRoute = (props) => {
  const { account, addTranslation, component: Component, exact, history, path } = props;

  useEffect(() => {
    Tracker.event({ type: "page_view" });
  }, [path]);

  useEffect(() => {
    if (account.accessToken) {
      if (history.location.pathname === routes.appStreamingLoginFromPlugin)
        history.push(routes.appStreamingSendTokenToPlugin);
      else history.push(routes.home);
    }
  }, []);

  useEffect(() => {
    addTranslation(authenticationTranslations);
    addTranslation(appStreamingTranslations);
  }, [addTranslation]);

  return (
    <Translate>
      {({ translate }) => (
        <Route
          exact={exact}
          path={path}
          render={(props) => (
            <AuthenticationLayout {...props}>
              <Component {...props} translate={translate} />
            </AuthenticationLayout>
          )}
        />
      )}
    </Translate>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps)(withRouter(withLocalize(AuthenticationRoute)));
