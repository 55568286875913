import React from "react";

import "./Divider.styles.scss";
import { Icon, TopLoader } from "UI";

const Divider = (props) => {
  const { withLogo, isLoading, color } = props;

  let lineWrapperClassName = "line-wrapper";

  if (withLogo) {
    lineWrapperClassName += " with-logo";
  }

  return (
    <div className="divider-container">
      <div className={lineWrapperClassName}>
        <div className="divider-line" />
        {withLogo && (
          <>
            <div className="divider-logo">
              <Icon name="vagon-logo" color={color || "gray-4"} />
            </div>
            <div className="divider-line" />
          </>
        )}
      </div>
      {isLoading && <TopLoader isInsideComponent ping transitionTime={500} />}
    </div>
  );
};

export default Divider;
