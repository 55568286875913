import React from "react";

import { Icon } from "UI";
import { shortenFileName } from "Utils";

const preparePath = (path) => {
  return path
    .split("/")
    .map((fileName) => shortenFileName(fileName, 15))
    .join(" > ");
};

const FileModalBreadcrumb = (props) => {
  const { currentFolder, path, goToFolder } = props;

  const stringPath =
    currentFolder &&
    currentFolder.attributes &&
    (currentFolder.attributes.object_type !== "root" ? currentFolder.attributes.path : "Teams");

  const handleKeyUp = (event, dir) => {
    event.preventDefault();

    if (event.key === "Enter") {
      goToFolder(dir.id);
    }
  };

  return (
    <div className="breadcrumb">
      {stringPath ? (
        <div className="directories">
          {path?.length > 1 ? (
            path.map((dir, index) => {
              const isFirstOrLastItem = index === 0 || index === path.length - 1;

              if (!isFirstOrLastItem && index === path.length - 2) {
                return (
                  <div
                    className="breadcrumb-button"
                    role="button"
                    tabIndex={0}
                    onKeyUp={(event) => handleKeyUp(event, dir)}
                    key={dir.id}
                    onClick={() => {
                      goToFolder(dir.id);
                    }}
                  >
                    ...
                    {index < path.length - 1 && <Icon name="chevron-right" color="black-stroke black" />}
                  </div>
                );
              }

              if (isFirstOrLastItem) {
                return (
                  <div
                    className="breadcrumb-button"
                    role="button"
                    tabIndex={0}
                    onKeyUp={(event) => handleKeyUp(event, dir)}
                    key={dir.id}
                    onClick={() => {
                      goToFolder(dir.id);
                    }}
                  >
                    <span>{dir.name}</span>
                    {index < path.length - 1 && <Icon name="chevron-right" color="black-stroke black" />}
                  </div>
                );
              }
              return null;
            })
          ) : (
            <div className="current-path">{preparePath(stringPath)}</div>
          )}
        </div>
      ) : (
        <span>Main</span>
      )}
    </div>
  );
};

export default FileModalBreadcrumb;
