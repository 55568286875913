/* eslint import/prefer-default-export: 0 */
/* eslint no-bitwise: 0 */

import { useCallback, useEffect, useRef, useState } from "react";

export function useClick(initialRefValue, callback) {
  const ref = useRef(initialRefValue);

  const handleClick = (e) => {
    if (ref.current && e.target && e.target.contains(ref.current)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [callback]);

  return ref;
}

export function useClickOutside(initialRefValue, callback) {
  const ref = useRef(initialRefValue);

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return ref;
}

export function useWindowResize(initialRefValue, callback) {
  const ref = useRef(initialRefValue);

  useEffect(() => {
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []);

  return ref;
}

export function useBlockKeyboardKey(key) {
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    return () => {
      if (isBlocked) {
        window.removeEventListener("keydown", keyPressEvent, false);
      }
    };
  }, []);

  const keyPressEvent = (event) => {
    if (event.key === key) {
      event.preventDefault();
    }
  };

  const toggle = () => {
    if (isBlocked) {
      window.removeEventListener("keydown", keyPressEvent, true);
    } else {
      window.addEventListener("keydown", keyPressEvent, true);
    }
    setIsBlocked(!isBlocked);
  };

  return {
    toggle,
    isBlocked,
  };
}

export function useFocusNext() {
  const controls = useRef([]);

  const handler = (event) => {
    if (event.key === "Enter") {
      // Required if the controls can be reordered
      controls.current = controls.current
        .filter((control) => document.body.contains(control))
        .sort((a, b) => (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1));

      const index = controls.current.indexOf(event.target);
      const next = controls.current[index + 1];
      if (next) next.focus();

      // IE 9, 10
      event.preventDefault();
    }
  };

  const focusFirst = () => {
    controls.current = controls.current
      .filter((control) => document.body.contains(control))
      .sort((a, b) => (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1));

    const first = controls.current[0];
    if (first) first.focus();
  };

  const focusFirstEmpty = () => {
    controls.current = controls.current
      .filter((control) => document.body.contains(control) && !control.value)
      .sort((a, b) => (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1));

    const firstEmpty = controls.current[0];
    if (firstEmpty) firstEmpty.focus();
  };

  return {
    focusNextRef: useCallback((element) => {
      if (element && !controls.current.includes(element)) {
        controls.current.push(element);
        element.addEventListener("keydown", handler);
      }
    }, []),
    focusFirst,
    focusFirstEmpty,
  };
}

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useIsLoaded({ onLoad }) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.onload = () => {
        onLoad();
      };
    }
  }, [ref.current]);

  return ref;
}

export function useDragged() {
  const [isDragActive, setIsDragActive] = useState(0);

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragActive(isDragActive + 1);
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragActive(isDragActive - 1);
  };

  const resetDrag = () => {
    setIsDragActive(0);
  };

  return { isDragActive: !!isDragActive, onDragOver, onDragEnter, onDragLeave, resetDrag };
}

const RESET_ERROR_TIMEOUT = 4000;

// Resets error to false in given timeout
export function useResetError(error, setError, errorTimeout = RESET_ERROR_TIMEOUT) {
  useEffect(() => {
    const resetErrorTimeout = setTimeout(() => setError(false), errorTimeout);
    return () => clearTimeout(resetErrorTimeout);
  }, [error]);
}
