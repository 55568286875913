import Ping from "./Ping";

const REGION_LINKS = {
  dublin: "https://dynamodb.eu-west-1.amazonaws.com",
  frankfurt: "https://dynamodb.eu-central-1.amazonaws.com",
  north_virginia: "https://dynamodb.us-east-1.amazonaws.com",
  oregon: "https://dynamodb.us-west-2.amazonaws.com",
  northern_california: "https://dynamodb.us-west-1.amazonaws.com",
  mumbai: "https://dynamodb.ap-south-1.amazonaws.com",
  singapore: "https://dynamodb.ap-southeast-1.amazonaws.com",
  sydney: "https://dynamodb.ap-southeast-2.amazonaws.com",
  tokyo: "https://dynamodb.ap-northeast-1.amazonaws.com",
  bahrain: "https://dynamodb.me-south-1.amazonaws.com",
  hong_kong: "https://dynamodb.ap-east-1.amazonaws.com",
  sao_paulo: "https://dynamodb.sa-east-1.amazonaws.com",
  seoul: "https://dynamodb.ap-northeast-2.amazonaws.com",
  ohio: "https://dynamodb.us-east-2.amazonaws.com",
  montreal: "https://dynamodb.ca-central-1.amazonaws.com",
  stockholm: "https://dynamodb.eu-north-1.amazonaws.com",
  cape_town: "https://dynamodb.af-south-1.amazonaws.com",
  israel: "https://dynamodb.il-central-1.amazonaws.com/ping",
  jakarta: "https://dynamodb.ap-southeast-3.amazonaws.com/ping",
  uae: "https://dynamodb.me-central-1.amazonaws.com/ping",
};

const ACTIVE_REGIONS = [
  "dublin",
  "frankfurt",
  "north_virginia",
  "oregon",
  "northern_california",
  "mumbai",
  "singapore",
  "sydney",
  "tokyo",
  "bahrain",
  "hong_kong",
  "sao_paulo",
  "seoul",
  "ohio",
  "cape_town",
  "israel",
  "jakarta",
  "uae",
];

const CONNECTION_TEST_DELAY = 100;

class VagonPinger {
  /**
   * Creates a Vagon Pinger instance.
   * @returns {VagonPinger}
   * @constructor
   */
  constructor(opt) {
    this.terminated = false;
    this.ping = new Ping(opt);
    this.regions = [];
    this.latencies = [];
    this.minLatency = 0;
    this.pingTimeout = null;
  }

  /**
   * Adds new latency to latencies object.
   * @param region Region that pinged
   * @param ping Ping value for pinged region
   * @param callback Callback function to trigger when all regions pinged.
   * Returns best region, sorted region array, sorted {region, ping} objects.
   */
  addLatency(region, ping, callback) {
    this.latencies.push({ region, ping });
    if (this.regions.length === Object.values(this.latencies).length) {
      this.latencies.sort((a, b) => a.ping - b.ping);
      const sortedRegionList = this.latencies.map((l) => l.region);
      const bestRegion = sortedRegionList[0];
      callback(bestRegion, sortedRegionList, this.latencies);
    }
  }

  /**
   * Pings source and triggers a callback when completed.
   * @param callback Callback function to trigger when completed. Returns best region, sorted region array, sorted {region, ping} objects.
   * @param regions Optional array for regions that will be pinged, default is all Vagon regions.
   * @param connectionTestDelay Optional delay(ms) to start pinging.
   */
  checkConnectionStrength(callback = () => 0, regions = ACTIVE_REGIONS, connectionTestDelay = CONNECTION_TEST_DELAY) {
    // Reset previous latency values
    this.minLatency = 0;
    this.latencies = [];
    this.regions = regions;

    // Clear timeout
    clearTimeout(this.pingTimeout);
    this.pingTimeout = setTimeout(() => {
      regions.forEach((region) => {
        if (REGION_LINKS[region]) {
          this.ping.ping(
            REGION_LINKS[region],
            (err, data) => {
              if (data) {
                this.addLatency(region, data, callback);
              }
            },
            10,
          );
        }
      });
    }, connectionTestDelay);
  }

  async regionPing(region, callback) {
    await this.ping.ping(
      REGION_LINKS[region],
      (err, data) => {
        callback(err, data);
      },
      10,
    );
  }

  /**
   * This function sets the field that prevents further call
   */
  terminate() {
    clearTimeout(this.pingTimeout);
    this.terminated = true;
  }
}

export default VagonPinger;
