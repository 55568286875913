import React, { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator } from "Utils";
import { Modal, TextButton } from "UI";
import ExpandableAnimation from "UI/ExpandableAnimation/ExpandableAnimation.component";
import ModalResult from "../../Components/ModalResult/ModalResult.component";
import "./ComputerStorageModal.styles.scss";

const ComputerStorageModal = ({ onClose, seat, diskSize, translate, history }) => {
  const { organizationPlan } = useSelector((state) => state.organizations?.getOrganizationPlanCTX) || {};
  const { disk_options: diskOptions } = organizationPlan?.attributes || {};
  const diskSizes = diskOptions?.map((diskOption) => diskOption.attributes.size);

  const [calculationData, setCalculationData] = useState(null);
  const [diskSizeIndex, setDiskSizeIndex] = useState(diskSizes.indexOf(diskSize));

  const calculateDiskSizePriceMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS_CALCULATE_DISK_SIZE_PRICE(seat.id), data);
    },
    onSuccess: (data) => {
      setCalculationData(data);
    },
  });

  const {
    current_month_price: currentMonthPrice,
    renewal_price: renewalPrice,
    is_balance_sufficient: isBalanceSufficient,
  } = calculationData?.data || {};

  const upgradeDiskSizeMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEATS_UPGRADE_DISK_SIZE(seat.id), data);
    },
  });

  const { isSuccess, isError } = upgradeDiskSizeMutation;

  useEffect(() => {
    calculateDiskSizePriceMutation.mutate({
      disk_size: diskSizes[diskSizeIndex],
    });
  }, [diskSizeIndex]);

  return (
    <Modal
      className="computer-storage-modal"
      headerText={translate("organizationHome.computerStorageModal.header.title")}
      descriptionText={translate("organizationHome.computerStorageModal.header.description")}
    >
      <div className="computer-storage-modal-content">
        {isSuccess || isError ? (
          <ModalResult
            success={isSuccess}
            successHeader={translate("organizationHome.computerStorageModal.success.title")}
            successDescription={translate("organizationHome.computerStorageModal.success.description")}
            failureHeader={translate("organizationHome.computerStorageModal.error.title")}
            failureDescription={translate("organizationHome.computerStorageModal.error.description")}
            failure={isError}
            onClose={onClose}
          />
        ) : (
          <>
            <div className="storage-buttons">
              <button
                type="button"
                label="Decrement"
                disabled={diskSizes.indexOf(diskSize) === diskSizeIndex}
                onClick={() => {
                  setDiskSizeIndex(diskSizeIndex - 1);
                }}
              >
                -
              </button>
              <p>{diskSizes[diskSizeIndex]} GB</p>
              <button
                type="button"
                label="Increment"
                disabled={diskSizeIndex === diskSizes.length - 1}
                onClick={() => {
                  setDiskSizeIndex(diskSizeIndex + 1);
                }}
              >
                +
              </button>
            </div>

            <ExpandableAnimation isOpen={diskSizes.indexOf(diskSize) !== diskSizeIndex}>
              <div className="storage-price-info">
                {isBalanceSufficient ? (
                  <>
                    ${currentMonthPrice.toFixed(2)} will be deducted from your Team balance for the remaining days of
                    the Seat Plan, then it will be renewed from ${parseFloat(renewalPrice).toFixed(2)} / month.
                  </>
                ) : (
                  <div className="insufficient-balance">
                    <Translate id="organizationHome.usageCreditModal.insufficientBalance" />
                    <TextButton
                      text="Add Balance"
                      color="purple-main"
                      onClick={() => {
                        history.push({
                          pathname: routes.organizationAdminBilling,
                          state: { showDepositBalanceModal: true },
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </ExpandableAnimation>

            <div className="buttons-container">
              <TextButton text="Cancel" color="gray-3" onClick={onClose} />
              <TextButton
                text="Increase Storage"
                color="aqua-main"
                disabled={diskSizes.indexOf(diskSize) === diskSizeIndex || !isBalanceSufficient}
                onClick={() => {
                  upgradeDiskSizeMutation.mutate({
                    disk_size: diskSizes[diskSizeIndex],
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ComputerStorageModal;
