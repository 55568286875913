import React, { useRef, useState } from "react";
import { Steps } from "intro.js-react";
import ChooseApplicationToStreamImage from "Assets/images/onboardingModals/choose-application-to-stream.jpg";
import StreamReadyImage from "Assets/images/onboardingModals/stream-ready.jpg";
import { STREAMS_ONBOARDING_STEPS } from "Constants/StreamsOnboarding.constants";

import "intro.js/introjs.css";
import "UI/OnboardingModal/OnboardingModal.styles.scss";

const StreamsOnboardingModal = ({ setCurrentStep, setShowStreamsDummyData, onExit, translate }) => {
  const backgroundClass = document.getElementById("dashboard-content-container");
  const stepsObject = useRef(null);
  const [firstStep, setFirstStep] = useState(true);
  const [thirdStep, setThirdStep] = useState(true);

  const steps = [
    {
      element: ".app-list-card-container.sample-app",
      title: `<img src=${ChooseApplicationToStreamImage} />`,
      intro: `<h1>${translate("streamsOnboardingModal.streams.appChoose.header")}</h1><p>${translate(
        "streamsOnboardingModal.streams.appChoose.description",
      )}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
    {
      element: ".table-row.sample-stream",
      title: `<img src=${StreamReadyImage} />`,
      intro: `<h1>${translate("streamsOnboardingModal.streams.sampleStream.header")}</h1><p>${translate(
        "streamsOnboardingModal.streams.sampleStream.description",
      )}</p>`,
      position: "left",
      tooltipClass: "onboarding-modal-floating",
      highlightClass: "myHighlightClass",
    },
  ];

  const onBeforeChange = (currentStep) => {
    setCurrentStep(currentStep);
    if (currentStep === STREAMS_ONBOARDING_STEPS.appChoose.step) {
      backgroundClass.style.overflowY = "hidden";
      if (firstStep) {
        setFirstStep(false);
        setTimeout(() => {
          stepsObject.current.updateStepElement(0);
          stepsObject.current.introJs.goToStep(1);
        }, 10);
      }
      setThirdStep(true);
    } else if (currentStep === STREAMS_ONBOARDING_STEPS.sampleStream.step) {
      setShowStreamsDummyData(true);
      setFirstStep(true);
      if (thirdStep) {
        setTimeout(() => {
          stepsObject.current.updateStepElement(1);
          stepsObject.current.introJs.goToStep(2);
        }, 10);

        setThirdStep(false);
      }
    } else {
      setThirdStep(true);
      setFirstStep(true);
    }
  };

  const onBeforeExit = () => {
    backgroundClass.style.overflowY = "visible";
  };

  return (
    <Steps
      enabled
      options={{ nextToDone: true }}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      onBeforeChange={onBeforeChange}
      onBeforeExit={onBeforeExit}
      ref={stepsObject}
    />
  );
};

export default StreamsOnboardingModal;
