import { STREAM_TYPES } from "Constants/AppStreaming.constants";
import React, { useRef, useState, useEffect } from "react";
import { Button, PasswordInput, Switch } from "UI";
import { classnames } from "Utils";

const VisitorDataStep = ({
  streamConfigs,
  setStreamConfigs,
  isVendorPro,
  password,
  setPassword,
  isVendorEnterprise,
  streamType,
  translate,
}) => {
  const { collectInfo, passwordProtection, passwordPresent, userSessionData } = streamConfigs;
  const passwordRef = useRef(null);
  const [passwordInputDisabled, setPasswordInputDisabled] = useState(passwordPresent);
  const parentStream = streamType === STREAM_TYPES.parent;

  useEffect(() => {
    setPasswordInputDisabled(passwordPresent);
    setPassword(null);
    if (passwordRef.current) passwordRef.current.value = null;
  }, [streamConfigs]);

  useEffect(() => {
    if (passwordProtection) {
      // eslint-disable-next-line no-unused-expressions
      passwordRef.current?.focus();
    }
  }, [passwordInputDisabled, passwordProtection]);

  return (
    <>
      <div className={classnames(["configuration-option", (!isVendorPro || parentStream) && "disabled"])}>
        <div className="with-badge">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.collectInfo.header")}
            checked={collectInfo}
            onChange={(e) => setStreamConfigs({ collectInfo: e })}
          />
          <div className="pro-badge">Pro</div>
        </div>
        <p className="description">{translate("configureStream.configurations.collectInfo.description")}</p>
      </div>

      <div className={classnames(["configuration-option", (!isVendorPro || parentStream) && "disabled"])}>
        <div className="with-badge">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.userSessionData.header")}
            checked={userSessionData}
            onChange={(e) => setStreamConfigs({ userSessionData: e })}
          />
          <div className="pro-badge">Pro</div>
        </div>
        <p className="description">{translate("configureStream.configurations.userSessionData.description")}</p>
      </div>

      <div className={classnames(["configuration-option", (!isVendorEnterprise || parentStream) && "disabled"])}>
        <div className="with-badge">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.password.header")}
            onChange={(e) => setStreamConfigs({ passwordProtection: e })}
            checked={passwordProtection}
          />
          <div className="enterprise-badge">Enterprise</div>
        </div>
        <p className="description">{translate("configureStream.configurations.password.description")}</p>
      </div>
      {passwordProtection && (
        <div className="stream-password-container">
          <PasswordInput
            inputRef={passwordRef}
            label={translate("configureStream.configurations.password.label")}
            placeholder={translate("configureStream.configurations.password.placeholder")}
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            disabled={passwordInputDisabled}
          />
          {passwordInputDisabled && (
            <Button
              className="stream-password-button"
              textContained
              white
              text="Update"
              onClick={() => {
                setPasswordInputDisabled(false);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default VisitorDataStep;
