import React, { useEffect } from "react";

import { Input } from "UI";

const JUST_NUMBERS = /^\d+$/;

const NumberInput = (props) => {
  const { inputRef, onChange, maxInputValue, minInputValue, maxDigitNumber, color, haveZero, ...rest } = props;

  const [value, setValue] = React.useState("");

  const onInputChange = (event) => {
    event.preventDefault();
    // Limit input with only numbers
    const strInput = `${event.target.value}`;
    if (strInput !== "" && !JUST_NUMBERS.test(strInput)) {
      return;
    }

    const inputLength = strInput.length;
    // Remove the 0's in beginning and limit digit number
    let slicedValue = strInput.slice(Math.max(0, inputLength - maxDigitNumber));
    if (!haveZero) {
      slicedValue = slicedValue.replace(/^0+/, "");
    }

    if (onChange) {
      if (maxInputValue === undefined && minInputValue !== undefined && slicedValue === "") {
        onChange(minInputValue);
      } else if (maxInputValue !== undefined && minInputValue !== undefined) {
        onChange(Math.max(Math.min(slicedValue, maxInputValue), minInputValue));
      } else {
        onChange(slicedValue);
      }
    }
    setValue(slicedValue);
  };

  useEffect(() => {
    if (maxInputValue !== undefined && value > maxInputValue) {
      setValue(maxInputValue);
      if (onChange) onChange(maxInputValue);
    }
  }, [maxInputValue]);

  const onKeyDown = (event) => {
    // Check if input is a "e" (69), "." (190), "," (188) "+" (187) or "-" (189)
    const notNumber =
      event.keyCode === 69 ||
      event.keyCode === 187 ||
      event.keyCode === 188 ||
      event.keyCode === 189 ||
      event.keyCode === 190;

    if (notNumber) {
      // Prevent adding that character
      event.preventDefault();
    }
  };

  const onBlur = () => {
    if (maxInputValue) {
      if (value > maxInputValue) {
        setValue(maxInputValue);
        if (onChange) {
          onChange(maxInputValue);
        }
      }
    }
    if (minInputValue) {
      if (minInputValue > value) {
        setValue(minInputValue);
        if (onChange) {
          onChange(minInputValue);
        }
      }
    }
  };

  return (
    <Input
      inputRef={inputRef}
      value={value}
      onChange={onInputChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      color={color}
      {...rest}
    />
  );
};

export default NumberInput;
