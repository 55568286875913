import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  resetCreateComputersCTX,
  resetCreateOrganizationSeatsAPI,
  setCreateComputersCTX,
} from "Actions/Organization.actions";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useDebounce } from "use-debounce";
import { Loader, apiGenerator, classnames, kebabToCamel } from "Utils";
import { Icon } from "UI";
import ActionHeader from "UI/ActionHeader/ActionHeader.component";
import ConfigurePlan from "../Components/ConfigurePlan/ConfigurePlan.component";
import Payment from "./Payment/Payment.component";
import QuickStartTable from "./QuickStartTable/QuickStartTable.component";
import "./CreateComputers.styles.scss";

const CHOOSE_PLAN_TABS = {
  QUICKSTART: "QUICKSTART",
  PLAN_CONFIGURATION: "PLAN_CONFIGURATION",
};

const ChoosePlanContainer = ({ selectedTab, setSelectedTab, translate, history, children }) => {
  return (
    <div className="teams-choose-plan-container">
      <div className="start-options-container">
        <div className="start-options-buttons">
          <div
            className={classnames(["button-container", selectedTab === CHOOSE_PLAN_TABS.QUICKSTART && "selected"])}
            onClick={() => {
              setSelectedTab(CHOOSE_PLAN_TABS.QUICKSTART);
              history.push(routes.organizationAdminCreateComputersQuickStart);
            }}
            aria-hidden="true"
          >
            <Icon className="button-icon" name="bolt" tiny />
            <p>{translate("teamDashboard.chooseComputerPlan.pageChangeButtons.quickStart")}</p>
          </div>

          <div
            className={classnames([
              "button-container",
              selectedTab === CHOOSE_PLAN_TABS.PLAN_CONFIGURATION && "selected",
            ])}
            onClick={() => {
              setSelectedTab(CHOOSE_PLAN_TABS.PLAN_CONFIGURATION);
              history.push(routes.organizationAdminCreateComputersCreatePlan);
            }}
            aria-hidden="true"
          >
            <p>{translate("teamDashboard.chooseComputerPlan.pageChangeButtons.createNewPlan")}</p>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

const CreateComputers = ({ history, translate, match }) => {
  const [selectedTab, setSelectedTab] = React.useState(CHOOSE_PLAN_TABS.QUICKSTART);
  const [selectedPlanID, setSelectedPlanID] = React.useState(null);
  const [planSearchQuery, setPlanSearchQuery] = React.useState("");
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);
  const debouncedPlanSearchQuery = useDebounce(planSearchQuery, 500);

  const dispatch = useDispatch();

  const { createComputersCTX } = useSelector((state) => state.organizations);

  useEffect(() => {
    dispatch(setCreateComputersCTX({ seat_plan_id: selectedPlanID }));
  }, [selectedPlanID]);

  useEffect(() => {
    if (
      !createComputersCTX.quantity &&
      !createComputersCTX.selectedUserIds &&
      !createComputersCTX.selectedInvitationIds
    ) {
      history.push(routes.organizationAdminHome);
    }

    return () => {
      dispatch(resetCreateOrganizationSeatsAPI());
      dispatch(resetCreateComputersCTX());
    };
  }, []);

  const { data: plansData } = useQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_SEAT_PLANS, debouncedPlanSearchQuery],
    queryFn: () => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_SEAT_PLANS, { params: { q: planSearchQuery } });
    },
    onSuccess: (resp) => {
      setIsInitialLoading(false);
      const seatPlans = resp.data.seat_plans;
      if (!seatPlans.length > 0 && planSearchQuery === "") {
        setSelectedTab(CHOOSE_PLAN_TABS.PLAN_CONFIGURATION);
        history.push(routes.organizationAdminCreateComputersCreatePlan);
      }
    },
  });

  const createSeatPlanMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATION_SEAT_PLANS, data);
    },
    onSuccess: (resp) => {
      dispatch(setCreateComputersCTX({ seat_plan_id: resp.data.id }));
      history.push(routes.organizationAdminCreateComputersCreatePayment);
    },
  });

  const handleNewPlan = (planConfigs) => {
    createSeatPlanMutation.mutate({ ...planConfigs });
  };

  const currentPage = kebabToCamel(match.params.page);

  return (
    <div className="create-computers-container">
      <ActionHeader
        title={translate(`organizationCreateComputers.${currentPage}.header.title`)}
        description={translate(`organizationCreateComputers.${currentPage}.header.description`)}
        onCloseButtonClick={() => history.push(routes.organizationAdminHome)}
      />
      {/* use DashboardSubScreenLayout */}
      {isInitialLoading ? (
        <Loader />
      ) : (
        <Switch>
          <Route
            path={routes.organizationAdminCreateComputersQuickStart}
            render={() => {
              return (
                <ChoosePlanContainer
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  translate={translate}
                  history={history}
                >
                  <QuickStartTable
                    selectedPlanID={selectedPlanID}
                    setSelectedPlanID={setSelectedPlanID}
                    plansData={plansData}
                    history={history}
                    planSearchQuery={planSearchQuery}
                    setPlanSearchQuery={setPlanSearchQuery}
                    createComputersCTX={createComputersCTX}
                    translate={translate}
                  />
                </ChoosePlanContainer>
              );
            }}
          />
          <Route
            path={routes.organizationAdminCreateComputersCreatePlan}
            render={() => {
              return (
                <ChoosePlanContainer
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  translate={translate}
                  history={history}
                >
                  <ConfigurePlan
                    onButtonClick={(planConfigs) => handleNewPlan(planConfigs)}
                    history={history}
                    translate={translate}
                    createComputers
                    hasError={createSeatPlanMutation.isError}
                    errorText={translate("organizationCreateComputers.customPlan.error")}
                  />
                </ChoosePlanContainer>
              );
            }}
          />

          <Route
            path={routes.organizationAdminCreateComputersCreatePayment}
            render={() => {
              return <Payment history={history} translate={translate} />;
            }}
          />
        </Switch>
      )}
    </div>
  );
};

export default CreateComputers;
