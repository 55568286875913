import React from "react";
import { TextButton } from "UI";

import "./KeyboardShortcuts.styles.scss";

const KeyboardShortcuts = ({ websocketConnection }) => {
  const handleButtonClick = ({ key, keyCode, code }) => {
    if (websocketConnection?.peripherals?.simulateKeyPress) {
      websocketConnection.peripherals.simulateKeyPress(key, keyCode, code || key);
    }
  };

  return (
    <div className="keyboard-shortcuts-container" tabIndex={-1}>
      <TextButton
        text="⇧"
        onClick={() => {
          handleButtonClick({ key: "Shift", keyCode: 16, code: "ShiftLeft" });
        }}
      />
      <TextButton
        text="Ctrl"
        onClick={() => {
          handleButtonClick({ key: "Control", keyCode: 17, code: "ControlLeft" });
        }}
      />
      <TextButton
        text="Alt"
        onClick={() => {
          handleButtonClick({ key: "Alt", keyCode: 18, code: "AltLeft" });
        }}
      />
      {/* <TextButton
        text="PtrScr"
        onClick={() => {
          handleButtonClick({ key: "PrintScreen", keyCode: 44 });
        }}
      />
      <TextButton
        text="Pause"
        onClick={() => {
          handleButtonClick({ key: "Pause", keyCode: 19, code: "Pause" });
        }}
      /> */}
      <TextButton
        text="Del"
        onClick={() => {
          handleButtonClick({ key: "Delete", keyCode: 46 });
        }}
      />
      <TextButton
        text="Home"
        onClick={() => {
          handleButtonClick({ key: "Home", keyCode: 16 });
        }}
      />
      <TextButton
        text="End"
        onClick={() => {
          handleButtonClick({ key: "End", keyCode: 35 });
        }}
      />
      <TextButton
        text="PgUp"
        onClick={() => {
          handleButtonClick({ key: "PageUp", keyCode: 33 });
        }}
      />
      <TextButton
        text="PgDn"
        onClick={() => {
          handleButtonClick({ key: "PageDown", keyCode: 34 });
        }}
      />
      <TextButton
        text="↑"
        onClick={() => {
          handleButtonClick({ key: "ArrowUp", keyCode: 38 });
        }}
      />
      <TextButton
        text="↓"
        onClick={() => {
          handleButtonClick({ key: "ArrowDown", keyCode: 40 });
        }}
      />
      <TextButton
        text="←"
        onClick={() => {
          handleButtonClick({ key: "ArrowLeft", keyCode: 37 });
        }}
      />
      <TextButton
        text="→"
        onClick={() => {
          handleButtonClick({ key: "ArrowRight", keyCode: 39 });
        }}
      />
    </div>
  );
};

export default KeyboardShortcuts;
