/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from "react";
import AddBillingAddressModalComponent from "Utils/Components/Payment/AddBillingAdressModal/AddBillingAddressModal.component";

const BillingAddressInformation = ({ disabled, billingInformation, translate, vendor }) => {
  const [showAddBillingAddressModal, setShowAddBillingAddressModal] = useState(false);

  const { company_name: companyName } = billingInformation || {};

  return (
    <>
      {companyName ? (
        <p className="billing-info-text">
          Billing information has been saved as <span className="bold">{companyName}</span>, click
          <span onClick={() => !disabled && setShowAddBillingAddressModal(true)} role="button">
            {" "}
            here{" "}
          </span>
          to update it.
        </p>
      ) : (
        <p className="billing-info-text">
          <span onClick={() => !disabled && setShowAddBillingAddressModal(true)} role="button">
            Add Billing Information
          </span>
        </p>
      )}
      {showAddBillingAddressModal && (
        <AddBillingAddressModalComponent
          setShowAddBillingAddressModal={setShowAddBillingAddressModal}
          billingInformation={billingInformation}
          translate={translate}
          vendor={vendor}
        />
      )}
    </>
  );
};

export default BillingAddressInformation;
