import _ from "lodash";
import { all, put, call, takeLatest, actionChannel, fork, select, take } from "redux-saga/effects";
import { channel, eventChannel, END } from "redux-saga";

import apiGenerator from "Utils/Helpers/api.helpers";
import axios from "axios";

import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";

import * as FilesConstants from "Constants/Dashboard/Files.constants";
import * as AppStreamingConstants from "Constants/AppStreaming.constants";

import { API_ENDPOINTS, APP_STREAMING_API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";
import { FILE_TYPES, UPLOAD_TYPES } from "Constants/global.constants";
import { MB_TO_BYTE } from "Utils/Helpers/functions.helpers";

const FILE_CHUNK_SIZE_MB = 50;

const getFiles = (state) => state.files;
const getFileCTX = (state) => state.files.userFilesCTX;
const getScope = () => {
  if (window.location.pathname.includes("team")) return "organization";
  return null;
};

const getViewScope = () => {
  if (!window.location.pathname.includes("team")) return null;
  return window.location.pathname.includes("/team/admin") ? "admin" : "member";
};

const RootFolder = { id: 0 };

// TODO: Caching
// function* loadUserFilesSaga(params, action) {
//   const { parentId } = action.payload;
//   const getFileCTX = yield select(getFileCTX);
//   const { files } = getFileCTX;

//   const nextFile = _.find(files, (file) => file.id === parentId);

//   if (nextFile && files[parentId]) {
//     yield put({
//       type: FilesConstants.GET_USER_FILES_SUCCESS,
//       payload: { files: files[parentId], currentFolder: nextFile },
//     });
//   } else {
//     yield put({
//       type: FilesConstants.GET_USER_FILES_PENDING,
//       payload: { parentId },
//     });
//   }
// }

function* getUserFilesSaga(action) {
  const { parentId, move = false, page = 1, perPage = 100, query = null, isRefresh } = action.payload;

  let { scope = null } = action.payload;
  scope ||= getScope();

  let currentSearch;
  if (isRefresh) {
    const userFilesCTX = yield select(getFileCTX);
    currentSearch = userFilesCTX.search;
  }

  let search = query === null ? currentSearch : query;
  if (search === "") {
    search = null;
  }

  const api = apiGenerator("get")(API_ENDPOINTS.GET_USER_FILES, {
    params: { page, per_page: perPage, q: search, parent_id: parentId, scope, view_scope: getViewScope() },
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: FilesConstants.GET_USER_FILES_SUCCESS,
        payload: {
          files: response.data.files,
          currentFolder: response.data.current || RootFolder,
          move,
          isRefresh,
          search,
        },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: FilesConstants.GET_USER_FILES_FAILURE,
      payload: error,
      move,
      isRefresh,
      search,
    });
  }
}

function* getRecentFilesSaga(action) {
  const { page = 1, perPage = 100, query = null } = action.payload;
  const scope = yield select(getScope);

  let api = apiGenerator("get")(API_ENDPOINTS.GET_RECENT_FILES, {
    params: { page, per_page: perPage, scope },
  });

  if (query) {
    api = apiGenerator("get")(API_ENDPOINTS.GET_USER_FILES, {
      params: { page, per_page: perPage, scope, q: query },
    });
  }

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: FilesConstants.GET_RECENT_FILES_SUCCESS,
        payload: {
          files: _.concat(
            _.filter(response.data.files, (file) => file.attributes.object_type === FILE_TYPES.file),
            _.filter(response.data.team_files, (file) => file.attributes.object_type === FILE_TYPES.file),
          ),
        },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: FilesConstants.GET_RECENT_FILES_FAILURE,
      payload: error,
    });
  }
}

function* getFileStorageCapacity() {
  const scope = getScope();

  const api = apiGenerator("get")(API_ENDPOINTS.GET_FILE_STORAGE_CAPACITY, {
    params: {
      scope,
    },
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: FilesConstants.GET_FILE_STORAGE_CAPACITY_SUCCESS,
        payload: {
          totalStorage: response.data.total,
          usedStorage: response.data.in_use,
          teamFolderInfo: response.data.team,
        },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: FilesConstants.GET_FILE_STORAGE_CAPACITY_FAILURE,
      payload: error,
    });
  }
}

function* deleteFileSaga(action) {
  const { id, currentFolderId } = action.payload;
  const scope = getScope();

  const api = apiGenerator("delete")(API_ENDPOINTS.MODIFY_USER_FILES(id), {
    scope,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: FilesConstants.DELETE_FILE_SUCCESS,
      });
      yield put({
        type: FilesConstants.GET_USER_FILES_PENDING,
        payload: { parentId: currentFolderId, isRefresh: true, scope },
      });
      yield put({
        type: FilesConstants.GET_MOVE_FILES_PENDING,
        payload: { parentId: currentFolderId, move: true, scope },
      });
      yield put({
        type: FilesConstants.GET_FILE_STORAGE_CAPACITY_PENDING,
        payload: { scope },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: FilesConstants.DELETE_FILE_FAILURE,
      payload: error,
    });
  }
}

function* moveFileSaga(action) {
  const { id, currentFolderId, selectedFolderId } = action.payload;

  const scope = getScope();
  const api = apiGenerator("put")(API_ENDPOINTS.MODIFY_USER_FILES(id), { parent_id: selectedFolderId, scope });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: FilesConstants.MOVE_FILE_SUCCESS,
      });
      yield put({
        type: FilesConstants.GET_USER_FILES_PENDING,
        payload: { parentId: currentFolderId, isRefresh: true, scope },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: FilesConstants.MOVE_FILE_FAILURE,
      payload: error,
    });
  }
}

function* renameFileSaga(action) {
  const { id, fileName, currentFolderId } = action.payload;
  const scope = getScope();
  const api = apiGenerator("put")(API_ENDPOINTS.MODIFY_USER_FILES(id), {
    file_name: fileName,
    parent_id: currentFolderId,
    scope,
  });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: FilesConstants.RENAME_FILE_SUCCESS,
      });
      yield put({
        type: FilesConstants.GET_USER_FILES_PENDING,
        payload: { parentId: currentFolderId, isRefresh: true, scope },
      });
      yield put({
        type: FilesConstants.GET_MOVE_FILES_PENDING,
        payload: { parentId: currentFolderId, move: true, scope },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: FilesConstants.RENAME_FILE_FAILURE,
      payload: error,
    });
  }
}

function fileAsPromise(entry) {
  return new Promise((resolve, reject) => {
    entry.file(resolve, reject);
  });
}

const folderContentsUploadChannel = channel();

export function* watchFolderContentsChannel() {
  while (true) {
    const action = yield take(folderContentsUploadChannel);
    yield put(action);
  }
}

function* createFolderSaga(action) {
  const { folderName, currentFolderId, item } = action.payload;
  const scope = getScope();

  const api = apiGenerator("post")(
    API_ENDPOINTS.GET_USER_FILES,
    {
      file_name: folderName,
      object_type: "directory",
      parent_id: currentFolderId || 0,
    },
    { params: { parentId: currentFolderId, scope } },
  );

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      if (item) {
        const nestedFolderId = response.data.id;
        const dirReader = item.createReader();
        // callback function
        dirReader.readEntries((entries) => {
          for (let i = 0; i < entries.length; i += 1) {
            const currentFile = entries[i];
            if (currentFile.isFile) {
              fileAsPromise(currentFile).then((file) => {
                folderContentsUploadChannel.put({
                  type: FilesConstants.UPLOAD_FILE_PENDING,
                  payload: {
                    files: [file],
                    currentFolderId: nestedFolderId,
                    isRecentFiles: false,
                    overwrite: false,
                    uploadType: UPLOAD_TYPES.FILE_UPLOAD,
                    scope,
                  },
                });
              });
            } else {
              folderContentsUploadChannel.put({
                type: FilesConstants.CREATE_FOLDER_PENDING,
                payload: { folderName: currentFile.name, currentFolderId: nestedFolderId, item: currentFile, scope },
              });
            }
          }
        });
      }

      yield put({
        type: FilesConstants.CREATE_FOLDER_SUCCESS,
      });
      yield put({
        type: FilesConstants.GET_USER_FILES_PENDING,
        payload: { parentId: currentFolderId, isRefresh: true, scope },
      });
      yield put({
        type: FilesConstants.GET_MOVE_FILES_PENDING,
        payload: { parentId: currentFolderId, move: true, scope },
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: FilesConstants.CREATE_FOLDER_FAILURE,
      payload: error,
    });
  }
}

function* downloadFileSaga(action) {
  const { fileId } = action.payload;
  const scope = getScope();

  const api = apiGenerator("get")(API_ENDPOINTS.DOWNLOAD_FILE(fileId), { params: { scope } });

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: FilesConstants.DOWNLOAD_FILE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: FilesConstants.DOWNLOAD_FILE_FAILURE,
      payload: error,
    });
  }
}

const UPLOAD_ENDPOINTS = {
  [UPLOAD_TYPES.FILE_UPLOAD]: {
    endpoint: API_ENDPOINTS.CREATE_FILE,
    complete: API_ENDPOINTS.UPLOAD_COMPLETED,
  },
  [UPLOAD_TYPES.WORKSTATION_UPLOAD]: {
    endpoint: API_ENDPOINTS.CREATE_WORKSTATION_FILE,
    complete: API_ENDPOINTS.WORKSTATION_FILE_UPLOAD_COMPLETED,
  },
  [UPLOAD_TYPES.APPLICATION_UPLOAD]: {
    endpoint: APP_STREAMING_API_ENDPOINTS.APPLICATION_UPLOAD,
    complete: APP_STREAMING_API_ENDPOINTS.APPLICATION_FILE_UPLOAD_COMPLETED,
  },
  [UPLOAD_TYPES.APPLICATION_VERSION_UPLOAD]: {
    endpoint: APP_STREAMING_API_ENDPOINTS.APPLICATION_VERSION_UPLOAD,
    complete: APP_STREAMING_API_ENDPOINTS.APPLICATION_VERSION_UPLOAD_COMPLETED,
  },
  [UPLOAD_TYPES.APPLICATION_LOGO_UPLOAD]: {
    endpoint: APP_STREAMING_API_ENDPOINTS.APPLICATION_LOGO_UPLOAD,
    complete: APP_STREAMING_API_ENDPOINTS.APPLICATION_LOGO_UPLOAD,
  },
  [UPLOAD_TYPES.APPLICATION_BANNER_UPLOAD]: {
    endpoint: APP_STREAMING_API_ENDPOINTS.APPLICATION_BANNER_UPLOAD,
    complete: APP_STREAMING_API_ENDPOINTS.APPLICATION_BANNER_UPLOAD,
  },
  [UPLOAD_TYPES.STREAM_SESSION_FILE_UPLOAD]: {
    endpoint: APP_STREAMING_API_ENDPOINTS.STREAM_SESSION_FILE_UPLOAD,
    complete: APP_STREAMING_API_ENDPOINTS.STREAM_SESSION_FILE_UPLOAD_COMPLETED,
  },
};

function* uploadFileSaga(
  file,
  currentFolderId,
  isRecentFiles,
  overwrite,
  uploadType,
  fileContents = {},
  options,
  organizationMachineId,
  scope = null,
) {
  let contentType = file.type || "application/octet-stream";

  const FILE_CREATE_ENDPOINT = UPLOAD_ENDPOINTS[uploadType].endpoint;
  const FILE_UPLOAD_COMPLETE_ENDPOINT = UPLOAD_ENDPOINTS[uploadType].complete;

  let createFileAPI;

  switch (uploadType) {
    case UPLOAD_TYPES.APPLICATION_LOGO_UPLOAD:
    case UPLOAD_TYPES.APPLICATION_BANNER_UPLOAD:
      createFileAPI = apiGenerator("post")(FILE_CREATE_ENDPOINT(options.appId), {
        file_name: file.fileName || file.name,
      });
      break;
    case UPLOAD_TYPES.APPLICATION_UPLOAD:
      createFileAPI = apiGenerator("post")(FILE_CREATE_ENDPOINT, {
        file_name: file.fileName || file.name,
        file_size: file.size,
        os: options.os,
        game_engine: options.gameEngine,
        company_name: options.companyName,
        product_name: options.productName,
        executable_list: fileContents[file.fileName || file.name],
      });
      yield put({
        type: FilesConstants.APPLICATION_UPLOAD_FILE_PENDING,
      });
      break;
    case UPLOAD_TYPES.APPLICATION_VERSION_UPLOAD:
      createFileAPI = apiGenerator("post")(FILE_CREATE_ENDPOINT, {
        file_name: file.fileName || file.name,
        file_size: file.size,
        vendor_application_id: options.appId,
        executable_list: fileContents[file.fileName || file.name],
        game_engine: options.gameEngine,
        company_name: options.companyName,
        product_name: options.productName,
      });
      yield put({
        type: FilesConstants.APPLICATION_VERSION_UPLOAD_FILE_PENDING,
      });
      break;

    case UPLOAD_TYPES.STREAM_SESSION_FILE_UPLOAD:
      createFileAPI = apiGenerator("post")(FILE_CREATE_ENDPOINT, {
        file_name: file.fileName || file.name,
        size: file.size,
        content_type: contentType,
        chunk_size: FILE_CHUNK_SIZE_MB,
        uid: options.uid,
      });
      yield put({
        type: FilesConstants.STREAM_SESSION_FILE_UPLOAD_PENDING,
      });
      break;
    default:
      createFileAPI = apiGenerator("post")(FILE_CREATE_ENDPOINT, {
        file_name: file.fileName || file.name,
        content_type: contentType,
        size: file.size,
        chunk_size: FILE_CHUNK_SIZE_MB,
        object_type: "file",
        parent_id: currentFolderId,
        overwrite,
        scope,
        organization_machine_id: organizationMachineId ? parseInt(organizationMachineId, 10) : null,
      });
      contentType = "application/octet-stream";
  }

  try {
    const response = yield createFileAPI;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      let { id: uploadId, upload_urls: uploadUrls } = response.data;

      const { chunk_size: chunkSize } = response.data;
      if (!uploadUrls) {
        uploadUrls = response.data.urls;
      }
      if (!uploadId) {
        uploadId = response.data.vendor_application_id || options.appId;
      }

      const totalPartNumber = uploadUrls.length;
      const expectedPartNumber = Math.ceil(file.size / (MB_TO_BYTE * chunkSize));

      if (chunkSize && totalPartNumber !== expectedPartNumber) {
        throw new Error("Request canceled");
      }

      const uploaded = [0];
      for (let i = 1; i < totalPartNumber; i += 1) {
        uploaded[i] = 0;
      }

      let uploadProgress = 0;
      let error = false;

      const { CancelToken } = axios;
      const source = CancelToken.source();

      const promises = _.map(uploadUrls, (uploadUrl, idx) => {
        const filePartStart = idx * (MB_TO_BYTE * chunkSize);
        const filePartEnd = (idx + 1) * (MB_TO_BYTE * chunkSize);
        const fileBlob = idx < uploadUrls.length ? file.slice(filePartStart, filePartEnd) : file.slice(filePartStart);

        if (uploadUrl.includes("&uploadId=t&")) {
          throw new Error("Wrong upload url");
        }

        return apiGenerator("put")(uploadUrl, fileBlob, {
          headers: {
            "content-type": contentType,
          },
          onUploadProgress: (progressEvent) => {
            uploaded[idx] = (progressEvent.loaded / progressEvent.total) * 100;
          },
          cancelToken: source.token,
        }).catch((e) => {
          error = e;
          if (axios.isCancel(e)) {
            // throw new Error("Request canceled", e.message);
          } else {
            // throw new Error("Error during AWS file upload", e);
          }
        });
      });

      const progressChannel = () =>
        eventChannel((emitter) => {
          const handleUnloadEvent = () => {
            error = true;
            emitter(END);
          };
          window.addEventListener("unload", handleUnloadEvent);

          const progressInterval = setInterval(() => {
            if (error) {
              emitter(END);
            }
            const progress = _.mean(uploaded);
            if (progress !== uploadProgress) {
              uploadProgress = progress;

              emitter(progress);
            } else if (progress === 100) {
              emitter(END); // Close the channel
            }
          }, 1000);
          // The subscriber must return an unsubscribe function
          return () => {
            window.removeEventListener("unload", handleUnloadEvent);
            clearInterval(progressInterval);
          };
        });
      const checkProgress = yield call(progressChannel);

      try {
        while (true) {
          // take(END) will cause the saga to terminate by jumping to the finally block
          const progress = yield take(checkProgress);
          const files = yield select(getFiles);
          const { uploadFilesCTX } = files;

          if (
            progress !== 100 &&
            // Cancel progress if file removed from context or cancelled
            (!uploadFilesCTX.uploadFiles[file.fileName] || uploadFilesCTX.uploadFiles[file.fileName].cancelled)
          ) {
            source.cancel("Upload cancelled");
          }
          yield put({
            type: FilesConstants.UPLOAD_FILE_PROGRESS,
            payload: { fileName: file.fileName, progress },
          });
        }
      } finally {
        if (error) {
          switch (uploadType) {
            case UPLOAD_TYPES.FILE_UPLOAD:
              yield put({
                type: FilesConstants.DELETE_FILE_PENDING,
                payload: { id: uploadId, currentFolderId },
              });
              break;
            default:
              break;
          }

          yield put({
            type: FilesConstants.UPLOAD_FILE_FAILURE,
            payload: { name: file.fileName, error },
          });
        } else {
          const awsS3Responses = yield all(promises);

          const completedParts = _.map(awsS3Responses, (part, index) => ({
            etag: part.headers.etag,
            part_number: index + 1,
          }));

          let uploadCompletedAPI;

          switch (uploadType) {
            case UPLOAD_TYPES.APPLICATION_LOGO_UPLOAD:
            case UPLOAD_TYPES.APPLICATION_BANNER_UPLOAD:
              uploadCompletedAPI = apiGenerator("put")(FILE_UPLOAD_COMPLETE_ENDPOINT(uploadId), {
                key_name: response.data.key_name,
              });
              break;
            default:
              uploadCompletedAPI = apiGenerator("post")(FILE_UPLOAD_COMPLETE_ENDPOINT(uploadId), {
                parts: completedParts,
                scope,
              });
              break;
          }

          const files = yield select(getFiles);
          const { currentFolder } = files.userFilesCTX;

          const uploadCompletedResponse = yield uploadCompletedAPI;
          const isUploadSuccessFull = getStatusCodeFamily(uploadCompletedResponse.status) === STATUS_TYPE.SUCCESS;

          switch (uploadType) {
            case UPLOAD_TYPES.APPLICATION_LOGO_UPLOAD:
            case UPLOAD_TYPES.APPLICATION_BANNER_UPLOAD:
              yield put({
                type: FilesConstants.UPLOAD_FILE_CLEAR,
                payload: { fileName: file.fileName },
              });
              yield put({
                type: AppStreamingConstants.GET_APPLICATIONS_API_PENDING,
              });
              break;
            case UPLOAD_TYPES.WORKSTATION_UPLOAD:
              if (isUploadSuccessFull) {
                const downloadUrl = uploadCompletedResponse.data.download_url;
                yield put({
                  type: FilesConstants.WORKSTATION_UPLOAD_FILE_SUCCESS,
                  payload: { name: file.fileName, downloadUrl },
                });
                yield put({
                  type: FilesConstants.UPLOAD_FILE_SUCCESS,
                  payload: { name: file.fileName, scope },
                });
              }
              break;
            case UPLOAD_TYPES.APPLICATION_UPLOAD:
              if (isUploadSuccessFull) {
                yield put({
                  type: FilesConstants.APPLICATION_UPLOAD_FILE_SUCCESS,
                  payload: {
                    name: file.fileName,
                    fileContents,
                    id: uploadId,
                    os: uploadCompletedResponse.data.attributes.os,
                  },
                });
                yield put({
                  type: FilesConstants.UPLOAD_FILE_CLEAR,
                  payload: { fileName: file.fileName },
                });
                yield put({
                  type: AppStreamingConstants.GET_APPLICATIONS_API_PENDING,
                });
              }
              break;
            case UPLOAD_TYPES.APPLICATION_VERSION_UPLOAD:
              if (isUploadSuccessFull) {
                yield put({
                  type: FilesConstants.APPLICATION_VERSION_UPLOAD_FILE_SUCCESS,
                  payload: { name: file.fileName, fileContents, id: uploadId },
                });
                yield put({
                  type: FilesConstants.UPLOAD_FILE_CLEAR,
                  payload: { fileName: file.fileName },
                });
              }
              break;
            case UPLOAD_TYPES.STREAM_SESSION_FILE_UPLOAD:
              if (isUploadSuccessFull) {
                const downloadUrl = uploadCompletedResponse.data.download_url;
                const fileName = uploadCompletedResponse.data.file_name;
                yield put({
                  type: FilesConstants.STREAM_SESSION_FILE_UPLOAD_SUCCESS,
                  payload: { name: fileName, id: uploadId, downloadUrl },
                });
                yield put({
                  type: FilesConstants.STREAM_SESSION_FILE_UPLOAD_REMOVE,
                  payload: { fileName: file.fileName },
                });
              }
              break;
            default:
              if (isUploadSuccessFull) {
                yield put({
                  type: FilesConstants.UPLOAD_FILE_SUCCESS,
                  payload: { name: file.fileName },
                });
              }

              if (isRecentFiles) {
                yield put({
                  type: FilesConstants.GET_RECENT_FILES_PENDING,
                  payload: { query: files.recentFilesCTX.query },
                });
              } else {
                yield put({
                  type: FilesConstants.GET_USER_FILES_PENDING,
                  payload: { parentId: currentFolder?.id || 0, isRefresh: true },
                });
              }
              yield put({
                type: FilesConstants.GET_FILE_STORAGE_CAPACITY_PENDING,
              });
          }
        }
      }
    }
  } catch (err) {
    const error = apiErrorHandler(err);
    yield put({
      type: FilesConstants.UPLOAD_FILE_FAILURE,
      payload: { name: file.fileName, error },
    });
  }
}

function* watchFileUploadRequests() {
  const requestChan = yield actionChannel(FilesConstants.FILE_UPLOAD);
  while (true) {
    const { payload } = yield take(requestChan);
    const {
      file,
      currentFolderId,
      isRecentFiles,
      overwrite,
      uploadType,
      fileContents,
      options,
      organizationMachineId,
      scope,
    } = payload;
    const files = yield select(getFiles);
    const { uploadFilesCTX } = files;

    if (!uploadFilesCTX.uploadFiles[file.fileName]?.cancelled) {
      yield call(
        uploadFileSaga,
        file,
        currentFolderId || 0,
        isRecentFiles,
        overwrite || false,
        uploadType,
        fileContents,
        options,
        organizationMachineId,
        scope,
      );
    }
  }
}

function* watchFolderUploadRequests() {
  const requestChan = yield actionChannel(FilesConstants.FOLDER_UPLOAD);
  while (true) {
    const { payload } = yield take(requestChan);
    yield call(createFolderSaga, payload);
  }
}

function* createFileUploadJob(payload) {
  yield put({
    type: FilesConstants.FILE_UPLOAD,
    payload,
  });
}

function* createFolderUploadJob(payload) {
  yield put({
    type: FilesConstants.FOLDER_UPLOAD,
    payload,
  });
}

function* uploadFilesGeneratorSaga(action) {
  const { files, ...payload } = action.payload;
  yield all(_.map(files, (file) => call(createFileUploadJob, { file, ...payload, scope: getScope() })));
}

export default function* root() {
  yield all([
    fork(watchFileUploadRequests),
    fork(watchFolderUploadRequests),
    fork(watchFolderContentsChannel),
    takeLatest(FilesConstants.GET_USER_FILES_PENDING, getUserFilesSaga),
    takeLatest(FilesConstants.GET_MOVE_FILES_PENDING, getUserFilesSaga),
    takeLatest(FilesConstants.GET_RECENT_FILES_PENDING, getRecentFilesSaga),
    takeLatest(FilesConstants.GET_FILE_STORAGE_CAPACITY_PENDING, getFileStorageCapacity),
    takeLatest(FilesConstants.DELETE_FILE_PENDING, deleteFileSaga),
    takeLatest(FilesConstants.MOVE_FILE_PENDING, moveFileSaga),
    takeLatest(FilesConstants.RENAME_FILE_PENDING, renameFileSaga),
    takeLatest(FilesConstants.CREATE_FOLDER_PENDING, createFolderUploadJob),
    takeLatest(FilesConstants.DOWNLOAD_FILE_PENDING, downloadFileSaga),
    takeLatest(FilesConstants.UPLOAD_FILE_PENDING, uploadFilesGeneratorSaga),
  ]);
}
