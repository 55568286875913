import React, { useRef, useEffect } from "react";
import { DatePickerInput, Icon, NumberInput, Dropdown, DivButton, HoverableTooltip } from "UI";
import { classnames } from "Utils/Helpers/functions.helpers";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { STREAM_TYPES } from "Constants/AppStreaming.constants";
import { useSelector } from "react-redux";

import "./AvailabilityStep.styles.scss";

const scheduleTypes = {
  always_on: "always_on",
  time_range: "time_range",
  work_days: "work_days",
};

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const resetInput = (e) => {
  e.target.value = "";
};

const AvailabilityStep = ({ streamConfigs, setStreamConfigs, streamType, translate }) => {
  const childStream = streamType === STREAM_TYPES.child;
  const { startDate, endDate, scheduleType, availableDays, timeZoneOffset } = streamConfigs;
  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};
  const customInputRef = useRef(null);
  const selectedTimeZone = userTimeZone;
  const currentDate = selectedTimeZone
    ? new Date().toLocaleString("en-US", { timeZone: selectedTimeZone })
    : new Date();
  const utcOffset = Math.abs(momentTimezone.tz(selectedTimeZone).utcOffset());
  const utcHours = Math.floor(utcOffset / 60);
  const utcMinutes = utcOffset % 60 === 0 ? "" : utcOffset % 60;
  const digitalTimeFormat =
    momentTimezone.tz(selectedTimeZone).utcOffset() >= 0
      ? utcHours + utcMinutes / 100
      : -1 * (utcHours + utcMinutes / 100);

  const timeString = timeZoneOffset.toFixed(2).replace(".", ":");
  const timeZone = `UTC${timeZoneOffset >= 0 ? "+" : ""}${timeString}`;

  useEffect(() => {
    setStreamConfigs({ startDate, endDate });
  }, [startDate, endDate]);

  const changeOffset = () => {
    setStreamConfigs({ timeZoneOffset: digitalTimeFormat });
  };

  useEffect(() => {
    if (timeZoneOffset === 0) setStreamConfigs({ timeZoneOffset: digitalTimeFormat });
  }, []);

  return (
    <>
      <div className={classnames(["configuration-option dropdown", childStream && "disabled"])}>
        <div>
          <p className="header">{translate("configureStream.configurations.scheduleType.header")}</p>
          <p className="description">{translate("configureStream.configurations.scheduleType.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            defaultSelected={scheduleType}
            options={Object.keys(scheduleTypes)}
            handleSelectedOptionChange={(option) => {
              if (option === scheduleTypes.work_days) {
                setStreamConfigs({ startDate: startDate || currentDate, endDate: endDate || currentDate });
              }

              setStreamConfigs({ scheduleType: option });
            }}
            mapFunction={(option) => translate(`configureStream.configurations.scheduleType.options.${option}`)}
          />
        </div>
      </div>
      {scheduleType === scheduleTypes.time_range && (
        <div className="event-schedule-container">
          <div>
            <h4>{translate("appStreamingModals.configureStream.schedule.date")}</h4>
            <div className="horizontal-divider" />
            <DatePickerInput
              startDate={startDate ? new Date(startDate) : startDate}
              setStartDate={(date) => {
                setStreamConfigs({ startDate: date });
                changeOffset();
              }}
              endDate={endDate ? new Date(endDate) : endDate}
              setEndDate={(date) => {
                setStreamConfigs({ endDate: date });
                changeOffset();
              }}
              className="schedule-event"
              minDate={new Date()}
              maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
              inline
            />
          </div>
          <div>
            <div className="schedule-info-header">
              <h4>
                {translate("appStreamingModals.configureStream.schedule.time")} ({timeZone})
              </h4>
              {timeZoneOffset !== digitalTimeFormat && (
                <HoverableTooltip
                  className="schedule-info-tooltip"
                  content="The previously set time zone is different from the current time zone"
                  side="top"
                  align="end"
                >
                  <Icon name="info" color="gray-4" smaller />
                </HoverableTooltip>
              )}
            </div>
            <div className="horizontal-divider" />
            <div className="test">
              <div>
                <p>
                  <span>{translate("appStreamingModals.configureStream.schedule.from")}</span>
                  {startDate ? new Date(startDate).toDateString().substring(0, 15) : ""}
                </p>
                <div className="time-input-box">
                  <Icon name="clock" color="aqua-dark" smaller />
                  <div className="input-container">
                    <NumberInput
                      inputRef={customInputRef}
                      onChange={(value) => {
                        setStreamConfigs({
                          startDate: momentTimezone(startDate).tz(userTimeZone).set("hour", value).toDate(),
                        });
                      }}
                      onClick={() => changeOffset()}
                      maxInputValue={23}
                      minInputValue={0}
                      maxDigitNumber={2}
                      value={momentTimezone(startDate || currentDate)
                        .utcOffset(timeZoneOffset * 60)
                        .format("HH")}
                      disabled={!startDate}
                      onFocus={(e) => resetInput(e)}
                    />
                    <span>:</span>
                    <NumberInput
                      inputRef={customInputRef}
                      onChange={(value) => {
                        setStreamConfigs({
                          startDate: momentTimezone(startDate || currentDate)
                            .tz(userTimeZone)
                            .set("minute", value)
                            .toDate(),
                        });
                      }}
                      onClick={() => changeOffset()}
                      maxInputValue={59}
                      maxDigitNumber={2}
                      minInputValue={0}
                      value={momentTimezone(startDate || currentDate)
                        .utcOffset(timeZoneOffset * 60)
                        .format("mm")}
                      disabled={!startDate}
                      onFocus={(e) => resetInput(e)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>
                  <span>{translate("appStreamingModals.configureStream.schedule.to")}</span>
                  {endDate ? new Date(endDate).toDateString().substring(0, 15) : ""}
                </p>
                <div className="time-input-box">
                  <Icon name="clock" color="aqua-dark" smaller />
                  <div className="input-container">
                    <NumberInput
                      inputRef={customInputRef}
                      onChange={(value) => {
                        setStreamConfigs({
                          endDate: momentTimezone(endDate || currentDate)
                            .tz(userTimeZone)
                            .set("hour", value)
                            .toDate(),
                        });
                      }}
                      onClick={() => changeOffset()}
                      maxInputValue={24}
                      maxDigitNumber={2}
                      minInputValue={0}
                      value={momentTimezone(endDate || currentDate)
                        .utcOffset(timeZoneOffset * 60)
                        .format("HH")}
                      disabled={!endDate}
                      onFocus={(e) => resetInput(e)}
                    />
                    <span>:</span>
                    <NumberInput
                      inputRef={customInputRef}
                      onChange={(value) => {
                        setStreamConfigs({
                          endDate: momentTimezone(endDate || currentDate)
                            .tz(userTimeZone)
                            .set("minute", value)
                            .toDate(),
                        });
                      }}
                      onClick={() => changeOffset()}
                      maxInputValue={59}
                      maxDigitNumber={2}
                      minInputValue={0}
                      value={momentTimezone(endDate || currentDate)
                        .utcOffset(timeZoneOffset * 60)
                        .format("mm")}
                      disabled={!endDate}
                      onFocus={(e) => resetInput(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {scheduleType === scheduleTypes.work_days && (
        <div className="event-schedule-container">
          <div>
            <h4>{translate("appStreamingModals.configureStream.schedule.date")}</h4>
            <div className="horizontal-divider" />
            {days.map((day) => {
              return (
                <DivButton
                  className={classnames(["day-button", availableDays.includes(day) && "active"])}
                  key={day}
                  onClick={() => {
                    setStreamConfigs({
                      availableDays: availableDays.includes(day)
                        ? availableDays.filter((d) => d !== day)
                        : [...availableDays, day],
                    });
                    changeOffset();
                  }}
                >
                  {day}
                </DivButton>
              );
            })}
          </div>
          <div>
            <div className="schedule-info-header">
              <h4>
                {translate("appStreamingModals.configureStream.schedule.time")} ({timeZone})
              </h4>
              {timeZoneOffset !== digitalTimeFormat && (
                <HoverableTooltip
                  className="schedule-info-tooltip"
                  content="The previously set time zone is different from the current time zone"
                  side="top"
                  align="end"
                >
                  <Icon name="info" color="gray-4" smaller />
                </HoverableTooltip>
              )}
            </div>
            <div className="horizontal-divider" />
            <div>
              <div>
                <p>
                  <span>Starts</span>
                </p>
                <div className="time-input-box">
                  <Icon name="clock" color="aqua-dark" smaller />
                  <div className="input-container">
                    <NumberInput
                      inputRef={customInputRef}
                      onChange={(value) => {
                        setStreamConfigs({
                          startDate: momentTimezone(startDate || currentDate)
                            .tz(userTimeZone)
                            .set("hour", value)
                            .toDate(),
                        });
                      }}
                      onClick={() => changeOffset()}
                      maxInputValue={23}
                      minInputValue={0}
                      maxDigitNumber={2}
                      value={momentTimezone(startDate || currentDate)
                        .utcOffset(timeZoneOffset * 60)
                        .format("HH")}
                      onFocus={(e) => resetInput(e)}
                    />
                    <span>:</span>
                    <NumberInput
                      inputRef={customInputRef}
                      onChange={(value) => {
                        setStreamConfigs({
                          startDate: momentTimezone(startDate || currentDate)
                            .tz(userTimeZone)
                            .set("minute", value)
                            .toDate(),
                        });
                      }}
                      onClick={() => changeOffset()}
                      maxInputValue={59}
                      maxDigitNumber={2}
                      minInputValue={0}
                      value={momentTimezone(startDate || currentDate)
                        .utcOffset(timeZoneOffset * 60)
                        .format("mm")}
                      onFocus={(e) => resetInput(e)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>
                  <span>Ends</span>
                </p>
                <div className="time-input-box">
                  <Icon name="clock" color="aqua-dark" smaller />
                  <div className="input-container">
                    <NumberInput
                      inputRef={customInputRef}
                      onChange={(value) => {
                        setStreamConfigs({
                          endDate: momentTimezone(startDate || currentDate)
                            .tz(userTimeZone)
                            .set("hour", value)
                            .toDate(),
                        });
                      }}
                      onClick={() => changeOffset()}
                      maxInputValue={24}
                      maxDigitNumber={2}
                      minInputValue={0}
                      value={momentTimezone(endDate || currentDate)
                        .utcOffset(timeZoneOffset * 60)
                        .format("HH")}
                      onFocus={(e) => resetInput(e)}
                    />
                    <span>:</span>
                    <NumberInput
                      inputRef={customInputRef}
                      onChange={(value) => {
                        setStreamConfigs({
                          endDate: momentTimezone(endDate || currentDate)
                            .tz(userTimeZone)
                            .set("minute", value)
                            .toDate(),
                        });
                      }}
                      onClick={() => changeOffset()}
                      maxInputValue={59}
                      maxDigitNumber={2}
                      minInputValue={0}
                      value={momentTimezone(endDate || currentDate)
                        .utcOffset(timeZoneOffset * 60)
                        .format("mm")}
                      onFocus={(e) => resetInput(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {scheduleType !== scheduleTypes.always_on && (
        <p className="availability-footer">{translate("configureStream.configurations.scheduleType.footer")}</p>
      )}
    </>
  );
};

export default AvailabilityStep;
