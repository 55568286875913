import React from "react";
import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { ConfirmationModal } from "UI";
import { apiGenerator } from "Utils";
import ModalResult from "Components/Organization/Admin/Components/ModalResult/ModalResult.component";

const CancelInvitationModal = ({ onClose, translate, invitationId }) => {
  const removeInviteMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("delete")(API_ENDPOINTS.ORGANIZATION_INVITATION(invitationId));
    },
  });

  if (removeInviteMutation.isSuccess) {
    return (
      <ModalResult
        success={removeInviteMutation.isSuccess}
        successHeader={translate("organizationHome.confirmationModals.cancelInvitation.success.header")}
        successDescription={translate("organizationHome.confirmationModals.cancelInvitation.success.description")}
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      headerText={translate("organizationHome.confirmationModals.cancelInvitation.confirmation.header")}
      descriptionText={
        <div className="show-revoke-description-content">
          <p>{translate("organizationHome.confirmationModals.cancelInvitation.confirmation.description")}</p>
        </div>
      }
      closeAction={() => {
        removeInviteMutation.reset();
        onClose();
      }}
      confirmText="Confirm"
      confirmAction={() => {
        removeInviteMutation.mutate();
      }}
      pending={removeInviteMutation.isLoading}
    />
  );
};

export default CancelInvitationModal;
