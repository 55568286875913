import React, { useEffect, useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import apiGenerator from "Utils/Helpers/api.helpers";
import { ConfirmationModal } from "UI";
import StatusBadge from "UI/StatusBadge/StatusBadge.component";
import ObjectsModal from "../../Components/ObjectsModal/ObjectsModal.component";
import UserCell from "../../Components/UserCell/UserCell.component";

import "./SwitchComputerOwnerModal.styles.scss";

const PER_PAGE = 20;

const SwitchComputerOwnerModal = ({ onClose, selectedObject, translate }) => {
  const [selected, setSelected] = useState(null);
  const [q, setQ] = useState("");
  const [keepFiles, setKeepFiles] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { refetch, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, data } = useInfiniteQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATION_USERS_ASSIGNABLE],
    queryFn: ({ pageParam = 1 }) => {
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATION_USERS_ASSIGNABLE, {
        params: { page: pageParam, per_page: PER_PAGE, q },
      }).then((res) => {
        return res.data;
      });
    },
    getNextPageParam: (lastPage) => lastPage.next_page,
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [q]);

  const switchSeatOwnerMutation = useMutation({
    mutationKey: [API_ENDPOINTS.ORGANIZATION_SEATS_SWITCH(selectedObject.attributes.seat.id)],
    mutationFn: (data) => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_SEATS_SWITCH(selectedObject.attributes.seat.id), data);
    },
    onSuccess: () => {
      setShowConfirmationModal(false);
    },
    onError: () => {
      setShowConfirmationModal(false);
    },
  });

  const flattenAssignableUsers = data?.pages?.flatMap((page) => page?.results);
  const columns = [
    { name: "name", weight: 30, hide: true },
    { name: "status", weight: 20, hide: true },
  ];

  const assignableUsers = flattenAssignableUsers?.map((object) => {
    return {
      key: object.email,
      name: (
        <UserCell
          onCheckBoxChange={(e) => {
            if (e.target.checked) setSelected(object.email);
            else setSelected(null);
          }}
          checked={selected === object.email}
          user={object}
        />
      ),
      status: <StatusBadge status={object.type === "user" ? "computer-required" : "pending-invitation"} />,
    };
  });

  return (
    <div className="switch-computer-owner-modal">
      {showConfirmationModal ? (
        <ConfirmationModal
          headerText={translate("switchComputerOwnerModal.confirmation.header")}
          descriptionText={
            <div className="confirmation-content">
              <p>{translate("switchComputerOwnerModal.confirmation.description")}</p>
              <div className="keep-file-checkbox">
                <input
                  type="checkbox"
                  value={keepFiles}
                  defaultChecked={keepFiles}
                  onChange={(e) => {
                    setKeepFiles(e.target.checked);
                  }}
                />
                <span>{translate("switchComputerOwnerModal.confirmation.keepFiles")}</span>
              </div>
            </div>
          }
          closeAction={() => {
            onClose();
          }}
          confirmText="Confirm"
          confirmAction={() => {
            switchSeatOwnerMutation.mutate({ email: selected, keep_files: keepFiles });
          }}
          pending={switchSeatOwnerMutation.isLoading}
        />
      ) : (
        <ObjectsModal
          onClose={onClose}
          columns={columns}
          data={assignableUsers}
          onConfirm={() => {
            setShowConfirmationModal(true);
          }}
          disableConfirm={!selected}
          success={switchSeatOwnerMutation.isSuccess}
          error={switchSeatOwnerMutation.isError}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          searchQuery={q}
          setSearchQuery={setQ}
          headerText={translate("switchComputerOwnerModal.header.title")}
          successHeader={translate("switchComputerOwnerModal.success.header")}
          successDescription={translate("switchComputerOwnerModal.success.description")}
          emptyContentHeader={translate("switchComputerOwnerModal.emptyContent.title")}
          emptyContentDescription={translate("switchComputerOwnerModal.emptyContent.description")}
        />
      )}
    </div>
  );
};

export default SwitchComputerOwnerModal;
