import React from "react";
import { connect } from "react-redux";
import AppStreamingHomeComponent from "Components/AppStreaming/Home/AppStreamingHome.component";
import {
  getApplicationsAPI,
  getApplicationsStatsAPI,
  getApplicationStatusAPI,
  getCustomerSessionStatsAPI,
  testApplicationAPI,
  getVendorChargeDetailsAPI,
  getCustomerStatsAPI,
  getCurrentPlanAPI,
  getApplicationImagesAPI,
  getApplicationMachineTypesAPI,
  getVendorAccountAPI,
} from "Actions/AppStreaming.actions";
import { getProductOnboardingAPI } from "Actions/Account.actions";
import { uploadApplicationFile, cancelFileUpload } from "Actions/Dashboard/Files.actions";
import { getPaymentMethodsAPI } from "Actions/Dashboard/Payment.actions";

class AppStreamingHomeContainer extends React.Component {
  componentDidMount() {
    const {
      getApplicationsAPI,
      getApplicationsStatsAPI,
      getCustomerStatsAPI,
      getCurrentPlanAPI,
      getPaymentMethodsAPI,
      getVendorAccountAPI,
      getProductOnboardingAPI,
    } = this.props;
    getApplicationsAPI();
    getApplicationsStatsAPI();
    getCustomerStatsAPI();
    getCurrentPlanAPI();
    getPaymentMethodsAPI();
    getVendorAccountAPI();
    getProductOnboardingAPI();
  }

  render() {
    return <AppStreamingHomeComponent {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  appStreaming: state.appStreaming,
  filesCTX: state.files,
});

const actionCreators = {
  getApplicationsAPI,
  getApplicationsStatsAPI,
  getApplicationStatusAPI,
  getCustomerSessionStatsAPI,
  testApplicationAPI,
  getVendorChargeDetailsAPI,
  getCustomerStatsAPI,
  getCurrentPlanAPI,
  uploadApplicationFile,
  cancelFileUpload,
  getApplicationImagesAPI,
  getApplicationMachineTypesAPI,
  getPaymentMethodsAPI,
  getVendorAccountAPI,
  getProductOnboardingAPI,
};

export default connect(mapStateToProps, actionCreators)(AppStreamingHomeContainer);
