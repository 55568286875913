const multiGpuConsentModalShouldOpen = (account, getWorkstationMachineTypesCTX, machineTypeId) => {
  const multiGpuConsentApproved = account.account.attributes.multi_gpu_consent;

  if (multiGpuConsentApproved) {
    return false;
  }

  const multipleGpuMachineTypeIds = getWorkstationMachineTypesCTX?.data?.machine_types?.map((machineType) => {
    if (machineType.attributes?.details?.attributes?.gpu > 1) {
      return parseInt(machineType.id, 10);
    }
    return null;
  });

  const a10gMachine = getWorkstationMachineTypesCTX?.data?.machine_types?.find((machineType) => {
    return machineType.attributes?.details?.attributes?.name.startsWith("g5");
  });

  if (!a10gMachine) {
    return false;
  }

  return multipleGpuMachineTypeIds?.includes(machineTypeId);
};

export default multiGpuConsentModalShouldOpen;
