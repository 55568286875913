import React, { useState, useEffect, useRef, useMemo } from "react";
import { Logger } from "Utils";
import { SESSION_STORAGE } from "Constants/global.constants";
import { REGION_LINKS } from "Constants/Regions.constants";
import _ from "lodash";

import Ping from "./Ping";

const p = new Ping();

// TODO find a less ugly way of handling this
const LIMITED_REGIONS = ["montreal", "bahrain", "frankfurt"];
const LESS_LIMITED_REGIONS = [];

/**
 * Awesome
 * Good
 * Moderate
 * Weak
 */
const STRENGTH_INTERVALS = [70, 120, 300, 9999];
const STRENGTH_THRESHOLD = 40;
const STRENGTHS = ["strong", "good", "moderate", "weak"];
const CONNECTION_TEST_DELAY = 100;

const ConnectionStrength = (props) => {
  const { children, regions, setRegion } = props;

  const [filteredRegions, setFilteredRegions] = useState([]);
  const [connectionStrength, setConnectionStrength] = useState("weak");
  const [latencies, setLatencies] = useState({});
  const [minLatency, setMinLatency] = useState(0);
  const pingTimeout = useRef(null);

  const testingPings = !(minLatency > 0);
  const strength = useMemo(() => {
    if (_.isEqual(regions, ["israel"])) return "moderate";

    return testingPings ? "undetermined" : connectionStrength;
  }, [testingPings, connectionStrength, regions]);

  const addLatency = (key, value) => {
    setLatencies((prevLatencies) => ({
      ...prevLatencies,
      [key]: value,
    }));
  };

  useEffect(() => {
    return () => {
      p.terminate();
      clearTimeout(pingTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (regions) {
      const tempFilteredRegions = _.filter(regions, (region) => REGION_LINKS[region]);
      if (!_.isEqual(filteredRegions, tempFilteredRegions)) {
        setFilteredRegions(tempFilteredRegions);
        checkConnectionStrength(null); //eslint-disable-line
      }
    }
  }, [regions]);

  useEffect(() => {
    if (filteredRegions && filteredRegions.length === Object.values(latencies).length) {
      setMinLatency(_.min(Object.values(latencies)));
    }
  }, [latencies]); //eslint-disable-line

  useEffect(() => {
    if (minLatency > 0) {
      let activeLatency = minLatency;
      let previousLatency = sessionStorage.getItem(SESSION_STORAGE.minLatency) || 0;
      const previousLatencyRegion = sessionStorage.getItem(SESSION_STORAGE.minLatencyRegion);

      let activeRegionName;
      previousLatency = parseInt(previousLatency, 10);
      // Don't change the interval
      if (previousLatency && Math.abs(previousLatency - minLatency) < STRENGTH_THRESHOLD) {
        Logger.log("Latency difference under the threshold", minLatency, previousLatency);
        activeLatency = previousLatency;
        activeRegionName = previousLatencyRegion;
      } else {
        activeRegionName = _.invert(latencies)[activeLatency];
      }

      Logger.log("ConnectionStrength", activeLatency, activeRegionName, latencies);
      if (setRegion) {
        setRegion(activeRegionName);
      }

      // Find the interval that min latency belongs to
      let curInterval = 0;
      const intervalCount = STRENGTH_INTERVALS.length;
      while (curInterval < intervalCount && activeLatency > STRENGTH_INTERVALS[curInterval]) {
        curInterval += 1;
      }

      // Set latency strength
      setConnectionStrength(STRENGTHS[curInterval]);
      sessionStorage.setItem(SESSION_STORAGE.minLatency, activeLatency);
      sessionStorage.setItem(SESSION_STORAGE.minLatencyRegion, activeRegionName);
    }
  }, [minLatency]);

  const checkConnectionStrength = (event, connectionTestDelay = CONNECTION_TEST_DELAY) => {
    // Reset previous latency values
    setMinLatency(0);
    setLatencies({});
    if (setRegion) {
      setRegion(undefined);
    }
    let regionPingTimeout;
    if (regions) {
      // Clear timeout
      clearTimeout(pingTimeout.current);
      regionPingTimeout = setTimeout(() => {
        regions.forEach((region) => {
          if (REGION_LINKS[region]) {
            p.ping(
              REGION_LINKS[region],
              (err, data) => {
                if (data) {
                  if (LIMITED_REGIONS.includes(region)) addLatency(region, data + 30);
                  else if (LESS_LIMITED_REGIONS.includes(region)) addLatency(region, data + 10);
                  else addLatency(region, data);
                }
              },
              10,
            );
          }
        });
      }, connectionTestDelay);
    }

    pingTimeout.current = regionPingTimeout;
  };

  const renderChildren = () =>
    typeof children === "function" ? children({ connectionStrength: strength, checkConnectionStrength }) : children;

  return <>{renderChildren()}</>;
};

export default ConnectionStrength;
