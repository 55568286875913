import { getProductOnboardingAPI } from "Actions/Account.actions";
import Templates from "Components/Organization/Admin/Templates/Templates.component";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const TemplatesContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductOnboardingAPI());
  }, []);

  return <Templates {...props} />;
};

export default TemplatesContainer;
