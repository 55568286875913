import * as LoginConstants from "Constants/Authentication/Login.constants";
import * as AccountConstants from "Constants/Account.constants";
import { getAccessTokenFromLocalStorage, getItemFromLocalStorage } from "Utils/Helpers/storage.helpers";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";
import * as Sentry from "@sentry/react";

// 24*3600*1000
const DAY_IN_MILLISECONDS = 86400000;

const initialState = {
  accessToken: getAccessTokenFromLocalStorage(),
  scope: getItemFromLocalStorage(LOCAL_STORAGE.scope),
  isTeamAccount: null,
  getAccountCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  account: null,
  getAccountPredefinedCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  applyPromoCodeCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  changePasswordCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  paymentHistoryCTX: {
    data: null,
    failedPayment: null,
    failedPaymentStatus: REQUEST_STATUS.PENDING,
    status: REQUEST_STATUS.PENDING,
    error: false,
  },
  transactionHistoryCTX: {
    data: null,
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  machineUsageHistoryCTX: {
    data: null,
    status: REQUEST_STATUS.PENDING,
    error: false,
  },
  resendConfirmationEmailCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
  },
  productOnboardingCTX: {
    data: null,
    status: REQUEST_STATUS.PENDING,
    error: false,
  },
};

export default function account(state = initialState, action) {
  switch (action.type) {
    case LoginConstants.LOGIN_API_SUCCESS:
      return loginAPISuccess(state, action);
    case LoginConstants.LOGOUT_API_PENDING:
      return logoutAPIPending();
    case AccountConstants.GET_ACCOUNT_API_PENDING:
      return getAccountAPIPending(state);
    case AccountConstants.GET_ACCOUNT_API_SUCCESS:
      return getAccountAPISuccess(state, action);
    case AccountConstants.GET_ACCOUNT_API_FAILURE:
      return getAccountAPIFailure(state, action);
    case AccountConstants.GET_ACCOUNT_PREDEFINED_API_PENDING:
      return getAccountPredefinedAPIPending(state);
    case AccountConstants.GET_ACCOUNT_PREDEFINED_API_SUCCESS:
      return getAccountPredefinedAPISuccess(state, action);
    case AccountConstants.GET_ACCOUNT_PREDEFINED_API_FAILURE:
      return getAccountPredefinedAPIFailure(state);
    case AccountConstants.APPLY_PROMOCODE_API_PENDING:
      return updateAccountPredefinedPending(state);
    case AccountConstants.APPLY_PROMOCODE_API_SUCCESS:
      return updateAccountPredefinedSuccess(state, action);
    case AccountConstants.APPLY_PROMOCODE_API_FAILURE:
      return updateAccountPredefinedFailure(state, action);
    case AccountConstants.CHANGE_PASSWORD_API_PENDING:
      return changePasswordAPIPending(state);
    case AccountConstants.CHANGE_PASSWORD_API_SUCCESS:
      return changePasswordAPISuccess(state);
    case AccountConstants.CHANGE_PASSWORD_API_FAILURE:
      return changePasswordAPIFailure(state);
    case AccountConstants.GET_USER_PAYMENT_HISTORY_PENDING:
      return getUserPaymentHistoryPending(state);
    case AccountConstants.GET_USER_PAYMENT_HISTORY_SUCCESS:
      return getUserPaymentHistorySuccess(state, action);
    case AccountConstants.GET_USER_PAYMENT_HISTORY_FAILURE:
      return getUserPaymentHistoryFailure(state);
    case AccountConstants.GET_TRANSACTION_HISTORY_PENDING:
      return getTransactionHistoryPending(state);
    case AccountConstants.GET_TRANSACTION_HISTORY_SUCCESS:
      return getTransactionHistorySuccess(state, action);
    case AccountConstants.GET_TRANSACTION_HISTORY_FAILURE:
      return getTransactionHistoryFailure(state);
    case AccountConstants.GET_FAILED_PAYMENT_PENDING:
      return getFailedPaymentsPending(state);
    case AccountConstants.GET_FAILED_PAYMENT_SUCCESS:
      return getFailedPaymentsSuccess(state, action);
    case AccountConstants.GET_FAILED_PAYMENT_FAILURE:
      return getFailedPaymentsFailure(state);
    case AccountConstants.GET_MACHINE_USAGE_HISTORY_PENDING:
      return getMachineUsageHistoryPending(state);
    case AccountConstants.GET_MACHINE_USAGE_HISTORY_SUCCESS:
      return getMachineUsageHistorySuccess(state, action);
    case AccountConstants.GET_MACHINE_USAGE_HISTORY_FAILURE:
      return getMachineUsageHistoryFailure(state);
    case AccountConstants.RESEND_CONFIRMATION_EMAIL_API_PENDING:
      return resendConfirmationEmailAPIPending(state);
    case AccountConstants.RESEND_CONFIRMATION_EMAIL_API_SUCCESS:
      return resendConfirmationEmailAPISuccess(state);
    case AccountConstants.RESEND_CONFIRMATION_EMAIL_API_FAILURE:
      return resendConfirmationEmailAPIFailure(state);
    case AccountConstants.GET_PRODUCT_ONBOARDING_PENDING:
      return getProductOnboardingPending(state);
    case AccountConstants.GET_PRODUCT_ONBOARDING_SUCCESS:
      return getProductOnboardingSuccess(state, action);
    case AccountConstants.GET_PRODUCT_ONBOARDING_FAILURE:
      return getProductOnboardingFailure(state);
    default:
      return state;
  }
}

function loginAPISuccess(state, action) {
  const { scope, accessToken } = action.payload;
  return {
    ...state,
    scope,
    accessToken,
  };
}

function logoutAPIPending() {
  return {
    ...initialState,
    accessToken: "",
  };
}

function getAccountAPIPending(state) {
  return {
    ...state,
    getAccountCTX: {
      status: state.getAccountCTX.status === REQUEST_STATUS.SUCCESS ? REQUEST_STATUS.SUCCESS : REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getAccountAPISuccess(state, action) {
  const { account } = action.payload;

  let isInGracePeriod = false;
  let isInTeamGracePeriod = false;
  const activeSubscription =
    account.attributes.subscriptions &&
    account.attributes.subscriptions.length > 0 &&
    account.attributes.subscriptions[0].attributes;

  if (activeSubscription) {
    const { status: subscriptionStatus, end_at: subscriptionEndDate } = activeSubscription;

    if (new Date() > new Date(subscriptionEndDate) && subscriptionStatus === "payment_required") {
      isInGracePeriod = true;
    } else if (subscriptionStatus === "waiting_team_subscription_payment") {
      isInTeamGracePeriod = true;
    }
  }

  const subscription = account.attributes.subscriptions[0];
  if (subscription) {
    const remainingDaysToRenew = parseInt(
      Math.abs((new Date(subscription.attributes.end_at) - new Date()) / DAY_IN_MILLISECONDS),
      10,
    );

    account.remainingDaysToRenew = remainingDaysToRenew;
  }
  account.isInGracePeriod = isInGracePeriod;
  account.isInTeamGracePeriod = isInTeamGracePeriod;

  Sentry.setUser({
    email: account.attributes.email,
    id: account.id,
  });

  return {
    ...state,
    getAccountCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
    scope: account.attributes.scope,
    isTeamAccount: account.attributes.team_member,
    hasDeletedSubscription: account.attributes.has_deleted_subscription,
    account,
  };
}

function getAccountAPIFailure(state) {
  return {
    ...state,
    getAccountCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getAccountPredefinedAPIPending(state) {
  return {
    ...state,
    getAccountPredefinedCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
      data: null,
    },
  };
}

function getAccountPredefinedAPISuccess(state, action) {
  return {
    ...state,
    getAccountPredefinedCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getAccountPredefinedAPIFailure(state) {
  return {
    ...state,
    getAccountPredefinedCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
      data: null,
    },
  };
}

function updateAccountPredefinedPending(state) {
  return {
    ...state,
    applyPromoCodeCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
      data: null,
    },
  };
}

function updateAccountPredefinedSuccess(state, action) {
  return {
    ...state,
    applyPromoCodeCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function updateAccountPredefinedFailure(state, action) {
  return {
    ...state,
    applyPromoCodeCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
      data: action.payload,
    },
  };
}

function changePasswordAPIPending(state) {
  return {
    ...state,
    changePasswordCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function changePasswordAPISuccess(state) {
  return {
    ...state,
    changePasswordCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function changePasswordAPIFailure(state) {
  return {
    ...state,
    changePasswordCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getUserPaymentHistoryPending(state) {
  return {
    ...state,
    paymentHistoryCTX: {
      ...state.paymentHistoryCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function getUserPaymentHistorySuccess(state, action) {
  return {
    ...state,
    paymentHistoryCTX: {
      ...state.paymentHistoryCTX,
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload.payments,
    },
  };
}

function getUserPaymentHistoryFailure(state) {
  return {
    ...state,
    paymentHistoryCTX: {
      ...state.paymentHistoryCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getTransactionHistoryPending(state) {
  return {
    ...state,
    transactionHistoryCTX: {
      ...state.transactionHistoryCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function getTransactionHistorySuccess(state, action) {
  return {
    ...state,
    transactionHistoryCTX: {
      ...state.transactionHistoryCTX,
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload.transactions,
    },
  };
}

function getTransactionHistoryFailure(state) {
  return {
    ...state,
    transactionHistoryCTX: {
      ...state.transactionHistoryCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getFailedPaymentsPending(state) {
  return {
    ...state,
    paymentHistoryCTX: {
      ...state.paymentHistoryCTX,
      failedPaymentStatus: REQUEST_STATUS.PENDING,
    },
  };
}

function getFailedPaymentsSuccess(state, action) {
  return {
    ...state,
    paymentHistoryCTX: {
      ...state.paymentHistoryCTX,
      failedPaymentStatus: REQUEST_STATUS.SUCCESS,
      failedPayment: action.payload,
    },
  };
}

function getFailedPaymentsFailure(state) {
  return {
    ...state,
    paymentHistoryCTX: {
      ...state.paymentHistoryCTX,
      failedPaymentStatus: REQUEST_STATUS.FAILURE,
    },
  };
}

function getMachineUsageHistoryPending(state) {
  return {
    ...state,
    machineUsageHistoryCTX: {
      ...state.machineUsageHistoryCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function getMachineUsageHistorySuccess(state, action) {
  return {
    ...state,
    machineUsageHistoryCTX: {
      ...state.machineUsageHistoryCTX,
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload.machine_usages,
    },
  };
}

function getMachineUsageHistoryFailure(state) {
  return {
    ...state,
    machineUsageHistoryCTX: {
      ...state.machineUsageHistoryCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function resendConfirmationEmailAPIPending(state) {
  return {
    ...state,
    resendConfirmationEmailCTX: {
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function resendConfirmationEmailAPISuccess(state) {
  return {
    ...state,
    resendConfirmationEmailCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
    },
  };
}

function resendConfirmationEmailAPIFailure(state) {
  return {
    ...state,
    resendConfirmationEmailCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getProductOnboardingPending(state) {
  return {
    ...state,
    productOnboardingCTX: {
      ...state.productOnboardingCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function getProductOnboardingSuccess(state, action) {
  return {
    ...state,
    productOnboardingCTX: {
      ...state.productOnboardingCTX,
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function getProductOnboardingFailure(state) {
  return {
    ...state,
    productOnboardingCTX: {
      ...state.productOnboardingCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}
