import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator } from "Utils";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AppScopeContext = createContext();

export const AppScopeContextProvider = ({ children }) => {
  const [appScope, setAppScope] = useState(null);
  const accessToken = useSelector((state) => state.account.accessToken);

  useEffect(() => {
    if (!accessToken) {
      setAppScope(null);
    }
  }, [accessToken]);

  const setScopeMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("put")(API_ENDPOINTS.ACCOUNT_SCOPE, data);
    },
  });

  useEffect(() => {
    if (appScope) {
      setScopeMutation.mutate({ scope: appScope });
    }
  }, [appScope]);

  return <AppScopeContext.Provider value={{ appScope, setAppScope }}>{children}</AppScopeContext.Provider>;
};

export const useAppScope = () => useContext(AppScopeContext);
