import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import { apiGenerator } from "Utils";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";

const useAppStreamingBillingAPI = (
  activeTab,
  tabs,
  useQuery,
  startAt,
  endAt,
  applicationFilter,
  streamNameFilter,
  transactionFilter,
) => {
  const { data: filterOptionsData } = useQuery({
    queryKey: [
      APP_STREAMING_API_ENDPOINTS.GET_VENDOR_TRANSACTIONS_FILTER_OPTIONS,
      startAt,
      endAt,
      applicationFilter,
      streamNameFilter,
    ],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_VENDOR_TRANSACTIONS_FILTER_OPTIONS, {
        params: {
          start_at: startAt,
          end_at: endAt,
          detail_level: "stream",
          application_id: applicationFilter?.id,
          stream_id: streamNameFilter?.id,
        },
      });
    },
    keepPreviousData: true,
  });

  const { data: vendorBalanceStats } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.GET_VENDOR_BALANCE_STATS, startAt, endAt],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_VENDOR_BALANCE_STATS, {
        params: {
          start_at: startAt,
          end_at: endAt,
        },
      });
    },
    refetchInterval: activeTab === tabs.SUMMARY && 60000,
  });

  const chargeDetailProps = useCustomInfiniteQuery({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_VENDOR_CHARGE_DETAILS,
    params: {
      start_at: startAt,
      end_at: endAt,
      detail_level: "stream",
      application_id: applicationFilter?.id,
      stream_id: streamNameFilter?.id,
      transaction_type: transactionFilter === "allTransactions" ? null : transactionFilter,
    },
    resource: "transactions",
  });

  const vendorPaymentsProps = useCustomInfiniteQuery({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_VENDOR_PAYMENTS,
    params: {
      start_at: startAt,
      end_at: endAt,
    },
    resource: "payments",
  });

  return {
    filterOptionsData,
    chargeDetailProps,
    vendorPaymentsProps,
    vendorBalanceStats,
  };
};

export default useAppStreamingBillingAPI;
