import React from "react";
import "./PaymentInfoLine.style.scss";

import { HoverableTooltip, Icon } from "UI";

const PaymentInfoLine = (props) => {
  const { price, credit, translate } = props;

  return (
    <div className="payment-info-line">
      <span className="info-description">
        {credit ? translate("paymentInfoLine.creditDescription") : translate("paymentInfoLine.description")}
        <HoverableTooltip content={translate("paymentInfoLine.information")}>
          <Icon name="info" color="purple-main" />
        </HoverableTooltip>
      </span>
      {price ? (
        <span>${price}</span>
      ) : (
        <>
          <span>
            {credit.toFixed(2)}
            <span className="credit">credits</span>
          </span>
        </>
      )}
    </div>
  );
};

export default PaymentInfoLine;
