/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import { classnames, getItemFromLocalStorage, isMobile, Loader } from "Utils";
import { LOCAL_STORAGE } from "Constants/global.constants";
import Draggable from "Utils/Components/Draggable/Draggable.component";

import "./WorkstationCanvas.styles.scss";

const WorkstationCanvas = (props) => {
  const {
    appStreaming,
    isConnected,
    loaderContent,
    customLoader,
    white,
    animatedBackground,
    machineHasMultiGpu,
    showPlayButton,
    analyticsCollectionEnabled,
    translate,
    overrideLoadingText,
  } = props;

  const [loadingTextKey, setLoadingTextKey] = useState();
  const [aspectRatioGreater, setAspectRatioGreater] = useState(true);
  const [resizeActive, setResizeActive] = useState(false);
  const [resetResize, setResetResize] = useState(false);
  const [clicks, setClicks] = useState(0);

  const loadingTextKeyIndex = useRef(0);

  const customResolution = getItemFromLocalStorage(LOCAL_STORAGE.customResolution);
  const x = customResolution?.x;
  const y = customResolution?.y;
  const useLocalResolution = appStreaming || customResolution?.localResolution;

  const loadingTextKeys = [
    {
      key: "multiGpuMessage",
      enabled: machineHasMultiGpu,
    },
    {
      key: "analyticsMessage",
      enabled: analyticsCollectionEnabled,
    },
    {
      key: "refresh",
      enabled: !analyticsCollectionEnabled,
    },
  ];

  useEffect(() => {
    const enabledLoadingTextKeys = loadingTextKeys.filter((key) => key.enabled);
    setLoadingTextKey(enabledLoadingTextKeys[loadingTextKeyIndex.current]?.key);
    let interval;

    if (enabledLoadingTextKeys.length > 1) {
      interval = setInterval(() => {
        loadingTextKeyIndex.current = (loadingTextKeyIndex.current + 1) % enabledLoadingTextKeys.length;
        setLoadingTextKey(enabledLoadingTextKeys[loadingTextKeyIndex.current]?.key);
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [machineHasMultiGpu, analyticsCollectionEnabled]);

  useEffect(() => {
    const aspectRatio = window.innerWidth / window.innerHeight;
    const videoAspectRatio = x / y;
    setAspectRatioGreater(aspectRatio > videoAspectRatio);
  }, [x, y]);

  // re calculate aspect ratio on window resize
  useEffect(() => {
    const handleResize = () => {
      const newAspectRatio = window.innerWidth / window.innerHeight;
      const newVideoAspectRatio = x / y;

      const newAspectRatioGreater = newAspectRatio > newVideoAspectRatio;
      setAspectRatioGreater(newAspectRatioGreater);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [x, y]);

  const handleClick = (e) => {
    if (!isMobile) return;
    const videoContainer = document.getElementById("video-container");
    if (videoContainer.contains(e.target)) return;

    setClicks((prev) => prev + 1);
  };

  useEffect(() => {
    let doubleClickTimeout = null;
    let singleClickTimeout = null;

    if (clicks === 3) {
      clearTimeout(doubleClickTimeout);
      clearTimeout(singleClickTimeout);
      setResetResize(true);
      setResizeActive(false);
      setClicks(0);
    } else if (clicks === 2) {
      doubleClickTimeout = setTimeout(() => {
        if (clicks === 2) {
          setResizeActive((prev) => !prev);
          setClicks(0);
        }
      }, 300);
    } else if (clicks === 1) {
      singleClickTimeout = setTimeout(() => {
        if (clicks === 1) {
          setClicks(0);
        }
      }, 300);
    }

    return () => {
      clearTimeout(doubleClickTimeout);
      clearTimeout(singleClickTimeout);
    };
  }, [clicks]);

  useEffect(() => {
    if (useLocalResolution) {
      setResetResize(true);
      setResizeActive(false);
    }
  }, [useLocalResolution]);

  return (
    <>
      {useLocalResolution ? (
        /* React has a problem with video element muted tag so we need to use dangerouslySetInnerHTML only for this */
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: "<video muted autoplay disablePictureInPicture playsinline id='player' />",
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: "<video muted autoplay disablePictureInPicture playsinline id='vr-player' />",
            }}
          />
        </div>
      ) : (
        <>
          <div
            onClick={handleClick}
            className={classnames([
              !useLocalResolution && isConnected && "video-container-centered",
              !useLocalResolution && aspectRatioGreater ? "aspect-ratio-greater" : "aspect-ratio-less",
            ])}
            style={{ "--x": x, "--y": y }}
          >
            <Draggable enabled={resizeActive} reset={resetResize} setReset={setResetResize}>
              {(scale) => (
                <div
                  id="video-container"
                  className={resizeActive ? "resize-active" : ""}
                  data-scale={scale}
                  dangerouslySetInnerHTML={{
                    __html: "<video muted autoplay disablePictureInPicture playsinline id='player' />",
                  }}
                />
              )}
            </Draggable>
            <div
              dangerouslySetInnerHTML={{
                __html: "<video muted autoplay disablePictureInPicture playsinline id='vr-player' />",
              }}
            />
          </div>
        </>
      )}

      <div id="in-hidden" className="intercom-hidden" />
      <div
        id="overlay"
        className={classnames([
          "workstation-overlay",
          white && "white",
          animatedBackground && "animated-bg",
          Boolean(customLoader) && "custom",
          showPlayButton && "higher-z-index",
        ])}
      >
        {(!isConnected || showPlayButton) &&
          (customLoader ? (
            <>{customLoader}</>
          ) : (
            <>
              <Loader description={loaderContent} big centered connection />
              <div className="loading-message">
                {overrideLoadingText || <p>{translate(`loadingScreen.${loadingTextKey}`)}</p>}
              </div>
            </>
          ))}
      </div>
    </>
  );
};

export default WorkstationCanvas;
